@import "../node_modules/@fortawesome/fontawesome-free/css/all.css";
@import "../node_modules/@fontsource/montserrat/400.css";
@import "../node_modules/@fontsource/montserrat/700.css";
@import "../node_modules/@fontsource/nunito-sans/200.css";
@import "../node_modules/@fontsource/nunito-sans/300.css";
@import "../node_modules/@fontsource/nunito-sans/400.css";
@import "../node_modules/@fontsource/nunito-sans/600.css";
@import "../node_modules/@fontsource/nunito-sans/700.css";

@import "../node_modules/leaflet/dist/leaflet.css";
@import "../node_modules/cirrus-ui/dist/cirrus.css";

@import "../node_modules/magnific-popup/dist/magnific-popup.css";

/* TODO - move to tailwind
@tailwind base;
@tailwind components;
@tailwind utilities;
*/

body {
  background-size: cover;
  background-repeat: no-repeat;
  backdrop-filter: blur(5px);
} 
.logo {
  width: 150px
}
#map-donau {
  z-index: 99;
  width: 100%;
  height: 400px;
}
#map-mh {
  z-index: 99;
  width: 100%;
  height: 400px;
}
.filtr-item {
  min-width: 250px;
}
.filter-cnt {
  min-height: 370px;
}