/*
* Cirrus 0.7.1
* Stanley Lim, Copyright 2022
* https://spiderpig86.github.io/Cirrus
*/
@charset "UTF-8";
/* v1 Color scheme for Cirrus */
/*
  Functions
  TODO Refactor to different categories
*/
/*
    Converts a given hex value to RGB.
*/
/*
    Generates delimited class name prefix.
*/
/*
    Gets entry from given config by key and will merge with extended properties inside the extend map. This function also exists because merging with a null map does not work.
    Use case is that if we have somthing like this:

    $config: (
        opacity: null, // Disable default opacity classes
        extends: (
            // Framework will now extend with your config and generate .u-opacity-42
            opacity: (
                42: .42
            )
        )
    );
*/
/*
    Converts a list of properties to a mapping where the key and value are the property
    itself.
*/
/*
    To generate the negative variant of classes, we want to prepend 'n' to the key and add a negative sign to the value.

    This automatically skips string values and 0 values.
    This also assumes that the values passed in are positive variants of classes. This will break with negative variants.
*/
/*
    Merge multiple maps with each other.

    $merged: map-multi-merge($map1, $map2, $map3, ...);
*/
/*
    Filters over a given map only for keys between $start inclusive and
    $end exclusive.
*/
/*
    Group selectors that share common styling.
*/
/*
    Global Mixins
*/
/*
    Mixin that returns a property map as a series of CSS class properties.

    E.g. $xs: (color: #fff, opacity: 0.5), $sm... =>

    <class>--xs {
        color: #fff;
        opacity: 0.5;
    }
*/
/*
    Bring to front on focus.
*/
/* Breakpoints */
/* Fonts */
/* Images */
/************
 * DEFAULTS *
 ************/
/*
    Fetch feature flag for different utility class types for generating viewport classes (e.g., u-flex-sm, u-flex-md, etc.).
*/
/*
  Helper function to determine if a set of classes should be generated based on given include/exclude configuration.
*/
/* Media Queries */
/* Variant of mixin that takes in mapping of class name to value */
/**
 * DART SASS ONLY: Generates viewport classes given styles in a @content mixin
 * 
 * When using this mixin, you must follow specify a mixin that takes in the same number of variables that is being passed into @content below -- in this case, 1.
 * 
 * Example usage (pretend \ is $):
 *      
 *       @include generate-styles-with-viewports('true') using ($viewport) {
 *           $suffix: if($viewport != '', '-viewport', '');
 *           .outersuffix {
 *               background: #222;
 *               .innersuffix {
 *                   color: #fff;
 *               }
 *           }
 *       }
 */
/* VALUE APIS */
/* BASE STYLING + RESET FOR CIRRUS */
:root {
  /* v1 Colors */
  --cirrus-fg: #0f172a;
  --cirrus-bg: #ffffff;
  --cirrus-select-bg: rgba(0, 161, 255, 0.2);
  --cirrus-code-bg: rgba(255, 218, 221, 1);
  --cirrus-code-fg: #dc4753;
  --cirrus-form-group-bg: rgba(248, 249, 250, 1);
  --cirrus-form-group-fg: rgba(144, 144, 144, 1);
  --toast-primary-bg: rgba(49, 59, 80, 0.9);
  --animation-duration: 0.2s;
  --focus-opacity: 0.55;
  --font-size-xs: 0.75rem;
  --font-size-s: 0.875rem;
  --font-size-m: 1rem;
  --font-size-l: 1.25rem;
  --font-size-xl: 1.5rem;
  --cirrus-focus-size: 0 0 0.1rem 0.15rem;
  --cirrus-focus-color: rgba(240, 61, 77, 0.13);
  --bg-opacity: 1;
  --color-opacity: 1;
  --border-opacity: 1;
}

/* RESET */
/* Box Sizing */
*,
*::before,
*::after {
  /* Prevent setting borders from increasing the size of an element */
  box-sizing: border-box;
  border: 0 solid currentColor;
  text-rendering: optimizeLegibility;
  -webkit-tap-highlight-color: transparent;
}

/* Remove default margin and padding */
html,
body,
p,
ol,
ul,
li,
dl,
dt,
dd,
blockquote,
figure,
fieldset,
legend,
textarea,
pre,
iframe,
hr,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}

/* Allow % styles to work on page */
html,
body {
  border: none;
  height: 100%;
}

/* Nunito Sans for the font */
body {
  letter-spacing: 0.01rem;
  line-height: 1.8; /* Globally adjust line height */
  font-size: 1rem;
  font-weight: 400;
  font-family: "Nunito Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  color: var(--cirrus-fg);
}

/* Remove bullets from unordered lists */
ul {
  list-style: none;
}

/* Setting up embedded content */
audio,
canvas,
iframe,
img,
embed,
object,
svg,
video {
  display: block;
  max-width: 100%;
  height: auto;
}

iframe {
  outline: 0;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}

/* Page sections set to block */
article,
aside,
figure,
footer,
header,
hgroup,
section {
  display: block;
}

button,
input,
optgroup,
select,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
}

/* Base styling for labels */
label {
  display: inline-block;
  margin: 0.25rem 0;
}

/* Base styling for fieldsets */
fieldset {
  padding: 1rem;
}

fieldset legend {
  font-weight: bold;
}

/* Hidden elements must not show */
[hidden] {
  display: none !important;
}

/* Selection Color */
::selection {
  background-color: var(--cirrus-select-bg);
}

/* When focusing any element */
:focus {
  box-shadow: var(--cirrus-focus-size) var(--cirrus-focus-color);
  outline: none;
}

/* Remove all animations based on preference */
@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto;
  }
  *,
*::before,
*::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}
/* ANIMATIONS */
/* Keyframes */
/* Spinning loading animation */
@keyframes loading {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
/* Heart animation */
@keyframes pound {
  to {
    transform: scale(1.1);
  }
}
/* Bounce animations */
@keyframes bounce {
  from, 20%, 53%, 80%, to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translate3d(0, 0, 0);
  }
  40%, 43% {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(0, -30px, 0);
  }
  70% {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(0, -15px, 0);
  }
  90% {
    transform: translate3d(0, -4px, 0);
  }
}
@keyframes bounceIn {
  from, 20%, 40%, 60%, 80%, to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }
  20% {
    transform: scale3d(1.1, 1.1, 1.1);
  }
  40% {
    transform: scale3d(0.9, 0.9, 0.9);
  }
  60% {
    opacity: 1;
    transform: scale3d(1.03, 1.03, 1.03);
  }
  80% {
    transform: scale3d(0.97, 0.97, 0.97);
  }
  to {
    opacity: 1;
    transform: scale3d(1, 1, 1);
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes pulse {
  50% {
    opacity: 0.5;
  }
}
@keyframes ping {
  75%, 100% {
    transform: scale(2);
    opacity: 0;
  }
}
/* Hover animation */
.hover-grow {
  /* Mouse leave */
  transition-duration: 0.32s;
}

.hover-grow:hover {
  /* Mouse enter */
  transform: scale(1.1);
  transition-duration: 0.08s;
}

.animated {
  animation-duration: 1s;
  animation-fill-mode: both;
  /* Loading button position relatively for loading spinner location */
}

.animated.loading {
  display: block;
  position: relative;
  /* Loading Spinner, align center by default */
  /* Hide text in loading button */
}

.animated.loading::after {
  border: 2px solid #ced4da;
  border-radius: 50%;
  border-right-color: transparent;
  border-top-color: transparent;
  content: "";
  display: block;
  height: 1rem;
  width: 1rem;
  left: calc(50% - 0.8em);
  top: calc(50% - 0.7407407407em);
  position: absolute;
  animation: loading 500ms infinite linear;
}

.animated.loading.loading-white::after {
  border-left-color: #fff;
  border-bottom-color: #fff;
}

.animated.loading.loading-left {
  padding-left: 3rem;
  /* Align spinner left */
}

.animated.loading.loading-left::after {
  left: 1rem;
  right: auto;
}

.animated.loading.loading-right {
  padding-right: 3rem;
  /* Align spinner right */
}

.animated.loading.loading-right::after {
  left: auto;
  right: 1rem;
}

.animated.loading.hide-text {
  color: transparent !important;
}

.animated.pound {
  animation: pound 0.35s infinite alternate;
  vertical-align: baseline;
}

.animated.bounce {
  animation-name: bounce;
  transform-origin: center bottom;
}

.animated.bounceIn {
  animation-name: bounceIn;
}

.animated.fadeIn {
  animation-name: fadeIn;
}

.animated.infinite {
  animation-iteration-count: infinite;
}

.animated.infinite.alternate {
  animation-direction: alternate;
}

.animated.paused {
  animation-play-state: paused !important;
}

.animated.pulse {
  animation: pulse 1.25s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

.animated.ping {
  animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite;
}

/* FONT */
/* Constants */
/* Headers */
h6 {
  font-size: 1.25rem;
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-weight: 700;
  line-height: 1.2;
  margin-bottom: 1rem;
}

h5 {
  font-size: 1.5rem;
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-weight: 700;
  line-height: 1.2;
  margin-bottom: 1rem;
}

h4 {
  font-size: 1.75rem;
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-weight: 700;
  line-height: 1.2;
  margin-bottom: 1rem;
}

h3 {
  font-size: 2rem;
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-weight: 700;
  line-height: 1.2;
  margin-bottom: 1rem;
}

h2 {
  font-size: 2.5rem;
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-weight: 700;
  line-height: 1.2;
  margin-bottom: 1rem;
}

h1 {
  font-size: 3rem;
  letter-spacing: 0.025rem;
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-weight: 700;
  line-height: 1.2;
  margin-bottom: 1rem;
}

/* Headlines (for larger titles) */
.headline-4 {
  font-size: 3.5rem;
  letter-spacing: 0.05rem;
}

.headline-3 {
  font-size: 4.5rem;
  letter-spacing: 0.05rem;
}

.headline-2 {
  font-size: 5.5rem;
  letter-spacing: 0.05rem;
}

.headline-1 {
  font-size: 6.5rem;
  letter-spacing: 0.05rem;
}

/* Regular text sizes */
.text-xs {
  font-size: 0.75rem !important;
  line-height: 1.5rem !important;
}

.text-sm {
  font-size: 0.875rem !important;
  line-height: 1.75rem !important;
}

.text-md {
  font-size: 1rem !important;
  line-height: 2rem !important;
}

.text-lg {
  font-size: 1.25rem !important;
  line-height: 2.25rem !important;
}

.text-xl {
  font-size: 1.5rem !important;
  line-height: 2.25rem !important;
}

p,
article,
blockquote {
  font-size: 1rem;
  line-height: 2;
  margin-bottom: 1rem;
}

p.lead {
  font-size: 1.2rem;
}

blockquote {
  background-color: #f8f9fa;
  border-left: 5px solid #e9ecef;
  border-radius: 3px;
  margin-bottom: 1rem;
  padding: 1rem 2rem;
}

blockquote.text--large {
  font-size: 1.15rem;
}

blockquote p {
  margin: 0;
}

cite {
  opacity: 0.7;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #f0e8c4;
}

.font-primary {
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

.font-alt, .font-secondary {
  font-family: "Nunito Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

.content h1 {
  line-height: 1.125em;
  margin: 1rem 0;
}

.content h2 {
  line-height: 1.125em;
  margin: 1rem 0;
}

.content h3 {
  line-height: 1.125em;
  margin: 1rem 0;
}

.content h4 {
  line-height: 1.125em;
  margin: 1rem 0;
}

.content h5 {
  line-height: 1.125em;
  margin: 1rem 0;
}

.content h6 {
  line-height: 1.125em;
  margin: 1rem 0;
}

/* TODO: To deprecate */
.title {
  font-weight: bold;
}

.title:not(:last-child),
.subtitle:not(:last-child) {
  margin-bottom: 1rem;
}

.title + .subtitle {
  /* Make the subtitlte closer to the title */
  margin-top: -0.75rem;
}

/* Use default line-height for these texts and when line-height-inherit is specified */
.card-tile p,
.card-tile article,
.card-tile blockquote {
  line-height: inherit;
}

/* Weights */
.font-extrathin {
  font-weight: 100;
}

.font-thin {
  font-weight: 200;
}

.font-light {
  font-weight: 300;
}

.font-normal {
  font-weight: 400;
}

.font-medium {
  font-weight: 500;
}

.font-semibold {
  font-weight: 600;
}

.font-bold {
  font-weight: 700;
}

.font-extrabold {
  font-weight: 800;
}

.font-black {
  font-weight: 900;
}

/* Text Transformations */
.uppercase {
  text-transform: uppercase;
}

.lowercase {
  text-transform: lowercase;
}

.capitalize {
  text-transform: capitalize;
}

.rtl {
  direction: rtl;
}

/* Font Awesome */
.icon {
  /* Usually used in spans */
  display: inline-block;
  /* font-size: 21px; Allow font sizes for icons to be overwritten */
  /* line-height: 1.5rem; Allow line height to be inherited or overwritten */
  text-align: center;
  width: 1.5rem;
  vertical-align: baseline;
}

.icon.x-small {
  margin: 0.55rem 0 0 0.1rem;
}

.icon.small {
  margin: 0.95rem 0 0 0.1rem;
}

.icon .fa-wrapper {
  font-size: inherit;
}

.info {
  display: block;
  font-size: var(--font-size-s);
  margin-top: 0.25rem;
  color: #868e96;
}

.info.inline {
  /* Keeps the extra info inline */
  display: inline-block;
  margin-left: 0.5rem;
}

.caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px dashed;
  border-top: 4px solid\9 ;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}

/* Other text elements */
abbr[title] {
  border-bottom: 0.1rem dotted;
  cursor: help;
  text-decoration: none;
}

kbd {
  background-color: var(--cirrus-fg);
  border-radius: 0.2rem;
  color: #fff;
  display: inline-block;
  line-height: 1;
  padding: 0.5rem;
  vertical-align: baseline;
}

/* Letter spacing */
.tracking-tightest {
  letter-spacing: -0.075em !important;
}

.tracking-tighter {
  letter-spacing: -0.05em !important;
}

.tracking-tight {
  letter-spacing: -0.025em !important;
}

.tracking-normal {
  letter-spacing: 0 !important;
}

.tracking-loose {
  letter-spacing: 0.025em !important;
}

.tracking-looser {
  letter-spacing: 0.05em !important;
}

.tracking-loosest {
  letter-spacing: 0.1em !important;
}

/* Line height */
.leading-none {
  line-height: 1 !important;
}

.leading-tighter {
  line-height: 1.25 !important;
}

.leading-tight {
  line-height: 1.375 !important;
}

.leading-normal {
  line-height: 1.5 !important;
}

.leading-loose {
  line-height: 1.625 !important;
}

.leading-looser {
  line-height: 2 !important;
}

/* MOBILE */
@media screen and (max-width: 639px) {
  h6 {
    font-size: 1rem;
  }
  h5 {
    font-size: 1.25rem;
  }
  h4 {
    font-size: 1.5rem;
  }
  h3 {
    font-size: 1.75rem;
  }
  h2 {
    font-size: 2rem;
  }
  h1 {
    font-size: 2.25rem;
  }
  /* Headlines (for larger titles) */
  .headline-4 {
    font-size: 3rem;
  }
  .headline-3 {
    font-size: 3.25rem;
  }
  .headline-2 {
    font-size: 3.5rem;
  }
  .headline-1 {
    font-size: 3.75rem;
  }
  p,
article,
blockquote {
    margin: 1rem 0;
  }
}
/* GRID */
:root {
  --grid-template-column: repeat(12, minmax(0, 1fr));
  --grid-column-start: auto;
  --grid-column-end: auto;
  --grid-row-start: auto;
  --grid-row-end: auto;
}

.grid {
  display: grid;
  grid-gap: var(--grid-gap);
  grid-template-columns: var(--grid-template-column);
}

/* Templates */
.grid-cols-1 {
  --grid-template-column: repeat(1, minmax(0, 1fr));
}

/* Column expansion */
.grid-c-1 {
  grid-column: span 1/span 1;
}

/* Row expansion */
.grid-r-1 {
  grid-row: span 1/span 1;
}

/* Cell Column Start/End */
.grid-cs-1 {
  grid-column-start: 1;
}

.grid-ce-1 {
  grid-column-end: 2;
}

/* Cell Row Start/End */
.grid-rs-1 {
  grid-row-start: 1;
}

.grid-re-1 {
  grid-row-end: 2;
}

/* Templates */
.grid-cols-2 {
  --grid-template-column: repeat(2, minmax(0, 1fr));
}

/* Column expansion */
.grid-c-2 {
  grid-column: span 2/span 2;
}

/* Row expansion */
.grid-r-2 {
  grid-row: span 2/span 2;
}

/* Cell Column Start/End */
.grid-cs-2 {
  grid-column-start: 2;
}

.grid-ce-2 {
  grid-column-end: 3;
}

/* Cell Row Start/End */
.grid-rs-2 {
  grid-row-start: 2;
}

.grid-re-2 {
  grid-row-end: 3;
}

/* Templates */
.grid-cols-3 {
  --grid-template-column: repeat(3, minmax(0, 1fr));
}

/* Column expansion */
.grid-c-3 {
  grid-column: span 3/span 3;
}

/* Row expansion */
.grid-r-3 {
  grid-row: span 3/span 3;
}

/* Cell Column Start/End */
.grid-cs-3 {
  grid-column-start: 3;
}

.grid-ce-3 {
  grid-column-end: 4;
}

/* Cell Row Start/End */
.grid-rs-3 {
  grid-row-start: 3;
}

.grid-re-3 {
  grid-row-end: 4;
}

/* Templates */
.grid-cols-4 {
  --grid-template-column: repeat(4, minmax(0, 1fr));
}

/* Column expansion */
.grid-c-4 {
  grid-column: span 4/span 4;
}

/* Row expansion */
.grid-r-4 {
  grid-row: span 4/span 4;
}

/* Cell Column Start/End */
.grid-cs-4 {
  grid-column-start: 4;
}

.grid-ce-4 {
  grid-column-end: 5;
}

/* Cell Row Start/End */
.grid-rs-4 {
  grid-row-start: 4;
}

.grid-re-4 {
  grid-row-end: 5;
}

/* Templates */
.grid-cols-5 {
  --grid-template-column: repeat(5, minmax(0, 1fr));
}

/* Column expansion */
.grid-c-5 {
  grid-column: span 5/span 5;
}

/* Row expansion */
.grid-r-5 {
  grid-row: span 5/span 5;
}

/* Cell Column Start/End */
.grid-cs-5 {
  grid-column-start: 5;
}

.grid-ce-5 {
  grid-column-end: 6;
}

/* Cell Row Start/End */
.grid-rs-5 {
  grid-row-start: 5;
}

.grid-re-5 {
  grid-row-end: 6;
}

/* Templates */
.grid-cols-6 {
  --grid-template-column: repeat(6, minmax(0, 1fr));
}

/* Column expansion */
.grid-c-6 {
  grid-column: span 6/span 6;
}

/* Row expansion */
.grid-r-6 {
  grid-row: span 6/span 6;
}

/* Cell Column Start/End */
.grid-cs-6 {
  grid-column-start: 6;
}

.grid-ce-6 {
  grid-column-end: 7;
}

/* Cell Row Start/End */
.grid-rs-6 {
  grid-row-start: 6;
}

.grid-re-6 {
  grid-row-end: 7;
}

/* Templates */
.grid-cols-7 {
  --grid-template-column: repeat(7, minmax(0, 1fr));
}

/* Column expansion */
.grid-c-7 {
  grid-column: span 7/span 7;
}

/* Row expansion */
.grid-r-7 {
  grid-row: span 7/span 7;
}

/* Cell Column Start/End */
.grid-cs-7 {
  grid-column-start: 7;
}

.grid-ce-7 {
  grid-column-end: 8;
}

/* Cell Row Start/End */
.grid-rs-7 {
  grid-row-start: 7;
}

.grid-re-7 {
  grid-row-end: 8;
}

/* Templates */
.grid-cols-8 {
  --grid-template-column: repeat(8, minmax(0, 1fr));
}

/* Column expansion */
.grid-c-8 {
  grid-column: span 8/span 8;
}

/* Row expansion */
.grid-r-8 {
  grid-row: span 8/span 8;
}

/* Cell Column Start/End */
.grid-cs-8 {
  grid-column-start: 8;
}

.grid-ce-8 {
  grid-column-end: 9;
}

/* Cell Row Start/End */
.grid-rs-8 {
  grid-row-start: 8;
}

.grid-re-8 {
  grid-row-end: 9;
}

/* Templates */
.grid-cols-9 {
  --grid-template-column: repeat(9, minmax(0, 1fr));
}

/* Column expansion */
.grid-c-9 {
  grid-column: span 9/span 9;
}

/* Row expansion */
.grid-r-9 {
  grid-row: span 9/span 9;
}

/* Cell Column Start/End */
.grid-cs-9 {
  grid-column-start: 9;
}

.grid-ce-9 {
  grid-column-end: 10;
}

/* Cell Row Start/End */
.grid-rs-9 {
  grid-row-start: 9;
}

.grid-re-9 {
  grid-row-end: 10;
}

/* Templates */
.grid-cols-10 {
  --grid-template-column: repeat(10, minmax(0, 1fr));
}

/* Column expansion */
.grid-c-10 {
  grid-column: span 10/span 10;
}

/* Row expansion */
.grid-r-10 {
  grid-row: span 10/span 10;
}

/* Cell Column Start/End */
.grid-cs-10 {
  grid-column-start: 10;
}

.grid-ce-10 {
  grid-column-end: 11;
}

/* Cell Row Start/End */
.grid-rs-10 {
  grid-row-start: 10;
}

.grid-re-10 {
  grid-row-end: 11;
}

/* Templates */
.grid-cols-11 {
  --grid-template-column: repeat(11, minmax(0, 1fr));
}

/* Column expansion */
.grid-c-11 {
  grid-column: span 11/span 11;
}

/* Row expansion */
.grid-r-11 {
  grid-row: span 11/span 11;
}

/* Cell Column Start/End */
.grid-cs-11 {
  grid-column-start: 11;
}

.grid-ce-11 {
  grid-column-end: 12;
}

/* Cell Row Start/End */
.grid-rs-11 {
  grid-row-start: 11;
}

.grid-re-11 {
  grid-row-end: 12;
}

/* Templates */
.grid-cols-12 {
  --grid-template-column: repeat(12, minmax(0, 1fr));
}

/* Column expansion */
.grid-c-12 {
  grid-column: span 12/span 12;
}

/* Row expansion */
.grid-r-12 {
  grid-row: span 12/span 12;
}

/* Cell Column Start/End */
.grid-cs-12 {
  grid-column-start: 12;
}

.grid-ce-12 {
  grid-column-end: 13;
}

/* Cell Row Start/End */
.grid-rs-12 {
  grid-row-start: 12;
}

.grid-re-12 {
  grid-row-end: 13;
}

.grid-ce-end {
  grid-column-end: -1;
}

.grid-re-end {
  grid-row-end: -1;
}

.grid-ce-auto {
  grid-column-end: auto;
}

.grid-re-auto {
  grid-row-end: auto;
}

@media screen and (min-width: 640px) {
  .grid-sm {
    display: grid;
    grid-gap: var(--grid-gap);
    grid-template-columns: var(--grid-template-column);
  }
  /* Templates */
  .grid-cols-1-sm {
    --grid-template-column: repeat(1, minmax(0, 1fr));
  }
  /* Column expansion */
  .grid-c-1-sm {
    grid-column: span 1/span 1;
  }
  /* Row expansion */
  .grid-r-1-sm {
    grid-row: span 1/span 1;
  }
  /* Cell Column Start/End */
  .grid-cs-1-sm {
    grid-column-start: 1;
  }
  .grid-ce-1-sm {
    grid-column-end: 2;
  }
  /* Cell Row Start/End */
  .grid-rs-1-sm {
    grid-row-start: 1;
  }
  .grid-re-1-sm {
    grid-row-end: 2;
  }
  /* Templates */
  .grid-cols-2-sm {
    --grid-template-column: repeat(2, minmax(0, 1fr));
  }
  /* Column expansion */
  .grid-c-2-sm {
    grid-column: span 2/span 2;
  }
  /* Row expansion */
  .grid-r-2-sm {
    grid-row: span 2/span 2;
  }
  /* Cell Column Start/End */
  .grid-cs-2-sm {
    grid-column-start: 2;
  }
  .grid-ce-2-sm {
    grid-column-end: 3;
  }
  /* Cell Row Start/End */
  .grid-rs-2-sm {
    grid-row-start: 2;
  }
  .grid-re-2-sm {
    grid-row-end: 3;
  }
  /* Templates */
  .grid-cols-3-sm {
    --grid-template-column: repeat(3, minmax(0, 1fr));
  }
  /* Column expansion */
  .grid-c-3-sm {
    grid-column: span 3/span 3;
  }
  /* Row expansion */
  .grid-r-3-sm {
    grid-row: span 3/span 3;
  }
  /* Cell Column Start/End */
  .grid-cs-3-sm {
    grid-column-start: 3;
  }
  .grid-ce-3-sm {
    grid-column-end: 4;
  }
  /* Cell Row Start/End */
  .grid-rs-3-sm {
    grid-row-start: 3;
  }
  .grid-re-3-sm {
    grid-row-end: 4;
  }
  /* Templates */
  .grid-cols-4-sm {
    --grid-template-column: repeat(4, minmax(0, 1fr));
  }
  /* Column expansion */
  .grid-c-4-sm {
    grid-column: span 4/span 4;
  }
  /* Row expansion */
  .grid-r-4-sm {
    grid-row: span 4/span 4;
  }
  /* Cell Column Start/End */
  .grid-cs-4-sm {
    grid-column-start: 4;
  }
  .grid-ce-4-sm {
    grid-column-end: 5;
  }
  /* Cell Row Start/End */
  .grid-rs-4-sm {
    grid-row-start: 4;
  }
  .grid-re-4-sm {
    grid-row-end: 5;
  }
  /* Templates */
  .grid-cols-5-sm {
    --grid-template-column: repeat(5, minmax(0, 1fr));
  }
  /* Column expansion */
  .grid-c-5-sm {
    grid-column: span 5/span 5;
  }
  /* Row expansion */
  .grid-r-5-sm {
    grid-row: span 5/span 5;
  }
  /* Cell Column Start/End */
  .grid-cs-5-sm {
    grid-column-start: 5;
  }
  .grid-ce-5-sm {
    grid-column-end: 6;
  }
  /* Cell Row Start/End */
  .grid-rs-5-sm {
    grid-row-start: 5;
  }
  .grid-re-5-sm {
    grid-row-end: 6;
  }
  /* Templates */
  .grid-cols-6-sm {
    --grid-template-column: repeat(6, minmax(0, 1fr));
  }
  /* Column expansion */
  .grid-c-6-sm {
    grid-column: span 6/span 6;
  }
  /* Row expansion */
  .grid-r-6-sm {
    grid-row: span 6/span 6;
  }
  /* Cell Column Start/End */
  .grid-cs-6-sm {
    grid-column-start: 6;
  }
  .grid-ce-6-sm {
    grid-column-end: 7;
  }
  /* Cell Row Start/End */
  .grid-rs-6-sm {
    grid-row-start: 6;
  }
  .grid-re-6-sm {
    grid-row-end: 7;
  }
  /* Templates */
  .grid-cols-7-sm {
    --grid-template-column: repeat(7, minmax(0, 1fr));
  }
  /* Column expansion */
  .grid-c-7-sm {
    grid-column: span 7/span 7;
  }
  /* Row expansion */
  .grid-r-7-sm {
    grid-row: span 7/span 7;
  }
  /* Cell Column Start/End */
  .grid-cs-7-sm {
    grid-column-start: 7;
  }
  .grid-ce-7-sm {
    grid-column-end: 8;
  }
  /* Cell Row Start/End */
  .grid-rs-7-sm {
    grid-row-start: 7;
  }
  .grid-re-7-sm {
    grid-row-end: 8;
  }
  /* Templates */
  .grid-cols-8-sm {
    --grid-template-column: repeat(8, minmax(0, 1fr));
  }
  /* Column expansion */
  .grid-c-8-sm {
    grid-column: span 8/span 8;
  }
  /* Row expansion */
  .grid-r-8-sm {
    grid-row: span 8/span 8;
  }
  /* Cell Column Start/End */
  .grid-cs-8-sm {
    grid-column-start: 8;
  }
  .grid-ce-8-sm {
    grid-column-end: 9;
  }
  /* Cell Row Start/End */
  .grid-rs-8-sm {
    grid-row-start: 8;
  }
  .grid-re-8-sm {
    grid-row-end: 9;
  }
  /* Templates */
  .grid-cols-9-sm {
    --grid-template-column: repeat(9, minmax(0, 1fr));
  }
  /* Column expansion */
  .grid-c-9-sm {
    grid-column: span 9/span 9;
  }
  /* Row expansion */
  .grid-r-9-sm {
    grid-row: span 9/span 9;
  }
  /* Cell Column Start/End */
  .grid-cs-9-sm {
    grid-column-start: 9;
  }
  .grid-ce-9-sm {
    grid-column-end: 10;
  }
  /* Cell Row Start/End */
  .grid-rs-9-sm {
    grid-row-start: 9;
  }
  .grid-re-9-sm {
    grid-row-end: 10;
  }
  /* Templates */
  .grid-cols-10-sm {
    --grid-template-column: repeat(10, minmax(0, 1fr));
  }
  /* Column expansion */
  .grid-c-10-sm {
    grid-column: span 10/span 10;
  }
  /* Row expansion */
  .grid-r-10-sm {
    grid-row: span 10/span 10;
  }
  /* Cell Column Start/End */
  .grid-cs-10-sm {
    grid-column-start: 10;
  }
  .grid-ce-10-sm {
    grid-column-end: 11;
  }
  /* Cell Row Start/End */
  .grid-rs-10-sm {
    grid-row-start: 10;
  }
  .grid-re-10-sm {
    grid-row-end: 11;
  }
  /* Templates */
  .grid-cols-11-sm {
    --grid-template-column: repeat(11, minmax(0, 1fr));
  }
  /* Column expansion */
  .grid-c-11-sm {
    grid-column: span 11/span 11;
  }
  /* Row expansion */
  .grid-r-11-sm {
    grid-row: span 11/span 11;
  }
  /* Cell Column Start/End */
  .grid-cs-11-sm {
    grid-column-start: 11;
  }
  .grid-ce-11-sm {
    grid-column-end: 12;
  }
  /* Cell Row Start/End */
  .grid-rs-11-sm {
    grid-row-start: 11;
  }
  .grid-re-11-sm {
    grid-row-end: 12;
  }
  /* Templates */
  .grid-cols-12-sm {
    --grid-template-column: repeat(12, minmax(0, 1fr));
  }
  /* Column expansion */
  .grid-c-12-sm {
    grid-column: span 12/span 12;
  }
  /* Row expansion */
  .grid-r-12-sm {
    grid-row: span 12/span 12;
  }
  /* Cell Column Start/End */
  .grid-cs-12-sm {
    grid-column-start: 12;
  }
  .grid-ce-12-sm {
    grid-column-end: 13;
  }
  /* Cell Row Start/End */
  .grid-rs-12-sm {
    grid-row-start: 12;
  }
  .grid-re-12-sm {
    grid-row-end: 13;
  }
  .grid-ce-end-sm {
    grid-column-end: -1;
  }
  .grid-re-end-sm {
    grid-row-end: -1;
  }
  .grid-ce-auto-sm {
    grid-column-end: auto;
  }
  .grid-re-auto-sm {
    grid-row-end: auto;
  }
}
@media screen and (min-width: 768px) {
  .grid-md {
    display: grid;
    grid-gap: var(--grid-gap);
    grid-template-columns: var(--grid-template-column);
  }
  /* Templates */
  .grid-cols-1-md {
    --grid-template-column: repeat(1, minmax(0, 1fr));
  }
  /* Column expansion */
  .grid-c-1-md {
    grid-column: span 1/span 1;
  }
  /* Row expansion */
  .grid-r-1-md {
    grid-row: span 1/span 1;
  }
  /* Cell Column Start/End */
  .grid-cs-1-md {
    grid-column-start: 1;
  }
  .grid-ce-1-md {
    grid-column-end: 2;
  }
  /* Cell Row Start/End */
  .grid-rs-1-md {
    grid-row-start: 1;
  }
  .grid-re-1-md {
    grid-row-end: 2;
  }
  /* Templates */
  .grid-cols-2-md {
    --grid-template-column: repeat(2, minmax(0, 1fr));
  }
  /* Column expansion */
  .grid-c-2-md {
    grid-column: span 2/span 2;
  }
  /* Row expansion */
  .grid-r-2-md {
    grid-row: span 2/span 2;
  }
  /* Cell Column Start/End */
  .grid-cs-2-md {
    grid-column-start: 2;
  }
  .grid-ce-2-md {
    grid-column-end: 3;
  }
  /* Cell Row Start/End */
  .grid-rs-2-md {
    grid-row-start: 2;
  }
  .grid-re-2-md {
    grid-row-end: 3;
  }
  /* Templates */
  .grid-cols-3-md {
    --grid-template-column: repeat(3, minmax(0, 1fr));
  }
  /* Column expansion */
  .grid-c-3-md {
    grid-column: span 3/span 3;
  }
  /* Row expansion */
  .grid-r-3-md {
    grid-row: span 3/span 3;
  }
  /* Cell Column Start/End */
  .grid-cs-3-md {
    grid-column-start: 3;
  }
  .grid-ce-3-md {
    grid-column-end: 4;
  }
  /* Cell Row Start/End */
  .grid-rs-3-md {
    grid-row-start: 3;
  }
  .grid-re-3-md {
    grid-row-end: 4;
  }
  /* Templates */
  .grid-cols-4-md {
    --grid-template-column: repeat(4, minmax(0, 1fr));
  }
  /* Column expansion */
  .grid-c-4-md {
    grid-column: span 4/span 4;
  }
  /* Row expansion */
  .grid-r-4-md {
    grid-row: span 4/span 4;
  }
  /* Cell Column Start/End */
  .grid-cs-4-md {
    grid-column-start: 4;
  }
  .grid-ce-4-md {
    grid-column-end: 5;
  }
  /* Cell Row Start/End */
  .grid-rs-4-md {
    grid-row-start: 4;
  }
  .grid-re-4-md {
    grid-row-end: 5;
  }
  /* Templates */
  .grid-cols-5-md {
    --grid-template-column: repeat(5, minmax(0, 1fr));
  }
  /* Column expansion */
  .grid-c-5-md {
    grid-column: span 5/span 5;
  }
  /* Row expansion */
  .grid-r-5-md {
    grid-row: span 5/span 5;
  }
  /* Cell Column Start/End */
  .grid-cs-5-md {
    grid-column-start: 5;
  }
  .grid-ce-5-md {
    grid-column-end: 6;
  }
  /* Cell Row Start/End */
  .grid-rs-5-md {
    grid-row-start: 5;
  }
  .grid-re-5-md {
    grid-row-end: 6;
  }
  /* Templates */
  .grid-cols-6-md {
    --grid-template-column: repeat(6, minmax(0, 1fr));
  }
  /* Column expansion */
  .grid-c-6-md {
    grid-column: span 6/span 6;
  }
  /* Row expansion */
  .grid-r-6-md {
    grid-row: span 6/span 6;
  }
  /* Cell Column Start/End */
  .grid-cs-6-md {
    grid-column-start: 6;
  }
  .grid-ce-6-md {
    grid-column-end: 7;
  }
  /* Cell Row Start/End */
  .grid-rs-6-md {
    grid-row-start: 6;
  }
  .grid-re-6-md {
    grid-row-end: 7;
  }
  /* Templates */
  .grid-cols-7-md {
    --grid-template-column: repeat(7, minmax(0, 1fr));
  }
  /* Column expansion */
  .grid-c-7-md {
    grid-column: span 7/span 7;
  }
  /* Row expansion */
  .grid-r-7-md {
    grid-row: span 7/span 7;
  }
  /* Cell Column Start/End */
  .grid-cs-7-md {
    grid-column-start: 7;
  }
  .grid-ce-7-md {
    grid-column-end: 8;
  }
  /* Cell Row Start/End */
  .grid-rs-7-md {
    grid-row-start: 7;
  }
  .grid-re-7-md {
    grid-row-end: 8;
  }
  /* Templates */
  .grid-cols-8-md {
    --grid-template-column: repeat(8, minmax(0, 1fr));
  }
  /* Column expansion */
  .grid-c-8-md {
    grid-column: span 8/span 8;
  }
  /* Row expansion */
  .grid-r-8-md {
    grid-row: span 8/span 8;
  }
  /* Cell Column Start/End */
  .grid-cs-8-md {
    grid-column-start: 8;
  }
  .grid-ce-8-md {
    grid-column-end: 9;
  }
  /* Cell Row Start/End */
  .grid-rs-8-md {
    grid-row-start: 8;
  }
  .grid-re-8-md {
    grid-row-end: 9;
  }
  /* Templates */
  .grid-cols-9-md {
    --grid-template-column: repeat(9, minmax(0, 1fr));
  }
  /* Column expansion */
  .grid-c-9-md {
    grid-column: span 9/span 9;
  }
  /* Row expansion */
  .grid-r-9-md {
    grid-row: span 9/span 9;
  }
  /* Cell Column Start/End */
  .grid-cs-9-md {
    grid-column-start: 9;
  }
  .grid-ce-9-md {
    grid-column-end: 10;
  }
  /* Cell Row Start/End */
  .grid-rs-9-md {
    grid-row-start: 9;
  }
  .grid-re-9-md {
    grid-row-end: 10;
  }
  /* Templates */
  .grid-cols-10-md {
    --grid-template-column: repeat(10, minmax(0, 1fr));
  }
  /* Column expansion */
  .grid-c-10-md {
    grid-column: span 10/span 10;
  }
  /* Row expansion */
  .grid-r-10-md {
    grid-row: span 10/span 10;
  }
  /* Cell Column Start/End */
  .grid-cs-10-md {
    grid-column-start: 10;
  }
  .grid-ce-10-md {
    grid-column-end: 11;
  }
  /* Cell Row Start/End */
  .grid-rs-10-md {
    grid-row-start: 10;
  }
  .grid-re-10-md {
    grid-row-end: 11;
  }
  /* Templates */
  .grid-cols-11-md {
    --grid-template-column: repeat(11, minmax(0, 1fr));
  }
  /* Column expansion */
  .grid-c-11-md {
    grid-column: span 11/span 11;
  }
  /* Row expansion */
  .grid-r-11-md {
    grid-row: span 11/span 11;
  }
  /* Cell Column Start/End */
  .grid-cs-11-md {
    grid-column-start: 11;
  }
  .grid-ce-11-md {
    grid-column-end: 12;
  }
  /* Cell Row Start/End */
  .grid-rs-11-md {
    grid-row-start: 11;
  }
  .grid-re-11-md {
    grid-row-end: 12;
  }
  /* Templates */
  .grid-cols-12-md {
    --grid-template-column: repeat(12, minmax(0, 1fr));
  }
  /* Column expansion */
  .grid-c-12-md {
    grid-column: span 12/span 12;
  }
  /* Row expansion */
  .grid-r-12-md {
    grid-row: span 12/span 12;
  }
  /* Cell Column Start/End */
  .grid-cs-12-md {
    grid-column-start: 12;
  }
  .grid-ce-12-md {
    grid-column-end: 13;
  }
  /* Cell Row Start/End */
  .grid-rs-12-md {
    grid-row-start: 12;
  }
  .grid-re-12-md {
    grid-row-end: 13;
  }
  .grid-ce-end-md {
    grid-column-end: -1;
  }
  .grid-re-end-md {
    grid-row-end: -1;
  }
  .grid-ce-auto-md {
    grid-column-end: auto;
  }
  .grid-re-auto-md {
    grid-row-end: auto;
  }
}
@media screen and (min-width: 1024px) {
  .grid-lg {
    display: grid;
    grid-gap: var(--grid-gap);
    grid-template-columns: var(--grid-template-column);
  }
  /* Templates */
  .grid-cols-1-lg {
    --grid-template-column: repeat(1, minmax(0, 1fr));
  }
  /* Column expansion */
  .grid-c-1-lg {
    grid-column: span 1/span 1;
  }
  /* Row expansion */
  .grid-r-1-lg {
    grid-row: span 1/span 1;
  }
  /* Cell Column Start/End */
  .grid-cs-1-lg {
    grid-column-start: 1;
  }
  .grid-ce-1-lg {
    grid-column-end: 2;
  }
  /* Cell Row Start/End */
  .grid-rs-1-lg {
    grid-row-start: 1;
  }
  .grid-re-1-lg {
    grid-row-end: 2;
  }
  /* Templates */
  .grid-cols-2-lg {
    --grid-template-column: repeat(2, minmax(0, 1fr));
  }
  /* Column expansion */
  .grid-c-2-lg {
    grid-column: span 2/span 2;
  }
  /* Row expansion */
  .grid-r-2-lg {
    grid-row: span 2/span 2;
  }
  /* Cell Column Start/End */
  .grid-cs-2-lg {
    grid-column-start: 2;
  }
  .grid-ce-2-lg {
    grid-column-end: 3;
  }
  /* Cell Row Start/End */
  .grid-rs-2-lg {
    grid-row-start: 2;
  }
  .grid-re-2-lg {
    grid-row-end: 3;
  }
  /* Templates */
  .grid-cols-3-lg {
    --grid-template-column: repeat(3, minmax(0, 1fr));
  }
  /* Column expansion */
  .grid-c-3-lg {
    grid-column: span 3/span 3;
  }
  /* Row expansion */
  .grid-r-3-lg {
    grid-row: span 3/span 3;
  }
  /* Cell Column Start/End */
  .grid-cs-3-lg {
    grid-column-start: 3;
  }
  .grid-ce-3-lg {
    grid-column-end: 4;
  }
  /* Cell Row Start/End */
  .grid-rs-3-lg {
    grid-row-start: 3;
  }
  .grid-re-3-lg {
    grid-row-end: 4;
  }
  /* Templates */
  .grid-cols-4-lg {
    --grid-template-column: repeat(4, minmax(0, 1fr));
  }
  /* Column expansion */
  .grid-c-4-lg {
    grid-column: span 4/span 4;
  }
  /* Row expansion */
  .grid-r-4-lg {
    grid-row: span 4/span 4;
  }
  /* Cell Column Start/End */
  .grid-cs-4-lg {
    grid-column-start: 4;
  }
  .grid-ce-4-lg {
    grid-column-end: 5;
  }
  /* Cell Row Start/End */
  .grid-rs-4-lg {
    grid-row-start: 4;
  }
  .grid-re-4-lg {
    grid-row-end: 5;
  }
  /* Templates */
  .grid-cols-5-lg {
    --grid-template-column: repeat(5, minmax(0, 1fr));
  }
  /* Column expansion */
  .grid-c-5-lg {
    grid-column: span 5/span 5;
  }
  /* Row expansion */
  .grid-r-5-lg {
    grid-row: span 5/span 5;
  }
  /* Cell Column Start/End */
  .grid-cs-5-lg {
    grid-column-start: 5;
  }
  .grid-ce-5-lg {
    grid-column-end: 6;
  }
  /* Cell Row Start/End */
  .grid-rs-5-lg {
    grid-row-start: 5;
  }
  .grid-re-5-lg {
    grid-row-end: 6;
  }
  /* Templates */
  .grid-cols-6-lg {
    --grid-template-column: repeat(6, minmax(0, 1fr));
  }
  /* Column expansion */
  .grid-c-6-lg {
    grid-column: span 6/span 6;
  }
  /* Row expansion */
  .grid-r-6-lg {
    grid-row: span 6/span 6;
  }
  /* Cell Column Start/End */
  .grid-cs-6-lg {
    grid-column-start: 6;
  }
  .grid-ce-6-lg {
    grid-column-end: 7;
  }
  /* Cell Row Start/End */
  .grid-rs-6-lg {
    grid-row-start: 6;
  }
  .grid-re-6-lg {
    grid-row-end: 7;
  }
  /* Templates */
  .grid-cols-7-lg {
    --grid-template-column: repeat(7, minmax(0, 1fr));
  }
  /* Column expansion */
  .grid-c-7-lg {
    grid-column: span 7/span 7;
  }
  /* Row expansion */
  .grid-r-7-lg {
    grid-row: span 7/span 7;
  }
  /* Cell Column Start/End */
  .grid-cs-7-lg {
    grid-column-start: 7;
  }
  .grid-ce-7-lg {
    grid-column-end: 8;
  }
  /* Cell Row Start/End */
  .grid-rs-7-lg {
    grid-row-start: 7;
  }
  .grid-re-7-lg {
    grid-row-end: 8;
  }
  /* Templates */
  .grid-cols-8-lg {
    --grid-template-column: repeat(8, minmax(0, 1fr));
  }
  /* Column expansion */
  .grid-c-8-lg {
    grid-column: span 8/span 8;
  }
  /* Row expansion */
  .grid-r-8-lg {
    grid-row: span 8/span 8;
  }
  /* Cell Column Start/End */
  .grid-cs-8-lg {
    grid-column-start: 8;
  }
  .grid-ce-8-lg {
    grid-column-end: 9;
  }
  /* Cell Row Start/End */
  .grid-rs-8-lg {
    grid-row-start: 8;
  }
  .grid-re-8-lg {
    grid-row-end: 9;
  }
  /* Templates */
  .grid-cols-9-lg {
    --grid-template-column: repeat(9, minmax(0, 1fr));
  }
  /* Column expansion */
  .grid-c-9-lg {
    grid-column: span 9/span 9;
  }
  /* Row expansion */
  .grid-r-9-lg {
    grid-row: span 9/span 9;
  }
  /* Cell Column Start/End */
  .grid-cs-9-lg {
    grid-column-start: 9;
  }
  .grid-ce-9-lg {
    grid-column-end: 10;
  }
  /* Cell Row Start/End */
  .grid-rs-9-lg {
    grid-row-start: 9;
  }
  .grid-re-9-lg {
    grid-row-end: 10;
  }
  /* Templates */
  .grid-cols-10-lg {
    --grid-template-column: repeat(10, minmax(0, 1fr));
  }
  /* Column expansion */
  .grid-c-10-lg {
    grid-column: span 10/span 10;
  }
  /* Row expansion */
  .grid-r-10-lg {
    grid-row: span 10/span 10;
  }
  /* Cell Column Start/End */
  .grid-cs-10-lg {
    grid-column-start: 10;
  }
  .grid-ce-10-lg {
    grid-column-end: 11;
  }
  /* Cell Row Start/End */
  .grid-rs-10-lg {
    grid-row-start: 10;
  }
  .grid-re-10-lg {
    grid-row-end: 11;
  }
  /* Templates */
  .grid-cols-11-lg {
    --grid-template-column: repeat(11, minmax(0, 1fr));
  }
  /* Column expansion */
  .grid-c-11-lg {
    grid-column: span 11/span 11;
  }
  /* Row expansion */
  .grid-r-11-lg {
    grid-row: span 11/span 11;
  }
  /* Cell Column Start/End */
  .grid-cs-11-lg {
    grid-column-start: 11;
  }
  .grid-ce-11-lg {
    grid-column-end: 12;
  }
  /* Cell Row Start/End */
  .grid-rs-11-lg {
    grid-row-start: 11;
  }
  .grid-re-11-lg {
    grid-row-end: 12;
  }
  /* Templates */
  .grid-cols-12-lg {
    --grid-template-column: repeat(12, minmax(0, 1fr));
  }
  /* Column expansion */
  .grid-c-12-lg {
    grid-column: span 12/span 12;
  }
  /* Row expansion */
  .grid-r-12-lg {
    grid-row: span 12/span 12;
  }
  /* Cell Column Start/End */
  .grid-cs-12-lg {
    grid-column-start: 12;
  }
  .grid-ce-12-lg {
    grid-column-end: 13;
  }
  /* Cell Row Start/End */
  .grid-rs-12-lg {
    grid-row-start: 12;
  }
  .grid-re-12-lg {
    grid-row-end: 13;
  }
  .grid-ce-end-lg {
    grid-column-end: -1;
  }
  .grid-re-end-lg {
    grid-row-end: -1;
  }
  .grid-ce-auto-lg {
    grid-column-end: auto;
  }
  .grid-re-auto-lg {
    grid-row-end: auto;
  }
}
@media screen and (min-width: 1280px) {
  .grid-xl {
    display: grid;
    grid-gap: var(--grid-gap);
    grid-template-columns: var(--grid-template-column);
  }
  /* Templates */
  .grid-cols-1-xl {
    --grid-template-column: repeat(1, minmax(0, 1fr));
  }
  /* Column expansion */
  .grid-c-1-xl {
    grid-column: span 1/span 1;
  }
  /* Row expansion */
  .grid-r-1-xl {
    grid-row: span 1/span 1;
  }
  /* Cell Column Start/End */
  .grid-cs-1-xl {
    grid-column-start: 1;
  }
  .grid-ce-1-xl {
    grid-column-end: 2;
  }
  /* Cell Row Start/End */
  .grid-rs-1-xl {
    grid-row-start: 1;
  }
  .grid-re-1-xl {
    grid-row-end: 2;
  }
  /* Templates */
  .grid-cols-2-xl {
    --grid-template-column: repeat(2, minmax(0, 1fr));
  }
  /* Column expansion */
  .grid-c-2-xl {
    grid-column: span 2/span 2;
  }
  /* Row expansion */
  .grid-r-2-xl {
    grid-row: span 2/span 2;
  }
  /* Cell Column Start/End */
  .grid-cs-2-xl {
    grid-column-start: 2;
  }
  .grid-ce-2-xl {
    grid-column-end: 3;
  }
  /* Cell Row Start/End */
  .grid-rs-2-xl {
    grid-row-start: 2;
  }
  .grid-re-2-xl {
    grid-row-end: 3;
  }
  /* Templates */
  .grid-cols-3-xl {
    --grid-template-column: repeat(3, minmax(0, 1fr));
  }
  /* Column expansion */
  .grid-c-3-xl {
    grid-column: span 3/span 3;
  }
  /* Row expansion */
  .grid-r-3-xl {
    grid-row: span 3/span 3;
  }
  /* Cell Column Start/End */
  .grid-cs-3-xl {
    grid-column-start: 3;
  }
  .grid-ce-3-xl {
    grid-column-end: 4;
  }
  /* Cell Row Start/End */
  .grid-rs-3-xl {
    grid-row-start: 3;
  }
  .grid-re-3-xl {
    grid-row-end: 4;
  }
  /* Templates */
  .grid-cols-4-xl {
    --grid-template-column: repeat(4, minmax(0, 1fr));
  }
  /* Column expansion */
  .grid-c-4-xl {
    grid-column: span 4/span 4;
  }
  /* Row expansion */
  .grid-r-4-xl {
    grid-row: span 4/span 4;
  }
  /* Cell Column Start/End */
  .grid-cs-4-xl {
    grid-column-start: 4;
  }
  .grid-ce-4-xl {
    grid-column-end: 5;
  }
  /* Cell Row Start/End */
  .grid-rs-4-xl {
    grid-row-start: 4;
  }
  .grid-re-4-xl {
    grid-row-end: 5;
  }
  /* Templates */
  .grid-cols-5-xl {
    --grid-template-column: repeat(5, minmax(0, 1fr));
  }
  /* Column expansion */
  .grid-c-5-xl {
    grid-column: span 5/span 5;
  }
  /* Row expansion */
  .grid-r-5-xl {
    grid-row: span 5/span 5;
  }
  /* Cell Column Start/End */
  .grid-cs-5-xl {
    grid-column-start: 5;
  }
  .grid-ce-5-xl {
    grid-column-end: 6;
  }
  /* Cell Row Start/End */
  .grid-rs-5-xl {
    grid-row-start: 5;
  }
  .grid-re-5-xl {
    grid-row-end: 6;
  }
  /* Templates */
  .grid-cols-6-xl {
    --grid-template-column: repeat(6, minmax(0, 1fr));
  }
  /* Column expansion */
  .grid-c-6-xl {
    grid-column: span 6/span 6;
  }
  /* Row expansion */
  .grid-r-6-xl {
    grid-row: span 6/span 6;
  }
  /* Cell Column Start/End */
  .grid-cs-6-xl {
    grid-column-start: 6;
  }
  .grid-ce-6-xl {
    grid-column-end: 7;
  }
  /* Cell Row Start/End */
  .grid-rs-6-xl {
    grid-row-start: 6;
  }
  .grid-re-6-xl {
    grid-row-end: 7;
  }
  /* Templates */
  .grid-cols-7-xl {
    --grid-template-column: repeat(7, minmax(0, 1fr));
  }
  /* Column expansion */
  .grid-c-7-xl {
    grid-column: span 7/span 7;
  }
  /* Row expansion */
  .grid-r-7-xl {
    grid-row: span 7/span 7;
  }
  /* Cell Column Start/End */
  .grid-cs-7-xl {
    grid-column-start: 7;
  }
  .grid-ce-7-xl {
    grid-column-end: 8;
  }
  /* Cell Row Start/End */
  .grid-rs-7-xl {
    grid-row-start: 7;
  }
  .grid-re-7-xl {
    grid-row-end: 8;
  }
  /* Templates */
  .grid-cols-8-xl {
    --grid-template-column: repeat(8, minmax(0, 1fr));
  }
  /* Column expansion */
  .grid-c-8-xl {
    grid-column: span 8/span 8;
  }
  /* Row expansion */
  .grid-r-8-xl {
    grid-row: span 8/span 8;
  }
  /* Cell Column Start/End */
  .grid-cs-8-xl {
    grid-column-start: 8;
  }
  .grid-ce-8-xl {
    grid-column-end: 9;
  }
  /* Cell Row Start/End */
  .grid-rs-8-xl {
    grid-row-start: 8;
  }
  .grid-re-8-xl {
    grid-row-end: 9;
  }
  /* Templates */
  .grid-cols-9-xl {
    --grid-template-column: repeat(9, minmax(0, 1fr));
  }
  /* Column expansion */
  .grid-c-9-xl {
    grid-column: span 9/span 9;
  }
  /* Row expansion */
  .grid-r-9-xl {
    grid-row: span 9/span 9;
  }
  /* Cell Column Start/End */
  .grid-cs-9-xl {
    grid-column-start: 9;
  }
  .grid-ce-9-xl {
    grid-column-end: 10;
  }
  /* Cell Row Start/End */
  .grid-rs-9-xl {
    grid-row-start: 9;
  }
  .grid-re-9-xl {
    grid-row-end: 10;
  }
  /* Templates */
  .grid-cols-10-xl {
    --grid-template-column: repeat(10, minmax(0, 1fr));
  }
  /* Column expansion */
  .grid-c-10-xl {
    grid-column: span 10/span 10;
  }
  /* Row expansion */
  .grid-r-10-xl {
    grid-row: span 10/span 10;
  }
  /* Cell Column Start/End */
  .grid-cs-10-xl {
    grid-column-start: 10;
  }
  .grid-ce-10-xl {
    grid-column-end: 11;
  }
  /* Cell Row Start/End */
  .grid-rs-10-xl {
    grid-row-start: 10;
  }
  .grid-re-10-xl {
    grid-row-end: 11;
  }
  /* Templates */
  .grid-cols-11-xl {
    --grid-template-column: repeat(11, minmax(0, 1fr));
  }
  /* Column expansion */
  .grid-c-11-xl {
    grid-column: span 11/span 11;
  }
  /* Row expansion */
  .grid-r-11-xl {
    grid-row: span 11/span 11;
  }
  /* Cell Column Start/End */
  .grid-cs-11-xl {
    grid-column-start: 11;
  }
  .grid-ce-11-xl {
    grid-column-end: 12;
  }
  /* Cell Row Start/End */
  .grid-rs-11-xl {
    grid-row-start: 11;
  }
  .grid-re-11-xl {
    grid-row-end: 12;
  }
  /* Templates */
  .grid-cols-12-xl {
    --grid-template-column: repeat(12, minmax(0, 1fr));
  }
  /* Column expansion */
  .grid-c-12-xl {
    grid-column: span 12/span 12;
  }
  /* Row expansion */
  .grid-r-12-xl {
    grid-row: span 12/span 12;
  }
  /* Cell Column Start/End */
  .grid-cs-12-xl {
    grid-column-start: 12;
  }
  .grid-ce-12-xl {
    grid-column-end: 13;
  }
  /* Cell Row Start/End */
  .grid-rs-12-xl {
    grid-row-start: 12;
  }
  .grid-re-12-xl {
    grid-row-end: 13;
  }
  .grid-ce-end-xl {
    grid-column-end: -1;
  }
  .grid-re-end-xl {
    grid-row-end: -1;
  }
  .grid-ce-auto-xl {
    grid-column-end: auto;
  }
  .grid-re-auto-xl {
    grid-row-end: auto;
  }
}
/* HEADER */
/* This works well with panel */
section {
  display: block;
}

.content {
  max-width: 48em;
  margin: 0 auto 1.5em;
  width: 100%;
}

@media screen and (min-width: 1024px) {
  .content {
    max-width: 64em;
  }
}
@media screen and (min-width: 1280px) {
  .content {
    max-width: 80em;
  }
}
@media screen and (min-width: 1536px) {
  .content {
    max-width: 96em;
  }
}
.fullscreen {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  min-height: 100vh;
}

/* This works well with spans and maybe even hr */
.divider {
  border-top: 0.05rem solid rgba(173, 181, 189, 0.5);
  height: 0.1rem;
  margin: 1.8rem 0 1.6rem;
  position: relative;
}

.divider[data-content] {
  margin: 0.8rem 0;
}

.divider--v[data-content]::after, .divider[data-content]::after {
  background: #fff;
  color: #adb5bd;
  content: attr(data-content);
  left: 50%;
  display: inline-block;
  padding: 0 0.4rem;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
}

.divider--v, .divider--v[data-content] {
  display: block;
  padding: 0.8rem;
}

.divider--v::before, .divider--v[data-content]::before {
  border-left: 0.05rem solid rgba(173, 181, 189, 0.5);
  bottom: 0.4rem;
  content: "";
  display: block;
  left: 50%;
  position: absolute;
  top: 0;
  transform: translateX(-50%);
}

.divider--v[data-content] {
  left: 50%;
  padding: 0.2rem 0;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}

/* Hero image div */
.hero-img {
  /* Specify the backgroud image yourself */
  background-size: cover;
}

.parallax-img {
  background-attachment: fixed !important;
}

.hero {
  /* Parent of hero-body */
  align-items: stretch;
  display: flex; /* Important to stretch height of hero-body */
  justify-content: space-between;
}

.hero .hero-body {
  flex-grow: 1; /* Tells the hero-body to take up the entire space */
  flex-shrink: 0;
  padding: 3rem 1.5rem;
  align-items: center;
  display: flex;
  width: 100%;
}

.space {
  display: block;
  width: 100%;
  height: 1rem;
}

.space.space--lg {
  padding: 1rem 0;
}

.space.space--xl {
  padding: 2rem 0;
}

/* Use for spacing out elements vertically */
.row {
  flex: 1;
  flex-wrap: wrap;
  padding: 0.5rem 0;
  display: flex;
  /* GRID */
  /* Auto align col to left in row */
  /* Auto align col to middle in row */
  /* Auto align col to right in row */
  /* Dividers for mobile layout */
  /* Base sizing where everything is 100% width */
  /* Column sizes for various viewports */
  /* Columns without the spacing */
}

.row::after {
  content: "";
  clear: both;
  display: table;
}

.row.row--no-wrap {
  flex-wrap: nowrap;
  overflow-x: auto; /* Can be disabled to remove scroll bar */
}

.row .col {
  display: block;
  flex: 1;
  padding: 0.15rem 0.75rem;
}

.row .offset-right {
  margin-left: 0;
  margin-right: auto;
}

.row .offset-center {
  margin-left: auto;
  margin-right: auto;
}

.row .offset-left {
  margin-left: auto;
  margin-right: 0;
}

.row.divided [class^=col], .row.divided [class*=" col"] {
  box-shadow: 0 -1px 0 0 rgba(173, 181, 189, 0.5);
}

.row [class^=col-],
.row [class*=" col-"] {
  width: 100%;
  margin-left: 0;
  padding: 0 0.5rem;
}

@media screen and (min-width: 768px) {
  .row .col-1 {
    width: 8.3333333333%;
  }
}
@media screen and (min-width: 768px) {
  .row .col-2 {
    width: 16.6666666667%;
  }
}
@media screen and (min-width: 768px) {
  .row .col-3 {
    width: 25%;
  }
}
@media screen and (min-width: 768px) {
  .row .col-4 {
    width: 33.3333333333%;
  }
}
@media screen and (min-width: 768px) {
  .row .col-5 {
    width: 41.6666666667%;
  }
}
@media screen and (min-width: 768px) {
  .row .col-6 {
    width: 50%;
  }
}
@media screen and (min-width: 768px) {
  .row .col-7 {
    width: 58.3333333333%;
  }
}
@media screen and (min-width: 768px) {
  .row .col-8 {
    width: 66.6666666667%;
  }
}
@media screen and (min-width: 768px) {
  .row .col-9 {
    width: 75%;
  }
}
@media screen and (min-width: 768px) {
  .row .col-10 {
    width: 83.3333333333%;
  }
}
@media screen and (min-width: 768px) {
  .row .col-11 {
    width: 91.6666666667%;
  }
}
@media screen and (min-width: 768px) {
  .row .col-12 {
    width: 100%;
  }
}
.row .col-xs-1 {
  width: 8.3333333333%;
}

.row .col-xs-2 {
  width: 16.6666666667%;
}

.row .col-xs-3 {
  width: 25%;
}

.row .col-xs-4 {
  width: 33.3333333333%;
}

.row .col-xs-5 {
  width: 41.6666666667%;
}

.row .col-xs-6 {
  width: 50%;
}

.row .col-xs-7 {
  width: 58.3333333333%;
}

.row .col-xs-8 {
  width: 66.6666666667%;
}

.row .col-xs-9 {
  width: 75%;
}

.row .col-xs-10 {
  width: 83.3333333333%;
}

.row .col-xs-11 {
  width: 91.6666666667%;
}

.row .col-xs-12 {
  width: 100%;
}

@media screen and (min-width: 640px) {
  .row .col-sm-1 {
    width: 8.3333333333%;
  }
  .row .col-sm-2 {
    width: 16.6666666667%;
  }
  .row .col-sm-3 {
    width: 25%;
  }
  .row .col-sm-4 {
    width: 33.3333333333%;
  }
  .row .col-sm-5 {
    width: 41.6666666667%;
  }
  .row .col-sm-6 {
    width: 50%;
  }
  .row .col-sm-7 {
    width: 58.3333333333%;
  }
  .row .col-sm-8 {
    width: 66.6666666667%;
  }
  .row .col-sm-9 {
    width: 75%;
  }
  .row .col-sm-10 {
    width: 83.3333333333%;
  }
  .row .col-sm-11 {
    width: 91.6666666667%;
  }
  .row .col-sm-12 {
    width: 100%;
  }
}
@media screen and (min-width: 768px) {
  .row .col-md-1 {
    width: 8.3333333333%;
  }
  .row .col-md-2 {
    width: 16.6666666667%;
  }
  .row .col-md-3 {
    width: 25%;
  }
  .row .col-md-4 {
    width: 33.3333333333%;
  }
  .row .col-md-5 {
    width: 41.6666666667%;
  }
  .row .col-md-6 {
    width: 50%;
  }
  .row .col-md-7 {
    width: 58.3333333333%;
  }
  .row .col-md-8 {
    width: 66.6666666667%;
  }
  .row .col-md-9 {
    width: 75%;
  }
  .row .col-md-10 {
    width: 83.3333333333%;
  }
  .row .col-md-11 {
    width: 91.6666666667%;
  }
  .row .col-md-12 {
    width: 100%;
  }
}
@media screen and (min-width: 1024px) {
  .row .col-lg-1 {
    width: 8.3333333333%;
  }
  .row .col-lg-2 {
    width: 16.6666666667%;
  }
  .row .col-lg-3 {
    width: 25%;
  }
  .row .col-lg-4 {
    width: 33.3333333333%;
  }
  .row .col-lg-5 {
    width: 41.6666666667%;
  }
  .row .col-lg-6 {
    width: 50%;
  }
  .row .col-lg-7 {
    width: 58.3333333333%;
  }
  .row .col-lg-8 {
    width: 66.6666666667%;
  }
  .row .col-lg-9 {
    width: 75%;
  }
  .row .col-lg-10 {
    width: 83.3333333333%;
  }
  .row .col-lg-11 {
    width: 91.6666666667%;
  }
  .row .col-lg-12 {
    width: 100%;
  }
}
@media screen and (min-width: 1280px) {
  .row .col-xl-1 {
    width: 8.3333333333%;
  }
  .row .col-xl-2 {
    width: 16.6666666667%;
  }
  .row .col-xl-3 {
    width: 25%;
  }
  .row .col-xl-4 {
    width: 33.3333333333%;
  }
  .row .col-xl-5 {
    width: 41.6666666667%;
  }
  .row .col-xl-6 {
    width: 50%;
  }
  .row .col-xl-7 {
    width: 58.3333333333%;
  }
  .row .col-xl-8 {
    width: 66.6666666667%;
  }
  .row .col-xl-9 {
    width: 75%;
  }
  .row .col-xl-10 {
    width: 83.3333333333%;
  }
  .row .col-xl-11 {
    width: 91.6666666667%;
  }
  .row .col-xl-12 {
    width: 100%;
  }
}
@media screen and (min-width: 640px) {
  .row .offset-1 {
    margin-left: 8.3333333333%;
  }
}
@media screen and (min-width: 640px) {
  .row .offset-2 {
    margin-left: 16.6666666667%;
  }
}
@media screen and (min-width: 640px) {
  .row .offset-3 {
    margin-left: 25%;
  }
}
@media screen and (min-width: 640px) {
  .row .offset-4 {
    margin-left: 33.3333333333%;
  }
}
@media screen and (min-width: 640px) {
  .row .offset-5 {
    margin-left: 41.6666666667%;
  }
}
@media screen and (min-width: 640px) {
  .row .offset-6 {
    margin-left: 50%;
  }
}
@media screen and (min-width: 640px) {
  .row .offset-7 {
    margin-left: 58.3333333333%;
  }
}
@media screen and (min-width: 640px) {
  .row .offset-8 {
    margin-left: 66.6666666667%;
  }
}
@media screen and (min-width: 640px) {
  .row .offset-9 {
    margin-left: 75%;
  }
}
@media screen and (min-width: 640px) {
  .row .offset-10 {
    margin-left: 83.3333333333%;
  }
}
@media screen and (min-width: 640px) {
  .row .offset-11 {
    margin-left: 91.6666666667%;
  }
}
@media screen and (min-width: 640px) {
  .row .offset-12 {
    margin-left: 100%;
  }
}
.row.no-space [class^=col-], .row.no-space [class*=" col-"] {
  padding: 0;
}

.r {
  /* Legacy row pre 0.5.5 */
  max-width: 100%;
  padding: 0.5rem;
}

.level {
  align-items: center;
  justify-content: space-between;
  /* Used to stretch the contents of div in level to fill */
}

.level .level-item {
  /* Centers items */
  align-items: center;
  display: flex;
  flex-basis: auto;
  flex-grow: 0;
  flex-shrink: 0;
  justify-content: center;
}

.level .level-content {
  flex-basis: auto;
  flex-grow: 1;
  flex-shrink: 1;
  text-align: left;
  width: 100%;
}

/* Width/Height CSS */
.h-0 {
  height: 0 !important;
}

.h-1 {
  height: 0.5rem !important;
}

.h-2 {
  height: 1rem !important;
}

.h-3 {
  height: 1.5rem !important;
}

.h-4 {
  height: 2rem !important;
}

.h-5 {
  height: 2.5rem !important;
}

.h-6 {
  height: 3rem !important;
}

.h-8 {
  height: 4rem !important;
}

.h-10 {
  height: 5rem !important;
}

.h-12 {
  height: 6rem !important;
}

.h-16 {
  height: 8rem !important;
}

.h-20 {
  height: 10rem !important;
}

.h-24 {
  height: 12rem !important;
}

.h-32 {
  height: 16rem !important;
}

.h-10p {
  height: 10% !important;
}

.h-20p {
  height: 20% !important;
}

.h-30p {
  height: 30% !important;
}

.h-40p {
  height: 40% !important;
}

.h-50p {
  height: 50% !important;
}

.h-60p {
  height: 60% !important;
}

.h-70p {
  height: 70% !important;
}

.h-80p {
  height: 80% !important;
}

.h-90p {
  height: 90% !important;
}

.h-100p {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.h-screen {
  height: 100vh !important;
}

@media screen and (min-width: 640px) {
  .h-0-sm {
    height: 0 !important;
  }
}
@media screen and (min-width: 640px) {
  .h-1-sm {
    height: 0.5rem !important;
  }
}
@media screen and (min-width: 640px) {
  .h-2-sm {
    height: 1rem !important;
  }
}
@media screen and (min-width: 640px) {
  .h-3-sm {
    height: 1.5rem !important;
  }
}
@media screen and (min-width: 640px) {
  .h-4-sm {
    height: 2rem !important;
  }
}
@media screen and (min-width: 640px) {
  .h-5-sm {
    height: 2.5rem !important;
  }
}
@media screen and (min-width: 640px) {
  .h-6-sm {
    height: 3rem !important;
  }
}
@media screen and (min-width: 640px) {
  .h-8-sm {
    height: 4rem !important;
  }
}
@media screen and (min-width: 640px) {
  .h-10-sm {
    height: 5rem !important;
  }
}
@media screen and (min-width: 640px) {
  .h-12-sm {
    height: 6rem !important;
  }
}
@media screen and (min-width: 640px) {
  .h-16-sm {
    height: 8rem !important;
  }
}
@media screen and (min-width: 640px) {
  .h-20-sm {
    height: 10rem !important;
  }
}
@media screen and (min-width: 640px) {
  .h-24-sm {
    height: 12rem !important;
  }
}
@media screen and (min-width: 640px) {
  .h-32-sm {
    height: 16rem !important;
  }
}
@media screen and (min-width: 640px) {
  .h-10p-sm {
    height: 10% !important;
  }
}
@media screen and (min-width: 640px) {
  .h-20p-sm {
    height: 20% !important;
  }
}
@media screen and (min-width: 640px) {
  .h-30p-sm {
    height: 30% !important;
  }
}
@media screen and (min-width: 640px) {
  .h-40p-sm {
    height: 40% !important;
  }
}
@media screen and (min-width: 640px) {
  .h-50p-sm {
    height: 50% !important;
  }
}
@media screen and (min-width: 640px) {
  .h-60p-sm {
    height: 60% !important;
  }
}
@media screen and (min-width: 640px) {
  .h-70p-sm {
    height: 70% !important;
  }
}
@media screen and (min-width: 640px) {
  .h-80p-sm {
    height: 80% !important;
  }
}
@media screen and (min-width: 640px) {
  .h-90p-sm {
    height: 90% !important;
  }
}
@media screen and (min-width: 640px) {
  .h-100p-sm {
    height: 100% !important;
  }
}
@media screen and (min-width: 640px) {
  .h-auto-sm {
    height: auto !important;
  }
}
@media screen and (min-width: 640px) {
  .h-screen-sm {
    height: 100vh !important;
  }
}
@media screen and (min-width: 768px) {
  .h-0-md {
    height: 0 !important;
  }
}
@media screen and (min-width: 768px) {
  .h-1-md {
    height: 0.5rem !important;
  }
}
@media screen and (min-width: 768px) {
  .h-2-md {
    height: 1rem !important;
  }
}
@media screen and (min-width: 768px) {
  .h-3-md {
    height: 1.5rem !important;
  }
}
@media screen and (min-width: 768px) {
  .h-4-md {
    height: 2rem !important;
  }
}
@media screen and (min-width: 768px) {
  .h-5-md {
    height: 2.5rem !important;
  }
}
@media screen and (min-width: 768px) {
  .h-6-md {
    height: 3rem !important;
  }
}
@media screen and (min-width: 768px) {
  .h-8-md {
    height: 4rem !important;
  }
}
@media screen and (min-width: 768px) {
  .h-10-md {
    height: 5rem !important;
  }
}
@media screen and (min-width: 768px) {
  .h-12-md {
    height: 6rem !important;
  }
}
@media screen and (min-width: 768px) {
  .h-16-md {
    height: 8rem !important;
  }
}
@media screen and (min-width: 768px) {
  .h-20-md {
    height: 10rem !important;
  }
}
@media screen and (min-width: 768px) {
  .h-24-md {
    height: 12rem !important;
  }
}
@media screen and (min-width: 768px) {
  .h-32-md {
    height: 16rem !important;
  }
}
@media screen and (min-width: 768px) {
  .h-10p-md {
    height: 10% !important;
  }
}
@media screen and (min-width: 768px) {
  .h-20p-md {
    height: 20% !important;
  }
}
@media screen and (min-width: 768px) {
  .h-30p-md {
    height: 30% !important;
  }
}
@media screen and (min-width: 768px) {
  .h-40p-md {
    height: 40% !important;
  }
}
@media screen and (min-width: 768px) {
  .h-50p-md {
    height: 50% !important;
  }
}
@media screen and (min-width: 768px) {
  .h-60p-md {
    height: 60% !important;
  }
}
@media screen and (min-width: 768px) {
  .h-70p-md {
    height: 70% !important;
  }
}
@media screen and (min-width: 768px) {
  .h-80p-md {
    height: 80% !important;
  }
}
@media screen and (min-width: 768px) {
  .h-90p-md {
    height: 90% !important;
  }
}
@media screen and (min-width: 768px) {
  .h-100p-md {
    height: 100% !important;
  }
}
@media screen and (min-width: 768px) {
  .h-auto-md {
    height: auto !important;
  }
}
@media screen and (min-width: 768px) {
  .h-screen-md {
    height: 100vh !important;
  }
}
@media screen and (min-width: 1024px) {
  .h-0-lg {
    height: 0 !important;
  }
}
@media screen and (min-width: 1024px) {
  .h-1-lg {
    height: 0.5rem !important;
  }
}
@media screen and (min-width: 1024px) {
  .h-2-lg {
    height: 1rem !important;
  }
}
@media screen and (min-width: 1024px) {
  .h-3-lg {
    height: 1.5rem !important;
  }
}
@media screen and (min-width: 1024px) {
  .h-4-lg {
    height: 2rem !important;
  }
}
@media screen and (min-width: 1024px) {
  .h-5-lg {
    height: 2.5rem !important;
  }
}
@media screen and (min-width: 1024px) {
  .h-6-lg {
    height: 3rem !important;
  }
}
@media screen and (min-width: 1024px) {
  .h-8-lg {
    height: 4rem !important;
  }
}
@media screen and (min-width: 1024px) {
  .h-10-lg {
    height: 5rem !important;
  }
}
@media screen and (min-width: 1024px) {
  .h-12-lg {
    height: 6rem !important;
  }
}
@media screen and (min-width: 1024px) {
  .h-16-lg {
    height: 8rem !important;
  }
}
@media screen and (min-width: 1024px) {
  .h-20-lg {
    height: 10rem !important;
  }
}
@media screen and (min-width: 1024px) {
  .h-24-lg {
    height: 12rem !important;
  }
}
@media screen and (min-width: 1024px) {
  .h-32-lg {
    height: 16rem !important;
  }
}
@media screen and (min-width: 1024px) {
  .h-10p-lg {
    height: 10% !important;
  }
}
@media screen and (min-width: 1024px) {
  .h-20p-lg {
    height: 20% !important;
  }
}
@media screen and (min-width: 1024px) {
  .h-30p-lg {
    height: 30% !important;
  }
}
@media screen and (min-width: 1024px) {
  .h-40p-lg {
    height: 40% !important;
  }
}
@media screen and (min-width: 1024px) {
  .h-50p-lg {
    height: 50% !important;
  }
}
@media screen and (min-width: 1024px) {
  .h-60p-lg {
    height: 60% !important;
  }
}
@media screen and (min-width: 1024px) {
  .h-70p-lg {
    height: 70% !important;
  }
}
@media screen and (min-width: 1024px) {
  .h-80p-lg {
    height: 80% !important;
  }
}
@media screen and (min-width: 1024px) {
  .h-90p-lg {
    height: 90% !important;
  }
}
@media screen and (min-width: 1024px) {
  .h-100p-lg {
    height: 100% !important;
  }
}
@media screen and (min-width: 1024px) {
  .h-auto-lg {
    height: auto !important;
  }
}
@media screen and (min-width: 1024px) {
  .h-screen-lg {
    height: 100vh !important;
  }
}
@media screen and (min-width: 1280px) {
  .h-0-xl {
    height: 0 !important;
  }
}
@media screen and (min-width: 1280px) {
  .h-1-xl {
    height: 0.5rem !important;
  }
}
@media screen and (min-width: 1280px) {
  .h-2-xl {
    height: 1rem !important;
  }
}
@media screen and (min-width: 1280px) {
  .h-3-xl {
    height: 1.5rem !important;
  }
}
@media screen and (min-width: 1280px) {
  .h-4-xl {
    height: 2rem !important;
  }
}
@media screen and (min-width: 1280px) {
  .h-5-xl {
    height: 2.5rem !important;
  }
}
@media screen and (min-width: 1280px) {
  .h-6-xl {
    height: 3rem !important;
  }
}
@media screen and (min-width: 1280px) {
  .h-8-xl {
    height: 4rem !important;
  }
}
@media screen and (min-width: 1280px) {
  .h-10-xl {
    height: 5rem !important;
  }
}
@media screen and (min-width: 1280px) {
  .h-12-xl {
    height: 6rem !important;
  }
}
@media screen and (min-width: 1280px) {
  .h-16-xl {
    height: 8rem !important;
  }
}
@media screen and (min-width: 1280px) {
  .h-20-xl {
    height: 10rem !important;
  }
}
@media screen and (min-width: 1280px) {
  .h-24-xl {
    height: 12rem !important;
  }
}
@media screen and (min-width: 1280px) {
  .h-32-xl {
    height: 16rem !important;
  }
}
@media screen and (min-width: 1280px) {
  .h-10p-xl {
    height: 10% !important;
  }
}
@media screen and (min-width: 1280px) {
  .h-20p-xl {
    height: 20% !important;
  }
}
@media screen and (min-width: 1280px) {
  .h-30p-xl {
    height: 30% !important;
  }
}
@media screen and (min-width: 1280px) {
  .h-40p-xl {
    height: 40% !important;
  }
}
@media screen and (min-width: 1280px) {
  .h-50p-xl {
    height: 50% !important;
  }
}
@media screen and (min-width: 1280px) {
  .h-60p-xl {
    height: 60% !important;
  }
}
@media screen and (min-width: 1280px) {
  .h-70p-xl {
    height: 70% !important;
  }
}
@media screen and (min-width: 1280px) {
  .h-80p-xl {
    height: 80% !important;
  }
}
@media screen and (min-width: 1280px) {
  .h-90p-xl {
    height: 90% !important;
  }
}
@media screen and (min-width: 1280px) {
  .h-100p-xl {
    height: 100% !important;
  }
}
@media screen and (min-width: 1280px) {
  .h-auto-xl {
    height: auto !important;
  }
}
@media screen and (min-width: 1280px) {
  .h-screen-xl {
    height: 100vh !important;
  }
}
.w-0 {
  width: 0rem !important;
}

.w-auto {
  width: auto !important;
}

.w-screen {
  width: 100vw !important;
}

.w-10p {
  width: 10% !important;
}

.w-20p {
  width: 20% !important;
}

.w-30p {
  width: 30% !important;
}

.w-40p {
  width: 40% !important;
}

.w-50p {
  width: 50% !important;
}

.w-60p {
  width: 60% !important;
}

.w-70p {
  width: 70% !important;
}

.w-80p {
  width: 80% !important;
}

.w-90p {
  width: 90% !important;
}

.w-100p {
  width: 100% !important;
}

.w-1 {
  width: 0.5rem !important;
}

.w-2 {
  width: 1rem !important;
}

.w-3 {
  width: 1.5rem !important;
}

.w-4 {
  width: 2rem !important;
}

.w-5 {
  width: 2.5rem !important;
}

.w-6 {
  width: 3rem !important;
}

.w-8 {
  width: 4rem !important;
}

.w-10 {
  width: 5rem !important;
}

.w-12 {
  width: 6rem !important;
}

.w-16 {
  width: 8rem !important;
}

.w-20 {
  width: 10rem !important;
}

.w-24 {
  width: 12rem !important;
}

.w-32 {
  width: 16rem !important;
}

@media screen and (min-width: 640px) {
  .w-0-sm {
    width: 0rem !important;
  }
}
@media screen and (min-width: 640px) {
  .w-auto-sm {
    width: auto !important;
  }
}
@media screen and (min-width: 640px) {
  .w-screen-sm {
    width: 100vw !important;
  }
}
@media screen and (min-width: 640px) {
  .w-10p-sm {
    width: 10% !important;
  }
}
@media screen and (min-width: 640px) {
  .w-20p-sm {
    width: 20% !important;
  }
}
@media screen and (min-width: 640px) {
  .w-30p-sm {
    width: 30% !important;
  }
}
@media screen and (min-width: 640px) {
  .w-40p-sm {
    width: 40% !important;
  }
}
@media screen and (min-width: 640px) {
  .w-50p-sm {
    width: 50% !important;
  }
}
@media screen and (min-width: 640px) {
  .w-60p-sm {
    width: 60% !important;
  }
}
@media screen and (min-width: 640px) {
  .w-70p-sm {
    width: 70% !important;
  }
}
@media screen and (min-width: 640px) {
  .w-80p-sm {
    width: 80% !important;
  }
}
@media screen and (min-width: 640px) {
  .w-90p-sm {
    width: 90% !important;
  }
}
@media screen and (min-width: 640px) {
  .w-100p-sm {
    width: 100% !important;
  }
}
@media screen and (min-width: 640px) {
  .w-1-sm {
    width: 0.5rem !important;
  }
}
@media screen and (min-width: 640px) {
  .w-2-sm {
    width: 1rem !important;
  }
}
@media screen and (min-width: 640px) {
  .w-3-sm {
    width: 1.5rem !important;
  }
}
@media screen and (min-width: 640px) {
  .w-4-sm {
    width: 2rem !important;
  }
}
@media screen and (min-width: 640px) {
  .w-5-sm {
    width: 2.5rem !important;
  }
}
@media screen and (min-width: 640px) {
  .w-6-sm {
    width: 3rem !important;
  }
}
@media screen and (min-width: 640px) {
  .w-8-sm {
    width: 4rem !important;
  }
}
@media screen and (min-width: 640px) {
  .w-10-sm {
    width: 5rem !important;
  }
}
@media screen and (min-width: 640px) {
  .w-12-sm {
    width: 6rem !important;
  }
}
@media screen and (min-width: 640px) {
  .w-16-sm {
    width: 8rem !important;
  }
}
@media screen and (min-width: 640px) {
  .w-20-sm {
    width: 10rem !important;
  }
}
@media screen and (min-width: 640px) {
  .w-24-sm {
    width: 12rem !important;
  }
}
@media screen and (min-width: 640px) {
  .w-32-sm {
    width: 16rem !important;
  }
}
@media screen and (min-width: 768px) {
  .w-0-md {
    width: 0rem !important;
  }
}
@media screen and (min-width: 768px) {
  .w-auto-md {
    width: auto !important;
  }
}
@media screen and (min-width: 768px) {
  .w-screen-md {
    width: 100vw !important;
  }
}
@media screen and (min-width: 768px) {
  .w-10p-md {
    width: 10% !important;
  }
}
@media screen and (min-width: 768px) {
  .w-20p-md {
    width: 20% !important;
  }
}
@media screen and (min-width: 768px) {
  .w-30p-md {
    width: 30% !important;
  }
}
@media screen and (min-width: 768px) {
  .w-40p-md {
    width: 40% !important;
  }
}
@media screen and (min-width: 768px) {
  .w-50p-md {
    width: 50% !important;
  }
}
@media screen and (min-width: 768px) {
  .w-60p-md {
    width: 60% !important;
  }
}
@media screen and (min-width: 768px) {
  .w-70p-md {
    width: 70% !important;
  }
}
@media screen and (min-width: 768px) {
  .w-80p-md {
    width: 80% !important;
  }
}
@media screen and (min-width: 768px) {
  .w-90p-md {
    width: 90% !important;
  }
}
@media screen and (min-width: 768px) {
  .w-100p-md {
    width: 100% !important;
  }
}
@media screen and (min-width: 768px) {
  .w-1-md {
    width: 0.5rem !important;
  }
}
@media screen and (min-width: 768px) {
  .w-2-md {
    width: 1rem !important;
  }
}
@media screen and (min-width: 768px) {
  .w-3-md {
    width: 1.5rem !important;
  }
}
@media screen and (min-width: 768px) {
  .w-4-md {
    width: 2rem !important;
  }
}
@media screen and (min-width: 768px) {
  .w-5-md {
    width: 2.5rem !important;
  }
}
@media screen and (min-width: 768px) {
  .w-6-md {
    width: 3rem !important;
  }
}
@media screen and (min-width: 768px) {
  .w-8-md {
    width: 4rem !important;
  }
}
@media screen and (min-width: 768px) {
  .w-10-md {
    width: 5rem !important;
  }
}
@media screen and (min-width: 768px) {
  .w-12-md {
    width: 6rem !important;
  }
}
@media screen and (min-width: 768px) {
  .w-16-md {
    width: 8rem !important;
  }
}
@media screen and (min-width: 768px) {
  .w-20-md {
    width: 10rem !important;
  }
}
@media screen and (min-width: 768px) {
  .w-24-md {
    width: 12rem !important;
  }
}
@media screen and (min-width: 768px) {
  .w-32-md {
    width: 16rem !important;
  }
}
@media screen and (min-width: 1024px) {
  .w-0-lg {
    width: 0rem !important;
  }
}
@media screen and (min-width: 1024px) {
  .w-auto-lg {
    width: auto !important;
  }
}
@media screen and (min-width: 1024px) {
  .w-screen-lg {
    width: 100vw !important;
  }
}
@media screen and (min-width: 1024px) {
  .w-10p-lg {
    width: 10% !important;
  }
}
@media screen and (min-width: 1024px) {
  .w-20p-lg {
    width: 20% !important;
  }
}
@media screen and (min-width: 1024px) {
  .w-30p-lg {
    width: 30% !important;
  }
}
@media screen and (min-width: 1024px) {
  .w-40p-lg {
    width: 40% !important;
  }
}
@media screen and (min-width: 1024px) {
  .w-50p-lg {
    width: 50% !important;
  }
}
@media screen and (min-width: 1024px) {
  .w-60p-lg {
    width: 60% !important;
  }
}
@media screen and (min-width: 1024px) {
  .w-70p-lg {
    width: 70% !important;
  }
}
@media screen and (min-width: 1024px) {
  .w-80p-lg {
    width: 80% !important;
  }
}
@media screen and (min-width: 1024px) {
  .w-90p-lg {
    width: 90% !important;
  }
}
@media screen and (min-width: 1024px) {
  .w-100p-lg {
    width: 100% !important;
  }
}
@media screen and (min-width: 1024px) {
  .w-1-lg {
    width: 0.5rem !important;
  }
}
@media screen and (min-width: 1024px) {
  .w-2-lg {
    width: 1rem !important;
  }
}
@media screen and (min-width: 1024px) {
  .w-3-lg {
    width: 1.5rem !important;
  }
}
@media screen and (min-width: 1024px) {
  .w-4-lg {
    width: 2rem !important;
  }
}
@media screen and (min-width: 1024px) {
  .w-5-lg {
    width: 2.5rem !important;
  }
}
@media screen and (min-width: 1024px) {
  .w-6-lg {
    width: 3rem !important;
  }
}
@media screen and (min-width: 1024px) {
  .w-8-lg {
    width: 4rem !important;
  }
}
@media screen and (min-width: 1024px) {
  .w-10-lg {
    width: 5rem !important;
  }
}
@media screen and (min-width: 1024px) {
  .w-12-lg {
    width: 6rem !important;
  }
}
@media screen and (min-width: 1024px) {
  .w-16-lg {
    width: 8rem !important;
  }
}
@media screen and (min-width: 1024px) {
  .w-20-lg {
    width: 10rem !important;
  }
}
@media screen and (min-width: 1024px) {
  .w-24-lg {
    width: 12rem !important;
  }
}
@media screen and (min-width: 1024px) {
  .w-32-lg {
    width: 16rem !important;
  }
}
@media screen and (min-width: 1280px) {
  .w-0-xl {
    width: 0rem !important;
  }
}
@media screen and (min-width: 1280px) {
  .w-auto-xl {
    width: auto !important;
  }
}
@media screen and (min-width: 1280px) {
  .w-screen-xl {
    width: 100vw !important;
  }
}
@media screen and (min-width: 1280px) {
  .w-10p-xl {
    width: 10% !important;
  }
}
@media screen and (min-width: 1280px) {
  .w-20p-xl {
    width: 20% !important;
  }
}
@media screen and (min-width: 1280px) {
  .w-30p-xl {
    width: 30% !important;
  }
}
@media screen and (min-width: 1280px) {
  .w-40p-xl {
    width: 40% !important;
  }
}
@media screen and (min-width: 1280px) {
  .w-50p-xl {
    width: 50% !important;
  }
}
@media screen and (min-width: 1280px) {
  .w-60p-xl {
    width: 60% !important;
  }
}
@media screen and (min-width: 1280px) {
  .w-70p-xl {
    width: 70% !important;
  }
}
@media screen and (min-width: 1280px) {
  .w-80p-xl {
    width: 80% !important;
  }
}
@media screen and (min-width: 1280px) {
  .w-90p-xl {
    width: 90% !important;
  }
}
@media screen and (min-width: 1280px) {
  .w-100p-xl {
    width: 100% !important;
  }
}
@media screen and (min-width: 1280px) {
  .w-1-xl {
    width: 0.5rem !important;
  }
}
@media screen and (min-width: 1280px) {
  .w-2-xl {
    width: 1rem !important;
  }
}
@media screen and (min-width: 1280px) {
  .w-3-xl {
    width: 1.5rem !important;
  }
}
@media screen and (min-width: 1280px) {
  .w-4-xl {
    width: 2rem !important;
  }
}
@media screen and (min-width: 1280px) {
  .w-5-xl {
    width: 2.5rem !important;
  }
}
@media screen and (min-width: 1280px) {
  .w-6-xl {
    width: 3rem !important;
  }
}
@media screen and (min-width: 1280px) {
  .w-8-xl {
    width: 4rem !important;
  }
}
@media screen and (min-width: 1280px) {
  .w-10-xl {
    width: 5rem !important;
  }
}
@media screen and (min-width: 1280px) {
  .w-12-xl {
    width: 6rem !important;
  }
}
@media screen and (min-width: 1280px) {
  .w-16-xl {
    width: 8rem !important;
  }
}
@media screen and (min-width: 1280px) {
  .w-20-xl {
    width: 10rem !important;
  }
}
@media screen and (min-width: 1280px) {
  .w-24-xl {
    width: 12rem !important;
  }
}
@media screen and (min-width: 1280px) {
  .w-32-xl {
    width: 16rem !important;
  }
}
.min-h-10p {
  min-height: 10% !important;
}

.min-h-20p {
  min-height: 20% !important;
}

.min-h-30p {
  min-height: 30% !important;
}

.min-h-40p {
  min-height: 40% !important;
}

.min-h-50p {
  min-height: 50% !important;
}

.min-h-60p {
  min-height: 60% !important;
}

.min-h-70p {
  min-height: 70% !important;
}

.min-h-80p {
  min-height: 80% !important;
}

.min-h-90p {
  min-height: 90% !important;
}

.min-h-100p {
  min-height: 100% !important;
}

.min-h-0 {
  min-height: 0 !important;
}

.min-h-screen {
  min-height: 100vh !important;
}

.min-w-xs {
  min-width: 640px !important;
}

.min-w-sm {
  min-width: 768px !important;
}

.min-w-md {
  min-width: 1024px !important;
}

.min-w-lg {
  min-width: 1280px !important;
}

.min-w-xl {
  min-width: 1536px !important;
}

.min-w-10p {
  min-width: 10% !important;
}

.min-w-20p {
  min-width: 20% !important;
}

.min-w-30p {
  min-width: 30% !important;
}

.min-w-40p {
  min-width: 40% !important;
}

.min-w-50p {
  min-width: 50% !important;
}

.min-w-60p {
  min-width: 60% !important;
}

.min-w-70p {
  min-width: 70% !important;
}

.min-w-80p {
  min-width: 80% !important;
}

.min-w-90p {
  min-width: 90% !important;
}

.min-w-100p {
  min-width: 100% !important;
}

.min-w-0 {
  min-width: 0 !important;
}

.min-w-screen {
  min-width: 100vw !important;
}

.max-h-10p {
  max-height: 10% !important;
}

.max-h-20p {
  max-height: 20% !important;
}

.max-h-30p {
  max-height: 30% !important;
}

.max-h-40p {
  max-height: 40% !important;
}

.max-h-50p {
  max-height: 50% !important;
}

.max-h-60p {
  max-height: 60% !important;
}

.max-h-70p {
  max-height: 70% !important;
}

.max-h-80p {
  max-height: 80% !important;
}

.max-h-90p {
  max-height: 90% !important;
}

.max-h-100p {
  max-height: 100% !important;
}

.max-h-none {
  max-height: none !important;
}

.max-h-screen {
  max-height: 100vh !important;
}

.max-w-xs {
  max-width: 640px !important;
}

.max-w-sm {
  max-width: 768px !important;
}

.max-w-md {
  max-width: 1024px !important;
}

.max-w-lg {
  max-width: 1280px !important;
}

.max-w-xl {
  max-width: 1536px !important;
}

.max-w-10p {
  max-width: 10% !important;
}

.max-w-20p {
  max-width: 20% !important;
}

.max-w-30p {
  max-width: 30% !important;
}

.max-w-40p {
  max-width: 40% !important;
}

.max-w-50p {
  max-width: 50% !important;
}

.max-w-60p {
  max-width: 60% !important;
}

.max-w-70p {
  max-width: 70% !important;
}

.max-w-80p {
  max-width: 80% !important;
}

.max-w-90p {
  max-width: 90% !important;
}

.max-w-100p {
  max-width: 100% !important;
}

.max-w-none {
  max-width: none !important;
}

.max-w-screen {
  max-width: 100vw !important;
}

/* Do the actual balancing only on larger screens */
@media screen and (min-width: 768px) {
  .level,
.level-left,
.level-right {
    display: flex;
  }
}
@media screen and (max-width: 767px) {
  .level-right {
    margin-left: 1rem;
  }
  /* Keep all level children the same height */
  .level.fill-height {
    align-items: stretch;
    display: flex;
  }
}
/* MOBILE */
@media screen and (max-width: 767px) {
  .container {
    width: 100%;
  }
  .row {
    margin-top: 0;
  }
  /* Dividers for mobile layout */
  .divided > .row [class^=col-],
.divided > .row [class*=" col-"] {
    box-shadow: 0 -1px 0 0 rgba(34, 36, 38, 0.15);
  }
  .level.fill-height {
    display: inherit; /* Allow children to expand */
  }
  .hero-body {
    padding: 0;
  }
}
/* MEDIA CSS */
/* Handles images, videos, figures, etc */
video.video-fullscreen {
  position: absolute;
  height: 100vh;
  object-fit: cover;
  width: 100%;
  z-index: -1;
}

.hero.fullscreen video {
  height: 100%;
  object-fit: fill;
  position: absolute;
  width: 100%;
  z-index: -1;
}

/* Add to parent container to make media child fill container */
.media-stretch {
  display: block;
  padding: 0;
  overflow: hidden;
  width: 100%;
  position: relative;
  /* Add pseudoclass to allow media to have height (default height is 0) */
  /* Force media embed to fill parent container */
  /* Alternate media ratios */
  /* For HTML5 video objects */
}

.media-stretch::before {
  content: "";
  display: block;
  padding-bottom: 56.25%; /* 9 / 16  for 16:9 ratio */
}

.media-stretch iframe,
.media-stretch embed,
.media-stretch object {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%; /* Make sure media does exceed or go under boundary limit */
  height: 100%;
}

.media-stretch.rat-4-3::before {
  padding-bottom: 75%;
}

.media-stretch.rat-1-1::before {
  padding-bottom: 100%;
}

.media-stretch video {
  height: auto;
  max-width: 100%;
  width: 100%;
}

/* FIGURES */
/* For flexible media display */
.fig {
  margin: 0 0 0.5rem 0; /* Small margin at the bottom */
}

.fig .fig-caption {
  margin-top: 1rem;
}

/* IMAGES */
.img-stretch {
  max-width: 100%;
  height: auto;
  display: block;
}

.img-cover {
  object-fit: cover;
}

.img-contain {
  object-fit: contain;
}

/* A collection of modifiers that extend control styling */
.btn, button, [type=submit], [type=reset], [type=button] {
  /* btn--circle: contents must be placed inside child element */
}

.btn.btn--pilled, button.btn--pilled, [type=submit].btn--pilled, [type=reset].btn--pilled, [type=button].btn--pilled {
  border-radius: 6.25rem;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.btn.btn--circle, button.btn--circle, [type=submit].btn--circle, [type=reset].btn--circle, [type=button].btn--circle {
  border-radius: 100%;
  min-width: 60px;
  overflow: hidden;
  padding: 0;
  text-align: center;
}

.btn.btn--circle:before, button.btn--circle:before, [type=submit].btn--circle:before, [type=reset].btn--circle:before, [type=button].btn--circle:before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  padding-top: 100%;
}

.btn.btn--circle *, button.btn--circle *, [type=submit].btn--circle *, [type=reset].btn--circle *, [type=button].btn--circle * {
  display: inline;
  max-width: 90%;
  vertical-align: middle;
  white-space: pre-wrap;
}

/* Input (primary text-based) controls apply to inputs */
.input-control--pilled {
  border-radius: 6.25rem !important;
}

/* Theme modifiers */
/* GLOBAL THEME SELECTORS */
/* v1 */
.bg-primary {
  background-color: rgba(240, 61, 77, var(--bg-opacity)) !important;
}

.text-primary {
  color: rgba(240, 61, 77, var(--color-opacity)) !important;
  border-color: rgba(240, 61, 77, var(--border-opacity));
}

.border-primary {
  border-color: rgba(240, 61, 77, var(--border-opacity)) !important;
}

.bg-success {
  background-color: rgba(13, 209, 87, var(--bg-opacity)) !important;
}

.text-success {
  color: rgba(13, 209, 87, var(--color-opacity)) !important;
  border-color: rgba(13, 209, 87, var(--border-opacity));
}

.border-success {
  border-color: rgba(13, 209, 87, var(--border-opacity)) !important;
}

.bg-warning {
  background-color: rgba(250, 182, 51, var(--bg-opacity)) !important;
}

.text-warning {
  color: rgba(250, 182, 51, var(--color-opacity)) !important;
  border-color: rgba(250, 182, 51, var(--border-opacity));
}

.border-warning {
  border-color: rgba(250, 182, 51, var(--border-opacity)) !important;
}

.bg-danger {
  background-color: rgba(251, 65, 67, var(--bg-opacity)) !important;
}

.text-danger {
  color: rgba(251, 65, 67, var(--color-opacity)) !important;
  border-color: rgba(251, 65, 67, var(--border-opacity));
}

.border-danger {
  border-color: rgba(251, 65, 67, var(--border-opacity)) !important;
}

.bg-light {
  background-color: rgba(246, 249, 252, var(--bg-opacity)) !important;
}

.text-light {
  color: rgba(246, 249, 252, var(--color-opacity)) !important;
  border-color: rgba(246, 249, 252, var(--border-opacity));
}

.border-light {
  border-color: rgba(246, 249, 252, var(--border-opacity)) !important;
}

.bg-dark {
  background-color: rgba(54, 54, 54, var(--bg-opacity)) !important;
}

.text-dark {
  color: rgba(54, 54, 54, var(--color-opacity)) !important;
  border-color: rgba(54, 54, 54, var(--border-opacity));
}

.border-dark {
  border-color: rgba(54, 54, 54, var(--border-opacity)) !important;
}

.bg-link {
  background-color: rgba(94, 92, 199, var(--bg-opacity)) !important;
}

.text-link {
  color: rgba(94, 92, 199, var(--color-opacity)) !important;
  border-color: rgba(94, 92, 199, var(--border-opacity));
}

.border-link {
  border-color: rgba(94, 92, 199, var(--border-opacity)) !important;
}

.bg-link-dark {
  background-color: rgba(70, 67, 226, var(--bg-opacity)) !important;
}

.text-link-dark {
  color: rgba(70, 67, 226, var(--color-opacity)) !important;
  border-color: rgba(70, 67, 226, var(--border-opacity));
}

.border-link-dark {
  border-color: rgba(70, 67, 226, var(--border-opacity)) !important;
}

.bg-info {
  background-color: rgba(41, 114, 250, var(--bg-opacity)) !important;
}

.text-info {
  color: rgba(41, 114, 250, var(--color-opacity)) !important;
  border-color: rgba(41, 114, 250, var(--border-opacity));
}

.border-info {
  border-color: rgba(41, 114, 250, var(--border-opacity)) !important;
}

.bg-black {
  background-color: rgba(0, 0, 0, var(--bg-opacity)) !important;
}

.text-black {
  color: rgba(0, 0, 0, var(--color-opacity)) !important;
  border-color: rgba(0, 0, 0, var(--border-opacity));
}

.border-black {
  border-color: rgba(0, 0, 0, var(--border-opacity)) !important;
}

.bg-white {
  background-color: rgba(255, 255, 255, var(--bg-opacity)) !important;
}

.text-white {
  color: rgba(255, 255, 255, var(--color-opacity)) !important;
  border-color: rgba(255, 255, 255, var(--border-opacity));
}

.border-white {
  border-color: rgba(255, 255, 255, var(--border-opacity)) !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.text-transparent {
  border-color: transparent !important;
  color: transparent !important;
}

/* v2 */
.bg-pink-100 {
  background-color: rgba(252, 232, 243, var(--bg-opacity)) !important;
}

.text-pink-100 {
  color: rgba(252, 232, 243, var(--color-opacity)) !important;
  border-color: rgba(252, 232, 243, var(--border-opacity));
}

.border-pink-100 {
  border-color: rgba(252, 232, 243, var(--border-opacity)) !important;
}

.bg-pink-200 {
  background-color: rgba(250, 209, 232, var(--bg-opacity)) !important;
}

.text-pink-200 {
  color: rgba(250, 209, 232, var(--color-opacity)) !important;
  border-color: rgba(250, 209, 232, var(--border-opacity));
}

.border-pink-200 {
  border-color: rgba(250, 209, 232, var(--border-opacity)) !important;
}

.bg-pink-300 {
  background-color: rgba(248, 180, 217, var(--bg-opacity)) !important;
}

.text-pink-300 {
  color: rgba(248, 180, 217, var(--color-opacity)) !important;
  border-color: rgba(248, 180, 217, var(--border-opacity));
}

.border-pink-300 {
  border-color: rgba(248, 180, 217, var(--border-opacity)) !important;
}

.bg-pink-400 {
  background-color: rgba(241, 126, 184, var(--bg-opacity)) !important;
}

.text-pink-400 {
  color: rgba(241, 126, 184, var(--color-opacity)) !important;
  border-color: rgba(241, 126, 184, var(--border-opacity));
}

.border-pink-400 {
  border-color: rgba(241, 126, 184, var(--border-opacity)) !important;
}

.bg-pink-500 {
  background-color: rgba(231, 70, 148, var(--bg-opacity)) !important;
}

.text-pink-500 {
  color: rgba(231, 70, 148, var(--color-opacity)) !important;
  border-color: rgba(231, 70, 148, var(--border-opacity));
}

.border-pink-500 {
  border-color: rgba(231, 70, 148, var(--border-opacity)) !important;
}

.bg-pink-600 {
  background-color: rgba(214, 31, 105, var(--bg-opacity)) !important;
}

.text-pink-600 {
  color: rgba(214, 31, 105, var(--color-opacity)) !important;
  border-color: rgba(214, 31, 105, var(--border-opacity));
}

.border-pink-600 {
  border-color: rgba(214, 31, 105, var(--border-opacity)) !important;
}

.bg-pink-700 {
  background-color: rgba(191, 18, 93, var(--bg-opacity)) !important;
}

.text-pink-700 {
  color: rgba(191, 18, 93, var(--color-opacity)) !important;
  border-color: rgba(191, 18, 93, var(--border-opacity));
}

.border-pink-700 {
  border-color: rgba(191, 18, 93, var(--border-opacity)) !important;
}

.bg-pink-800 {
  background-color: rgba(153, 21, 75, var(--bg-opacity)) !important;
}

.text-pink-800 {
  color: rgba(153, 21, 75, var(--color-opacity)) !important;
  border-color: rgba(153, 21, 75, var(--border-opacity));
}

.border-pink-800 {
  border-color: rgba(153, 21, 75, var(--border-opacity)) !important;
}

.bg-pink-900 {
  background-color: rgba(117, 26, 61, var(--bg-opacity)) !important;
}

.text-pink-900 {
  color: rgba(117, 26, 61, var(--color-opacity)) !important;
  border-color: rgba(117, 26, 61, var(--border-opacity));
}

.border-pink-900 {
  border-color: rgba(117, 26, 61, var(--border-opacity)) !important;
}

.bg-red-100 {
  background-color: rgba(253, 232, 232, var(--bg-opacity)) !important;
}

.text-red-100 {
  color: rgba(253, 232, 232, var(--color-opacity)) !important;
  border-color: rgba(253, 232, 232, var(--border-opacity));
}

.border-red-100 {
  border-color: rgba(253, 232, 232, var(--border-opacity)) !important;
}

.bg-red-200 {
  background-color: rgba(251, 213, 213, var(--bg-opacity)) !important;
}

.text-red-200 {
  color: rgba(251, 213, 213, var(--color-opacity)) !important;
  border-color: rgba(251, 213, 213, var(--border-opacity));
}

.border-red-200 {
  border-color: rgba(251, 213, 213, var(--border-opacity)) !important;
}

.bg-red-300 {
  background-color: rgba(248, 180, 180, var(--bg-opacity)) !important;
}

.text-red-300 {
  color: rgba(248, 180, 180, var(--color-opacity)) !important;
  border-color: rgba(248, 180, 180, var(--border-opacity));
}

.border-red-300 {
  border-color: rgba(248, 180, 180, var(--border-opacity)) !important;
}

.bg-red-400 {
  background-color: rgba(249, 128, 128, var(--bg-opacity)) !important;
}

.text-red-400 {
  color: rgba(249, 128, 128, var(--color-opacity)) !important;
  border-color: rgba(249, 128, 128, var(--border-opacity));
}

.border-red-400 {
  border-color: rgba(249, 128, 128, var(--border-opacity)) !important;
}

.bg-red-500 {
  background-color: rgba(240, 82, 82, var(--bg-opacity)) !important;
}

.text-red-500 {
  color: rgba(240, 82, 82, var(--color-opacity)) !important;
  border-color: rgba(240, 82, 82, var(--border-opacity));
}

.border-red-500 {
  border-color: rgba(240, 82, 82, var(--border-opacity)) !important;
}

.bg-red-600 {
  background-color: rgba(224, 36, 36, var(--bg-opacity)) !important;
}

.text-red-600 {
  color: rgba(224, 36, 36, var(--color-opacity)) !important;
  border-color: rgba(224, 36, 36, var(--border-opacity));
}

.border-red-600 {
  border-color: rgba(224, 36, 36, var(--border-opacity)) !important;
}

.bg-red-700 {
  background-color: rgba(200, 30, 30, var(--bg-opacity)) !important;
}

.text-red-700 {
  color: rgba(200, 30, 30, var(--color-opacity)) !important;
  border-color: rgba(200, 30, 30, var(--border-opacity));
}

.border-red-700 {
  border-color: rgba(200, 30, 30, var(--border-opacity)) !important;
}

.bg-red-800 {
  background-color: rgba(155, 28, 28, var(--bg-opacity)) !important;
}

.text-red-800 {
  color: rgba(155, 28, 28, var(--color-opacity)) !important;
  border-color: rgba(155, 28, 28, var(--border-opacity));
}

.border-red-800 {
  border-color: rgba(155, 28, 28, var(--border-opacity)) !important;
}

.bg-red-900 {
  background-color: rgba(119, 29, 29, var(--bg-opacity)) !important;
}

.text-red-900 {
  color: rgba(119, 29, 29, var(--color-opacity)) !important;
  border-color: rgba(119, 29, 29, var(--border-opacity));
}

.border-red-900 {
  border-color: rgba(119, 29, 29, var(--border-opacity)) !important;
}

.bg-orange-100 {
  background-color: rgba(254, 236, 220, var(--bg-opacity)) !important;
}

.text-orange-100 {
  color: rgba(254, 236, 220, var(--color-opacity)) !important;
  border-color: rgba(254, 236, 220, var(--border-opacity));
}

.border-orange-100 {
  border-color: rgba(254, 236, 220, var(--border-opacity)) !important;
}

.bg-orange-200 {
  background-color: rgba(252, 217, 189, var(--bg-opacity)) !important;
}

.text-orange-200 {
  color: rgba(252, 217, 189, var(--color-opacity)) !important;
  border-color: rgba(252, 217, 189, var(--border-opacity));
}

.border-orange-200 {
  border-color: rgba(252, 217, 189, var(--border-opacity)) !important;
}

.bg-orange-300 {
  background-color: rgba(253, 186, 140, var(--bg-opacity)) !important;
}

.text-orange-300 {
  color: rgba(253, 186, 140, var(--color-opacity)) !important;
  border-color: rgba(253, 186, 140, var(--border-opacity));
}

.border-orange-300 {
  border-color: rgba(253, 186, 140, var(--border-opacity)) !important;
}

.bg-orange-400 {
  background-color: rgba(255, 138, 76, var(--bg-opacity)) !important;
}

.text-orange-400 {
  color: rgba(255, 138, 76, var(--color-opacity)) !important;
  border-color: rgba(255, 138, 76, var(--border-opacity));
}

.border-orange-400 {
  border-color: rgba(255, 138, 76, var(--border-opacity)) !important;
}

.bg-orange-500 {
  background-color: rgba(255, 90, 31, var(--bg-opacity)) !important;
}

.text-orange-500 {
  color: rgba(255, 90, 31, var(--color-opacity)) !important;
  border-color: rgba(255, 90, 31, var(--border-opacity));
}

.border-orange-500 {
  border-color: rgba(255, 90, 31, var(--border-opacity)) !important;
}

.bg-orange-600 {
  background-color: rgba(208, 56, 1, var(--bg-opacity)) !important;
}

.text-orange-600 {
  color: rgba(208, 56, 1, var(--color-opacity)) !important;
  border-color: rgba(208, 56, 1, var(--border-opacity));
}

.border-orange-600 {
  border-color: rgba(208, 56, 1, var(--border-opacity)) !important;
}

.bg-orange-700 {
  background-color: rgba(180, 52, 3, var(--bg-opacity)) !important;
}

.text-orange-700 {
  color: rgba(180, 52, 3, var(--color-opacity)) !important;
  border-color: rgba(180, 52, 3, var(--border-opacity));
}

.border-orange-700 {
  border-color: rgba(180, 52, 3, var(--border-opacity)) !important;
}

.bg-orange-800 {
  background-color: rgba(138, 44, 13, var(--bg-opacity)) !important;
}

.text-orange-800 {
  color: rgba(138, 44, 13, var(--color-opacity)) !important;
  border-color: rgba(138, 44, 13, var(--border-opacity));
}

.border-orange-800 {
  border-color: rgba(138, 44, 13, var(--border-opacity)) !important;
}

.bg-orange-900 {
  background-color: rgba(115, 35, 13, var(--bg-opacity)) !important;
}

.text-orange-900 {
  color: rgba(115, 35, 13, var(--color-opacity)) !important;
  border-color: rgba(115, 35, 13, var(--border-opacity));
}

.border-orange-900 {
  border-color: rgba(115, 35, 13, var(--border-opacity)) !important;
}

.bg-yellow-100 {
  background-color: rgba(253, 246, 178, var(--bg-opacity)) !important;
}

.text-yellow-100 {
  color: rgba(253, 246, 178, var(--color-opacity)) !important;
  border-color: rgba(253, 246, 178, var(--border-opacity));
}

.border-yellow-100 {
  border-color: rgba(253, 246, 178, var(--border-opacity)) !important;
}

.bg-yellow-200 {
  background-color: rgba(252, 233, 106, var(--bg-opacity)) !important;
}

.text-yellow-200 {
  color: rgba(252, 233, 106, var(--color-opacity)) !important;
  border-color: rgba(252, 233, 106, var(--border-opacity));
}

.border-yellow-200 {
  border-color: rgba(252, 233, 106, var(--border-opacity)) !important;
}

.bg-yellow-300 {
  background-color: rgba(250, 202, 21, var(--bg-opacity)) !important;
}

.text-yellow-300 {
  color: rgba(250, 202, 21, var(--color-opacity)) !important;
  border-color: rgba(250, 202, 21, var(--border-opacity));
}

.border-yellow-300 {
  border-color: rgba(250, 202, 21, var(--border-opacity)) !important;
}

.bg-yellow-400 {
  background-color: rgba(227, 160, 8, var(--bg-opacity)) !important;
}

.text-yellow-400 {
  color: rgba(227, 160, 8, var(--color-opacity)) !important;
  border-color: rgba(227, 160, 8, var(--border-opacity));
}

.border-yellow-400 {
  border-color: rgba(227, 160, 8, var(--border-opacity)) !important;
}

.bg-yellow-500 {
  background-color: rgba(194, 120, 3, var(--bg-opacity)) !important;
}

.text-yellow-500 {
  color: rgba(194, 120, 3, var(--color-opacity)) !important;
  border-color: rgba(194, 120, 3, var(--border-opacity));
}

.border-yellow-500 {
  border-color: rgba(194, 120, 3, var(--border-opacity)) !important;
}

.bg-yellow-600 {
  background-color: rgba(159, 88, 10, var(--bg-opacity)) !important;
}

.text-yellow-600 {
  color: rgba(159, 88, 10, var(--color-opacity)) !important;
  border-color: rgba(159, 88, 10, var(--border-opacity));
}

.border-yellow-600 {
  border-color: rgba(159, 88, 10, var(--border-opacity)) !important;
}

.bg-yellow-700 {
  background-color: rgba(142, 75, 16, var(--bg-opacity)) !important;
}

.text-yellow-700 {
  color: rgba(142, 75, 16, var(--color-opacity)) !important;
  border-color: rgba(142, 75, 16, var(--border-opacity));
}

.border-yellow-700 {
  border-color: rgba(142, 75, 16, var(--border-opacity)) !important;
}

.bg-yellow-800 {
  background-color: rgba(114, 59, 19, var(--bg-opacity)) !important;
}

.text-yellow-800 {
  color: rgba(114, 59, 19, var(--color-opacity)) !important;
  border-color: rgba(114, 59, 19, var(--border-opacity));
}

.border-yellow-800 {
  border-color: rgba(114, 59, 19, var(--border-opacity)) !important;
}

.bg-yellow-900 {
  background-color: rgba(99, 49, 18, var(--bg-opacity)) !important;
}

.text-yellow-900 {
  color: rgba(99, 49, 18, var(--color-opacity)) !important;
  border-color: rgba(99, 49, 18, var(--border-opacity));
}

.border-yellow-900 {
  border-color: rgba(99, 49, 18, var(--border-opacity)) !important;
}

.bg-green-100 {
  background-color: rgba(222, 247, 236, var(--bg-opacity)) !important;
}

.text-green-100 {
  color: rgba(222, 247, 236, var(--color-opacity)) !important;
  border-color: rgba(222, 247, 236, var(--border-opacity));
}

.border-green-100 {
  border-color: rgba(222, 247, 236, var(--border-opacity)) !important;
}

.bg-green-200 {
  background-color: rgba(188, 240, 218, var(--bg-opacity)) !important;
}

.text-green-200 {
  color: rgba(188, 240, 218, var(--color-opacity)) !important;
  border-color: rgba(188, 240, 218, var(--border-opacity));
}

.border-green-200 {
  border-color: rgba(188, 240, 218, var(--border-opacity)) !important;
}

.bg-green-300 {
  background-color: rgba(132, 225, 188, var(--bg-opacity)) !important;
}

.text-green-300 {
  color: rgba(132, 225, 188, var(--color-opacity)) !important;
  border-color: rgba(132, 225, 188, var(--border-opacity));
}

.border-green-300 {
  border-color: rgba(132, 225, 188, var(--border-opacity)) !important;
}

.bg-green-400 {
  background-color: rgba(49, 196, 141, var(--bg-opacity)) !important;
}

.text-green-400 {
  color: rgba(49, 196, 141, var(--color-opacity)) !important;
  border-color: rgba(49, 196, 141, var(--border-opacity));
}

.border-green-400 {
  border-color: rgba(49, 196, 141, var(--border-opacity)) !important;
}

.bg-green-500 {
  background-color: rgba(14, 159, 110, var(--bg-opacity)) !important;
}

.text-green-500 {
  color: rgba(14, 159, 110, var(--color-opacity)) !important;
  border-color: rgba(14, 159, 110, var(--border-opacity));
}

.border-green-500 {
  border-color: rgba(14, 159, 110, var(--border-opacity)) !important;
}

.bg-green-600 {
  background-color: rgba(5, 122, 85, var(--bg-opacity)) !important;
}

.text-green-600 {
  color: rgba(5, 122, 85, var(--color-opacity)) !important;
  border-color: rgba(5, 122, 85, var(--border-opacity));
}

.border-green-600 {
  border-color: rgba(5, 122, 85, var(--border-opacity)) !important;
}

.bg-green-700 {
  background-color: rgba(4, 108, 78, var(--bg-opacity)) !important;
}

.text-green-700 {
  color: rgba(4, 108, 78, var(--color-opacity)) !important;
  border-color: rgba(4, 108, 78, var(--border-opacity));
}

.border-green-700 {
  border-color: rgba(4, 108, 78, var(--border-opacity)) !important;
}

.bg-green-800 {
  background-color: rgba(3, 84, 63, var(--bg-opacity)) !important;
}

.text-green-800 {
  color: rgba(3, 84, 63, var(--color-opacity)) !important;
  border-color: rgba(3, 84, 63, var(--border-opacity));
}

.border-green-800 {
  border-color: rgba(3, 84, 63, var(--border-opacity)) !important;
}

.bg-green-900 {
  background-color: rgba(1, 71, 55, var(--bg-opacity)) !important;
}

.text-green-900 {
  color: rgba(1, 71, 55, var(--color-opacity)) !important;
  border-color: rgba(1, 71, 55, var(--border-opacity));
}

.border-green-900 {
  border-color: rgba(1, 71, 55, var(--border-opacity)) !important;
}

.bg-teal-100 {
  background-color: rgba(213, 245, 246, var(--bg-opacity)) !important;
}

.text-teal-100 {
  color: rgba(213, 245, 246, var(--color-opacity)) !important;
  border-color: rgba(213, 245, 246, var(--border-opacity));
}

.border-teal-100 {
  border-color: rgba(213, 245, 246, var(--border-opacity)) !important;
}

.bg-teal-200 {
  background-color: rgba(175, 236, 239, var(--bg-opacity)) !important;
}

.text-teal-200 {
  color: rgba(175, 236, 239, var(--color-opacity)) !important;
  border-color: rgba(175, 236, 239, var(--border-opacity));
}

.border-teal-200 {
  border-color: rgba(175, 236, 239, var(--border-opacity)) !important;
}

.bg-teal-300 {
  background-color: rgba(126, 220, 226, var(--bg-opacity)) !important;
}

.text-teal-300 {
  color: rgba(126, 220, 226, var(--color-opacity)) !important;
  border-color: rgba(126, 220, 226, var(--border-opacity));
}

.border-teal-300 {
  border-color: rgba(126, 220, 226, var(--border-opacity)) !important;
}

.bg-teal-400 {
  background-color: rgba(22, 189, 202, var(--bg-opacity)) !important;
}

.text-teal-400 {
  color: rgba(22, 189, 202, var(--color-opacity)) !important;
  border-color: rgba(22, 189, 202, var(--border-opacity));
}

.border-teal-400 {
  border-color: rgba(22, 189, 202, var(--border-opacity)) !important;
}

.bg-teal-500 {
  background-color: rgba(6, 148, 162, var(--bg-opacity)) !important;
}

.text-teal-500 {
  color: rgba(6, 148, 162, var(--color-opacity)) !important;
  border-color: rgba(6, 148, 162, var(--border-opacity));
}

.border-teal-500 {
  border-color: rgba(6, 148, 162, var(--border-opacity)) !important;
}

.bg-teal-600 {
  background-color: rgba(4, 116, 129, var(--bg-opacity)) !important;
}

.text-teal-600 {
  color: rgba(4, 116, 129, var(--color-opacity)) !important;
  border-color: rgba(4, 116, 129, var(--border-opacity));
}

.border-teal-600 {
  border-color: rgba(4, 116, 129, var(--border-opacity)) !important;
}

.bg-teal-700 {
  background-color: rgba(3, 102, 114, var(--bg-opacity)) !important;
}

.text-teal-700 {
  color: rgba(3, 102, 114, var(--color-opacity)) !important;
  border-color: rgba(3, 102, 114, var(--border-opacity));
}

.border-teal-700 {
  border-color: rgba(3, 102, 114, var(--border-opacity)) !important;
}

.bg-teal-800 {
  background-color: rgba(5, 80, 92, var(--bg-opacity)) !important;
}

.text-teal-800 {
  color: rgba(5, 80, 92, var(--color-opacity)) !important;
  border-color: rgba(5, 80, 92, var(--border-opacity));
}

.border-teal-800 {
  border-color: rgba(5, 80, 92, var(--border-opacity)) !important;
}

.bg-teal-900 {
  background-color: rgba(1, 68, 81, var(--bg-opacity)) !important;
}

.text-teal-900 {
  color: rgba(1, 68, 81, var(--color-opacity)) !important;
  border-color: rgba(1, 68, 81, var(--border-opacity));
}

.border-teal-900 {
  border-color: rgba(1, 68, 81, var(--border-opacity)) !important;
}

.bg-blue-100 {
  background-color: rgba(225, 239, 254, var(--bg-opacity)) !important;
}

.text-blue-100 {
  color: rgba(225, 239, 254, var(--color-opacity)) !important;
  border-color: rgba(225, 239, 254, var(--border-opacity));
}

.border-blue-100 {
  border-color: rgba(225, 239, 254, var(--border-opacity)) !important;
}

.bg-blue-200 {
  background-color: rgba(195, 221, 253, var(--bg-opacity)) !important;
}

.text-blue-200 {
  color: rgba(195, 221, 253, var(--color-opacity)) !important;
  border-color: rgba(195, 221, 253, var(--border-opacity));
}

.border-blue-200 {
  border-color: rgba(195, 221, 253, var(--border-opacity)) !important;
}

.bg-blue-300 {
  background-color: rgba(164, 202, 254, var(--bg-opacity)) !important;
}

.text-blue-300 {
  color: rgba(164, 202, 254, var(--color-opacity)) !important;
  border-color: rgba(164, 202, 254, var(--border-opacity));
}

.border-blue-300 {
  border-color: rgba(164, 202, 254, var(--border-opacity)) !important;
}

.bg-blue-400 {
  background-color: rgba(118, 169, 250, var(--bg-opacity)) !important;
}

.text-blue-400 {
  color: rgba(118, 169, 250, var(--color-opacity)) !important;
  border-color: rgba(118, 169, 250, var(--border-opacity));
}

.border-blue-400 {
  border-color: rgba(118, 169, 250, var(--border-opacity)) !important;
}

.bg-blue-500 {
  background-color: rgba(63, 131, 248, var(--bg-opacity)) !important;
}

.text-blue-500 {
  color: rgba(63, 131, 248, var(--color-opacity)) !important;
  border-color: rgba(63, 131, 248, var(--border-opacity));
}

.border-blue-500 {
  border-color: rgba(63, 131, 248, var(--border-opacity)) !important;
}

.bg-blue-600 {
  background-color: rgba(28, 100, 242, var(--bg-opacity)) !important;
}

.text-blue-600 {
  color: rgba(28, 100, 242, var(--color-opacity)) !important;
  border-color: rgba(28, 100, 242, var(--border-opacity));
}

.border-blue-600 {
  border-color: rgba(28, 100, 242, var(--border-opacity)) !important;
}

.bg-blue-700 {
  background-color: rgba(26, 86, 219, var(--bg-opacity)) !important;
}

.text-blue-700 {
  color: rgba(26, 86, 219, var(--color-opacity)) !important;
  border-color: rgba(26, 86, 219, var(--border-opacity));
}

.border-blue-700 {
  border-color: rgba(26, 86, 219, var(--border-opacity)) !important;
}

.bg-blue-800 {
  background-color: rgba(30, 66, 159, var(--bg-opacity)) !important;
}

.text-blue-800 {
  color: rgba(30, 66, 159, var(--color-opacity)) !important;
  border-color: rgba(30, 66, 159, var(--border-opacity));
}

.border-blue-800 {
  border-color: rgba(30, 66, 159, var(--border-opacity)) !important;
}

.bg-blue-900 {
  background-color: rgba(35, 56, 118, var(--bg-opacity)) !important;
}

.text-blue-900 {
  color: rgba(35, 56, 118, var(--color-opacity)) !important;
  border-color: rgba(35, 56, 118, var(--border-opacity));
}

.border-blue-900 {
  border-color: rgba(35, 56, 118, var(--border-opacity)) !important;
}

.bg-indigo-100 {
  background-color: rgba(229, 237, 255, var(--bg-opacity)) !important;
}

.text-indigo-100 {
  color: rgba(229, 237, 255, var(--color-opacity)) !important;
  border-color: rgba(229, 237, 255, var(--border-opacity));
}

.border-indigo-100 {
  border-color: rgba(229, 237, 255, var(--border-opacity)) !important;
}

.bg-indigo-200 {
  background-color: rgba(205, 219, 254, var(--bg-opacity)) !important;
}

.text-indigo-200 {
  color: rgba(205, 219, 254, var(--color-opacity)) !important;
  border-color: rgba(205, 219, 254, var(--border-opacity));
}

.border-indigo-200 {
  border-color: rgba(205, 219, 254, var(--border-opacity)) !important;
}

.bg-indigo-300 {
  background-color: rgba(180, 198, 252, var(--bg-opacity)) !important;
}

.text-indigo-300 {
  color: rgba(180, 198, 252, var(--color-opacity)) !important;
  border-color: rgba(180, 198, 252, var(--border-opacity));
}

.border-indigo-300 {
  border-color: rgba(180, 198, 252, var(--border-opacity)) !important;
}

.bg-indigo-400 {
  background-color: rgba(141, 162, 251, var(--bg-opacity)) !important;
}

.text-indigo-400 {
  color: rgba(141, 162, 251, var(--color-opacity)) !important;
  border-color: rgba(141, 162, 251, var(--border-opacity));
}

.border-indigo-400 {
  border-color: rgba(141, 162, 251, var(--border-opacity)) !important;
}

.bg-indigo-500 {
  background-color: rgba(104, 117, 245, var(--bg-opacity)) !important;
}

.text-indigo-500 {
  color: rgba(104, 117, 245, var(--color-opacity)) !important;
  border-color: rgba(104, 117, 245, var(--border-opacity));
}

.border-indigo-500 {
  border-color: rgba(104, 117, 245, var(--border-opacity)) !important;
}

.bg-indigo-600 {
  background-color: rgba(88, 80, 236, var(--bg-opacity)) !important;
}

.text-indigo-600 {
  color: rgba(88, 80, 236, var(--color-opacity)) !important;
  border-color: rgba(88, 80, 236, var(--border-opacity));
}

.border-indigo-600 {
  border-color: rgba(88, 80, 236, var(--border-opacity)) !important;
}

.bg-indigo-700 {
  background-color: rgba(81, 69, 205, var(--bg-opacity)) !important;
}

.text-indigo-700 {
  color: rgba(81, 69, 205, var(--color-opacity)) !important;
  border-color: rgba(81, 69, 205, var(--border-opacity));
}

.border-indigo-700 {
  border-color: rgba(81, 69, 205, var(--border-opacity)) !important;
}

.bg-indigo-800 {
  background-color: rgba(66, 56, 157, var(--bg-opacity)) !important;
}

.text-indigo-800 {
  color: rgba(66, 56, 157, var(--color-opacity)) !important;
  border-color: rgba(66, 56, 157, var(--border-opacity));
}

.border-indigo-800 {
  border-color: rgba(66, 56, 157, var(--border-opacity)) !important;
}

.bg-indigo-900 {
  background-color: rgba(54, 47, 120, var(--bg-opacity)) !important;
}

.text-indigo-900 {
  color: rgba(54, 47, 120, var(--color-opacity)) !important;
  border-color: rgba(54, 47, 120, var(--border-opacity));
}

.border-indigo-900 {
  border-color: rgba(54, 47, 120, var(--border-opacity)) !important;
}

.bg-purple-100 {
  background-color: rgba(237, 235, 254, var(--bg-opacity)) !important;
}

.text-purple-100 {
  color: rgba(237, 235, 254, var(--color-opacity)) !important;
  border-color: rgba(237, 235, 254, var(--border-opacity));
}

.border-purple-100 {
  border-color: rgba(237, 235, 254, var(--border-opacity)) !important;
}

.bg-purple-200 {
  background-color: rgba(220, 215, 254, var(--bg-opacity)) !important;
}

.text-purple-200 {
  color: rgba(220, 215, 254, var(--color-opacity)) !important;
  border-color: rgba(220, 215, 254, var(--border-opacity));
}

.border-purple-200 {
  border-color: rgba(220, 215, 254, var(--border-opacity)) !important;
}

.bg-purple-300 {
  background-color: rgba(202, 191, 253, var(--bg-opacity)) !important;
}

.text-purple-300 {
  color: rgba(202, 191, 253, var(--color-opacity)) !important;
  border-color: rgba(202, 191, 253, var(--border-opacity));
}

.border-purple-300 {
  border-color: rgba(202, 191, 253, var(--border-opacity)) !important;
}

.bg-purple-400 {
  background-color: rgba(172, 148, 250, var(--bg-opacity)) !important;
}

.text-purple-400 {
  color: rgba(172, 148, 250, var(--color-opacity)) !important;
  border-color: rgba(172, 148, 250, var(--border-opacity));
}

.border-purple-400 {
  border-color: rgba(172, 148, 250, var(--border-opacity)) !important;
}

.bg-purple-500 {
  background-color: rgba(144, 97, 249, var(--bg-opacity)) !important;
}

.text-purple-500 {
  color: rgba(144, 97, 249, var(--color-opacity)) !important;
  border-color: rgba(144, 97, 249, var(--border-opacity));
}

.border-purple-500 {
  border-color: rgba(144, 97, 249, var(--border-opacity)) !important;
}

.bg-purple-600 {
  background-color: rgba(126, 58, 242, var(--bg-opacity)) !important;
}

.text-purple-600 {
  color: rgba(126, 58, 242, var(--color-opacity)) !important;
  border-color: rgba(126, 58, 242, var(--border-opacity));
}

.border-purple-600 {
  border-color: rgba(126, 58, 242, var(--border-opacity)) !important;
}

.bg-purple-700 {
  background-color: rgba(108, 43, 217, var(--bg-opacity)) !important;
}

.text-purple-700 {
  color: rgba(108, 43, 217, var(--color-opacity)) !important;
  border-color: rgba(108, 43, 217, var(--border-opacity));
}

.border-purple-700 {
  border-color: rgba(108, 43, 217, var(--border-opacity)) !important;
}

.bg-purple-800 {
  background-color: rgba(85, 33, 181, var(--bg-opacity)) !important;
}

.text-purple-800 {
  color: rgba(85, 33, 181, var(--color-opacity)) !important;
  border-color: rgba(85, 33, 181, var(--border-opacity));
}

.border-purple-800 {
  border-color: rgba(85, 33, 181, var(--border-opacity)) !important;
}

.bg-purple-900 {
  background-color: rgba(74, 29, 150, var(--bg-opacity)) !important;
}

.text-purple-900 {
  color: rgba(74, 29, 150, var(--color-opacity)) !important;
  border-color: rgba(74, 29, 150, var(--border-opacity));
}

.border-purple-900 {
  border-color: rgba(74, 29, 150, var(--border-opacity)) !important;
}

.bg-gray-000 {
  background-color: rgba(248, 249, 250, var(--bg-opacity)) !important;
}

.text-gray-000 {
  color: rgba(248, 249, 250, var(--color-opacity)) !important;
  border-color: rgba(248, 249, 250, var(--border-opacity));
}

.border-gray-000 {
  border-color: rgba(248, 249, 250, var(--border-opacity)) !important;
}

.bg-gray-100 {
  background-color: rgba(241, 243, 245, var(--bg-opacity)) !important;
}

.text-gray-100 {
  color: rgba(241, 243, 245, var(--color-opacity)) !important;
  border-color: rgba(241, 243, 245, var(--border-opacity));
}

.border-gray-100 {
  border-color: rgba(241, 243, 245, var(--border-opacity)) !important;
}

.bg-gray-200 {
  background-color: rgba(233, 236, 239, var(--bg-opacity)) !important;
}

.text-gray-200 {
  color: rgba(233, 236, 239, var(--color-opacity)) !important;
  border-color: rgba(233, 236, 239, var(--border-opacity));
}

.border-gray-200 {
  border-color: rgba(233, 236, 239, var(--border-opacity)) !important;
}

.bg-gray-300 {
  background-color: rgba(222, 226, 230, var(--bg-opacity)) !important;
}

.text-gray-300 {
  color: rgba(222, 226, 230, var(--color-opacity)) !important;
  border-color: rgba(222, 226, 230, var(--border-opacity));
}

.border-gray-300 {
  border-color: rgba(222, 226, 230, var(--border-opacity)) !important;
}

.bg-gray-400 {
  background-color: rgba(206, 212, 218, var(--bg-opacity)) !important;
}

.text-gray-400 {
  color: rgba(206, 212, 218, var(--color-opacity)) !important;
  border-color: rgba(206, 212, 218, var(--border-opacity));
}

.border-gray-400 {
  border-color: rgba(206, 212, 218, var(--border-opacity)) !important;
}

.bg-gray-500 {
  background-color: rgba(173, 181, 189, var(--bg-opacity)) !important;
}

.text-gray-500 {
  color: rgba(173, 181, 189, var(--color-opacity)) !important;
  border-color: rgba(173, 181, 189, var(--border-opacity));
}

.border-gray-500 {
  border-color: rgba(173, 181, 189, var(--border-opacity)) !important;
}

.bg-gray-600 {
  background-color: rgba(134, 142, 150, var(--bg-opacity)) !important;
}

.text-gray-600 {
  color: rgba(134, 142, 150, var(--color-opacity)) !important;
  border-color: rgba(134, 142, 150, var(--border-opacity));
}

.border-gray-600 {
  border-color: rgba(134, 142, 150, var(--border-opacity)) !important;
}

.bg-gray-700 {
  background-color: rgba(73, 80, 87, var(--bg-opacity)) !important;
}

.text-gray-700 {
  color: rgba(73, 80, 87, var(--color-opacity)) !important;
  border-color: rgba(73, 80, 87, var(--border-opacity));
}

.border-gray-700 {
  border-color: rgba(73, 80, 87, var(--border-opacity)) !important;
}

.bg-gray-800 {
  background-color: rgba(52, 58, 64, var(--bg-opacity)) !important;
}

.text-gray-800 {
  color: rgba(52, 58, 64, var(--color-opacity)) !important;
  border-color: rgba(52, 58, 64, var(--border-opacity));
}

.border-gray-800 {
  border-color: rgba(52, 58, 64, var(--border-opacity)) !important;
}

.bg-gray-900 {
  background-color: rgba(33, 37, 41, var(--bg-opacity)) !important;
}

.text-gray-900 {
  color: rgba(33, 37, 41, var(--color-opacity)) !important;
  border-color: rgba(33, 37, 41, var(--border-opacity));
}

.border-gray-900 {
  border-color: rgba(33, 37, 41, var(--border-opacity)) !important;
}

/* Margin */
.m-0 {
  margin: 0rem !important;
}

.mt-0,
.my-0 {
  margin-top: 0rem !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0rem !important;
}

.ml-0,
.mx-0 {
  margin-left: 0rem !important;
}

.mr-0,
.mx-0 {
  margin-right: 0rem !important;
}

/* Spacing */
.p-0 {
  padding: 0rem !important;
}

.pt-0,
.py-0 {
  padding-top: 0rem !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0rem !important;
}

.pl-0,
.px-0 {
  padding-left: 0rem !important;
}

.pr-0,
.px-0 {
  padding-right: 0rem !important;
}

/* Margin */
.m-1 {
  margin: 0.5rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.5rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.5rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.5rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.5rem !important;
}

/* Spacing */
.p-1 {
  padding: 0.5rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.5rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.5rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.5rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.5rem !important;
}

/* Margin */
.m-2 {
  margin: 1rem !important;
}

.mt-2,
.my-2 {
  margin-top: 1rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 1rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 1rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 1rem !important;
}

/* Spacing */
.p-2 {
  padding: 1rem !important;
}

.pt-2,
.py-2 {
  padding-top: 1rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 1rem !important;
}

.pl-2,
.px-2 {
  padding-left: 1rem !important;
}

.pr-2,
.px-2 {
  padding-right: 1rem !important;
}

/* Margin */
.m-3 {
  margin: 1.5rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1.5rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1.5rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1.5rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1.5rem !important;
}

/* Spacing */
.p-3 {
  padding: 1.5rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1.5rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1.5rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1.5rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1.5rem !important;
}

/* Margin */
.m-4 {
  margin: 2rem !important;
}

.mt-4,
.my-4 {
  margin-top: 2rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 2rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 2rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 2rem !important;
}

/* Spacing */
.p-4 {
  padding: 2rem !important;
}

.pt-4,
.py-4 {
  padding-top: 2rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 2rem !important;
}

.pl-4,
.px-4 {
  padding-left: 2rem !important;
}

.pr-4,
.px-4 {
  padding-right: 2rem !important;
}

/* Margin */
.m-5 {
  margin: 2.5rem !important;
}

.mt-5,
.my-5 {
  margin-top: 2.5rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 2.5rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 2.5rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 2.5rem !important;
}

/* Spacing */
.p-5 {
  padding: 2.5rem !important;
}

.pt-5,
.py-5 {
  padding-top: 2.5rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 2.5rem !important;
}

.pl-5,
.px-5 {
  padding-left: 2.5rem !important;
}

.pr-5,
.px-5 {
  padding-right: 2.5rem !important;
}

/* Margin */
.m-6 {
  margin: 3rem !important;
}

.mt-6,
.my-6 {
  margin-top: 3rem !important;
}

.mb-6,
.my-6 {
  margin-bottom: 3rem !important;
}

.ml-6,
.mx-6 {
  margin-left: 3rem !important;
}

.mr-6,
.mx-6 {
  margin-right: 3rem !important;
}

/* Spacing */
.p-6 {
  padding: 3rem !important;
}

.pt-6,
.py-6 {
  padding-top: 3rem !important;
}

.pb-6,
.py-6 {
  padding-bottom: 3rem !important;
}

.pl-6,
.px-6 {
  padding-left: 3rem !important;
}

.pr-6,
.px-6 {
  padding-right: 3rem !important;
}

/* Margin */
.m-8 {
  margin: 4rem !important;
}

.mt-8,
.my-8 {
  margin-top: 4rem !important;
}

.mb-8,
.my-8 {
  margin-bottom: 4rem !important;
}

.ml-8,
.mx-8 {
  margin-left: 4rem !important;
}

.mr-8,
.mx-8 {
  margin-right: 4rem !important;
}

/* Spacing */
.p-8 {
  padding: 4rem !important;
}

.pt-8,
.py-8 {
  padding-top: 4rem !important;
}

.pb-8,
.py-8 {
  padding-bottom: 4rem !important;
}

.pl-8,
.px-8 {
  padding-left: 4rem !important;
}

.pr-8,
.px-8 {
  padding-right: 4rem !important;
}

/* Margin */
.m-10 {
  margin: 5rem !important;
}

.mt-10,
.my-10 {
  margin-top: 5rem !important;
}

.mb-10,
.my-10 {
  margin-bottom: 5rem !important;
}

.ml-10,
.mx-10 {
  margin-left: 5rem !important;
}

.mr-10,
.mx-10 {
  margin-right: 5rem !important;
}

/* Spacing */
.p-10 {
  padding: 5rem !important;
}

.pt-10,
.py-10 {
  padding-top: 5rem !important;
}

.pb-10,
.py-10 {
  padding-bottom: 5rem !important;
}

.pl-10,
.px-10 {
  padding-left: 5rem !important;
}

.pr-10,
.px-10 {
  padding-right: 5rem !important;
}

/* Margin */
.m-12 {
  margin: 6rem !important;
}

.mt-12,
.my-12 {
  margin-top: 6rem !important;
}

.mb-12,
.my-12 {
  margin-bottom: 6rem !important;
}

.ml-12,
.mx-12 {
  margin-left: 6rem !important;
}

.mr-12,
.mx-12 {
  margin-right: 6rem !important;
}

/* Spacing */
.p-12 {
  padding: 6rem !important;
}

.pt-12,
.py-12 {
  padding-top: 6rem !important;
}

.pb-12,
.py-12 {
  padding-bottom: 6rem !important;
}

.pl-12,
.px-12 {
  padding-left: 6rem !important;
}

.pr-12,
.px-12 {
  padding-right: 6rem !important;
}

/* Margin */
.m-16 {
  margin: 8rem !important;
}

.mt-16,
.my-16 {
  margin-top: 8rem !important;
}

.mb-16,
.my-16 {
  margin-bottom: 8rem !important;
}

.ml-16,
.mx-16 {
  margin-left: 8rem !important;
}

.mr-16,
.mx-16 {
  margin-right: 8rem !important;
}

/* Spacing */
.p-16 {
  padding: 8rem !important;
}

.pt-16,
.py-16 {
  padding-top: 8rem !important;
}

.pb-16,
.py-16 {
  padding-bottom: 8rem !important;
}

.pl-16,
.px-16 {
  padding-left: 8rem !important;
}

.pr-16,
.px-16 {
  padding-right: 8rem !important;
}

/* Margin */
.m-20 {
  margin: 10rem !important;
}

.mt-20,
.my-20 {
  margin-top: 10rem !important;
}

.mb-20,
.my-20 {
  margin-bottom: 10rem !important;
}

.ml-20,
.mx-20 {
  margin-left: 10rem !important;
}

.mr-20,
.mx-20 {
  margin-right: 10rem !important;
}

/* Spacing */
.p-20 {
  padding: 10rem !important;
}

.pt-20,
.py-20 {
  padding-top: 10rem !important;
}

.pb-20,
.py-20 {
  padding-bottom: 10rem !important;
}

.pl-20,
.px-20 {
  padding-left: 10rem !important;
}

.pr-20,
.px-20 {
  padding-right: 10rem !important;
}

/* Margin */
.m-24 {
  margin: 12rem !important;
}

.mt-24,
.my-24 {
  margin-top: 12rem !important;
}

.mb-24,
.my-24 {
  margin-bottom: 12rem !important;
}

.ml-24,
.mx-24 {
  margin-left: 12rem !important;
}

.mr-24,
.mx-24 {
  margin-right: 12rem !important;
}

/* Spacing */
.p-24 {
  padding: 12rem !important;
}

.pt-24,
.py-24 {
  padding-top: 12rem !important;
}

.pb-24,
.py-24 {
  padding-bottom: 12rem !important;
}

.pl-24,
.px-24 {
  padding-left: 12rem !important;
}

.pr-24,
.px-24 {
  padding-right: 12rem !important;
}

/* Margin */
.m-32 {
  margin: 16rem !important;
}

.mt-32,
.my-32 {
  margin-top: 16rem !important;
}

.mb-32,
.my-32 {
  margin-bottom: 16rem !important;
}

.ml-32,
.mx-32 {
  margin-left: 16rem !important;
}

.mr-32,
.mx-32 {
  margin-right: 16rem !important;
}

/* Spacing */
.p-32 {
  padding: 16rem !important;
}

.pt-32,
.py-32 {
  padding-top: 16rem !important;
}

.pb-32,
.py-32 {
  padding-bottom: 16rem !important;
}

.pl-32,
.px-32 {
  padding-left: 16rem !important;
}

.pr-32,
.px-32 {
  padding-right: 16rem !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

@media screen and (min-width: 640px) {
  /* Margin */
  .m-0-sm {
    margin: 0rem !important;
  }
  .mt-0-sm,
.my-0-sm {
    margin-top: 0rem !important;
  }
  .mb-0-sm,
.my-0-sm {
    margin-bottom: 0rem !important;
  }
  .ml-0-sm,
.mx-0-sm {
    margin-left: 0rem !important;
  }
  .mr-0-sm,
.mx-0-sm {
    margin-right: 0rem !important;
  }
  /* Spacing */
  .p-0-sm {
    padding: 0rem !important;
  }
  .pt-0-sm,
.py-0-sm {
    padding-top: 0rem !important;
  }
  .pb-0-sm,
.py-0-sm {
    padding-bottom: 0rem !important;
  }
  .pl-0-sm,
.px-0-sm {
    padding-left: 0rem !important;
  }
  .pr-0-sm,
.px-0-sm {
    padding-right: 0rem !important;
  }
  .ml-auto-sm,
.mx-auto-sm {
    margin-left: auto !important;
  }
  .mr-auto-sm,
.mx-auto-sm {
    margin-right: auto !important;
  }
  .mt-auto-sm,
.my-auto-sm {
    margin-top: auto !important;
  }
  .mb-auto-sm,
.my-auto-sm {
    margin-bottom: auto !important;
  }
}
@media screen and (min-width: 640px) {
  /* Margin */
  .m-1-sm {
    margin: 0.5rem !important;
  }
  .mt-1-sm,
.my-1-sm {
    margin-top: 0.5rem !important;
  }
  .mb-1-sm,
.my-1-sm {
    margin-bottom: 0.5rem !important;
  }
  .ml-1-sm,
.mx-1-sm {
    margin-left: 0.5rem !important;
  }
  .mr-1-sm,
.mx-1-sm {
    margin-right: 0.5rem !important;
  }
  /* Spacing */
  .p-1-sm {
    padding: 0.5rem !important;
  }
  .pt-1-sm,
.py-1-sm {
    padding-top: 0.5rem !important;
  }
  .pb-1-sm,
.py-1-sm {
    padding-bottom: 0.5rem !important;
  }
  .pl-1-sm,
.px-1-sm {
    padding-left: 0.5rem !important;
  }
  .pr-1-sm,
.px-1-sm {
    padding-right: 0.5rem !important;
  }
  .ml-auto-sm,
.mx-auto-sm {
    margin-left: auto !important;
  }
  .mr-auto-sm,
.mx-auto-sm {
    margin-right: auto !important;
  }
  .mt-auto-sm,
.my-auto-sm {
    margin-top: auto !important;
  }
  .mb-auto-sm,
.my-auto-sm {
    margin-bottom: auto !important;
  }
}
@media screen and (min-width: 640px) {
  /* Margin */
  .m-2-sm {
    margin: 1rem !important;
  }
  .mt-2-sm,
.my-2-sm {
    margin-top: 1rem !important;
  }
  .mb-2-sm,
.my-2-sm {
    margin-bottom: 1rem !important;
  }
  .ml-2-sm,
.mx-2-sm {
    margin-left: 1rem !important;
  }
  .mr-2-sm,
.mx-2-sm {
    margin-right: 1rem !important;
  }
  /* Spacing */
  .p-2-sm {
    padding: 1rem !important;
  }
  .pt-2-sm,
.py-2-sm {
    padding-top: 1rem !important;
  }
  .pb-2-sm,
.py-2-sm {
    padding-bottom: 1rem !important;
  }
  .pl-2-sm,
.px-2-sm {
    padding-left: 1rem !important;
  }
  .pr-2-sm,
.px-2-sm {
    padding-right: 1rem !important;
  }
  .ml-auto-sm,
.mx-auto-sm {
    margin-left: auto !important;
  }
  .mr-auto-sm,
.mx-auto-sm {
    margin-right: auto !important;
  }
  .mt-auto-sm,
.my-auto-sm {
    margin-top: auto !important;
  }
  .mb-auto-sm,
.my-auto-sm {
    margin-bottom: auto !important;
  }
}
@media screen and (min-width: 640px) {
  /* Margin */
  .m-3-sm {
    margin: 1.5rem !important;
  }
  .mt-3-sm,
.my-3-sm {
    margin-top: 1.5rem !important;
  }
  .mb-3-sm,
.my-3-sm {
    margin-bottom: 1.5rem !important;
  }
  .ml-3-sm,
.mx-3-sm {
    margin-left: 1.5rem !important;
  }
  .mr-3-sm,
.mx-3-sm {
    margin-right: 1.5rem !important;
  }
  /* Spacing */
  .p-3-sm {
    padding: 1.5rem !important;
  }
  .pt-3-sm,
.py-3-sm {
    padding-top: 1.5rem !important;
  }
  .pb-3-sm,
.py-3-sm {
    padding-bottom: 1.5rem !important;
  }
  .pl-3-sm,
.px-3-sm {
    padding-left: 1.5rem !important;
  }
  .pr-3-sm,
.px-3-sm {
    padding-right: 1.5rem !important;
  }
  .ml-auto-sm,
.mx-auto-sm {
    margin-left: auto !important;
  }
  .mr-auto-sm,
.mx-auto-sm {
    margin-right: auto !important;
  }
  .mt-auto-sm,
.my-auto-sm {
    margin-top: auto !important;
  }
  .mb-auto-sm,
.my-auto-sm {
    margin-bottom: auto !important;
  }
}
@media screen and (min-width: 640px) {
  /* Margin */
  .m-4-sm {
    margin: 2rem !important;
  }
  .mt-4-sm,
.my-4-sm {
    margin-top: 2rem !important;
  }
  .mb-4-sm,
.my-4-sm {
    margin-bottom: 2rem !important;
  }
  .ml-4-sm,
.mx-4-sm {
    margin-left: 2rem !important;
  }
  .mr-4-sm,
.mx-4-sm {
    margin-right: 2rem !important;
  }
  /* Spacing */
  .p-4-sm {
    padding: 2rem !important;
  }
  .pt-4-sm,
.py-4-sm {
    padding-top: 2rem !important;
  }
  .pb-4-sm,
.py-4-sm {
    padding-bottom: 2rem !important;
  }
  .pl-4-sm,
.px-4-sm {
    padding-left: 2rem !important;
  }
  .pr-4-sm,
.px-4-sm {
    padding-right: 2rem !important;
  }
  .ml-auto-sm,
.mx-auto-sm {
    margin-left: auto !important;
  }
  .mr-auto-sm,
.mx-auto-sm {
    margin-right: auto !important;
  }
  .mt-auto-sm,
.my-auto-sm {
    margin-top: auto !important;
  }
  .mb-auto-sm,
.my-auto-sm {
    margin-bottom: auto !important;
  }
}
@media screen and (min-width: 640px) {
  /* Margin */
  .m-5-sm {
    margin: 2.5rem !important;
  }
  .mt-5-sm,
.my-5-sm {
    margin-top: 2.5rem !important;
  }
  .mb-5-sm,
.my-5-sm {
    margin-bottom: 2.5rem !important;
  }
  .ml-5-sm,
.mx-5-sm {
    margin-left: 2.5rem !important;
  }
  .mr-5-sm,
.mx-5-sm {
    margin-right: 2.5rem !important;
  }
  /* Spacing */
  .p-5-sm {
    padding: 2.5rem !important;
  }
  .pt-5-sm,
.py-5-sm {
    padding-top: 2.5rem !important;
  }
  .pb-5-sm,
.py-5-sm {
    padding-bottom: 2.5rem !important;
  }
  .pl-5-sm,
.px-5-sm {
    padding-left: 2.5rem !important;
  }
  .pr-5-sm,
.px-5-sm {
    padding-right: 2.5rem !important;
  }
  .ml-auto-sm,
.mx-auto-sm {
    margin-left: auto !important;
  }
  .mr-auto-sm,
.mx-auto-sm {
    margin-right: auto !important;
  }
  .mt-auto-sm,
.my-auto-sm {
    margin-top: auto !important;
  }
  .mb-auto-sm,
.my-auto-sm {
    margin-bottom: auto !important;
  }
}
@media screen and (min-width: 640px) {
  /* Margin */
  .m-6-sm {
    margin: 3rem !important;
  }
  .mt-6-sm,
.my-6-sm {
    margin-top: 3rem !important;
  }
  .mb-6-sm,
.my-6-sm {
    margin-bottom: 3rem !important;
  }
  .ml-6-sm,
.mx-6-sm {
    margin-left: 3rem !important;
  }
  .mr-6-sm,
.mx-6-sm {
    margin-right: 3rem !important;
  }
  /* Spacing */
  .p-6-sm {
    padding: 3rem !important;
  }
  .pt-6-sm,
.py-6-sm {
    padding-top: 3rem !important;
  }
  .pb-6-sm,
.py-6-sm {
    padding-bottom: 3rem !important;
  }
  .pl-6-sm,
.px-6-sm {
    padding-left: 3rem !important;
  }
  .pr-6-sm,
.px-6-sm {
    padding-right: 3rem !important;
  }
  .ml-auto-sm,
.mx-auto-sm {
    margin-left: auto !important;
  }
  .mr-auto-sm,
.mx-auto-sm {
    margin-right: auto !important;
  }
  .mt-auto-sm,
.my-auto-sm {
    margin-top: auto !important;
  }
  .mb-auto-sm,
.my-auto-sm {
    margin-bottom: auto !important;
  }
}
@media screen and (min-width: 640px) {
  /* Margin */
  .m-8-sm {
    margin: 4rem !important;
  }
  .mt-8-sm,
.my-8-sm {
    margin-top: 4rem !important;
  }
  .mb-8-sm,
.my-8-sm {
    margin-bottom: 4rem !important;
  }
  .ml-8-sm,
.mx-8-sm {
    margin-left: 4rem !important;
  }
  .mr-8-sm,
.mx-8-sm {
    margin-right: 4rem !important;
  }
  /* Spacing */
  .p-8-sm {
    padding: 4rem !important;
  }
  .pt-8-sm,
.py-8-sm {
    padding-top: 4rem !important;
  }
  .pb-8-sm,
.py-8-sm {
    padding-bottom: 4rem !important;
  }
  .pl-8-sm,
.px-8-sm {
    padding-left: 4rem !important;
  }
  .pr-8-sm,
.px-8-sm {
    padding-right: 4rem !important;
  }
  .ml-auto-sm,
.mx-auto-sm {
    margin-left: auto !important;
  }
  .mr-auto-sm,
.mx-auto-sm {
    margin-right: auto !important;
  }
  .mt-auto-sm,
.my-auto-sm {
    margin-top: auto !important;
  }
  .mb-auto-sm,
.my-auto-sm {
    margin-bottom: auto !important;
  }
}
@media screen and (min-width: 640px) {
  /* Margin */
  .m-10-sm {
    margin: 5rem !important;
  }
  .mt-10-sm,
.my-10-sm {
    margin-top: 5rem !important;
  }
  .mb-10-sm,
.my-10-sm {
    margin-bottom: 5rem !important;
  }
  .ml-10-sm,
.mx-10-sm {
    margin-left: 5rem !important;
  }
  .mr-10-sm,
.mx-10-sm {
    margin-right: 5rem !important;
  }
  /* Spacing */
  .p-10-sm {
    padding: 5rem !important;
  }
  .pt-10-sm,
.py-10-sm {
    padding-top: 5rem !important;
  }
  .pb-10-sm,
.py-10-sm {
    padding-bottom: 5rem !important;
  }
  .pl-10-sm,
.px-10-sm {
    padding-left: 5rem !important;
  }
  .pr-10-sm,
.px-10-sm {
    padding-right: 5rem !important;
  }
  .ml-auto-sm,
.mx-auto-sm {
    margin-left: auto !important;
  }
  .mr-auto-sm,
.mx-auto-sm {
    margin-right: auto !important;
  }
  .mt-auto-sm,
.my-auto-sm {
    margin-top: auto !important;
  }
  .mb-auto-sm,
.my-auto-sm {
    margin-bottom: auto !important;
  }
}
@media screen and (min-width: 640px) {
  /* Margin */
  .m-12-sm {
    margin: 6rem !important;
  }
  .mt-12-sm,
.my-12-sm {
    margin-top: 6rem !important;
  }
  .mb-12-sm,
.my-12-sm {
    margin-bottom: 6rem !important;
  }
  .ml-12-sm,
.mx-12-sm {
    margin-left: 6rem !important;
  }
  .mr-12-sm,
.mx-12-sm {
    margin-right: 6rem !important;
  }
  /* Spacing */
  .p-12-sm {
    padding: 6rem !important;
  }
  .pt-12-sm,
.py-12-sm {
    padding-top: 6rem !important;
  }
  .pb-12-sm,
.py-12-sm {
    padding-bottom: 6rem !important;
  }
  .pl-12-sm,
.px-12-sm {
    padding-left: 6rem !important;
  }
  .pr-12-sm,
.px-12-sm {
    padding-right: 6rem !important;
  }
  .ml-auto-sm,
.mx-auto-sm {
    margin-left: auto !important;
  }
  .mr-auto-sm,
.mx-auto-sm {
    margin-right: auto !important;
  }
  .mt-auto-sm,
.my-auto-sm {
    margin-top: auto !important;
  }
  .mb-auto-sm,
.my-auto-sm {
    margin-bottom: auto !important;
  }
}
@media screen and (min-width: 640px) {
  /* Margin */
  .m-16-sm {
    margin: 8rem !important;
  }
  .mt-16-sm,
.my-16-sm {
    margin-top: 8rem !important;
  }
  .mb-16-sm,
.my-16-sm {
    margin-bottom: 8rem !important;
  }
  .ml-16-sm,
.mx-16-sm {
    margin-left: 8rem !important;
  }
  .mr-16-sm,
.mx-16-sm {
    margin-right: 8rem !important;
  }
  /* Spacing */
  .p-16-sm {
    padding: 8rem !important;
  }
  .pt-16-sm,
.py-16-sm {
    padding-top: 8rem !important;
  }
  .pb-16-sm,
.py-16-sm {
    padding-bottom: 8rem !important;
  }
  .pl-16-sm,
.px-16-sm {
    padding-left: 8rem !important;
  }
  .pr-16-sm,
.px-16-sm {
    padding-right: 8rem !important;
  }
  .ml-auto-sm,
.mx-auto-sm {
    margin-left: auto !important;
  }
  .mr-auto-sm,
.mx-auto-sm {
    margin-right: auto !important;
  }
  .mt-auto-sm,
.my-auto-sm {
    margin-top: auto !important;
  }
  .mb-auto-sm,
.my-auto-sm {
    margin-bottom: auto !important;
  }
}
@media screen and (min-width: 640px) {
  /* Margin */
  .m-20-sm {
    margin: 10rem !important;
  }
  .mt-20-sm,
.my-20-sm {
    margin-top: 10rem !important;
  }
  .mb-20-sm,
.my-20-sm {
    margin-bottom: 10rem !important;
  }
  .ml-20-sm,
.mx-20-sm {
    margin-left: 10rem !important;
  }
  .mr-20-sm,
.mx-20-sm {
    margin-right: 10rem !important;
  }
  /* Spacing */
  .p-20-sm {
    padding: 10rem !important;
  }
  .pt-20-sm,
.py-20-sm {
    padding-top: 10rem !important;
  }
  .pb-20-sm,
.py-20-sm {
    padding-bottom: 10rem !important;
  }
  .pl-20-sm,
.px-20-sm {
    padding-left: 10rem !important;
  }
  .pr-20-sm,
.px-20-sm {
    padding-right: 10rem !important;
  }
  .ml-auto-sm,
.mx-auto-sm {
    margin-left: auto !important;
  }
  .mr-auto-sm,
.mx-auto-sm {
    margin-right: auto !important;
  }
  .mt-auto-sm,
.my-auto-sm {
    margin-top: auto !important;
  }
  .mb-auto-sm,
.my-auto-sm {
    margin-bottom: auto !important;
  }
}
@media screen and (min-width: 640px) {
  /* Margin */
  .m-24-sm {
    margin: 12rem !important;
  }
  .mt-24-sm,
.my-24-sm {
    margin-top: 12rem !important;
  }
  .mb-24-sm,
.my-24-sm {
    margin-bottom: 12rem !important;
  }
  .ml-24-sm,
.mx-24-sm {
    margin-left: 12rem !important;
  }
  .mr-24-sm,
.mx-24-sm {
    margin-right: 12rem !important;
  }
  /* Spacing */
  .p-24-sm {
    padding: 12rem !important;
  }
  .pt-24-sm,
.py-24-sm {
    padding-top: 12rem !important;
  }
  .pb-24-sm,
.py-24-sm {
    padding-bottom: 12rem !important;
  }
  .pl-24-sm,
.px-24-sm {
    padding-left: 12rem !important;
  }
  .pr-24-sm,
.px-24-sm {
    padding-right: 12rem !important;
  }
  .ml-auto-sm,
.mx-auto-sm {
    margin-left: auto !important;
  }
  .mr-auto-sm,
.mx-auto-sm {
    margin-right: auto !important;
  }
  .mt-auto-sm,
.my-auto-sm {
    margin-top: auto !important;
  }
  .mb-auto-sm,
.my-auto-sm {
    margin-bottom: auto !important;
  }
}
@media screen and (min-width: 640px) {
  /* Margin */
  .m-32-sm {
    margin: 16rem !important;
  }
  .mt-32-sm,
.my-32-sm {
    margin-top: 16rem !important;
  }
  .mb-32-sm,
.my-32-sm {
    margin-bottom: 16rem !important;
  }
  .ml-32-sm,
.mx-32-sm {
    margin-left: 16rem !important;
  }
  .mr-32-sm,
.mx-32-sm {
    margin-right: 16rem !important;
  }
  /* Spacing */
  .p-32-sm {
    padding: 16rem !important;
  }
  .pt-32-sm,
.py-32-sm {
    padding-top: 16rem !important;
  }
  .pb-32-sm,
.py-32-sm {
    padding-bottom: 16rem !important;
  }
  .pl-32-sm,
.px-32-sm {
    padding-left: 16rem !important;
  }
  .pr-32-sm,
.px-32-sm {
    padding-right: 16rem !important;
  }
  .ml-auto-sm,
.mx-auto-sm {
    margin-left: auto !important;
  }
  .mr-auto-sm,
.mx-auto-sm {
    margin-right: auto !important;
  }
  .mt-auto-sm,
.my-auto-sm {
    margin-top: auto !important;
  }
  .mb-auto-sm,
.my-auto-sm {
    margin-bottom: auto !important;
  }
}
@media screen and (min-width: 768px) {
  /* Margin */
  .m-0-md {
    margin: 0rem !important;
  }
  .mt-0-md,
.my-0-md {
    margin-top: 0rem !important;
  }
  .mb-0-md,
.my-0-md {
    margin-bottom: 0rem !important;
  }
  .ml-0-md,
.mx-0-md {
    margin-left: 0rem !important;
  }
  .mr-0-md,
.mx-0-md {
    margin-right: 0rem !important;
  }
  /* Spacing */
  .p-0-md {
    padding: 0rem !important;
  }
  .pt-0-md,
.py-0-md {
    padding-top: 0rem !important;
  }
  .pb-0-md,
.py-0-md {
    padding-bottom: 0rem !important;
  }
  .pl-0-md,
.px-0-md {
    padding-left: 0rem !important;
  }
  .pr-0-md,
.px-0-md {
    padding-right: 0rem !important;
  }
  .ml-auto-md,
.mx-auto-md {
    margin-left: auto !important;
  }
  .mr-auto-md,
.mx-auto-md {
    margin-right: auto !important;
  }
  .mt-auto-md,
.my-auto-md {
    margin-top: auto !important;
  }
  .mb-auto-md,
.my-auto-md {
    margin-bottom: auto !important;
  }
}
@media screen and (min-width: 768px) {
  /* Margin */
  .m-1-md {
    margin: 0.5rem !important;
  }
  .mt-1-md,
.my-1-md {
    margin-top: 0.5rem !important;
  }
  .mb-1-md,
.my-1-md {
    margin-bottom: 0.5rem !important;
  }
  .ml-1-md,
.mx-1-md {
    margin-left: 0.5rem !important;
  }
  .mr-1-md,
.mx-1-md {
    margin-right: 0.5rem !important;
  }
  /* Spacing */
  .p-1-md {
    padding: 0.5rem !important;
  }
  .pt-1-md,
.py-1-md {
    padding-top: 0.5rem !important;
  }
  .pb-1-md,
.py-1-md {
    padding-bottom: 0.5rem !important;
  }
  .pl-1-md,
.px-1-md {
    padding-left: 0.5rem !important;
  }
  .pr-1-md,
.px-1-md {
    padding-right: 0.5rem !important;
  }
  .ml-auto-md,
.mx-auto-md {
    margin-left: auto !important;
  }
  .mr-auto-md,
.mx-auto-md {
    margin-right: auto !important;
  }
  .mt-auto-md,
.my-auto-md {
    margin-top: auto !important;
  }
  .mb-auto-md,
.my-auto-md {
    margin-bottom: auto !important;
  }
}
@media screen and (min-width: 768px) {
  /* Margin */
  .m-2-md {
    margin: 1rem !important;
  }
  .mt-2-md,
.my-2-md {
    margin-top: 1rem !important;
  }
  .mb-2-md,
.my-2-md {
    margin-bottom: 1rem !important;
  }
  .ml-2-md,
.mx-2-md {
    margin-left: 1rem !important;
  }
  .mr-2-md,
.mx-2-md {
    margin-right: 1rem !important;
  }
  /* Spacing */
  .p-2-md {
    padding: 1rem !important;
  }
  .pt-2-md,
.py-2-md {
    padding-top: 1rem !important;
  }
  .pb-2-md,
.py-2-md {
    padding-bottom: 1rem !important;
  }
  .pl-2-md,
.px-2-md {
    padding-left: 1rem !important;
  }
  .pr-2-md,
.px-2-md {
    padding-right: 1rem !important;
  }
  .ml-auto-md,
.mx-auto-md {
    margin-left: auto !important;
  }
  .mr-auto-md,
.mx-auto-md {
    margin-right: auto !important;
  }
  .mt-auto-md,
.my-auto-md {
    margin-top: auto !important;
  }
  .mb-auto-md,
.my-auto-md {
    margin-bottom: auto !important;
  }
}
@media screen and (min-width: 768px) {
  /* Margin */
  .m-3-md {
    margin: 1.5rem !important;
  }
  .mt-3-md,
.my-3-md {
    margin-top: 1.5rem !important;
  }
  .mb-3-md,
.my-3-md {
    margin-bottom: 1.5rem !important;
  }
  .ml-3-md,
.mx-3-md {
    margin-left: 1.5rem !important;
  }
  .mr-3-md,
.mx-3-md {
    margin-right: 1.5rem !important;
  }
  /* Spacing */
  .p-3-md {
    padding: 1.5rem !important;
  }
  .pt-3-md,
.py-3-md {
    padding-top: 1.5rem !important;
  }
  .pb-3-md,
.py-3-md {
    padding-bottom: 1.5rem !important;
  }
  .pl-3-md,
.px-3-md {
    padding-left: 1.5rem !important;
  }
  .pr-3-md,
.px-3-md {
    padding-right: 1.5rem !important;
  }
  .ml-auto-md,
.mx-auto-md {
    margin-left: auto !important;
  }
  .mr-auto-md,
.mx-auto-md {
    margin-right: auto !important;
  }
  .mt-auto-md,
.my-auto-md {
    margin-top: auto !important;
  }
  .mb-auto-md,
.my-auto-md {
    margin-bottom: auto !important;
  }
}
@media screen and (min-width: 768px) {
  /* Margin */
  .m-4-md {
    margin: 2rem !important;
  }
  .mt-4-md,
.my-4-md {
    margin-top: 2rem !important;
  }
  .mb-4-md,
.my-4-md {
    margin-bottom: 2rem !important;
  }
  .ml-4-md,
.mx-4-md {
    margin-left: 2rem !important;
  }
  .mr-4-md,
.mx-4-md {
    margin-right: 2rem !important;
  }
  /* Spacing */
  .p-4-md {
    padding: 2rem !important;
  }
  .pt-4-md,
.py-4-md {
    padding-top: 2rem !important;
  }
  .pb-4-md,
.py-4-md {
    padding-bottom: 2rem !important;
  }
  .pl-4-md,
.px-4-md {
    padding-left: 2rem !important;
  }
  .pr-4-md,
.px-4-md {
    padding-right: 2rem !important;
  }
  .ml-auto-md,
.mx-auto-md {
    margin-left: auto !important;
  }
  .mr-auto-md,
.mx-auto-md {
    margin-right: auto !important;
  }
  .mt-auto-md,
.my-auto-md {
    margin-top: auto !important;
  }
  .mb-auto-md,
.my-auto-md {
    margin-bottom: auto !important;
  }
}
@media screen and (min-width: 768px) {
  /* Margin */
  .m-5-md {
    margin: 2.5rem !important;
  }
  .mt-5-md,
.my-5-md {
    margin-top: 2.5rem !important;
  }
  .mb-5-md,
.my-5-md {
    margin-bottom: 2.5rem !important;
  }
  .ml-5-md,
.mx-5-md {
    margin-left: 2.5rem !important;
  }
  .mr-5-md,
.mx-5-md {
    margin-right: 2.5rem !important;
  }
  /* Spacing */
  .p-5-md {
    padding: 2.5rem !important;
  }
  .pt-5-md,
.py-5-md {
    padding-top: 2.5rem !important;
  }
  .pb-5-md,
.py-5-md {
    padding-bottom: 2.5rem !important;
  }
  .pl-5-md,
.px-5-md {
    padding-left: 2.5rem !important;
  }
  .pr-5-md,
.px-5-md {
    padding-right: 2.5rem !important;
  }
  .ml-auto-md,
.mx-auto-md {
    margin-left: auto !important;
  }
  .mr-auto-md,
.mx-auto-md {
    margin-right: auto !important;
  }
  .mt-auto-md,
.my-auto-md {
    margin-top: auto !important;
  }
  .mb-auto-md,
.my-auto-md {
    margin-bottom: auto !important;
  }
}
@media screen and (min-width: 768px) {
  /* Margin */
  .m-6-md {
    margin: 3rem !important;
  }
  .mt-6-md,
.my-6-md {
    margin-top: 3rem !important;
  }
  .mb-6-md,
.my-6-md {
    margin-bottom: 3rem !important;
  }
  .ml-6-md,
.mx-6-md {
    margin-left: 3rem !important;
  }
  .mr-6-md,
.mx-6-md {
    margin-right: 3rem !important;
  }
  /* Spacing */
  .p-6-md {
    padding: 3rem !important;
  }
  .pt-6-md,
.py-6-md {
    padding-top: 3rem !important;
  }
  .pb-6-md,
.py-6-md {
    padding-bottom: 3rem !important;
  }
  .pl-6-md,
.px-6-md {
    padding-left: 3rem !important;
  }
  .pr-6-md,
.px-6-md {
    padding-right: 3rem !important;
  }
  .ml-auto-md,
.mx-auto-md {
    margin-left: auto !important;
  }
  .mr-auto-md,
.mx-auto-md {
    margin-right: auto !important;
  }
  .mt-auto-md,
.my-auto-md {
    margin-top: auto !important;
  }
  .mb-auto-md,
.my-auto-md {
    margin-bottom: auto !important;
  }
}
@media screen and (min-width: 768px) {
  /* Margin */
  .m-8-md {
    margin: 4rem !important;
  }
  .mt-8-md,
.my-8-md {
    margin-top: 4rem !important;
  }
  .mb-8-md,
.my-8-md {
    margin-bottom: 4rem !important;
  }
  .ml-8-md,
.mx-8-md {
    margin-left: 4rem !important;
  }
  .mr-8-md,
.mx-8-md {
    margin-right: 4rem !important;
  }
  /* Spacing */
  .p-8-md {
    padding: 4rem !important;
  }
  .pt-8-md,
.py-8-md {
    padding-top: 4rem !important;
  }
  .pb-8-md,
.py-8-md {
    padding-bottom: 4rem !important;
  }
  .pl-8-md,
.px-8-md {
    padding-left: 4rem !important;
  }
  .pr-8-md,
.px-8-md {
    padding-right: 4rem !important;
  }
  .ml-auto-md,
.mx-auto-md {
    margin-left: auto !important;
  }
  .mr-auto-md,
.mx-auto-md {
    margin-right: auto !important;
  }
  .mt-auto-md,
.my-auto-md {
    margin-top: auto !important;
  }
  .mb-auto-md,
.my-auto-md {
    margin-bottom: auto !important;
  }
}
@media screen and (min-width: 768px) {
  /* Margin */
  .m-10-md {
    margin: 5rem !important;
  }
  .mt-10-md,
.my-10-md {
    margin-top: 5rem !important;
  }
  .mb-10-md,
.my-10-md {
    margin-bottom: 5rem !important;
  }
  .ml-10-md,
.mx-10-md {
    margin-left: 5rem !important;
  }
  .mr-10-md,
.mx-10-md {
    margin-right: 5rem !important;
  }
  /* Spacing */
  .p-10-md {
    padding: 5rem !important;
  }
  .pt-10-md,
.py-10-md {
    padding-top: 5rem !important;
  }
  .pb-10-md,
.py-10-md {
    padding-bottom: 5rem !important;
  }
  .pl-10-md,
.px-10-md {
    padding-left: 5rem !important;
  }
  .pr-10-md,
.px-10-md {
    padding-right: 5rem !important;
  }
  .ml-auto-md,
.mx-auto-md {
    margin-left: auto !important;
  }
  .mr-auto-md,
.mx-auto-md {
    margin-right: auto !important;
  }
  .mt-auto-md,
.my-auto-md {
    margin-top: auto !important;
  }
  .mb-auto-md,
.my-auto-md {
    margin-bottom: auto !important;
  }
}
@media screen and (min-width: 768px) {
  /* Margin */
  .m-12-md {
    margin: 6rem !important;
  }
  .mt-12-md,
.my-12-md {
    margin-top: 6rem !important;
  }
  .mb-12-md,
.my-12-md {
    margin-bottom: 6rem !important;
  }
  .ml-12-md,
.mx-12-md {
    margin-left: 6rem !important;
  }
  .mr-12-md,
.mx-12-md {
    margin-right: 6rem !important;
  }
  /* Spacing */
  .p-12-md {
    padding: 6rem !important;
  }
  .pt-12-md,
.py-12-md {
    padding-top: 6rem !important;
  }
  .pb-12-md,
.py-12-md {
    padding-bottom: 6rem !important;
  }
  .pl-12-md,
.px-12-md {
    padding-left: 6rem !important;
  }
  .pr-12-md,
.px-12-md {
    padding-right: 6rem !important;
  }
  .ml-auto-md,
.mx-auto-md {
    margin-left: auto !important;
  }
  .mr-auto-md,
.mx-auto-md {
    margin-right: auto !important;
  }
  .mt-auto-md,
.my-auto-md {
    margin-top: auto !important;
  }
  .mb-auto-md,
.my-auto-md {
    margin-bottom: auto !important;
  }
}
@media screen and (min-width: 768px) {
  /* Margin */
  .m-16-md {
    margin: 8rem !important;
  }
  .mt-16-md,
.my-16-md {
    margin-top: 8rem !important;
  }
  .mb-16-md,
.my-16-md {
    margin-bottom: 8rem !important;
  }
  .ml-16-md,
.mx-16-md {
    margin-left: 8rem !important;
  }
  .mr-16-md,
.mx-16-md {
    margin-right: 8rem !important;
  }
  /* Spacing */
  .p-16-md {
    padding: 8rem !important;
  }
  .pt-16-md,
.py-16-md {
    padding-top: 8rem !important;
  }
  .pb-16-md,
.py-16-md {
    padding-bottom: 8rem !important;
  }
  .pl-16-md,
.px-16-md {
    padding-left: 8rem !important;
  }
  .pr-16-md,
.px-16-md {
    padding-right: 8rem !important;
  }
  .ml-auto-md,
.mx-auto-md {
    margin-left: auto !important;
  }
  .mr-auto-md,
.mx-auto-md {
    margin-right: auto !important;
  }
  .mt-auto-md,
.my-auto-md {
    margin-top: auto !important;
  }
  .mb-auto-md,
.my-auto-md {
    margin-bottom: auto !important;
  }
}
@media screen and (min-width: 768px) {
  /* Margin */
  .m-20-md {
    margin: 10rem !important;
  }
  .mt-20-md,
.my-20-md {
    margin-top: 10rem !important;
  }
  .mb-20-md,
.my-20-md {
    margin-bottom: 10rem !important;
  }
  .ml-20-md,
.mx-20-md {
    margin-left: 10rem !important;
  }
  .mr-20-md,
.mx-20-md {
    margin-right: 10rem !important;
  }
  /* Spacing */
  .p-20-md {
    padding: 10rem !important;
  }
  .pt-20-md,
.py-20-md {
    padding-top: 10rem !important;
  }
  .pb-20-md,
.py-20-md {
    padding-bottom: 10rem !important;
  }
  .pl-20-md,
.px-20-md {
    padding-left: 10rem !important;
  }
  .pr-20-md,
.px-20-md {
    padding-right: 10rem !important;
  }
  .ml-auto-md,
.mx-auto-md {
    margin-left: auto !important;
  }
  .mr-auto-md,
.mx-auto-md {
    margin-right: auto !important;
  }
  .mt-auto-md,
.my-auto-md {
    margin-top: auto !important;
  }
  .mb-auto-md,
.my-auto-md {
    margin-bottom: auto !important;
  }
}
@media screen and (min-width: 768px) {
  /* Margin */
  .m-24-md {
    margin: 12rem !important;
  }
  .mt-24-md,
.my-24-md {
    margin-top: 12rem !important;
  }
  .mb-24-md,
.my-24-md {
    margin-bottom: 12rem !important;
  }
  .ml-24-md,
.mx-24-md {
    margin-left: 12rem !important;
  }
  .mr-24-md,
.mx-24-md {
    margin-right: 12rem !important;
  }
  /* Spacing */
  .p-24-md {
    padding: 12rem !important;
  }
  .pt-24-md,
.py-24-md {
    padding-top: 12rem !important;
  }
  .pb-24-md,
.py-24-md {
    padding-bottom: 12rem !important;
  }
  .pl-24-md,
.px-24-md {
    padding-left: 12rem !important;
  }
  .pr-24-md,
.px-24-md {
    padding-right: 12rem !important;
  }
  .ml-auto-md,
.mx-auto-md {
    margin-left: auto !important;
  }
  .mr-auto-md,
.mx-auto-md {
    margin-right: auto !important;
  }
  .mt-auto-md,
.my-auto-md {
    margin-top: auto !important;
  }
  .mb-auto-md,
.my-auto-md {
    margin-bottom: auto !important;
  }
}
@media screen and (min-width: 768px) {
  /* Margin */
  .m-32-md {
    margin: 16rem !important;
  }
  .mt-32-md,
.my-32-md {
    margin-top: 16rem !important;
  }
  .mb-32-md,
.my-32-md {
    margin-bottom: 16rem !important;
  }
  .ml-32-md,
.mx-32-md {
    margin-left: 16rem !important;
  }
  .mr-32-md,
.mx-32-md {
    margin-right: 16rem !important;
  }
  /* Spacing */
  .p-32-md {
    padding: 16rem !important;
  }
  .pt-32-md,
.py-32-md {
    padding-top: 16rem !important;
  }
  .pb-32-md,
.py-32-md {
    padding-bottom: 16rem !important;
  }
  .pl-32-md,
.px-32-md {
    padding-left: 16rem !important;
  }
  .pr-32-md,
.px-32-md {
    padding-right: 16rem !important;
  }
  .ml-auto-md,
.mx-auto-md {
    margin-left: auto !important;
  }
  .mr-auto-md,
.mx-auto-md {
    margin-right: auto !important;
  }
  .mt-auto-md,
.my-auto-md {
    margin-top: auto !important;
  }
  .mb-auto-md,
.my-auto-md {
    margin-bottom: auto !important;
  }
}
@media screen and (min-width: 1024px) {
  /* Margin */
  .m-0-lg {
    margin: 0rem !important;
  }
  .mt-0-lg,
.my-0-lg {
    margin-top: 0rem !important;
  }
  .mb-0-lg,
.my-0-lg {
    margin-bottom: 0rem !important;
  }
  .ml-0-lg,
.mx-0-lg {
    margin-left: 0rem !important;
  }
  .mr-0-lg,
.mx-0-lg {
    margin-right: 0rem !important;
  }
  /* Spacing */
  .p-0-lg {
    padding: 0rem !important;
  }
  .pt-0-lg,
.py-0-lg {
    padding-top: 0rem !important;
  }
  .pb-0-lg,
.py-0-lg {
    padding-bottom: 0rem !important;
  }
  .pl-0-lg,
.px-0-lg {
    padding-left: 0rem !important;
  }
  .pr-0-lg,
.px-0-lg {
    padding-right: 0rem !important;
  }
  .ml-auto-lg,
.mx-auto-lg {
    margin-left: auto !important;
  }
  .mr-auto-lg,
.mx-auto-lg {
    margin-right: auto !important;
  }
  .mt-auto-lg,
.my-auto-lg {
    margin-top: auto !important;
  }
  .mb-auto-lg,
.my-auto-lg {
    margin-bottom: auto !important;
  }
}
@media screen and (min-width: 1024px) {
  /* Margin */
  .m-1-lg {
    margin: 0.5rem !important;
  }
  .mt-1-lg,
.my-1-lg {
    margin-top: 0.5rem !important;
  }
  .mb-1-lg,
.my-1-lg {
    margin-bottom: 0.5rem !important;
  }
  .ml-1-lg,
.mx-1-lg {
    margin-left: 0.5rem !important;
  }
  .mr-1-lg,
.mx-1-lg {
    margin-right: 0.5rem !important;
  }
  /* Spacing */
  .p-1-lg {
    padding: 0.5rem !important;
  }
  .pt-1-lg,
.py-1-lg {
    padding-top: 0.5rem !important;
  }
  .pb-1-lg,
.py-1-lg {
    padding-bottom: 0.5rem !important;
  }
  .pl-1-lg,
.px-1-lg {
    padding-left: 0.5rem !important;
  }
  .pr-1-lg,
.px-1-lg {
    padding-right: 0.5rem !important;
  }
  .ml-auto-lg,
.mx-auto-lg {
    margin-left: auto !important;
  }
  .mr-auto-lg,
.mx-auto-lg {
    margin-right: auto !important;
  }
  .mt-auto-lg,
.my-auto-lg {
    margin-top: auto !important;
  }
  .mb-auto-lg,
.my-auto-lg {
    margin-bottom: auto !important;
  }
}
@media screen and (min-width: 1024px) {
  /* Margin */
  .m-2-lg {
    margin: 1rem !important;
  }
  .mt-2-lg,
.my-2-lg {
    margin-top: 1rem !important;
  }
  .mb-2-lg,
.my-2-lg {
    margin-bottom: 1rem !important;
  }
  .ml-2-lg,
.mx-2-lg {
    margin-left: 1rem !important;
  }
  .mr-2-lg,
.mx-2-lg {
    margin-right: 1rem !important;
  }
  /* Spacing */
  .p-2-lg {
    padding: 1rem !important;
  }
  .pt-2-lg,
.py-2-lg {
    padding-top: 1rem !important;
  }
  .pb-2-lg,
.py-2-lg {
    padding-bottom: 1rem !important;
  }
  .pl-2-lg,
.px-2-lg {
    padding-left: 1rem !important;
  }
  .pr-2-lg,
.px-2-lg {
    padding-right: 1rem !important;
  }
  .ml-auto-lg,
.mx-auto-lg {
    margin-left: auto !important;
  }
  .mr-auto-lg,
.mx-auto-lg {
    margin-right: auto !important;
  }
  .mt-auto-lg,
.my-auto-lg {
    margin-top: auto !important;
  }
  .mb-auto-lg,
.my-auto-lg {
    margin-bottom: auto !important;
  }
}
@media screen and (min-width: 1024px) {
  /* Margin */
  .m-3-lg {
    margin: 1.5rem !important;
  }
  .mt-3-lg,
.my-3-lg {
    margin-top: 1.5rem !important;
  }
  .mb-3-lg,
.my-3-lg {
    margin-bottom: 1.5rem !important;
  }
  .ml-3-lg,
.mx-3-lg {
    margin-left: 1.5rem !important;
  }
  .mr-3-lg,
.mx-3-lg {
    margin-right: 1.5rem !important;
  }
  /* Spacing */
  .p-3-lg {
    padding: 1.5rem !important;
  }
  .pt-3-lg,
.py-3-lg {
    padding-top: 1.5rem !important;
  }
  .pb-3-lg,
.py-3-lg {
    padding-bottom: 1.5rem !important;
  }
  .pl-3-lg,
.px-3-lg {
    padding-left: 1.5rem !important;
  }
  .pr-3-lg,
.px-3-lg {
    padding-right: 1.5rem !important;
  }
  .ml-auto-lg,
.mx-auto-lg {
    margin-left: auto !important;
  }
  .mr-auto-lg,
.mx-auto-lg {
    margin-right: auto !important;
  }
  .mt-auto-lg,
.my-auto-lg {
    margin-top: auto !important;
  }
  .mb-auto-lg,
.my-auto-lg {
    margin-bottom: auto !important;
  }
}
@media screen and (min-width: 1024px) {
  /* Margin */
  .m-4-lg {
    margin: 2rem !important;
  }
  .mt-4-lg,
.my-4-lg {
    margin-top: 2rem !important;
  }
  .mb-4-lg,
.my-4-lg {
    margin-bottom: 2rem !important;
  }
  .ml-4-lg,
.mx-4-lg {
    margin-left: 2rem !important;
  }
  .mr-4-lg,
.mx-4-lg {
    margin-right: 2rem !important;
  }
  /* Spacing */
  .p-4-lg {
    padding: 2rem !important;
  }
  .pt-4-lg,
.py-4-lg {
    padding-top: 2rem !important;
  }
  .pb-4-lg,
.py-4-lg {
    padding-bottom: 2rem !important;
  }
  .pl-4-lg,
.px-4-lg {
    padding-left: 2rem !important;
  }
  .pr-4-lg,
.px-4-lg {
    padding-right: 2rem !important;
  }
  .ml-auto-lg,
.mx-auto-lg {
    margin-left: auto !important;
  }
  .mr-auto-lg,
.mx-auto-lg {
    margin-right: auto !important;
  }
  .mt-auto-lg,
.my-auto-lg {
    margin-top: auto !important;
  }
  .mb-auto-lg,
.my-auto-lg {
    margin-bottom: auto !important;
  }
}
@media screen and (min-width: 1024px) {
  /* Margin */
  .m-5-lg {
    margin: 2.5rem !important;
  }
  .mt-5-lg,
.my-5-lg {
    margin-top: 2.5rem !important;
  }
  .mb-5-lg,
.my-5-lg {
    margin-bottom: 2.5rem !important;
  }
  .ml-5-lg,
.mx-5-lg {
    margin-left: 2.5rem !important;
  }
  .mr-5-lg,
.mx-5-lg {
    margin-right: 2.5rem !important;
  }
  /* Spacing */
  .p-5-lg {
    padding: 2.5rem !important;
  }
  .pt-5-lg,
.py-5-lg {
    padding-top: 2.5rem !important;
  }
  .pb-5-lg,
.py-5-lg {
    padding-bottom: 2.5rem !important;
  }
  .pl-5-lg,
.px-5-lg {
    padding-left: 2.5rem !important;
  }
  .pr-5-lg,
.px-5-lg {
    padding-right: 2.5rem !important;
  }
  .ml-auto-lg,
.mx-auto-lg {
    margin-left: auto !important;
  }
  .mr-auto-lg,
.mx-auto-lg {
    margin-right: auto !important;
  }
  .mt-auto-lg,
.my-auto-lg {
    margin-top: auto !important;
  }
  .mb-auto-lg,
.my-auto-lg {
    margin-bottom: auto !important;
  }
}
@media screen and (min-width: 1024px) {
  /* Margin */
  .m-6-lg {
    margin: 3rem !important;
  }
  .mt-6-lg,
.my-6-lg {
    margin-top: 3rem !important;
  }
  .mb-6-lg,
.my-6-lg {
    margin-bottom: 3rem !important;
  }
  .ml-6-lg,
.mx-6-lg {
    margin-left: 3rem !important;
  }
  .mr-6-lg,
.mx-6-lg {
    margin-right: 3rem !important;
  }
  /* Spacing */
  .p-6-lg {
    padding: 3rem !important;
  }
  .pt-6-lg,
.py-6-lg {
    padding-top: 3rem !important;
  }
  .pb-6-lg,
.py-6-lg {
    padding-bottom: 3rem !important;
  }
  .pl-6-lg,
.px-6-lg {
    padding-left: 3rem !important;
  }
  .pr-6-lg,
.px-6-lg {
    padding-right: 3rem !important;
  }
  .ml-auto-lg,
.mx-auto-lg {
    margin-left: auto !important;
  }
  .mr-auto-lg,
.mx-auto-lg {
    margin-right: auto !important;
  }
  .mt-auto-lg,
.my-auto-lg {
    margin-top: auto !important;
  }
  .mb-auto-lg,
.my-auto-lg {
    margin-bottom: auto !important;
  }
}
@media screen and (min-width: 1024px) {
  /* Margin */
  .m-8-lg {
    margin: 4rem !important;
  }
  .mt-8-lg,
.my-8-lg {
    margin-top: 4rem !important;
  }
  .mb-8-lg,
.my-8-lg {
    margin-bottom: 4rem !important;
  }
  .ml-8-lg,
.mx-8-lg {
    margin-left: 4rem !important;
  }
  .mr-8-lg,
.mx-8-lg {
    margin-right: 4rem !important;
  }
  /* Spacing */
  .p-8-lg {
    padding: 4rem !important;
  }
  .pt-8-lg,
.py-8-lg {
    padding-top: 4rem !important;
  }
  .pb-8-lg,
.py-8-lg {
    padding-bottom: 4rem !important;
  }
  .pl-8-lg,
.px-8-lg {
    padding-left: 4rem !important;
  }
  .pr-8-lg,
.px-8-lg {
    padding-right: 4rem !important;
  }
  .ml-auto-lg,
.mx-auto-lg {
    margin-left: auto !important;
  }
  .mr-auto-lg,
.mx-auto-lg {
    margin-right: auto !important;
  }
  .mt-auto-lg,
.my-auto-lg {
    margin-top: auto !important;
  }
  .mb-auto-lg,
.my-auto-lg {
    margin-bottom: auto !important;
  }
}
@media screen and (min-width: 1024px) {
  /* Margin */
  .m-10-lg {
    margin: 5rem !important;
  }
  .mt-10-lg,
.my-10-lg {
    margin-top: 5rem !important;
  }
  .mb-10-lg,
.my-10-lg {
    margin-bottom: 5rem !important;
  }
  .ml-10-lg,
.mx-10-lg {
    margin-left: 5rem !important;
  }
  .mr-10-lg,
.mx-10-lg {
    margin-right: 5rem !important;
  }
  /* Spacing */
  .p-10-lg {
    padding: 5rem !important;
  }
  .pt-10-lg,
.py-10-lg {
    padding-top: 5rem !important;
  }
  .pb-10-lg,
.py-10-lg {
    padding-bottom: 5rem !important;
  }
  .pl-10-lg,
.px-10-lg {
    padding-left: 5rem !important;
  }
  .pr-10-lg,
.px-10-lg {
    padding-right: 5rem !important;
  }
  .ml-auto-lg,
.mx-auto-lg {
    margin-left: auto !important;
  }
  .mr-auto-lg,
.mx-auto-lg {
    margin-right: auto !important;
  }
  .mt-auto-lg,
.my-auto-lg {
    margin-top: auto !important;
  }
  .mb-auto-lg,
.my-auto-lg {
    margin-bottom: auto !important;
  }
}
@media screen and (min-width: 1024px) {
  /* Margin */
  .m-12-lg {
    margin: 6rem !important;
  }
  .mt-12-lg,
.my-12-lg {
    margin-top: 6rem !important;
  }
  .mb-12-lg,
.my-12-lg {
    margin-bottom: 6rem !important;
  }
  .ml-12-lg,
.mx-12-lg {
    margin-left: 6rem !important;
  }
  .mr-12-lg,
.mx-12-lg {
    margin-right: 6rem !important;
  }
  /* Spacing */
  .p-12-lg {
    padding: 6rem !important;
  }
  .pt-12-lg,
.py-12-lg {
    padding-top: 6rem !important;
  }
  .pb-12-lg,
.py-12-lg {
    padding-bottom: 6rem !important;
  }
  .pl-12-lg,
.px-12-lg {
    padding-left: 6rem !important;
  }
  .pr-12-lg,
.px-12-lg {
    padding-right: 6rem !important;
  }
  .ml-auto-lg,
.mx-auto-lg {
    margin-left: auto !important;
  }
  .mr-auto-lg,
.mx-auto-lg {
    margin-right: auto !important;
  }
  .mt-auto-lg,
.my-auto-lg {
    margin-top: auto !important;
  }
  .mb-auto-lg,
.my-auto-lg {
    margin-bottom: auto !important;
  }
}
@media screen and (min-width: 1024px) {
  /* Margin */
  .m-16-lg {
    margin: 8rem !important;
  }
  .mt-16-lg,
.my-16-lg {
    margin-top: 8rem !important;
  }
  .mb-16-lg,
.my-16-lg {
    margin-bottom: 8rem !important;
  }
  .ml-16-lg,
.mx-16-lg {
    margin-left: 8rem !important;
  }
  .mr-16-lg,
.mx-16-lg {
    margin-right: 8rem !important;
  }
  /* Spacing */
  .p-16-lg {
    padding: 8rem !important;
  }
  .pt-16-lg,
.py-16-lg {
    padding-top: 8rem !important;
  }
  .pb-16-lg,
.py-16-lg {
    padding-bottom: 8rem !important;
  }
  .pl-16-lg,
.px-16-lg {
    padding-left: 8rem !important;
  }
  .pr-16-lg,
.px-16-lg {
    padding-right: 8rem !important;
  }
  .ml-auto-lg,
.mx-auto-lg {
    margin-left: auto !important;
  }
  .mr-auto-lg,
.mx-auto-lg {
    margin-right: auto !important;
  }
  .mt-auto-lg,
.my-auto-lg {
    margin-top: auto !important;
  }
  .mb-auto-lg,
.my-auto-lg {
    margin-bottom: auto !important;
  }
}
@media screen and (min-width: 1024px) {
  /* Margin */
  .m-20-lg {
    margin: 10rem !important;
  }
  .mt-20-lg,
.my-20-lg {
    margin-top: 10rem !important;
  }
  .mb-20-lg,
.my-20-lg {
    margin-bottom: 10rem !important;
  }
  .ml-20-lg,
.mx-20-lg {
    margin-left: 10rem !important;
  }
  .mr-20-lg,
.mx-20-lg {
    margin-right: 10rem !important;
  }
  /* Spacing */
  .p-20-lg {
    padding: 10rem !important;
  }
  .pt-20-lg,
.py-20-lg {
    padding-top: 10rem !important;
  }
  .pb-20-lg,
.py-20-lg {
    padding-bottom: 10rem !important;
  }
  .pl-20-lg,
.px-20-lg {
    padding-left: 10rem !important;
  }
  .pr-20-lg,
.px-20-lg {
    padding-right: 10rem !important;
  }
  .ml-auto-lg,
.mx-auto-lg {
    margin-left: auto !important;
  }
  .mr-auto-lg,
.mx-auto-lg {
    margin-right: auto !important;
  }
  .mt-auto-lg,
.my-auto-lg {
    margin-top: auto !important;
  }
  .mb-auto-lg,
.my-auto-lg {
    margin-bottom: auto !important;
  }
}
@media screen and (min-width: 1024px) {
  /* Margin */
  .m-24-lg {
    margin: 12rem !important;
  }
  .mt-24-lg,
.my-24-lg {
    margin-top: 12rem !important;
  }
  .mb-24-lg,
.my-24-lg {
    margin-bottom: 12rem !important;
  }
  .ml-24-lg,
.mx-24-lg {
    margin-left: 12rem !important;
  }
  .mr-24-lg,
.mx-24-lg {
    margin-right: 12rem !important;
  }
  /* Spacing */
  .p-24-lg {
    padding: 12rem !important;
  }
  .pt-24-lg,
.py-24-lg {
    padding-top: 12rem !important;
  }
  .pb-24-lg,
.py-24-lg {
    padding-bottom: 12rem !important;
  }
  .pl-24-lg,
.px-24-lg {
    padding-left: 12rem !important;
  }
  .pr-24-lg,
.px-24-lg {
    padding-right: 12rem !important;
  }
  .ml-auto-lg,
.mx-auto-lg {
    margin-left: auto !important;
  }
  .mr-auto-lg,
.mx-auto-lg {
    margin-right: auto !important;
  }
  .mt-auto-lg,
.my-auto-lg {
    margin-top: auto !important;
  }
  .mb-auto-lg,
.my-auto-lg {
    margin-bottom: auto !important;
  }
}
@media screen and (min-width: 1024px) {
  /* Margin */
  .m-32-lg {
    margin: 16rem !important;
  }
  .mt-32-lg,
.my-32-lg {
    margin-top: 16rem !important;
  }
  .mb-32-lg,
.my-32-lg {
    margin-bottom: 16rem !important;
  }
  .ml-32-lg,
.mx-32-lg {
    margin-left: 16rem !important;
  }
  .mr-32-lg,
.mx-32-lg {
    margin-right: 16rem !important;
  }
  /* Spacing */
  .p-32-lg {
    padding: 16rem !important;
  }
  .pt-32-lg,
.py-32-lg {
    padding-top: 16rem !important;
  }
  .pb-32-lg,
.py-32-lg {
    padding-bottom: 16rem !important;
  }
  .pl-32-lg,
.px-32-lg {
    padding-left: 16rem !important;
  }
  .pr-32-lg,
.px-32-lg {
    padding-right: 16rem !important;
  }
  .ml-auto-lg,
.mx-auto-lg {
    margin-left: auto !important;
  }
  .mr-auto-lg,
.mx-auto-lg {
    margin-right: auto !important;
  }
  .mt-auto-lg,
.my-auto-lg {
    margin-top: auto !important;
  }
  .mb-auto-lg,
.my-auto-lg {
    margin-bottom: auto !important;
  }
}
@media screen and (min-width: 1280px) {
  /* Margin */
  .m-0-xl {
    margin: 0rem !important;
  }
  .mt-0-xl,
.my-0-xl {
    margin-top: 0rem !important;
  }
  .mb-0-xl,
.my-0-xl {
    margin-bottom: 0rem !important;
  }
  .ml-0-xl,
.mx-0-xl {
    margin-left: 0rem !important;
  }
  .mr-0-xl,
.mx-0-xl {
    margin-right: 0rem !important;
  }
  /* Spacing */
  .p-0-xl {
    padding: 0rem !important;
  }
  .pt-0-xl,
.py-0-xl {
    padding-top: 0rem !important;
  }
  .pb-0-xl,
.py-0-xl {
    padding-bottom: 0rem !important;
  }
  .pl-0-xl,
.px-0-xl {
    padding-left: 0rem !important;
  }
  .pr-0-xl,
.px-0-xl {
    padding-right: 0rem !important;
  }
  .ml-auto-xl,
.mx-auto-xl {
    margin-left: auto !important;
  }
  .mr-auto-xl,
.mx-auto-xl {
    margin-right: auto !important;
  }
  .mt-auto-xl,
.my-auto-xl {
    margin-top: auto !important;
  }
  .mb-auto-xl,
.my-auto-xl {
    margin-bottom: auto !important;
  }
}
@media screen and (min-width: 1280px) {
  /* Margin */
  .m-1-xl {
    margin: 0.5rem !important;
  }
  .mt-1-xl,
.my-1-xl {
    margin-top: 0.5rem !important;
  }
  .mb-1-xl,
.my-1-xl {
    margin-bottom: 0.5rem !important;
  }
  .ml-1-xl,
.mx-1-xl {
    margin-left: 0.5rem !important;
  }
  .mr-1-xl,
.mx-1-xl {
    margin-right: 0.5rem !important;
  }
  /* Spacing */
  .p-1-xl {
    padding: 0.5rem !important;
  }
  .pt-1-xl,
.py-1-xl {
    padding-top: 0.5rem !important;
  }
  .pb-1-xl,
.py-1-xl {
    padding-bottom: 0.5rem !important;
  }
  .pl-1-xl,
.px-1-xl {
    padding-left: 0.5rem !important;
  }
  .pr-1-xl,
.px-1-xl {
    padding-right: 0.5rem !important;
  }
  .ml-auto-xl,
.mx-auto-xl {
    margin-left: auto !important;
  }
  .mr-auto-xl,
.mx-auto-xl {
    margin-right: auto !important;
  }
  .mt-auto-xl,
.my-auto-xl {
    margin-top: auto !important;
  }
  .mb-auto-xl,
.my-auto-xl {
    margin-bottom: auto !important;
  }
}
@media screen and (min-width: 1280px) {
  /* Margin */
  .m-2-xl {
    margin: 1rem !important;
  }
  .mt-2-xl,
.my-2-xl {
    margin-top: 1rem !important;
  }
  .mb-2-xl,
.my-2-xl {
    margin-bottom: 1rem !important;
  }
  .ml-2-xl,
.mx-2-xl {
    margin-left: 1rem !important;
  }
  .mr-2-xl,
.mx-2-xl {
    margin-right: 1rem !important;
  }
  /* Spacing */
  .p-2-xl {
    padding: 1rem !important;
  }
  .pt-2-xl,
.py-2-xl {
    padding-top: 1rem !important;
  }
  .pb-2-xl,
.py-2-xl {
    padding-bottom: 1rem !important;
  }
  .pl-2-xl,
.px-2-xl {
    padding-left: 1rem !important;
  }
  .pr-2-xl,
.px-2-xl {
    padding-right: 1rem !important;
  }
  .ml-auto-xl,
.mx-auto-xl {
    margin-left: auto !important;
  }
  .mr-auto-xl,
.mx-auto-xl {
    margin-right: auto !important;
  }
  .mt-auto-xl,
.my-auto-xl {
    margin-top: auto !important;
  }
  .mb-auto-xl,
.my-auto-xl {
    margin-bottom: auto !important;
  }
}
@media screen and (min-width: 1280px) {
  /* Margin */
  .m-3-xl {
    margin: 1.5rem !important;
  }
  .mt-3-xl,
.my-3-xl {
    margin-top: 1.5rem !important;
  }
  .mb-3-xl,
.my-3-xl {
    margin-bottom: 1.5rem !important;
  }
  .ml-3-xl,
.mx-3-xl {
    margin-left: 1.5rem !important;
  }
  .mr-3-xl,
.mx-3-xl {
    margin-right: 1.5rem !important;
  }
  /* Spacing */
  .p-3-xl {
    padding: 1.5rem !important;
  }
  .pt-3-xl,
.py-3-xl {
    padding-top: 1.5rem !important;
  }
  .pb-3-xl,
.py-3-xl {
    padding-bottom: 1.5rem !important;
  }
  .pl-3-xl,
.px-3-xl {
    padding-left: 1.5rem !important;
  }
  .pr-3-xl,
.px-3-xl {
    padding-right: 1.5rem !important;
  }
  .ml-auto-xl,
.mx-auto-xl {
    margin-left: auto !important;
  }
  .mr-auto-xl,
.mx-auto-xl {
    margin-right: auto !important;
  }
  .mt-auto-xl,
.my-auto-xl {
    margin-top: auto !important;
  }
  .mb-auto-xl,
.my-auto-xl {
    margin-bottom: auto !important;
  }
}
@media screen and (min-width: 1280px) {
  /* Margin */
  .m-4-xl {
    margin: 2rem !important;
  }
  .mt-4-xl,
.my-4-xl {
    margin-top: 2rem !important;
  }
  .mb-4-xl,
.my-4-xl {
    margin-bottom: 2rem !important;
  }
  .ml-4-xl,
.mx-4-xl {
    margin-left: 2rem !important;
  }
  .mr-4-xl,
.mx-4-xl {
    margin-right: 2rem !important;
  }
  /* Spacing */
  .p-4-xl {
    padding: 2rem !important;
  }
  .pt-4-xl,
.py-4-xl {
    padding-top: 2rem !important;
  }
  .pb-4-xl,
.py-4-xl {
    padding-bottom: 2rem !important;
  }
  .pl-4-xl,
.px-4-xl {
    padding-left: 2rem !important;
  }
  .pr-4-xl,
.px-4-xl {
    padding-right: 2rem !important;
  }
  .ml-auto-xl,
.mx-auto-xl {
    margin-left: auto !important;
  }
  .mr-auto-xl,
.mx-auto-xl {
    margin-right: auto !important;
  }
  .mt-auto-xl,
.my-auto-xl {
    margin-top: auto !important;
  }
  .mb-auto-xl,
.my-auto-xl {
    margin-bottom: auto !important;
  }
}
@media screen and (min-width: 1280px) {
  /* Margin */
  .m-5-xl {
    margin: 2.5rem !important;
  }
  .mt-5-xl,
.my-5-xl {
    margin-top: 2.5rem !important;
  }
  .mb-5-xl,
.my-5-xl {
    margin-bottom: 2.5rem !important;
  }
  .ml-5-xl,
.mx-5-xl {
    margin-left: 2.5rem !important;
  }
  .mr-5-xl,
.mx-5-xl {
    margin-right: 2.5rem !important;
  }
  /* Spacing */
  .p-5-xl {
    padding: 2.5rem !important;
  }
  .pt-5-xl,
.py-5-xl {
    padding-top: 2.5rem !important;
  }
  .pb-5-xl,
.py-5-xl {
    padding-bottom: 2.5rem !important;
  }
  .pl-5-xl,
.px-5-xl {
    padding-left: 2.5rem !important;
  }
  .pr-5-xl,
.px-5-xl {
    padding-right: 2.5rem !important;
  }
  .ml-auto-xl,
.mx-auto-xl {
    margin-left: auto !important;
  }
  .mr-auto-xl,
.mx-auto-xl {
    margin-right: auto !important;
  }
  .mt-auto-xl,
.my-auto-xl {
    margin-top: auto !important;
  }
  .mb-auto-xl,
.my-auto-xl {
    margin-bottom: auto !important;
  }
}
@media screen and (min-width: 1280px) {
  /* Margin */
  .m-6-xl {
    margin: 3rem !important;
  }
  .mt-6-xl,
.my-6-xl {
    margin-top: 3rem !important;
  }
  .mb-6-xl,
.my-6-xl {
    margin-bottom: 3rem !important;
  }
  .ml-6-xl,
.mx-6-xl {
    margin-left: 3rem !important;
  }
  .mr-6-xl,
.mx-6-xl {
    margin-right: 3rem !important;
  }
  /* Spacing */
  .p-6-xl {
    padding: 3rem !important;
  }
  .pt-6-xl,
.py-6-xl {
    padding-top: 3rem !important;
  }
  .pb-6-xl,
.py-6-xl {
    padding-bottom: 3rem !important;
  }
  .pl-6-xl,
.px-6-xl {
    padding-left: 3rem !important;
  }
  .pr-6-xl,
.px-6-xl {
    padding-right: 3rem !important;
  }
  .ml-auto-xl,
.mx-auto-xl {
    margin-left: auto !important;
  }
  .mr-auto-xl,
.mx-auto-xl {
    margin-right: auto !important;
  }
  .mt-auto-xl,
.my-auto-xl {
    margin-top: auto !important;
  }
  .mb-auto-xl,
.my-auto-xl {
    margin-bottom: auto !important;
  }
}
@media screen and (min-width: 1280px) {
  /* Margin */
  .m-8-xl {
    margin: 4rem !important;
  }
  .mt-8-xl,
.my-8-xl {
    margin-top: 4rem !important;
  }
  .mb-8-xl,
.my-8-xl {
    margin-bottom: 4rem !important;
  }
  .ml-8-xl,
.mx-8-xl {
    margin-left: 4rem !important;
  }
  .mr-8-xl,
.mx-8-xl {
    margin-right: 4rem !important;
  }
  /* Spacing */
  .p-8-xl {
    padding: 4rem !important;
  }
  .pt-8-xl,
.py-8-xl {
    padding-top: 4rem !important;
  }
  .pb-8-xl,
.py-8-xl {
    padding-bottom: 4rem !important;
  }
  .pl-8-xl,
.px-8-xl {
    padding-left: 4rem !important;
  }
  .pr-8-xl,
.px-8-xl {
    padding-right: 4rem !important;
  }
  .ml-auto-xl,
.mx-auto-xl {
    margin-left: auto !important;
  }
  .mr-auto-xl,
.mx-auto-xl {
    margin-right: auto !important;
  }
  .mt-auto-xl,
.my-auto-xl {
    margin-top: auto !important;
  }
  .mb-auto-xl,
.my-auto-xl {
    margin-bottom: auto !important;
  }
}
@media screen and (min-width: 1280px) {
  /* Margin */
  .m-10-xl {
    margin: 5rem !important;
  }
  .mt-10-xl,
.my-10-xl {
    margin-top: 5rem !important;
  }
  .mb-10-xl,
.my-10-xl {
    margin-bottom: 5rem !important;
  }
  .ml-10-xl,
.mx-10-xl {
    margin-left: 5rem !important;
  }
  .mr-10-xl,
.mx-10-xl {
    margin-right: 5rem !important;
  }
  /* Spacing */
  .p-10-xl {
    padding: 5rem !important;
  }
  .pt-10-xl,
.py-10-xl {
    padding-top: 5rem !important;
  }
  .pb-10-xl,
.py-10-xl {
    padding-bottom: 5rem !important;
  }
  .pl-10-xl,
.px-10-xl {
    padding-left: 5rem !important;
  }
  .pr-10-xl,
.px-10-xl {
    padding-right: 5rem !important;
  }
  .ml-auto-xl,
.mx-auto-xl {
    margin-left: auto !important;
  }
  .mr-auto-xl,
.mx-auto-xl {
    margin-right: auto !important;
  }
  .mt-auto-xl,
.my-auto-xl {
    margin-top: auto !important;
  }
  .mb-auto-xl,
.my-auto-xl {
    margin-bottom: auto !important;
  }
}
@media screen and (min-width: 1280px) {
  /* Margin */
  .m-12-xl {
    margin: 6rem !important;
  }
  .mt-12-xl,
.my-12-xl {
    margin-top: 6rem !important;
  }
  .mb-12-xl,
.my-12-xl {
    margin-bottom: 6rem !important;
  }
  .ml-12-xl,
.mx-12-xl {
    margin-left: 6rem !important;
  }
  .mr-12-xl,
.mx-12-xl {
    margin-right: 6rem !important;
  }
  /* Spacing */
  .p-12-xl {
    padding: 6rem !important;
  }
  .pt-12-xl,
.py-12-xl {
    padding-top: 6rem !important;
  }
  .pb-12-xl,
.py-12-xl {
    padding-bottom: 6rem !important;
  }
  .pl-12-xl,
.px-12-xl {
    padding-left: 6rem !important;
  }
  .pr-12-xl,
.px-12-xl {
    padding-right: 6rem !important;
  }
  .ml-auto-xl,
.mx-auto-xl {
    margin-left: auto !important;
  }
  .mr-auto-xl,
.mx-auto-xl {
    margin-right: auto !important;
  }
  .mt-auto-xl,
.my-auto-xl {
    margin-top: auto !important;
  }
  .mb-auto-xl,
.my-auto-xl {
    margin-bottom: auto !important;
  }
}
@media screen and (min-width: 1280px) {
  /* Margin */
  .m-16-xl {
    margin: 8rem !important;
  }
  .mt-16-xl,
.my-16-xl {
    margin-top: 8rem !important;
  }
  .mb-16-xl,
.my-16-xl {
    margin-bottom: 8rem !important;
  }
  .ml-16-xl,
.mx-16-xl {
    margin-left: 8rem !important;
  }
  .mr-16-xl,
.mx-16-xl {
    margin-right: 8rem !important;
  }
  /* Spacing */
  .p-16-xl {
    padding: 8rem !important;
  }
  .pt-16-xl,
.py-16-xl {
    padding-top: 8rem !important;
  }
  .pb-16-xl,
.py-16-xl {
    padding-bottom: 8rem !important;
  }
  .pl-16-xl,
.px-16-xl {
    padding-left: 8rem !important;
  }
  .pr-16-xl,
.px-16-xl {
    padding-right: 8rem !important;
  }
  .ml-auto-xl,
.mx-auto-xl {
    margin-left: auto !important;
  }
  .mr-auto-xl,
.mx-auto-xl {
    margin-right: auto !important;
  }
  .mt-auto-xl,
.my-auto-xl {
    margin-top: auto !important;
  }
  .mb-auto-xl,
.my-auto-xl {
    margin-bottom: auto !important;
  }
}
@media screen and (min-width: 1280px) {
  /* Margin */
  .m-20-xl {
    margin: 10rem !important;
  }
  .mt-20-xl,
.my-20-xl {
    margin-top: 10rem !important;
  }
  .mb-20-xl,
.my-20-xl {
    margin-bottom: 10rem !important;
  }
  .ml-20-xl,
.mx-20-xl {
    margin-left: 10rem !important;
  }
  .mr-20-xl,
.mx-20-xl {
    margin-right: 10rem !important;
  }
  /* Spacing */
  .p-20-xl {
    padding: 10rem !important;
  }
  .pt-20-xl,
.py-20-xl {
    padding-top: 10rem !important;
  }
  .pb-20-xl,
.py-20-xl {
    padding-bottom: 10rem !important;
  }
  .pl-20-xl,
.px-20-xl {
    padding-left: 10rem !important;
  }
  .pr-20-xl,
.px-20-xl {
    padding-right: 10rem !important;
  }
  .ml-auto-xl,
.mx-auto-xl {
    margin-left: auto !important;
  }
  .mr-auto-xl,
.mx-auto-xl {
    margin-right: auto !important;
  }
  .mt-auto-xl,
.my-auto-xl {
    margin-top: auto !important;
  }
  .mb-auto-xl,
.my-auto-xl {
    margin-bottom: auto !important;
  }
}
@media screen and (min-width: 1280px) {
  /* Margin */
  .m-24-xl {
    margin: 12rem !important;
  }
  .mt-24-xl,
.my-24-xl {
    margin-top: 12rem !important;
  }
  .mb-24-xl,
.my-24-xl {
    margin-bottom: 12rem !important;
  }
  .ml-24-xl,
.mx-24-xl {
    margin-left: 12rem !important;
  }
  .mr-24-xl,
.mx-24-xl {
    margin-right: 12rem !important;
  }
  /* Spacing */
  .p-24-xl {
    padding: 12rem !important;
  }
  .pt-24-xl,
.py-24-xl {
    padding-top: 12rem !important;
  }
  .pb-24-xl,
.py-24-xl {
    padding-bottom: 12rem !important;
  }
  .pl-24-xl,
.px-24-xl {
    padding-left: 12rem !important;
  }
  .pr-24-xl,
.px-24-xl {
    padding-right: 12rem !important;
  }
  .ml-auto-xl,
.mx-auto-xl {
    margin-left: auto !important;
  }
  .mr-auto-xl,
.mx-auto-xl {
    margin-right: auto !important;
  }
  .mt-auto-xl,
.my-auto-xl {
    margin-top: auto !important;
  }
  .mb-auto-xl,
.my-auto-xl {
    margin-bottom: auto !important;
  }
}
@media screen and (min-width: 1280px) {
  /* Margin */
  .m-32-xl {
    margin: 16rem !important;
  }
  .mt-32-xl,
.my-32-xl {
    margin-top: 16rem !important;
  }
  .mb-32-xl,
.my-32-xl {
    margin-bottom: 16rem !important;
  }
  .ml-32-xl,
.mx-32-xl {
    margin-left: 16rem !important;
  }
  .mr-32-xl,
.mx-32-xl {
    margin-right: 16rem !important;
  }
  /* Spacing */
  .p-32-xl {
    padding: 16rem !important;
  }
  .pt-32-xl,
.py-32-xl {
    padding-top: 16rem !important;
  }
  .pb-32-xl,
.py-32-xl {
    padding-bottom: 16rem !important;
  }
  .pl-32-xl,
.px-32-xl {
    padding-left: 16rem !important;
  }
  .pr-32-xl,
.px-32-xl {
    padding-right: 16rem !important;
  }
  .ml-auto-xl,
.mx-auto-xl {
    margin-left: auto !important;
  }
  .mr-auto-xl,
.mx-auto-xl {
    margin-right: auto !important;
  }
  .mt-auto-xl,
.my-auto-xl {
    margin-top: auto !important;
  }
  .mb-auto-xl,
.my-auto-xl {
    margin-bottom: auto !important;
  }
}
/* Render the x in the close button */
/* BUTTONS */
/* Button styling */
/* Un-themed */
.btn, button, [type=submit], [type=reset], [type=button] {
  line-height: 2rem;
  overflow: hidden;
  padding: 0.5rem 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  cursor: pointer;
  text-align: center;
  transition: all var(--animation-duration);
  background-color: rgba(var(--btn-color), var(--bg-opacity));
  border-color: rgba(var(--btn-border-color), var(--color-opacity));
  color: rgba(var(--btn-fg), var(--color-opacity));
  font-size: 0.75rem;
  font-weight: inherit;
  letter-spacing: 0.03rem;
  text-transform: uppercase;
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  min-width: 2rem;
  user-select: none;
  margin-bottom: 1rem;
  outline: none;
  --btn-color: 248, 249, 250;
  --btn-fg: 73, 80, 87;
  --btn-border-color: 233, 236, 239;
  /* Base States */
  /* Loading Button*/
  /* Make the loading circle, if present, white when button is active */
  /* Animated Button */
  /* Close Button */
  /* BUTTON STYLES */
  /* Regular */
  /* GLYPHS */
  /* Alternatives to pad-left and pad-right */
}

.btn:hover, button:hover, [type=submit]:hover, [type=reset]:hover, [type=button]:hover {
  transition: all var(--animation-duration);
  --btn-color: 241, 243, 245;
  color: rgba(var(--btn-fg), var(--color-opacity));
}

.btn:active, button:active, [type=submit]:active, [type=reset]:active, [type=button]:active {
  transition: var(--animation-duration) ease;
}

.btn:focus, button:focus, [type=submit]:focus, [type=reset]:focus, [type=button]:focus {
  outline: none;
}

.btn:focus:not([disabled]), button:focus:not([disabled]), [type=submit]:focus:not([disabled]), [type=reset]:focus:not([disabled]), [type=button]:focus:not([disabled]) {
  box-shadow: var(--btn-shadow);
}

.btn:disabled, .btn.btn--disabled, button:disabled, button.btn--disabled, [type=submit]:disabled, [type=submit].btn--disabled, [type=reset]:disabled, [type=reset].btn--disabled, [type=button]:disabled, [type=button].btn--disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.btn:disabled:active, .btn.btn--disabled:active, button:disabled:active, button.btn--disabled:active, [type=submit]:disabled:active, [type=submit].btn--disabled:active, [type=reset]:disabled:active, [type=reset].btn--disabled:active, [type=button]:disabled:active, [type=button].btn--disabled:active {
  pointer-events: none;
}

.btn.outline, button.outline, [type=submit].outline, [type=reset].outline, [type=button].outline {
  --btn-color: transparent;
}

.btn.outline:hover, button.outline:hover, [type=submit].outline:hover, [type=reset].outline:hover, [type=button].outline:hover {
  --btn-color: 233, 236, 239;
}

.btn.loading:active::after, button.loading:active::after, [type=submit].loading:active::after, [type=reset].loading:active::after, [type=button].loading:active::after {
  border-radius: 50%;
  border-right-color: transparent;
  border-top-color: transparent;
  transition: var(--animation-duration) ease;
}

.btn.btn-animated, button.btn-animated, [type=submit].btn-animated, [type=reset].btn-animated, [type=button].btn-animated {
  transition: calc(var(--animation-duration) / 2) ease;
}

.btn.btn-animated:active, button.btn-animated:active, [type=submit].btn-animated:active, [type=reset].btn-animated:active, [type=button].btn-animated:active {
  transform: scale(0.95);
  transition: calc(var(--animation-duration) / 2) ease;
}

.btn.btn-close, button.btn-close, [type=submit].btn-close, [type=reset].btn-close, [type=button].btn-close {
  background-color: rgba(10, 10, 10, 0.2);
  border: none;
  border-radius: 290486px;
  cursor: pointer;
  display: inline-block;
  flex-grow: 0;
  flex-shrink: 0;
  font-size: 0;
  height: 20px;
  outline: 0;
  position: relative;
  vertical-align: top;
  width: 20px;
  padding: 0;
  min-width: 20px;
}

.btn.btn-close:hover, button.btn-close:hover, [type=submit].btn-close:hover, [type=reset].btn-close:hover, [type=button].btn-close:hover {
  background-color: rgba(10, 10, 10, 0.3);
}

.btn.btn-close::before, button.btn-close::before, [type=submit].btn-close::before, [type=reset].btn-close::before, [type=button].btn-close::before {
  background-color: var(--cirrus-bg);
  content: "";
  display: block;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translateX(-50%) translateY(-50%) rotate(45deg);
  transform-origin: center center;
  height: 2px;
  width: 50%;
}

.btn.btn-close::after, button.btn-close::after, [type=submit].btn-close::after, [type=reset].btn-close::after, [type=button].btn-close::after {
  background-color: var(--cirrus-bg);
  content: "";
  display: block;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translateX(-50%) translateY(-50%) rotate(45deg);
  transform-origin: center center;
  height: 50%;
  width: 2px;
}

.btn.btn-transparent, button.btn-transparent, [type=submit].btn-transparent, [type=reset].btn-transparent, [type=button].btn-transparent {
  --btn-color: transparent;
  --btn-fg: 54, 54, 54;
  --btn-border-color: transparent;
  --btn-shadow: 0 0 0 0.2rem rgba(246, 249, 252, 0.5);
}

.btn.btn-transparent:hover, button.btn-transparent:hover, [type=submit].btn-transparent:hover, [type=reset].btn-transparent:hover, [type=button].btn-transparent:hover {
  --bg-opacity: 0.1;
  --btn-color: 0, 0, 0;
}

.btn.btn-transparent.outline, button.btn-transparent.outline, [type=submit].btn-transparent.outline, [type=reset].btn-transparent.outline, [type=button].btn-transparent.outline {
  --btn-fg: 54, 54, 54;
}

.btn.btn-light, button.btn-light, [type=submit].btn-light, [type=reset].btn-light, [type=button].btn-light {
  --btn-color: 246, 249, 252;
  --btn-fg: 54, 54, 54;
  --btn-border-color: 246, 249, 252;
  --btn-shadow: 0 0 0 0.2rem rgba(246, 249, 252, 0.5);
}

.btn.btn-light:hover, button.btn-light:hover, [type=submit].btn-light:hover, [type=reset].btn-light:hover, [type=button].btn-light:hover {
  --btn-color: 208, 224, 239;
}

.btn.btn-black, button.btn-black, [type=submit].btn-black, [type=reset].btn-black, [type=button].btn-black {
  --btn-color: 0, 0, 0;
  --btn-fg: 246, 249, 252;
  --btn-border-color: 0, 0, 0;
  --btn-shadow: 0 0 0 0.2rem rgba(54, 54, 54, 0.5);
}

.btn.btn-black:hover, button.btn-black:hover, [type=submit].btn-black:hover, [type=reset].btn-black:hover, [type=button].btn-black:hover {
  --btn-color: 0, 0, 0;
}

.btn.btn-primary, button.btn-primary, [type=submit].btn-primary, [type=reset].btn-primary, [type=button].btn-primary {
  --btn-color: 240, 61, 77;
  --btn-fg: 246, 249, 252;
  --btn-border-color: 240, 61, 77;
  --btn-shadow: 0 0 0 0.2rem rgba(240, 61, 77, 0.5);
}

.btn.btn-primary:hover, button.btn-primary:hover, [type=submit].btn-primary:hover, [type=reset].btn-primary:hover, [type=button].btn-primary:hover {
  --btn-color: 232, 18, 37;
}

.btn.btn-primary.outline:hover, button.btn-primary.outline:hover, [type=submit].btn-primary.outline:hover, [type=reset].btn-primary.outline:hover, [type=button].btn-primary.outline:hover {
  --btn-color: 240, 61, 77;
}

.btn.btn-dark, button.btn-dark, [type=submit].btn-dark, [type=reset].btn-dark, [type=button].btn-dark {
  --btn-color: 54, 54, 54;
  --btn-fg: 246, 249, 252;
  --btn-border-color: 54, 54, 54;
  --btn-shadow: 0 0 0 0.2rem rgba(54, 54, 54, 0.5);
}

.btn.btn-dark:hover, button.btn-dark:hover, [type=submit].btn-dark:hover, [type=reset].btn-dark:hover, [type=button].btn-dark:hover {
  --btn-color: 29, 29, 29;
}

.btn.btn-dark.outline:hover, button.btn-dark.outline:hover, [type=submit].btn-dark.outline:hover, [type=reset].btn-dark.outline:hover, [type=button].btn-dark.outline:hover {
  --btn-color: 54, 54, 54;
}

.btn.btn-link, button.btn-link, [type=submit].btn-link, [type=reset].btn-link, [type=button].btn-link {
  --btn-color: 94, 92, 199;
  --btn-fg: 246, 249, 252;
  --btn-border-color: 94, 92, 199;
  --btn-shadow: 0 0 0 0.2rem rgba(94, 92, 199, 0.5);
}

.btn.btn-link:hover, button.btn-link:hover, [type=submit].btn-link:hover, [type=reset].btn-link:hover, [type=button].btn-link:hover {
  --btn-color: 64, 61, 179;
}

.btn.btn-link.outline:hover, button.btn-link.outline:hover, [type=submit].btn-link.outline:hover, [type=reset].btn-link.outline:hover, [type=button].btn-link.outline:hover {
  --btn-color: 94, 92, 199;
}

.btn.btn-info, button.btn-info, [type=submit].btn-info, [type=reset].btn-info, [type=button].btn-info {
  --btn-color: 41, 114, 250;
  --btn-fg: 246, 249, 252;
  --btn-border-color: 41, 114, 250;
  --btn-shadow: 0 0 0 0.2rem rgba(41, 114, 250, 0.5);
}

.btn.btn-info:hover, button.btn-info:hover, [type=submit].btn-info:hover, [type=reset].btn-info:hover, [type=button].btn-info:hover {
  --btn-color: 5, 85, 235;
}

.btn.btn-info.outline:hover, button.btn-info.outline:hover, [type=submit].btn-info.outline:hover, [type=reset].btn-info.outline:hover, [type=button].btn-info.outline:hover {
  --btn-color: 41, 114, 250;
}

.btn.btn-success, button.btn-success, [type=submit].btn-success, [type=reset].btn-success, [type=button].btn-success {
  --btn-color: 13, 209, 87;
  --btn-fg: 246, 249, 252;
  --btn-border-color: 13, 209, 87;
  --btn-shadow: 0 0 0 0.2rem rgba(13, 209, 87, 0.5);
}

.btn.btn-success:hover, button.btn-success:hover, [type=submit].btn-success:hover, [type=reset].btn-success:hover, [type=button].btn-success:hover {
  --btn-color: 10, 161, 67;
}

.btn.btn-success.outline:hover, button.btn-success.outline:hover, [type=submit].btn-success.outline:hover, [type=reset].btn-success.outline:hover, [type=button].btn-success.outline:hover {
  --btn-color: 13, 209, 87;
}

.btn.btn-warning, button.btn-warning, [type=submit].btn-warning, [type=reset].btn-warning, [type=button].btn-warning {
  --btn-color: 250, 182, 51;
  --btn-fg: 54, 54, 54;
  --btn-border-color: 250, 182, 51;
  --btn-shadow: 0 0 0 0.2rem rgba(250, 182, 51, 0.5);
}

.btn.btn-warning:hover, button.btn-warning:hover, [type=submit].btn-warning:hover, [type=reset].btn-warning:hover, [type=button].btn-warning:hover {
  --btn-color: 244, 163, 6;
}

.btn.btn-warning.outline:hover, button.btn-warning.outline:hover, [type=submit].btn-warning.outline:hover, [type=reset].btn-warning.outline:hover, [type=button].btn-warning.outline:hover {
  --btn-color: 250, 182, 51;
}

.btn.btn-danger, button.btn-danger, [type=submit].btn-danger, [type=reset].btn-danger, [type=button].btn-danger {
  --btn-color: 251, 65, 67;
  --btn-fg: 246, 249, 252;
  --btn-border-color: 251, 65, 67;
  --btn-shadow: 0 0 0 0.2rem rgba(251, 65, 67, 0.5);
}

.btn.btn-danger:hover, button.btn-danger:hover, [type=submit].btn-danger:hover, [type=reset].btn-danger:hover, [type=button].btn-danger:hover {
  --btn-color: 250, 15, 18;
}

.btn.btn-danger.outline:hover, button.btn-danger.outline:hover, [type=submit].btn-danger.outline:hover, [type=reset].btn-danger.outline:hover, [type=button].btn-danger.outline:hover {
  --btn-color: 251, 65, 67;
}

.btn.btn--xs, button.btn--xs, [type=submit].btn--xs, [type=reset].btn--xs, [type=button].btn--xs {
  padding: 0 0.5rem;
  font-size: 50%;
}

.btn.btn--sm, button.btn--sm, [type=submit].btn--sm, [type=reset].btn--sm, [type=button].btn--sm {
  padding: 0.25rem 1rem;
  font-size: 70%;
}

.btn.btn--md, button.btn--md, [type=submit].btn--md, [type=reset].btn--md, [type=button].btn--md {
  padding: 0.5rem 1.5rem;
  font-size: 90%;
}

.btn.btn--lg, button.btn--lg, [type=submit].btn--lg, [type=reset].btn--lg, [type=button].btn--lg {
  padding: 0.75rem 2rem;
  font-size: 110%;
}

.btn.btn--xl, button.btn--xl, [type=submit].btn--xl, [type=reset].btn--xl, [type=button].btn--xl {
  padding: 1rem 2.5rem;
  font-size: 130%;
}

.btn .fa-wrapper.pad-right, button .fa-wrapper.pad-right, [type=submit] .fa-wrapper.pad-right, [type=reset] .fa-wrapper.pad-right, [type=button] .fa-wrapper.pad-right {
  margin-right: 0.4rem;
}

.btn .fa-wrapper.pad-left, button .fa-wrapper.pad-left, [type=submit] .fa-wrapper.pad-left, [type=reset] .fa-wrapper.pad-left, [type=button] .fa-wrapper.pad-left {
  margin-left: 0.4rem;
}

.btn span:first-child, button span:first-child, [type=submit] span:first-child, [type=reset] span:first-child, [type=button] span:first-child {
  margin-right: 0.2rem;
}

.btn span:last-child, button span:last-child, [type=submit] span:last-child, [type=reset] span:last-child, [type=button] span:last-child {
  margin-left: 0.2rem;
}

/* Links as Buttons */
a.btn {
  display: inline-flex;
}

[class^=btn-]:not(.btn-container):not(.btn-close),
[class*=" btn-"]:not(.btn-container):not(.btn-close) {
  background-color: rgba(var(--btn-color), var(--bg-opacity));
  border: 1px solid rgba(var(--btn-border-color), var(--color-opacity));
  color: rgba(var(--btn-fg), var(--color-opacity));
  transition: all var(--animation-duration);
  /* Outline Variants */
}

[class^=btn-]:not(.btn-container):not(.btn-close):hover,
[class*=" btn-"]:not(.btn-container):not(.btn-close):hover {
  background-color: rgba(var(--btn-color), var(--bg-opacity));
  border-color: rgba(var(--btn-border-color), var(--color-opacity));
  transition: all var(--animation-duration);
}

[class^=btn-]:not(.btn-container):not(.btn-close).outline,
[class*=" btn-"]:not(.btn-container):not(.btn-close).outline {
  background-color: transparent;
  color: rgba(var(--btn-color), var(--color-opacity));
}

[class^=btn-]:not(.btn-container):not(.btn-close).outline:hover,
[class*=" btn-"]:not(.btn-container):not(.btn-close).outline:hover {
  background-color: rgba(var(--btn-color), var(--bg-opacity));
  color: rgba(var(--btn-fg), var(--color-opacity));
  transition: all var(--animation-duration);
}

[class^=btn-]:not(.btn-container):not(.btn-close).outline.btn-transparent,
[class*=" btn-"]:not(.btn-container):not(.btn-close).outline.btn-transparent {
  color: 73, 80, 87 !important;
}

/* Make the loader white so it is visible */
.loading.btn-accent:after {
  border: 2px solid #fff;
  border-right-color: transparent;
  border-top-color: transparent;
}

/* BUTTON GROUPS */
/* Will group buttons with components in a single component */
.btn-group {
  display: inline-flex;
}

.btn-group .btn, .btn-group button, .btn-group [type=submit], .btn-group [type=reset], .btn-group [type=button] {
  flex: 1 0 auto;
  margin: 0;
}

.btn-group .btn:first-child:not(:last-child), .btn-group button:first-child:not(:last-child), .btn-group [type=submit]:first-child:not(:last-child), .btn-group [type=reset]:first-child:not(:last-child), .btn-group [type=button]:first-child:not(:last-child) {
  /* Style the first child in group > 1 buttons */
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group .btn:last-child:not(:first-child), .btn-group button:last-child:not(:first-child), .btn-group [type=submit]:last-child:not(:first-child), .btn-group [type=reset]:last-child:not(:first-child), .btn-group [type=button]:last-child:not(:first-child) {
  /* Style the last child in group > 1 buttons */
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  margin-left: -1px;
}

.btn-group .btn:not(:first-child):not(:last-child), .btn-group button:not(:first-child):not(:last-child), .btn-group [type=submit]:not(:first-child):not(:last-child), .btn-group [type=reset]:not(:first-child):not(:last-child), .btn-group [type=button]:not(:first-child):not(:last-child) {
  /* Style button in middle of group */
  border-radius: 0; /* Remove roundness from center buttons */
  margin-left: -1px;
}

.btn-group .btn:focus, .btn-group button:focus, .btn-group [type=submit]:focus, .btn-group [type=reset]:focus, .btn-group [type=button]:focus {
  z-index: 1;
}

.btn-group.btn-group-fill {
  /* Makes the button group fill the width and proportion the buttons to fill the space */
  display: flex;
}

.btn-group.btn-group-fill .btn, .btn-group.btn-group-fill button, .btn-group.btn-group-fill [type=submit], .btn-group.btn-group-fill [type=reset], .btn-group.btn-group-fill [type=button] {
  flex: 1 0; /* Make the buttons fill the available width and proportion themselves */
}

.btn-group.btn-group-fill:focus {
  z-index: 1;
}

@media screen and (max-width: 767px) {
  .btn-group {
    display: flex;
    flex-direction: column;
  }
  .btn-group .btn, .btn-group button, .btn-group [type=submit], .btn-group [type=reset], .btn-group [type=button] {
    margin-bottom: -1px;
  }
  .btn-group .btn:first-child:not(:last-child), .btn-group button:first-child:not(:last-child), .btn-group [type=submit]:first-child:not(:last-child), .btn-group [type=reset]:first-child:not(:last-child), .btn-group [type=button]:first-child:not(:last-child) {
    border-radius: 0.25rem 0.25rem 0 0;
  }
  .btn-group .btn:not(:first-child):not(:last-child), .btn-group button:not(:first-child):not(:last-child), .btn-group [type=submit]:not(:first-child):not(:last-child), .btn-group [type=reset]:not(:first-child):not(:last-child), .btn-group [type=button]:not(:first-child):not(:last-child) {
    margin-left: 0;
  }
  .btn-group .btn:last-child:not(:first-child), .btn-group button:last-child:not(:first-child), .btn-group [type=submit]:last-child:not(:first-child), .btn-group [type=reset]:last-child:not(:first-child), .btn-group [type=button]:last-child:not(:first-child) {
    border-radius: 0 0 0.25rem 0.25rem;
    margin-left: 0;
  }
}
/* CODE */
code {
  --cirrus-code-label-fg: #868e96;
  padding: 0.3rem;
  margin: 0.5em 0;
  overflow: auto;
  background-color: var(--cirrus-code-bg);
  color: var(--cirrus-code-fg);
  border-radius: 3px;
  /* Dark theme for code */
}

code:before {
  color: var(--cirrus-code-label-fg);
  content: attr(data-lang);
  font-size: 0.9rem;
  position: absolute;
  right: 1rem;
  top: 0.7rem;
}

code.dark {
  --cirrus-code-bg: #343a40;
  --cirrus-code-fg: #f8f9fa;
  --cirrus-border-left-bg: #212529;
  --cirrus-code-label-fg: #ced4da;
}

pre > code {
  --cirrus-code-bg: #f8f9fa;
  --cirrus-code-fg: #343a40;
  --cirrus-border-left-bg: #e9ecef;
  background-color: var(--cirrus-code-bg);
  font-size: 14px;
  display: block;
  padding: 1rem;
  white-space: pre-wrap;
  word-wrap: break-word;
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  text-align: left;
  line-height: 1.5;
  tab-size: 4;
  -moz-hyphens: none;
  -ms-hyphens: none;
  hyphens: none;
  border-left: 0.3rem solid var(--cirrus-border-left-bg);
  margin: 0;
  position: relative;
  color: var(--cirrus-code-fg);
}

pre > code[data-lang]:not([data-lang=""]) {
  padding: 2rem 1.5rem 1rem;
}

/* FOOTER */
.footer {
  background-color: #343a40;
  padding: 6rem 0;
  text-align: center;
  margin-top: 5rem;
  width: 100%;
}

.footer.footer--fixed {
  bottom: 0;
  position: fixed;
}

.footer p {
  color: #868e96;
}

.footer ul {
  margin: 0.5rem 0; /* Remove the left margin seen in global style */
}

.footer .footer__title {
  text-align: center;
  letter-spacing: 6px;
  position: relative;
  padding-bottom: 10px;
}

.footer .footer__list-title {
  color: #ced4da;
  font-size: 75%;
  text-transform: uppercase;
  font-weight: bolder;
}

.footer .footer__list-title::after {
  content: "";
  display: block;
  width: 10%;
  margin: auto;
  border-bottom: 2px solid;
  border-color: #343b49;
}

.footer ul a .footer__list-item,
.footer .footer__list-item {
  margin: 0.1rem;
  color: #868e96;
  transition: all var(--animation-duration);
  font-size: 75%;
  text-transform: uppercase;
}

/*
    Mixins
*/
/* This is just an extended portion of an input which is just like a label and is read only */
/* This will style the controls if they are the leftmost control in the form group for labels, inputs, and buttons. */
/* FORMS */
/*
    Styles
*/
input:not([type=checkbox]):not([type=radio]):not([type=submit]):not([type=button]):not([type=reset]),
select {
  width: 100%;
  border: 1px solid #dee2e6;
  border-radius: 3px;
  font-family: "Nunito Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: var(--font-size-m);
  letter-spacing: 0.02rem;
  transition: all 0.3s;
  outline: none;
  padding: 0.85rem 1.1rem;
  /* Input Field Sizes */
  /* Hold Font Awesome glyphs inside input fields */
  /* More sizes coming soon */
  /* Search field */
}

input:not([type=checkbox]):not([type=radio]):not([type=submit]):not([type=button]):not([type=reset]).input--xs,
select.input--xs {
  font-size: 0.75rem;
  padding: 0.35rem 0.9rem;
}

input:not([type=checkbox]):not([type=radio]):not([type=submit]):not([type=button]):not([type=reset]).input--sm,
select.input--sm {
  font-size: 0.875rem;
  padding: 0.55rem 1rem;
}

input:not([type=checkbox]):not([type=radio]):not([type=submit]):not([type=button]):not([type=reset]).input--lg,
select.input--lg {
  font-size: 1.25rem;
}

input:not([type=checkbox]):not([type=radio]):not([type=submit]):not([type=button]):not([type=reset]).input--xl,
select.input--xl {
  font-size: 1.5rem;
}

input:not([type=checkbox]):not([type=radio]):not([type=submit]):not([type=button]):not([type=reset]).input-contains-icon, input:not([type=checkbox]):not([type=radio]):not([type=submit]):not([type=button]):not([type=reset]).input-contains-icon-left,
select.input-contains-icon,
select.input-contains-icon-left {
  padding-left: 2.75rem;
}

input:not([type=checkbox]):not([type=radio]):not([type=submit]):not([type=button]):not([type=reset]).input-contains-icon-right,
select.input-contains-icon-right {
  padding-right: 2.75rem;
}

input:not([type=checkbox]):not([type=radio]):not([type=submit]):not([type=button]):not([type=reset]).input-contains-icon.input--xs, input:not([type=checkbox]):not([type=radio]):not([type=submit]):not([type=button]):not([type=reset]).input-contains-icon-right.input-xs,
select.input-contains-icon.input--xs,
select.input-contains-icon-right.input-xs {
  padding-left: 2rem;
}

input:not([type=checkbox]):not([type=radio]):not([type=submit]):not([type=button]):not([type=reset]).input-contains-icon.input--sm, input:not([type=checkbox]):not([type=radio]):not([type=submit]):not([type=button]):not([type=reset]).input-contains-icon-right.input-sm,
select.input-contains-icon.input--sm,
select.input-contains-icon-right.input-sm {
  padding-left: 2.5rem;
}

input:not([type=checkbox]):not([type=radio]):not([type=submit]):not([type=button]):not([type=reset]).input-contains-icon.input--lg, input:not([type=checkbox]):not([type=radio]):not([type=submit]):not([type=button]):not([type=reset]).input-contains-icon-right.input-lg,
select.input-contains-icon.input--lg,
select.input-contains-icon-right.input-lg {
  padding-left: 3.5rem;
}

input:not([type=checkbox]):not([type=radio]):not([type=submit]):not([type=button]):not([type=reset]).input-contains-icon.input--xl, input:not([type=checkbox]):not([type=radio]):not([type=submit]):not([type=button]):not([type=reset]).input-contains-icon-right.input-xl,
select.input-contains-icon.input--xl,
select.input-contains-icon-right.input-xl {
  padding-left: 4rem;
}

input:not([type=checkbox]):not([type=radio]):not([type=submit]):not([type=button]):not([type=reset]).input-contains-icon.input--xs ~ .icon, input:not([type=checkbox]):not([type=radio]):not([type=submit]):not([type=button]):not([type=reset]).input-contains-icon-left.input--xs ~ .icon, input:not([type=checkbox]):not([type=radio]):not([type=submit]):not([type=button]):not([type=reset]).input-contains-icon-right.input--xs ~ .icon.icon-right,
select.input-contains-icon.input--xs ~ .icon,
select.input-contains-icon-left.input--xs ~ .icon,
select.input-contains-icon-right.input--xs ~ .icon.icon-right {
  line-height: 1.75rem;
  width: 1.75rem;
  font-size: 7px;
}

input:not([type=checkbox]):not([type=radio]):not([type=submit]):not([type=button]):not([type=reset]).input-contains-icon.input--sm ~ .icon, input:not([type=checkbox]):not([type=radio]):not([type=submit]):not([type=button]):not([type=reset]).input-contains-icon-left.input--sm ~ .icon, input:not([type=checkbox]):not([type=radio]):not([type=submit]):not([type=button]):not([type=reset]).input-contains-icon-right.input--sm ~ .icon.icon-right,
select.input-contains-icon.input--sm ~ .icon,
select.input-contains-icon-left.input--sm ~ .icon,
select.input-contains-icon-right.input--sm ~ .icon.icon-right {
  width: 2.5rem;
  font-size: 14px;
}

input:not([type=checkbox]):not([type=radio]):not([type=submit]):not([type=button]):not([type=reset]).input-contains-icon.input--lg ~ .icon, input:not([type=checkbox]):not([type=radio]):not([type=submit]):not([type=button]):not([type=reset]).input-contains-icon-left.input--lg ~ .icon, input:not([type=checkbox]):not([type=radio]):not([type=submit]):not([type=button]):not([type=reset]).input-contains-icon-right.input--lg ~ .icon.icon-right,
select.input-contains-icon.input--lg ~ .icon,
select.input-contains-icon-left.input--lg ~ .icon,
select.input-contains-icon-right.input--lg ~ .icon.icon-right {
  line-height: 3.5rem;
  width: 3.5rem;
  font-size: 28px;
}

input:not([type=checkbox]):not([type=radio]):not([type=submit]):not([type=button]):not([type=reset]).input-contains-icon.input--xl ~ .icon, input:not([type=checkbox]):not([type=radio]):not([type=submit]):not([type=button]):not([type=reset]).input-contains-icon-left.input--xl ~ .icon, input:not([type=checkbox]):not([type=radio]):not([type=submit]):not([type=button]):not([type=reset]).input-contains-icon-right.input--xl ~ .icon.icon-right,
select.input-contains-icon.input--xl ~ .icon,
select.input-contains-icon-left.input--xl ~ .icon,
select.input-contains-icon-right.input--xl ~ .icon.icon-right {
  line-height: 2.5rem;
  width: 3.75rem;
  font-size: 35px;
}

input:not([type=checkbox]):not([type=radio]):not([type=submit]):not([type=button]):not([type=reset])[type=search], input:not([type=checkbox]):not([type=radio]):not([type=submit]):not([type=button]):not([type=reset]).search,
select[type=search],
select.search {
  background-repeat: no-repeat;
  background-position: left 0.6rem center;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><path opacity="0.45" d="M14.891,14.39l-0.5.5a0.355,0.355,0,0,1-.5,0L9.526,10.529a5.3,5.3,0,1,1,2.106-4.212,5.268,5.268,0,0,1-1.1,3.21l4.362,4.362A0.354,0.354,0,0,1,14.891,14.39ZM6.316,2.418a3.9,3.9,0,1,0,3.9,3.9A3.9,3.9,0,0,0,6.316,2.418Z"/></svg>'); /* NEEDS FIXING */
  padding-left: 2rem !important;
}

input:not([type=checkbox]):not([type=radio]):not([type=submit]):not([type=button]):not([type=reset])[type=color],
select[type=color] {
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.05);
  box-sizing: border-box;
  transition: 0.3s;
  outline: 0;
  position: relative;
  height: 3rem;
  background-color: #fff;
  padding: 0.55rem 0.8rem !important;
}

input:not([type=checkbox]):not([type=radio]):not([type=submit]):not([type=button]):not([type=reset]).input-success,
select.input-success {
  border-color: #0dd157;
  background-color: rgba(0, 224, 0, 0.05) !important;
}

input:not([type=checkbox]):not([type=radio]):not([type=submit]):not([type=button]):not([type=reset]).input-success:focus,
select.input-success:focus {
  box-shadow: 0 0 0 0.2rem rgba(13, 209, 87, 0.55), inset 0px 1px 8px rgba(0, 0, 0, 0.07);
}

input:not([type=checkbox]):not([type=radio]):not([type=submit]):not([type=button]):not([type=reset]).input-error,
select.input-error {
  border-color: #fb4143;
  background-color: rgba(244, 67, 54, 0.05) !important;
}

input:not([type=checkbox]):not([type=radio]):not([type=submit]):not([type=button]):not([type=reset]).input-error:focus,
select.input-error:focus {
  box-shadow: 0 0 0 0.2rem rgba(251, 65, 67, 0.55), inset 0px 1px 8px rgba(0, 0, 0, 0.07);
}

textarea,
textarea[type=text] {
  width: 100%;
  border: 1px solid #dee2e6;
  border-radius: 3px;
  font-family: "Nunito Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: var(--font-size-m);
  letter-spacing: 0.02rem;
  transition: all 0.3s;
  outline: none;
  padding: 0.85rem 1.1rem;
  /* Input Field Sizes */
  /* Hold Font Awesome glyphs inside input fields */
  /* More sizes coming soon */
  min-height: 8rem;
  line-height: 1.5rem;
  resize: vertical;
}

textarea.input--xs,
textarea[type=text].input--xs {
  font-size: 0.75rem;
  padding: 0.35rem 0.9rem;
}

textarea.input--sm,
textarea[type=text].input--sm {
  font-size: 0.875rem;
  padding: 0.55rem 1rem;
}

textarea.input--lg,
textarea[type=text].input--lg {
  font-size: 1.25rem;
}

textarea.input--xl,
textarea[type=text].input--xl {
  font-size: 1.5rem;
}

textarea.input-contains-icon, textarea.input-contains-icon-left,
textarea[type=text].input-contains-icon,
textarea[type=text].input-contains-icon-left {
  padding-left: 2.75rem;
}

textarea.input-contains-icon-right,
textarea[type=text].input-contains-icon-right {
  padding-right: 2.75rem;
}

textarea.input-contains-icon.input--xs, textarea.input-contains-icon-right.input-xs,
textarea[type=text].input-contains-icon.input--xs,
textarea[type=text].input-contains-icon-right.input-xs {
  padding-left: 2rem;
}

textarea.input-contains-icon.input--sm, textarea.input-contains-icon-right.input-sm,
textarea[type=text].input-contains-icon.input--sm,
textarea[type=text].input-contains-icon-right.input-sm {
  padding-left: 2.5rem;
}

textarea.input-contains-icon.input--lg, textarea.input-contains-icon-right.input-lg,
textarea[type=text].input-contains-icon.input--lg,
textarea[type=text].input-contains-icon-right.input-lg {
  padding-left: 3.5rem;
}

textarea.input-contains-icon.input--xl, textarea.input-contains-icon-right.input-xl,
textarea[type=text].input-contains-icon.input--xl,
textarea[type=text].input-contains-icon-right.input-xl {
  padding-left: 4rem;
}

textarea.input-contains-icon.input--xs ~ .icon, textarea.input-contains-icon-left.input--xs ~ .icon, textarea.input-contains-icon-right.input--xs ~ .icon.icon-right,
textarea[type=text].input-contains-icon.input--xs ~ .icon,
textarea[type=text].input-contains-icon-left.input--xs ~ .icon,
textarea[type=text].input-contains-icon-right.input--xs ~ .icon.icon-right {
  line-height: 1.75rem;
  width: 1.75rem;
  font-size: 7px;
}

textarea.input-contains-icon.input--sm ~ .icon, textarea.input-contains-icon-left.input--sm ~ .icon, textarea.input-contains-icon-right.input--sm ~ .icon.icon-right,
textarea[type=text].input-contains-icon.input--sm ~ .icon,
textarea[type=text].input-contains-icon-left.input--sm ~ .icon,
textarea[type=text].input-contains-icon-right.input--sm ~ .icon.icon-right {
  width: 2.5rem;
  font-size: 14px;
}

textarea.input-contains-icon.input--lg ~ .icon, textarea.input-contains-icon-left.input--lg ~ .icon, textarea.input-contains-icon-right.input--lg ~ .icon.icon-right,
textarea[type=text].input-contains-icon.input--lg ~ .icon,
textarea[type=text].input-contains-icon-left.input--lg ~ .icon,
textarea[type=text].input-contains-icon-right.input--lg ~ .icon.icon-right {
  line-height: 3.5rem;
  width: 3.5rem;
  font-size: 28px;
}

textarea.input-contains-icon.input--xl ~ .icon, textarea.input-contains-icon-left.input--xl ~ .icon, textarea.input-contains-icon-right.input--xl ~ .icon.icon-right,
textarea[type=text].input-contains-icon.input--xl ~ .icon,
textarea[type=text].input-contains-icon-left.input--xl ~ .icon,
textarea[type=text].input-contains-icon-right.input--xl ~ .icon.icon-right {
  line-height: 2.5rem;
  width: 3.75rem;
  font-size: 35px;
}

input:not([type=checkbox]):not([type=radio]):not([type=submit]):not([type=button]):not([type=reset]):focus,
select:focus,
textarea:focus,
textarea[type=text]:focus,
input[type=text].input-focused,
.input-focused {
  border-color: rgb(61, 171, 240);
  box-shadow: 0 0 0 0.2rem rgba(61, 171, 240, 0.45), inset 0px 1px 8px rgba(0, 0, 0, 0.07);
}

input:not([type=checkbox]):not([type=radio]):not([type=submit]):not([type=button]):not([type=reset]):focus.input-success,
select:focus.input-success,
textarea:focus.input-success,
textarea[type=text]:focus.input-success,
input[type=text].input-focused.input-success,
.input-focused.input-success {
  border-color: #0dd157;
  background-color: rgba(0, 224, 0, 0.05) !important;
}

input:not([type=checkbox]):not([type=radio]):not([type=submit]):not([type=button]):not([type=reset]):focus.input-success:focus,
select:focus.input-success:focus,
textarea:focus.input-success:focus,
textarea[type=text]:focus.input-success:focus,
input[type=text].input-focused.input-success:focus,
.input-focused.input-success:focus {
  box-shadow: 0 0 0 0.2rem rgba(13, 209, 87, 0.55), inset 0px 1px 8px rgba(0, 0, 0, 0.07);
}

input:not([type=checkbox]):not([type=radio]):not([type=submit]):not([type=button]):not([type=reset]):focus.input-error,
select:focus.input-error,
textarea:focus.input-error,
textarea[type=text]:focus.input-error,
input[type=text].input-focused.input-error,
.input-focused.input-error {
  border-color: #fb4143;
  background-color: rgba(244, 67, 54, 0.05) !important;
}

input:not([type=checkbox]):not([type=radio]):not([type=submit]):not([type=button]):not([type=reset]):focus.input-error:focus,
select:focus.input-error:focus,
textarea:focus.input-error:focus,
textarea[type=text]:focus.input-error:focus,
input[type=text].input-focused.input-error:focus,
.input-focused.input-error:focus {
  box-shadow: 0 0 0 0.2rem rgba(251, 65, 67, 0.55), inset 0px 1px 8px rgba(0, 0, 0, 0.07);
}

select {
  width: 100%;
  border: 1px solid #dee2e6;
  border-radius: 3px;
  font-family: "Nunito Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: var(--font-size-m);
  letter-spacing: 0.02rem;
  transition: all 0.3s;
  outline: none;
  padding: 0.85rem 1.1rem;
  /* Input Field Sizes */
  /* Hold Font Awesome glyphs inside input fields */
  /* More sizes coming soon */
  background-color: #fff;
  border: 1px solid #ddd;
  /* Fixes for Safari and other browsers for consistent UI */
}

select.input--xs {
  font-size: 0.75rem;
  padding: 0.35rem 0.9rem;
}

select.input--sm {
  font-size: 0.875rem;
  padding: 0.55rem 1rem;
}

select.input--lg {
  font-size: 1.25rem;
}

select.input--xl {
  font-size: 1.5rem;
}

select.input-contains-icon, select.input-contains-icon-left {
  padding-left: 2.75rem;
}

select.input-contains-icon-right {
  padding-right: 2.75rem;
}

select.input-contains-icon.input--xs, select.input-contains-icon-right.input-xs {
  padding-left: 2rem;
}

select.input-contains-icon.input--sm, select.input-contains-icon-right.input-sm {
  padding-left: 2.5rem;
}

select.input-contains-icon.input--lg, select.input-contains-icon-right.input-lg {
  padding-left: 3.5rem;
}

select.input-contains-icon.input--xl, select.input-contains-icon-right.input-xl {
  padding-left: 4rem;
}

select.input-contains-icon.input--xs ~ .icon, select.input-contains-icon-left.input--xs ~ .icon, select.input-contains-icon-right.input--xs ~ .icon.icon-right {
  line-height: 1.75rem;
  width: 1.75rem;
  font-size: 7px;
}

select.input-contains-icon.input--sm ~ .icon, select.input-contains-icon-left.input--sm ~ .icon, select.input-contains-icon-right.input--sm ~ .icon.icon-right {
  width: 2.5rem;
  font-size: 14px;
}

select.input-contains-icon.input--lg ~ .icon, select.input-contains-icon-left.input--lg ~ .icon, select.input-contains-icon-right.input--lg ~ .icon.icon-right {
  line-height: 3.5rem;
  width: 3.5rem;
  font-size: 28px;
}

select.input-contains-icon.input--xl ~ .icon, select.input-contains-icon-left.input--xl ~ .icon, select.input-contains-icon-right.input--xl ~ .icon.icon-right {
  line-height: 2.5rem;
  width: 3.75rem;
  font-size: 35px;
}

select[multiple] option {
  padding: 0.2rem 0.4rem;
}

select.select:not([size]):not([multiple]) {
  background: url("data:image/svg+xml;charset=utf8,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%204%205'%3E%3Cpath%20fill='%23667189'%20d='M2%200L0%202h4zm0%205L0%203h4z'/%3E%3C/svg%3E") no-repeat right 0.85rem center/0.5rem 0.6rem no-repeat;
  appearance: none;
}

input:not([class*=btn-]):disabled,
input:not([class*=" btn-"]):disabled:hover,
select:disabled,
textarea:disabled {
  background-color: #f1f3f5;
  cursor: not-allowed;
  border: 1px solid #f1f3f5;
}

label.label {
  color: #495057;
  display: inline-block; /* changed from block */
  font-weight: bold;
  margin-top: 0.8rem;
}

label + .input-control {
  margin-top: 0;
}

label.label:not(:last-child) {
  margin-bottom: 0;
}

label:first-child:not(:last-child):not(.form-group-label) {
  margin-right: 0.5rem;
}

label:not(:first-child):not(:last-child):not(.form-group-label) {
  margin: 0 0.5rem;
}

label:last-child:not(:first-child):not(.form-group-label) {
  margin-left: 0.5rem;
}

/* Required asterisk */
.required {
  /* Use this in a span or a div */
  position: relative;
  top: 1px;
  font-weight: bold;
  color: #c81e1e;
  padding-left: 0.1rem;
}

/* Used for when the input controls have another control adjacent to it but we want to position it inside the input field like font-awesome glyphs. As of 0.5.2, it is the default container for input fields. */
.input-control {
  position: relative;
  margin: 0.5rem 0;
}

.input-contains-icon ~ .icon,
.input-contains-icon-left ~ .icon,
.input-contains-icon-right ~ .icon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.input-contains-icon ~ .icon:not(.icon-right),
.input-contains-icon-left ~ .icon.icon-left {
  /* Nomral input only */
  position: absolute;
  left: 0;
  top: 0;
  width: 3rem;
  z-index: 1;
}

.input-contains-icon-right ~ .icon.icon-right {
  position: absolute;
  pointer-events: none;
  line-height: 2.75rem;
  vertical-align: baseline;
  top: 0;
  right: 0;
  width: 3rem;
  z-index: 1;
}

/* Form groups that group inputs with other controls. */
.form-group {
  display: flex;
  margin: 0.5rem 0; /* Add margin to the container since they have been removed from children */
  /* Fixes the text width being cut off */
  /* Form group controls */
  /* Keeps inputs in form group above other components */
}

.form-group .form-group-btn {
  flex: 1 0 auto;
  margin-bottom: 0;
  /* Style the front controls */
  /* Style the middle controls */
  /* Handles right most control in form group */
}

.form-group .form-group-btn:first-child:not(:last-child) {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.form-group .form-group-btn:not(:first-child):not(:last-child) {
  border-radius: 0 !important;
  margin-left: -1px;
  margin-right: -1px; /* Remove spacing in middle controls */
}

.form-group .form-group-btn:last-child:not(:first-child) {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  margin-left: -1px;
  z-index: 0;
}

.form-group .form-group-btn:focus {
  z-index: 1;
}

.form-group .form-group-label {
  background-color: var(--cirrus-form-group-bg);
  border: 1px solid #dee2e6;
  border-radius: 0.2rem;
  color: var(--cirrus-form-group-fg);
  margin: 0;
  padding: 0.8rem;
  user-select: none;
  /* Style the front controls */
  /* Style the middle controls */
  /* Handles right most control in form group */
}

.form-group .form-group-label:first-child:not(:last-child) {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.form-group .form-group-label:not(:first-child):not(:last-child) {
  border-radius: 0 !important;
  margin-left: -1px;
  margin-right: -1px; /* Remove spacing in middle controls */
}

.form-group .form-group-label:last-child:not(:first-child) {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  margin-left: -1px;
  z-index: 0;
}

.form-group .form-group-label.label--xs {
  font-size: 0.75rem;
}

.form-group .form-group-label.label--sm {
  font-size: 0.875rem;
}

.form-group .form-group-label.label--lg {
  font-size: 1.25rem;
}

.form-group .form-group-label.label--xl {
  font-size: 1.5rem;
}

.form-group .form-group-input {
  /* Style the front controls */
  /* Style the middle controls */
  /* Handles right most control in form group */
}

.form-group .form-group-input:first-child:not(:last-child) {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.form-group .form-group-input:not(:first-child):not(:last-child) {
  border-radius: 0 !important;
  margin-left: -1px;
  margin-right: -1px; /* Remove spacing in middle controls */
}

.form-group .form-group-input:last-child:not(:first-child) {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  margin-left: -1px;
  z-index: 0;
}

.form-group .form-group-input:focus {
  z-index: 1;
}

/* Change place holder color */
::-moz-placeholder {
  color: #a9a9a9; /* Lighter than the default */
}

::-webkit-input-placeholder {
  color: #a9a9a9; /* Lighter than the default */
}

/* Separate CSS file, but same part as Layout */
/* The base container that is similar in structure to a card but more flexible */
.frame {
  border-radius: 3px;
  box-shadow: 0 0.2rem 1.25rem 0 rgba(27, 30, 36, 0.07);
  display: flex;
  flex-direction: column;
  /* For the frame header and footer, use flex display */
  /* The frame navbar can be used with level or header classes to create a navigation menu */
}

.frame .frame__header,
.frame .frame__footer {
  flex: 0 0 auto;
  padding: 1rem;
}

.frame .frame__nav {
  flex: 0 0 auto;
}

.frame .frame__body {
  flex: 1 1 auto;
  overflow-y: auto; /* Show the scrollbar when needed */
  padding: 0 1rem;
}

.frame .frame__title {
  color: #0f172a;
  font-size: 1rem;
  margin: 0.75rem auto 0;
}

.frame .frame__subtitle {
  color: rgba(15, 23, 42, 0.6);
  font-size: 1rem;
  margin: 0 auto 0.75rem;
}

/* These styles can also be used on menus in the body */
/* HEADER */
.header {
  flex-grow: 1;
  width: 100%;
  z-index: 100;
  margin-bottom: 20px;
  box-shadow: 0 3px 15px rgba(57, 63, 72, 0.1);
  background-color: var(--cirrus-bg);
  max-height: 100vh;
  padding: 0 2rem;
  transition: all 0.3s;
  display: flex;
  --header-link-color: #495057;
  --header-link-color-hover: #606a73;
  /* Remove any padding set for headers in default.scss */
  /* Header dark theme */
  /* Add transition to nav menu when it drops down */
  /* The container that contains all the header menu components. Child of header */
  /* Static header at the top of the screen */
  /* Hover effect for header elements when appropriate */
}

.header h1 {
  margin: 0;
}

.header h2 {
  margin: 0;
}

.header h3 {
  margin: 0;
}

.header h4 {
  margin: 0;
}

.header h5 {
  margin: 0;
}

.header h6 {
  margin: 0;
}

.header a {
  color: var(--header-link-color);
}

.header a:hover {
  color: var(--header-link-color-hover);
}

.header.header-dark {
  background-color: rgba(0, 0, 0, 0.87);
  color: #fff;
  --header-link-color: #fff;
  --header-link-color-hover: #fff;
  /* Theme for dark menu */
}

.header.header-clear {
  background-color: transparent;
  box-shadow: none;
  /* Add border radius to whole menu with clear header */
}

.header.header-clear .nav-item .dropdown-menu {
  border-radius: 4px;
}

.header.header-animated .header-nav {
  transition: all 0.3s;
}

.header .header-nav {
  /* Flex display 768px and higher */
  overflow: auto;
}

.header .header-brand {
  align-items: stretch;
  display: flex;
  flex-shrink: 0;
  max-width: 100vw;
  min-height: 3.25rem;
  overflow-x: auto;
  overflow-y: hidden;
}

.header.header-fixed {
  position: fixed;
  top: 0; /* Change this if you want to float the header somewhere else */
}

.header:not(.header-clear) .nav-item:not(.no-hover):hover, .header:not(.header-clear) .nav-item:not(.no-hover).hovered {
  background-color: rgba(216, 216, 216, 0.15);
  transition: all 0.3s;
}

.header:not(.header-clear) .nav-item.active, .header:not(.header-clear) .nav-item.active:hover {
  background-color: rgba(216, 216, 216, 0.35);
}

.header .nav-btn {
  cursor: pointer;
  display: block;
  height: 3.5rem;
  position: relative;
  width: 3.5rem;
}

.header .btn, .header button, .header [type=submit], .header [type=reset], .header [type=button] {
  margin: 0;
}

/* Styles for header menu (aka the nav bar) */
.nav-menu {
  transition: all 0.3s;
}

.nav-menu .has-sub {
  position: relative;
}

/* Allow the user to scroll through navbar items if it exceeds nav-left, nav-center, or nav-right widths */
.nav-overflow-x {
  justify-content: inherit;
  overflow-x: scroll;
}

/* Wrapper to vertically center header items */
.nav-item {
  align-items: center;
  display: flex;
  position: relative;
  flex-grow: 0;
  flex-shrink: 0;
  justify-content: center;
  transition: all 0.3s;
  padding: 0 0.3rem;
  cursor: pointer;
  /* HEADER DROP DOWN MENU */
  /* Add room for the dropdown chevron pseudoelement */
}

.nav-item a {
  align-items: center;
  display: flex;
}

.nav-item .dropdown-menu {
  background-color: var(--cirrus-bg);
  position: absolute;
  top: 95%;
  /*right: 0; /* Unable to solve problem when we have multiple drop down menus */
  z-index: 1000;
  float: left;
  min-width: 160px;
  padding: 5px 0;
  margin: 2px 0 0;
  font-size: 14px;
  text-align: left;
  list-style: none;
  background-clip: padding-box;
  border: 1px solid #e9ecef;
  border-radius: 0 0 4px 4px;
  box-shadow: 0 0.5rem 1rem rgba(10, 10, 10, 0.1);
  /* Just add a transition in general */
}

.nav-item .dropdown-menu.dropdown-animated {
  transition: all var(--animation-duration);
}

.nav-item .dropdown-menu > li > a {
  display: block;
  padding: 0.5rem 1rem;
  clear: both;
  line-height: 1.42857143;
  white-space: nowrap;
}

.nav-item .dropdown-menu > li {
  margin: 0;
  transition: all 0.3s;
}

.nav-item .dropdown-menu > li:hover {
  transition: all 0.3s;
  background-color: rgba(216, 216, 216, 0.15);
}

.nav-item .dropdown-menu > li:active {
  transition: all 0.3s;
  background-color: rgba(216, 216, 216, 0.25);
}

.nav-item .dropdown-menu > li:last-child {
  margin-bottom: 0;
}

.nav-item .dropdown-menu .dropdown-menu-divider {
  border: none;
  background-color: rgba(216, 216, 216, 0.15);
  height: 1px;
  margin: 0.5rem 0;
}

.nav-item.has-sub .nav-dropdown-link {
  padding-right: 2.5rem;
  position: relative; /* Needed for hiding glyphs in mobile header */
  /* Dropdown menu location adjustment located below for sizes above phones */
  /* Dropdown menu indicator */
}

.nav-item.has-sub .nav-dropdown-link::after {
  border: 2px solid #f03d4d; /* Must be first to create the triangle shape */
  border-right: 0; /* Create the triangle effect */
  border-top: 0;
  display: block;
  height: 0.5em;
  width: 0.5em;
  content: " ";
  transform: rotate(-45deg);
  pointer-events: none;
  margin-top: -0.435em;
  right: 1.125em;
  top: 50%;
  position: absolute;
}

/* Dark dropdown menu theme */
.nav-item .dropdown-menu.dropdown-dark,
.header.header-dark .dropdown-menu {
  background-color: rgba(0, 0, 0, 0.87);
  border: 1px solid #343a40;
  color: #fff;
}

.dropdown-menu.dropdown-shown,
.nav-item.active {
  opacity: 1;
}

@media screen and (min-width: 768px) {
  .header {
    align-items: stretch;
    display: flex;
    /* Align items to the left */
    /* Align items to the right */
    /* Centering items, best used when nav-brand is not used to prevent offset */
    /* Hide hamburger button */
  }
  .header .header-nav {
    flex-grow: 1;
    align-items: stretch; /* Make items fill up all available space regardless of width https://drafts.csswg.org/css-align/ */
    display: flex;
    position: relative;
    text-align: center;
    width: 100%;
    top: 0;
    overflow: visible; /* Only visible for showing dropdown menus, hidden for mobile */
  }
  .header .nav-left {
    align-items: stretch;
    flex-basis: 0;
    flex-grow: 1;
    flex-shrink: 0;
    display: flex;
    justify-content: flex-start;
    white-space: nowrap;
    /* Align dropdown menu to the left side of the parent nav-item for header-left */
  }
  .header .nav-left .has-sub .dropdown-menu {
    left: 0;
    right: auto;
  }
  .header .nav-right {
    align-items: stretch;
    flex-basis: 0;
    flex-grow: 1;
    flex-shrink: 0;
    display: flex;
    justify-content: flex-end;
    white-space: nowrap;
    /* Align dropdown menu to the right side of the parent nav-item for header-right */
  }
  .header .nav-right .has-sub .dropdown-menu {
    left: auto;
    right: 0;
  }
  .header .nav-center {
    align-items: stretch;
    display: flex;
    flex-grow: 0;
    flex-shrink: 0;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
  }
  .header .nav-btn {
    display: none;
  }
  .header .nav-item {
    /* Expand link area for desktop views so it is easier to click */
  }
  .header .nav-item a {
    padding: 0.5rem 1rem;
  }
  .header .nav-item .dropdown-menu {
    opacity: 0;
    pointer-events: none; /* Make cursor not respond to menu links when not displayed */
  }
  .header .nav-item .dropdown-menu.dropdown-animated {
    transform: translateY(-5px);
  }
  .header .nav-item .dropdown-menu.dropdown-shown, .header .nav-item.toggle-hover:hover .dropdown-menu,
.header .nav-item .dropdown-menu.dropdown-animated.dropdown-shown {
    opacity: 1;
    transform: none;
    pointer-events: auto;
  }
}
@media screen and (max-width: 767px) {
  /* Add scrolling support in header */
  .header {
    flex-direction: column;
    /* Hide dropdown menu on mobile */
  }
  .header .header-brand .nav-item:first-child {
    padding: 0 1rem; /* Add padding to the first child, usually the logo */
  }
  .header .header-nav {
    height: 0;
    /* Show the dropdown */
    /* Make the menu items larger in the dropdown menu */
    /* Get rid of extra spaces */
  }
  .header .header-nav.active {
    height: 100vh;
  }
  .header .header-nav .nav-item {
    padding: 1rem;
  }
  .header .header-nav .nav-item > a {
    padding: 0;
    width: 100%; /* Stretch the link to span the entire list item */
  }
  /* Remove flex for mobile devices */
  .nav-item.has-sub {
    display: block;
  }
  .nav-item.has-sub .dropdown-menu {
    /* Hide the menu by default in mobile view (overflow on header-right can expose it) */
    display: none;
  }
  .nav-item.has-sub .dropdown-menu.dropdown-shown {
    border-radius: 0;
    box-shadow: none;
    display: block;
    position: relative;
    top: 1rem;
    float: none;
    border: none;
    background-color: transparent;
    margin-bottom: 1rem;
  }
  .nav-item.has-sub .dropdown-menu.dropdown-dark {
    background-color: rgba(0, 0, 0, 0.17);
    border: 0;
  }
  /* Hamburger button */
  .nav-btn {
    cursor: pointer;
    display: block;
    position: relative;
    margin-left: auto;
    /* Hamburger button on active */
  }
  .nav-btn span {
    background-color: var(--header-link-color);
    display: block;
    height: 2px;
    left: 50%;
    margin-left: -7px;
    position: absolute;
    top: 50%;
    transition: all 86ms ease-out;
    width: 15px;
    /* Line spacing in hamburger button */
  }
  .nav-btn span:nth-child(1) {
    margin-top: -6px;
  }
  .nav-btn span:nth-child(2) {
    margin-top: -1px;
  }
  .nav-btn span:nth-child(3) {
    margin-top: 4px;
  }
  .nav-btn.active span:nth-child(1) {
    margin-left: -5px;
    transform: rotate(45deg);
    transform-origin: left top;
  }
  .nav-btn.active span:nth-child(2) {
    opacity: 0;
  }
  .nav-btn.active span:nth-child(3) {
    margin-left: -5px;
    transform: rotate(-45deg);
    transform-origin: left bottom;
  }
  /* Hide drop down menu items */
  .nav-left,
.nav-center,
.nav-right {
    overflow: hidden;
  }
  /* No need for JS to show dropdown menu on mobile devices now */
  .header .nav-item.has-sub.toggle-hover:not(.no-hover):hover > .dropdown-menu {
    border-radius: 0;
    box-shadow: none;
    display: block;
    position: relative;
    top: 1rem;
    float: none;
    border: none;
    background-color: transparent;
    margin-bottom: 1rem;
  }
}
/* LINKS */
a {
  color: #5e5cc7;
  font-weight: 600;
  padding: 2px;
  text-decoration: none;
  transition: all 0.3s;
}

a:hover {
  color: #4643e2;
  transition: all 0.3s;
}

a.underline {
  text-decoration: underline;
}

.subtitle a {
  padding: 0; /* Removes weird offset in subtitle links */
}

h1 a, h2 a, h3 a, h4 a, h5 a, h6 a,
article a,
blockquote a {
  display: inline;
}

/* Remove uneeded space since buttons have their own padding */
a .btn,
a button,
[type=submit] a {
  margin-bottom: 0;
}

/* LISTS */
ul,
ol {
  margin: 1rem 0 1rem 1rem;
  padding-inline-start: 0.5rem;
  /* Handle nesting */
}

ul ul, ul ol,
ol ul,
ol ol {
  margin: 0 0 0 1rem;
}

ul {
  list-style: disc;
  /* Nested list bullet types */
}

ul ul {
  list-style-type: circle;
}

ul ul ul {
  list-style-type: square;
}

ol ol {
  list-style: lower-alpha;
}

ol ol ol {
  list-style: upper-roman;
}

/* Description lists */
dl {
  margin: 1rem 0;
}

/* Detail title */
dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
}

li {
  margin: 0.25rem 0;
}

/* MISC */
ul {
  /* MENU LISTS */
  /* Style headers to have even space distribution. */
  /* Style menu items */
  /* Style sub menus inside a menu */
  /* Menu item divider */
  /* Title for the section separated by the divider */
}

ul.no-bullets {
  list-style: none;
}

ul.menu {
  font-size: 1rem;
  list-style: none;
  margin: 0.5rem 0;
}

ul .menu-title:not(:first-child) {
  margin-bottom: 1rem;
}

ul .menu-title:not(:last-child) {
  margin-top: 1rem;
}

ul .menu-item a {
  color: #495057;
  display: block;
  padding: 0.5em 0.75em;
  border-radius: 3px;
  font-size: var(--font-size-s);
  cursor: pointer;
  transition: all var(--animation-duration);
}

ul .menu-item:hover > a {
  background-color: rgba(208, 208, 208, 0.3);
  color: #f03d4d;
  transition: all var(--animation-duration);
}

ul .menu-item.selected > a {
  color: #fff;
  background-color: #f03d4d;
}

ul .menu-item .menu-addon {
  padding: 0.3rem;
  z-index: 1;
  position: relative;
  color: var(--cirrus-fg);
  cursor: pointer;
  float: left;
  margin-right: 0.1rem;
  transition: all var(--animation-duration);
}

ul .menu-item .menu-addon .icon {
  font-size: inherit;
  vertical-align: auto;
}

ul .menu-item .menu-addon:hover {
  transition: all var(--animation-duration);
}

ul .menu-item .menu-addon.right {
  float: right;
  margin-right: 0;
  margin-left: 0.1rem;
}

ul .menu-item.selected .menu-addon {
  color: #fff;
}

ul .menu-item ul {
  border-left: 1px solid #dee2e6;
  margin: 0.75rem;
  padding-left: 0.75rem;
}

ul .divider {
  border-top: 0.1rem solid #e9ecef;
  height: 0.1rem;
  margin: 1rem 0;
}

ul .divider::after {
  content: attr(data-label); /* Text that will be displayed */
  background-color: var(--cirrus-bg);
  color: #adb5bd;
  display: inline-block;
  padding: 0 0.7rem;
  margin: 0.5rem;
  font-size: 0.7rem;
  transform: translateY(-1.1rem);
}

/* Dropdown menu for dropdown buttons */
.list-dropdown {
  display: inline-block;
  position: relative;
  /* Allow for shown and :focus selectors for JS and native CSS transitions */
}

.list-dropdown .menu {
  position: absolute;
  top: 75%;
  left: 0;
  animation: slide-down var(--animation-duration) ease 1;
  background-color: var(--cirrus-bg);
  border-radius: 0.2rem;
  box-shadow: 0 1rem 3rem rgba(149, 157, 165, 0.3);
  margin: 0;
  opacity: 0;
  min-width: 15rem;
  padding: 0.25rem 0.5rem;
  transform: translateY(0.5rem);
  z-index: 10;
  pointer-events: none; /* Remove pointer events to prevent mouse hover to show menu even though it is not visible */
  overflow: hidden;
  transition: all var(--animation-duration);
}

@media screen and (max-width: 767px) {
  .list-dropdown .menu {
    height: 0;
    padding: 0 !important;
    position: relative;
  }
}
.list-dropdown.dropdown-right .menu {
  left: auto;
  right: 0;
}

.list-dropdown.shown .menu,
.list-dropdown .btn-dropdown:focus + .menu,
.list-dropdown .menu:hover {
  display: block;
  opacity: 1;
  top: 100%;
  z-index: 100;
  pointer-events: auto; /* Restore pointer events */
  height: auto;
  transition: all var(--animation-duration);
}

.list-dropdown .btn-group .btn-dropdown:nth-last-child(2) {
  border-bottom-right-radius: 3px;
  border-top-right-radius: 3px;
}

/* TREE */
/* Tree Navigation Menu */
.tree {
  margin: 0;
}

.tree .tree-item {
  /* The title of the tree menu */
  /* The dropdown glyph of the tree menu */
  /* Expand the tree-item-body (has menu-items) */
  /* Rotate the dropdown glyph */
  /* Container for the menu-items of the tree menu */
}

.tree .tree-item .tree-item-header {
  display: block;
  padding: 0.25rem 0.5rem;
  cursor: pointer;
  font-weight: 700; /* Keep styling consistent with menu-items */
}

.tree .tree-item .tree-item-header .icon {
  transition: all var(--animation-duration);
}

.tree .tree-item input:checked ~ .tree-item-body {
  max-height: 100vh;
}

.tree .tree-item input:checked ~ .tree-item-header .icon {
  transform: rotate(90deg);
}

.tree .tree-item .tree-item-body {
  max-height: 0; /* Hidden at first */
  margin-left: 1.5rem;
  overflow: hidden;
  transition: all var(--animation-duration);
}

/* The body that will encompass the tree-nav and tree-nav-content */
.tree-nav-body {
  display: flex;
  height: 100vh;
  flex-wrap: nowrap;
  /* A fixed menu on the side with a tree component */
  /* The container for the tree component to prevent it from overflowing */
  /* Darkened area that will close the navbar when clicked for mobile only */
  /* Push document body further right to account for sidebar space */
  /* The body where all other HTML components are entered if a tree-nav is used */
}

.tree-nav-body .tree-nav {
  flex-grow: 0;
  flex-shrink: 1;
  padding: 2rem 1rem 2rem 2rem;
  min-width: 15rem;
  height: 100vh;
  overflow: auto;
}

.tree-nav-body .tree-nav-container {
  overflow-y: auto;
  top: 4rem;
  bottom: 1rem;
}

.tree-nav-body + .tree-nav-close {
  display: none; /* Not needed in desktop */
}

.tree-nav-body + .tree-nav-content {
  max-width: 100%;
  padding: 2rem;
  flex: 1 0 auto;
  overflow: auto;
  margin: 0;
}

.tree-nav-body .tree-nav-content {
  width: 100%;
  overflow: auto;
  margin: 0;
  padding: 2rem;
}

@media screen and (max-width: 767px) {
  /* Hide the menu to the side by default */
  .tree-nav {
    height: 100%;
    left: 0;
    overflow-y: auto;
    padding: 3rem 1.5rem;
    position: fixed;
    top: 0;
    transform: translateX(-100%);
    transition: transform var(--animation-duration) ease;
    z-index: 400;
    /* Open the menu from the side */
    /* Use a link and add the id of this element as the url */
    /* Create the area to click to close the menu */
    /* Reset max-width in mobile */
  }
  .tree-nav:target {
    transform: translateX(0);
    transition: transform var(--animation-duration) ease;
    /* Show the darkened area when the tree nav menu is clicked */
  }
  .tree-nav:target + .tree-nav-close {
    display: block;
    background-color: rgba(0, 0, 0, 0.15);
    height: 100%;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 300;
  }
  .tree-nav .tree-nav-close {
    background-color: rgba(0, 0, 0, 0.15);
    display: none;
    height: 100%;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 300;
  }
  .tree-nav + .tree-nav-body {
    max-width: inherit;
  }
  /* Header bar for mobile websites with the tree-nav */
  .tree-nav-header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: rgba(248, 249, 250, 0.8);
    height: 3.5rem;
    padding: 0.75rem 0.5rem;
    text-align: center;
    z-index: 300;
  }
  /* For dropdown menu used in header (different from header dropdown) */
  .nav-item.has-sub .list-dropdown {
    width: 100%;
  }
  .nav-item.has-sub .list-dropdown .btn-group {
    width: 100%;
  }
  .nav-item.has-sub .list-dropdown .btn-group .btn-dropdown {
    flex-grow: 0;
  }
  /* Show the dropdown menu in mobile hamburger menu relative with the header menu */
  .list-dropdown .btn-dropdown:focus + .menu {
    position: relative;
    width: 100%;
  }
}
.progress {
  --progress-color: #f03d4d;
  background-color: #e9ecef;
  border: none;
  border-radius: 0.25rem;
  overflow: hidden;
  padding: 0;
  width: 100%;
  height: 0.75rem;
}

.progress::-webkit-progress-bar {
  background-color: transparent;
}

.progress::-webkit-progress-value {
  background-color: var(--progress-color);
}

.progress::-moz-progress-bar {
  background-color: var(--progress-color);
}

.progress::-ms-fill {
  background-color: var(--progress-color);
  border: none;
}

.progress.progress--xs {
  height: 0.25rem;
}

.progress.progress--sm {
  height: 0.5rem;
}

.progress.progress--md {
  height: 0.75rem;
}

.progress.progress--lg {
  height: 1rem;
}

.progress.progress--xl {
  height: 1.25rem;
}

.progress.progress--primary {
  --progress-color: #f03d4d;
}

.progress.progress--dark {
  --progress-color: #363636;
}

.progress.progress--link {
  --progress-color: #5e5cc7;
}

.progress.progress--info {
  --progress-color: #2972fa;
}

.progress.progress--success {
  --progress-color: #0dd157;
}

.progress.progress--warning {
  --progress-color: #fab633;
}

.progress.progress--danger {
  --progress-color: #fb4143;
}

.progress:indeterminate {
  animation: progress-indeterminate 1.5s linear infinite;
  background: #e9ecef linear-gradient(to right, var(--progress-color) 30%, #e9ecef 30%) top left/150% 150% no-repeat;
}

.progress:indeterminate::-webkit-progress-bar {
  --progress-color: transparent;
}

.progress:indeterminate::-moz-progress-bar {
  --progress-color: transparent;
}

.progress:indeterminate::-ms-fill {
  animation-name: none;
}

@keyframes progress-indeterminate {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}
/* TABLE */
.table-container {
  overflow: auto;
  overflow-y: hidden;
  max-width: 100%;
}

.table {
  margin-bottom: 1.5rem;
  width: 100%;
  border-collapse: collapse; /* Allows us to set 0 margin in table cells */
  border-spacing: 0;
  text-align: center; /* Text center by default */
  /* Thicker border for table header */
  /* CUSTOM STYLES */
  /* All borders */
  /* Striped table */
  /* Decrease padding */
  /* Fixed title table */
  /* Borderless Table (for inner cells) */
}

.table td,
.table th {
  border: 1px solid rgba(222, 226, 230, 0.5);
  border-width: 0 0 1px;
  padding: 0.75rem;
  vertical-align: top;
  text-align: inherit;
  margin: 0;
}

.table tr {
  transition: all 0.3s;
}

.table tr.selected {
  /* Style a selected row */
  background-color: #f03d4d;
  color: #fff;
}

.table .table caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #dee2e6;
  text-align: left;
  caption-side: bottom;
}

.table tr:not(.selected):hover, .table.striped tbody tr:not(.selected):nth-child(even):hover {
  background-color: rgba(222, 226, 230, 0.15);
}

.table thead th,
.table thead {
  border-bottom: 2px solid rgba(222, 226, 230, 0.5);
}

.table thead th,
.table tfoot th {
  padding: 1rem;
}

.table tfoot th {
  border-top: 2px solid rgba(222, 226, 230, 0.5);
  border-bottom: none;
}

.table.bordered thead th,
.table.bordered thead {
  border-bottom: 1px solid rgba(222, 226, 230, 0.5);
}

.table.bordered td,
.table.bordered th {
  border: 1px solid rgba(219, 219, 219, 0.5);
}

.table.bordered thead td,
.table.bordered thead {
  border-width: 1px;
}

.table.striped tbody tr:nth-child(even) {
  background-color: rgba(0, 0, 0, 0.05);
}

.table.small td, .table.small th {
  padding: 0.25rem 0.75rem;
}

.table.fixed-head thead {
  position: relative;
  display: block;
}

.table.fixed-head tbody {
  min-height: 200px; /* Set the height you want */
  display: block; /* Needed */
  overflow: auto; /* Shows scrollbars */
}

.table.fixed-head tr {
  display: table;
  width: 100%; /* Forces row to span container */
}

.table.borderless thead th, .table.borderless th, .table.borderless td {
  border: none;
}

.u-top-auto {
  top: auto !important;
}

.u-top-0 {
  top: 0rem !important;
}

.u-top-25p {
  top: 25% !important;
}

.u-top-n25p {
  top: -25% !important;
}

.u-top-50p {
  top: 50% !important;
}

.u-top-n50p {
  top: -50% !important;
}

.u-top-75p {
  top: 75% !important;
}

.u-top-n75p {
  top: -75% !important;
}

.u-top-100p {
  top: 100% !important;
}

.u-top-n100p {
  top: -100% !important;
}

.u-top-1 {
  top: 0.5rem !important;
}

.u-top-n1 {
  top: -0.5rem !important;
}

.u-top-2 {
  top: 1rem !important;
}

.u-top-n2 {
  top: -1rem !important;
}

.u-top-3 {
  top: 1.5rem !important;
}

.u-top-n3 {
  top: -1.5rem !important;
}

.u-top-4 {
  top: 2rem !important;
}

.u-top-n4 {
  top: -2rem !important;
}

.u-top-5 {
  top: 2.5rem !important;
}

.u-top-n5 {
  top: -2.5rem !important;
}

.u-top-6 {
  top: 3rem !important;
}

.u-top-n6 {
  top: -3rem !important;
}

.u-top-8 {
  top: 4rem !important;
}

.u-top-n8 {
  top: -4rem !important;
}

.u-left-auto {
  left: auto !important;
}

.u-left-0 {
  left: 0rem !important;
}

.u-left-25p {
  left: 25% !important;
}

.u-left-n25p {
  left: -25% !important;
}

.u-left-50p {
  left: 50% !important;
}

.u-left-n50p {
  left: -50% !important;
}

.u-left-75p {
  left: 75% !important;
}

.u-left-n75p {
  left: -75% !important;
}

.u-left-100p {
  left: 100% !important;
}

.u-left-n100p {
  left: -100% !important;
}

.u-left-1 {
  left: 0.5rem !important;
}

.u-left-n1 {
  left: -0.5rem !important;
}

.u-left-2 {
  left: 1rem !important;
}

.u-left-n2 {
  left: -1rem !important;
}

.u-left-3 {
  left: 1.5rem !important;
}

.u-left-n3 {
  left: -1.5rem !important;
}

.u-left-4 {
  left: 2rem !important;
}

.u-left-n4 {
  left: -2rem !important;
}

.u-left-5 {
  left: 2.5rem !important;
}

.u-left-n5 {
  left: -2.5rem !important;
}

.u-left-6 {
  left: 3rem !important;
}

.u-left-n6 {
  left: -3rem !important;
}

.u-left-8 {
  left: 4rem !important;
}

.u-left-n8 {
  left: -4rem !important;
}

.u-right-auto {
  right: auto !important;
}

.u-right-0 {
  right: 0rem !important;
}

.u-right-25p {
  right: 25% !important;
}

.u-right-n25p {
  right: -25% !important;
}

.u-right-50p {
  right: 50% !important;
}

.u-right-n50p {
  right: -50% !important;
}

.u-right-75p {
  right: 75% !important;
}

.u-right-n75p {
  right: -75% !important;
}

.u-right-100p {
  right: 100% !important;
}

.u-right-n100p {
  right: -100% !important;
}

.u-right-1 {
  right: 0.5rem !important;
}

.u-right-n1 {
  right: -0.5rem !important;
}

.u-right-2 {
  right: 1rem !important;
}

.u-right-n2 {
  right: -1rem !important;
}

.u-right-3 {
  right: 1.5rem !important;
}

.u-right-n3 {
  right: -1.5rem !important;
}

.u-right-4 {
  right: 2rem !important;
}

.u-right-n4 {
  right: -2rem !important;
}

.u-right-5 {
  right: 2.5rem !important;
}

.u-right-n5 {
  right: -2.5rem !important;
}

.u-right-6 {
  right: 3rem !important;
}

.u-right-n6 {
  right: -3rem !important;
}

.u-right-8 {
  right: 4rem !important;
}

.u-right-n8 {
  right: -4rem !important;
}

.u-bottom-auto {
  bottom: auto !important;
}

.u-bottom-0 {
  bottom: 0rem !important;
}

.u-bottom-25p {
  bottom: 25% !important;
}

.u-bottom-n25p {
  bottom: -25% !important;
}

.u-bottom-50p {
  bottom: 50% !important;
}

.u-bottom-n50p {
  bottom: -50% !important;
}

.u-bottom-75p {
  bottom: 75% !important;
}

.u-bottom-n75p {
  bottom: -75% !important;
}

.u-bottom-100p {
  bottom: 100% !important;
}

.u-bottom-n100p {
  bottom: -100% !important;
}

.u-bottom-1 {
  bottom: 0.5rem !important;
}

.u-bottom-n1 {
  bottom: -0.5rem !important;
}

.u-bottom-2 {
  bottom: 1rem !important;
}

.u-bottom-n2 {
  bottom: -1rem !important;
}

.u-bottom-3 {
  bottom: 1.5rem !important;
}

.u-bottom-n3 {
  bottom: -1.5rem !important;
}

.u-bottom-4 {
  bottom: 2rem !important;
}

.u-bottom-n4 {
  bottom: -2rem !important;
}

.u-bottom-5 {
  bottom: 2.5rem !important;
}

.u-bottom-n5 {
  bottom: -2.5rem !important;
}

.u-bottom-6 {
  bottom: 3rem !important;
}

.u-bottom-n6 {
  bottom: -3rem !important;
}

.u-bottom-8 {
  bottom: 4rem !important;
}

.u-bottom-n8 {
  bottom: -4rem !important;
}

.u-blur-none {
  --cirrus-blur: blur(0);
  filter: var(--cirrus-blur);
}

.u-blur-xs {
  --cirrus-blur: blur(0.25rem);
  filter: var(--cirrus-blur);
}

.u-blur-sm {
  --cirrus-blur: blur(0.5rem);
  filter: var(--cirrus-blur);
}

.u-blur-md {
  --cirrus-blur: blur(1rem);
  filter: var(--cirrus-blur);
}

.u-blur-lg {
  --cirrus-blur: blur(1.5rem);
  filter: var(--cirrus-blur);
}

.u-blur-xl {
  --cirrus-blur: blur(3rem);
  filter: var(--cirrus-blur);
}

.u-border-0 {
  border-width: 0px;
}

.u-border-1 {
  border-width: 1px;
}

.u-border-2 {
  border-width: 2px;
}

.u-border-4 {
  border-width: 4px;
}

.u-border-8 {
  border-width: 8px;
}

.u-border-opacity-0 {
  --border-opacity: 0;
}

.u-border-opacity-10 {
  --border-opacity: 0.1;
}

.u-border-opacity-20 {
  --border-opacity: 0.2;
}

.u-border-opacity-30 {
  --border-opacity: 0.3;
}

.u-border-opacity-40 {
  --border-opacity: 0.4;
}

.u-border-opacity-50 {
  --border-opacity: 0.5;
}

.u-border-opacity-60 {
  --border-opacity: 0.6;
}

.u-border-opacity-70 {
  --border-opacity: 0.7;
}

.u-border-opacity-80 {
  --border-opacity: 0.8;
}

.u-border-opacity-90 {
  --border-opacity: 0.9;
}

.u-border-opacity-100 {
  --border-opacity: 1;
}

.u-round-none {
  border-radius: 0px;
}

.u-round-xs {
  border-radius: 0.25rem;
}

.u-round-sm {
  border-radius: 0.5rem;
}

.u-round-md {
  border-radius: 0.75rem;
}

.u-round-lg {
  border-radius: 1rem;
}

.u-round-xl {
  border-radius: 1.25rem;
}

.u-round-full {
  border-radius: 9001px;
}

.u-clear-left {
  clear: left !important;
}

.u-clear-right {
  clear: right !important;
}

.u-clear-both {
  clear: both !important;
}

@media screen and (min-width: 640px) {
  .u-clear-left-sm {
    clear: left !important;
  }
}
@media screen and (min-width: 640px) {
  .u-clear-right-sm {
    clear: right !important;
  }
}
@media screen and (min-width: 640px) {
  .u-clear-both-sm {
    clear: both !important;
  }
}
@media screen and (min-width: 768px) {
  .u-clear-left-md {
    clear: left !important;
  }
}
@media screen and (min-width: 768px) {
  .u-clear-right-md {
    clear: right !important;
  }
}
@media screen and (min-width: 768px) {
  .u-clear-both-md {
    clear: both !important;
  }
}
@media screen and (min-width: 1024px) {
  .u-clear-left-lg {
    clear: left !important;
  }
}
@media screen and (min-width: 1024px) {
  .u-clear-right-lg {
    clear: right !important;
  }
}
@media screen and (min-width: 1024px) {
  .u-clear-both-lg {
    clear: both !important;
  }
}
@media screen and (min-width: 1280px) {
  .u-clear-left-xl {
    clear: left !important;
  }
}
@media screen and (min-width: 1280px) {
  .u-clear-right-xl {
    clear: right !important;
  }
}
@media screen and (min-width: 1280px) {
  .u-clear-both-xl {
    clear: both !important;
  }
}
/*
   When using floats, clearfix allows the container to automatically resize so that
   other elements are not blocked by children.
*/
.u-clearfix:after {
  clear: both !important;
  content: " ";
  display: table !important;
}

.u-none {
  display: none !important;
}

.u-inline {
  display: inline !important;
}

.u-inline-block {
  display: inline-block !important;
}

.u-block {
  display: block !important;
}

.u-flex {
  display: flex !important;
}

.u-inline-flex {
  display: inline-flex !important;
}

.u-table {
  display: table !important;
}

.u-table-row {
  display: table-row !important;
}

.u-table-column {
  display: table-column !important;
}

.u-table-cell {
  display: table-cell !important;
}

@media screen and (min-width: 640px) {
  .u-none-sm {
    display: none !important;
  }
}
@media screen and (min-width: 640px) {
  .u-inline-sm {
    display: inline !important;
  }
}
@media screen and (min-width: 640px) {
  .u-inline-block-sm {
    display: inline-block !important;
  }
}
@media screen and (min-width: 640px) {
  .u-block-sm {
    display: block !important;
  }
}
@media screen and (min-width: 640px) {
  .u-flex-sm {
    display: flex !important;
  }
}
@media screen and (min-width: 640px) {
  .u-inline-flex-sm {
    display: inline-flex !important;
  }
}
@media screen and (min-width: 640px) {
  .u-table-sm {
    display: table !important;
  }
}
@media screen and (min-width: 640px) {
  .u-table-row-sm {
    display: table-row !important;
  }
}
@media screen and (min-width: 640px) {
  .u-table-column-sm {
    display: table-column !important;
  }
}
@media screen and (min-width: 640px) {
  .u-table-cell-sm {
    display: table-cell !important;
  }
}
@media screen and (min-width: 768px) {
  .u-none-md {
    display: none !important;
  }
}
@media screen and (min-width: 768px) {
  .u-inline-md {
    display: inline !important;
  }
}
@media screen and (min-width: 768px) {
  .u-inline-block-md {
    display: inline-block !important;
  }
}
@media screen and (min-width: 768px) {
  .u-block-md {
    display: block !important;
  }
}
@media screen and (min-width: 768px) {
  .u-flex-md {
    display: flex !important;
  }
}
@media screen and (min-width: 768px) {
  .u-inline-flex-md {
    display: inline-flex !important;
  }
}
@media screen and (min-width: 768px) {
  .u-table-md {
    display: table !important;
  }
}
@media screen and (min-width: 768px) {
  .u-table-row-md {
    display: table-row !important;
  }
}
@media screen and (min-width: 768px) {
  .u-table-column-md {
    display: table-column !important;
  }
}
@media screen and (min-width: 768px) {
  .u-table-cell-md {
    display: table-cell !important;
  }
}
@media screen and (min-width: 1024px) {
  .u-none-lg {
    display: none !important;
  }
}
@media screen and (min-width: 1024px) {
  .u-inline-lg {
    display: inline !important;
  }
}
@media screen and (min-width: 1024px) {
  .u-inline-block-lg {
    display: inline-block !important;
  }
}
@media screen and (min-width: 1024px) {
  .u-block-lg {
    display: block !important;
  }
}
@media screen and (min-width: 1024px) {
  .u-flex-lg {
    display: flex !important;
  }
}
@media screen and (min-width: 1024px) {
  .u-inline-flex-lg {
    display: inline-flex !important;
  }
}
@media screen and (min-width: 1024px) {
  .u-table-lg {
    display: table !important;
  }
}
@media screen and (min-width: 1024px) {
  .u-table-row-lg {
    display: table-row !important;
  }
}
@media screen and (min-width: 1024px) {
  .u-table-column-lg {
    display: table-column !important;
  }
}
@media screen and (min-width: 1024px) {
  .u-table-cell-lg {
    display: table-cell !important;
  }
}
@media screen and (min-width: 1280px) {
  .u-none-xl {
    display: none !important;
  }
}
@media screen and (min-width: 1280px) {
  .u-inline-xl {
    display: inline !important;
  }
}
@media screen and (min-width: 1280px) {
  .u-inline-block-xl {
    display: inline-block !important;
  }
}
@media screen and (min-width: 1280px) {
  .u-block-xl {
    display: block !important;
  }
}
@media screen and (min-width: 1280px) {
  .u-flex-xl {
    display: flex !important;
  }
}
@media screen and (min-width: 1280px) {
  .u-inline-flex-xl {
    display: inline-flex !important;
  }
}
@media screen and (min-width: 1280px) {
  .u-table-xl {
    display: table !important;
  }
}
@media screen and (min-width: 1280px) {
  .u-table-row-xl {
    display: table-row !important;
  }
}
@media screen and (min-width: 1280px) {
  .u-table-column-xl {
    display: table-column !important;
  }
}
@media screen and (min-width: 1280px) {
  .u-table-cell-xl {
    display: table-cell !important;
  }
}
.u-flex-row {
  flex-direction: row !important;
}

.u-flex-row-reverse {
  flex-direction: row-reverse !important;
}

.u-flex-column {
  flex-direction: column !important;
}

.u-flex-column-reverse {
  flex-direction: column-reverse !important;
}

@media screen and (min-width: 640px) {
  .u-flex-row-sm {
    flex-direction: row !important;
  }
}
@media screen and (min-width: 640px) {
  .u-flex-row-reverse-sm {
    flex-direction: row-reverse !important;
  }
}
@media screen and (min-width: 640px) {
  .u-flex-column-sm {
    flex-direction: column !important;
  }
}
@media screen and (min-width: 640px) {
  .u-flex-column-reverse-sm {
    flex-direction: column-reverse !important;
  }
}
@media screen and (min-width: 768px) {
  .u-flex-row-md {
    flex-direction: row !important;
  }
}
@media screen and (min-width: 768px) {
  .u-flex-row-reverse-md {
    flex-direction: row-reverse !important;
  }
}
@media screen and (min-width: 768px) {
  .u-flex-column-md {
    flex-direction: column !important;
  }
}
@media screen and (min-width: 768px) {
  .u-flex-column-reverse-md {
    flex-direction: column-reverse !important;
  }
}
@media screen and (min-width: 1024px) {
  .u-flex-row-lg {
    flex-direction: row !important;
  }
}
@media screen and (min-width: 1024px) {
  .u-flex-row-reverse-lg {
    flex-direction: row-reverse !important;
  }
}
@media screen and (min-width: 1024px) {
  .u-flex-column-lg {
    flex-direction: column !important;
  }
}
@media screen and (min-width: 1024px) {
  .u-flex-column-reverse-lg {
    flex-direction: column-reverse !important;
  }
}
@media screen and (min-width: 1280px) {
  .u-flex-row-xl {
    flex-direction: row !important;
  }
}
@media screen and (min-width: 1280px) {
  .u-flex-row-reverse-xl {
    flex-direction: row-reverse !important;
  }
}
@media screen and (min-width: 1280px) {
  .u-flex-column-xl {
    flex-direction: column !important;
  }
}
@media screen and (min-width: 1280px) {
  .u-flex-column-reverse-xl {
    flex-direction: column-reverse !important;
  }
}
.u-justify-flex-start {
  justify-content: flex-start !important;
}

.u-justify-center {
  justify-content: center !important;
}

.u-justify-flex-end {
  justify-content: flex-end !important;
}

.u-justify-space-between {
  justify-content: space-between !important;
}

.u-justify-space-around {
  justify-content: space-around !important;
}

.u-justify-space-evenly {
  justify-content: space-evenly !important;
}

@media screen and (min-width: 640px) {
  .u-justify-flex-start-sm {
    justify-content: flex-start !important;
  }
}
@media screen and (min-width: 640px) {
  .u-justify-center-sm {
    justify-content: center !important;
  }
}
@media screen and (min-width: 640px) {
  .u-justify-flex-end-sm {
    justify-content: flex-end !important;
  }
}
@media screen and (min-width: 640px) {
  .u-justify-space-between-sm {
    justify-content: space-between !important;
  }
}
@media screen and (min-width: 640px) {
  .u-justify-space-around-sm {
    justify-content: space-around !important;
  }
}
@media screen and (min-width: 640px) {
  .u-justify-space-evenly-sm {
    justify-content: space-evenly !important;
  }
}
@media screen and (min-width: 768px) {
  .u-justify-flex-start-md {
    justify-content: flex-start !important;
  }
}
@media screen and (min-width: 768px) {
  .u-justify-center-md {
    justify-content: center !important;
  }
}
@media screen and (min-width: 768px) {
  .u-justify-flex-end-md {
    justify-content: flex-end !important;
  }
}
@media screen and (min-width: 768px) {
  .u-justify-space-between-md {
    justify-content: space-between !important;
  }
}
@media screen and (min-width: 768px) {
  .u-justify-space-around-md {
    justify-content: space-around !important;
  }
}
@media screen and (min-width: 768px) {
  .u-justify-space-evenly-md {
    justify-content: space-evenly !important;
  }
}
@media screen and (min-width: 1024px) {
  .u-justify-flex-start-lg {
    justify-content: flex-start !important;
  }
}
@media screen and (min-width: 1024px) {
  .u-justify-center-lg {
    justify-content: center !important;
  }
}
@media screen and (min-width: 1024px) {
  .u-justify-flex-end-lg {
    justify-content: flex-end !important;
  }
}
@media screen and (min-width: 1024px) {
  .u-justify-space-between-lg {
    justify-content: space-between !important;
  }
}
@media screen and (min-width: 1024px) {
  .u-justify-space-around-lg {
    justify-content: space-around !important;
  }
}
@media screen and (min-width: 1024px) {
  .u-justify-space-evenly-lg {
    justify-content: space-evenly !important;
  }
}
@media screen and (min-width: 1280px) {
  .u-justify-flex-start-xl {
    justify-content: flex-start !important;
  }
}
@media screen and (min-width: 1280px) {
  .u-justify-center-xl {
    justify-content: center !important;
  }
}
@media screen and (min-width: 1280px) {
  .u-justify-flex-end-xl {
    justify-content: flex-end !important;
  }
}
@media screen and (min-width: 1280px) {
  .u-justify-space-between-xl {
    justify-content: space-between !important;
  }
}
@media screen and (min-width: 1280px) {
  .u-justify-space-around-xl {
    justify-content: space-around !important;
  }
}
@media screen and (min-width: 1280px) {
  .u-justify-space-evenly-xl {
    justify-content: space-evenly !important;
  }
}
.u-items-stretch {
  align-items: stretch !important;
}

.u-items-flex-start {
  align-items: flex-start !important;
}

.u-items-center {
  align-items: center !important;
}

.u-items-flex-end {
  align-items: flex-end !important;
}

.u-items-baseline {
  align-items: baseline !important;
}

@media screen and (min-width: 640px) {
  .u-items-stretch-sm {
    align-items: stretch !important;
  }
}
@media screen and (min-width: 640px) {
  .u-items-flex-start-sm {
    align-items: flex-start !important;
  }
}
@media screen and (min-width: 640px) {
  .u-items-center-sm {
    align-items: center !important;
  }
}
@media screen and (min-width: 640px) {
  .u-items-flex-end-sm {
    align-items: flex-end !important;
  }
}
@media screen and (min-width: 640px) {
  .u-items-baseline-sm {
    align-items: baseline !important;
  }
}
@media screen and (min-width: 768px) {
  .u-items-stretch-md {
    align-items: stretch !important;
  }
}
@media screen and (min-width: 768px) {
  .u-items-flex-start-md {
    align-items: flex-start !important;
  }
}
@media screen and (min-width: 768px) {
  .u-items-center-md {
    align-items: center !important;
  }
}
@media screen and (min-width: 768px) {
  .u-items-flex-end-md {
    align-items: flex-end !important;
  }
}
@media screen and (min-width: 768px) {
  .u-items-baseline-md {
    align-items: baseline !important;
  }
}
@media screen and (min-width: 1024px) {
  .u-items-stretch-lg {
    align-items: stretch !important;
  }
}
@media screen and (min-width: 1024px) {
  .u-items-flex-start-lg {
    align-items: flex-start !important;
  }
}
@media screen and (min-width: 1024px) {
  .u-items-center-lg {
    align-items: center !important;
  }
}
@media screen and (min-width: 1024px) {
  .u-items-flex-end-lg {
    align-items: flex-end !important;
  }
}
@media screen and (min-width: 1024px) {
  .u-items-baseline-lg {
    align-items: baseline !important;
  }
}
@media screen and (min-width: 1280px) {
  .u-items-stretch-xl {
    align-items: stretch !important;
  }
}
@media screen and (min-width: 1280px) {
  .u-items-flex-start-xl {
    align-items: flex-start !important;
  }
}
@media screen and (min-width: 1280px) {
  .u-items-center-xl {
    align-items: center !important;
  }
}
@media screen and (min-width: 1280px) {
  .u-items-flex-end-xl {
    align-items: flex-end !important;
  }
}
@media screen and (min-width: 1280px) {
  .u-items-baseline-xl {
    align-items: baseline !important;
  }
}
.u-flex-grow-0 {
  flex-grow: 0 !important;
}

.u-flex-grow-1 {
  flex-grow: 1 !important;
}

@media screen and (min-width: 640px) {
  .u-flex-grow-0-sm {
    flex-grow: 0 !important;
  }
}
@media screen and (min-width: 640px) {
  .u-flex-grow-1-sm {
    flex-grow: 1 !important;
  }
}
@media screen and (min-width: 768px) {
  .u-flex-grow-0-md {
    flex-grow: 0 !important;
  }
}
@media screen and (min-width: 768px) {
  .u-flex-grow-1-md {
    flex-grow: 1 !important;
  }
}
@media screen and (min-width: 1024px) {
  .u-flex-grow-0-lg {
    flex-grow: 0 !important;
  }
}
@media screen and (min-width: 1024px) {
  .u-flex-grow-1-lg {
    flex-grow: 1 !important;
  }
}
@media screen and (min-width: 1280px) {
  .u-flex-grow-0-xl {
    flex-grow: 0 !important;
  }
}
@media screen and (min-width: 1280px) {
  .u-flex-grow-1-xl {
    flex-grow: 1 !important;
  }
}
.u-flex-shrink-0 {
  flex-shrink: 0 !important;
}

.u-flex-shrink-1 {
  flex-shrink: 1 !important;
}

@media screen and (min-width: 640px) {
  .u-flex-shrink-0-sm {
    flex-shrink: 0 !important;
  }
}
@media screen and (min-width: 640px) {
  .u-flex-shrink-1-sm {
    flex-shrink: 1 !important;
  }
}
@media screen and (min-width: 768px) {
  .u-flex-shrink-0-md {
    flex-shrink: 0 !important;
  }
}
@media screen and (min-width: 768px) {
  .u-flex-shrink-1-md {
    flex-shrink: 1 !important;
  }
}
@media screen and (min-width: 1024px) {
  .u-flex-shrink-0-lg {
    flex-shrink: 0 !important;
  }
}
@media screen and (min-width: 1024px) {
  .u-flex-shrink-1-lg {
    flex-shrink: 1 !important;
  }
}
@media screen and (min-width: 1280px) {
  .u-flex-shrink-0-xl {
    flex-shrink: 0 !important;
  }
}
@media screen and (min-width: 1280px) {
  .u-flex-shrink-1-xl {
    flex-shrink: 1 !important;
  }
}
.u-flex-wrap {
  flex-wrap: wrap !important;
}

.u-flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.u-flex-nowrap {
  flex-wrap: nowrap !important;
}

@media screen and (min-width: 640px) {
  .u-flex-wrap-sm {
    flex-wrap: wrap !important;
  }
}
@media screen and (min-width: 640px) {
  .u-flex-wrap-reverse-sm {
    flex-wrap: wrap-reverse !important;
  }
}
@media screen and (min-width: 640px) {
  .u-flex-nowrap-sm {
    flex-wrap: nowrap !important;
  }
}
@media screen and (min-width: 768px) {
  .u-flex-wrap-md {
    flex-wrap: wrap !important;
  }
}
@media screen and (min-width: 768px) {
  .u-flex-wrap-reverse-md {
    flex-wrap: wrap-reverse !important;
  }
}
@media screen and (min-width: 768px) {
  .u-flex-nowrap-md {
    flex-wrap: nowrap !important;
  }
}
@media screen and (min-width: 1024px) {
  .u-flex-wrap-lg {
    flex-wrap: wrap !important;
  }
}
@media screen and (min-width: 1024px) {
  .u-flex-wrap-reverse-lg {
    flex-wrap: wrap-reverse !important;
  }
}
@media screen and (min-width: 1024px) {
  .u-flex-nowrap-lg {
    flex-wrap: nowrap !important;
  }
}
@media screen and (min-width: 1280px) {
  .u-flex-wrap-xl {
    flex-wrap: wrap !important;
  }
}
@media screen and (min-width: 1280px) {
  .u-flex-wrap-reverse-xl {
    flex-wrap: wrap-reverse !important;
  }
}
@media screen and (min-width: 1280px) {
  .u-flex-nowrap-xl {
    flex-wrap: nowrap !important;
  }
}
.u-basis-auto {
  flex-basis: auto !important;
}

.u-basis-max-content {
  flex-basis: max-content !important;
}

.u-basis-min-content {
  flex-basis: min-content !important;
}

.u-basis-fit-content {
  flex-basis: fit-content !important;
}

.u-basis-content {
  flex-basis: content !important;
}

.u-basis-revert {
  flex-basis: revert !important;
}

.u-basis-revert-layer {
  flex-basis: revert-layer !important;
}

.u-basis-10p {
  flex-basis: 10% !important;
}

.u-basis-20p {
  flex-basis: 20% !important;
}

.u-basis-30p {
  flex-basis: 30% !important;
}

.u-basis-40p {
  flex-basis: 40% !important;
}

.u-basis-50p {
  flex-basis: 50% !important;
}

.u-basis-60p {
  flex-basis: 60% !important;
}

.u-basis-70p {
  flex-basis: 70% !important;
}

.u-basis-80p {
  flex-basis: 80% !important;
}

.u-basis-90p {
  flex-basis: 90% !important;
}

.u-basis-100p {
  flex-basis: 100% !important;
}

@media screen and (min-width: 640px) {
  .u-basis-auto-sm {
    flex-basis: auto !important;
  }
}
@media screen and (min-width: 640px) {
  .u-basis-max-content-sm {
    flex-basis: max-content !important;
  }
}
@media screen and (min-width: 640px) {
  .u-basis-min-content-sm {
    flex-basis: min-content !important;
  }
}
@media screen and (min-width: 640px) {
  .u-basis-fit-content-sm {
    flex-basis: fit-content !important;
  }
}
@media screen and (min-width: 640px) {
  .u-basis-content-sm {
    flex-basis: content !important;
  }
}
@media screen and (min-width: 640px) {
  .u-basis-revert-sm {
    flex-basis: revert !important;
  }
}
@media screen and (min-width: 640px) {
  .u-basis-revert-layer-sm {
    flex-basis: revert-layer !important;
  }
}
@media screen and (min-width: 640px) {
  .u-basis-10p-sm {
    flex-basis: 10% !important;
  }
}
@media screen and (min-width: 640px) {
  .u-basis-20p-sm {
    flex-basis: 20% !important;
  }
}
@media screen and (min-width: 640px) {
  .u-basis-30p-sm {
    flex-basis: 30% !important;
  }
}
@media screen and (min-width: 640px) {
  .u-basis-40p-sm {
    flex-basis: 40% !important;
  }
}
@media screen and (min-width: 640px) {
  .u-basis-50p-sm {
    flex-basis: 50% !important;
  }
}
@media screen and (min-width: 640px) {
  .u-basis-60p-sm {
    flex-basis: 60% !important;
  }
}
@media screen and (min-width: 640px) {
  .u-basis-70p-sm {
    flex-basis: 70% !important;
  }
}
@media screen and (min-width: 640px) {
  .u-basis-80p-sm {
    flex-basis: 80% !important;
  }
}
@media screen and (min-width: 640px) {
  .u-basis-90p-sm {
    flex-basis: 90% !important;
  }
}
@media screen and (min-width: 640px) {
  .u-basis-100p-sm {
    flex-basis: 100% !important;
  }
}
@media screen and (min-width: 768px) {
  .u-basis-auto-md {
    flex-basis: auto !important;
  }
}
@media screen and (min-width: 768px) {
  .u-basis-max-content-md {
    flex-basis: max-content !important;
  }
}
@media screen and (min-width: 768px) {
  .u-basis-min-content-md {
    flex-basis: min-content !important;
  }
}
@media screen and (min-width: 768px) {
  .u-basis-fit-content-md {
    flex-basis: fit-content !important;
  }
}
@media screen and (min-width: 768px) {
  .u-basis-content-md {
    flex-basis: content !important;
  }
}
@media screen and (min-width: 768px) {
  .u-basis-revert-md {
    flex-basis: revert !important;
  }
}
@media screen and (min-width: 768px) {
  .u-basis-revert-layer-md {
    flex-basis: revert-layer !important;
  }
}
@media screen and (min-width: 768px) {
  .u-basis-10p-md {
    flex-basis: 10% !important;
  }
}
@media screen and (min-width: 768px) {
  .u-basis-20p-md {
    flex-basis: 20% !important;
  }
}
@media screen and (min-width: 768px) {
  .u-basis-30p-md {
    flex-basis: 30% !important;
  }
}
@media screen and (min-width: 768px) {
  .u-basis-40p-md {
    flex-basis: 40% !important;
  }
}
@media screen and (min-width: 768px) {
  .u-basis-50p-md {
    flex-basis: 50% !important;
  }
}
@media screen and (min-width: 768px) {
  .u-basis-60p-md {
    flex-basis: 60% !important;
  }
}
@media screen and (min-width: 768px) {
  .u-basis-70p-md {
    flex-basis: 70% !important;
  }
}
@media screen and (min-width: 768px) {
  .u-basis-80p-md {
    flex-basis: 80% !important;
  }
}
@media screen and (min-width: 768px) {
  .u-basis-90p-md {
    flex-basis: 90% !important;
  }
}
@media screen and (min-width: 768px) {
  .u-basis-100p-md {
    flex-basis: 100% !important;
  }
}
@media screen and (min-width: 1024px) {
  .u-basis-auto-lg {
    flex-basis: auto !important;
  }
}
@media screen and (min-width: 1024px) {
  .u-basis-max-content-lg {
    flex-basis: max-content !important;
  }
}
@media screen and (min-width: 1024px) {
  .u-basis-min-content-lg {
    flex-basis: min-content !important;
  }
}
@media screen and (min-width: 1024px) {
  .u-basis-fit-content-lg {
    flex-basis: fit-content !important;
  }
}
@media screen and (min-width: 1024px) {
  .u-basis-content-lg {
    flex-basis: content !important;
  }
}
@media screen and (min-width: 1024px) {
  .u-basis-revert-lg {
    flex-basis: revert !important;
  }
}
@media screen and (min-width: 1024px) {
  .u-basis-revert-layer-lg {
    flex-basis: revert-layer !important;
  }
}
@media screen and (min-width: 1024px) {
  .u-basis-10p-lg {
    flex-basis: 10% !important;
  }
}
@media screen and (min-width: 1024px) {
  .u-basis-20p-lg {
    flex-basis: 20% !important;
  }
}
@media screen and (min-width: 1024px) {
  .u-basis-30p-lg {
    flex-basis: 30% !important;
  }
}
@media screen and (min-width: 1024px) {
  .u-basis-40p-lg {
    flex-basis: 40% !important;
  }
}
@media screen and (min-width: 1024px) {
  .u-basis-50p-lg {
    flex-basis: 50% !important;
  }
}
@media screen and (min-width: 1024px) {
  .u-basis-60p-lg {
    flex-basis: 60% !important;
  }
}
@media screen and (min-width: 1024px) {
  .u-basis-70p-lg {
    flex-basis: 70% !important;
  }
}
@media screen and (min-width: 1024px) {
  .u-basis-80p-lg {
    flex-basis: 80% !important;
  }
}
@media screen and (min-width: 1024px) {
  .u-basis-90p-lg {
    flex-basis: 90% !important;
  }
}
@media screen and (min-width: 1024px) {
  .u-basis-100p-lg {
    flex-basis: 100% !important;
  }
}
@media screen and (min-width: 1280px) {
  .u-basis-auto-xl {
    flex-basis: auto !important;
  }
}
@media screen and (min-width: 1280px) {
  .u-basis-max-content-xl {
    flex-basis: max-content !important;
  }
}
@media screen and (min-width: 1280px) {
  .u-basis-min-content-xl {
    flex-basis: min-content !important;
  }
}
@media screen and (min-width: 1280px) {
  .u-basis-fit-content-xl {
    flex-basis: fit-content !important;
  }
}
@media screen and (min-width: 1280px) {
  .u-basis-content-xl {
    flex-basis: content !important;
  }
}
@media screen and (min-width: 1280px) {
  .u-basis-revert-xl {
    flex-basis: revert !important;
  }
}
@media screen and (min-width: 1280px) {
  .u-basis-revert-layer-xl {
    flex-basis: revert-layer !important;
  }
}
@media screen and (min-width: 1280px) {
  .u-basis-10p-xl {
    flex-basis: 10% !important;
  }
}
@media screen and (min-width: 1280px) {
  .u-basis-20p-xl {
    flex-basis: 20% !important;
  }
}
@media screen and (min-width: 1280px) {
  .u-basis-30p-xl {
    flex-basis: 30% !important;
  }
}
@media screen and (min-width: 1280px) {
  .u-basis-40p-xl {
    flex-basis: 40% !important;
  }
}
@media screen and (min-width: 1280px) {
  .u-basis-50p-xl {
    flex-basis: 50% !important;
  }
}
@media screen and (min-width: 1280px) {
  .u-basis-60p-xl {
    flex-basis: 60% !important;
  }
}
@media screen and (min-width: 1280px) {
  .u-basis-70p-xl {
    flex-basis: 70% !important;
  }
}
@media screen and (min-width: 1280px) {
  .u-basis-80p-xl {
    flex-basis: 80% !important;
  }
}
@media screen and (min-width: 1280px) {
  .u-basis-90p-xl {
    flex-basis: 90% !important;
  }
}
@media screen and (min-width: 1280px) {
  .u-basis-100p-xl {
    flex-basis: 100% !important;
  }
}
.u-gap-0 {
  gap: 0rem !important;
}

.u-gap-1 {
  gap: 0.5rem !important;
}

.u-gap-2 {
  gap: 1rem !important;
}

.u-gap-3 {
  gap: 1.5rem !important;
}

.u-gap-4 {
  gap: 2rem !important;
}

.u-gap-5 {
  gap: 2.5rem !important;
}

.u-gap-6 {
  gap: 3rem !important;
}

.u-gap-8 {
  gap: 4rem !important;
}

.u-gap-10 {
  gap: 5rem !important;
}

.u-gap-12 {
  gap: 6rem !important;
}

.u-gap-16 {
  gap: 8rem !important;
}

.u-gap-20 {
  gap: 10rem !important;
}

.u-gap-24 {
  gap: 12rem !important;
}

.u-gap-32 {
  gap: 16rem !important;
}

/* UTILS */
/* Utility classes to help solve some very annoying issues */
.u-pull-left {
  float: left !important;
}

.u-pull-right {
  float: right !important;
}

@media screen and (min-width: 640px) {
  .u-pull-left-sm {
    float: left !important;
  }
}
@media screen and (min-width: 640px) {
  .u-pull-right-sm {
    float: right !important;
  }
}
@media screen and (min-width: 768px) {
  .u-pull-left-md {
    float: left !important;
  }
}
@media screen and (min-width: 768px) {
  .u-pull-right-md {
    float: right !important;
  }
}
@media screen and (min-width: 1024px) {
  .u-pull-left-lg {
    float: left !important;
  }
}
@media screen and (min-width: 1024px) {
  .u-pull-right-lg {
    float: right !important;
  }
}
@media screen and (min-width: 1280px) {
  .u-pull-left-xl {
    float: left !important;
  }
}
@media screen and (min-width: 1280px) {
  .u-pull-right-xl {
    float: right !important;
  }
}
.u-text-justify {
  text-align: justify !important;
}

.u-text-left {
  text-align: left !important;
}

.u-text-right {
  text-align: right !important;
}

.u-text-center {
  text-align: center !important;
}

@media screen and (min-width: 640px) {
  .u-text-justify-sm {
    text-align: justify !important;
  }
}
@media screen and (min-width: 640px) {
  .u-text-left-sm {
    text-align: left !important;
  }
}
@media screen and (min-width: 640px) {
  .u-text-right-sm {
    text-align: right !important;
  }
}
@media screen and (min-width: 640px) {
  .u-text-center-sm {
    text-align: center !important;
  }
}
@media screen and (min-width: 768px) {
  .u-text-justify-md {
    text-align: justify !important;
  }
}
@media screen and (min-width: 768px) {
  .u-text-left-md {
    text-align: left !important;
  }
}
@media screen and (min-width: 768px) {
  .u-text-right-md {
    text-align: right !important;
  }
}
@media screen and (min-width: 768px) {
  .u-text-center-md {
    text-align: center !important;
  }
}
@media screen and (min-width: 1024px) {
  .u-text-justify-lg {
    text-align: justify !important;
  }
}
@media screen and (min-width: 1024px) {
  .u-text-left-lg {
    text-align: left !important;
  }
}
@media screen and (min-width: 1024px) {
  .u-text-right-lg {
    text-align: right !important;
  }
}
@media screen and (min-width: 1024px) {
  .u-text-center-lg {
    text-align: center !important;
  }
}
@media screen and (min-width: 1280px) {
  .u-text-justify-xl {
    text-align: justify !important;
  }
}
@media screen and (min-width: 1280px) {
  .u-text-left-xl {
    text-align: left !important;
  }
}
@media screen and (min-width: 1280px) {
  .u-text-right-xl {
    text-align: right !important;
  }
}
@media screen and (min-width: 1280px) {
  .u-text-center-xl {
    text-align: center !important;
  }
}
.u-text-ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.u-text-break {
  hyphens: auto;
  word-break: break-word;
  word-wrap: break-word;
}

/* Center element - best used for hero bodies or text */
.u-center {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  flex-wrap: wrap;
}

.u-center-alt,
.u-center-alt:hover,
.u-center-alt:active {
  /* If flexbox method fails, this works for elements with unknown dimensions */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.u-overlay {
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  position: absolute;
}

.u-disabled {
  cursor: not-allowed !important;
  pointer-events: none !important;
}

.u-unselectable {
  user-select: none !important;
}

.u-no-outline {
  outline: 0px !important;
}

.u-opacity-0 {
  opacity: 0 !important;
}

.u-opacity-10 {
  opacity: 0.1 !important;
}

.u-opacity-20 {
  opacity: 0.2 !important;
}

.u-opacity-30 {
  opacity: 0.3 !important;
}

.u-opacity-40 {
  opacity: 0.4 !important;
}

.u-opacity-50 {
  opacity: 0.5 !important;
}

.u-opacity-60 {
  opacity: 0.6 !important;
}

.u-opacity-70 {
  opacity: 0.7 !important;
}

.u-opacity-80 {
  opacity: 0.8 !important;
}

.u-opacity-90 {
  opacity: 0.9 !important;
}

.u-opacity-100 {
  opacity: 1 !important;
}

@media screen and (min-width: 640px) {
  .u-opacity-0-sm {
    opacity: 0 !important;
  }
}
@media screen and (min-width: 640px) {
  .u-opacity-10-sm {
    opacity: 0.1 !important;
  }
}
@media screen and (min-width: 640px) {
  .u-opacity-20-sm {
    opacity: 0.2 !important;
  }
}
@media screen and (min-width: 640px) {
  .u-opacity-30-sm {
    opacity: 0.3 !important;
  }
}
@media screen and (min-width: 640px) {
  .u-opacity-40-sm {
    opacity: 0.4 !important;
  }
}
@media screen and (min-width: 640px) {
  .u-opacity-50-sm {
    opacity: 0.5 !important;
  }
}
@media screen and (min-width: 640px) {
  .u-opacity-60-sm {
    opacity: 0.6 !important;
  }
}
@media screen and (min-width: 640px) {
  .u-opacity-70-sm {
    opacity: 0.7 !important;
  }
}
@media screen and (min-width: 640px) {
  .u-opacity-80-sm {
    opacity: 0.8 !important;
  }
}
@media screen and (min-width: 640px) {
  .u-opacity-90-sm {
    opacity: 0.9 !important;
  }
}
@media screen and (min-width: 640px) {
  .u-opacity-100-sm {
    opacity: 1 !important;
  }
}
@media screen and (min-width: 768px) {
  .u-opacity-0-md {
    opacity: 0 !important;
  }
}
@media screen and (min-width: 768px) {
  .u-opacity-10-md {
    opacity: 0.1 !important;
  }
}
@media screen and (min-width: 768px) {
  .u-opacity-20-md {
    opacity: 0.2 !important;
  }
}
@media screen and (min-width: 768px) {
  .u-opacity-30-md {
    opacity: 0.3 !important;
  }
}
@media screen and (min-width: 768px) {
  .u-opacity-40-md {
    opacity: 0.4 !important;
  }
}
@media screen and (min-width: 768px) {
  .u-opacity-50-md {
    opacity: 0.5 !important;
  }
}
@media screen and (min-width: 768px) {
  .u-opacity-60-md {
    opacity: 0.6 !important;
  }
}
@media screen and (min-width: 768px) {
  .u-opacity-70-md {
    opacity: 0.7 !important;
  }
}
@media screen and (min-width: 768px) {
  .u-opacity-80-md {
    opacity: 0.8 !important;
  }
}
@media screen and (min-width: 768px) {
  .u-opacity-90-md {
    opacity: 0.9 !important;
  }
}
@media screen and (min-width: 768px) {
  .u-opacity-100-md {
    opacity: 1 !important;
  }
}
@media screen and (min-width: 1024px) {
  .u-opacity-0-lg {
    opacity: 0 !important;
  }
}
@media screen and (min-width: 1024px) {
  .u-opacity-10-lg {
    opacity: 0.1 !important;
  }
}
@media screen and (min-width: 1024px) {
  .u-opacity-20-lg {
    opacity: 0.2 !important;
  }
}
@media screen and (min-width: 1024px) {
  .u-opacity-30-lg {
    opacity: 0.3 !important;
  }
}
@media screen and (min-width: 1024px) {
  .u-opacity-40-lg {
    opacity: 0.4 !important;
  }
}
@media screen and (min-width: 1024px) {
  .u-opacity-50-lg {
    opacity: 0.5 !important;
  }
}
@media screen and (min-width: 1024px) {
  .u-opacity-60-lg {
    opacity: 0.6 !important;
  }
}
@media screen and (min-width: 1024px) {
  .u-opacity-70-lg {
    opacity: 0.7 !important;
  }
}
@media screen and (min-width: 1024px) {
  .u-opacity-80-lg {
    opacity: 0.8 !important;
  }
}
@media screen and (min-width: 1024px) {
  .u-opacity-90-lg {
    opacity: 0.9 !important;
  }
}
@media screen and (min-width: 1024px) {
  .u-opacity-100-lg {
    opacity: 1 !important;
  }
}
@media screen and (min-width: 1280px) {
  .u-opacity-0-xl {
    opacity: 0 !important;
  }
}
@media screen and (min-width: 1280px) {
  .u-opacity-10-xl {
    opacity: 0.1 !important;
  }
}
@media screen and (min-width: 1280px) {
  .u-opacity-20-xl {
    opacity: 0.2 !important;
  }
}
@media screen and (min-width: 1280px) {
  .u-opacity-30-xl {
    opacity: 0.3 !important;
  }
}
@media screen and (min-width: 1280px) {
  .u-opacity-40-xl {
    opacity: 0.4 !important;
  }
}
@media screen and (min-width: 1280px) {
  .u-opacity-50-xl {
    opacity: 0.5 !important;
  }
}
@media screen and (min-width: 1280px) {
  .u-opacity-60-xl {
    opacity: 0.6 !important;
  }
}
@media screen and (min-width: 1280px) {
  .u-opacity-70-xl {
    opacity: 0.7 !important;
  }
}
@media screen and (min-width: 1280px) {
  .u-opacity-80-xl {
    opacity: 0.8 !important;
  }
}
@media screen and (min-width: 1280px) {
  .u-opacity-90-xl {
    opacity: 0.9 !important;
  }
}
@media screen and (min-width: 1280px) {
  .u-opacity-100-xl {
    opacity: 1 !important;
  }
}
.u-bg-opacity-0 {
  --bg-opacity: 0;
}

.u-bg-opacity-10 {
  --bg-opacity: 0.1;
}

.u-bg-opacity-20 {
  --bg-opacity: 0.2;
}

.u-bg-opacity-30 {
  --bg-opacity: 0.3;
}

.u-bg-opacity-40 {
  --bg-opacity: 0.4;
}

.u-bg-opacity-50 {
  --bg-opacity: 0.5;
}

.u-bg-opacity-60 {
  --bg-opacity: 0.6;
}

.u-bg-opacity-70 {
  --bg-opacity: 0.7;
}

.u-bg-opacity-80 {
  --bg-opacity: 0.8;
}

.u-bg-opacity-90 {
  --bg-opacity: 0.9;
}

.u-bg-opacity-100 {
  --bg-opacity: 1;
}

.u-color-opacity-0 {
  --color-opacity: 0;
}

.u-color-opacity-10 {
  --color-opacity: 0.1;
}

.u-color-opacity-20 {
  --color-opacity: 0.2;
}

.u-color-opacity-30 {
  --color-opacity: 0.3;
}

.u-color-opacity-40 {
  --color-opacity: 0.4;
}

.u-color-opacity-50 {
  --color-opacity: 0.5;
}

.u-color-opacity-60 {
  --color-opacity: 0.6;
}

.u-color-opacity-70 {
  --color-opacity: 0.7;
}

.u-color-opacity-80 {
  --color-opacity: 0.8;
}

.u-color-opacity-90 {
  --color-opacity: 0.9;
}

.u-color-opacity-100 {
  --color-opacity: 1;
}

.u-border-opacity-0 {
  --border-opacity: 0;
}

.u-border-opacity-10 {
  --border-opacity: 0.1;
}

.u-border-opacity-20 {
  --border-opacity: 0.2;
}

.u-border-opacity-30 {
  --border-opacity: 0.3;
}

.u-border-opacity-40 {
  --border-opacity: 0.4;
}

.u-border-opacity-50 {
  --border-opacity: 0.5;
}

.u-border-opacity-60 {
  --border-opacity: 0.6;
}

.u-border-opacity-70 {
  --border-opacity: 0.7;
}

.u-border-opacity-80 {
  --border-opacity: 0.8;
}

.u-border-opacity-90 {
  --border-opacity: 0.9;
}

.u-border-opacity-100 {
  --border-opacity: 1;
}

.u-overflow-auto {
  overflow: auto !important;
}

.u-overflow-hidden {
  overflow: hidden !important;
}

.u-overflow-visible {
  overflow: visible !important;
}

.u-overflow-scroll {
  overflow: scroll !important;
}

@media screen and (min-width: 640px) {
  .u-overflow-auto-sm {
    overflow: auto !important;
  }
}
@media screen and (min-width: 640px) {
  .u-overflow-hidden-sm {
    overflow: hidden !important;
  }
}
@media screen and (min-width: 640px) {
  .u-overflow-visible-sm {
    overflow: visible !important;
  }
}
@media screen and (min-width: 640px) {
  .u-overflow-scroll-sm {
    overflow: scroll !important;
  }
}
@media screen and (min-width: 768px) {
  .u-overflow-auto-md {
    overflow: auto !important;
  }
}
@media screen and (min-width: 768px) {
  .u-overflow-hidden-md {
    overflow: hidden !important;
  }
}
@media screen and (min-width: 768px) {
  .u-overflow-visible-md {
    overflow: visible !important;
  }
}
@media screen and (min-width: 768px) {
  .u-overflow-scroll-md {
    overflow: scroll !important;
  }
}
@media screen and (min-width: 1024px) {
  .u-overflow-auto-lg {
    overflow: auto !important;
  }
}
@media screen and (min-width: 1024px) {
  .u-overflow-hidden-lg {
    overflow: hidden !important;
  }
}
@media screen and (min-width: 1024px) {
  .u-overflow-visible-lg {
    overflow: visible !important;
  }
}
@media screen and (min-width: 1024px) {
  .u-overflow-scroll-lg {
    overflow: scroll !important;
  }
}
@media screen and (min-width: 1280px) {
  .u-overflow-auto-xl {
    overflow: auto !important;
  }
}
@media screen and (min-width: 1280px) {
  .u-overflow-hidden-xl {
    overflow: hidden !important;
  }
}
@media screen and (min-width: 1280px) {
  .u-overflow-visible-xl {
    overflow: visible !important;
  }
}
@media screen and (min-width: 1280px) {
  .u-overflow-scroll-xl {
    overflow: scroll !important;
  }
}
.u-overflow-x-auto {
  overflow-x: auto !important;
}

.u-overflow-x-hidden {
  overflow-x: hidden !important;
}

.u-overflow-x-visible {
  overflow-x: visible !important;
}

.u-overflow-x-scroll {
  overflow-x: scroll !important;
}

@media screen and (min-width: 640px) {
  .u-overflow-x-auto-sm {
    overflow-x: auto !important;
  }
}
@media screen and (min-width: 640px) {
  .u-overflow-x-hidden-sm {
    overflow-x: hidden !important;
  }
}
@media screen and (min-width: 640px) {
  .u-overflow-x-visible-sm {
    overflow-x: visible !important;
  }
}
@media screen and (min-width: 640px) {
  .u-overflow-x-scroll-sm {
    overflow-x: scroll !important;
  }
}
@media screen and (min-width: 768px) {
  .u-overflow-x-auto-md {
    overflow-x: auto !important;
  }
}
@media screen and (min-width: 768px) {
  .u-overflow-x-hidden-md {
    overflow-x: hidden !important;
  }
}
@media screen and (min-width: 768px) {
  .u-overflow-x-visible-md {
    overflow-x: visible !important;
  }
}
@media screen and (min-width: 768px) {
  .u-overflow-x-scroll-md {
    overflow-x: scroll !important;
  }
}
@media screen and (min-width: 1024px) {
  .u-overflow-x-auto-lg {
    overflow-x: auto !important;
  }
}
@media screen and (min-width: 1024px) {
  .u-overflow-x-hidden-lg {
    overflow-x: hidden !important;
  }
}
@media screen and (min-width: 1024px) {
  .u-overflow-x-visible-lg {
    overflow-x: visible !important;
  }
}
@media screen and (min-width: 1024px) {
  .u-overflow-x-scroll-lg {
    overflow-x: scroll !important;
  }
}
@media screen and (min-width: 1280px) {
  .u-overflow-x-auto-xl {
    overflow-x: auto !important;
  }
}
@media screen and (min-width: 1280px) {
  .u-overflow-x-hidden-xl {
    overflow-x: hidden !important;
  }
}
@media screen and (min-width: 1280px) {
  .u-overflow-x-visible-xl {
    overflow-x: visible !important;
  }
}
@media screen and (min-width: 1280px) {
  .u-overflow-x-scroll-xl {
    overflow-x: scroll !important;
  }
}
.u-overflow-y-auto {
  overflow-y: auto !important;
}

.u-overflow-y-hidden {
  overflow-y: hidden !important;
}

.u-overflow-y-visible {
  overflow-y: visible !important;
}

.u-overflow-y-scroll {
  overflow-y: scroll !important;
}

@media screen and (min-width: 640px) {
  .u-overflow-y-auto-sm {
    overflow-y: auto !important;
  }
}
@media screen and (min-width: 640px) {
  .u-overflow-y-hidden-sm {
    overflow-y: hidden !important;
  }
}
@media screen and (min-width: 640px) {
  .u-overflow-y-visible-sm {
    overflow-y: visible !important;
  }
}
@media screen and (min-width: 640px) {
  .u-overflow-y-scroll-sm {
    overflow-y: scroll !important;
  }
}
@media screen and (min-width: 768px) {
  .u-overflow-y-auto-md {
    overflow-y: auto !important;
  }
}
@media screen and (min-width: 768px) {
  .u-overflow-y-hidden-md {
    overflow-y: hidden !important;
  }
}
@media screen and (min-width: 768px) {
  .u-overflow-y-visible-md {
    overflow-y: visible !important;
  }
}
@media screen and (min-width: 768px) {
  .u-overflow-y-scroll-md {
    overflow-y: scroll !important;
  }
}
@media screen and (min-width: 1024px) {
  .u-overflow-y-auto-lg {
    overflow-y: auto !important;
  }
}
@media screen and (min-width: 1024px) {
  .u-overflow-y-hidden-lg {
    overflow-y: hidden !important;
  }
}
@media screen and (min-width: 1024px) {
  .u-overflow-y-visible-lg {
    overflow-y: visible !important;
  }
}
@media screen and (min-width: 1024px) {
  .u-overflow-y-scroll-lg {
    overflow-y: scroll !important;
  }
}
@media screen and (min-width: 1280px) {
  .u-overflow-y-auto-xl {
    overflow-y: auto !important;
  }
}
@media screen and (min-width: 1280px) {
  .u-overflow-y-hidden-xl {
    overflow-y: hidden !important;
  }
}
@media screen and (min-width: 1280px) {
  .u-overflow-y-visible-xl {
    overflow-y: visible !important;
  }
}
@media screen and (min-width: 1280px) {
  .u-overflow-y-scroll-xl {
    overflow-y: scroll !important;
  }
}
.u-static {
  position: static !important;
}

.u-fixed {
  position: fixed !important;
}

.u-absolute {
  position: absolute !important;
}

.u-relative {
  position: relative !important;
}

.u-sticky {
  position: sticky !important;
}

@media screen and (min-width: 640px) {
  .u-static-sm {
    position: static !important;
  }
}
@media screen and (min-width: 640px) {
  .u-fixed-sm {
    position: fixed !important;
  }
}
@media screen and (min-width: 640px) {
  .u-absolute-sm {
    position: absolute !important;
  }
}
@media screen and (min-width: 640px) {
  .u-relative-sm {
    position: relative !important;
  }
}
@media screen and (min-width: 640px) {
  .u-sticky-sm {
    position: sticky !important;
  }
}
@media screen and (min-width: 768px) {
  .u-static-md {
    position: static !important;
  }
}
@media screen and (min-width: 768px) {
  .u-fixed-md {
    position: fixed !important;
  }
}
@media screen and (min-width: 768px) {
  .u-absolute-md {
    position: absolute !important;
  }
}
@media screen and (min-width: 768px) {
  .u-relative-md {
    position: relative !important;
  }
}
@media screen and (min-width: 768px) {
  .u-sticky-md {
    position: sticky !important;
  }
}
@media screen and (min-width: 1024px) {
  .u-static-lg {
    position: static !important;
  }
}
@media screen and (min-width: 1024px) {
  .u-fixed-lg {
    position: fixed !important;
  }
}
@media screen and (min-width: 1024px) {
  .u-absolute-lg {
    position: absolute !important;
  }
}
@media screen and (min-width: 1024px) {
  .u-relative-lg {
    position: relative !important;
  }
}
@media screen and (min-width: 1024px) {
  .u-sticky-lg {
    position: sticky !important;
  }
}
@media screen and (min-width: 1280px) {
  .u-static-xl {
    position: static !important;
  }
}
@media screen and (min-width: 1280px) {
  .u-fixed-xl {
    position: fixed !important;
  }
}
@media screen and (min-width: 1280px) {
  .u-absolute-xl {
    position: absolute !important;
  }
}
@media screen and (min-width: 1280px) {
  .u-relative-xl {
    position: relative !important;
  }
}
@media screen and (min-width: 1280px) {
  .u-sticky-xl {
    position: sticky !important;
  }
}
.u-shadow-none {
  --cirrus-shadow: 0 0 #000;
  box-shadow: var(--cirrus-shadow);
}

.u-shadow-xs {
  --cirrus-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  box-shadow: var(--cirrus-shadow);
}

.u-shadow-sm {
  --cirrus-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  box-shadow: var(--cirrus-shadow);
}

.u-shadow-md {
  --cirrus-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  box-shadow: var(--cirrus-shadow);
}

.u-shadow-lg {
  --cirrus-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  box-shadow: var(--cirrus-shadow);
}

.u-shadow-xl {
  --cirrus-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
  box-shadow: var(--cirrus-shadow);
}

.u-shadow-inset {
  --cirrus-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
  box-shadow: var(--cirrus-shadow);
}

/* TRANSITION DURATION */
.u-duration-75 {
  transition-duration: 75ms !important;
}

.u-duration-100 {
  transition-duration: 100ms !important;
}

.u-duration-200 {
  transition-duration: 200ms !important;
}

.u-duration-300 {
  transition-duration: 300ms !important;
}

.u-duration-500 {
  transition-duration: 500ms !important;
}

.u-duration-700 {
  transition-duration: 700ms !important;
}

.u-duration-1000 {
  transition-duration: 1000ms !important;
}

.u-z-n1 {
  z-index: -1 !important;
}

.u-z-0 {
  z-index: 0 !important;
}

.u-z-1 {
  z-index: 1 !important;
}

.u-z-10 {
  z-index: 10 !important;
}

.u-z-20 {
  z-index: 20 !important;
}

.u-z-30 {
  z-index: 30 !important;
}

.u-z-40 {
  z-index: 40 !important;
}

.u-z-50 {
  z-index: 50 !important;
}

.u-z-auto {
  z-index: auto !important;
}

/* Accordion */
/* Use with <detail> and <summary> HTML tags */
details.accordion {
  border-bottom: 1px solid #dee2e6;
  display: block;
  padding: 1rem;
}

details.accordion summary.accordion__summary {
  align-items: center;
  cursor: pointer;
  display: flex;
  font-weight: bold;
  justify-content: space-between;
  list-style-type: none;
  opacity: 0.75;
  transition: all 250ms;
}

details.accordion summary.accordion__summary:after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='rgb(65, 84, 98)' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
  background-position: right center;
  background-size: 1rem auto;
  background-repeat: no-repeat;
  content: "";
  display: block;
  float: right;
  height: 1rem;
  transform: rotate(-90deg);
  width: 1rem;
}

details.accordion[open] summary.accordion__summary {
  margin-bottom: 1rem;
  opacity: 1;
}

details.accordion[open] summary.accordion__summary:after {
  transform: rotate(0);
}

/* Avatar */
.avatar {
  border-radius: 50%;
  position: relative;
  display: block;
  margin: auto;
  font-size: 1.5rem;
  font-weight: lighter;
  width: 3.2rem;
  height: 3.2rem;
  background-color: #f03d4d;
  overflow: hidden;
  /* Draw text on image just like in Gmail */
}

.avatar::before {
  content: attr(data-text);
  color: currentColor;
  left: 50%;
  top: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
}

.avatar.avatar--xs {
  font-size: 0.8rem;
  width: 1.6rem;
  height: 1.6rem;
}

.avatar.avatar--sm {
  font-size: 1rem;
  width: 2.4rem;
  height: 2.4rem;
}

.avatar.avatar--lg {
  font-size: 2rem;
  width: 4.8rem;
  height: 4.8rem;
}

.avatar.avatar--xl {
  font-size: 3rem;
  width: 6.4rem;
  height: 6.4rem;
}

.avatar img.padded {
  padding: 0.5rem;
  width: 100%;
}

.breadcrumb {
  margin: 0.5rem 0;
  padding: 0.5rem 0;
  align-items: flex-start;
  display: flex;
  justify-content: flex-start;
  list-style: none;
  white-space: nowrap;
}

.breadcrumb.breadcrumb--center {
  justify-content: center;
}

.breadcrumb.breadcrumb--right {
  justify-content: flex-end;
}

.breadcrumb.breadcrumb--arrow .breadcrumb__item:not(:first-child)::before {
  content: "→";
}

.breadcrumb.breadcrumb--bullet .breadcrumb__item:not(:first-child)::before {
  content: "•";
}

.breadcrumb.breadcrumb--dot .breadcrumb__item:not(:first-child)::before {
  content: "·";
}

.breadcrumb.breadcrumb--gt .breadcrumb__item:not(:first-child)::before {
  content: "≻";
}

.breadcrumb .breadcrumb__item {
  margin: 0;
}

.breadcrumb .breadcrumb__item:not(:last-child) {
  margin-right: 0.5rem;
}

.breadcrumb .breadcrumb__item:not(:first-child)::before {
  content: "/";
  padding-right: 0.5rem;
}

.breadcrumb .breadcrumb__item.breadcrumb__item--active {
  cursor: default;
  pointer-events: none;
}

.breadcrumb .breadcrumb__item.breadcrumb__item--active a {
  color: #0f172a;
}

/* CARDS */
.card {
  background-color: #fff;
  backface-visibility: hidden;
  border-radius: 5px;
  box-shadow: 0px 5px 12px 0 rgba(42, 51, 83, 0.12), 0px 0px 5px rgba(0, 0, 0, 0.06);
  margin-bottom: 1rem;
  overflow: hidden;
  position: relative;
  transition: all 0.3s;
  /* Allow for the cards to have the same height when u-flex is used on the card */
  /*
      NOTE: To have cards with the same height, place the card class with col-x classes and have the parent have fluid-container.
  */
  /* Card Title Bar */
}

.card:hover {
  transition: all 0.3s;
  box-shadow: 0px 8px 20px 0 rgba(42, 51, 83, 0.12), 0 5px 5px rgba(0, 0, 0, 0.06);
}

.card:hover .card-image::after {
  opacity: 0;
}

.card.slide-up, .card.card--slide-up {
  display: flex;
  flex-direction: column;
  max-height: 550px;
  /* More card components in second example */
}

.card.slide-up:hover .card-body,
.card.slide-up:hover .card__body, .card.card--slide-up:hover .card-body,
.card.card--slide-up:hover .card__body {
  opacity: 1;
}

.card.slide-up:hover .card-image,
.card.slide-up:hover .card__image, .card.card--slide-up:hover .card-image,
.card.card--slide-up:hover .card__image {
  transform: translateY(-40px);
}

.card.slide-up:hover .mobile-title,
.card.slide-up:hover .card__mobile-title, .card.card--slide-up:hover .mobile-title,
.card.card--slide-up:hover .card__mobile-title {
  bottom: 35%;
  transition: all 0.3s ease-in-out;
}

.card.slide-up .mobile-title,
.card.slide-up .card__mobile-title, .card.card--slide-up .mobile-title,
.card.card--slide-up .card__mobile-title {
  position: absolute;
  left: 0;
  bottom: 5rem;
  background-color: var(--cirrus-bg);
  transition: 0.3s ease-in-out;
  width: 100%;
  padding: 1.5rem 0 0 0;
  backface-visibility: hidden;
}

.card.slide-up .card-body,
.card.slide-up .card__body, .card.card--slide-up .card-body,
.card.card--slide-up .card__body {
  opacity: 0;
  overflow: auto;
  transition: all var(--animation-duration) ease-in-out;
}

.card.u-flex .content {
  flex-grow: 1;
}

.card p {
  margin: 1rem 0;
}

.card .card-image,
.card .card__image {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  border-radius: 5px 5px 0 0;
  background-size: cover;
  background-repeat: no-repeat;
  transition: all 0.3s ease-in-out;
}

.card .card-image::after,
.card .card__image::after {
  content: "";
  display: block;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.1);
  top: 0;
  left: 0;
  right: 0;
  transition: all 500ms;
  bottom: 0;
}

.card .card-container,
.card .card__container {
  display: block;
  position: relative;
  height: 40%;
  min-height: 21rem; /* So it appears in html5 standards mode */
}

.card .title-container,
.card .card__title-container {
  position: absolute;
  bottom: 1rem;
  width: 100%;
  padding: 0 1rem;
}

.card .title-container .title,
.card .title-container .subtitle,
.card .card__title-container .title,
.card .card__title-container .subtitle {
  color: #fff;
  margin: 1rem auto;
}

.card .title-container .title,
.card .card__title-container .title {
  font-weight: 300;
  font-size: 1.5rem;
  margin-bottom: 0;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.25);
}

.card .action-bar,
.card .card__action-bar {
  user-select: none;
  padding: 0.5rem;
  margin: 0;
  border-top: 1px solid #e9ecef;
  box-sizing: border-box;
  transition: left 200ms cubic-bezier(0.075, 0.82, 0.165, 1);
  /* Add some padding to the buttons */
}

.card .action-bar .btn, .card .action-bar button, .card .action-bar [type=submit], .card .action-bar [type=reset], .card .action-bar [type=button],
.card .card__action-bar .btn,
.card .card__action-bar button,
.card .card__action-bar [type=submit],
.card .card__action-bar [type=reset],
.card .card__action-bar [type=button] {
  margin: 0 0.5rem;
}

.card .action-bar + .card-footer, .card .action-bar + .card__footer,
.card .card__action-bar + .card-footer,
.card .card__action-bar + .card__footer {
  padding: 1rem 0;
  border-top: 1px solid #e9ecef;
}

.card .card-footer,
.card .card__footer {
  position: relative;
  font-size: 0.875rem;
  color: #868e96;
}

.card .card-head,
.card .card__header {
  align-items: stretch;
  display: flex;
  border-bottom: 1px solid #e9ecef;
}

/* Mixins */
/* EXTENDED FORM */
/* Base class layout for extended form */
.form-ext-control {
  padding-left: 1.5rem;
  position: relative;
  /* Checkbox */
  /* Radio Button */
  /* Hides the original input */
  /* Toggle Switches */
}

.form-ext-control.form-ext-checkbox .form-ext-input:checked ~ .form-ext-label:after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
}

.form-ext-control.form-ext-checkbox .form-ext-label:before {
  border-radius: 0.25rem;
}

.form-ext-control.form-ext-radio .form-ext-input:checked ~ .form-ext-label:after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23fff'/%3E%3C/svg%3E");
}

.form-ext-control.form-ext-radio .form-ext-label:before {
  border-radius: 50%;
}

.form-ext-control .form-ext-input {
  opacity: 0;
  position: absolute;
  z-index: -1;
  /* Checked state */
}

.form-ext-control .form-ext-input:disabled ~ .form-ext-label {
  opacity: 0.4;
}

.form-ext-control .form-ext-input:checked ~ .form-ext-label:before {
  background-color: #f03d4d;
}

.form-ext-control .form-ext-input.form-ext-input--primary:checked ~ .form-ext-label {
  color: #f03d4d;
}

.form-ext-control .form-ext-input.form-ext-input--primary:checked ~ .form-ext-label:before {
  background-color: #f03d4d;
}

.form-ext-control .form-ext-input.form-ext-input--primary:focus ~ .form-ext-label:before {
  border-color: inherit;
  box-shadow: 0 0 0 0.2rem rgba(240, 61, 77, 0.55), inset 0 1px 8px rgba(0, 0, 0, 0.07);
}

.form-ext-control .form-ext-input.form-ext-input--dark:checked ~ .form-ext-label {
  color: #363636;
}

.form-ext-control .form-ext-input.form-ext-input--dark:checked ~ .form-ext-label:before {
  background-color: #363636;
}

.form-ext-control .form-ext-input.form-ext-input--dark:focus ~ .form-ext-label:before {
  border-color: inherit;
  box-shadow: 0 0 0 0.2rem rgba(54, 54, 54, 0.55), inset 0 1px 8px rgba(0, 0, 0, 0.07);
}

.form-ext-control .form-ext-input.form-ext-input--link:checked ~ .form-ext-label {
  color: #5e5cc7;
}

.form-ext-control .form-ext-input.form-ext-input--link:checked ~ .form-ext-label:before {
  background-color: #5e5cc7;
}

.form-ext-control .form-ext-input.form-ext-input--link:focus ~ .form-ext-label:before {
  border-color: inherit;
  box-shadow: 0 0 0 0.2rem rgba(94, 92, 199, 0.55), inset 0 1px 8px rgba(0, 0, 0, 0.07);
}

.form-ext-control .form-ext-input.form-ext-input--info:checked ~ .form-ext-label {
  color: #2972fa;
}

.form-ext-control .form-ext-input.form-ext-input--info:checked ~ .form-ext-label:before {
  background-color: #2972fa;
}

.form-ext-control .form-ext-input.form-ext-input--info:focus ~ .form-ext-label:before {
  border-color: inherit;
  box-shadow: 0 0 0 0.2rem rgba(41, 114, 250, 0.55), inset 0 1px 8px rgba(0, 0, 0, 0.07);
}

.form-ext-control .form-ext-input.form-ext-input--success:checked ~ .form-ext-label {
  color: #0dd157;
}

.form-ext-control .form-ext-input.form-ext-input--success:checked ~ .form-ext-label:before {
  background-color: #0dd157;
}

.form-ext-control .form-ext-input.form-ext-input--success:focus ~ .form-ext-label:before {
  border-color: inherit;
  box-shadow: 0 0 0 0.2rem rgba(13, 209, 87, 0.55), inset 0 1px 8px rgba(0, 0, 0, 0.07);
}

.form-ext-control .form-ext-input.form-ext-input--warning:checked ~ .form-ext-label {
  color: #fab633;
}

.form-ext-control .form-ext-input.form-ext-input--warning:checked ~ .form-ext-label:before {
  background-color: #fab633;
}

.form-ext-control .form-ext-input.form-ext-input--warning:focus ~ .form-ext-label:before {
  border-color: inherit;
  box-shadow: 0 0 0 0.2rem rgba(250, 182, 51, 0.55), inset 0 1px 8px rgba(0, 0, 0, 0.07);
}

.form-ext-control .form-ext-input.form-ext-input--danger:checked ~ .form-ext-label {
  color: #fb4143;
}

.form-ext-control .form-ext-input.form-ext-input--danger:checked ~ .form-ext-label:before {
  background-color: #fb4143;
}

.form-ext-control .form-ext-input.form-ext-input--danger:focus ~ .form-ext-label:before {
  border-color: inherit;
  box-shadow: 0 0 0 0.2rem rgba(251, 65, 67, 0.55), inset 0 1px 8px rgba(0, 0, 0, 0.07);
}

.form-ext-control .form-ext-input:disabled ~ .form-ext-toggle__toggler {
  opacity: 0.5;
}

.form-ext-control .form-ext-toggle__label {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.form-ext-control .form-ext-toggle {
  cursor: pointer;
  position: relative;
  /* Toggle themes */
  /**
  * Accessibility
  */
  /**
  * Accessibility
  */
  /**
  * Accessibility
  */
  /**
  * Accessibility
  */
  /**
  * Accessibility
  */
  /**
  * Accessibility
  */
  /**
  * Accessibility
  */
}

.form-ext-control .form-ext-toggle input[type=checkbox],
.form-ext-control .form-ext-toggle input[type=radio] {
  opacity: 0;
  position: absolute;
  z-index: -1;
}

.form-ext-control .form-ext-toggle .form-ext-toggle__toggler {
  border: 1px solid #d5d7dc;
  border-radius: 6.25rem;
  color: #d5d7dc;
  display: block;
  font-size: 9px;
  height: 1.5rem;
  position: relative;
  width: 3rem;
}

.form-ext-control .form-ext-toggle .form-ext-toggle__toggler i {
  display: inline-block;
}

.form-ext-control .form-ext-toggle input[type=checkbox]:checked + .form-ext-toggle__toggler,
.form-ext-control .form-ext-toggle input[type=checkbox]:checked + * .form-ext-toggle__toggler,
.form-ext-control .form-ext-toggle input[type=radio]:checked + .form-ext-toggle__toggler,
.form-ext-control .form-ext-toggle input[type=radio]:checked + * .form-ext-toggle__toggler {
  background-color: #f03d4d;
  border-color: #f03d4d;
  color: #fff;
  position: relative;
  transition: all 0.4s ease;
}

.form-ext-control .form-ext-toggle input[type=checkbox]:checked + .form-ext-toggle__toggler i::after,
.form-ext-control .form-ext-toggle input[type=checkbox]:checked + * .form-ext-toggle__toggler i::after,
.form-ext-control .form-ext-toggle input[type=radio]:checked + .form-ext-toggle__toggler i::after,
.form-ext-control .form-ext-toggle input[type=radio]:checked + * .form-ext-toggle__toggler i::after {
  background-color: #fff;
  left: calc(100% - 20px);
}

.form-ext-control .form-ext-toggle input[type=checkbox]:checked + .form-ext-toggle__toggler i::before,
.form-ext-control .form-ext-toggle input[type=checkbox]:checked + * .form-ext-toggle__toggler i::before,
.form-ext-control .form-ext-toggle input[type=radio]:checked + .form-ext-toggle__toggler i::before,
.form-ext-control .form-ext-toggle input[type=radio]:checked + * .form-ext-toggle__toggler i::before {
  color: #fff;
  content: attr(data-check-icon);
  text-align: left;
}

.form-ext-control .form-ext-toggle.form-ext-toggle--primary input[type=checkbox]:checked + .form-ext-toggle__toggler, .form-ext-control .form-ext-toggle.form-ext-toggle--primary input[type=checkbox]:checked + * .form-ext-toggle__toggler, .form-ext-control .form-ext-toggle.form-ext-toggle--primary input[type=radio]:checked + .form-ext-toggle__toggler, .form-ext-control .form-ext-toggle.form-ext-toggle--primary input[type=radio]:checked + * .form-ext-toggle__toggler {
  background-color: #f03d4d;
  border-color: #f03d4d;
}

.form-ext-control .form-ext-toggle .form-ext-toggle--primary .form-ext-toggle__toggler {
  border-color: #f03d4d;
  color: #f03d4d;
}

.form-ext-control .form-ext-toggle .form-ext-toggle--primary .form-ext-toggle__toggler i::after {
  background-color: #f03d4d;
}

.form-ext-control .form-ext-toggle .form-ext-input:focus + .form-ext-toggle__toggler,
.form-ext-control .form-ext-toggle .form-ext-input:focus ~ .form-ext-label:before {
  box-shadow: 0 0 0 0.2rem rgba(240, 61, 77, 0.55), inset 0 1px 8px rgba(0, 0, 0, 0.07);
}

.form-ext-control .form-ext-toggle.form-ext-toggle--primary .form-ext-input:focus + .form-ext-toggle__toggler {
  box-shadow: 0 0 0 0.2rem rgba(240, 61, 77, 0.55), inset 0 1px 8px rgba(0, 0, 0, 0.07);
}

.form-ext-control .form-ext-toggle.form-ext-toggle--dark input[type=checkbox]:checked + .form-ext-toggle__toggler, .form-ext-control .form-ext-toggle.form-ext-toggle--dark input[type=checkbox]:checked + * .form-ext-toggle__toggler, .form-ext-control .form-ext-toggle.form-ext-toggle--dark input[type=radio]:checked + .form-ext-toggle__toggler, .form-ext-control .form-ext-toggle.form-ext-toggle--dark input[type=radio]:checked + * .form-ext-toggle__toggler {
  background-color: #363636;
  border-color: #363636;
}

.form-ext-control .form-ext-toggle .form-ext-toggle--dark .form-ext-toggle__toggler {
  border-color: #363636;
  color: #363636;
}

.form-ext-control .form-ext-toggle .form-ext-toggle--dark .form-ext-toggle__toggler i::after {
  background-color: #363636;
}

.form-ext-control .form-ext-toggle .form-ext-input:focus + .form-ext-toggle__toggler,
.form-ext-control .form-ext-toggle .form-ext-input:focus ~ .form-ext-label:before {
  box-shadow: 0 0 0 0.2rem rgba(54, 54, 54, 0.55), inset 0 1px 8px rgba(0, 0, 0, 0.07);
}

.form-ext-control .form-ext-toggle.form-ext-toggle--dark .form-ext-input:focus + .form-ext-toggle__toggler {
  box-shadow: 0 0 0 0.2rem rgba(54, 54, 54, 0.55), inset 0 1px 8px rgba(0, 0, 0, 0.07);
}

.form-ext-control .form-ext-toggle.form-ext-toggle--link input[type=checkbox]:checked + .form-ext-toggle__toggler, .form-ext-control .form-ext-toggle.form-ext-toggle--link input[type=checkbox]:checked + * .form-ext-toggle__toggler, .form-ext-control .form-ext-toggle.form-ext-toggle--link input[type=radio]:checked + .form-ext-toggle__toggler, .form-ext-control .form-ext-toggle.form-ext-toggle--link input[type=radio]:checked + * .form-ext-toggle__toggler {
  background-color: #5e5cc7;
  border-color: #5e5cc7;
}

.form-ext-control .form-ext-toggle .form-ext-toggle--link .form-ext-toggle__toggler {
  border-color: #5e5cc7;
  color: #5e5cc7;
}

.form-ext-control .form-ext-toggle .form-ext-toggle--link .form-ext-toggle__toggler i::after {
  background-color: #5e5cc7;
}

.form-ext-control .form-ext-toggle .form-ext-input:focus + .form-ext-toggle__toggler,
.form-ext-control .form-ext-toggle .form-ext-input:focus ~ .form-ext-label:before {
  box-shadow: 0 0 0 0.2rem rgba(94, 92, 199, 0.55), inset 0 1px 8px rgba(0, 0, 0, 0.07);
}

.form-ext-control .form-ext-toggle.form-ext-toggle--link .form-ext-input:focus + .form-ext-toggle__toggler {
  box-shadow: 0 0 0 0.2rem rgba(94, 92, 199, 0.55), inset 0 1px 8px rgba(0, 0, 0, 0.07);
}

.form-ext-control .form-ext-toggle.form-ext-toggle--info input[type=checkbox]:checked + .form-ext-toggle__toggler, .form-ext-control .form-ext-toggle.form-ext-toggle--info input[type=checkbox]:checked + * .form-ext-toggle__toggler, .form-ext-control .form-ext-toggle.form-ext-toggle--info input[type=radio]:checked + .form-ext-toggle__toggler, .form-ext-control .form-ext-toggle.form-ext-toggle--info input[type=radio]:checked + * .form-ext-toggle__toggler {
  background-color: #2972fa;
  border-color: #2972fa;
}

.form-ext-control .form-ext-toggle .form-ext-toggle--info .form-ext-toggle__toggler {
  border-color: #2972fa;
  color: #2972fa;
}

.form-ext-control .form-ext-toggle .form-ext-toggle--info .form-ext-toggle__toggler i::after {
  background-color: #2972fa;
}

.form-ext-control .form-ext-toggle .form-ext-input:focus + .form-ext-toggle__toggler,
.form-ext-control .form-ext-toggle .form-ext-input:focus ~ .form-ext-label:before {
  box-shadow: 0 0 0 0.2rem rgba(41, 114, 250, 0.55), inset 0 1px 8px rgba(0, 0, 0, 0.07);
}

.form-ext-control .form-ext-toggle.form-ext-toggle--info .form-ext-input:focus + .form-ext-toggle__toggler {
  box-shadow: 0 0 0 0.2rem rgba(41, 114, 250, 0.55), inset 0 1px 8px rgba(0, 0, 0, 0.07);
}

.form-ext-control .form-ext-toggle.form-ext-toggle--success input[type=checkbox]:checked + .form-ext-toggle__toggler, .form-ext-control .form-ext-toggle.form-ext-toggle--success input[type=checkbox]:checked + * .form-ext-toggle__toggler, .form-ext-control .form-ext-toggle.form-ext-toggle--success input[type=radio]:checked + .form-ext-toggle__toggler, .form-ext-control .form-ext-toggle.form-ext-toggle--success input[type=radio]:checked + * .form-ext-toggle__toggler {
  background-color: #0dd157;
  border-color: #0dd157;
}

.form-ext-control .form-ext-toggle .form-ext-toggle--success .form-ext-toggle__toggler {
  border-color: #0dd157;
  color: #0dd157;
}

.form-ext-control .form-ext-toggle .form-ext-toggle--success .form-ext-toggle__toggler i::after {
  background-color: #0dd157;
}

.form-ext-control .form-ext-toggle .form-ext-input:focus + .form-ext-toggle__toggler,
.form-ext-control .form-ext-toggle .form-ext-input:focus ~ .form-ext-label:before {
  box-shadow: 0 0 0 0.2rem rgba(13, 209, 87, 0.55), inset 0 1px 8px rgba(0, 0, 0, 0.07);
}

.form-ext-control .form-ext-toggle.form-ext-toggle--success .form-ext-input:focus + .form-ext-toggle__toggler {
  box-shadow: 0 0 0 0.2rem rgba(13, 209, 87, 0.55), inset 0 1px 8px rgba(0, 0, 0, 0.07);
}

.form-ext-control .form-ext-toggle.form-ext-toggle--warning input[type=checkbox]:checked + .form-ext-toggle__toggler, .form-ext-control .form-ext-toggle.form-ext-toggle--warning input[type=checkbox]:checked + * .form-ext-toggle__toggler, .form-ext-control .form-ext-toggle.form-ext-toggle--warning input[type=radio]:checked + .form-ext-toggle__toggler, .form-ext-control .form-ext-toggle.form-ext-toggle--warning input[type=radio]:checked + * .form-ext-toggle__toggler {
  background-color: #fab633;
  border-color: #fab633;
}

.form-ext-control .form-ext-toggle .form-ext-toggle--warning .form-ext-toggle__toggler {
  border-color: #fab633;
  color: #fab633;
}

.form-ext-control .form-ext-toggle .form-ext-toggle--warning .form-ext-toggle__toggler i::after {
  background-color: #fab633;
}

.form-ext-control .form-ext-toggle .form-ext-input:focus + .form-ext-toggle__toggler,
.form-ext-control .form-ext-toggle .form-ext-input:focus ~ .form-ext-label:before {
  box-shadow: 0 0 0 0.2rem rgba(250, 182, 51, 0.55), inset 0 1px 8px rgba(0, 0, 0, 0.07);
}

.form-ext-control .form-ext-toggle.form-ext-toggle--warning .form-ext-input:focus + .form-ext-toggle__toggler {
  box-shadow: 0 0 0 0.2rem rgba(250, 182, 51, 0.55), inset 0 1px 8px rgba(0, 0, 0, 0.07);
}

.form-ext-control .form-ext-toggle.form-ext-toggle--danger input[type=checkbox]:checked + .form-ext-toggle__toggler, .form-ext-control .form-ext-toggle.form-ext-toggle--danger input[type=checkbox]:checked + * .form-ext-toggle__toggler, .form-ext-control .form-ext-toggle.form-ext-toggle--danger input[type=radio]:checked + .form-ext-toggle__toggler, .form-ext-control .form-ext-toggle.form-ext-toggle--danger input[type=radio]:checked + * .form-ext-toggle__toggler {
  background-color: #fb4143;
  border-color: #fb4143;
}

.form-ext-control .form-ext-toggle .form-ext-toggle--danger .form-ext-toggle__toggler {
  border-color: #fb4143;
  color: #fb4143;
}

.form-ext-control .form-ext-toggle .form-ext-toggle--danger .form-ext-toggle__toggler i::after {
  background-color: #fb4143;
}

.form-ext-control .form-ext-toggle .form-ext-input:focus + .form-ext-toggle__toggler,
.form-ext-control .form-ext-toggle .form-ext-input:focus ~ .form-ext-label:before {
  box-shadow: 0 0 0 0.2rem rgba(251, 65, 67, 0.55), inset 0 1px 8px rgba(0, 0, 0, 0.07);
}

.form-ext-control .form-ext-toggle.form-ext-toggle--danger .form-ext-input:focus + .form-ext-toggle__toggler {
  box-shadow: 0 0 0 0.2rem rgba(251, 65, 67, 0.55), inset 0 1px 8px rgba(0, 0, 0, 0.07);
}

.form-ext-control .form-ext-toggle .form-ext-toggle__toggler i::before,
.form-ext-control .form-ext-toggle .form-ext-toggle__toggler i::after {
  content: "";
  display: block;
  position: absolute;
}

.form-ext-control .form-ext-toggle .form-ext-toggle__toggler i::before {
  content: attr(data-uncheck-icon);
  padding: 2px 7px;
  line-height: 18px;
  text-align: right;
  top: 0;
  width: 55%;
  font-size: 12px;
}

.form-ext-control .form-ext-toggle .form-ext-toggle__toggler i::after {
  background-color: #d5d7dc;
  border-radius: 50%;
  height: 16px;
  left: 4px;
  width: 16px;
  transform: translateY(-50%);
  transition: left var(--animation-duration) ease;
  text-align: left;
  top: 50%;
}

.form-ext-control .form-ext-label {
  margin-bottom: 0;
  position: relative;
  /* Base of custom form inputs */
}

.form-ext-control .form-ext-label:before, .form-ext-control .form-ext-label:after {
  content: "";
  display: block;
  height: 1rem;
  left: -1.5rem;
  position: absolute;
  top: 0.3rem;
  transition: all var(--animation-duration);
  width: 1rem;
}

.form-ext-control .form-ext-label:before {
  background-color: #f6f9fc;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  pointer-events: none;
  user-select: none;
  box-sizing: border-box;
}

.form-ext-control .form-ext-label:after {
  background-position: center;
  background-repeat: no-repeat;
  background-size: 50% 50%;
}

.form-ext-control .form-ext-input:checked ~ .form-ext-label:before {
  border: none;
}

/* EXTENDED LINKS */
/* Animated links */
.u,
.utb {
  display: inline;
  position: relative; /* So the psuedo-elements are positioned correctly */
}

/* Underline */
.u::after {
  content: "";
  transition: all 0.3s;
  backface-visibility: hidden;
  position: absolute;
  height: 2px;
  width: 0;
  background: #4643e2;
  bottom: -0.25em;
}

.u:hover::after {
  width: 100%;
}

.u.u-LR::after {
  /* Left to Right */
  left: 0;
}

.u.u-LR::after {
  /* Left to Right */
  left: 0;
}

.u.u-RL::after {
  /* Right to Left */
  right: 0;
}

.u.u-RL:hover::after {
  width: 100%;
}

.u.u-C::after {
  /* Center Outwards */
  left: 50%;
  transform: translateX(-50%);
}

/* Underline top and bottom */
.utb {
  /* Opposite start */
}

.utb::before, .utb::after {
  content: "";
  transition: all 0.3s;
  backface-visibility: hidden;
  position: absolute;
  height: 2px;
  width: 0;
  background: #4643e2;
}

.utb::before {
  top: -0.25em;
}

.utb::after {
  bottom: -0.25em;
}

.utb:hover::before, .utb:hover::after {
  width: 100%;
}

.utb.utb-LR::before, .utb.utb-LR::after {
  left: 0;
}

.utb.utb-RL::before, .utb.utb-RL::after {
  right: 0;
}

.utb.utb-C::before, .utb.utb-C::after {
  left: 50%;
  transform: translateX(-50%);
}

.utb.utb-OLR::before {
  /* Suffix denotes border transition direction for the top border (left to right). Bottom border will be the opposite direction */
  left: 0;
}

.utb.utb-OLR::after {
  right: 0;
}

.utb.utb-ORL::before {
  right: 0;
}

.utb.utb-ORL::after {
  left: 0;
}

/* Links with square borders */
/* Please note that usquare is the parent span (not div) which would create the left and right borders. The link (.utb-OLR) gets nested within this */
.usquare {
  margin-left: 0.4rem;
  position: relative;
  overflow: hidden;
}

.usquare::before, .usquare::after {
  content: "";
  transition: 0.25s all ease;
  backface-visibility: hidden;
  position: absolute;
  width: 2px;
  width: 0.1 rem;
  height: 0;
  background: #4643e2;
}

.usquare::before {
  left: 0;
  bottom: -0.2rem;
}

.usquare::after {
  right: 0;
  top: -0.2rem;
}

.usquare:hover::before, .usquare:hover::after {
  height: calc(100% + 0.4rem); /* Makes the left and right borders */
}

.usquare:hover a::before,
.usquare:hover a::after {
  width: 100%;
}

.usquare.delay::before {
  transition-delay: 0.6s;
}

.usquare.delay::after {
  transition-delay: var(--animation-duration);
}

.usquare.delay a::after {
  transition: 0.25s all ease 0.4s;
}

.usquare a {
  position: static;
  padding: 0.2rem 0.4rem;
  transition: 0.25s all ease;
}

.usquare a::before {
  left: 0;
}

.usquare a::after {
  right: 0;
}

/* The base of the modal dialog, which is an overlay of the webpage */
.modal {
  position: fixed; /* Scrolls with the user */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0; /* Initially hidden */
  padding: 1rem;
  display: none; /* Doesn't block the elements underneath */
  align-items: center;
  justify-content: center; /* Vertical centering */
  pointer-events: none; /* Prevent any pointer events made to modal while hidden */
  /* When the modal dialog is visible */
  /* Different size modals */
  /* The modal dialog body with the text itself */
  /* MODAL ANIMATIONS */
  /* Visible state */
}

.modal:target, .modal.modal--visible {
  display: flex;
  opacity: 1;
  z-index: 999;
  pointer-events: auto; /* Re-enable pointer events */
  /* The div in the modal dialpog used to create the translucent background */
}

.modal:target .modal-overlay, .modal.modal--visible .modal-overlay {
  position: absolute; /* Absolute inside of the modal container */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: block;
  background-color: rgba(54, 54, 54, 0.5);
}

.modal:target .modal-container,
.modal:target .modal-container, .modal.modal--visible .modal-container,
.modal.modal--visible .modal-container {
  animation: slide-down var(--animation-duration) ease 1;
  z-index: 1;
}

.modal:target.modal-animated--zoom-in, .modal:target.modal-animated--zoom-out, .modal.modal--visible.modal-animated--zoom-in, .modal.modal--visible.modal-animated--zoom-out {
  opacity: 1;
  transition: 300ms all ease;
}

.modal:target.modal-animated--zoom-in .modal-content, .modal:target.modal-animated--zoom-out .modal-content, .modal.modal--visible.modal-animated--zoom-in .modal-content, .modal.modal--visible.modal-animated--zoom-out .modal-content {
  transform: scale(1);
  transition: 300ms all ease;
}

.modal.modal-small .modal-content {
  max-width: 20rem; /* 320px */
}

.modal.modal-large .modal-content {
  max-width: 60rem; /* 960px */
}

.modal .modal-content {
  background-color: var(--cirrus-bg);
  padding: 0;
  display: block;
  border-radius: 3px;
  box-shadow: 0 0.4rem 1rem rgba(54, 54, 54, 0.3);
  z-index: 1;
  color: var(--cirrus-fg);
  max-width: 40rem; /* 640px */
  /* Restrict width  */
}

.modal .modal-content.small {
  max-width: 32rem;
}

.modal .modal-content h1, .modal .modal-content h2, .modal .modal-content h3, .modal .modal-content h4, .modal .modal-content h5, .modal .modal-content h6 {
  color: var(--cirrus-fg);
}

.modal .modal-content .modal-header {
  padding: 1rem 2.5rem;
}

.modal .modal-content .modal-header .modal-title {
  font-weight: bolder;
  font-size: 1.4rem;
}

.modal .modal-content .modal-body {
  padding: 1rem 2.5rem;
  overflow-y: auto;
  max-height: 50vh; /* Max height is 50% of viewport height which prevents dialog from extetnding past screen */
  position: relative;
}

.modal .modal-content .modal-footer {
  padding: 1rem 2.5rem;
}

@media screen and (max-width: 767px) {
  .modal .modal-content {
    max-width: 90%;
  }
}
.modal.modal-animated--dropdown {
  animation: slide-down var(--animation-duration) ease 1;
}

.modal.modal-animated--zoom-in, .modal.modal-animated--zoom-out {
  display: flex;
  opacity: 0;
  transition: 300ms all ease;
}

.modal.modal-animated--zoom-in .modal-content {
  transform: scale(0.8);
  transition: 300ms all ease;
}

.modal.modal-animated--zoom-out .modal-content {
  transform: scale(1.2);
  transition: 300ms all ease;
}

/* Keyframes for slide down animation */
@keyframes slide-down {
  0% {
    opacity: 0;
    transform: translateY(-3rem);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
@media screen and (max-width: 767px) {
  .modal-content {
    max-width: 90%;
  }
}
/* PAGINATION */
/* Parent contianer for page navigation */
.pagination {
  display: flex;
  /* Use for the major pagination meant to display about 2 items */
  /* Bordered pagination */
}

.pagination .pagination-item {
  flex: 1 0 50%; /* Flex to fill */
  margin: 1rem 0.1rem;
  transition: all 0.3s;
  /* Pagination Item */
  /* Used to display many pagination items */
}

.pagination .pagination-item:hover p,
.pagination .pagination-item:hover h1, .pagination .pagination-item:hover h2, .pagination .pagination-item:hover h3, .pagination .pagination-item:hover h4, .pagination .pagination-item:hover h5, .pagination .pagination-item:hover h6 {
  transition: all 0.3s;
  color: #f03d4d;
}

.pagination .pagination-item:not(.selected):hover *:not([disabled]):not(.disabled):not(.ellipses) {
  transition: all 0.3s;
  color: #f03d4d;
}

.pagination .pagination-item.pagination-next {
  text-align: right;
}

.pagination .pagination-item.pagination-prev {
  text-align: left;
}

.pagination .pagination-item a {
  color: #495057;
}

.pagination .pagination-item a[disabled], .pagination .pagination-item a.disabled {
  cursor: pointer;
  opacity: 0.5;
  pointer-events: none;
  user-select: none;
}

.pagination .pagination-item .pagination-item-subtitle {
  opacity: 0.7;
  margin: 0; /* Needed to override style after formatting paragraph update */
}

.pagination .pagination-item.short {
  border-radius: 0.1rem;
  margin: 0.2rem 0;
  flex: 0; /* Flex to fill */
  /* Color the selected pagination item with accent color */
}

.pagination .pagination-item.short:first-child a {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.pagination .pagination-item.short:last-child a {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.pagination .pagination-item.short:not([disabled]):not(.disabled):not(.selected):hover {
  background-color: rgba(0, 0, 0, 0.07);
}

.pagination .pagination-item.short.selected {
  background-color: #f03d4d;
  color: #fff;
}

.pagination .pagination-item.short.selected a {
  color: #fff;
}

.pagination .pagination-item.short.ellipses {
  color: #adb5bd;
  pointer-events: none;
}

.pagination .pagination-item.short a {
  display: inline-block;
  line-height: 1.25;
  padding: 0.5rem 0.75rem;
}

.pagination.pagination-bordered .pagination-item.short a {
  border: 1px solid #dee2e6;
  border-radius: 0;
  margin-left: -1px;
}

/* PLACEHOLDER */
/* Designed to be embedded in any parent container as placeholder when content is absent. */
.placeholder {
  background-color: rgba(222, 226, 230, 0.5);
  border-radius: 3px;
  text-align: center;
  padding: 3rem 2rem;
  color: #495057; /* Lighter than original text color */
  display: block;
  /* Place buttons here for the placeholder */
}

.placeholder .placeholder-icon {
  text-align: center;
}

.placeholder .placeholder-title,
.placeholder .placeholder-subtitle {
  margin: 1rem auto;
}

.placeholder .placeholder-subtitle {
  opacity: 0.7;
  margin: 0;
}

.placeholder .placeholder-commands {
  margin-top: 1rem;
}

/* TABS AND TAB CONTAINER */
.tab-container {
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  overflow-x: auto; /* Allow scrollbar to be visible if the width of the tabs exceeds container */
  user-select: none;
  white-space: nowrap;
  align-items: stretch;
  font-size: 1rem;
  /* Shift tabs to the left */
  /* Shift tabs to the center */
  /* Shift tabs to the right */
  /* Depth tab styles */
  /* Classic tab styles */
  /* Tabs that fill the width */
  /* Different tab sizes */
  /* Tabs are embedded inside an unordered list */
  /* Font Awesome Integration */
  /* Left icon */
  /* Right icon */
}

.tab-container.tabs--left ul {
  justify-content: flex-start;
}

.tab-container.tabs--center ul {
  justify-content: center;
}

.tab-container.tabs--right ul {
  justify-content: flex-end;
}

.tab-container.tabs-depth ul {
  box-shadow: 0 2px 3px rgba(134, 142, 150, 0.15);
  border-bottom: 0;
}

.tab-container.tabs-classic ul {
  border-bottom-color: #dee2e6;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-radius: 3px 3px 0 0; /* Only round the top left and right corners */
  transition: all 0.3s;
}

.tab-container.tabs-classic ul li:not(.selected) a:hover, .tab-container.tabs-classic ul li:not(.selected) .tab-item-content:hover {
  background-color: #f1f3f5;
  transition: all 0.3s;
}

.tab-container.tabs-classic ul li.selected a, .tab-container.tabs-classic ul li.selected .tab-item-content {
  color: #f03d4d;
  border-color: #dee2e6;
  border-bottom-color: transparent !important;
}

.tab-container.tabs-classic ul li a,
.tab-container.tabs-classic ul li .tab-item-content {
  border: 1px solid transparent;
  border-bottom-color: #dee2e6;
  border-radius: 3px 3px 0 0;
  cursor: pointer;
  transition: all 0.3s;
}

.tab-container.tabs-fill {
  /* Override the -webkit-box display that sizes tab items based on text */
}

.tab-container.tabs-fill ul {
  display: flex; /* Equal size tabs */
}

.tab-container.tabs-fill li {
  flex-grow: 1;
  flex-shrink: 0; /* Disable shrinking to original width */
  flex-basis: 0;
}

.tab-container.tabs--xs {
  font-size: 0.75rem;
}

.tab-container.tabs--sm {
  font-size: 0.875rem;
}

.tab-container.tabs--lg {
  font-size: 1.25rem;
}

.tab-container.tabs--xl {
  font-size: 1.5rem;
}

.tab-container ul {
  display: flex;
  justify-content: flex-start; /* Move tab items to left side */
  margin: 0.5rem; /* Override the behavior for standard ul and add equal padding */
  border-bottom: 1px solid #e9ecef; /* Bottom border of tabs */
  flex-grow: 1;
  list-style: none;
  padding-inline-start: 0;
}

.tab-container li {
  display: block; /* Keep the tabs from extending too far */
  cursor: pointer;
  margin: 0;
  text-align: center;
}

.tab-container li:hover a, .tab-container li:hover .tab-item-content {
  border-bottom-color: rgba(240, 61, 77, 0.6);
  transition: all 0.3s;
}

.tab-container li.selected a, .tab-container li.selected .tab-item-content {
  border-bottom-color: #f03d4d;
  color: #f03d4d;
  border-width: 2px;
  transition: all 0.3s;
}

.tab-container li a,
.tab-container li .tab-item-content {
  display: flex;
  align-items: center;
  border: 0;
  border-bottom: 1px solid #e9ecef;
  justify-content: center;
  padding: 0.5rem 1rem;
  transition: all 0.3s;
  color: var(--cirrus-fg);
  border-bottom-width: 2px;
  margin-bottom: -2px; /* Get rid of any remaining border lines */
  background-color: var(--cirrus-bg);
}

.tab-container .icon:first-child {
  margin-right: 0.75rem;
}

.tab-container .icon:last-child {
  margin-left: 0.75rem;
}

/* TAGS */
.tag {
  align-items: center;
  background-color: #f6f9fc;
  border-radius: 0.25rem;
  color: currentColor;
  display: inline-flex;
  height: 2em;
  line-height: 1.5;
  font-size: 0.875rem;
  padding: 0.625rem 0.625rem;
  white-space: nowrap;
  /* Delete button for tag */
  /* Draw the delete button */
  /* TAG THEMES */
  /* Tag close button */
}

.tag.tag--xs {
  font-size: 0.75rem;
  padding: 0.5rem 0.5rem;
}

.tag.tag--sm {
  font-size: 0.875rem;
  padding: 0.625rem 0.625rem;
}

.tag.tag--md {
  font-size: 1rem;
  padding: 0.75rem 0.75rem;
}

.tag.tag--lg {
  font-size: 1.25rem;
  padding: 0.875rem 0.875rem;
}

.tag.tag--xl {
  font-size: 1.5rem;
  padding: 1rem 1rem;
}

.tag.tag--rounded {
  border-radius: 290486px;
}

.tag.tag__delete {
  aspect-ratio: 1/1;
  background-color: rgba(10, 10, 10, 0.2);
  border-radius: 290486px;
  cursor: pointer;
  pointer-events: auto;
  display: inline-block;
  height: auto;
  position: relative;
  width: auto;
}

.tag.tag__delete:hover {
  background-color: rgba(10, 10, 10, 0.3);
}

.tag.tag__delete::before, .tag.tag__delete::after {
  background-color: #f6f9fc;
  content: "";
  display: block;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translateX(-50%) translateY(-50%) rotate(45deg);
  transform-origin: center center;
}

.tag.tag__delete::before {
  height: 1px;
  width: 50%;
}

.tag.tag__delete::after {
  height: 50%;
  width: 1px;
}

.tag.tag__delete {
  margin-left: 0.25rem;
  margin-right: -0.125rem;
}

.tag.tag--white {
  background-color: #fff;
  color: #363636;
}

.tag.tag--black {
  background-color: #000;
  color: #fff;
}

.tag.tag--primary {
  background-color: #f03d4d;
  color: #fff;
}

.tag.tag--primary {
  background-color: #f03d4d;
  color: #fff;
  cursor: pointer;
}

.tag.tag--primary.tag__close-btn:hover {
  background-color: #ee2537;
}

.tag.tag--primary.tag__close-btn:active {
  background-color: #e81225;
}

.tag.tag--dark {
  background-color: #363636;
  color: #fff;
  cursor: pointer;
}

.tag.tag--dark.tag__close-btn:hover {
  background-color: #292929;
}

.tag.tag--dark.tag__close-btn:active {
  background-color: #1d1d1d;
}

.tag.tag--link {
  background-color: #5e5cc7;
  color: #fff;
  cursor: pointer;
}

.tag.tag--link.tag__close-btn:hover {
  background-color: #4b49c0;
}

.tag.tag--link.tag__close-btn:active {
  background-color: #403db3;
}

.tag.tag--info {
  background-color: #2972fa;
  color: #fff;
  cursor: pointer;
}

.tag.tag--info.tag__close-btn:hover {
  background-color: #1062f9;
}

.tag.tag--info.tag__close-btn:active {
  background-color: #0555eb;
}

.tag.tag--success {
  background-color: #0dd157;
  color: #fff;
  cursor: pointer;
}

.tag.tag--success.tag__close-btn:hover {
  background-color: #0cb94d;
}

.tag.tag--success.tag__close-btn:active {
  background-color: #0aa143;
}

.tag.tag--warning {
  background-color: #fab633;
  color: #fff;
  cursor: pointer;
}

.tag.tag--warning.tag__close-btn:hover {
  background-color: #f9ad1a;
}

.tag.tag--warning.tag__close-btn:active {
  background-color: #f4a306;
}

.tag.tag--danger {
  background-color: #fb4143;
  color: #fff;
  cursor: pointer;
}

.tag.tag--danger.tag__close-btn:hover {
  background-color: #fa282a;
}

.tag.tag--danger.tag__close-btn:active {
  background-color: #fa0f12;
}

.tag.tag__close-btn {
  position: relative;
  width: 2em;
}

.tag.tag__close-btn::before, .tag.tag__close-btn::after {
  background-color: currentColor;
  content: "";
  display: block;
  left: 50%;
  top: 50%;
  position: absolute;
  transform: translateX(-50%) translateY(-50%) rotate(45deg);
  transform-origin: center center;
}

.tag.tag__close-btn::before {
  height: 1px;
  width: 50%;
}

.tag.tag__close-btn::after {
  height: 50%;
  width: 1px;
}

/* TAG CONTAINER */
.tag-container {
  /* Add margin in between tag containers */
  /* Used for grouping tags together */
  /* Tag positioning */
}

.tag-container:not(:last-child) {
  margin-bottom: 1rem;
}

.tag-container.group-tags {
  --tag-first-border-radius: 0.25rem 0 0 0.25rem;
  --tag-last-border-radius: 0 0.25rem 0.25rem 0;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
}

.tag-container.group-tags .tag {
  margin-right: 0 !important;
}

.tag-container.group-tags .tag:first-child {
  border-radius: var(--tag-first-border-radius);
}

.tag-container.group-tags .tag:not(:first-child):not(:last-child) {
  border-radius: 0;
}

.tag-container.group-tags .tag:last-child {
  border-radius: var(--tag-last-border-radius);
}

.tag-container.group-tags.group-tags--rounded {
  --tag-first-border-radius: 290486px 0 0 290486px;
  --tag-last-border-radius: 0 290486px 290486px 0;
}

.tag-container.tag-container--centered {
  justify-content: center;
}

.tag-container.tag-container--centered .tag {
  margin: 0 0.25rem;
}

.tag-container.tag-container--right {
  justify-content: flex-end;
}

.tag-container.tag-container--right .tag:not(:first-child) {
  margin-left: 0.5rem;
}

.tag-container.tag-container--right .tag:not(:last-child) {
  margin-right: 0;
}

.tag-container .tag {
  margin-bottom: 0.5rem;
}

.tag-container .tag:not(:last-child) {
  margin-right: 0.5rem;
}

/* MISC */
a.tag:hover {
  text-decoration: underline;
}

/* TILES */
.tile {
  display: flex;
  align-content: space-between; /* Ensure space between child elements of card tile */
  overflow: inherit; /* Prevent overflowing when unnecessary */
  /* Override the default behavior where buttons display as blocks */
  /* Add spacing between the elements in between */
}

.tile p {
  font-size: 0.95rem; /* Base font size is 1rem */
}

.tile .tile__icon,
.tile .tile__buttons {
  flex: 0 0 auto;
}

.tile .tile__buttons button,
.tile .tile__buttons .btn,
.tile .tile__buttons [type=submit] {
  display: inline-block;
  margin: 0.1rem; /* Reduce space used by buttons, default .5rem */
}

.tile .tile__container {
  flex: 1 1 auto;
}

.tile .tile__container:not(:first-child) {
  padding-left: 1rem;
}

.tile .tile__container:not(:last-child) {
  padding-right: 1rem;
}

.tile .tile__title {
  line-height: 1.5rem;
  font-weight: bolder;
  margin: 0.1rem auto;
}

.tile .tile__subtitle {
  line-height: 1.25rem;
  opacity: 0.7;
  margin: 0.1rem auto;
}

.toast {
  display: block;
  width: 100%;
  padding: 0.75rem 1.25rem;
  background-color: var(--toast-primary-bg);
  border: 1px solid var(--toast-primary-bg);
  border-radius: 2px;
  color: #fff;
  position: relative;
  margin: 0.5rem;
  /* TOAST TYPES */
}

.toast.toast--translucent {
  opacity: 0.5;
}

.toast.toast--translucent:hover {
  opacity: 1;
}

.toast p {
  margin: 0;
}

.toast a {
  color: #f6f9fc;
  transition: all 0.3s;
}

.toast a:hover {
  opacity: 0.8;
  transition: all 0.3s;
}

.toast .toast__title {
  margin: 0;
  margin-top: 1rem;
}

.toast .btn-close {
  position: absolute;
  right: 1rem;
  top: 1rem;
}

.toast.toast--primary {
  background-color: #f03d4d;
  border-color: #f03d4d;
}

.toast.toast--primary {
  background-color: #f03d4d;
  border-color: #f03d4d;
}

.toast.toast--dark {
  background-color: #363636;
  border-color: #363636;
}

.toast.toast--link {
  background-color: #5e5cc7;
  border-color: #5e5cc7;
}

.toast.toast--info {
  background-color: #2972fa;
  border-color: #2972fa;
}

.toast.toast--success {
  background-color: #0dd157;
  border-color: #0dd157;
}

.toast.toast--warning {
  background-color: #fab633;
  border-color: #fab633;
}

.toast.toast--danger {
  background-color: #fb4143;
  border-color: #fb4143;
}

/* TOOLTIPS */
.tooltip {
  position: relative; /* let's the pseudoelement that we are displaying relative to the button. */
  overflow: visible; /* Allows the pseudoelement to be shown */
  white-space: nowrap; /* Keeps the button text from wrapping and getting too large */
  /* This is the psuedoelement that creates the tooltip */
  /* Handles the on hover event of the button and then modifies the associated tooltip accordingly. */
  /* Tooltip top left */
  /* Tooltip top right */
  /* Tooltip bottom */
  /* Tooltip bottom left */
  /* Tooltip bottom right */
  /* Tooltip right */
  /* Tooltip Left */
}

.tooltip::after {
  position: absolute;
  color: #fff;
  font-size: 0.6rem;
  background-color: rgba(69, 77, 93, 0.9);
  content: attr(data-tooltip); /* Retrieves the data specified in this element property and displays it as text */
  display: block;
  line-height: 1rem;
  text-transform: none;
  overflow: hidden;
  padding: 0.4rem 0.8rem;
  opacity: 0; /* Hide the element */
  text-overflow: ellipsis;
  max-width: 15rem;
  transform: translate(-50%, 0);
  transition: all var(--animation-duration) ease;
  z-index: 200;
  pointer-events: none;
  bottom: 100%; /* Pushes the tooltip above the button */
  left: 50%; /* Horizontally center it */
  border-radius: 0.2rem;
}

.tooltip:focus::after, .tooltip:hover::after {
  opacity: 1;
  transform: translate(-50%, -0.5rem);
  transition: all var(--animation-duration) ease;
}

.tooltip.tooltip--top-left::after {
  transform: translate(-50%, 0);
}

.tooltip.tooltip--top-left:hover::after, .tooltip.tooltip--top-left:focus::after {
  left: 0;
  transform: translate(-100%, -0.5rem);
}

.tooltip.tooltip--top-right::after {
  left: auto;
  transform: translate(15%, 0);
}

.tooltip.tooltip--top-right:hover::after, .tooltip.tooltip--top-right:focus::after {
  right: 0;
  transform: translate(100%, -0.5rem);
}

.tooltip.tooltip--bottom::after {
  top: 100%;
  transform: translate(-50%, -1rem); /* Moves tooltip to the center horizontally and shifts tooltip down */
  bottom: auto;
}

.tooltip.tooltip--bottom:hover::after, .tooltip.tooltip--bottom:focus::after {
  transform: translate(-50%, 0.5rem);
  bottom: auto;
}

.tooltip.tooltip--bottom-left::after {
  top: 100%;
  transform: translate(-65%, -1rem);
  bottom: auto;
}

.tooltip.tooltip--bottom-left:hover::after, .tooltip.tooltip--bottom-left:focus::after {
  left: 0;
  transform: translate(-100%, 0.5rem);
}

.tooltip.tooltip--bottom-right::after {
  left: auto;
  top: 100%;
  transform: translate(0%, -1rem);
  bottom: auto;
}

.tooltip.tooltip--bottom-right:hover::after, .tooltip.tooltip--bottom-right:focus::after {
  right: 0;
  transform: translate(100%, 0.5rem);
}

.tooltip.tooltip--right::after {
  left: 100%;
  bottom: 50%;
  transform: translate(-1rem, 50%);
}

.tooltip.tooltip--right:hover::after, .tooltip.tooltip--right:focus::after {
  transform: translate(0.5rem, 50%);
}

.tooltip.tooltip--left::after {
  right: 100%;
  bottom: 50%;
  left: auto; /* Needed to work */
  transform: translate(1rem, 50%);
}

.tooltip.tooltip--left:hover::after, .tooltip.tooltip--left:focus::after {
  transform: translate(-0.5rem, 50%);
}