.fa {
  font-family: var(--fa-style-family, "Font Awesome 6 Free");
  font-weight: var(--fa-style, 900);
}

.fa, .fa-classic, .fa-sharp, .fas, .fa-solid, .far, .fa-regular, .fab, .fa-brands {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: var(--fa-display, inline-block);
  font-variant: normal;
  text-rendering: auto;
  font-style: normal;
  line-height: 1;
}

.fas, .fa-classic, .fa-solid, .far, .fa-regular {
  font-family: "Font Awesome 6 Free";
}

.fab, .fa-brands {
  font-family: "Font Awesome 6 Brands";
}

.fa-1x {
  font-size: 1em;
}

.fa-2x {
  font-size: 2em;
}

.fa-3x {
  font-size: 3em;
}

.fa-4x {
  font-size: 4em;
}

.fa-5x {
  font-size: 5em;
}

.fa-6x {
  font-size: 6em;
}

.fa-7x {
  font-size: 7em;
}

.fa-8x {
  font-size: 8em;
}

.fa-9x {
  font-size: 9em;
}

.fa-10x {
  font-size: 10em;
}

.fa-2xs {
  vertical-align: .225em;
  font-size: .625em;
  line-height: .1em;
}

.fa-xs {
  vertical-align: .125em;
  font-size: .75em;
  line-height: .08333em;
}

.fa-sm {
  vertical-align: .05357em;
  font-size: .875em;
  line-height: .07143em;
}

.fa-lg {
  vertical-align: -.075em;
  font-size: 1.25em;
  line-height: .05em;
}

.fa-xl {
  vertical-align: -.125em;
  font-size: 1.5em;
  line-height: .04167em;
}

.fa-2xl {
  vertical-align: -.1875em;
  font-size: 2em;
  line-height: .03125em;
}

.fa-fw {
  text-align: center;
  width: 1.25em;
}

.fa-ul {
  margin-left: var(--fa-li-margin, 2.5em);
  padding-left: 0;
  list-style-type: none;
}

.fa-ul > li {
  position: relative;
}

.fa-li {
  left: calc(var(--fa-li-width, 2em) * -1);
  text-align: center;
  width: var(--fa-li-width, 2em);
  line-height: inherit;
  position: absolute;
}

.fa-border {
  border-color: var(--fa-border-color, #eee);
  border-radius: var(--fa-border-radius, .1em);
  border-style: var(--fa-border-style, solid);
  border-width: var(--fa-border-width, .08em);
  padding: var(--fa-border-padding, .2em .25em .15em);
}

.fa-pull-left {
  float: left;
  margin-right: var(--fa-pull-margin, .3em);
}

.fa-pull-right {
  float: right;
  margin-left: var(--fa-pull-margin, .3em);
}

.fa-beat {
  animation-name: fa-beat;
  -webkit-animation-delay: var(--fa-animation-delay, 0s);
  animation-delay: var(--fa-animation-delay, 0s);
  -webkit-animation-direction: var(--fa-animation-direction, normal);
  animation-direction: var(--fa-animation-direction, normal);
  -webkit-animation-duration: var(--fa-animation-duration, 1s);
  animation-duration: var(--fa-animation-duration, 1s);
  -webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  -webkit-animation-timing-function: var(--fa-animation-timing, ease-in-out);
  animation-timing-function: var(--fa-animation-timing, ease-in-out);
}

.fa-bounce {
  animation-name: fa-bounce;
  -webkit-animation-delay: var(--fa-animation-delay, 0s);
  animation-delay: var(--fa-animation-delay, 0s);
  -webkit-animation-direction: var(--fa-animation-direction, normal);
  animation-direction: var(--fa-animation-direction, normal);
  -webkit-animation-duration: var(--fa-animation-duration, 1s);
  animation-duration: var(--fa-animation-duration, 1s);
  -webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  -webkit-animation-timing-function: var(--fa-animation-timing, cubic-bezier(.28, .84, .42, 1));
  animation-timing-function: var(--fa-animation-timing, cubic-bezier(.28, .84, .42, 1));
}

.fa-fade {
  animation-name: fa-fade;
  -webkit-animation-delay: var(--fa-animation-delay, 0s);
  animation-delay: var(--fa-animation-delay, 0s);
  -webkit-animation-direction: var(--fa-animation-direction, normal);
  animation-direction: var(--fa-animation-direction, normal);
  -webkit-animation-duration: var(--fa-animation-duration, 1s);
  animation-duration: var(--fa-animation-duration, 1s);
  -webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  -webkit-animation-timing-function: var(--fa-animation-timing, cubic-bezier(.4, 0, .6, 1));
  animation-timing-function: var(--fa-animation-timing, cubic-bezier(.4, 0, .6, 1));
}

.fa-beat-fade {
  animation-name: fa-beat-fade;
  -webkit-animation-delay: var(--fa-animation-delay, 0s);
  animation-delay: var(--fa-animation-delay, 0s);
  -webkit-animation-direction: var(--fa-animation-direction, normal);
  animation-direction: var(--fa-animation-direction, normal);
  -webkit-animation-duration: var(--fa-animation-duration, 1s);
  animation-duration: var(--fa-animation-duration, 1s);
  -webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  -webkit-animation-timing-function: var(--fa-animation-timing, cubic-bezier(.4, 0, .6, 1));
  animation-timing-function: var(--fa-animation-timing, cubic-bezier(.4, 0, .6, 1));
}

.fa-flip {
  animation-name: fa-flip;
  -webkit-animation-delay: var(--fa-animation-delay, 0s);
  animation-delay: var(--fa-animation-delay, 0s);
  -webkit-animation-direction: var(--fa-animation-direction, normal);
  animation-direction: var(--fa-animation-direction, normal);
  -webkit-animation-duration: var(--fa-animation-duration, 1s);
  animation-duration: var(--fa-animation-duration, 1s);
  -webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  -webkit-animation-timing-function: var(--fa-animation-timing, ease-in-out);
  animation-timing-function: var(--fa-animation-timing, ease-in-out);
}

.fa-shake {
  animation-name: fa-shake;
  -webkit-animation-delay: var(--fa-animation-delay, 0s);
  animation-delay: var(--fa-animation-delay, 0s);
  -webkit-animation-direction: var(--fa-animation-direction, normal);
  animation-direction: var(--fa-animation-direction, normal);
  -webkit-animation-duration: var(--fa-animation-duration, 1s);
  animation-duration: var(--fa-animation-duration, 1s);
  -webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  -webkit-animation-timing-function: var(--fa-animation-timing, linear);
  animation-timing-function: var(--fa-animation-timing, linear);
}

.fa-spin {
  animation-name: fa-spin;
  -webkit-animation-delay: var(--fa-animation-delay, 0s);
  animation-delay: var(--fa-animation-delay, 0s);
  -webkit-animation-direction: var(--fa-animation-direction, normal);
  animation-direction: var(--fa-animation-direction, normal);
  -webkit-animation-duration: var(--fa-animation-duration, 2s);
  animation-duration: var(--fa-animation-duration, 2s);
  -webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  -webkit-animation-timing-function: var(--fa-animation-timing, linear);
  animation-timing-function: var(--fa-animation-timing, linear);
}

.fa-spin-reverse {
  --fa-animation-direction: reverse;
}

.fa-pulse, .fa-spin-pulse {
  animation-name: fa-spin;
  -webkit-animation-direction: var(--fa-animation-direction, normal);
  animation-direction: var(--fa-animation-direction, normal);
  -webkit-animation-duration: var(--fa-animation-duration, 1s);
  animation-duration: var(--fa-animation-duration, 1s);
  -webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  -webkit-animation-timing-function: var(--fa-animation-timing, steps(8));
  animation-timing-function: var(--fa-animation-timing, steps(8));
}

@media (prefers-reduced-motion: reduce) {
  .fa-beat, .fa-bounce, .fa-fade, .fa-beat-fade, .fa-flip, .fa-pulse, .fa-shake, .fa-spin, .fa-spin-pulse {
    transition-duration: 0s;
    transition-delay: 0s;
    animation-duration: 1ms;
    animation-iteration-count: 1;
    animation-delay: -1ms;
  }
}

@keyframes fa-beat {
  0%, 90% {
    transform: scale(1);
  }

  45% {
    -webkit-transform: scale(var(--fa-beat-scale, 1.25));
    transform: scale(var(--fa-beat-scale, 1.25));
  }
}

@keyframes fa-bounce {
  0% {
    transform: scale(1)translateY(0);
  }

  10% {
    -webkit-transform: scale(var(--fa-bounce-start-scale-x, 1.1), var(--fa-bounce-start-scale-y, .9)) translateY(0);
    transform: scale(var(--fa-bounce-start-scale-x, 1.1), var(--fa-bounce-start-scale-y, .9)) translateY(0);
  }

  30% {
    -webkit-transform: scale(var(--fa-bounce-jump-scale-x, .9), var(--fa-bounce-jump-scale-y, 1.1)) translateY(var(--fa-bounce-height, -.5em));
    transform: scale(var(--fa-bounce-jump-scale-x, .9), var(--fa-bounce-jump-scale-y, 1.1)) translateY(var(--fa-bounce-height, -.5em));
  }

  50% {
    -webkit-transform: scale(var(--fa-bounce-land-scale-x, 1.05), var(--fa-bounce-land-scale-y, .95)) translateY(0);
    transform: scale(var(--fa-bounce-land-scale-x, 1.05), var(--fa-bounce-land-scale-y, .95)) translateY(0);
  }

  57% {
    -webkit-transform: scale(1, 1) translateY(var(--fa-bounce-rebound, -.125em));
    transform: scale(1, 1) translateY(var(--fa-bounce-rebound, -.125em));
  }

  64% {
    transform: scale(1)translateY(0);
  }

  100% {
    transform: scale(1)translateY(0);
  }
}

@keyframes fa-fade {
  50% {
    opacity: var(--fa-fade-opacity, .4);
  }
}

@keyframes fa-beat-fade {
  0%, 100% {
    opacity: var(--fa-beat-fade-opacity, .4);
    transform: scale(1);
  }

  50% {
    opacity: 1;
    -webkit-transform: scale(var(--fa-beat-fade-scale, 1.125));
    transform: scale(var(--fa-beat-fade-scale, 1.125));
  }
}

@keyframes fa-flip {
  50% {
    -webkit-transform: rotate3d(var(--fa-flip-x, 0), var(--fa-flip-y, 1), var(--fa-flip-z, 0), var(--fa-flip-angle, -180deg));
    transform: rotate3d(var(--fa-flip-x, 0), var(--fa-flip-y, 1), var(--fa-flip-z, 0), var(--fa-flip-angle, -180deg));
  }
}

@keyframes fa-shake {
  0% {
    transform: rotate(-15deg);
  }

  4% {
    transform: rotate(15deg);
  }

  8%, 24% {
    transform: rotate(-18deg);
  }

  12%, 28% {
    transform: rotate(18deg);
  }

  16% {
    transform: rotate(-22deg);
  }

  20% {
    transform: rotate(22deg);
  }

  32% {
    transform: rotate(-12deg);
  }

  36% {
    transform: rotate(12deg);
  }

  40%, 100% {
    transform: rotate(0);
  }
}

@keyframes fa-spin {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.fa-rotate-90 {
  transform: rotate(90deg);
}

.fa-rotate-180 {
  transform: rotate(180deg);
}

.fa-rotate-270 {
  transform: rotate(270deg);
}

.fa-flip-horizontal {
  transform: scale(-1, 1);
}

.fa-flip-vertical {
  transform: scale(1, -1);
}

.fa-flip-both, .fa-flip-horizontal.fa-flip-vertical {
  transform: scale(-1);
}

.fa-rotate-by {
  -webkit-transform: rotate(var(--fa-rotate-angle, none));
  transform: rotate(var(--fa-rotate-angle, none));
}

.fa-stack {
  height: 2em;
  vertical-align: middle;
  width: 2.5em;
  line-height: 2em;
  display: inline-block;
  position: relative;
}

.fa-stack-1x, .fa-stack-2x {
  text-align: center;
  width: 100%;
  z-index: var(--fa-stack-z-index, auto);
  position: absolute;
  left: 0;
}

.fa-stack-1x {
  line-height: inherit;
}

.fa-stack-2x {
  font-size: 2em;
}

.fa-inverse {
  color: var(--fa-inverse, #fff);
}

.fa-0:before {
  content: "0";
}

.fa-1:before {
  content: "1";
}

.fa-2:before {
  content: "2";
}

.fa-3:before {
  content: "3";
}

.fa-4:before {
  content: "4";
}

.fa-5:before {
  content: "5";
}

.fa-6:before {
  content: "6";
}

.fa-7:before {
  content: "7";
}

.fa-8:before {
  content: "8";
}

.fa-9:before {
  content: "9";
}

.fa-fill-drip:before {
  content: "";
}

.fa-arrows-to-circle:before {
  content: "";
}

.fa-circle-chevron-right:before, .fa-chevron-circle-right:before {
  content: "";
}

.fa-at:before {
  content: "@";
}

.fa-trash-can:before, .fa-trash-alt:before {
  content: "";
}

.fa-text-height:before {
  content: "";
}

.fa-user-xmark:before, .fa-user-times:before {
  content: "";
}

.fa-stethoscope:before {
  content: "";
}

.fa-message:before, .fa-comment-alt:before {
  content: "";
}

.fa-info:before {
  content: "";
}

.fa-down-left-and-up-right-to-center:before, .fa-compress-alt:before {
  content: "";
}

.fa-explosion:before {
  content: "";
}

.fa-file-lines:before, .fa-file-alt:before, .fa-file-text:before {
  content: "";
}

.fa-wave-square:before {
  content: "";
}

.fa-ring:before {
  content: "";
}

.fa-building-un:before {
  content: "";
}

.fa-dice-three:before {
  content: "";
}

.fa-calendar-days:before, .fa-calendar-alt:before {
  content: "";
}

.fa-anchor-circle-check:before {
  content: "";
}

.fa-building-circle-arrow-right:before {
  content: "";
}

.fa-volleyball:before, .fa-volleyball-ball:before {
  content: "";
}

.fa-arrows-up-to-line:before {
  content: "";
}

.fa-sort-down:before, .fa-sort-desc:before {
  content: "";
}

.fa-circle-minus:before, .fa-minus-circle:before {
  content: "";
}

.fa-door-open:before {
  content: "";
}

.fa-right-from-bracket:before, .fa-sign-out-alt:before {
  content: "";
}

.fa-atom:before {
  content: "";
}

.fa-soap:before {
  content: "";
}

.fa-icons:before, .fa-heart-music-camera-bolt:before {
  content: "";
}

.fa-microphone-lines-slash:before, .fa-microphone-alt-slash:before {
  content: "";
}

.fa-bridge-circle-check:before {
  content: "";
}

.fa-pump-medical:before {
  content: "";
}

.fa-fingerprint:before {
  content: "";
}

.fa-hand-point-right:before {
  content: "";
}

.fa-magnifying-glass-location:before, .fa-search-location:before {
  content: "";
}

.fa-forward-step:before, .fa-step-forward:before {
  content: "";
}

.fa-face-smile-beam:before, .fa-smile-beam:before {
  content: "";
}

.fa-flag-checkered:before {
  content: "";
}

.fa-football:before, .fa-football-ball:before {
  content: "";
}

.fa-school-circle-exclamation:before {
  content: "";
}

.fa-crop:before {
  content: "";
}

.fa-angles-down:before, .fa-angle-double-down:before {
  content: "";
}

.fa-users-rectangle:before {
  content: "";
}

.fa-people-roof:before {
  content: "";
}

.fa-people-line:before {
  content: "";
}

.fa-beer-mug-empty:before, .fa-beer:before {
  content: "";
}

.fa-diagram-predecessor:before {
  content: "";
}

.fa-arrow-up-long:before, .fa-long-arrow-up:before {
  content: "";
}

.fa-fire-flame-simple:before, .fa-burn:before {
  content: "";
}

.fa-person:before, .fa-male:before {
  content: "";
}

.fa-laptop:before {
  content: "";
}

.fa-file-csv:before {
  content: "";
}

.fa-menorah:before {
  content: "";
}

.fa-truck-plane:before {
  content: "";
}

.fa-record-vinyl:before {
  content: "";
}

.fa-face-grin-stars:before, .fa-grin-stars:before {
  content: "";
}

.fa-bong:before {
  content: "";
}

.fa-spaghetti-monster-flying:before, .fa-pastafarianism:before {
  content: "";
}

.fa-arrow-down-up-across-line:before {
  content: "";
}

.fa-spoon:before, .fa-utensil-spoon:before {
  content: "";
}

.fa-jar-wheat:before {
  content: "";
}

.fa-envelopes-bulk:before, .fa-mail-bulk:before {
  content: "";
}

.fa-file-circle-exclamation:before {
  content: "";
}

.fa-circle-h:before, .fa-hospital-symbol:before {
  content: "";
}

.fa-pager:before {
  content: "";
}

.fa-address-book:before, .fa-contact-book:before {
  content: "";
}

.fa-strikethrough:before {
  content: "";
}

.fa-k:before {
  content: "K";
}

.fa-landmark-flag:before {
  content: "";
}

.fa-pencil:before, .fa-pencil-alt:before {
  content: "";
}

.fa-backward:before {
  content: "";
}

.fa-caret-right:before {
  content: "";
}

.fa-comments:before {
  content: "";
}

.fa-paste:before, .fa-file-clipboard:before {
  content: "";
}

.fa-code-pull-request:before {
  content: "";
}

.fa-clipboard-list:before {
  content: "";
}

.fa-truck-ramp-box:before, .fa-truck-loading:before {
  content: "";
}

.fa-user-check:before {
  content: "";
}

.fa-vial-virus:before {
  content: "";
}

.fa-sheet-plastic:before {
  content: "";
}

.fa-blog:before {
  content: "";
}

.fa-user-ninja:before {
  content: "";
}

.fa-person-arrow-up-from-line:before {
  content: "";
}

.fa-scroll-torah:before, .fa-torah:before {
  content: "";
}

.fa-broom-ball:before, .fa-quidditch:before, .fa-quidditch-broom-ball:before {
  content: "";
}

.fa-toggle-off:before {
  content: "";
}

.fa-box-archive:before, .fa-archive:before {
  content: "";
}

.fa-person-drowning:before {
  content: "";
}

.fa-arrow-down-9-1:before, .fa-sort-numeric-desc:before, .fa-sort-numeric-down-alt:before {
  content: "";
}

.fa-face-grin-tongue-squint:before, .fa-grin-tongue-squint:before {
  content: "";
}

.fa-spray-can:before {
  content: "";
}

.fa-truck-monster:before {
  content: "";
}

.fa-w:before {
  content: "W";
}

.fa-earth-africa:before, .fa-globe-africa:before {
  content: "";
}

.fa-rainbow:before {
  content: "";
}

.fa-circle-notch:before {
  content: "";
}

.fa-tablet-screen-button:before, .fa-tablet-alt:before {
  content: "";
}

.fa-paw:before {
  content: "";
}

.fa-cloud:before {
  content: "";
}

.fa-trowel-bricks:before {
  content: "";
}

.fa-face-flushed:before, .fa-flushed:before {
  content: "";
}

.fa-hospital-user:before {
  content: "";
}

.fa-tent-arrow-left-right:before {
  content: "";
}

.fa-gavel:before, .fa-legal:before {
  content: "";
}

.fa-binoculars:before {
  content: "";
}

.fa-microphone-slash:before {
  content: "";
}

.fa-box-tissue:before {
  content: "";
}

.fa-motorcycle:before {
  content: "";
}

.fa-bell-concierge:before, .fa-concierge-bell:before {
  content: "";
}

.fa-pen-ruler:before, .fa-pencil-ruler:before {
  content: "";
}

.fa-people-arrows:before, .fa-people-arrows-left-right:before {
  content: "";
}

.fa-mars-and-venus-burst:before {
  content: "";
}

.fa-square-caret-right:before, .fa-caret-square-right:before {
  content: "";
}

.fa-scissors:before, .fa-cut:before {
  content: "";
}

.fa-sun-plant-wilt:before {
  content: "";
}

.fa-toilets-portable:before {
  content: "";
}

.fa-hockey-puck:before {
  content: "";
}

.fa-table:before {
  content: "";
}

.fa-magnifying-glass-arrow-right:before {
  content: "";
}

.fa-tachograph-digital:before, .fa-digital-tachograph:before {
  content: "";
}

.fa-users-slash:before {
  content: "";
}

.fa-clover:before {
  content: "";
}

.fa-reply:before, .fa-mail-reply:before {
  content: "";
}

.fa-star-and-crescent:before {
  content: "";
}

.fa-house-fire:before {
  content: "";
}

.fa-square-minus:before, .fa-minus-square:before {
  content: "";
}

.fa-helicopter:before {
  content: "";
}

.fa-compass:before {
  content: "";
}

.fa-square-caret-down:before, .fa-caret-square-down:before {
  content: "";
}

.fa-file-circle-question:before {
  content: "";
}

.fa-laptop-code:before {
  content: "";
}

.fa-swatchbook:before {
  content: "";
}

.fa-prescription-bottle:before {
  content: "";
}

.fa-bars:before, .fa-navicon:before {
  content: "";
}

.fa-people-group:before {
  content: "";
}

.fa-hourglass-end:before, .fa-hourglass-3:before {
  content: "";
}

.fa-heart-crack:before, .fa-heart-broken:before {
  content: "";
}

.fa-square-up-right:before, .fa-external-link-square-alt:before {
  content: "";
}

.fa-face-kiss-beam:before, .fa-kiss-beam:before {
  content: "";
}

.fa-film:before {
  content: "";
}

.fa-ruler-horizontal:before {
  content: "";
}

.fa-people-robbery:before {
  content: "";
}

.fa-lightbulb:before {
  content: "";
}

.fa-caret-left:before {
  content: "";
}

.fa-circle-exclamation:before, .fa-exclamation-circle:before {
  content: "";
}

.fa-school-circle-xmark:before {
  content: "";
}

.fa-arrow-right-from-bracket:before, .fa-sign-out:before {
  content: "";
}

.fa-circle-chevron-down:before, .fa-chevron-circle-down:before {
  content: "";
}

.fa-unlock-keyhole:before, .fa-unlock-alt:before {
  content: "";
}

.fa-cloud-showers-heavy:before {
  content: "";
}

.fa-headphones-simple:before, .fa-headphones-alt:before {
  content: "";
}

.fa-sitemap:before {
  content: "";
}

.fa-circle-dollar-to-slot:before, .fa-donate:before {
  content: "";
}

.fa-memory:before {
  content: "";
}

.fa-road-spikes:before {
  content: "";
}

.fa-fire-burner:before {
  content: "";
}

.fa-flag:before {
  content: "";
}

.fa-hanukiah:before {
  content: "";
}

.fa-feather:before {
  content: "";
}

.fa-volume-low:before, .fa-volume-down:before {
  content: "";
}

.fa-comment-slash:before {
  content: "";
}

.fa-cloud-sun-rain:before {
  content: "";
}

.fa-compress:before {
  content: "";
}

.fa-wheat-awn:before, .fa-wheat-alt:before {
  content: "";
}

.fa-ankh:before {
  content: "";
}

.fa-hands-holding-child:before {
  content: "";
}

.fa-asterisk:before {
  content: "*";
}

.fa-square-check:before, .fa-check-square:before {
  content: "";
}

.fa-peseta-sign:before {
  content: "";
}

.fa-heading:before, .fa-header:before {
  content: "";
}

.fa-ghost:before {
  content: "";
}

.fa-list:before, .fa-list-squares:before {
  content: "";
}

.fa-square-phone-flip:before, .fa-phone-square-alt:before {
  content: "";
}

.fa-cart-plus:before {
  content: "";
}

.fa-gamepad:before {
  content: "";
}

.fa-circle-dot:before, .fa-dot-circle:before {
  content: "";
}

.fa-face-dizzy:before, .fa-dizzy:before {
  content: "";
}

.fa-egg:before {
  content: "";
}

.fa-house-medical-circle-xmark:before {
  content: "";
}

.fa-campground:before {
  content: "";
}

.fa-folder-plus:before {
  content: "";
}

.fa-futbol:before, .fa-futbol-ball:before, .fa-soccer-ball:before {
  content: "";
}

.fa-paintbrush:before, .fa-paint-brush:before {
  content: "";
}

.fa-lock:before {
  content: "";
}

.fa-gas-pump:before {
  content: "";
}

.fa-hot-tub-person:before, .fa-hot-tub:before {
  content: "";
}

.fa-map-location:before, .fa-map-marked:before {
  content: "";
}

.fa-house-flood-water:before {
  content: "";
}

.fa-tree:before {
  content: "";
}

.fa-bridge-lock:before {
  content: "";
}

.fa-sack-dollar:before {
  content: "";
}

.fa-pen-to-square:before, .fa-edit:before {
  content: "";
}

.fa-car-side:before {
  content: "";
}

.fa-share-nodes:before, .fa-share-alt:before {
  content: "";
}

.fa-heart-circle-minus:before {
  content: "";
}

.fa-hourglass-half:before, .fa-hourglass-2:before {
  content: "";
}

.fa-microscope:before {
  content: "";
}

.fa-sink:before {
  content: "";
}

.fa-bag-shopping:before, .fa-shopping-bag:before {
  content: "";
}

.fa-arrow-down-z-a:before, .fa-sort-alpha-desc:before, .fa-sort-alpha-down-alt:before {
  content: "";
}

.fa-mitten:before {
  content: "";
}

.fa-person-rays:before {
  content: "";
}

.fa-users:before {
  content: "";
}

.fa-eye-slash:before {
  content: "";
}

.fa-flask-vial:before {
  content: "";
}

.fa-hand:before, .fa-hand-paper:before {
  content: "";
}

.fa-om:before {
  content: "";
}

.fa-worm:before {
  content: "";
}

.fa-house-circle-xmark:before {
  content: "";
}

.fa-plug:before {
  content: "";
}

.fa-chevron-up:before {
  content: "";
}

.fa-hand-spock:before {
  content: "";
}

.fa-stopwatch:before {
  content: "";
}

.fa-face-kiss:before, .fa-kiss:before {
  content: "";
}

.fa-bridge-circle-xmark:before {
  content: "";
}

.fa-face-grin-tongue:before, .fa-grin-tongue:before {
  content: "";
}

.fa-chess-bishop:before {
  content: "";
}

.fa-face-grin-wink:before, .fa-grin-wink:before {
  content: "";
}

.fa-ear-deaf:before, .fa-deaf:before, .fa-deafness:before, .fa-hard-of-hearing:before {
  content: "";
}

.fa-road-circle-check:before {
  content: "";
}

.fa-dice-five:before {
  content: "";
}

.fa-square-rss:before, .fa-rss-square:before {
  content: "";
}

.fa-land-mine-on:before {
  content: "";
}

.fa-i-cursor:before {
  content: "";
}

.fa-stamp:before {
  content: "";
}

.fa-stairs:before {
  content: "";
}

.fa-i:before {
  content: "I";
}

.fa-hryvnia-sign:before, .fa-hryvnia:before {
  content: "";
}

.fa-pills:before {
  content: "";
}

.fa-face-grin-wide:before, .fa-grin-alt:before {
  content: "";
}

.fa-tooth:before {
  content: "";
}

.fa-v:before {
  content: "V";
}

.fa-bangladeshi-taka-sign:before {
  content: "";
}

.fa-bicycle:before {
  content: "";
}

.fa-staff-snake:before, .fa-rod-asclepius:before, .fa-rod-snake:before, .fa-staff-aesculapius:before {
  content: "";
}

.fa-head-side-cough-slash:before {
  content: "";
}

.fa-truck-medical:before, .fa-ambulance:before {
  content: "";
}

.fa-wheat-awn-circle-exclamation:before {
  content: "";
}

.fa-snowman:before {
  content: "";
}

.fa-mortar-pestle:before {
  content: "";
}

.fa-road-barrier:before {
  content: "";
}

.fa-school:before {
  content: "";
}

.fa-igloo:before {
  content: "";
}

.fa-joint:before {
  content: "";
}

.fa-angle-right:before {
  content: "";
}

.fa-horse:before {
  content: "";
}

.fa-q:before {
  content: "Q";
}

.fa-g:before {
  content: "G";
}

.fa-notes-medical:before {
  content: "";
}

.fa-temperature-half:before, .fa-temperature-2:before, .fa-thermometer-2:before, .fa-thermometer-half:before {
  content: "";
}

.fa-dong-sign:before {
  content: "";
}

.fa-capsules:before {
  content: "";
}

.fa-poo-storm:before, .fa-poo-bolt:before {
  content: "";
}

.fa-face-frown-open:before, .fa-frown-open:before {
  content: "";
}

.fa-hand-point-up:before {
  content: "";
}

.fa-money-bill:before {
  content: "";
}

.fa-bookmark:before {
  content: "";
}

.fa-align-justify:before {
  content: "";
}

.fa-umbrella-beach:before {
  content: "";
}

.fa-helmet-un:before {
  content: "";
}

.fa-bullseye:before {
  content: "";
}

.fa-bacon:before {
  content: "";
}

.fa-hand-point-down:before {
  content: "";
}

.fa-arrow-up-from-bracket:before {
  content: "";
}

.fa-folder:before, .fa-folder-blank:before {
  content: "";
}

.fa-file-waveform:before, .fa-file-medical-alt:before {
  content: "";
}

.fa-radiation:before {
  content: "";
}

.fa-chart-simple:before {
  content: "";
}

.fa-mars-stroke:before {
  content: "";
}

.fa-vial:before {
  content: "";
}

.fa-gauge:before, .fa-dashboard:before, .fa-gauge-med:before, .fa-tachometer-alt-average:before {
  content: "";
}

.fa-wand-magic-sparkles:before, .fa-magic-wand-sparkles:before {
  content: "";
}

.fa-e:before {
  content: "E";
}

.fa-pen-clip:before, .fa-pen-alt:before {
  content: "";
}

.fa-bridge-circle-exclamation:before {
  content: "";
}

.fa-user:before {
  content: "";
}

.fa-school-circle-check:before {
  content: "";
}

.fa-dumpster:before {
  content: "";
}

.fa-van-shuttle:before, .fa-shuttle-van:before {
  content: "";
}

.fa-building-user:before {
  content: "";
}

.fa-square-caret-left:before, .fa-caret-square-left:before {
  content: "";
}

.fa-highlighter:before {
  content: "";
}

.fa-key:before {
  content: "";
}

.fa-bullhorn:before {
  content: "";
}

.fa-globe:before {
  content: "";
}

.fa-synagogue:before {
  content: "";
}

.fa-person-half-dress:before {
  content: "";
}

.fa-road-bridge:before {
  content: "";
}

.fa-location-arrow:before {
  content: "";
}

.fa-c:before {
  content: "C";
}

.fa-tablet-button:before {
  content: "";
}

.fa-building-lock:before {
  content: "";
}

.fa-pizza-slice:before {
  content: "";
}

.fa-money-bill-wave:before {
  content: "";
}

.fa-chart-area:before, .fa-area-chart:before {
  content: "";
}

.fa-house-flag:before {
  content: "";
}

.fa-person-circle-minus:before {
  content: "";
}

.fa-ban:before, .fa-cancel:before {
  content: "";
}

.fa-camera-rotate:before {
  content: "";
}

.fa-spray-can-sparkles:before, .fa-air-freshener:before {
  content: "";
}

.fa-star:before {
  content: "";
}

.fa-repeat:before {
  content: "";
}

.fa-cross:before {
  content: "";
}

.fa-box:before {
  content: "";
}

.fa-venus-mars:before {
  content: "";
}

.fa-arrow-pointer:before, .fa-mouse-pointer:before {
  content: "";
}

.fa-maximize:before, .fa-expand-arrows-alt:before {
  content: "";
}

.fa-charging-station:before {
  content: "";
}

.fa-shapes:before, .fa-triangle-circle-square:before {
  content: "";
}

.fa-shuffle:before, .fa-random:before {
  content: "";
}

.fa-person-running:before, .fa-running:before {
  content: "";
}

.fa-mobile-retro:before {
  content: "";
}

.fa-grip-lines-vertical:before {
  content: "";
}

.fa-spider:before {
  content: "";
}

.fa-hands-bound:before {
  content: "";
}

.fa-file-invoice-dollar:before {
  content: "";
}

.fa-plane-circle-exclamation:before {
  content: "";
}

.fa-x-ray:before {
  content: "";
}

.fa-spell-check:before {
  content: "";
}

.fa-slash:before {
  content: "";
}

.fa-computer-mouse:before, .fa-mouse:before {
  content: "";
}

.fa-arrow-right-to-bracket:before, .fa-sign-in:before {
  content: "";
}

.fa-shop-slash:before, .fa-store-alt-slash:before {
  content: "";
}

.fa-server:before {
  content: "";
}

.fa-virus-covid-slash:before {
  content: "";
}

.fa-shop-lock:before {
  content: "";
}

.fa-hourglass-start:before, .fa-hourglass-1:before {
  content: "";
}

.fa-blender-phone:before {
  content: "";
}

.fa-building-wheat:before {
  content: "";
}

.fa-person-breastfeeding:before {
  content: "";
}

.fa-right-to-bracket:before, .fa-sign-in-alt:before {
  content: "";
}

.fa-venus:before {
  content: "";
}

.fa-passport:before {
  content: "";
}

.fa-heart-pulse:before, .fa-heartbeat:before {
  content: "";
}

.fa-people-carry-box:before, .fa-people-carry:before {
  content: "";
}

.fa-temperature-high:before {
  content: "";
}

.fa-microchip:before {
  content: "";
}

.fa-crown:before {
  content: "";
}

.fa-weight-hanging:before {
  content: "";
}

.fa-xmarks-lines:before {
  content: "";
}

.fa-file-prescription:before {
  content: "";
}

.fa-weight-scale:before, .fa-weight:before {
  content: "";
}

.fa-user-group:before, .fa-user-friends:before {
  content: "";
}

.fa-arrow-up-a-z:before, .fa-sort-alpha-up:before {
  content: "";
}

.fa-chess-knight:before {
  content: "";
}

.fa-face-laugh-squint:before, .fa-laugh-squint:before {
  content: "";
}

.fa-wheelchair:before {
  content: "";
}

.fa-circle-arrow-up:before, .fa-arrow-circle-up:before {
  content: "";
}

.fa-toggle-on:before {
  content: "";
}

.fa-person-walking:before, .fa-walking:before {
  content: "";
}

.fa-l:before {
  content: "L";
}

.fa-fire:before {
  content: "";
}

.fa-bed-pulse:before, .fa-procedures:before {
  content: "";
}

.fa-shuttle-space:before, .fa-space-shuttle:before {
  content: "";
}

.fa-face-laugh:before, .fa-laugh:before {
  content: "";
}

.fa-folder-open:before {
  content: "";
}

.fa-heart-circle-plus:before {
  content: "";
}

.fa-code-fork:before {
  content: "";
}

.fa-city:before {
  content: "";
}

.fa-microphone-lines:before, .fa-microphone-alt:before {
  content: "";
}

.fa-pepper-hot:before {
  content: "";
}

.fa-unlock:before {
  content: "";
}

.fa-colon-sign:before {
  content: "";
}

.fa-headset:before {
  content: "";
}

.fa-store-slash:before {
  content: "";
}

.fa-road-circle-xmark:before {
  content: "";
}

.fa-user-minus:before {
  content: "";
}

.fa-mars-stroke-up:before, .fa-mars-stroke-v:before {
  content: "";
}

.fa-champagne-glasses:before, .fa-glass-cheers:before {
  content: "";
}

.fa-clipboard:before {
  content: "";
}

.fa-house-circle-exclamation:before {
  content: "";
}

.fa-file-arrow-up:before, .fa-file-upload:before {
  content: "";
}

.fa-wifi:before, .fa-wifi-3:before, .fa-wifi-strong:before {
  content: "";
}

.fa-bath:before, .fa-bathtub:before {
  content: "";
}

.fa-underline:before {
  content: "";
}

.fa-user-pen:before, .fa-user-edit:before {
  content: "";
}

.fa-signature:before {
  content: "";
}

.fa-stroopwafel:before {
  content: "";
}

.fa-bold:before {
  content: "";
}

.fa-anchor-lock:before {
  content: "";
}

.fa-building-ngo:before {
  content: "";
}

.fa-manat-sign:before {
  content: "";
}

.fa-not-equal:before {
  content: "";
}

.fa-border-top-left:before, .fa-border-style:before {
  content: "";
}

.fa-map-location-dot:before, .fa-map-marked-alt:before {
  content: "";
}

.fa-jedi:before {
  content: "";
}

.fa-square-poll-vertical:before, .fa-poll:before {
  content: "";
}

.fa-mug-hot:before {
  content: "";
}

.fa-car-battery:before, .fa-battery-car:before {
  content: "";
}

.fa-gift:before {
  content: "";
}

.fa-dice-two:before {
  content: "";
}

.fa-chess-queen:before {
  content: "";
}

.fa-glasses:before {
  content: "";
}

.fa-chess-board:before {
  content: "";
}

.fa-building-circle-check:before {
  content: "";
}

.fa-person-chalkboard:before {
  content: "";
}

.fa-mars-stroke-right:before, .fa-mars-stroke-h:before {
  content: "";
}

.fa-hand-back-fist:before, .fa-hand-rock:before {
  content: "";
}

.fa-square-caret-up:before, .fa-caret-square-up:before {
  content: "";
}

.fa-cloud-showers-water:before {
  content: "";
}

.fa-chart-bar:before, .fa-bar-chart:before {
  content: "";
}

.fa-hands-bubbles:before, .fa-hands-wash:before {
  content: "";
}

.fa-less-than-equal:before {
  content: "";
}

.fa-train:before {
  content: "";
}

.fa-eye-low-vision:before, .fa-low-vision:before {
  content: "";
}

.fa-crow:before {
  content: "";
}

.fa-sailboat:before {
  content: "";
}

.fa-window-restore:before {
  content: "";
}

.fa-square-plus:before, .fa-plus-square:before {
  content: "";
}

.fa-torii-gate:before {
  content: "";
}

.fa-frog:before {
  content: "";
}

.fa-bucket:before {
  content: "";
}

.fa-image:before {
  content: "";
}

.fa-microphone:before {
  content: "";
}

.fa-cow:before {
  content: "";
}

.fa-caret-up:before {
  content: "";
}

.fa-screwdriver:before {
  content: "";
}

.fa-folder-closed:before {
  content: "";
}

.fa-house-tsunami:before {
  content: "";
}

.fa-square-nfi:before {
  content: "";
}

.fa-arrow-up-from-ground-water:before {
  content: "";
}

.fa-martini-glass:before, .fa-glass-martini-alt:before {
  content: "";
}

.fa-rotate-left:before, .fa-rotate-back:before, .fa-rotate-backward:before, .fa-undo-alt:before {
  content: "";
}

.fa-table-columns:before, .fa-columns:before {
  content: "";
}

.fa-lemon:before {
  content: "";
}

.fa-head-side-mask:before {
  content: "";
}

.fa-handshake:before {
  content: "";
}

.fa-gem:before {
  content: "";
}

.fa-dolly:before, .fa-dolly-box:before {
  content: "";
}

.fa-smoking:before {
  content: "";
}

.fa-minimize:before, .fa-compress-arrows-alt:before {
  content: "";
}

.fa-monument:before {
  content: "";
}

.fa-snowplow:before {
  content: "";
}

.fa-angles-right:before, .fa-angle-double-right:before {
  content: "";
}

.fa-cannabis:before {
  content: "";
}

.fa-circle-play:before, .fa-play-circle:before {
  content: "";
}

.fa-tablets:before {
  content: "";
}

.fa-ethernet:before {
  content: "";
}

.fa-euro-sign:before, .fa-eur:before, .fa-euro:before {
  content: "";
}

.fa-chair:before {
  content: "";
}

.fa-circle-check:before, .fa-check-circle:before {
  content: "";
}

.fa-circle-stop:before, .fa-stop-circle:before {
  content: "";
}

.fa-compass-drafting:before, .fa-drafting-compass:before {
  content: "";
}

.fa-plate-wheat:before {
  content: "";
}

.fa-icicles:before {
  content: "";
}

.fa-person-shelter:before {
  content: "";
}

.fa-neuter:before {
  content: "";
}

.fa-id-badge:before {
  content: "";
}

.fa-marker:before {
  content: "";
}

.fa-face-laugh-beam:before, .fa-laugh-beam:before {
  content: "";
}

.fa-helicopter-symbol:before {
  content: "";
}

.fa-universal-access:before {
  content: "";
}

.fa-circle-chevron-up:before, .fa-chevron-circle-up:before {
  content: "";
}

.fa-lari-sign:before {
  content: "";
}

.fa-volcano:before {
  content: "";
}

.fa-person-walking-dashed-line-arrow-right:before {
  content: "";
}

.fa-sterling-sign:before, .fa-gbp:before, .fa-pound-sign:before {
  content: "";
}

.fa-viruses:before {
  content: "";
}

.fa-square-person-confined:before {
  content: "";
}

.fa-user-tie:before {
  content: "";
}

.fa-arrow-down-long:before, .fa-long-arrow-down:before {
  content: "";
}

.fa-tent-arrow-down-to-line:before {
  content: "";
}

.fa-certificate:before {
  content: "";
}

.fa-reply-all:before, .fa-mail-reply-all:before {
  content: "";
}

.fa-suitcase:before {
  content: "";
}

.fa-person-skating:before, .fa-skating:before {
  content: "";
}

.fa-filter-circle-dollar:before, .fa-funnel-dollar:before {
  content: "";
}

.fa-camera-retro:before {
  content: "";
}

.fa-circle-arrow-down:before, .fa-arrow-circle-down:before {
  content: "";
}

.fa-file-import:before, .fa-arrow-right-to-file:before {
  content: "";
}

.fa-square-arrow-up-right:before, .fa-external-link-square:before {
  content: "";
}

.fa-box-open:before {
  content: "";
}

.fa-scroll:before {
  content: "";
}

.fa-spa:before {
  content: "";
}

.fa-location-pin-lock:before {
  content: "";
}

.fa-pause:before {
  content: "";
}

.fa-hill-avalanche:before {
  content: "";
}

.fa-temperature-empty:before, .fa-temperature-0:before, .fa-thermometer-0:before, .fa-thermometer-empty:before {
  content: "";
}

.fa-bomb:before {
  content: "";
}

.fa-registered:before {
  content: "";
}

.fa-address-card:before, .fa-contact-card:before, .fa-vcard:before {
  content: "";
}

.fa-scale-unbalanced-flip:before, .fa-balance-scale-right:before {
  content: "";
}

.fa-subscript:before {
  content: "";
}

.fa-diamond-turn-right:before, .fa-directions:before {
  content: "";
}

.fa-burst:before {
  content: "";
}

.fa-house-laptop:before, .fa-laptop-house:before {
  content: "";
}

.fa-face-tired:before, .fa-tired:before {
  content: "";
}

.fa-money-bills:before {
  content: "";
}

.fa-smog:before {
  content: "";
}

.fa-crutch:before {
  content: "";
}

.fa-cloud-arrow-up:before, .fa-cloud-upload:before, .fa-cloud-upload-alt:before {
  content: "";
}

.fa-palette:before {
  content: "";
}

.fa-arrows-turn-right:before {
  content: "";
}

.fa-vest:before {
  content: "";
}

.fa-ferry:before {
  content: "";
}

.fa-arrows-down-to-people:before {
  content: "";
}

.fa-seedling:before, .fa-sprout:before {
  content: "";
}

.fa-left-right:before, .fa-arrows-alt-h:before {
  content: "";
}

.fa-boxes-packing:before {
  content: "";
}

.fa-circle-arrow-left:before, .fa-arrow-circle-left:before {
  content: "";
}

.fa-group-arrows-rotate:before {
  content: "";
}

.fa-bowl-food:before {
  content: "";
}

.fa-candy-cane:before {
  content: "";
}

.fa-arrow-down-wide-short:before, .fa-sort-amount-asc:before, .fa-sort-amount-down:before {
  content: "";
}

.fa-cloud-bolt:before, .fa-thunderstorm:before {
  content: "";
}

.fa-text-slash:before, .fa-remove-format:before {
  content: "";
}

.fa-face-smile-wink:before, .fa-smile-wink:before {
  content: "";
}

.fa-file-word:before {
  content: "";
}

.fa-file-powerpoint:before {
  content: "";
}

.fa-arrows-left-right:before, .fa-arrows-h:before {
  content: "";
}

.fa-house-lock:before {
  content: "";
}

.fa-cloud-arrow-down:before, .fa-cloud-download:before, .fa-cloud-download-alt:before {
  content: "";
}

.fa-children:before {
  content: "";
}

.fa-chalkboard:before, .fa-blackboard:before {
  content: "";
}

.fa-user-large-slash:before, .fa-user-alt-slash:before {
  content: "";
}

.fa-envelope-open:before {
  content: "";
}

.fa-handshake-simple-slash:before, .fa-handshake-alt-slash:before {
  content: "";
}

.fa-mattress-pillow:before {
  content: "";
}

.fa-guarani-sign:before {
  content: "";
}

.fa-arrows-rotate:before, .fa-refresh:before, .fa-sync:before {
  content: "";
}

.fa-fire-extinguisher:before {
  content: "";
}

.fa-cruzeiro-sign:before {
  content: "";
}

.fa-greater-than-equal:before {
  content: "";
}

.fa-shield-halved:before, .fa-shield-alt:before {
  content: "";
}

.fa-book-atlas:before, .fa-atlas:before {
  content: "";
}

.fa-virus:before {
  content: "";
}

.fa-envelope-circle-check:before {
  content: "";
}

.fa-layer-group:before {
  content: "";
}

.fa-arrows-to-dot:before {
  content: "";
}

.fa-archway:before {
  content: "";
}

.fa-heart-circle-check:before {
  content: "";
}

.fa-house-chimney-crack:before, .fa-house-damage:before {
  content: "";
}

.fa-file-zipper:before, .fa-file-archive:before {
  content: "";
}

.fa-square:before {
  content: "";
}

.fa-martini-glass-empty:before, .fa-glass-martini:before {
  content: "";
}

.fa-couch:before {
  content: "";
}

.fa-cedi-sign:before {
  content: "";
}

.fa-italic:before {
  content: "";
}

.fa-church:before {
  content: "";
}

.fa-comments-dollar:before {
  content: "";
}

.fa-democrat:before {
  content: "";
}

.fa-z:before {
  content: "Z";
}

.fa-person-skiing:before, .fa-skiing:before {
  content: "";
}

.fa-road-lock:before {
  content: "";
}

.fa-a:before {
  content: "A";
}

.fa-temperature-arrow-down:before, .fa-temperature-down:before {
  content: "";
}

.fa-feather-pointed:before, .fa-feather-alt:before {
  content: "";
}

.fa-p:before {
  content: "P";
}

.fa-snowflake:before {
  content: "";
}

.fa-newspaper:before {
  content: "";
}

.fa-rectangle-ad:before, .fa-ad:before {
  content: "";
}

.fa-circle-arrow-right:before, .fa-arrow-circle-right:before {
  content: "";
}

.fa-filter-circle-xmark:before {
  content: "";
}

.fa-locust:before {
  content: "";
}

.fa-sort:before, .fa-unsorted:before {
  content: "";
}

.fa-list-ol:before, .fa-list-1-2:before, .fa-list-numeric:before {
  content: "";
}

.fa-person-dress-burst:before {
  content: "";
}

.fa-money-check-dollar:before, .fa-money-check-alt:before {
  content: "";
}

.fa-vector-square:before {
  content: "";
}

.fa-bread-slice:before {
  content: "";
}

.fa-language:before {
  content: "";
}

.fa-face-kiss-wink-heart:before, .fa-kiss-wink-heart:before {
  content: "";
}

.fa-filter:before {
  content: "";
}

.fa-question:before {
  content: "?";
}

.fa-file-signature:before {
  content: "";
}

.fa-up-down-left-right:before, .fa-arrows-alt:before {
  content: "";
}

.fa-house-chimney-user:before {
  content: "";
}

.fa-hand-holding-heart:before {
  content: "";
}

.fa-puzzle-piece:before {
  content: "";
}

.fa-money-check:before {
  content: "";
}

.fa-star-half-stroke:before, .fa-star-half-alt:before {
  content: "";
}

.fa-code:before {
  content: "";
}

.fa-whiskey-glass:before, .fa-glass-whiskey:before {
  content: "";
}

.fa-building-circle-exclamation:before {
  content: "";
}

.fa-magnifying-glass-chart:before {
  content: "";
}

.fa-arrow-up-right-from-square:before, .fa-external-link:before {
  content: "";
}

.fa-cubes-stacked:before {
  content: "";
}

.fa-won-sign:before, .fa-krw:before, .fa-won:before {
  content: "";
}

.fa-virus-covid:before {
  content: "";
}

.fa-austral-sign:before {
  content: "";
}

.fa-f:before {
  content: "F";
}

.fa-leaf:before {
  content: "";
}

.fa-road:before {
  content: "";
}

.fa-taxi:before, .fa-cab:before {
  content: "";
}

.fa-person-circle-plus:before {
  content: "";
}

.fa-chart-pie:before, .fa-pie-chart:before {
  content: "";
}

.fa-bolt-lightning:before {
  content: "";
}

.fa-sack-xmark:before {
  content: "";
}

.fa-file-excel:before {
  content: "";
}

.fa-file-contract:before {
  content: "";
}

.fa-fish-fins:before {
  content: "";
}

.fa-building-flag:before {
  content: "";
}

.fa-face-grin-beam:before, .fa-grin-beam:before {
  content: "";
}

.fa-object-ungroup:before {
  content: "";
}

.fa-poop:before {
  content: "";
}

.fa-location-pin:before, .fa-map-marker:before {
  content: "";
}

.fa-kaaba:before {
  content: "";
}

.fa-toilet-paper:before {
  content: "";
}

.fa-helmet-safety:before, .fa-hard-hat:before, .fa-hat-hard:before {
  content: "";
}

.fa-eject:before {
  content: "";
}

.fa-circle-right:before, .fa-arrow-alt-circle-right:before {
  content: "";
}

.fa-plane-circle-check:before {
  content: "";
}

.fa-face-rolling-eyes:before, .fa-meh-rolling-eyes:before {
  content: "";
}

.fa-object-group:before {
  content: "";
}

.fa-chart-line:before, .fa-line-chart:before {
  content: "";
}

.fa-mask-ventilator:before {
  content: "";
}

.fa-arrow-right:before {
  content: "";
}

.fa-signs-post:before, .fa-map-signs:before {
  content: "";
}

.fa-cash-register:before {
  content: "";
}

.fa-person-circle-question:before {
  content: "";
}

.fa-h:before {
  content: "H";
}

.fa-tarp:before {
  content: "";
}

.fa-screwdriver-wrench:before, .fa-tools:before {
  content: "";
}

.fa-arrows-to-eye:before {
  content: "";
}

.fa-plug-circle-bolt:before {
  content: "";
}

.fa-heart:before {
  content: "";
}

.fa-mars-and-venus:before {
  content: "";
}

.fa-house-user:before, .fa-home-user:before {
  content: "";
}

.fa-dumpster-fire:before {
  content: "";
}

.fa-house-crack:before {
  content: "";
}

.fa-martini-glass-citrus:before, .fa-cocktail:before {
  content: "";
}

.fa-face-surprise:before, .fa-surprise:before {
  content: "";
}

.fa-bottle-water:before {
  content: "";
}

.fa-circle-pause:before, .fa-pause-circle:before {
  content: "";
}

.fa-toilet-paper-slash:before {
  content: "";
}

.fa-apple-whole:before, .fa-apple-alt:before {
  content: "";
}

.fa-kitchen-set:before {
  content: "";
}

.fa-r:before {
  content: "R";
}

.fa-temperature-quarter:before, .fa-temperature-1:before, .fa-thermometer-1:before, .fa-thermometer-quarter:before {
  content: "";
}

.fa-cube:before {
  content: "";
}

.fa-bitcoin-sign:before {
  content: "";
}

.fa-shield-dog:before {
  content: "";
}

.fa-solar-panel:before {
  content: "";
}

.fa-lock-open:before {
  content: "";
}

.fa-elevator:before {
  content: "";
}

.fa-money-bill-transfer:before {
  content: "";
}

.fa-money-bill-trend-up:before {
  content: "";
}

.fa-house-flood-water-circle-arrow-right:before {
  content: "";
}

.fa-square-poll-horizontal:before, .fa-poll-h:before {
  content: "";
}

.fa-circle:before {
  content: "";
}

.fa-backward-fast:before, .fa-fast-backward:before {
  content: "";
}

.fa-recycle:before {
  content: "";
}

.fa-user-astronaut:before {
  content: "";
}

.fa-plane-slash:before {
  content: "";
}

.fa-trademark:before {
  content: "";
}

.fa-basketball:before, .fa-basketball-ball:before {
  content: "";
}

.fa-satellite-dish:before {
  content: "";
}

.fa-circle-up:before, .fa-arrow-alt-circle-up:before {
  content: "";
}

.fa-mobile-screen-button:before, .fa-mobile-alt:before {
  content: "";
}

.fa-volume-high:before, .fa-volume-up:before {
  content: "";
}

.fa-users-rays:before {
  content: "";
}

.fa-wallet:before {
  content: "";
}

.fa-clipboard-check:before {
  content: "";
}

.fa-file-audio:before {
  content: "";
}

.fa-burger:before, .fa-hamburger:before {
  content: "";
}

.fa-wrench:before {
  content: "";
}

.fa-bugs:before {
  content: "";
}

.fa-rupee-sign:before, .fa-rupee:before {
  content: "";
}

.fa-file-image:before {
  content: "";
}

.fa-circle-question:before, .fa-question-circle:before {
  content: "";
}

.fa-plane-departure:before {
  content: "";
}

.fa-handshake-slash:before {
  content: "";
}

.fa-book-bookmark:before {
  content: "";
}

.fa-code-branch:before {
  content: "";
}

.fa-hat-cowboy:before {
  content: "";
}

.fa-bridge:before {
  content: "";
}

.fa-phone-flip:before, .fa-phone-alt:before {
  content: "";
}

.fa-truck-front:before {
  content: "";
}

.fa-cat:before {
  content: "";
}

.fa-anchor-circle-exclamation:before {
  content: "";
}

.fa-truck-field:before {
  content: "";
}

.fa-route:before {
  content: "";
}

.fa-clipboard-question:before {
  content: "";
}

.fa-panorama:before {
  content: "";
}

.fa-comment-medical:before {
  content: "";
}

.fa-teeth-open:before {
  content: "";
}

.fa-file-circle-minus:before {
  content: "";
}

.fa-tags:before {
  content: "";
}

.fa-wine-glass:before {
  content: "";
}

.fa-forward-fast:before, .fa-fast-forward:before {
  content: "";
}

.fa-face-meh-blank:before, .fa-meh-blank:before {
  content: "";
}

.fa-square-parking:before, .fa-parking:before {
  content: "";
}

.fa-house-signal:before {
  content: "";
}

.fa-bars-progress:before, .fa-tasks-alt:before {
  content: "";
}

.fa-faucet-drip:before {
  content: "";
}

.fa-cart-flatbed:before, .fa-dolly-flatbed:before {
  content: "";
}

.fa-ban-smoking:before, .fa-smoking-ban:before {
  content: "";
}

.fa-terminal:before {
  content: "";
}

.fa-mobile-button:before {
  content: "";
}

.fa-house-medical-flag:before {
  content: "";
}

.fa-basket-shopping:before, .fa-shopping-basket:before {
  content: "";
}

.fa-tape:before {
  content: "";
}

.fa-bus-simple:before, .fa-bus-alt:before {
  content: "";
}

.fa-eye:before {
  content: "";
}

.fa-face-sad-cry:before, .fa-sad-cry:before {
  content: "";
}

.fa-audio-description:before {
  content: "";
}

.fa-person-military-to-person:before {
  content: "";
}

.fa-file-shield:before {
  content: "";
}

.fa-user-slash:before {
  content: "";
}

.fa-pen:before {
  content: "";
}

.fa-tower-observation:before {
  content: "";
}

.fa-file-code:before {
  content: "";
}

.fa-signal:before, .fa-signal-5:before, .fa-signal-perfect:before {
  content: "";
}

.fa-bus:before {
  content: "";
}

.fa-heart-circle-xmark:before {
  content: "";
}

.fa-house-chimney:before, .fa-home-lg:before {
  content: "";
}

.fa-window-maximize:before {
  content: "";
}

.fa-face-frown:before, .fa-frown:before {
  content: "";
}

.fa-prescription:before {
  content: "";
}

.fa-shop:before, .fa-store-alt:before {
  content: "";
}

.fa-floppy-disk:before, .fa-save:before {
  content: "";
}

.fa-vihara:before {
  content: "";
}

.fa-scale-unbalanced:before, .fa-balance-scale-left:before {
  content: "";
}

.fa-sort-up:before, .fa-sort-asc:before {
  content: "";
}

.fa-comment-dots:before, .fa-commenting:before {
  content: "";
}

.fa-plant-wilt:before {
  content: "";
}

.fa-diamond:before {
  content: "";
}

.fa-face-grin-squint:before, .fa-grin-squint:before {
  content: "";
}

.fa-hand-holding-dollar:before, .fa-hand-holding-usd:before {
  content: "";
}

.fa-bacterium:before {
  content: "";
}

.fa-hand-pointer:before {
  content: "";
}

.fa-drum-steelpan:before {
  content: "";
}

.fa-hand-scissors:before {
  content: "";
}

.fa-hands-praying:before, .fa-praying-hands:before {
  content: "";
}

.fa-arrow-rotate-right:before, .fa-arrow-right-rotate:before, .fa-arrow-rotate-forward:before, .fa-redo:before {
  content: "";
}

.fa-biohazard:before {
  content: "";
}

.fa-location-crosshairs:before, .fa-location:before {
  content: "";
}

.fa-mars-double:before {
  content: "";
}

.fa-child-dress:before {
  content: "";
}

.fa-users-between-lines:before {
  content: "";
}

.fa-lungs-virus:before {
  content: "";
}

.fa-face-grin-tears:before, .fa-grin-tears:before {
  content: "";
}

.fa-phone:before {
  content: "";
}

.fa-calendar-xmark:before, .fa-calendar-times:before {
  content: "";
}

.fa-child-reaching:before {
  content: "";
}

.fa-head-side-virus:before {
  content: "";
}

.fa-user-gear:before, .fa-user-cog:before {
  content: "";
}

.fa-arrow-up-1-9:before, .fa-sort-numeric-up:before {
  content: "";
}

.fa-door-closed:before {
  content: "";
}

.fa-shield-virus:before {
  content: "";
}

.fa-dice-six:before {
  content: "";
}

.fa-mosquito-net:before {
  content: "";
}

.fa-bridge-water:before {
  content: "";
}

.fa-person-booth:before {
  content: "";
}

.fa-text-width:before {
  content: "";
}

.fa-hat-wizard:before {
  content: "";
}

.fa-pen-fancy:before {
  content: "";
}

.fa-person-digging:before, .fa-digging:before {
  content: "";
}

.fa-trash:before {
  content: "";
}

.fa-gauge-simple:before, .fa-gauge-simple-med:before, .fa-tachometer-average:before {
  content: "";
}

.fa-book-medical:before {
  content: "";
}

.fa-poo:before {
  content: "";
}

.fa-quote-right:before, .fa-quote-right-alt:before {
  content: "";
}

.fa-shirt:before, .fa-t-shirt:before, .fa-tshirt:before {
  content: "";
}

.fa-cubes:before {
  content: "";
}

.fa-divide:before {
  content: "";
}

.fa-tenge-sign:before, .fa-tenge:before {
  content: "";
}

.fa-headphones:before {
  content: "";
}

.fa-hands-holding:before {
  content: "";
}

.fa-hands-clapping:before {
  content: "";
}

.fa-republican:before {
  content: "";
}

.fa-arrow-left:before {
  content: "";
}

.fa-person-circle-xmark:before {
  content: "";
}

.fa-ruler:before {
  content: "";
}

.fa-align-left:before {
  content: "";
}

.fa-dice-d6:before {
  content: "";
}

.fa-restroom:before {
  content: "";
}

.fa-j:before {
  content: "J";
}

.fa-users-viewfinder:before {
  content: "";
}

.fa-file-video:before {
  content: "";
}

.fa-up-right-from-square:before, .fa-external-link-alt:before {
  content: "";
}

.fa-table-cells:before, .fa-th:before {
  content: "";
}

.fa-file-pdf:before {
  content: "";
}

.fa-book-bible:before, .fa-bible:before {
  content: "";
}

.fa-o:before {
  content: "O";
}

.fa-suitcase-medical:before, .fa-medkit:before {
  content: "";
}

.fa-user-secret:before {
  content: "";
}

.fa-otter:before {
  content: "";
}

.fa-person-dress:before, .fa-female:before {
  content: "";
}

.fa-comment-dollar:before {
  content: "";
}

.fa-business-time:before, .fa-briefcase-clock:before {
  content: "";
}

.fa-table-cells-large:before, .fa-th-large:before {
  content: "";
}

.fa-book-tanakh:before, .fa-tanakh:before {
  content: "";
}

.fa-phone-volume:before, .fa-volume-control-phone:before {
  content: "";
}

.fa-hat-cowboy-side:before {
  content: "";
}

.fa-clipboard-user:before {
  content: "";
}

.fa-child:before {
  content: "";
}

.fa-lira-sign:before {
  content: "";
}

.fa-satellite:before {
  content: "";
}

.fa-plane-lock:before {
  content: "";
}

.fa-tag:before {
  content: "";
}

.fa-comment:before {
  content: "";
}

.fa-cake-candles:before, .fa-birthday-cake:before, .fa-cake:before {
  content: "";
}

.fa-envelope:before {
  content: "";
}

.fa-angles-up:before, .fa-angle-double-up:before {
  content: "";
}

.fa-paperclip:before {
  content: "";
}

.fa-arrow-right-to-city:before {
  content: "";
}

.fa-ribbon:before {
  content: "";
}

.fa-lungs:before {
  content: "";
}

.fa-arrow-up-9-1:before, .fa-sort-numeric-up-alt:before {
  content: "";
}

.fa-litecoin-sign:before {
  content: "";
}

.fa-border-none:before {
  content: "";
}

.fa-circle-nodes:before {
  content: "";
}

.fa-parachute-box:before {
  content: "";
}

.fa-indent:before {
  content: "";
}

.fa-truck-field-un:before {
  content: "";
}

.fa-hourglass:before, .fa-hourglass-empty:before {
  content: "";
}

.fa-mountain:before {
  content: "";
}

.fa-user-doctor:before, .fa-user-md:before {
  content: "";
}

.fa-circle-info:before, .fa-info-circle:before {
  content: "";
}

.fa-cloud-meatball:before {
  content: "";
}

.fa-camera:before, .fa-camera-alt:before {
  content: "";
}

.fa-square-virus:before {
  content: "";
}

.fa-meteor:before {
  content: "";
}

.fa-car-on:before {
  content: "";
}

.fa-sleigh:before {
  content: "";
}

.fa-arrow-down-1-9:before, .fa-sort-numeric-asc:before, .fa-sort-numeric-down:before {
  content: "";
}

.fa-hand-holding-droplet:before, .fa-hand-holding-water:before {
  content: "";
}

.fa-water:before {
  content: "";
}

.fa-calendar-check:before {
  content: "";
}

.fa-braille:before {
  content: "";
}

.fa-prescription-bottle-medical:before, .fa-prescription-bottle-alt:before {
  content: "";
}

.fa-landmark:before {
  content: "";
}

.fa-truck:before {
  content: "";
}

.fa-crosshairs:before {
  content: "";
}

.fa-person-cane:before {
  content: "";
}

.fa-tent:before {
  content: "";
}

.fa-vest-patches:before {
  content: "";
}

.fa-check-double:before {
  content: "";
}

.fa-arrow-down-a-z:before, .fa-sort-alpha-asc:before, .fa-sort-alpha-down:before {
  content: "";
}

.fa-money-bill-wheat:before {
  content: "";
}

.fa-cookie:before {
  content: "";
}

.fa-arrow-rotate-left:before, .fa-arrow-left-rotate:before, .fa-arrow-rotate-back:before, .fa-arrow-rotate-backward:before, .fa-undo:before {
  content: "";
}

.fa-hard-drive:before, .fa-hdd:before {
  content: "";
}

.fa-face-grin-squint-tears:before, .fa-grin-squint-tears:before {
  content: "";
}

.fa-dumbbell:before {
  content: "";
}

.fa-rectangle-list:before, .fa-list-alt:before {
  content: "";
}

.fa-tarp-droplet:before {
  content: "";
}

.fa-house-medical-circle-check:before {
  content: "";
}

.fa-person-skiing-nordic:before, .fa-skiing-nordic:before {
  content: "";
}

.fa-calendar-plus:before {
  content: "";
}

.fa-plane-arrival:before {
  content: "";
}

.fa-circle-left:before, .fa-arrow-alt-circle-left:before {
  content: "";
}

.fa-train-subway:before, .fa-subway:before {
  content: "";
}

.fa-chart-gantt:before {
  content: "";
}

.fa-indian-rupee-sign:before, .fa-indian-rupee:before, .fa-inr:before {
  content: "";
}

.fa-crop-simple:before, .fa-crop-alt:before {
  content: "";
}

.fa-money-bill-1:before, .fa-money-bill-alt:before {
  content: "";
}

.fa-left-long:before, .fa-long-arrow-alt-left:before {
  content: "";
}

.fa-dna:before {
  content: "";
}

.fa-virus-slash:before {
  content: "";
}

.fa-minus:before, .fa-subtract:before {
  content: "";
}

.fa-chess:before {
  content: "";
}

.fa-arrow-left-long:before, .fa-long-arrow-left:before {
  content: "";
}

.fa-plug-circle-check:before {
  content: "";
}

.fa-street-view:before {
  content: "";
}

.fa-franc-sign:before {
  content: "";
}

.fa-volume-off:before {
  content: "";
}

.fa-hands-asl-interpreting:before, .fa-american-sign-language-interpreting:before, .fa-asl-interpreting:before, .fa-hands-american-sign-language-interpreting:before {
  content: "";
}

.fa-gear:before, .fa-cog:before {
  content: "";
}

.fa-droplet-slash:before, .fa-tint-slash:before {
  content: "";
}

.fa-mosque:before {
  content: "";
}

.fa-mosquito:before {
  content: "";
}

.fa-star-of-david:before {
  content: "";
}

.fa-person-military-rifle:before {
  content: "";
}

.fa-cart-shopping:before, .fa-shopping-cart:before {
  content: "";
}

.fa-vials:before {
  content: "";
}

.fa-plug-circle-plus:before {
  content: "";
}

.fa-place-of-worship:before {
  content: "";
}

.fa-grip-vertical:before {
  content: "";
}

.fa-arrow-turn-up:before, .fa-level-up:before {
  content: "";
}

.fa-u:before {
  content: "U";
}

.fa-square-root-variable:before, .fa-square-root-alt:before {
  content: "";
}

.fa-clock:before, .fa-clock-four:before {
  content: "";
}

.fa-backward-step:before, .fa-step-backward:before {
  content: "";
}

.fa-pallet:before {
  content: "";
}

.fa-faucet:before {
  content: "";
}

.fa-baseball-bat-ball:before {
  content: "";
}

.fa-s:before {
  content: "S";
}

.fa-timeline:before {
  content: "";
}

.fa-keyboard:before {
  content: "";
}

.fa-caret-down:before {
  content: "";
}

.fa-house-chimney-medical:before, .fa-clinic-medical:before {
  content: "";
}

.fa-temperature-three-quarters:before, .fa-temperature-3:before, .fa-thermometer-3:before, .fa-thermometer-three-quarters:before {
  content: "";
}

.fa-mobile-screen:before, .fa-mobile-android-alt:before {
  content: "";
}

.fa-plane-up:before {
  content: "";
}

.fa-piggy-bank:before {
  content: "";
}

.fa-battery-half:before, .fa-battery-3:before {
  content: "";
}

.fa-mountain-city:before {
  content: "";
}

.fa-coins:before {
  content: "";
}

.fa-khanda:before {
  content: "";
}

.fa-sliders:before, .fa-sliders-h:before {
  content: "";
}

.fa-folder-tree:before {
  content: "";
}

.fa-network-wired:before {
  content: "";
}

.fa-map-pin:before {
  content: "";
}

.fa-hamsa:before {
  content: "";
}

.fa-cent-sign:before {
  content: "";
}

.fa-flask:before {
  content: "";
}

.fa-person-pregnant:before {
  content: "";
}

.fa-wand-sparkles:before {
  content: "";
}

.fa-ellipsis-vertical:before, .fa-ellipsis-v:before {
  content: "";
}

.fa-ticket:before {
  content: "";
}

.fa-power-off:before {
  content: "";
}

.fa-right-long:before, .fa-long-arrow-alt-right:before {
  content: "";
}

.fa-flag-usa:before {
  content: "";
}

.fa-laptop-file:before {
  content: "";
}

.fa-tty:before, .fa-teletype:before {
  content: "";
}

.fa-diagram-next:before {
  content: "";
}

.fa-person-rifle:before {
  content: "";
}

.fa-house-medical-circle-exclamation:before {
  content: "";
}

.fa-closed-captioning:before {
  content: "";
}

.fa-person-hiking:before, .fa-hiking:before {
  content: "";
}

.fa-venus-double:before {
  content: "";
}

.fa-images:before {
  content: "";
}

.fa-calculator:before {
  content: "";
}

.fa-people-pulling:before {
  content: "";
}

.fa-n:before {
  content: "N";
}

.fa-cable-car:before, .fa-tram:before {
  content: "";
}

.fa-cloud-rain:before {
  content: "";
}

.fa-building-circle-xmark:before {
  content: "";
}

.fa-ship:before {
  content: "";
}

.fa-arrows-down-to-line:before {
  content: "";
}

.fa-download:before {
  content: "";
}

.fa-face-grin:before, .fa-grin:before {
  content: "";
}

.fa-delete-left:before, .fa-backspace:before {
  content: "";
}

.fa-eye-dropper:before, .fa-eye-dropper-empty:before, .fa-eyedropper:before {
  content: "";
}

.fa-file-circle-check:before {
  content: "";
}

.fa-forward:before {
  content: "";
}

.fa-mobile:before, .fa-mobile-android:before, .fa-mobile-phone:before {
  content: "";
}

.fa-face-meh:before, .fa-meh:before {
  content: "";
}

.fa-align-center:before {
  content: "";
}

.fa-book-skull:before, .fa-book-dead:before {
  content: "";
}

.fa-id-card:before, .fa-drivers-license:before {
  content: "";
}

.fa-outdent:before, .fa-dedent:before {
  content: "";
}

.fa-heart-circle-exclamation:before {
  content: "";
}

.fa-house:before, .fa-home:before, .fa-home-alt:before, .fa-home-lg-alt:before {
  content: "";
}

.fa-calendar-week:before {
  content: "";
}

.fa-laptop-medical:before {
  content: "";
}

.fa-b:before {
  content: "B";
}

.fa-file-medical:before {
  content: "";
}

.fa-dice-one:before {
  content: "";
}

.fa-kiwi-bird:before {
  content: "";
}

.fa-arrow-right-arrow-left:before, .fa-exchange:before {
  content: "";
}

.fa-rotate-right:before, .fa-redo-alt:before, .fa-rotate-forward:before {
  content: "";
}

.fa-utensils:before, .fa-cutlery:before {
  content: "";
}

.fa-arrow-up-wide-short:before, .fa-sort-amount-up:before {
  content: "";
}

.fa-mill-sign:before {
  content: "";
}

.fa-bowl-rice:before {
  content: "";
}

.fa-skull:before {
  content: "";
}

.fa-tower-broadcast:before, .fa-broadcast-tower:before {
  content: "";
}

.fa-truck-pickup:before {
  content: "";
}

.fa-up-long:before, .fa-long-arrow-alt-up:before {
  content: "";
}

.fa-stop:before {
  content: "";
}

.fa-code-merge:before {
  content: "";
}

.fa-upload:before {
  content: "";
}

.fa-hurricane:before {
  content: "";
}

.fa-mound:before {
  content: "";
}

.fa-toilet-portable:before {
  content: "";
}

.fa-compact-disc:before {
  content: "";
}

.fa-file-arrow-down:before, .fa-file-download:before {
  content: "";
}

.fa-caravan:before {
  content: "";
}

.fa-shield-cat:before {
  content: "";
}

.fa-bolt:before, .fa-zap:before {
  content: "";
}

.fa-glass-water:before {
  content: "";
}

.fa-oil-well:before {
  content: "";
}

.fa-vault:before {
  content: "";
}

.fa-mars:before {
  content: "";
}

.fa-toilet:before {
  content: "";
}

.fa-plane-circle-xmark:before {
  content: "";
}

.fa-yen-sign:before, .fa-cny:before, .fa-jpy:before, .fa-rmb:before, .fa-yen:before {
  content: "";
}

.fa-ruble-sign:before, .fa-rouble:before, .fa-rub:before, .fa-ruble:before {
  content: "";
}

.fa-sun:before {
  content: "";
}

.fa-guitar:before {
  content: "";
}

.fa-face-laugh-wink:before, .fa-laugh-wink:before {
  content: "";
}

.fa-horse-head:before {
  content: "";
}

.fa-bore-hole:before {
  content: "";
}

.fa-industry:before {
  content: "";
}

.fa-circle-down:before, .fa-arrow-alt-circle-down:before {
  content: "";
}

.fa-arrows-turn-to-dots:before {
  content: "";
}

.fa-florin-sign:before {
  content: "";
}

.fa-arrow-down-short-wide:before, .fa-sort-amount-desc:before, .fa-sort-amount-down-alt:before {
  content: "";
}

.fa-less-than:before {
  content: "<";
}

.fa-angle-down:before {
  content: "";
}

.fa-car-tunnel:before {
  content: "";
}

.fa-head-side-cough:before {
  content: "";
}

.fa-grip-lines:before {
  content: "";
}

.fa-thumbs-down:before {
  content: "";
}

.fa-user-lock:before {
  content: "";
}

.fa-arrow-right-long:before, .fa-long-arrow-right:before {
  content: "";
}

.fa-anchor-circle-xmark:before {
  content: "";
}

.fa-ellipsis:before, .fa-ellipsis-h:before {
  content: "";
}

.fa-chess-pawn:before {
  content: "";
}

.fa-kit-medical:before, .fa-first-aid:before {
  content: "";
}

.fa-person-through-window:before {
  content: "";
}

.fa-toolbox:before {
  content: "";
}

.fa-hands-holding-circle:before {
  content: "";
}

.fa-bug:before {
  content: "";
}

.fa-credit-card:before, .fa-credit-card-alt:before {
  content: "";
}

.fa-car:before, .fa-automobile:before {
  content: "";
}

.fa-hand-holding-hand:before {
  content: "";
}

.fa-book-open-reader:before, .fa-book-reader:before {
  content: "";
}

.fa-mountain-sun:before {
  content: "";
}

.fa-arrows-left-right-to-line:before {
  content: "";
}

.fa-dice-d20:before {
  content: "";
}

.fa-truck-droplet:before {
  content: "";
}

.fa-file-circle-xmark:before {
  content: "";
}

.fa-temperature-arrow-up:before, .fa-temperature-up:before {
  content: "";
}

.fa-medal:before {
  content: "";
}

.fa-bed:before {
  content: "";
}

.fa-square-h:before, .fa-h-square:before {
  content: "";
}

.fa-podcast:before {
  content: "";
}

.fa-temperature-full:before, .fa-temperature-4:before, .fa-thermometer-4:before, .fa-thermometer-full:before {
  content: "";
}

.fa-bell:before {
  content: "";
}

.fa-superscript:before {
  content: "";
}

.fa-plug-circle-xmark:before {
  content: "";
}

.fa-star-of-life:before {
  content: "";
}

.fa-phone-slash:before {
  content: "";
}

.fa-paint-roller:before {
  content: "";
}

.fa-handshake-angle:before, .fa-hands-helping:before {
  content: "";
}

.fa-location-dot:before, .fa-map-marker-alt:before {
  content: "";
}

.fa-file:before {
  content: "";
}

.fa-greater-than:before {
  content: ">";
}

.fa-person-swimming:before, .fa-swimmer:before {
  content: "";
}

.fa-arrow-down:before {
  content: "";
}

.fa-droplet:before, .fa-tint:before {
  content: "";
}

.fa-eraser:before {
  content: "";
}

.fa-earth-americas:before, .fa-earth:before, .fa-earth-america:before, .fa-globe-americas:before {
  content: "";
}

.fa-person-burst:before {
  content: "";
}

.fa-dove:before {
  content: "";
}

.fa-battery-empty:before, .fa-battery-0:before {
  content: "";
}

.fa-socks:before {
  content: "";
}

.fa-inbox:before {
  content: "";
}

.fa-section:before {
  content: "";
}

.fa-gauge-high:before, .fa-tachometer-alt:before, .fa-tachometer-alt-fast:before {
  content: "";
}

.fa-envelope-open-text:before {
  content: "";
}

.fa-hospital:before, .fa-hospital-alt:before, .fa-hospital-wide:before {
  content: "";
}

.fa-wine-bottle:before {
  content: "";
}

.fa-chess-rook:before {
  content: "";
}

.fa-bars-staggered:before, .fa-reorder:before, .fa-stream:before {
  content: "";
}

.fa-dharmachakra:before {
  content: "";
}

.fa-hotdog:before {
  content: "";
}

.fa-person-walking-with-cane:before, .fa-blind:before {
  content: "";
}

.fa-drum:before {
  content: "";
}

.fa-ice-cream:before {
  content: "";
}

.fa-heart-circle-bolt:before {
  content: "";
}

.fa-fax:before {
  content: "";
}

.fa-paragraph:before {
  content: "";
}

.fa-check-to-slot:before, .fa-vote-yea:before {
  content: "";
}

.fa-star-half:before {
  content: "";
}

.fa-boxes-stacked:before, .fa-boxes:before, .fa-boxes-alt:before {
  content: "";
}

.fa-link:before, .fa-chain:before {
  content: "";
}

.fa-ear-listen:before, .fa-assistive-listening-systems:before {
  content: "";
}

.fa-tree-city:before {
  content: "";
}

.fa-play:before {
  content: "";
}

.fa-font:before {
  content: "";
}

.fa-rupiah-sign:before {
  content: "";
}

.fa-magnifying-glass:before, .fa-search:before {
  content: "";
}

.fa-table-tennis-paddle-ball:before, .fa-ping-pong-paddle-ball:before, .fa-table-tennis:before {
  content: "";
}

.fa-person-dots-from-line:before, .fa-diagnoses:before {
  content: "";
}

.fa-trash-can-arrow-up:before, .fa-trash-restore-alt:before {
  content: "";
}

.fa-naira-sign:before {
  content: "";
}

.fa-cart-arrow-down:before {
  content: "";
}

.fa-walkie-talkie:before {
  content: "";
}

.fa-file-pen:before, .fa-file-edit:before {
  content: "";
}

.fa-receipt:before {
  content: "";
}

.fa-square-pen:before, .fa-pen-square:before, .fa-pencil-square:before {
  content: "";
}

.fa-suitcase-rolling:before {
  content: "";
}

.fa-person-circle-exclamation:before {
  content: "";
}

.fa-chevron-down:before {
  content: "";
}

.fa-battery-full:before, .fa-battery:before, .fa-battery-5:before {
  content: "";
}

.fa-skull-crossbones:before {
  content: "";
}

.fa-code-compare:before {
  content: "";
}

.fa-list-ul:before, .fa-list-dots:before {
  content: "";
}

.fa-school-lock:before {
  content: "";
}

.fa-tower-cell:before {
  content: "";
}

.fa-down-long:before, .fa-long-arrow-alt-down:before {
  content: "";
}

.fa-ranking-star:before {
  content: "";
}

.fa-chess-king:before {
  content: "";
}

.fa-person-harassing:before {
  content: "";
}

.fa-brazilian-real-sign:before {
  content: "";
}

.fa-landmark-dome:before, .fa-landmark-alt:before {
  content: "";
}

.fa-arrow-up:before {
  content: "";
}

.fa-tv:before, .fa-television:before, .fa-tv-alt:before {
  content: "";
}

.fa-shrimp:before {
  content: "";
}

.fa-list-check:before, .fa-tasks:before {
  content: "";
}

.fa-jug-detergent:before {
  content: "";
}

.fa-circle-user:before, .fa-user-circle:before {
  content: "";
}

.fa-user-shield:before {
  content: "";
}

.fa-wind:before {
  content: "";
}

.fa-car-burst:before, .fa-car-crash:before {
  content: "";
}

.fa-y:before {
  content: "Y";
}

.fa-person-snowboarding:before, .fa-snowboarding:before {
  content: "";
}

.fa-truck-fast:before, .fa-shipping-fast:before {
  content: "";
}

.fa-fish:before {
  content: "";
}

.fa-user-graduate:before {
  content: "";
}

.fa-circle-half-stroke:before, .fa-adjust:before {
  content: "";
}

.fa-clapperboard:before {
  content: "";
}

.fa-circle-radiation:before, .fa-radiation-alt:before {
  content: "";
}

.fa-baseball:before, .fa-baseball-ball:before {
  content: "";
}

.fa-jet-fighter-up:before {
  content: "";
}

.fa-diagram-project:before, .fa-project-diagram:before {
  content: "";
}

.fa-copy:before {
  content: "";
}

.fa-volume-xmark:before, .fa-volume-mute:before, .fa-volume-times:before {
  content: "";
}

.fa-hand-sparkles:before {
  content: "";
}

.fa-grip:before, .fa-grip-horizontal:before {
  content: "";
}

.fa-share-from-square:before, .fa-share-square:before {
  content: "";
}

.fa-child-combatant:before, .fa-child-rifle:before {
  content: "";
}

.fa-gun:before {
  content: "";
}

.fa-square-phone:before, .fa-phone-square:before {
  content: "";
}

.fa-plus:before, .fa-add:before {
  content: "+";
}

.fa-expand:before {
  content: "";
}

.fa-computer:before {
  content: "";
}

.fa-xmark:before, .fa-close:before, .fa-multiply:before, .fa-remove:before, .fa-times:before {
  content: "";
}

.fa-arrows-up-down-left-right:before, .fa-arrows:before {
  content: "";
}

.fa-chalkboard-user:before, .fa-chalkboard-teacher:before {
  content: "";
}

.fa-peso-sign:before {
  content: "";
}

.fa-building-shield:before {
  content: "";
}

.fa-baby:before {
  content: "";
}

.fa-users-line:before {
  content: "";
}

.fa-quote-left:before, .fa-quote-left-alt:before {
  content: "";
}

.fa-tractor:before {
  content: "";
}

.fa-trash-arrow-up:before, .fa-trash-restore:before {
  content: "";
}

.fa-arrow-down-up-lock:before {
  content: "";
}

.fa-lines-leaning:before {
  content: "";
}

.fa-ruler-combined:before {
  content: "";
}

.fa-copyright:before {
  content: "";
}

.fa-equals:before {
  content: "=";
}

.fa-blender:before {
  content: "";
}

.fa-teeth:before {
  content: "";
}

.fa-shekel-sign:before, .fa-ils:before, .fa-shekel:before, .fa-sheqel:before, .fa-sheqel-sign:before {
  content: "";
}

.fa-map:before {
  content: "";
}

.fa-rocket:before {
  content: "";
}

.fa-photo-film:before, .fa-photo-video:before {
  content: "";
}

.fa-folder-minus:before {
  content: "";
}

.fa-store:before {
  content: "";
}

.fa-arrow-trend-up:before {
  content: "";
}

.fa-plug-circle-minus:before {
  content: "";
}

.fa-sign-hanging:before, .fa-sign:before {
  content: "";
}

.fa-bezier-curve:before {
  content: "";
}

.fa-bell-slash:before {
  content: "";
}

.fa-tablet:before, .fa-tablet-android:before {
  content: "";
}

.fa-school-flag:before {
  content: "";
}

.fa-fill:before {
  content: "";
}

.fa-angle-up:before {
  content: "";
}

.fa-drumstick-bite:before {
  content: "";
}

.fa-holly-berry:before {
  content: "";
}

.fa-chevron-left:before {
  content: "";
}

.fa-bacteria:before {
  content: "";
}

.fa-hand-lizard:before {
  content: "";
}

.fa-notdef:before {
  content: "";
}

.fa-disease:before {
  content: "";
}

.fa-briefcase-medical:before {
  content: "";
}

.fa-genderless:before {
  content: "";
}

.fa-chevron-right:before {
  content: "";
}

.fa-retweet:before {
  content: "";
}

.fa-car-rear:before, .fa-car-alt:before {
  content: "";
}

.fa-pump-soap:before {
  content: "";
}

.fa-video-slash:before {
  content: "";
}

.fa-battery-quarter:before, .fa-battery-2:before {
  content: "";
}

.fa-radio:before {
  content: "";
}

.fa-baby-carriage:before, .fa-carriage-baby:before {
  content: "";
}

.fa-traffic-light:before {
  content: "";
}

.fa-thermometer:before {
  content: "";
}

.fa-vr-cardboard:before {
  content: "";
}

.fa-hand-middle-finger:before {
  content: "";
}

.fa-percent:before, .fa-percentage:before {
  content: "%";
}

.fa-truck-moving:before {
  content: "";
}

.fa-glass-water-droplet:before {
  content: "";
}

.fa-display:before {
  content: "";
}

.fa-face-smile:before, .fa-smile:before {
  content: "";
}

.fa-thumbtack:before, .fa-thumb-tack:before {
  content: "";
}

.fa-trophy:before {
  content: "";
}

.fa-person-praying:before, .fa-pray:before {
  content: "";
}

.fa-hammer:before {
  content: "";
}

.fa-hand-peace:before {
  content: "";
}

.fa-rotate:before, .fa-sync-alt:before {
  content: "";
}

.fa-spinner:before {
  content: "";
}

.fa-robot:before {
  content: "";
}

.fa-peace:before {
  content: "";
}

.fa-gears:before, .fa-cogs:before {
  content: "";
}

.fa-warehouse:before {
  content: "";
}

.fa-arrow-up-right-dots:before {
  content: "";
}

.fa-splotch:before {
  content: "";
}

.fa-face-grin-hearts:before, .fa-grin-hearts:before {
  content: "";
}

.fa-dice-four:before {
  content: "";
}

.fa-sim-card:before {
  content: "";
}

.fa-transgender:before, .fa-transgender-alt:before {
  content: "";
}

.fa-mercury:before {
  content: "";
}

.fa-arrow-turn-down:before, .fa-level-down:before {
  content: "";
}

.fa-person-falling-burst:before {
  content: "";
}

.fa-award:before {
  content: "";
}

.fa-ticket-simple:before, .fa-ticket-alt:before {
  content: "";
}

.fa-building:before {
  content: "";
}

.fa-angles-left:before, .fa-angle-double-left:before {
  content: "";
}

.fa-qrcode:before {
  content: "";
}

.fa-clock-rotate-left:before, .fa-history:before {
  content: "";
}

.fa-face-grin-beam-sweat:before, .fa-grin-beam-sweat:before {
  content: "";
}

.fa-file-export:before, .fa-arrow-right-from-file:before {
  content: "";
}

.fa-shield:before, .fa-shield-blank:before {
  content: "";
}

.fa-arrow-up-short-wide:before, .fa-sort-amount-up-alt:before {
  content: "";
}

.fa-house-medical:before {
  content: "";
}

.fa-golf-ball-tee:before, .fa-golf-ball:before {
  content: "";
}

.fa-circle-chevron-left:before, .fa-chevron-circle-left:before {
  content: "";
}

.fa-house-chimney-window:before {
  content: "";
}

.fa-pen-nib:before {
  content: "";
}

.fa-tent-arrow-turn-left:before {
  content: "";
}

.fa-tents:before {
  content: "";
}

.fa-wand-magic:before, .fa-magic:before {
  content: "";
}

.fa-dog:before {
  content: "";
}

.fa-carrot:before {
  content: "";
}

.fa-moon:before {
  content: "";
}

.fa-wine-glass-empty:before, .fa-wine-glass-alt:before {
  content: "";
}

.fa-cheese:before {
  content: "";
}

.fa-yin-yang:before {
  content: "";
}

.fa-music:before {
  content: "";
}

.fa-code-commit:before {
  content: "";
}

.fa-temperature-low:before {
  content: "";
}

.fa-person-biking:before, .fa-biking:before {
  content: "";
}

.fa-broom:before {
  content: "";
}

.fa-shield-heart:before {
  content: "";
}

.fa-gopuram:before {
  content: "";
}

.fa-earth-oceania:before, .fa-globe-oceania:before {
  content: "";
}

.fa-square-xmark:before, .fa-times-square:before, .fa-xmark-square:before {
  content: "";
}

.fa-hashtag:before {
  content: "#";
}

.fa-up-right-and-down-left-from-center:before, .fa-expand-alt:before {
  content: "";
}

.fa-oil-can:before {
  content: "";
}

.fa-t:before {
  content: "T";
}

.fa-hippo:before {
  content: "";
}

.fa-chart-column:before {
  content: "";
}

.fa-infinity:before {
  content: "";
}

.fa-vial-circle-check:before {
  content: "";
}

.fa-person-arrow-down-to-line:before {
  content: "";
}

.fa-voicemail:before {
  content: "";
}

.fa-fan:before {
  content: "";
}

.fa-person-walking-luggage:before {
  content: "";
}

.fa-up-down:before, .fa-arrows-alt-v:before {
  content: "";
}

.fa-cloud-moon-rain:before {
  content: "";
}

.fa-calendar:before {
  content: "";
}

.fa-trailer:before {
  content: "";
}

.fa-bahai:before, .fa-haykal:before {
  content: "";
}

.fa-sd-card:before {
  content: "";
}

.fa-dragon:before {
  content: "";
}

.fa-shoe-prints:before {
  content: "";
}

.fa-circle-plus:before, .fa-plus-circle:before {
  content: "";
}

.fa-face-grin-tongue-wink:before, .fa-grin-tongue-wink:before {
  content: "";
}

.fa-hand-holding:before {
  content: "";
}

.fa-plug-circle-exclamation:before {
  content: "";
}

.fa-link-slash:before, .fa-chain-broken:before, .fa-chain-slash:before, .fa-unlink:before {
  content: "";
}

.fa-clone:before {
  content: "";
}

.fa-person-walking-arrow-loop-left:before {
  content: "";
}

.fa-arrow-up-z-a:before, .fa-sort-alpha-up-alt:before {
  content: "";
}

.fa-fire-flame-curved:before, .fa-fire-alt:before {
  content: "";
}

.fa-tornado:before {
  content: "";
}

.fa-file-circle-plus:before {
  content: "";
}

.fa-book-quran:before, .fa-quran:before {
  content: "";
}

.fa-anchor:before {
  content: "";
}

.fa-border-all:before {
  content: "";
}

.fa-face-angry:before, .fa-angry:before {
  content: "";
}

.fa-cookie-bite:before {
  content: "";
}

.fa-arrow-trend-down:before {
  content: "";
}

.fa-rss:before, .fa-feed:before {
  content: "";
}

.fa-draw-polygon:before {
  content: "";
}

.fa-scale-balanced:before, .fa-balance-scale:before {
  content: "";
}

.fa-gauge-simple-high:before, .fa-tachometer:before, .fa-tachometer-fast:before {
  content: "";
}

.fa-shower:before {
  content: "";
}

.fa-desktop:before, .fa-desktop-alt:before {
  content: "";
}

.fa-m:before {
  content: "M";
}

.fa-table-list:before, .fa-th-list:before {
  content: "";
}

.fa-comment-sms:before, .fa-sms:before {
  content: "";
}

.fa-book:before {
  content: "";
}

.fa-user-plus:before {
  content: "";
}

.fa-check:before {
  content: "";
}

.fa-battery-three-quarters:before, .fa-battery-4:before {
  content: "";
}

.fa-house-circle-check:before {
  content: "";
}

.fa-angle-left:before {
  content: "";
}

.fa-diagram-successor:before {
  content: "";
}

.fa-truck-arrow-right:before {
  content: "";
}

.fa-arrows-split-up-and-left:before {
  content: "";
}

.fa-hand-fist:before, .fa-fist-raised:before {
  content: "";
}

.fa-cloud-moon:before {
  content: "";
}

.fa-briefcase:before {
  content: "";
}

.fa-person-falling:before {
  content: "";
}

.fa-image-portrait:before, .fa-portrait:before {
  content: "";
}

.fa-user-tag:before {
  content: "";
}

.fa-rug:before {
  content: "";
}

.fa-earth-europe:before, .fa-globe-europe:before {
  content: "";
}

.fa-cart-flatbed-suitcase:before, .fa-luggage-cart:before {
  content: "";
}

.fa-rectangle-xmark:before, .fa-rectangle-times:before, .fa-times-rectangle:before, .fa-window-close:before {
  content: "";
}

.fa-baht-sign:before {
  content: "";
}

.fa-book-open:before {
  content: "";
}

.fa-book-journal-whills:before, .fa-journal-whills:before {
  content: "";
}

.fa-handcuffs:before {
  content: "";
}

.fa-triangle-exclamation:before, .fa-exclamation-triangle:before, .fa-warning:before {
  content: "";
}

.fa-database:before {
  content: "";
}

.fa-share:before, .fa-arrow-turn-right:before, .fa-mail-forward:before {
  content: "";
}

.fa-bottle-droplet:before {
  content: "";
}

.fa-mask-face:before {
  content: "";
}

.fa-hill-rockslide:before {
  content: "";
}

.fa-right-left:before, .fa-exchange-alt:before {
  content: "";
}

.fa-paper-plane:before {
  content: "";
}

.fa-road-circle-exclamation:before {
  content: "";
}

.fa-dungeon:before {
  content: "";
}

.fa-align-right:before {
  content: "";
}

.fa-money-bill-1-wave:before, .fa-money-bill-wave-alt:before {
  content: "";
}

.fa-life-ring:before {
  content: "";
}

.fa-hands:before, .fa-sign-language:before, .fa-signing:before {
  content: "";
}

.fa-calendar-day:before {
  content: "";
}

.fa-water-ladder:before, .fa-ladder-water:before, .fa-swimming-pool:before {
  content: "";
}

.fa-arrows-up-down:before, .fa-arrows-v:before {
  content: "";
}

.fa-face-grimace:before, .fa-grimace:before {
  content: "";
}

.fa-wheelchair-move:before, .fa-wheelchair-alt:before {
  content: "";
}

.fa-turn-down:before, .fa-level-down-alt:before {
  content: "";
}

.fa-person-walking-arrow-right:before {
  content: "";
}

.fa-square-envelope:before, .fa-envelope-square:before {
  content: "";
}

.fa-dice:before {
  content: "";
}

.fa-bowling-ball:before {
  content: "";
}

.fa-brain:before {
  content: "";
}

.fa-bandage:before, .fa-band-aid:before {
  content: "";
}

.fa-calendar-minus:before {
  content: "";
}

.fa-circle-xmark:before, .fa-times-circle:before, .fa-xmark-circle:before {
  content: "";
}

.fa-gifts:before {
  content: "";
}

.fa-hotel:before {
  content: "";
}

.fa-earth-asia:before, .fa-globe-asia:before {
  content: "";
}

.fa-id-card-clip:before, .fa-id-card-alt:before {
  content: "";
}

.fa-magnifying-glass-plus:before, .fa-search-plus:before {
  content: "";
}

.fa-thumbs-up:before {
  content: "";
}

.fa-user-clock:before {
  content: "";
}

.fa-hand-dots:before, .fa-allergies:before {
  content: "";
}

.fa-file-invoice:before {
  content: "";
}

.fa-window-minimize:before {
  content: "";
}

.fa-mug-saucer:before, .fa-coffee:before {
  content: "";
}

.fa-brush:before {
  content: "";
}

.fa-mask:before {
  content: "";
}

.fa-magnifying-glass-minus:before, .fa-search-minus:before {
  content: "";
}

.fa-ruler-vertical:before {
  content: "";
}

.fa-user-large:before, .fa-user-alt:before {
  content: "";
}

.fa-train-tram:before {
  content: "";
}

.fa-user-nurse:before {
  content: "";
}

.fa-syringe:before {
  content: "";
}

.fa-cloud-sun:before {
  content: "";
}

.fa-stopwatch-20:before {
  content: "";
}

.fa-square-full:before {
  content: "";
}

.fa-magnet:before {
  content: "";
}

.fa-jar:before {
  content: "";
}

.fa-note-sticky:before, .fa-sticky-note:before {
  content: "";
}

.fa-bug-slash:before {
  content: "";
}

.fa-arrow-up-from-water-pump:before {
  content: "";
}

.fa-bone:before {
  content: "";
}

.fa-user-injured:before {
  content: "";
}

.fa-face-sad-tear:before, .fa-sad-tear:before {
  content: "";
}

.fa-plane:before {
  content: "";
}

.fa-tent-arrows-down:before {
  content: "";
}

.fa-exclamation:before {
  content: "!";
}

.fa-arrows-spin:before {
  content: "";
}

.fa-print:before {
  content: "";
}

.fa-turkish-lira-sign:before, .fa-try:before, .fa-turkish-lira:before {
  content: "";
}

.fa-dollar-sign:before, .fa-dollar:before, .fa-usd:before {
  content: "$";
}

.fa-x:before {
  content: "X";
}

.fa-magnifying-glass-dollar:before, .fa-search-dollar:before {
  content: "";
}

.fa-users-gear:before, .fa-users-cog:before {
  content: "";
}

.fa-person-military-pointing:before {
  content: "";
}

.fa-building-columns:before, .fa-bank:before, .fa-institution:before, .fa-museum:before, .fa-university:before {
  content: "";
}

.fa-umbrella:before {
  content: "";
}

.fa-trowel:before {
  content: "";
}

.fa-d:before {
  content: "D";
}

.fa-stapler:before {
  content: "";
}

.fa-masks-theater:before, .fa-theater-masks:before {
  content: "";
}

.fa-kip-sign:before {
  content: "";
}

.fa-hand-point-left:before {
  content: "";
}

.fa-handshake-simple:before, .fa-handshake-alt:before {
  content: "";
}

.fa-jet-fighter:before, .fa-fighter-jet:before {
  content: "";
}

.fa-square-share-nodes:before, .fa-share-alt-square:before {
  content: "";
}

.fa-barcode:before {
  content: "";
}

.fa-plus-minus:before {
  content: "";
}

.fa-video:before, .fa-video-camera:before {
  content: "";
}

.fa-graduation-cap:before, .fa-mortar-board:before {
  content: "";
}

.fa-hand-holding-medical:before {
  content: "";
}

.fa-person-circle-check:before {
  content: "";
}

.fa-turn-up:before, .fa-level-up-alt:before {
  content: "";
}

.sr-only, .fa-sr-only, .sr-only-focusable:not(:focus), .fa-sr-only-focusable:not(:focus) {
  width: 1px;
  height: 1px;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

:root, :host {
  --fa-style-family-brands: "Font Awesome 6 Brands";
  --fa-font-brands: normal 400 1em / 1 "Font Awesome 6 Brands";
}

@font-face {
  font-family: "Font Awesome 6 Brands";
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url("fa-brands-400.a0641c0f.woff2") format("woff2"), url("fa-brands-400.381ee61b.ttf") format("truetype");
}

.fab, .fa-brands {
  font-weight: 400;
}

.fa-monero:before {
  content: "";
}

.fa-hooli:before {
  content: "";
}

.fa-yelp:before {
  content: "";
}

.fa-cc-visa:before {
  content: "";
}

.fa-lastfm:before {
  content: "";
}

.fa-shopware:before {
  content: "";
}

.fa-creative-commons-nc:before {
  content: "";
}

.fa-aws:before {
  content: "";
}

.fa-redhat:before {
  content: "";
}

.fa-yoast:before {
  content: "";
}

.fa-cloudflare:before {
  content: "";
}

.fa-ups:before {
  content: "";
}

.fa-wpexplorer:before {
  content: "";
}

.fa-dyalog:before {
  content: "";
}

.fa-bity:before {
  content: "";
}

.fa-stackpath:before {
  content: "";
}

.fa-buysellads:before {
  content: "";
}

.fa-first-order:before {
  content: "";
}

.fa-modx:before {
  content: "";
}

.fa-guilded:before {
  content: "";
}

.fa-vnv:before {
  content: "";
}

.fa-square-js:before, .fa-js-square:before {
  content: "";
}

.fa-microsoft:before {
  content: "";
}

.fa-qq:before {
  content: "";
}

.fa-orcid:before {
  content: "";
}

.fa-java:before {
  content: "";
}

.fa-invision:before {
  content: "";
}

.fa-creative-commons-pd-alt:before {
  content: "";
}

.fa-centercode:before {
  content: "";
}

.fa-glide-g:before {
  content: "";
}

.fa-drupal:before {
  content: "";
}

.fa-hire-a-helper:before {
  content: "";
}

.fa-creative-commons-by:before {
  content: "";
}

.fa-unity:before {
  content: "";
}

.fa-whmcs:before {
  content: "";
}

.fa-rocketchat:before {
  content: "";
}

.fa-vk:before {
  content: "";
}

.fa-untappd:before {
  content: "";
}

.fa-mailchimp:before {
  content: "";
}

.fa-css3-alt:before {
  content: "";
}

.fa-square-reddit:before, .fa-reddit-square:before {
  content: "";
}

.fa-vimeo-v:before {
  content: "";
}

.fa-contao:before {
  content: "";
}

.fa-square-font-awesome:before {
  content: "";
}

.fa-deskpro:before {
  content: "";
}

.fa-sistrix:before {
  content: "";
}

.fa-square-instagram:before, .fa-instagram-square:before {
  content: "";
}

.fa-battle-net:before {
  content: "";
}

.fa-the-red-yeti:before {
  content: "";
}

.fa-square-hacker-news:before, .fa-hacker-news-square:before {
  content: "";
}

.fa-edge:before {
  content: "";
}

.fa-napster:before {
  content: "";
}

.fa-square-snapchat:before, .fa-snapchat-square:before {
  content: "";
}

.fa-google-plus-g:before {
  content: "";
}

.fa-artstation:before {
  content: "";
}

.fa-markdown:before {
  content: "";
}

.fa-sourcetree:before {
  content: "";
}

.fa-google-plus:before {
  content: "";
}

.fa-diaspora:before {
  content: "";
}

.fa-foursquare:before {
  content: "";
}

.fa-stack-overflow:before {
  content: "";
}

.fa-github-alt:before {
  content: "";
}

.fa-phoenix-squadron:before {
  content: "";
}

.fa-pagelines:before {
  content: "";
}

.fa-algolia:before {
  content: "";
}

.fa-red-river:before {
  content: "";
}

.fa-creative-commons-sa:before {
  content: "";
}

.fa-safari:before {
  content: "";
}

.fa-google:before {
  content: "";
}

.fa-square-font-awesome-stroke:before, .fa-font-awesome-alt:before {
  content: "";
}

.fa-atlassian:before {
  content: "";
}

.fa-linkedin-in:before {
  content: "";
}

.fa-digital-ocean:before {
  content: "";
}

.fa-nimblr:before {
  content: "";
}

.fa-chromecast:before {
  content: "";
}

.fa-evernote:before {
  content: "";
}

.fa-hacker-news:before {
  content: "";
}

.fa-creative-commons-sampling:before {
  content: "";
}

.fa-adversal:before {
  content: "";
}

.fa-creative-commons:before {
  content: "";
}

.fa-watchman-monitoring:before {
  content: "";
}

.fa-fonticons:before {
  content: "";
}

.fa-weixin:before {
  content: "";
}

.fa-shirtsinbulk:before {
  content: "";
}

.fa-codepen:before {
  content: "";
}

.fa-git-alt:before {
  content: "";
}

.fa-lyft:before {
  content: "";
}

.fa-rev:before {
  content: "";
}

.fa-windows:before {
  content: "";
}

.fa-wizards-of-the-coast:before {
  content: "";
}

.fa-square-viadeo:before, .fa-viadeo-square:before {
  content: "";
}

.fa-meetup:before {
  content: "";
}

.fa-centos:before {
  content: "";
}

.fa-adn:before {
  content: "";
}

.fa-cloudsmith:before {
  content: "";
}

.fa-pied-piper-alt:before {
  content: "";
}

.fa-square-dribbble:before, .fa-dribbble-square:before {
  content: "";
}

.fa-codiepie:before {
  content: "";
}

.fa-node:before {
  content: "";
}

.fa-mix:before {
  content: "";
}

.fa-steam:before {
  content: "";
}

.fa-cc-apple-pay:before {
  content: "";
}

.fa-scribd:before {
  content: "";
}

.fa-openid:before {
  content: "";
}

.fa-instalod:before {
  content: "";
}

.fa-expeditedssl:before {
  content: "";
}

.fa-sellcast:before {
  content: "";
}

.fa-square-twitter:before, .fa-twitter-square:before {
  content: "";
}

.fa-r-project:before {
  content: "";
}

.fa-delicious:before {
  content: "";
}

.fa-freebsd:before {
  content: "";
}

.fa-vuejs:before {
  content: "";
}

.fa-accusoft:before {
  content: "";
}

.fa-ioxhost:before {
  content: "";
}

.fa-fonticons-fi:before {
  content: "";
}

.fa-app-store:before {
  content: "";
}

.fa-cc-mastercard:before {
  content: "";
}

.fa-itunes-note:before {
  content: "";
}

.fa-golang:before {
  content: "";
}

.fa-kickstarter:before {
  content: "";
}

.fa-grav:before {
  content: "";
}

.fa-weibo:before {
  content: "";
}

.fa-uncharted:before {
  content: "";
}

.fa-firstdraft:before {
  content: "";
}

.fa-square-youtube:before, .fa-youtube-square:before {
  content: "";
}

.fa-wikipedia-w:before {
  content: "";
}

.fa-wpressr:before, .fa-rendact:before {
  content: "";
}

.fa-angellist:before {
  content: "";
}

.fa-galactic-republic:before {
  content: "";
}

.fa-nfc-directional:before {
  content: "";
}

.fa-skype:before {
  content: "";
}

.fa-joget:before {
  content: "";
}

.fa-fedora:before {
  content: "";
}

.fa-stripe-s:before {
  content: "";
}

.fa-meta:before {
  content: "";
}

.fa-laravel:before {
  content: "";
}

.fa-hotjar:before {
  content: "";
}

.fa-bluetooth-b:before {
  content: "";
}

.fa-sticker-mule:before {
  content: "";
}

.fa-creative-commons-zero:before {
  content: "";
}

.fa-hips:before {
  content: "";
}

.fa-behance:before {
  content: "";
}

.fa-reddit:before {
  content: "";
}

.fa-discord:before {
  content: "";
}

.fa-chrome:before {
  content: "";
}

.fa-app-store-ios:before {
  content: "";
}

.fa-cc-discover:before {
  content: "";
}

.fa-wpbeginner:before {
  content: "";
}

.fa-confluence:before {
  content: "";
}

.fa-mdb:before {
  content: "";
}

.fa-dochub:before {
  content: "";
}

.fa-accessible-icon:before {
  content: "";
}

.fa-ebay:before {
  content: "";
}

.fa-amazon:before {
  content: "";
}

.fa-unsplash:before {
  content: "";
}

.fa-yarn:before {
  content: "";
}

.fa-square-steam:before, .fa-steam-square:before {
  content: "";
}

.fa-500px:before {
  content: "";
}

.fa-square-vimeo:before, .fa-vimeo-square:before {
  content: "";
}

.fa-asymmetrik:before {
  content: "";
}

.fa-font-awesome:before, .fa-font-awesome-flag:before, .fa-font-awesome-logo-full:before {
  content: "";
}

.fa-gratipay:before {
  content: "";
}

.fa-apple:before {
  content: "";
}

.fa-hive:before {
  content: "";
}

.fa-gitkraken:before {
  content: "";
}

.fa-keybase:before {
  content: "";
}

.fa-apple-pay:before {
  content: "";
}

.fa-padlet:before {
  content: "";
}

.fa-amazon-pay:before {
  content: "";
}

.fa-square-github:before, .fa-github-square:before {
  content: "";
}

.fa-stumbleupon:before {
  content: "";
}

.fa-fedex:before {
  content: "";
}

.fa-phoenix-framework:before {
  content: "";
}

.fa-shopify:before {
  content: "";
}

.fa-neos:before {
  content: "";
}

.fa-hackerrank:before {
  content: "";
}

.fa-researchgate:before {
  content: "";
}

.fa-swift:before {
  content: "";
}

.fa-angular:before {
  content: "";
}

.fa-speakap:before {
  content: "";
}

.fa-angrycreative:before {
  content: "";
}

.fa-y-combinator:before {
  content: "";
}

.fa-empire:before {
  content: "";
}

.fa-envira:before {
  content: "";
}

.fa-square-gitlab:before, .fa-gitlab-square:before {
  content: "";
}

.fa-studiovinari:before {
  content: "";
}

.fa-pied-piper:before {
  content: "";
}

.fa-wordpress:before {
  content: "";
}

.fa-product-hunt:before {
  content: "";
}

.fa-firefox:before {
  content: "";
}

.fa-linode:before {
  content: "";
}

.fa-goodreads:before {
  content: "";
}

.fa-square-odnoklassniki:before, .fa-odnoklassniki-square:before {
  content: "";
}

.fa-jsfiddle:before {
  content: "";
}

.fa-sith:before {
  content: "";
}

.fa-themeisle:before {
  content: "";
}

.fa-page4:before {
  content: "";
}

.fa-hashnode:before {
  content: "";
}

.fa-react:before {
  content: "";
}

.fa-cc-paypal:before {
  content: "";
}

.fa-squarespace:before {
  content: "";
}

.fa-cc-stripe:before {
  content: "";
}

.fa-creative-commons-share:before {
  content: "";
}

.fa-bitcoin:before {
  content: "";
}

.fa-keycdn:before {
  content: "";
}

.fa-opera:before {
  content: "";
}

.fa-itch-io:before {
  content: "";
}

.fa-umbraco:before {
  content: "";
}

.fa-galactic-senate:before {
  content: "";
}

.fa-ubuntu:before {
  content: "";
}

.fa-draft2digital:before {
  content: "";
}

.fa-stripe:before {
  content: "";
}

.fa-houzz:before {
  content: "";
}

.fa-gg:before {
  content: "";
}

.fa-dhl:before {
  content: "";
}

.fa-square-pinterest:before, .fa-pinterest-square:before {
  content: "";
}

.fa-xing:before {
  content: "";
}

.fa-blackberry:before {
  content: "";
}

.fa-creative-commons-pd:before {
  content: "";
}

.fa-playstation:before {
  content: "";
}

.fa-quinscape:before {
  content: "";
}

.fa-less:before {
  content: "";
}

.fa-blogger-b:before {
  content: "";
}

.fa-opencart:before {
  content: "";
}

.fa-vine:before {
  content: "";
}

.fa-paypal:before {
  content: "";
}

.fa-gitlab:before {
  content: "";
}

.fa-typo3:before {
  content: "";
}

.fa-reddit-alien:before {
  content: "";
}

.fa-yahoo:before {
  content: "";
}

.fa-dailymotion:before {
  content: "";
}

.fa-affiliatetheme:before {
  content: "";
}

.fa-pied-piper-pp:before {
  content: "";
}

.fa-bootstrap:before {
  content: "";
}

.fa-odnoklassniki:before {
  content: "";
}

.fa-nfc-symbol:before {
  content: "";
}

.fa-ethereum:before {
  content: "";
}

.fa-speaker-deck:before {
  content: "";
}

.fa-creative-commons-nc-eu:before {
  content: "";
}

.fa-patreon:before {
  content: "";
}

.fa-avianex:before {
  content: "";
}

.fa-ello:before {
  content: "";
}

.fa-gofore:before {
  content: "";
}

.fa-bimobject:before {
  content: "";
}

.fa-facebook-f:before {
  content: "";
}

.fa-square-google-plus:before, .fa-google-plus-square:before {
  content: "";
}

.fa-mandalorian:before {
  content: "";
}

.fa-first-order-alt:before {
  content: "";
}

.fa-osi:before {
  content: "";
}

.fa-google-wallet:before {
  content: "";
}

.fa-d-and-d-beyond:before {
  content: "";
}

.fa-periscope:before {
  content: "";
}

.fa-fulcrum:before {
  content: "";
}

.fa-cloudscale:before {
  content: "";
}

.fa-forumbee:before {
  content: "";
}

.fa-mizuni:before {
  content: "";
}

.fa-schlix:before {
  content: "";
}

.fa-square-xing:before, .fa-xing-square:before {
  content: "";
}

.fa-bandcamp:before {
  content: "";
}

.fa-wpforms:before {
  content: "";
}

.fa-cloudversify:before {
  content: "";
}

.fa-usps:before {
  content: "";
}

.fa-megaport:before {
  content: "";
}

.fa-magento:before {
  content: "";
}

.fa-spotify:before {
  content: "";
}

.fa-optin-monster:before {
  content: "";
}

.fa-fly:before {
  content: "";
}

.fa-aviato:before {
  content: "";
}

.fa-itunes:before {
  content: "";
}

.fa-cuttlefish:before {
  content: "";
}

.fa-blogger:before {
  content: "";
}

.fa-flickr:before {
  content: "";
}

.fa-viber:before {
  content: "";
}

.fa-soundcloud:before {
  content: "";
}

.fa-digg:before {
  content: "";
}

.fa-tencent-weibo:before {
  content: "";
}

.fa-symfony:before {
  content: "";
}

.fa-maxcdn:before {
  content: "";
}

.fa-etsy:before {
  content: "";
}

.fa-facebook-messenger:before {
  content: "";
}

.fa-audible:before {
  content: "";
}

.fa-think-peaks:before {
  content: "";
}

.fa-bilibili:before {
  content: "";
}

.fa-erlang:before {
  content: "";
}

.fa-cotton-bureau:before {
  content: "";
}

.fa-dashcube:before {
  content: "";
}

.fa-42-group:before, .fa-innosoft:before {
  content: "";
}

.fa-stack-exchange:before {
  content: "";
}

.fa-elementor:before {
  content: "";
}

.fa-square-pied-piper:before, .fa-pied-piper-square:before {
  content: "";
}

.fa-creative-commons-nd:before {
  content: "";
}

.fa-palfed:before {
  content: "";
}

.fa-superpowers:before {
  content: "";
}

.fa-resolving:before {
  content: "";
}

.fa-xbox:before {
  content: "";
}

.fa-searchengin:before {
  content: "";
}

.fa-tiktok:before {
  content: "";
}

.fa-square-facebook:before, .fa-facebook-square:before {
  content: "";
}

.fa-renren:before {
  content: "";
}

.fa-linux:before {
  content: "";
}

.fa-glide:before {
  content: "";
}

.fa-linkedin:before {
  content: "";
}

.fa-hubspot:before {
  content: "";
}

.fa-deploydog:before {
  content: "";
}

.fa-twitch:before {
  content: "";
}

.fa-ravelry:before {
  content: "";
}

.fa-mixer:before {
  content: "";
}

.fa-square-lastfm:before, .fa-lastfm-square:before {
  content: "";
}

.fa-vimeo:before {
  content: "";
}

.fa-mendeley:before {
  content: "";
}

.fa-uniregistry:before {
  content: "";
}

.fa-figma:before {
  content: "";
}

.fa-creative-commons-remix:before {
  content: "";
}

.fa-cc-amazon-pay:before {
  content: "";
}

.fa-dropbox:before {
  content: "";
}

.fa-instagram:before {
  content: "";
}

.fa-cmplid:before {
  content: "";
}

.fa-facebook:before {
  content: "";
}

.fa-gripfire:before {
  content: "";
}

.fa-jedi-order:before {
  content: "";
}

.fa-uikit:before {
  content: "";
}

.fa-fort-awesome-alt:before {
  content: "";
}

.fa-phabricator:before {
  content: "";
}

.fa-ussunnah:before {
  content: "";
}

.fa-earlybirds:before {
  content: "";
}

.fa-trade-federation:before {
  content: "";
}

.fa-autoprefixer:before {
  content: "";
}

.fa-whatsapp:before {
  content: "";
}

.fa-slideshare:before {
  content: "";
}

.fa-google-play:before {
  content: "";
}

.fa-viadeo:before {
  content: "";
}

.fa-line:before {
  content: "";
}

.fa-google-drive:before {
  content: "";
}

.fa-servicestack:before {
  content: "";
}

.fa-simplybuilt:before {
  content: "";
}

.fa-bitbucket:before {
  content: "";
}

.fa-imdb:before {
  content: "";
}

.fa-deezer:before {
  content: "";
}

.fa-raspberry-pi:before {
  content: "";
}

.fa-jira:before {
  content: "";
}

.fa-docker:before {
  content: "";
}

.fa-screenpal:before {
  content: "";
}

.fa-bluetooth:before {
  content: "";
}

.fa-gitter:before {
  content: "";
}

.fa-d-and-d:before {
  content: "";
}

.fa-microblog:before {
  content: "";
}

.fa-cc-diners-club:before {
  content: "";
}

.fa-gg-circle:before {
  content: "";
}

.fa-pied-piper-hat:before {
  content: "";
}

.fa-kickstarter-k:before {
  content: "";
}

.fa-yandex:before {
  content: "";
}

.fa-readme:before {
  content: "";
}

.fa-html5:before {
  content: "";
}

.fa-sellsy:before {
  content: "";
}

.fa-sass:before {
  content: "";
}

.fa-wirsindhandwerk:before, .fa-wsh:before {
  content: "";
}

.fa-buromobelexperte:before {
  content: "";
}

.fa-salesforce:before {
  content: "";
}

.fa-octopus-deploy:before {
  content: "";
}

.fa-medapps:before {
  content: "";
}

.fa-ns8:before {
  content: "";
}

.fa-pinterest-p:before {
  content: "";
}

.fa-apper:before {
  content: "";
}

.fa-fort-awesome:before {
  content: "";
}

.fa-waze:before {
  content: "";
}

.fa-cc-jcb:before {
  content: "";
}

.fa-snapchat:before, .fa-snapchat-ghost:before {
  content: "";
}

.fa-fantasy-flight-games:before {
  content: "";
}

.fa-rust:before {
  content: "";
}

.fa-wix:before {
  content: "";
}

.fa-square-behance:before, .fa-behance-square:before {
  content: "";
}

.fa-supple:before {
  content: "";
}

.fa-rebel:before {
  content: "";
}

.fa-css3:before {
  content: "";
}

.fa-staylinked:before {
  content: "";
}

.fa-kaggle:before {
  content: "";
}

.fa-space-awesome:before {
  content: "";
}

.fa-deviantart:before {
  content: "";
}

.fa-cpanel:before {
  content: "";
}

.fa-goodreads-g:before {
  content: "";
}

.fa-square-git:before, .fa-git-square:before {
  content: "";
}

.fa-square-tumblr:before, .fa-tumblr-square:before {
  content: "";
}

.fa-trello:before {
  content: "";
}

.fa-creative-commons-nc-jp:before {
  content: "";
}

.fa-get-pocket:before {
  content: "";
}

.fa-perbyte:before {
  content: "";
}

.fa-grunt:before {
  content: "";
}

.fa-weebly:before {
  content: "";
}

.fa-connectdevelop:before {
  content: "";
}

.fa-leanpub:before {
  content: "";
}

.fa-black-tie:before {
  content: "";
}

.fa-themeco:before {
  content: "";
}

.fa-python:before {
  content: "";
}

.fa-android:before {
  content: "";
}

.fa-bots:before {
  content: "";
}

.fa-free-code-camp:before {
  content: "";
}

.fa-hornbill:before {
  content: "";
}

.fa-js:before {
  content: "";
}

.fa-ideal:before {
  content: "";
}

.fa-git:before {
  content: "";
}

.fa-dev:before {
  content: "";
}

.fa-sketch:before {
  content: "";
}

.fa-yandex-international:before {
  content: "";
}

.fa-cc-amex:before {
  content: "";
}

.fa-uber:before {
  content: "";
}

.fa-github:before {
  content: "";
}

.fa-php:before {
  content: "";
}

.fa-alipay:before {
  content: "";
}

.fa-youtube:before {
  content: "";
}

.fa-skyatlas:before {
  content: "";
}

.fa-firefox-browser:before {
  content: "";
}

.fa-replyd:before {
  content: "";
}

.fa-suse:before {
  content: "";
}

.fa-jenkins:before {
  content: "";
}

.fa-twitter:before {
  content: "";
}

.fa-rockrms:before {
  content: "";
}

.fa-pinterest:before {
  content: "";
}

.fa-buffer:before {
  content: "";
}

.fa-npm:before {
  content: "";
}

.fa-yammer:before {
  content: "";
}

.fa-btc:before {
  content: "";
}

.fa-dribbble:before {
  content: "";
}

.fa-stumbleupon-circle:before {
  content: "";
}

.fa-internet-explorer:before {
  content: "";
}

.fa-telegram:before, .fa-telegram-plane:before {
  content: "";
}

.fa-old-republic:before {
  content: "";
}

.fa-square-whatsapp:before, .fa-whatsapp-square:before {
  content: "";
}

.fa-node-js:before {
  content: "";
}

.fa-edge-legacy:before {
  content: "";
}

.fa-slack:before, .fa-slack-hash:before {
  content: "";
}

.fa-medrt:before {
  content: "";
}

.fa-usb:before {
  content: "";
}

.fa-tumblr:before {
  content: "";
}

.fa-vaadin:before {
  content: "";
}

.fa-quora:before {
  content: "";
}

.fa-reacteurope:before {
  content: "";
}

.fa-medium:before, .fa-medium-m:before {
  content: "";
}

.fa-amilia:before {
  content: "";
}

.fa-mixcloud:before {
  content: "";
}

.fa-flipboard:before {
  content: "";
}

.fa-viacoin:before {
  content: "";
}

.fa-critical-role:before {
  content: "";
}

.fa-sitrox:before {
  content: "";
}

.fa-discourse:before {
  content: "";
}

.fa-joomla:before {
  content: "";
}

.fa-mastodon:before {
  content: "";
}

.fa-airbnb:before {
  content: "";
}

.fa-wolf-pack-battalion:before {
  content: "";
}

.fa-buy-n-large:before {
  content: "";
}

.fa-gulp:before {
  content: "";
}

.fa-creative-commons-sampling-plus:before {
  content: "";
}

.fa-strava:before {
  content: "";
}

.fa-ember:before {
  content: "";
}

.fa-canadian-maple-leaf:before {
  content: "";
}

.fa-teamspeak:before {
  content: "";
}

.fa-pushed:before {
  content: "";
}

.fa-wordpress-simple:before {
  content: "";
}

.fa-nutritionix:before {
  content: "";
}

.fa-wodu:before {
  content: "";
}

.fa-google-pay:before {
  content: "";
}

.fa-intercom:before {
  content: "";
}

.fa-zhihu:before {
  content: "";
}

.fa-korvue:before {
  content: "";
}

.fa-pix:before {
  content: "";
}

.fa-steam-symbol:before {
  content: "";
}

:root, :host {
  --fa-style-family-classic: "Font Awesome 6 Free";
  --fa-font-regular: normal 400 1em / 1 "Font Awesome 6 Free";
}

@font-face {
  font-family: "Font Awesome 6 Free";
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url("fa-regular-400.5bae157a.woff2") format("woff2"), url("fa-regular-400.981cdc6e.ttf") format("truetype");
}

.far, .fa-regular {
  font-weight: 400;
}

:root, :host {
  --fa-style-family-classic: "Font Awesome 6 Free";
  --fa-font-solid: normal 900 1em / 1 "Font Awesome 6 Free";
}

@font-face {
  font-family: "Font Awesome 6 Free";
  font-style: normal;
  font-weight: 900;
  font-display: block;
  src: url("fa-solid-900.57a8ac1f.woff2") format("woff2"), url("fa-solid-900.754eb2f2.ttf") format("truetype");
}

.fas, .fa-solid {
  font-weight: 900;
}

@font-face {
  font-family: "Font Awesome 5 Brands";
  font-display: block;
  font-weight: 400;
  src: url("fa-brands-400.a0641c0f.woff2") format("woff2"), url("fa-brands-400.381ee61b.ttf") format("truetype");
}

@font-face {
  font-family: "Font Awesome 5 Free";
  font-display: block;
  font-weight: 900;
  src: url("fa-solid-900.57a8ac1f.woff2") format("woff2"), url("fa-solid-900.754eb2f2.ttf") format("truetype");
}

@font-face {
  font-family: "Font Awesome 5 Free";
  font-display: block;
  font-weight: 400;
  src: url("fa-regular-400.5bae157a.woff2") format("woff2"), url("fa-regular-400.981cdc6e.ttf") format("truetype");
}

@font-face {
  font-family: FontAwesome;
  font-display: block;
  src: url("fa-solid-900.57a8ac1f.woff2") format("woff2"), url("fa-solid-900.754eb2f2.ttf") format("truetype");
}

@font-face {
  font-family: FontAwesome;
  font-display: block;
  src: url("fa-brands-400.a0641c0f.woff2") format("woff2"), url("fa-brands-400.381ee61b.ttf") format("truetype");
}

@font-face {
  font-family: FontAwesome;
  font-display: block;
  src: url("fa-regular-400.5bae157a.woff2") format("woff2"), url("fa-regular-400.981cdc6e.ttf") format("truetype");
  unicode-range: U+F003, U+F006, U+F014, U+F016-F017, U+F01A-F01B, U+F01D, U+F022, U+F03E, U+F044, U+F046, U+F05C-F05D, U+F06E, U+F070, U+F087-F088, U+F08A, U+F094, U+F096-F097, U+F09D, U+F0A0, U+F0A2, U+F0A4-F0A7, U+F0C5, U+F0C7, U+F0E5-F0E6, U+F0EB, U+F0F6-F0F8, U+F10C, U+F114-F115, U+F118-F11A, U+F11C-F11D, U+F133, U+F147, U+F14E, U+F150-F152, U+F185-F186, U+F18E, U+F190-F192, U+F196, U+F1C1-F1C9, U+F1D9, U+F1DB, U+F1E3, U+F1EA, U+F1F7, U+F1F9, U+F20A, U+F247-F248, U+F24A, U+F24D, U+F255-F25B, U+F25D, U+F271-F274, U+F278, U+F27B, U+F28C, U+F28E, U+F29C, U+F2B5, U+F2B7, U+F2BA, U+F2BC, U+F2BE, U+F2C0-F2C1, U+F2C3, U+F2D0, U+F2D2, U+F2D4, U+F2DC;
}

@font-face {
  font-family: FontAwesome;
  font-display: block;
  src: url("fa-v4compatibility.c0440d6d.woff2") format("woff2"), url("fa-v4compatibility.232ac13e.ttf") format("truetype");
  unicode-range: U+F041, U+F047, U+F065-F066, U+F07D-F07E, U+F080, U+F08B, U+F08E, U+F090, U+F09A, U+F0AC, U+F0AE, U+F0B2, U+F0D0, U+F0D6, U+F0E4, U+F0EC, U+F10A-F10B, U+F123, U+F13E, U+F148-F149, U+F14C, U+F156, U+F15E, U+F160-F161, U+F163, U+F175-F178, U+F195, U+F1F8, U+F219, U+F27A;
}

@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("montserrat-cyrillic-ext-400-normal.82cc8bcf.woff2") format("woff2"), url("montserrat-all-400-normal.4a356507.woff") format("woff");
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("montserrat-cyrillic-400-normal.b7e1967a.woff2") format("woff2"), url("montserrat-all-400-normal.4a356507.woff") format("woff");
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("montserrat-vietnamese-400-normal.9dab74e8.woff2") format("woff2"), url("montserrat-all-400-normal.4a356507.woff") format("woff");
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("montserrat-latin-ext-400-normal.01c85d8d.woff2") format("woff2"), url("montserrat-all-400-normal.4a356507.woff") format("woff");
  unicode-range: U+100-24F, U+259, U+1E??, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("montserrat-latin-400-normal.ac08c953.woff2") format("woff2"), url("montserrat-all-400-normal.4a356507.woff") format("woff");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url("montserrat-cyrillic-ext-700-normal.eedb4ce5.woff2") format("woff2"), url("montserrat-all-700-normal.250ea919.woff") format("woff");
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url("montserrat-cyrillic-700-normal.f9084961.woff2") format("woff2"), url("montserrat-all-700-normal.250ea919.woff") format("woff");
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url("montserrat-vietnamese-700-normal.20e9f050.woff2") format("woff2"), url("montserrat-all-700-normal.250ea919.woff") format("woff");
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url("montserrat-latin-ext-700-normal.572cf3e2.woff2") format("woff2"), url("montserrat-all-700-normal.250ea919.woff") format("woff");
  unicode-range: U+100-24F, U+259, U+1E??, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url("montserrat-latin-700-normal.6484fdfd.woff2") format("woff2"), url("montserrat-all-700-normal.250ea919.woff") format("woff");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Nunito Sans;
  font-style: normal;
  font-display: swap;
  font-weight: 200;
  src: url("nunito-sans-cyrillic-ext-200-normal.99caf498.woff2") format("woff2"), url("nunito-sans-all-200-normal.86945a78.woff") format("woff");
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-family: Nunito Sans;
  font-style: normal;
  font-display: swap;
  font-weight: 200;
  src: url("nunito-sans-cyrillic-200-normal.b92bed99.woff2") format("woff2"), url("nunito-sans-all-200-normal.86945a78.woff") format("woff");
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-family: Nunito Sans;
  font-style: normal;
  font-display: swap;
  font-weight: 200;
  src: url("nunito-sans-vietnamese-200-normal.709e664d.woff2") format("woff2"), url("nunito-sans-all-200-normal.86945a78.woff") format("woff");
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: Nunito Sans;
  font-style: normal;
  font-display: swap;
  font-weight: 200;
  src: url("nunito-sans-latin-ext-200-normal.a60aaa43.woff2") format("woff2"), url("nunito-sans-all-200-normal.86945a78.woff") format("woff");
  unicode-range: U+100-24F, U+259, U+1E??, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Nunito Sans;
  font-style: normal;
  font-display: swap;
  font-weight: 200;
  src: url("nunito-sans-latin-200-normal.47e25736.woff2") format("woff2"), url("nunito-sans-all-200-normal.86945a78.woff") format("woff");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Nunito Sans;
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: url("nunito-sans-cyrillic-ext-300-normal.74337f5b.woff2") format("woff2"), url("nunito-sans-all-300-normal.ad792a50.woff") format("woff");
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-family: Nunito Sans;
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: url("nunito-sans-cyrillic-300-normal.e37f2796.woff2") format("woff2"), url("nunito-sans-all-300-normal.ad792a50.woff") format("woff");
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-family: Nunito Sans;
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: url("nunito-sans-vietnamese-300-normal.6a82c0fc.woff2") format("woff2"), url("nunito-sans-all-300-normal.ad792a50.woff") format("woff");
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: Nunito Sans;
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: url("nunito-sans-latin-ext-300-normal.52e16e86.woff2") format("woff2"), url("nunito-sans-all-300-normal.ad792a50.woff") format("woff");
  unicode-range: U+100-24F, U+259, U+1E??, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Nunito Sans;
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: url("nunito-sans-latin-300-normal.df5c3808.woff2") format("woff2"), url("nunito-sans-all-300-normal.ad792a50.woff") format("woff");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Nunito Sans;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("nunito-sans-cyrillic-ext-400-normal.fb91598d.woff2") format("woff2"), url("nunito-sans-all-400-normal.b6d97a67.woff") format("woff");
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-family: Nunito Sans;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("nunito-sans-cyrillic-400-normal.cce69b53.woff2") format("woff2"), url("nunito-sans-all-400-normal.b6d97a67.woff") format("woff");
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-family: Nunito Sans;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("nunito-sans-vietnamese-400-normal.f09dcde1.woff2") format("woff2"), url("nunito-sans-all-400-normal.b6d97a67.woff") format("woff");
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: Nunito Sans;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("nunito-sans-latin-ext-400-normal.fc12b7a0.woff2") format("woff2"), url("nunito-sans-all-400-normal.b6d97a67.woff") format("woff");
  unicode-range: U+100-24F, U+259, U+1E??, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Nunito Sans;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("nunito-sans-latin-400-normal.67acc80e.woff2") format("woff2"), url("nunito-sans-all-400-normal.b6d97a67.woff") format("woff");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Nunito Sans;
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src: url("nunito-sans-cyrillic-ext-600-normal.d1a3b803.woff2") format("woff2"), url("nunito-sans-all-600-normal.b4d59c7c.woff") format("woff");
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-family: Nunito Sans;
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src: url("nunito-sans-cyrillic-600-normal.5138f3ea.woff2") format("woff2"), url("nunito-sans-all-600-normal.b4d59c7c.woff") format("woff");
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-family: Nunito Sans;
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src: url("nunito-sans-vietnamese-600-normal.599210b3.woff2") format("woff2"), url("nunito-sans-all-600-normal.b4d59c7c.woff") format("woff");
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: Nunito Sans;
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src: url("nunito-sans-latin-ext-600-normal.ba19f0d7.woff2") format("woff2"), url("nunito-sans-all-600-normal.b4d59c7c.woff") format("woff");
  unicode-range: U+100-24F, U+259, U+1E??, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Nunito Sans;
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src: url("nunito-sans-latin-600-normal.251f65d0.woff2") format("woff2"), url("nunito-sans-all-600-normal.b4d59c7c.woff") format("woff");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Nunito Sans;
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url("nunito-sans-cyrillic-ext-700-normal.27d32f36.woff2") format("woff2"), url("nunito-sans-all-700-normal.ad339104.woff") format("woff");
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-family: Nunito Sans;
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url("nunito-sans-cyrillic-700-normal.0e97ba89.woff2") format("woff2"), url("nunito-sans-all-700-normal.ad339104.woff") format("woff");
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-family: Nunito Sans;
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url("nunito-sans-vietnamese-700-normal.8cce8701.woff2") format("woff2"), url("nunito-sans-all-700-normal.ad339104.woff") format("woff");
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: Nunito Sans;
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url("nunito-sans-latin-ext-700-normal.4234516b.woff2") format("woff2"), url("nunito-sans-all-700-normal.ad339104.woff") format("woff");
  unicode-range: U+100-24F, U+259, U+1E??, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Nunito Sans;
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url("nunito-sans-latin-700-normal.5961b4db.woff2") format("woff2"), url("nunito-sans-all-700-normal.ad339104.woff") format("woff");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

.leaflet-pane, .leaflet-tile, .leaflet-marker-icon, .leaflet-marker-shadow, .leaflet-tile-container, .leaflet-pane > svg, .leaflet-pane > canvas, .leaflet-zoom-box, .leaflet-image-layer, .leaflet-layer {
  position: absolute;
  top: 0;
  left: 0;
}

.leaflet-container {
  overflow: hidden;
}

.leaflet-tile, .leaflet-marker-icon, .leaflet-marker-shadow {
  -webkit-user-select: none;
  user-select: none;
  -webkit-user-drag: none;
}

.leaflet-tile::selection {
  background: none;
}

.leaflet-safari .leaflet-tile {
  image-rendering: -webkit-optimize-contrast;
}

.leaflet-safari .leaflet-tile-container {
  width: 1600px;
  height: 1600px;
  -webkit-transform-origin: 0 0;
}

.leaflet-marker-icon, .leaflet-marker-shadow {
  display: block;
}

.leaflet-container .leaflet-overlay-pane svg {
  max-width: none !important;
  max-height: none !important;
}

.leaflet-container .leaflet-marker-pane img, .leaflet-container .leaflet-shadow-pane img, .leaflet-container .leaflet-tile-pane img, .leaflet-container img.leaflet-image-layer, .leaflet-container .leaflet-tile {
  width: auto;
  padding: 0;
  max-width: none !important;
  max-height: none !important;
}

.leaflet-container.leaflet-touch-zoom {
  -ms-touch-action: pan-x pan-y;
  touch-action: pan-x pan-y;
}

.leaflet-container.leaflet-touch-drag {
  -ms-touch-action: pinch-zoom;
  touch-action: none;
  touch-action: pinch-zoom;
}

.leaflet-container.leaflet-touch-drag.leaflet-touch-zoom {
  -ms-touch-action: none;
  touch-action: none;
}

.leaflet-container {
  -webkit-tap-highlight-color: transparent;
}

.leaflet-container a {
  -webkit-tap-highlight-color: #33b5e566;
}

.leaflet-tile {
  filter: inherit;
  visibility: hidden;
}

.leaflet-tile-loaded {
  visibility: inherit;
}

.leaflet-zoom-box {
  width: 0;
  height: 0;
  box-sizing: border-box;
  z-index: 800;
}

.leaflet-overlay-pane svg {
  -moz-user-select: none;
}

.leaflet-pane {
  z-index: 400;
}

.leaflet-tile-pane {
  z-index: 200;
}

.leaflet-overlay-pane {
  z-index: 400;
}

.leaflet-shadow-pane {
  z-index: 500;
}

.leaflet-marker-pane {
  z-index: 600;
}

.leaflet-tooltip-pane {
  z-index: 650;
}

.leaflet-popup-pane {
  z-index: 700;
}

.leaflet-map-pane canvas {
  z-index: 100;
}

.leaflet-map-pane svg {
  z-index: 200;
}

.leaflet-vml-shape {
  width: 1px;
  height: 1px;
}

.lvml {
  behavior: url("#default#VML");
  display: inline-block;
  position: absolute;
}

.leaflet-control {
  z-index: 800;
  pointer-events: visiblePainted;
  pointer-events: auto;
  position: relative;
}

.leaflet-top, .leaflet-bottom {
  z-index: 1000;
  pointer-events: none;
  position: absolute;
}

.leaflet-top {
  top: 0;
}

.leaflet-right {
  right: 0;
}

.leaflet-bottom {
  bottom: 0;
}

.leaflet-left {
  left: 0;
}

.leaflet-control {
  float: left;
  clear: both;
}

.leaflet-right .leaflet-control {
  float: right;
}

.leaflet-top .leaflet-control {
  margin-top: 10px;
}

.leaflet-bottom .leaflet-control {
  margin-bottom: 10px;
}

.leaflet-left .leaflet-control {
  margin-left: 10px;
}

.leaflet-right .leaflet-control {
  margin-right: 10px;
}

.leaflet-fade-anim .leaflet-popup {
  opacity: 0;
  transition: opacity .2s linear;
}

.leaflet-fade-anim .leaflet-map-pane .leaflet-popup {
  opacity: 1;
}

.leaflet-zoom-animated {
  transform-origin: 0 0;
}

svg.leaflet-zoom-animated {
  will-change: transform;
}

.leaflet-zoom-anim .leaflet-zoom-animated {
  -webkit-transition: -webkit-transform .25s cubic-bezier(0, 0, .25, 1);
  -moz-transition: -moz-transform .25s cubic-bezier(0, 0, .25, 1);
  transition: transform .25s cubic-bezier(0, 0, .25, 1);
}

.leaflet-zoom-anim .leaflet-tile, .leaflet-pan-anim .leaflet-tile {
  transition: none;
}

.leaflet-zoom-anim .leaflet-zoom-hide {
  visibility: hidden;
}

.leaflet-interactive {
  cursor: pointer;
}

.leaflet-grab {
  cursor: -webkit-grab;
  cursor: -moz-grab;
  cursor: grab;
}

.leaflet-crosshair, .leaflet-crosshair .leaflet-interactive {
  cursor: crosshair;
}

.leaflet-popup-pane, .leaflet-control {
  cursor: auto;
}

.leaflet-dragging .leaflet-grab, .leaflet-dragging .leaflet-grab .leaflet-interactive, .leaflet-dragging .leaflet-marker-draggable {
  cursor: move;
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
  cursor: grabbing;
}

.leaflet-marker-icon, .leaflet-marker-shadow, .leaflet-image-layer, .leaflet-pane > svg path, .leaflet-tile-container {
  pointer-events: none;
}

.leaflet-marker-icon.leaflet-interactive, .leaflet-image-layer.leaflet-interactive, .leaflet-pane > svg path.leaflet-interactive, svg.leaflet-image-layer.leaflet-interactive path {
  pointer-events: visiblePainted;
  pointer-events: auto;
}

.leaflet-container {
  outline-offset: 1px;
  background: #ddd;
}

.leaflet-container a {
  color: #0078a8;
}

.leaflet-zoom-box {
  background: #ffffff80;
  border: 2px dotted #38f;
}

.leaflet-container {
  font-family: Helvetica Neue, Arial, Helvetica, sans-serif;
  font-size: .75rem;
  line-height: 1.5;
}

.leaflet-bar {
  border-radius: 4px;
  box-shadow: 0 1px 5px #000000a6;
}

.leaflet-bar a {
  width: 26px;
  height: 26px;
  text-align: center;
  color: #000;
  background-color: #fff;
  border-bottom: 1px solid #ccc;
  line-height: 26px;
  text-decoration: none;
  display: block;
}

.leaflet-bar a, .leaflet-control-layers-toggle {
  background-position: 50%;
  background-repeat: no-repeat;
  display: block;
}

.leaflet-bar a:hover, .leaflet-bar a:focus {
  background-color: #f4f4f4;
}

.leaflet-bar a:first-child {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.leaflet-bar a:last-child {
  border-bottom: none;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

.leaflet-bar a.leaflet-disabled {
  cursor: default;
  color: #bbb;
  background-color: #f4f4f4;
}

.leaflet-touch .leaflet-bar a {
  width: 30px;
  height: 30px;
  line-height: 30px;
}

.leaflet-touch .leaflet-bar a:first-child {
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}

.leaflet-touch .leaflet-bar a:last-child {
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 2px;
}

.leaflet-control-zoom-in, .leaflet-control-zoom-out {
  text-indent: 1px;
  font: bold 18px Lucida Console, Monaco, monospace;
}

.leaflet-touch .leaflet-control-zoom-in, .leaflet-touch .leaflet-control-zoom-out {
  font-size: 22px;
}

.leaflet-control-layers {
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 1px 5px #0006;
}

.leaflet-control-layers-toggle {
  width: 36px;
  height: 36px;
  background-image: url("layers.760a0456.png");
}

.leaflet-retina .leaflet-control-layers-toggle {
  background-image: url("layers-2x.b7b89169.png");
  background-size: 26px 26px;
}

.leaflet-touch .leaflet-control-layers-toggle {
  width: 44px;
  height: 44px;
}

.leaflet-control-layers .leaflet-control-layers-list, .leaflet-control-layers-expanded .leaflet-control-layers-toggle {
  display: none;
}

.leaflet-control-layers-expanded .leaflet-control-layers-list {
  display: block;
  position: relative;
}

.leaflet-control-layers-expanded {
  color: #333;
  background: #fff;
  padding: 6px 10px 6px 6px;
}

.leaflet-control-layers-scrollbar {
  padding-right: 5px;
  overflow-x: hidden;
  overflow-y: scroll;
}

.leaflet-control-layers-selector {
  margin-top: 2px;
  position: relative;
  top: 1px;
}

.leaflet-control-layers label {
  font-size: 1.08333em;
  display: block;
}

.leaflet-control-layers-separator {
  height: 0;
  border-top: 1px solid #ddd;
  margin: 5px -10px 5px -6px;
}

.leaflet-default-icon-path {
  background-image: url("marker-icon.3f7d3721.png");
}

.leaflet-container .leaflet-control-attribution {
  background: #fffc;
  margin: 0;
}

.leaflet-control-attribution, .leaflet-control-scale-line {
  color: #333;
  padding: 0 5px;
  line-height: 1.4;
}

.leaflet-control-attribution a {
  text-decoration: none;
}

.leaflet-control-attribution a:hover, .leaflet-control-attribution a:focus {
  text-decoration: underline;
}

.leaflet-attribution-flag {
  width: 1em;
  height: .6669em;
  vertical-align: baseline !important;
  display: inline !important;
}

.leaflet-left .leaflet-control-scale {
  margin-left: 5px;
}

.leaflet-bottom .leaflet-control-scale {
  margin-bottom: 5px;
}

.leaflet-control-scale-line {
  white-space: nowrap;
  box-sizing: border-box;
  text-shadow: 1px 1px #fff;
  background: #fffc;
  border: 2px solid #777;
  border-top: none;
  padding: 2px 5px 1px;
  line-height: 1.1;
}

.leaflet-control-scale-line:not(:first-child) {
  border-top: 2px solid #777;
  border-bottom: none;
  margin-top: -2px;
}

.leaflet-control-scale-line:not(:first-child):not(:last-child) {
  border-bottom: 2px solid #777;
}

.leaflet-touch .leaflet-control-attribution, .leaflet-touch .leaflet-control-layers, .leaflet-touch .leaflet-bar {
  box-shadow: none;
}

.leaflet-touch .leaflet-control-layers, .leaflet-touch .leaflet-bar {
  background-clip: padding-box;
  border: 2px solid #0003;
}

.leaflet-popup {
  text-align: center;
  margin-bottom: 20px;
  position: absolute;
}

.leaflet-popup-content-wrapper {
  text-align: left;
  border-radius: 12px;
  padding: 1px;
}

.leaflet-popup-content {
  min-height: 1px;
  margin: 13px 24px 13px 20px;
  font-size: 1.08333em;
  line-height: 1.3;
}

.leaflet-popup-content p {
  margin: 1.3em 0;
}

.leaflet-popup-tip-container {
  width: 40px;
  height: 20px;
  pointer-events: none;
  margin-top: -1px;
  margin-left: -20px;
  position: absolute;
  left: 50%;
  overflow: hidden;
}

.leaflet-popup-tip {
  width: 17px;
  height: 17px;
  pointer-events: auto;
  margin: -10px auto 0;
  padding: 1px;
  transform: rotate(45deg);
}

.leaflet-popup-content-wrapper, .leaflet-popup-tip {
  color: #333;
  background: #fff;
  box-shadow: 0 3px 14px #0006;
}

.leaflet-container a.leaflet-popup-close-button {
  text-align: center;
  width: 24px;
  height: 24px;
  color: #757575;
  background: none;
  border: none;
  font: 16px / 24px Tahoma, Verdana, sans-serif;
  text-decoration: none;
  position: absolute;
  top: 0;
  right: 0;
}

.leaflet-container a.leaflet-popup-close-button:hover, .leaflet-container a.leaflet-popup-close-button:focus {
  color: #585858;
}

.leaflet-popup-scrolled {
  overflow: auto;
}

.leaflet-oldie .leaflet-popup-content-wrapper {
  -ms-zoom: 1;
}

.leaflet-oldie .leaflet-popup-tip {
  width: 24px;
  -ms-filter: "progid:DXImageTransform.Microsoft.Matrix(M11=0.70710678, M12=0.70710678, M21=-0.70710678, M22=0.70710678)";
  filter: progid:DXImageTransform. Microsoft. Matrix(M11= .707107, M12= .707107, M21= -.707107, M22= .707107);
  margin: 0 auto;
}

.leaflet-oldie .leaflet-control-zoom, .leaflet-oldie .leaflet-control-layers, .leaflet-oldie .leaflet-popup-content-wrapper, .leaflet-oldie .leaflet-popup-tip {
  border: 1px solid #999;
}

.leaflet-div-icon {
  background: #fff;
  border: 1px solid #666;
}

.leaflet-tooltip {
  color: #222;
  white-space: nowrap;
  -webkit-user-select: none;
  user-select: none;
  pointer-events: none;
  background-color: #fff;
  border: 1px solid #fff;
  border-radius: 3px;
  padding: 6px;
  position: absolute;
  box-shadow: 0 1px 3px #0006;
}

.leaflet-tooltip.leaflet-interactive {
  cursor: pointer;
  pointer-events: auto;
}

.leaflet-tooltip-top:before, .leaflet-tooltip-bottom:before, .leaflet-tooltip-left:before, .leaflet-tooltip-right:before {
  pointer-events: none;
  content: "";
  background: none;
  border: 6px solid #0000;
  position: absolute;
}

.leaflet-tooltip-bottom {
  margin-top: 6px;
}

.leaflet-tooltip-top {
  margin-top: -6px;
}

.leaflet-tooltip-bottom:before, .leaflet-tooltip-top:before {
  margin-left: -6px;
  left: 50%;
}

.leaflet-tooltip-top:before {
  border-top-color: #fff;
  margin-bottom: -12px;
  bottom: 0;
}

.leaflet-tooltip-bottom:before {
  border-bottom-color: #fff;
  margin-top: -12px;
  margin-left: -6px;
  top: 0;
}

.leaflet-tooltip-left {
  margin-left: -6px;
}

.leaflet-tooltip-right {
  margin-left: 6px;
}

.leaflet-tooltip-left:before, .leaflet-tooltip-right:before {
  margin-top: -6px;
  top: 50%;
}

.leaflet-tooltip-left:before {
  border-left-color: #fff;
  margin-right: -12px;
  right: 0;
}

.leaflet-tooltip-right:before {
  border-right-color: #fff;
  margin-left: -12px;
  left: 0;
}

@media print {
  .leaflet-control {
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
  }
}

:root {
  --cirrus-fg: #0f172a;
  --cirrus-bg: #fff;
  --cirrus-select-bg: #00a1ff33;
  --cirrus-code-bg: #ffdadd;
  --cirrus-code-fg: #dc4753;
  --cirrus-form-group-bg: #f8f9fa;
  --cirrus-form-group-fg: #909090;
  --toast-primary-bg: #313b50e6;
  --animation-duration: .2s;
  --focus-opacity: .55;
  --font-size-xs: .75rem;
  --font-size-s: .875rem;
  --font-size-m: 1rem;
  --font-size-l: 1.25rem;
  --font-size-xl: 1.5rem;
  --cirrus-focus-size: 0 0 .1rem .15rem;
  --cirrus-focus-color: #f03d4d21;
  --bg-opacity: 1;
  --color-opacity: 1;
  --border-opacity: 1;
}

*, :before, :after {
  box-sizing: border-box;
  text-rendering: optimizelegibility;
  -webkit-tap-highlight-color: transparent;
  border: 0 solid;
}

html, body, p, ol, ul, li, dl, dt, dd, blockquote, figure, fieldset, legend, textarea, pre, iframe, hr, h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
}

html, body {
  height: 100%;
  border: none;
}

body {
  letter-spacing: .01rem;
  color: var(--cirrus-fg);
  font-family: Nunito Sans, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.8;
}

ul {
  list-style: none;
}

audio, canvas, iframe, img, embed, object, svg, video {
  max-width: 100%;
  height: auto;
  display: block;
}

iframe {
  border: 1px solid #0000001a;
  border-radius: 3px;
  outline: 0;
  box-shadow: 0 1px 2px #0000000d;
}

article, aside, figure, footer, header, hgroup, section {
  display: block;
}

button, input, optgroup, select, textarea {
  font-family: inherit;
  font-size: inherit;
  margin: 0;
}

label {
  margin: .25rem 0;
  display: inline-block;
}

fieldset {
  padding: 1rem;
}

fieldset legend {
  font-weight: bold;
}

[hidden] {
  display: none !important;
}

::selection {
  background-color: var(--cirrus-select-bg);
}

:focus {
  box-shadow: var(--cirrus-focus-size) var(--cirrus-focus-color);
  outline: none;
}

@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto;
  }

  *, :before, :after {
    scroll-behavior: auto !important;
    transition-duration: .01ms !important;
    animation-duration: .01ms !important;
    animation-iteration-count: 1 !important;
  }
}

@keyframes loading {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(359deg);
  }
}

@keyframes pound {
  to {
    transform: scale(1.1);
  }
}

@keyframes bounce {
  from, 20%, 53%, 80%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
    transform: translate3d(0, 0, 0);
  }

  40%, 43% {
    animation-timing-function: cubic-bezier(.755, .05, .855, .06);
    transform: translate3d(0, -30px, 0);
  }

  70% {
    animation-timing-function: cubic-bezier(.755, .05, .855, .06);
    transform: translate3d(0, -15px, 0);
  }

  90% {
    transform: translate3d(0, -4px, 0);
  }
}

@keyframes bounceIn {
  from, 20%, 40%, 60%, 80%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  0% {
    opacity: 0;
    transform: scale3d(.3, .3, .3);
  }

  20% {
    transform: scale3d(1.1, 1.1, 1.1);
  }

  40% {
    transform: scale3d(.9, .9, .9);
  }

  60% {
    opacity: 1;
    transform: scale3d(1.03, 1.03, 1.03);
  }

  80% {
    transform: scale3d(.97, .97, .97);
  }

  to {
    opacity: 1;
    transform: scale3d(1, 1, 1);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes pulse {
  50% {
    opacity: .5;
  }
}

@keyframes ping {
  75%, 100% {
    opacity: 0;
    transform: scale(2);
  }
}

.hover-grow {
  transition-duration: .32s;
}

.hover-grow:hover {
  transition-duration: 80ms;
  transform: scale(1.1);
}

.animated {
  animation-duration: 1s;
  animation-fill-mode: both;
}

.animated.loading {
  display: block;
  position: relative;
}

.animated.loading:after {
  content: "";
  height: 1rem;
  width: 1rem;
  border: 2px solid #0000;
  border-color: #0000 #0000 #ced4da #ced4da;
  border-radius: 50%;
  animation: .5s linear infinite loading;
  display: block;
  position: absolute;
  top: calc(50% - .740741em);
  left: calc(50% - .8em);
}

.animated.loading.loading-white:after {
  border-bottom-color: #fff;
  border-left-color: #fff;
}

.animated.loading.loading-left {
  padding-left: 3rem;
}

.animated.loading.loading-left:after {
  left: 1rem;
  right: auto;
}

.animated.loading.loading-right {
  padding-right: 3rem;
}

.animated.loading.loading-right:after {
  left: auto;
  right: 1rem;
}

.animated.loading.hide-text {
  color: #0000 !important;
}

.animated.pound {
  vertical-align: baseline;
  animation: .35s infinite alternate pound;
}

.animated.bounce {
  transform-origin: bottom;
  animation-name: bounce;
}

.animated.bounceIn {
  animation-name: bounceIn;
}

.animated.fadeIn {
  animation-name: fadeIn;
}

.animated.infinite {
  animation-iteration-count: infinite;
}

.animated.infinite.alternate {
  animation-direction: alternate;
}

.animated.paused {
  animation-play-state: paused !important;
}

.animated.pulse {
  animation: 1.25s cubic-bezier(.4, 0, .6, 1) infinite pulse;
}

.animated.ping {
  animation: 1s cubic-bezier(0, 0, .2, 1) infinite ping;
}

h6 {
  margin-bottom: 1rem;
  font-family: Montserrat, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.2;
}

h5 {
  margin-bottom: 1rem;
  font-family: Montserrat, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1.2;
}

h4 {
  margin-bottom: 1rem;
  font-family: Montserrat, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  font-size: 1.75rem;
  font-weight: 700;
  line-height: 1.2;
}

h3 {
  margin-bottom: 1rem;
  font-family: Montserrat, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  font-size: 2rem;
  font-weight: 700;
  line-height: 1.2;
}

h2 {
  margin-bottom: 1rem;
  font-family: Montserrat, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  font-size: 2.5rem;
  font-weight: 700;
  line-height: 1.2;
}

h1 {
  letter-spacing: .025rem;
  margin-bottom: 1rem;
  font-family: Montserrat, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  font-size: 3rem;
  font-weight: 700;
  line-height: 1.2;
}

.headline-4 {
  letter-spacing: .05rem;
  font-size: 3.5rem;
}

.headline-3 {
  letter-spacing: .05rem;
  font-size: 4.5rem;
}

.headline-2 {
  letter-spacing: .05rem;
  font-size: 5.5rem;
}

.headline-1 {
  letter-spacing: .05rem;
  font-size: 6.5rem;
}

.text-xs {
  font-size: .75rem !important;
  line-height: 1.5rem !important;
}

.text-sm {
  font-size: .875rem !important;
  line-height: 1.75rem !important;
}

.text-md {
  font-size: 1rem !important;
  line-height: 2rem !important;
}

.text-lg {
  font-size: 1.25rem !important;
  line-height: 2.25rem !important;
}

.text-xl {
  font-size: 1.5rem !important;
  line-height: 2.25rem !important;
}

p, article, blockquote {
  margin-bottom: 1rem;
  font-size: 1rem;
  line-height: 2;
}

p.lead {
  font-size: 1.2rem;
}

blockquote {
  background-color: #f8f9fa;
  border-left: 5px solid #e9ecef;
  border-radius: 3px;
  margin-bottom: 1rem;
  padding: 1rem 2rem;
}

blockquote.text--large {
  font-size: 1.15rem;
}

blockquote p {
  margin: 0;
}

cite {
  opacity: .7;
}

mark, .mark {
  background-color: #f0e8c4;
  padding: .2em;
}

.font-primary {
  font-family: Montserrat, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
}

.font-alt, .font-secondary {
  font-family: Nunito Sans, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
}

.content h1, .content h2, .content h3, .content h4, .content h5, .content h6 {
  margin: 1rem 0;
  line-height: 1.125em;
}

.title {
  font-weight: bold;
}

.title:not(:last-child), .subtitle:not(:last-child) {
  margin-bottom: 1rem;
}

.title + .subtitle {
  margin-top: -.75rem;
}

.card-tile p, .card-tile article, .card-tile blockquote {
  line-height: inherit;
}

.font-extrathin {
  font-weight: 100;
}

.font-thin {
  font-weight: 200;
}

.font-light {
  font-weight: 300;
}

.font-normal {
  font-weight: 400;
}

.font-medium {
  font-weight: 500;
}

.font-semibold {
  font-weight: 600;
}

.font-bold {
  font-weight: 700;
}

.font-extrabold {
  font-weight: 800;
}

.font-black {
  font-weight: 900;
}

.uppercase {
  text-transform: uppercase;
}

.lowercase {
  text-transform: lowercase;
}

.capitalize {
  text-transform: capitalize;
}

.rtl {
  direction: rtl;
}

.icon {
  text-align: center;
  width: 1.5rem;
  vertical-align: baseline;
  display: inline-block;
}

.icon.x-small {
  margin: .55rem 0 0 .1rem;
}

.icon.small {
  margin: .95rem 0 0 .1rem;
}

.icon .fa-wrapper {
  font-size: inherit;
}

.info {
  font-size: var(--font-size-s);
  color: #868e96;
  margin-top: .25rem;
  display: block;
}

.info.inline {
  margin-left: .5rem;
  display: inline-block;
}

.caret {
  width: 0;
  height: 0;
  vertical-align: middle;
  border-top: 4px dashed;
  border-top: 4px solid\9 ;
  border-left: 4px solid #0000;
  border-right: 4px solid #0000;
  margin-left: 2px;
  display: inline-block;
}

abbr[title] {
  cursor: help;
  border-bottom: .1rem dotted;
  text-decoration: none;
}

kbd {
  background-color: var(--cirrus-fg);
  color: #fff;
  vertical-align: baseline;
  border-radius: .2rem;
  padding: .5rem;
  line-height: 1;
  display: inline-block;
}

.tracking-tightest {
  letter-spacing: -.075em !important;
}

.tracking-tighter {
  letter-spacing: -.05em !important;
}

.tracking-tight {
  letter-spacing: -.025em !important;
}

.tracking-normal {
  letter-spacing: 0 !important;
}

.tracking-loose {
  letter-spacing: .025em !important;
}

.tracking-looser {
  letter-spacing: .05em !important;
}

.tracking-loosest {
  letter-spacing: .1em !important;
}

.leading-none {
  line-height: 1 !important;
}

.leading-tighter {
  line-height: 1.25 !important;
}

.leading-tight {
  line-height: 1.375 !important;
}

.leading-normal {
  line-height: 1.5 !important;
}

.leading-loose {
  line-height: 1.625 !important;
}

.leading-looser {
  line-height: 2 !important;
}

@media screen and (max-width: 639px) {
  h6 {
    font-size: 1rem;
  }

  h5 {
    font-size: 1.25rem;
  }

  h4 {
    font-size: 1.5rem;
  }

  h3 {
    font-size: 1.75rem;
  }

  h2 {
    font-size: 2rem;
  }

  h1 {
    font-size: 2.25rem;
  }

  .headline-4 {
    font-size: 3rem;
  }

  .headline-3 {
    font-size: 3.25rem;
  }

  .headline-2 {
    font-size: 3.5rem;
  }

  .headline-1 {
    font-size: 3.75rem;
  }

  p, article, blockquote {
    margin: 1rem 0;
  }
}

:root {
  --grid-template-column: repeat(12, minmax(0, 1fr) );
  --grid-column-start: auto;
  --grid-column-end: auto;
  --grid-row-start: auto;
  --grid-row-end: auto;
}

.grid {
  grid-gap: var(--grid-gap);
  grid-template-columns: var(--grid-template-column);
  display: grid;
}

.grid-cols-1 {
  --grid-template-column: repeat(1, minmax(0, 1fr) );
}

.grid-c-1 {
  grid-column: span 1 / span 1;
}

.grid-r-1 {
  grid-row: span 1 / span 1;
}

.grid-cs-1 {
  grid-column-start: 1;
}

.grid-ce-1 {
  grid-column-end: 2;
}

.grid-rs-1 {
  grid-row-start: 1;
}

.grid-re-1 {
  grid-row-end: 2;
}

.grid-cols-2 {
  --grid-template-column: repeat(2, minmax(0, 1fr) );
}

.grid-c-2 {
  grid-column: span 2 / span 2;
}

.grid-r-2 {
  grid-row: span 2 / span 2;
}

.grid-cs-2 {
  grid-column-start: 2;
}

.grid-ce-2 {
  grid-column-end: 3;
}

.grid-rs-2 {
  grid-row-start: 2;
}

.grid-re-2 {
  grid-row-end: 3;
}

.grid-cols-3 {
  --grid-template-column: repeat(3, minmax(0, 1fr) );
}

.grid-c-3 {
  grid-column: span 3 / span 3;
}

.grid-r-3 {
  grid-row: span 3 / span 3;
}

.grid-cs-3 {
  grid-column-start: 3;
}

.grid-ce-3 {
  grid-column-end: 4;
}

.grid-rs-3 {
  grid-row-start: 3;
}

.grid-re-3 {
  grid-row-end: 4;
}

.grid-cols-4 {
  --grid-template-column: repeat(4, minmax(0, 1fr) );
}

.grid-c-4 {
  grid-column: span 4 / span 4;
}

.grid-r-4 {
  grid-row: span 4 / span 4;
}

.grid-cs-4 {
  grid-column-start: 4;
}

.grid-ce-4 {
  grid-column-end: 5;
}

.grid-rs-4 {
  grid-row-start: 4;
}

.grid-re-4 {
  grid-row-end: 5;
}

.grid-cols-5 {
  --grid-template-column: repeat(5, minmax(0, 1fr) );
}

.grid-c-5 {
  grid-column: span 5 / span 5;
}

.grid-r-5 {
  grid-row: span 5 / span 5;
}

.grid-cs-5 {
  grid-column-start: 5;
}

.grid-ce-5 {
  grid-column-end: 6;
}

.grid-rs-5 {
  grid-row-start: 5;
}

.grid-re-5 {
  grid-row-end: 6;
}

.grid-cols-6 {
  --grid-template-column: repeat(6, minmax(0, 1fr) );
}

.grid-c-6 {
  grid-column: span 6 / span 6;
}

.grid-r-6 {
  grid-row: span 6 / span 6;
}

.grid-cs-6 {
  grid-column-start: 6;
}

.grid-ce-6 {
  grid-column-end: 7;
}

.grid-rs-6 {
  grid-row-start: 6;
}

.grid-re-6 {
  grid-row-end: 7;
}

.grid-cols-7 {
  --grid-template-column: repeat(7, minmax(0, 1fr) );
}

.grid-c-7 {
  grid-column: span 7 / span 7;
}

.grid-r-7 {
  grid-row: span 7 / span 7;
}

.grid-cs-7 {
  grid-column-start: 7;
}

.grid-ce-7 {
  grid-column-end: 8;
}

.grid-rs-7 {
  grid-row-start: 7;
}

.grid-re-7 {
  grid-row-end: 8;
}

.grid-cols-8 {
  --grid-template-column: repeat(8, minmax(0, 1fr) );
}

.grid-c-8 {
  grid-column: span 8 / span 8;
}

.grid-r-8 {
  grid-row: span 8 / span 8;
}

.grid-cs-8 {
  grid-column-start: 8;
}

.grid-ce-8 {
  grid-column-end: 9;
}

.grid-rs-8 {
  grid-row-start: 8;
}

.grid-re-8 {
  grid-row-end: 9;
}

.grid-cols-9 {
  --grid-template-column: repeat(9, minmax(0, 1fr) );
}

.grid-c-9 {
  grid-column: span 9 / span 9;
}

.grid-r-9 {
  grid-row: span 9 / span 9;
}

.grid-cs-9 {
  grid-column-start: 9;
}

.grid-ce-9 {
  grid-column-end: 10;
}

.grid-rs-9 {
  grid-row-start: 9;
}

.grid-re-9 {
  grid-row-end: 10;
}

.grid-cols-10 {
  --grid-template-column: repeat(10, minmax(0, 1fr) );
}

.grid-c-10 {
  grid-column: span 10 / span 10;
}

.grid-r-10 {
  grid-row: span 10 / span 10;
}

.grid-cs-10 {
  grid-column-start: 10;
}

.grid-ce-10 {
  grid-column-end: 11;
}

.grid-rs-10 {
  grid-row-start: 10;
}

.grid-re-10 {
  grid-row-end: 11;
}

.grid-cols-11 {
  --grid-template-column: repeat(11, minmax(0, 1fr) );
}

.grid-c-11 {
  grid-column: span 11 / span 11;
}

.grid-r-11 {
  grid-row: span 11 / span 11;
}

.grid-cs-11 {
  grid-column-start: 11;
}

.grid-ce-11 {
  grid-column-end: 12;
}

.grid-rs-11 {
  grid-row-start: 11;
}

.grid-re-11 {
  grid-row-end: 12;
}

.grid-cols-12 {
  --grid-template-column: repeat(12, minmax(0, 1fr) );
}

.grid-c-12 {
  grid-column: span 12 / span 12;
}

.grid-r-12 {
  grid-row: span 12 / span 12;
}

.grid-cs-12 {
  grid-column-start: 12;
}

.grid-ce-12 {
  grid-column-end: 13;
}

.grid-rs-12 {
  grid-row-start: 12;
}

.grid-re-12 {
  grid-row-end: 13;
}

.grid-ce-end {
  grid-column-end: -1;
}

.grid-re-end {
  grid-row-end: -1;
}

.grid-ce-auto {
  grid-column-end: auto;
}

.grid-re-auto {
  grid-row-end: auto;
}

@media screen and (min-width: 640px) {
  .grid-sm {
    grid-gap: var(--grid-gap);
    grid-template-columns: var(--grid-template-column);
    display: grid;
  }

  .grid-cols-1-sm {
    --grid-template-column: repeat(1, minmax(0, 1fr) );
  }

  .grid-c-1-sm {
    grid-column: span 1 / span 1;
  }

  .grid-r-1-sm {
    grid-row: span 1 / span 1;
  }

  .grid-cs-1-sm {
    grid-column-start: 1;
  }

  .grid-ce-1-sm {
    grid-column-end: 2;
  }

  .grid-rs-1-sm {
    grid-row-start: 1;
  }

  .grid-re-1-sm {
    grid-row-end: 2;
  }

  .grid-cols-2-sm {
    --grid-template-column: repeat(2, minmax(0, 1fr) );
  }

  .grid-c-2-sm {
    grid-column: span 2 / span 2;
  }

  .grid-r-2-sm {
    grid-row: span 2 / span 2;
  }

  .grid-cs-2-sm {
    grid-column-start: 2;
  }

  .grid-ce-2-sm {
    grid-column-end: 3;
  }

  .grid-rs-2-sm {
    grid-row-start: 2;
  }

  .grid-re-2-sm {
    grid-row-end: 3;
  }

  .grid-cols-3-sm {
    --grid-template-column: repeat(3, minmax(0, 1fr) );
  }

  .grid-c-3-sm {
    grid-column: span 3 / span 3;
  }

  .grid-r-3-sm {
    grid-row: span 3 / span 3;
  }

  .grid-cs-3-sm {
    grid-column-start: 3;
  }

  .grid-ce-3-sm {
    grid-column-end: 4;
  }

  .grid-rs-3-sm {
    grid-row-start: 3;
  }

  .grid-re-3-sm {
    grid-row-end: 4;
  }

  .grid-cols-4-sm {
    --grid-template-column: repeat(4, minmax(0, 1fr) );
  }

  .grid-c-4-sm {
    grid-column: span 4 / span 4;
  }

  .grid-r-4-sm {
    grid-row: span 4 / span 4;
  }

  .grid-cs-4-sm {
    grid-column-start: 4;
  }

  .grid-ce-4-sm {
    grid-column-end: 5;
  }

  .grid-rs-4-sm {
    grid-row-start: 4;
  }

  .grid-re-4-sm {
    grid-row-end: 5;
  }

  .grid-cols-5-sm {
    --grid-template-column: repeat(5, minmax(0, 1fr) );
  }

  .grid-c-5-sm {
    grid-column: span 5 / span 5;
  }

  .grid-r-5-sm {
    grid-row: span 5 / span 5;
  }

  .grid-cs-5-sm {
    grid-column-start: 5;
  }

  .grid-ce-5-sm {
    grid-column-end: 6;
  }

  .grid-rs-5-sm {
    grid-row-start: 5;
  }

  .grid-re-5-sm {
    grid-row-end: 6;
  }

  .grid-cols-6-sm {
    --grid-template-column: repeat(6, minmax(0, 1fr) );
  }

  .grid-c-6-sm {
    grid-column: span 6 / span 6;
  }

  .grid-r-6-sm {
    grid-row: span 6 / span 6;
  }

  .grid-cs-6-sm {
    grid-column-start: 6;
  }

  .grid-ce-6-sm {
    grid-column-end: 7;
  }

  .grid-rs-6-sm {
    grid-row-start: 6;
  }

  .grid-re-6-sm {
    grid-row-end: 7;
  }

  .grid-cols-7-sm {
    --grid-template-column: repeat(7, minmax(0, 1fr) );
  }

  .grid-c-7-sm {
    grid-column: span 7 / span 7;
  }

  .grid-r-7-sm {
    grid-row: span 7 / span 7;
  }

  .grid-cs-7-sm {
    grid-column-start: 7;
  }

  .grid-ce-7-sm {
    grid-column-end: 8;
  }

  .grid-rs-7-sm {
    grid-row-start: 7;
  }

  .grid-re-7-sm {
    grid-row-end: 8;
  }

  .grid-cols-8-sm {
    --grid-template-column: repeat(8, minmax(0, 1fr) );
  }

  .grid-c-8-sm {
    grid-column: span 8 / span 8;
  }

  .grid-r-8-sm {
    grid-row: span 8 / span 8;
  }

  .grid-cs-8-sm {
    grid-column-start: 8;
  }

  .grid-ce-8-sm {
    grid-column-end: 9;
  }

  .grid-rs-8-sm {
    grid-row-start: 8;
  }

  .grid-re-8-sm {
    grid-row-end: 9;
  }

  .grid-cols-9-sm {
    --grid-template-column: repeat(9, minmax(0, 1fr) );
  }

  .grid-c-9-sm {
    grid-column: span 9 / span 9;
  }

  .grid-r-9-sm {
    grid-row: span 9 / span 9;
  }

  .grid-cs-9-sm {
    grid-column-start: 9;
  }

  .grid-ce-9-sm {
    grid-column-end: 10;
  }

  .grid-rs-9-sm {
    grid-row-start: 9;
  }

  .grid-re-9-sm {
    grid-row-end: 10;
  }

  .grid-cols-10-sm {
    --grid-template-column: repeat(10, minmax(0, 1fr) );
  }

  .grid-c-10-sm {
    grid-column: span 10 / span 10;
  }

  .grid-r-10-sm {
    grid-row: span 10 / span 10;
  }

  .grid-cs-10-sm {
    grid-column-start: 10;
  }

  .grid-ce-10-sm {
    grid-column-end: 11;
  }

  .grid-rs-10-sm {
    grid-row-start: 10;
  }

  .grid-re-10-sm {
    grid-row-end: 11;
  }

  .grid-cols-11-sm {
    --grid-template-column: repeat(11, minmax(0, 1fr) );
  }

  .grid-c-11-sm {
    grid-column: span 11 / span 11;
  }

  .grid-r-11-sm {
    grid-row: span 11 / span 11;
  }

  .grid-cs-11-sm {
    grid-column-start: 11;
  }

  .grid-ce-11-sm {
    grid-column-end: 12;
  }

  .grid-rs-11-sm {
    grid-row-start: 11;
  }

  .grid-re-11-sm {
    grid-row-end: 12;
  }

  .grid-cols-12-sm {
    --grid-template-column: repeat(12, minmax(0, 1fr) );
  }

  .grid-c-12-sm {
    grid-column: span 12 / span 12;
  }

  .grid-r-12-sm {
    grid-row: span 12 / span 12;
  }

  .grid-cs-12-sm {
    grid-column-start: 12;
  }

  .grid-ce-12-sm {
    grid-column-end: 13;
  }

  .grid-rs-12-sm {
    grid-row-start: 12;
  }

  .grid-re-12-sm {
    grid-row-end: 13;
  }

  .grid-ce-end-sm {
    grid-column-end: -1;
  }

  .grid-re-end-sm {
    grid-row-end: -1;
  }

  .grid-ce-auto-sm {
    grid-column-end: auto;
  }

  .grid-re-auto-sm {
    grid-row-end: auto;
  }
}

@media screen and (min-width: 768px) {
  .grid-md {
    grid-gap: var(--grid-gap);
    grid-template-columns: var(--grid-template-column);
    display: grid;
  }

  .grid-cols-1-md {
    --grid-template-column: repeat(1, minmax(0, 1fr) );
  }

  .grid-c-1-md {
    grid-column: span 1 / span 1;
  }

  .grid-r-1-md {
    grid-row: span 1 / span 1;
  }

  .grid-cs-1-md {
    grid-column-start: 1;
  }

  .grid-ce-1-md {
    grid-column-end: 2;
  }

  .grid-rs-1-md {
    grid-row-start: 1;
  }

  .grid-re-1-md {
    grid-row-end: 2;
  }

  .grid-cols-2-md {
    --grid-template-column: repeat(2, minmax(0, 1fr) );
  }

  .grid-c-2-md {
    grid-column: span 2 / span 2;
  }

  .grid-r-2-md {
    grid-row: span 2 / span 2;
  }

  .grid-cs-2-md {
    grid-column-start: 2;
  }

  .grid-ce-2-md {
    grid-column-end: 3;
  }

  .grid-rs-2-md {
    grid-row-start: 2;
  }

  .grid-re-2-md {
    grid-row-end: 3;
  }

  .grid-cols-3-md {
    --grid-template-column: repeat(3, minmax(0, 1fr) );
  }

  .grid-c-3-md {
    grid-column: span 3 / span 3;
  }

  .grid-r-3-md {
    grid-row: span 3 / span 3;
  }

  .grid-cs-3-md {
    grid-column-start: 3;
  }

  .grid-ce-3-md {
    grid-column-end: 4;
  }

  .grid-rs-3-md {
    grid-row-start: 3;
  }

  .grid-re-3-md {
    grid-row-end: 4;
  }

  .grid-cols-4-md {
    --grid-template-column: repeat(4, minmax(0, 1fr) );
  }

  .grid-c-4-md {
    grid-column: span 4 / span 4;
  }

  .grid-r-4-md {
    grid-row: span 4 / span 4;
  }

  .grid-cs-4-md {
    grid-column-start: 4;
  }

  .grid-ce-4-md {
    grid-column-end: 5;
  }

  .grid-rs-4-md {
    grid-row-start: 4;
  }

  .grid-re-4-md {
    grid-row-end: 5;
  }

  .grid-cols-5-md {
    --grid-template-column: repeat(5, minmax(0, 1fr) );
  }

  .grid-c-5-md {
    grid-column: span 5 / span 5;
  }

  .grid-r-5-md {
    grid-row: span 5 / span 5;
  }

  .grid-cs-5-md {
    grid-column-start: 5;
  }

  .grid-ce-5-md {
    grid-column-end: 6;
  }

  .grid-rs-5-md {
    grid-row-start: 5;
  }

  .grid-re-5-md {
    grid-row-end: 6;
  }

  .grid-cols-6-md {
    --grid-template-column: repeat(6, minmax(0, 1fr) );
  }

  .grid-c-6-md {
    grid-column: span 6 / span 6;
  }

  .grid-r-6-md {
    grid-row: span 6 / span 6;
  }

  .grid-cs-6-md {
    grid-column-start: 6;
  }

  .grid-ce-6-md {
    grid-column-end: 7;
  }

  .grid-rs-6-md {
    grid-row-start: 6;
  }

  .grid-re-6-md {
    grid-row-end: 7;
  }

  .grid-cols-7-md {
    --grid-template-column: repeat(7, minmax(0, 1fr) );
  }

  .grid-c-7-md {
    grid-column: span 7 / span 7;
  }

  .grid-r-7-md {
    grid-row: span 7 / span 7;
  }

  .grid-cs-7-md {
    grid-column-start: 7;
  }

  .grid-ce-7-md {
    grid-column-end: 8;
  }

  .grid-rs-7-md {
    grid-row-start: 7;
  }

  .grid-re-7-md {
    grid-row-end: 8;
  }

  .grid-cols-8-md {
    --grid-template-column: repeat(8, minmax(0, 1fr) );
  }

  .grid-c-8-md {
    grid-column: span 8 / span 8;
  }

  .grid-r-8-md {
    grid-row: span 8 / span 8;
  }

  .grid-cs-8-md {
    grid-column-start: 8;
  }

  .grid-ce-8-md {
    grid-column-end: 9;
  }

  .grid-rs-8-md {
    grid-row-start: 8;
  }

  .grid-re-8-md {
    grid-row-end: 9;
  }

  .grid-cols-9-md {
    --grid-template-column: repeat(9, minmax(0, 1fr) );
  }

  .grid-c-9-md {
    grid-column: span 9 / span 9;
  }

  .grid-r-9-md {
    grid-row: span 9 / span 9;
  }

  .grid-cs-9-md {
    grid-column-start: 9;
  }

  .grid-ce-9-md {
    grid-column-end: 10;
  }

  .grid-rs-9-md {
    grid-row-start: 9;
  }

  .grid-re-9-md {
    grid-row-end: 10;
  }

  .grid-cols-10-md {
    --grid-template-column: repeat(10, minmax(0, 1fr) );
  }

  .grid-c-10-md {
    grid-column: span 10 / span 10;
  }

  .grid-r-10-md {
    grid-row: span 10 / span 10;
  }

  .grid-cs-10-md {
    grid-column-start: 10;
  }

  .grid-ce-10-md {
    grid-column-end: 11;
  }

  .grid-rs-10-md {
    grid-row-start: 10;
  }

  .grid-re-10-md {
    grid-row-end: 11;
  }

  .grid-cols-11-md {
    --grid-template-column: repeat(11, minmax(0, 1fr) );
  }

  .grid-c-11-md {
    grid-column: span 11 / span 11;
  }

  .grid-r-11-md {
    grid-row: span 11 / span 11;
  }

  .grid-cs-11-md {
    grid-column-start: 11;
  }

  .grid-ce-11-md {
    grid-column-end: 12;
  }

  .grid-rs-11-md {
    grid-row-start: 11;
  }

  .grid-re-11-md {
    grid-row-end: 12;
  }

  .grid-cols-12-md {
    --grid-template-column: repeat(12, minmax(0, 1fr) );
  }

  .grid-c-12-md {
    grid-column: span 12 / span 12;
  }

  .grid-r-12-md {
    grid-row: span 12 / span 12;
  }

  .grid-cs-12-md {
    grid-column-start: 12;
  }

  .grid-ce-12-md {
    grid-column-end: 13;
  }

  .grid-rs-12-md {
    grid-row-start: 12;
  }

  .grid-re-12-md {
    grid-row-end: 13;
  }

  .grid-ce-end-md {
    grid-column-end: -1;
  }

  .grid-re-end-md {
    grid-row-end: -1;
  }

  .grid-ce-auto-md {
    grid-column-end: auto;
  }

  .grid-re-auto-md {
    grid-row-end: auto;
  }
}

@media screen and (min-width: 1024px) {
  .grid-lg {
    grid-gap: var(--grid-gap);
    grid-template-columns: var(--grid-template-column);
    display: grid;
  }

  .grid-cols-1-lg {
    --grid-template-column: repeat(1, minmax(0, 1fr) );
  }

  .grid-c-1-lg {
    grid-column: span 1 / span 1;
  }

  .grid-r-1-lg {
    grid-row: span 1 / span 1;
  }

  .grid-cs-1-lg {
    grid-column-start: 1;
  }

  .grid-ce-1-lg {
    grid-column-end: 2;
  }

  .grid-rs-1-lg {
    grid-row-start: 1;
  }

  .grid-re-1-lg {
    grid-row-end: 2;
  }

  .grid-cols-2-lg {
    --grid-template-column: repeat(2, minmax(0, 1fr) );
  }

  .grid-c-2-lg {
    grid-column: span 2 / span 2;
  }

  .grid-r-2-lg {
    grid-row: span 2 / span 2;
  }

  .grid-cs-2-lg {
    grid-column-start: 2;
  }

  .grid-ce-2-lg {
    grid-column-end: 3;
  }

  .grid-rs-2-lg {
    grid-row-start: 2;
  }

  .grid-re-2-lg {
    grid-row-end: 3;
  }

  .grid-cols-3-lg {
    --grid-template-column: repeat(3, minmax(0, 1fr) );
  }

  .grid-c-3-lg {
    grid-column: span 3 / span 3;
  }

  .grid-r-3-lg {
    grid-row: span 3 / span 3;
  }

  .grid-cs-3-lg {
    grid-column-start: 3;
  }

  .grid-ce-3-lg {
    grid-column-end: 4;
  }

  .grid-rs-3-lg {
    grid-row-start: 3;
  }

  .grid-re-3-lg {
    grid-row-end: 4;
  }

  .grid-cols-4-lg {
    --grid-template-column: repeat(4, minmax(0, 1fr) );
  }

  .grid-c-4-lg {
    grid-column: span 4 / span 4;
  }

  .grid-r-4-lg {
    grid-row: span 4 / span 4;
  }

  .grid-cs-4-lg {
    grid-column-start: 4;
  }

  .grid-ce-4-lg {
    grid-column-end: 5;
  }

  .grid-rs-4-lg {
    grid-row-start: 4;
  }

  .grid-re-4-lg {
    grid-row-end: 5;
  }

  .grid-cols-5-lg {
    --grid-template-column: repeat(5, minmax(0, 1fr) );
  }

  .grid-c-5-lg {
    grid-column: span 5 / span 5;
  }

  .grid-r-5-lg {
    grid-row: span 5 / span 5;
  }

  .grid-cs-5-lg {
    grid-column-start: 5;
  }

  .grid-ce-5-lg {
    grid-column-end: 6;
  }

  .grid-rs-5-lg {
    grid-row-start: 5;
  }

  .grid-re-5-lg {
    grid-row-end: 6;
  }

  .grid-cols-6-lg {
    --grid-template-column: repeat(6, minmax(0, 1fr) );
  }

  .grid-c-6-lg {
    grid-column: span 6 / span 6;
  }

  .grid-r-6-lg {
    grid-row: span 6 / span 6;
  }

  .grid-cs-6-lg {
    grid-column-start: 6;
  }

  .grid-ce-6-lg {
    grid-column-end: 7;
  }

  .grid-rs-6-lg {
    grid-row-start: 6;
  }

  .grid-re-6-lg {
    grid-row-end: 7;
  }

  .grid-cols-7-lg {
    --grid-template-column: repeat(7, minmax(0, 1fr) );
  }

  .grid-c-7-lg {
    grid-column: span 7 / span 7;
  }

  .grid-r-7-lg {
    grid-row: span 7 / span 7;
  }

  .grid-cs-7-lg {
    grid-column-start: 7;
  }

  .grid-ce-7-lg {
    grid-column-end: 8;
  }

  .grid-rs-7-lg {
    grid-row-start: 7;
  }

  .grid-re-7-lg {
    grid-row-end: 8;
  }

  .grid-cols-8-lg {
    --grid-template-column: repeat(8, minmax(0, 1fr) );
  }

  .grid-c-8-lg {
    grid-column: span 8 / span 8;
  }

  .grid-r-8-lg {
    grid-row: span 8 / span 8;
  }

  .grid-cs-8-lg {
    grid-column-start: 8;
  }

  .grid-ce-8-lg {
    grid-column-end: 9;
  }

  .grid-rs-8-lg {
    grid-row-start: 8;
  }

  .grid-re-8-lg {
    grid-row-end: 9;
  }

  .grid-cols-9-lg {
    --grid-template-column: repeat(9, minmax(0, 1fr) );
  }

  .grid-c-9-lg {
    grid-column: span 9 / span 9;
  }

  .grid-r-9-lg {
    grid-row: span 9 / span 9;
  }

  .grid-cs-9-lg {
    grid-column-start: 9;
  }

  .grid-ce-9-lg {
    grid-column-end: 10;
  }

  .grid-rs-9-lg {
    grid-row-start: 9;
  }

  .grid-re-9-lg {
    grid-row-end: 10;
  }

  .grid-cols-10-lg {
    --grid-template-column: repeat(10, minmax(0, 1fr) );
  }

  .grid-c-10-lg {
    grid-column: span 10 / span 10;
  }

  .grid-r-10-lg {
    grid-row: span 10 / span 10;
  }

  .grid-cs-10-lg {
    grid-column-start: 10;
  }

  .grid-ce-10-lg {
    grid-column-end: 11;
  }

  .grid-rs-10-lg {
    grid-row-start: 10;
  }

  .grid-re-10-lg {
    grid-row-end: 11;
  }

  .grid-cols-11-lg {
    --grid-template-column: repeat(11, minmax(0, 1fr) );
  }

  .grid-c-11-lg {
    grid-column: span 11 / span 11;
  }

  .grid-r-11-lg {
    grid-row: span 11 / span 11;
  }

  .grid-cs-11-lg {
    grid-column-start: 11;
  }

  .grid-ce-11-lg {
    grid-column-end: 12;
  }

  .grid-rs-11-lg {
    grid-row-start: 11;
  }

  .grid-re-11-lg {
    grid-row-end: 12;
  }

  .grid-cols-12-lg {
    --grid-template-column: repeat(12, minmax(0, 1fr) );
  }

  .grid-c-12-lg {
    grid-column: span 12 / span 12;
  }

  .grid-r-12-lg {
    grid-row: span 12 / span 12;
  }

  .grid-cs-12-lg {
    grid-column-start: 12;
  }

  .grid-ce-12-lg {
    grid-column-end: 13;
  }

  .grid-rs-12-lg {
    grid-row-start: 12;
  }

  .grid-re-12-lg {
    grid-row-end: 13;
  }

  .grid-ce-end-lg {
    grid-column-end: -1;
  }

  .grid-re-end-lg {
    grid-row-end: -1;
  }

  .grid-ce-auto-lg {
    grid-column-end: auto;
  }

  .grid-re-auto-lg {
    grid-row-end: auto;
  }
}

@media screen and (min-width: 1280px) {
  .grid-xl {
    grid-gap: var(--grid-gap);
    grid-template-columns: var(--grid-template-column);
    display: grid;
  }

  .grid-cols-1-xl {
    --grid-template-column: repeat(1, minmax(0, 1fr) );
  }

  .grid-c-1-xl {
    grid-column: span 1 / span 1;
  }

  .grid-r-1-xl {
    grid-row: span 1 / span 1;
  }

  .grid-cs-1-xl {
    grid-column-start: 1;
  }

  .grid-ce-1-xl {
    grid-column-end: 2;
  }

  .grid-rs-1-xl {
    grid-row-start: 1;
  }

  .grid-re-1-xl {
    grid-row-end: 2;
  }

  .grid-cols-2-xl {
    --grid-template-column: repeat(2, minmax(0, 1fr) );
  }

  .grid-c-2-xl {
    grid-column: span 2 / span 2;
  }

  .grid-r-2-xl {
    grid-row: span 2 / span 2;
  }

  .grid-cs-2-xl {
    grid-column-start: 2;
  }

  .grid-ce-2-xl {
    grid-column-end: 3;
  }

  .grid-rs-2-xl {
    grid-row-start: 2;
  }

  .grid-re-2-xl {
    grid-row-end: 3;
  }

  .grid-cols-3-xl {
    --grid-template-column: repeat(3, minmax(0, 1fr) );
  }

  .grid-c-3-xl {
    grid-column: span 3 / span 3;
  }

  .grid-r-3-xl {
    grid-row: span 3 / span 3;
  }

  .grid-cs-3-xl {
    grid-column-start: 3;
  }

  .grid-ce-3-xl {
    grid-column-end: 4;
  }

  .grid-rs-3-xl {
    grid-row-start: 3;
  }

  .grid-re-3-xl {
    grid-row-end: 4;
  }

  .grid-cols-4-xl {
    --grid-template-column: repeat(4, minmax(0, 1fr) );
  }

  .grid-c-4-xl {
    grid-column: span 4 / span 4;
  }

  .grid-r-4-xl {
    grid-row: span 4 / span 4;
  }

  .grid-cs-4-xl {
    grid-column-start: 4;
  }

  .grid-ce-4-xl {
    grid-column-end: 5;
  }

  .grid-rs-4-xl {
    grid-row-start: 4;
  }

  .grid-re-4-xl {
    grid-row-end: 5;
  }

  .grid-cols-5-xl {
    --grid-template-column: repeat(5, minmax(0, 1fr) );
  }

  .grid-c-5-xl {
    grid-column: span 5 / span 5;
  }

  .grid-r-5-xl {
    grid-row: span 5 / span 5;
  }

  .grid-cs-5-xl {
    grid-column-start: 5;
  }

  .grid-ce-5-xl {
    grid-column-end: 6;
  }

  .grid-rs-5-xl {
    grid-row-start: 5;
  }

  .grid-re-5-xl {
    grid-row-end: 6;
  }

  .grid-cols-6-xl {
    --grid-template-column: repeat(6, minmax(0, 1fr) );
  }

  .grid-c-6-xl {
    grid-column: span 6 / span 6;
  }

  .grid-r-6-xl {
    grid-row: span 6 / span 6;
  }

  .grid-cs-6-xl {
    grid-column-start: 6;
  }

  .grid-ce-6-xl {
    grid-column-end: 7;
  }

  .grid-rs-6-xl {
    grid-row-start: 6;
  }

  .grid-re-6-xl {
    grid-row-end: 7;
  }

  .grid-cols-7-xl {
    --grid-template-column: repeat(7, minmax(0, 1fr) );
  }

  .grid-c-7-xl {
    grid-column: span 7 / span 7;
  }

  .grid-r-7-xl {
    grid-row: span 7 / span 7;
  }

  .grid-cs-7-xl {
    grid-column-start: 7;
  }

  .grid-ce-7-xl {
    grid-column-end: 8;
  }

  .grid-rs-7-xl {
    grid-row-start: 7;
  }

  .grid-re-7-xl {
    grid-row-end: 8;
  }

  .grid-cols-8-xl {
    --grid-template-column: repeat(8, minmax(0, 1fr) );
  }

  .grid-c-8-xl {
    grid-column: span 8 / span 8;
  }

  .grid-r-8-xl {
    grid-row: span 8 / span 8;
  }

  .grid-cs-8-xl {
    grid-column-start: 8;
  }

  .grid-ce-8-xl {
    grid-column-end: 9;
  }

  .grid-rs-8-xl {
    grid-row-start: 8;
  }

  .grid-re-8-xl {
    grid-row-end: 9;
  }

  .grid-cols-9-xl {
    --grid-template-column: repeat(9, minmax(0, 1fr) );
  }

  .grid-c-9-xl {
    grid-column: span 9 / span 9;
  }

  .grid-r-9-xl {
    grid-row: span 9 / span 9;
  }

  .grid-cs-9-xl {
    grid-column-start: 9;
  }

  .grid-ce-9-xl {
    grid-column-end: 10;
  }

  .grid-rs-9-xl {
    grid-row-start: 9;
  }

  .grid-re-9-xl {
    grid-row-end: 10;
  }

  .grid-cols-10-xl {
    --grid-template-column: repeat(10, minmax(0, 1fr) );
  }

  .grid-c-10-xl {
    grid-column: span 10 / span 10;
  }

  .grid-r-10-xl {
    grid-row: span 10 / span 10;
  }

  .grid-cs-10-xl {
    grid-column-start: 10;
  }

  .grid-ce-10-xl {
    grid-column-end: 11;
  }

  .grid-rs-10-xl {
    grid-row-start: 10;
  }

  .grid-re-10-xl {
    grid-row-end: 11;
  }

  .grid-cols-11-xl {
    --grid-template-column: repeat(11, minmax(0, 1fr) );
  }

  .grid-c-11-xl {
    grid-column: span 11 / span 11;
  }

  .grid-r-11-xl {
    grid-row: span 11 / span 11;
  }

  .grid-cs-11-xl {
    grid-column-start: 11;
  }

  .grid-ce-11-xl {
    grid-column-end: 12;
  }

  .grid-rs-11-xl {
    grid-row-start: 11;
  }

  .grid-re-11-xl {
    grid-row-end: 12;
  }

  .grid-cols-12-xl {
    --grid-template-column: repeat(12, minmax(0, 1fr) );
  }

  .grid-c-12-xl {
    grid-column: span 12 / span 12;
  }

  .grid-r-12-xl {
    grid-row: span 12 / span 12;
  }

  .grid-cs-12-xl {
    grid-column-start: 12;
  }

  .grid-ce-12-xl {
    grid-column-end: 13;
  }

  .grid-rs-12-xl {
    grid-row-start: 12;
  }

  .grid-re-12-xl {
    grid-row-end: 13;
  }

  .grid-ce-end-xl {
    grid-column-end: -1;
  }

  .grid-re-end-xl {
    grid-row-end: -1;
  }

  .grid-ce-auto-xl {
    grid-column-end: auto;
  }

  .grid-re-auto-xl {
    grid-row-end: auto;
  }
}

section {
  display: block;
}

.content {
  max-width: 48em;
  width: 100%;
  margin: 0 auto 1.5em;
}

@media screen and (min-width: 1024px) {
  .content {
    max-width: 64em;
  }
}

@media screen and (min-width: 1280px) {
  .content {
    max-width: 80em;
  }
}

@media screen and (min-width: 1536px) {
  .content {
    max-width: 96em;
  }
}

.fullscreen {
  min-height: 100vh;
  inset: 0;
}

.divider {
  height: .1rem;
  border-top: .05rem solid #adb5bd80;
  margin: 1.8rem 0 1.6rem;
  position: relative;
}

.divider[data-content] {
  margin: .8rem 0;
}

.divider--v[data-content]:after, .divider[data-content]:after {
  color: #adb5bd;
  content: attr(data-content);
  background: #fff;
  padding: 0 .4rem;
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.divider--v, .divider--v[data-content] {
  padding: .8rem;
  display: block;
}

.divider--v:before, .divider--v[data-content]:before {
  content: "";
  border-left: .05rem solid #adb5bd80;
  display: block;
  position: absolute;
  top: 0;
  bottom: .4rem;
  left: 50%;
  transform: translateX(-50%);
}

.divider--v[data-content] {
  padding: .2rem 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.hero-img {
  background-size: cover;
}

.parallax-img {
  background-attachment: fixed !important;
}

.hero {
  justify-content: space-between;
  align-items: stretch;
  display: flex;
}

.hero .hero-body {
  width: 100%;
  flex-grow: 1;
  flex-shrink: 0;
  align-items: center;
  padding: 3rem 1.5rem;
  display: flex;
}

.space {
  width: 100%;
  height: 1rem;
  display: block;
}

.space.space--lg {
  padding: 1rem 0;
}

.space.space--xl {
  padding: 2rem 0;
}

.row {
  flex-wrap: wrap;
  flex: 1;
  padding: .5rem 0;
  display: flex;
}

.row:after {
  content: "";
  clear: both;
  display: table;
}

.row.row--no-wrap {
  flex-wrap: nowrap;
  overflow-x: auto;
}

.row .col {
  flex: 1;
  padding: .15rem .75rem;
  display: block;
}

.row .offset-right {
  margin-left: 0;
  margin-right: auto;
}

.row .offset-center {
  margin-left: auto;
  margin-right: auto;
}

.row .offset-left {
  margin-left: auto;
  margin-right: 0;
}

.row.divided [class^="col"], .row.divided [class*=" col"] {
  box-shadow: 0 -1px #adb5bd80;
}

.row [class^="col-"], .row [class*=" col-"] {
  width: 100%;
  margin-left: 0;
  padding: 0 .5rem;
}

@media screen and (min-width: 768px) {
  .row .col-1 {
    width: 8.33333%;
  }

  .row .col-2 {
    width: 16.6667%;
  }

  .row .col-3 {
    width: 25%;
  }

  .row .col-4 {
    width: 33.3333%;
  }

  .row .col-5 {
    width: 41.6667%;
  }

  .row .col-6 {
    width: 50%;
  }

  .row .col-7 {
    width: 58.3333%;
  }

  .row .col-8 {
    width: 66.6667%;
  }

  .row .col-9 {
    width: 75%;
  }

  .row .col-10 {
    width: 83.3333%;
  }

  .row .col-11 {
    width: 91.6667%;
  }

  .row .col-12 {
    width: 100%;
  }
}

.row .col-xs-1 {
  width: 8.33333%;
}

.row .col-xs-2 {
  width: 16.6667%;
}

.row .col-xs-3 {
  width: 25%;
}

.row .col-xs-4 {
  width: 33.3333%;
}

.row .col-xs-5 {
  width: 41.6667%;
}

.row .col-xs-6 {
  width: 50%;
}

.row .col-xs-7 {
  width: 58.3333%;
}

.row .col-xs-8 {
  width: 66.6667%;
}

.row .col-xs-9 {
  width: 75%;
}

.row .col-xs-10 {
  width: 83.3333%;
}

.row .col-xs-11 {
  width: 91.6667%;
}

.row .col-xs-12 {
  width: 100%;
}

@media screen and (min-width: 640px) {
  .row .col-sm-1 {
    width: 8.33333%;
  }

  .row .col-sm-2 {
    width: 16.6667%;
  }

  .row .col-sm-3 {
    width: 25%;
  }

  .row .col-sm-4 {
    width: 33.3333%;
  }

  .row .col-sm-5 {
    width: 41.6667%;
  }

  .row .col-sm-6 {
    width: 50%;
  }

  .row .col-sm-7 {
    width: 58.3333%;
  }

  .row .col-sm-8 {
    width: 66.6667%;
  }

  .row .col-sm-9 {
    width: 75%;
  }

  .row .col-sm-10 {
    width: 83.3333%;
  }

  .row .col-sm-11 {
    width: 91.6667%;
  }

  .row .col-sm-12 {
    width: 100%;
  }
}

@media screen and (min-width: 768px) {
  .row .col-md-1 {
    width: 8.33333%;
  }

  .row .col-md-2 {
    width: 16.6667%;
  }

  .row .col-md-3 {
    width: 25%;
  }

  .row .col-md-4 {
    width: 33.3333%;
  }

  .row .col-md-5 {
    width: 41.6667%;
  }

  .row .col-md-6 {
    width: 50%;
  }

  .row .col-md-7 {
    width: 58.3333%;
  }

  .row .col-md-8 {
    width: 66.6667%;
  }

  .row .col-md-9 {
    width: 75%;
  }

  .row .col-md-10 {
    width: 83.3333%;
  }

  .row .col-md-11 {
    width: 91.6667%;
  }

  .row .col-md-12 {
    width: 100%;
  }
}

@media screen and (min-width: 1024px) {
  .row .col-lg-1 {
    width: 8.33333%;
  }

  .row .col-lg-2 {
    width: 16.6667%;
  }

  .row .col-lg-3 {
    width: 25%;
  }

  .row .col-lg-4 {
    width: 33.3333%;
  }

  .row .col-lg-5 {
    width: 41.6667%;
  }

  .row .col-lg-6 {
    width: 50%;
  }

  .row .col-lg-7 {
    width: 58.3333%;
  }

  .row .col-lg-8 {
    width: 66.6667%;
  }

  .row .col-lg-9 {
    width: 75%;
  }

  .row .col-lg-10 {
    width: 83.3333%;
  }

  .row .col-lg-11 {
    width: 91.6667%;
  }

  .row .col-lg-12 {
    width: 100%;
  }
}

@media screen and (min-width: 1280px) {
  .row .col-xl-1 {
    width: 8.33333%;
  }

  .row .col-xl-2 {
    width: 16.6667%;
  }

  .row .col-xl-3 {
    width: 25%;
  }

  .row .col-xl-4 {
    width: 33.3333%;
  }

  .row .col-xl-5 {
    width: 41.6667%;
  }

  .row .col-xl-6 {
    width: 50%;
  }

  .row .col-xl-7 {
    width: 58.3333%;
  }

  .row .col-xl-8 {
    width: 66.6667%;
  }

  .row .col-xl-9 {
    width: 75%;
  }

  .row .col-xl-10 {
    width: 83.3333%;
  }

  .row .col-xl-11 {
    width: 91.6667%;
  }

  .row .col-xl-12 {
    width: 100%;
  }
}

@media screen and (min-width: 640px) {
  .row .offset-1 {
    margin-left: 8.33333%;
  }

  .row .offset-2 {
    margin-left: 16.6667%;
  }

  .row .offset-3 {
    margin-left: 25%;
  }

  .row .offset-4 {
    margin-left: 33.3333%;
  }

  .row .offset-5 {
    margin-left: 41.6667%;
  }

  .row .offset-6 {
    margin-left: 50%;
  }

  .row .offset-7 {
    margin-left: 58.3333%;
  }

  .row .offset-8 {
    margin-left: 66.6667%;
  }

  .row .offset-9 {
    margin-left: 75%;
  }

  .row .offset-10 {
    margin-left: 83.3333%;
  }

  .row .offset-11 {
    margin-left: 91.6667%;
  }

  .row .offset-12 {
    margin-left: 100%;
  }
}

.row.no-space [class^="col-"], .row.no-space [class*=" col-"] {
  padding: 0;
}

.r {
  max-width: 100%;
  padding: .5rem;
}

.level {
  justify-content: space-between;
  align-items: center;
}

.level .level-item {
  flex: none;
  justify-content: center;
  align-items: center;
  display: flex;
}

.level .level-content {
  text-align: left;
  width: 100%;
  flex: auto;
}

.h-0 {
  height: 0 !important;
}

.h-1 {
  height: .5rem !important;
}

.h-2 {
  height: 1rem !important;
}

.h-3 {
  height: 1.5rem !important;
}

.h-4 {
  height: 2rem !important;
}

.h-5 {
  height: 2.5rem !important;
}

.h-6 {
  height: 3rem !important;
}

.h-8 {
  height: 4rem !important;
}

.h-10 {
  height: 5rem !important;
}

.h-12 {
  height: 6rem !important;
}

.h-16 {
  height: 8rem !important;
}

.h-20 {
  height: 10rem !important;
}

.h-24 {
  height: 12rem !important;
}

.h-32 {
  height: 16rem !important;
}

.h-10p {
  height: 10% !important;
}

.h-20p {
  height: 20% !important;
}

.h-30p {
  height: 30% !important;
}

.h-40p {
  height: 40% !important;
}

.h-50p {
  height: 50% !important;
}

.h-60p {
  height: 60% !important;
}

.h-70p {
  height: 70% !important;
}

.h-80p {
  height: 80% !important;
}

.h-90p {
  height: 90% !important;
}

.h-100p {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.h-screen {
  height: 100vh !important;
}

@media screen and (min-width: 640px) {
  .h-0-sm {
    height: 0 !important;
  }

  .h-1-sm {
    height: .5rem !important;
  }

  .h-2-sm {
    height: 1rem !important;
  }

  .h-3-sm {
    height: 1.5rem !important;
  }

  .h-4-sm {
    height: 2rem !important;
  }

  .h-5-sm {
    height: 2.5rem !important;
  }

  .h-6-sm {
    height: 3rem !important;
  }

  .h-8-sm {
    height: 4rem !important;
  }

  .h-10-sm {
    height: 5rem !important;
  }

  .h-12-sm {
    height: 6rem !important;
  }

  .h-16-sm {
    height: 8rem !important;
  }

  .h-20-sm {
    height: 10rem !important;
  }

  .h-24-sm {
    height: 12rem !important;
  }

  .h-32-sm {
    height: 16rem !important;
  }

  .h-10p-sm {
    height: 10% !important;
  }

  .h-20p-sm {
    height: 20% !important;
  }

  .h-30p-sm {
    height: 30% !important;
  }

  .h-40p-sm {
    height: 40% !important;
  }

  .h-50p-sm {
    height: 50% !important;
  }

  .h-60p-sm {
    height: 60% !important;
  }

  .h-70p-sm {
    height: 70% !important;
  }

  .h-80p-sm {
    height: 80% !important;
  }

  .h-90p-sm {
    height: 90% !important;
  }

  .h-100p-sm {
    height: 100% !important;
  }

  .h-auto-sm {
    height: auto !important;
  }

  .h-screen-sm {
    height: 100vh !important;
  }
}

@media screen and (min-width: 768px) {
  .h-0-md {
    height: 0 !important;
  }

  .h-1-md {
    height: .5rem !important;
  }

  .h-2-md {
    height: 1rem !important;
  }

  .h-3-md {
    height: 1.5rem !important;
  }

  .h-4-md {
    height: 2rem !important;
  }

  .h-5-md {
    height: 2.5rem !important;
  }

  .h-6-md {
    height: 3rem !important;
  }

  .h-8-md {
    height: 4rem !important;
  }

  .h-10-md {
    height: 5rem !important;
  }

  .h-12-md {
    height: 6rem !important;
  }

  .h-16-md {
    height: 8rem !important;
  }

  .h-20-md {
    height: 10rem !important;
  }

  .h-24-md {
    height: 12rem !important;
  }

  .h-32-md {
    height: 16rem !important;
  }

  .h-10p-md {
    height: 10% !important;
  }

  .h-20p-md {
    height: 20% !important;
  }

  .h-30p-md {
    height: 30% !important;
  }

  .h-40p-md {
    height: 40% !important;
  }

  .h-50p-md {
    height: 50% !important;
  }

  .h-60p-md {
    height: 60% !important;
  }

  .h-70p-md {
    height: 70% !important;
  }

  .h-80p-md {
    height: 80% !important;
  }

  .h-90p-md {
    height: 90% !important;
  }

  .h-100p-md {
    height: 100% !important;
  }

  .h-auto-md {
    height: auto !important;
  }

  .h-screen-md {
    height: 100vh !important;
  }
}

@media screen and (min-width: 1024px) {
  .h-0-lg {
    height: 0 !important;
  }

  .h-1-lg {
    height: .5rem !important;
  }

  .h-2-lg {
    height: 1rem !important;
  }

  .h-3-lg {
    height: 1.5rem !important;
  }

  .h-4-lg {
    height: 2rem !important;
  }

  .h-5-lg {
    height: 2.5rem !important;
  }

  .h-6-lg {
    height: 3rem !important;
  }

  .h-8-lg {
    height: 4rem !important;
  }

  .h-10-lg {
    height: 5rem !important;
  }

  .h-12-lg {
    height: 6rem !important;
  }

  .h-16-lg {
    height: 8rem !important;
  }

  .h-20-lg {
    height: 10rem !important;
  }

  .h-24-lg {
    height: 12rem !important;
  }

  .h-32-lg {
    height: 16rem !important;
  }

  .h-10p-lg {
    height: 10% !important;
  }

  .h-20p-lg {
    height: 20% !important;
  }

  .h-30p-lg {
    height: 30% !important;
  }

  .h-40p-lg {
    height: 40% !important;
  }

  .h-50p-lg {
    height: 50% !important;
  }

  .h-60p-lg {
    height: 60% !important;
  }

  .h-70p-lg {
    height: 70% !important;
  }

  .h-80p-lg {
    height: 80% !important;
  }

  .h-90p-lg {
    height: 90% !important;
  }

  .h-100p-lg {
    height: 100% !important;
  }

  .h-auto-lg {
    height: auto !important;
  }

  .h-screen-lg {
    height: 100vh !important;
  }
}

@media screen and (min-width: 1280px) {
  .h-0-xl {
    height: 0 !important;
  }

  .h-1-xl {
    height: .5rem !important;
  }

  .h-2-xl {
    height: 1rem !important;
  }

  .h-3-xl {
    height: 1.5rem !important;
  }

  .h-4-xl {
    height: 2rem !important;
  }

  .h-5-xl {
    height: 2.5rem !important;
  }

  .h-6-xl {
    height: 3rem !important;
  }

  .h-8-xl {
    height: 4rem !important;
  }

  .h-10-xl {
    height: 5rem !important;
  }

  .h-12-xl {
    height: 6rem !important;
  }

  .h-16-xl {
    height: 8rem !important;
  }

  .h-20-xl {
    height: 10rem !important;
  }

  .h-24-xl {
    height: 12rem !important;
  }

  .h-32-xl {
    height: 16rem !important;
  }

  .h-10p-xl {
    height: 10% !important;
  }

  .h-20p-xl {
    height: 20% !important;
  }

  .h-30p-xl {
    height: 30% !important;
  }

  .h-40p-xl {
    height: 40% !important;
  }

  .h-50p-xl {
    height: 50% !important;
  }

  .h-60p-xl {
    height: 60% !important;
  }

  .h-70p-xl {
    height: 70% !important;
  }

  .h-80p-xl {
    height: 80% !important;
  }

  .h-90p-xl {
    height: 90% !important;
  }

  .h-100p-xl {
    height: 100% !important;
  }

  .h-auto-xl {
    height: auto !important;
  }

  .h-screen-xl {
    height: 100vh !important;
  }
}

.w-0 {
  width: 0 !important;
}

.w-auto {
  width: auto !important;
}

.w-screen {
  width: 100vw !important;
}

.w-10p {
  width: 10% !important;
}

.w-20p {
  width: 20% !important;
}

.w-30p {
  width: 30% !important;
}

.w-40p {
  width: 40% !important;
}

.w-50p {
  width: 50% !important;
}

.w-60p {
  width: 60% !important;
}

.w-70p {
  width: 70% !important;
}

.w-80p {
  width: 80% !important;
}

.w-90p {
  width: 90% !important;
}

.w-100p {
  width: 100% !important;
}

.w-1 {
  width: .5rem !important;
}

.w-2 {
  width: 1rem !important;
}

.w-3 {
  width: 1.5rem !important;
}

.w-4 {
  width: 2rem !important;
}

.w-5 {
  width: 2.5rem !important;
}

.w-6 {
  width: 3rem !important;
}

.w-8 {
  width: 4rem !important;
}

.w-10 {
  width: 5rem !important;
}

.w-12 {
  width: 6rem !important;
}

.w-16 {
  width: 8rem !important;
}

.w-20 {
  width: 10rem !important;
}

.w-24 {
  width: 12rem !important;
}

.w-32 {
  width: 16rem !important;
}

@media screen and (min-width: 640px) {
  .w-0-sm {
    width: 0 !important;
  }

  .w-auto-sm {
    width: auto !important;
  }

  .w-screen-sm {
    width: 100vw !important;
  }

  .w-10p-sm {
    width: 10% !important;
  }

  .w-20p-sm {
    width: 20% !important;
  }

  .w-30p-sm {
    width: 30% !important;
  }

  .w-40p-sm {
    width: 40% !important;
  }

  .w-50p-sm {
    width: 50% !important;
  }

  .w-60p-sm {
    width: 60% !important;
  }

  .w-70p-sm {
    width: 70% !important;
  }

  .w-80p-sm {
    width: 80% !important;
  }

  .w-90p-sm {
    width: 90% !important;
  }

  .w-100p-sm {
    width: 100% !important;
  }

  .w-1-sm {
    width: .5rem !important;
  }

  .w-2-sm {
    width: 1rem !important;
  }

  .w-3-sm {
    width: 1.5rem !important;
  }

  .w-4-sm {
    width: 2rem !important;
  }

  .w-5-sm {
    width: 2.5rem !important;
  }

  .w-6-sm {
    width: 3rem !important;
  }

  .w-8-sm {
    width: 4rem !important;
  }

  .w-10-sm {
    width: 5rem !important;
  }

  .w-12-sm {
    width: 6rem !important;
  }

  .w-16-sm {
    width: 8rem !important;
  }

  .w-20-sm {
    width: 10rem !important;
  }

  .w-24-sm {
    width: 12rem !important;
  }

  .w-32-sm {
    width: 16rem !important;
  }
}

@media screen and (min-width: 768px) {
  .w-0-md {
    width: 0 !important;
  }

  .w-auto-md {
    width: auto !important;
  }

  .w-screen-md {
    width: 100vw !important;
  }

  .w-10p-md {
    width: 10% !important;
  }

  .w-20p-md {
    width: 20% !important;
  }

  .w-30p-md {
    width: 30% !important;
  }

  .w-40p-md {
    width: 40% !important;
  }

  .w-50p-md {
    width: 50% !important;
  }

  .w-60p-md {
    width: 60% !important;
  }

  .w-70p-md {
    width: 70% !important;
  }

  .w-80p-md {
    width: 80% !important;
  }

  .w-90p-md {
    width: 90% !important;
  }

  .w-100p-md {
    width: 100% !important;
  }

  .w-1-md {
    width: .5rem !important;
  }

  .w-2-md {
    width: 1rem !important;
  }

  .w-3-md {
    width: 1.5rem !important;
  }

  .w-4-md {
    width: 2rem !important;
  }

  .w-5-md {
    width: 2.5rem !important;
  }

  .w-6-md {
    width: 3rem !important;
  }

  .w-8-md {
    width: 4rem !important;
  }

  .w-10-md {
    width: 5rem !important;
  }

  .w-12-md {
    width: 6rem !important;
  }

  .w-16-md {
    width: 8rem !important;
  }

  .w-20-md {
    width: 10rem !important;
  }

  .w-24-md {
    width: 12rem !important;
  }

  .w-32-md {
    width: 16rem !important;
  }
}

@media screen and (min-width: 1024px) {
  .w-0-lg {
    width: 0 !important;
  }

  .w-auto-lg {
    width: auto !important;
  }

  .w-screen-lg {
    width: 100vw !important;
  }

  .w-10p-lg {
    width: 10% !important;
  }

  .w-20p-lg {
    width: 20% !important;
  }

  .w-30p-lg {
    width: 30% !important;
  }

  .w-40p-lg {
    width: 40% !important;
  }

  .w-50p-lg {
    width: 50% !important;
  }

  .w-60p-lg {
    width: 60% !important;
  }

  .w-70p-lg {
    width: 70% !important;
  }

  .w-80p-lg {
    width: 80% !important;
  }

  .w-90p-lg {
    width: 90% !important;
  }

  .w-100p-lg {
    width: 100% !important;
  }

  .w-1-lg {
    width: .5rem !important;
  }

  .w-2-lg {
    width: 1rem !important;
  }

  .w-3-lg {
    width: 1.5rem !important;
  }

  .w-4-lg {
    width: 2rem !important;
  }

  .w-5-lg {
    width: 2.5rem !important;
  }

  .w-6-lg {
    width: 3rem !important;
  }

  .w-8-lg {
    width: 4rem !important;
  }

  .w-10-lg {
    width: 5rem !important;
  }

  .w-12-lg {
    width: 6rem !important;
  }

  .w-16-lg {
    width: 8rem !important;
  }

  .w-20-lg {
    width: 10rem !important;
  }

  .w-24-lg {
    width: 12rem !important;
  }

  .w-32-lg {
    width: 16rem !important;
  }
}

@media screen and (min-width: 1280px) {
  .w-0-xl {
    width: 0 !important;
  }

  .w-auto-xl {
    width: auto !important;
  }

  .w-screen-xl {
    width: 100vw !important;
  }

  .w-10p-xl {
    width: 10% !important;
  }

  .w-20p-xl {
    width: 20% !important;
  }

  .w-30p-xl {
    width: 30% !important;
  }

  .w-40p-xl {
    width: 40% !important;
  }

  .w-50p-xl {
    width: 50% !important;
  }

  .w-60p-xl {
    width: 60% !important;
  }

  .w-70p-xl {
    width: 70% !important;
  }

  .w-80p-xl {
    width: 80% !important;
  }

  .w-90p-xl {
    width: 90% !important;
  }

  .w-100p-xl {
    width: 100% !important;
  }

  .w-1-xl {
    width: .5rem !important;
  }

  .w-2-xl {
    width: 1rem !important;
  }

  .w-3-xl {
    width: 1.5rem !important;
  }

  .w-4-xl {
    width: 2rem !important;
  }

  .w-5-xl {
    width: 2.5rem !important;
  }

  .w-6-xl {
    width: 3rem !important;
  }

  .w-8-xl {
    width: 4rem !important;
  }

  .w-10-xl {
    width: 5rem !important;
  }

  .w-12-xl {
    width: 6rem !important;
  }

  .w-16-xl {
    width: 8rem !important;
  }

  .w-20-xl {
    width: 10rem !important;
  }

  .w-24-xl {
    width: 12rem !important;
  }

  .w-32-xl {
    width: 16rem !important;
  }
}

.min-h-10p {
  min-height: 10% !important;
}

.min-h-20p {
  min-height: 20% !important;
}

.min-h-30p {
  min-height: 30% !important;
}

.min-h-40p {
  min-height: 40% !important;
}

.min-h-50p {
  min-height: 50% !important;
}

.min-h-60p {
  min-height: 60% !important;
}

.min-h-70p {
  min-height: 70% !important;
}

.min-h-80p {
  min-height: 80% !important;
}

.min-h-90p {
  min-height: 90% !important;
}

.min-h-100p {
  min-height: 100% !important;
}

.min-h-0 {
  min-height: 0 !important;
}

.min-h-screen {
  min-height: 100vh !important;
}

.min-w-xs {
  min-width: 640px !important;
}

.min-w-sm {
  min-width: 768px !important;
}

.min-w-md {
  min-width: 1024px !important;
}

.min-w-lg {
  min-width: 1280px !important;
}

.min-w-xl {
  min-width: 1536px !important;
}

.min-w-10p {
  min-width: 10% !important;
}

.min-w-20p {
  min-width: 20% !important;
}

.min-w-30p {
  min-width: 30% !important;
}

.min-w-40p {
  min-width: 40% !important;
}

.min-w-50p {
  min-width: 50% !important;
}

.min-w-60p {
  min-width: 60% !important;
}

.min-w-70p {
  min-width: 70% !important;
}

.min-w-80p {
  min-width: 80% !important;
}

.min-w-90p {
  min-width: 90% !important;
}

.min-w-100p {
  min-width: 100% !important;
}

.min-w-0 {
  min-width: 0 !important;
}

.min-w-screen {
  min-width: 100vw !important;
}

.max-h-10p {
  max-height: 10% !important;
}

.max-h-20p {
  max-height: 20% !important;
}

.max-h-30p {
  max-height: 30% !important;
}

.max-h-40p {
  max-height: 40% !important;
}

.max-h-50p {
  max-height: 50% !important;
}

.max-h-60p {
  max-height: 60% !important;
}

.max-h-70p {
  max-height: 70% !important;
}

.max-h-80p {
  max-height: 80% !important;
}

.max-h-90p {
  max-height: 90% !important;
}

.max-h-100p {
  max-height: 100% !important;
}

.max-h-none {
  max-height: none !important;
}

.max-h-screen {
  max-height: 100vh !important;
}

.max-w-xs {
  max-width: 640px !important;
}

.max-w-sm {
  max-width: 768px !important;
}

.max-w-md {
  max-width: 1024px !important;
}

.max-w-lg {
  max-width: 1280px !important;
}

.max-w-xl {
  max-width: 1536px !important;
}

.max-w-10p {
  max-width: 10% !important;
}

.max-w-20p {
  max-width: 20% !important;
}

.max-w-30p {
  max-width: 30% !important;
}

.max-w-40p {
  max-width: 40% !important;
}

.max-w-50p {
  max-width: 50% !important;
}

.max-w-60p {
  max-width: 60% !important;
}

.max-w-70p {
  max-width: 70% !important;
}

.max-w-80p {
  max-width: 80% !important;
}

.max-w-90p {
  max-width: 90% !important;
}

.max-w-100p {
  max-width: 100% !important;
}

.max-w-none {
  max-width: none !important;
}

.max-w-screen {
  max-width: 100vw !important;
}

@media screen and (min-width: 768px) {
  .level, .level-left, .level-right {
    display: flex;
  }
}

@media screen and (max-width: 767px) {
  .level-right {
    margin-left: 1rem;
  }

  .level.fill-height {
    align-items: stretch;
    display: flex;
  }

  .container {
    width: 100%;
  }

  .row {
    margin-top: 0;
  }

  .divided > .row [class^="col-"], .divided > .row [class*=" col-"] {
    box-shadow: 0 -1px #22242626;
  }

  .level.fill-height {
    display: inherit;
  }

  .hero-body {
    padding: 0;
  }
}

video.video-fullscreen {
  height: 100vh;
  object-fit: cover;
  width: 100%;
  z-index: -1;
  position: absolute;
}

.hero.fullscreen video {
  height: 100%;
  object-fit: fill;
  width: 100%;
  z-index: -1;
  position: absolute;
}

.media-stretch {
  width: 100%;
  padding: 0;
  display: block;
  position: relative;
  overflow: hidden;
}

.media-stretch:before {
  content: "";
  padding-bottom: 56.25%;
  display: block;
}

.media-stretch iframe, .media-stretch embed, .media-stretch object {
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0;
}

.media-stretch.rat-4-3:before {
  padding-bottom: 75%;
}

.media-stretch.rat-1-1:before {
  padding-bottom: 100%;
}

.media-stretch video {
  height: auto;
  max-width: 100%;
  width: 100%;
}

.fig {
  margin: 0 0 .5rem;
}

.fig .fig-caption {
  margin-top: 1rem;
}

.img-stretch {
  max-width: 100%;
  height: auto;
  display: block;
}

.img-cover {
  object-fit: cover;
}

.img-contain {
  object-fit: contain;
}

.btn.btn--pilled, button.btn--pilled, [type="submit"].btn--pilled, [type="reset"].btn--pilled, [type="button"].btn--pilled {
  border-radius: 6.25rem;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.btn.btn--circle, button.btn--circle, [type="submit"].btn--circle, [type="reset"].btn--circle, [type="button"].btn--circle {
  min-width: 60px;
  text-align: center;
  border-radius: 100%;
  padding: 0;
  overflow: hidden;
}

.btn.btn--circle:before, button.btn--circle:before, [type="submit"].btn--circle:before, [type="reset"].btn--circle:before, [type="button"].btn--circle:before {
  content: "";
  vertical-align: middle;
  padding-top: 100%;
  display: inline-block;
}

.btn.btn--circle *, button.btn--circle *, [type="submit"].btn--circle *, [type="reset"].btn--circle *, [type="button"].btn--circle * {
  max-width: 90%;
  vertical-align: middle;
  white-space: pre-wrap;
  display: inline;
}

.input-control--pilled {
  border-radius: 6.25rem !important;
}

.bg-primary {
  background-color: rgba(240, 61, 77, var(--bg-opacity)) !important;
}

.text-primary {
  border-color: rgba(240, 61, 77, var(--border-opacity));
  color: rgba(240, 61, 77, var(--color-opacity)) !important;
}

.border-primary {
  border-color: rgba(240, 61, 77, var(--border-opacity)) !important;
}

.bg-success {
  background-color: rgba(13, 209, 87, var(--bg-opacity)) !important;
}

.text-success {
  border-color: rgba(13, 209, 87, var(--border-opacity));
  color: rgba(13, 209, 87, var(--color-opacity)) !important;
}

.border-success {
  border-color: rgba(13, 209, 87, var(--border-opacity)) !important;
}

.bg-warning {
  background-color: rgba(250, 182, 51, var(--bg-opacity)) !important;
}

.text-warning {
  border-color: rgba(250, 182, 51, var(--border-opacity));
  color: rgba(250, 182, 51, var(--color-opacity)) !important;
}

.border-warning {
  border-color: rgba(250, 182, 51, var(--border-opacity)) !important;
}

.bg-danger {
  background-color: rgba(251, 65, 67, var(--bg-opacity)) !important;
}

.text-danger {
  border-color: rgba(251, 65, 67, var(--border-opacity));
  color: rgba(251, 65, 67, var(--color-opacity)) !important;
}

.border-danger {
  border-color: rgba(251, 65, 67, var(--border-opacity)) !important;
}

.bg-light {
  background-color: rgba(246, 249, 252, var(--bg-opacity)) !important;
}

.text-light {
  border-color: rgba(246, 249, 252, var(--border-opacity));
  color: rgba(246, 249, 252, var(--color-opacity)) !important;
}

.border-light {
  border-color: rgba(246, 249, 252, var(--border-opacity)) !important;
}

.bg-dark {
  background-color: rgba(54, 54, 54, var(--bg-opacity)) !important;
}

.text-dark {
  border-color: rgba(54, 54, 54, var(--border-opacity));
  color: rgba(54, 54, 54, var(--color-opacity)) !important;
}

.border-dark {
  border-color: rgba(54, 54, 54, var(--border-opacity)) !important;
}

.bg-link {
  background-color: rgba(94, 92, 199, var(--bg-opacity)) !important;
}

.text-link {
  border-color: rgba(94, 92, 199, var(--border-opacity));
  color: rgba(94, 92, 199, var(--color-opacity)) !important;
}

.border-link {
  border-color: rgba(94, 92, 199, var(--border-opacity)) !important;
}

.bg-link-dark {
  background-color: rgba(70, 67, 226, var(--bg-opacity)) !important;
}

.text-link-dark {
  border-color: rgba(70, 67, 226, var(--border-opacity));
  color: rgba(70, 67, 226, var(--color-opacity)) !important;
}

.border-link-dark {
  border-color: rgba(70, 67, 226, var(--border-opacity)) !important;
}

.bg-info {
  background-color: rgba(41, 114, 250, var(--bg-opacity)) !important;
}

.text-info {
  border-color: rgba(41, 114, 250, var(--border-opacity));
  color: rgba(41, 114, 250, var(--color-opacity)) !important;
}

.border-info {
  border-color: rgba(41, 114, 250, var(--border-opacity)) !important;
}

.bg-black {
  background-color: rgba(0, 0, 0, var(--bg-opacity)) !important;
}

.text-black {
  border-color: rgba(0, 0, 0, var(--border-opacity));
  color: rgba(0, 0, 0, var(--color-opacity)) !important;
}

.border-black {
  border-color: rgba(0, 0, 0, var(--border-opacity)) !important;
}

.bg-white {
  background-color: rgba(255, 255, 255, var(--bg-opacity)) !important;
}

.text-white {
  border-color: rgba(255, 255, 255, var(--border-opacity));
  color: rgba(255, 255, 255, var(--color-opacity)) !important;
}

.border-white {
  border-color: rgba(255, 255, 255, var(--border-opacity)) !important;
}

.bg-transparent {
  background-color: #0000 !important;
}

.text-transparent {
  color: #0000 !important;
  border-color: #0000 !important;
}

.bg-pink-100 {
  background-color: rgba(252, 232, 243, var(--bg-opacity)) !important;
}

.text-pink-100 {
  border-color: rgba(252, 232, 243, var(--border-opacity));
  color: rgba(252, 232, 243, var(--color-opacity)) !important;
}

.border-pink-100 {
  border-color: rgba(252, 232, 243, var(--border-opacity)) !important;
}

.bg-pink-200 {
  background-color: rgba(250, 209, 232, var(--bg-opacity)) !important;
}

.text-pink-200 {
  border-color: rgba(250, 209, 232, var(--border-opacity));
  color: rgba(250, 209, 232, var(--color-opacity)) !important;
}

.border-pink-200 {
  border-color: rgba(250, 209, 232, var(--border-opacity)) !important;
}

.bg-pink-300 {
  background-color: rgba(248, 180, 217, var(--bg-opacity)) !important;
}

.text-pink-300 {
  border-color: rgba(248, 180, 217, var(--border-opacity));
  color: rgba(248, 180, 217, var(--color-opacity)) !important;
}

.border-pink-300 {
  border-color: rgba(248, 180, 217, var(--border-opacity)) !important;
}

.bg-pink-400 {
  background-color: rgba(241, 126, 184, var(--bg-opacity)) !important;
}

.text-pink-400 {
  border-color: rgba(241, 126, 184, var(--border-opacity));
  color: rgba(241, 126, 184, var(--color-opacity)) !important;
}

.border-pink-400 {
  border-color: rgba(241, 126, 184, var(--border-opacity)) !important;
}

.bg-pink-500 {
  background-color: rgba(231, 70, 148, var(--bg-opacity)) !important;
}

.text-pink-500 {
  border-color: rgba(231, 70, 148, var(--border-opacity));
  color: rgba(231, 70, 148, var(--color-opacity)) !important;
}

.border-pink-500 {
  border-color: rgba(231, 70, 148, var(--border-opacity)) !important;
}

.bg-pink-600 {
  background-color: rgba(214, 31, 105, var(--bg-opacity)) !important;
}

.text-pink-600 {
  border-color: rgba(214, 31, 105, var(--border-opacity));
  color: rgba(214, 31, 105, var(--color-opacity)) !important;
}

.border-pink-600 {
  border-color: rgba(214, 31, 105, var(--border-opacity)) !important;
}

.bg-pink-700 {
  background-color: rgba(191, 18, 93, var(--bg-opacity)) !important;
}

.text-pink-700 {
  border-color: rgba(191, 18, 93, var(--border-opacity));
  color: rgba(191, 18, 93, var(--color-opacity)) !important;
}

.border-pink-700 {
  border-color: rgba(191, 18, 93, var(--border-opacity)) !important;
}

.bg-pink-800 {
  background-color: rgba(153, 21, 75, var(--bg-opacity)) !important;
}

.text-pink-800 {
  border-color: rgba(153, 21, 75, var(--border-opacity));
  color: rgba(153, 21, 75, var(--color-opacity)) !important;
}

.border-pink-800 {
  border-color: rgba(153, 21, 75, var(--border-opacity)) !important;
}

.bg-pink-900 {
  background-color: rgba(117, 26, 61, var(--bg-opacity)) !important;
}

.text-pink-900 {
  border-color: rgba(117, 26, 61, var(--border-opacity));
  color: rgba(117, 26, 61, var(--color-opacity)) !important;
}

.border-pink-900 {
  border-color: rgba(117, 26, 61, var(--border-opacity)) !important;
}

.bg-red-100 {
  background-color: rgba(253, 232, 232, var(--bg-opacity)) !important;
}

.text-red-100 {
  border-color: rgba(253, 232, 232, var(--border-opacity));
  color: rgba(253, 232, 232, var(--color-opacity)) !important;
}

.border-red-100 {
  border-color: rgba(253, 232, 232, var(--border-opacity)) !important;
}

.bg-red-200 {
  background-color: rgba(251, 213, 213, var(--bg-opacity)) !important;
}

.text-red-200 {
  border-color: rgba(251, 213, 213, var(--border-opacity));
  color: rgba(251, 213, 213, var(--color-opacity)) !important;
}

.border-red-200 {
  border-color: rgba(251, 213, 213, var(--border-opacity)) !important;
}

.bg-red-300 {
  background-color: rgba(248, 180, 180, var(--bg-opacity)) !important;
}

.text-red-300 {
  border-color: rgba(248, 180, 180, var(--border-opacity));
  color: rgba(248, 180, 180, var(--color-opacity)) !important;
}

.border-red-300 {
  border-color: rgba(248, 180, 180, var(--border-opacity)) !important;
}

.bg-red-400 {
  background-color: rgba(249, 128, 128, var(--bg-opacity)) !important;
}

.text-red-400 {
  border-color: rgba(249, 128, 128, var(--border-opacity));
  color: rgba(249, 128, 128, var(--color-opacity)) !important;
}

.border-red-400 {
  border-color: rgba(249, 128, 128, var(--border-opacity)) !important;
}

.bg-red-500 {
  background-color: rgba(240, 82, 82, var(--bg-opacity)) !important;
}

.text-red-500 {
  border-color: rgba(240, 82, 82, var(--border-opacity));
  color: rgba(240, 82, 82, var(--color-opacity)) !important;
}

.border-red-500 {
  border-color: rgba(240, 82, 82, var(--border-opacity)) !important;
}

.bg-red-600 {
  background-color: rgba(224, 36, 36, var(--bg-opacity)) !important;
}

.text-red-600 {
  border-color: rgba(224, 36, 36, var(--border-opacity));
  color: rgba(224, 36, 36, var(--color-opacity)) !important;
}

.border-red-600 {
  border-color: rgba(224, 36, 36, var(--border-opacity)) !important;
}

.bg-red-700 {
  background-color: rgba(200, 30, 30, var(--bg-opacity)) !important;
}

.text-red-700 {
  border-color: rgba(200, 30, 30, var(--border-opacity));
  color: rgba(200, 30, 30, var(--color-opacity)) !important;
}

.border-red-700 {
  border-color: rgba(200, 30, 30, var(--border-opacity)) !important;
}

.bg-red-800 {
  background-color: rgba(155, 28, 28, var(--bg-opacity)) !important;
}

.text-red-800 {
  border-color: rgba(155, 28, 28, var(--border-opacity));
  color: rgba(155, 28, 28, var(--color-opacity)) !important;
}

.border-red-800 {
  border-color: rgba(155, 28, 28, var(--border-opacity)) !important;
}

.bg-red-900 {
  background-color: rgba(119, 29, 29, var(--bg-opacity)) !important;
}

.text-red-900 {
  border-color: rgba(119, 29, 29, var(--border-opacity));
  color: rgba(119, 29, 29, var(--color-opacity)) !important;
}

.border-red-900 {
  border-color: rgba(119, 29, 29, var(--border-opacity)) !important;
}

.bg-orange-100 {
  background-color: rgba(254, 236, 220, var(--bg-opacity)) !important;
}

.text-orange-100 {
  border-color: rgba(254, 236, 220, var(--border-opacity));
  color: rgba(254, 236, 220, var(--color-opacity)) !important;
}

.border-orange-100 {
  border-color: rgba(254, 236, 220, var(--border-opacity)) !important;
}

.bg-orange-200 {
  background-color: rgba(252, 217, 189, var(--bg-opacity)) !important;
}

.text-orange-200 {
  border-color: rgba(252, 217, 189, var(--border-opacity));
  color: rgba(252, 217, 189, var(--color-opacity)) !important;
}

.border-orange-200 {
  border-color: rgba(252, 217, 189, var(--border-opacity)) !important;
}

.bg-orange-300 {
  background-color: rgba(253, 186, 140, var(--bg-opacity)) !important;
}

.text-orange-300 {
  border-color: rgba(253, 186, 140, var(--border-opacity));
  color: rgba(253, 186, 140, var(--color-opacity)) !important;
}

.border-orange-300 {
  border-color: rgba(253, 186, 140, var(--border-opacity)) !important;
}

.bg-orange-400 {
  background-color: rgba(255, 138, 76, var(--bg-opacity)) !important;
}

.text-orange-400 {
  border-color: rgba(255, 138, 76, var(--border-opacity));
  color: rgba(255, 138, 76, var(--color-opacity)) !important;
}

.border-orange-400 {
  border-color: rgba(255, 138, 76, var(--border-opacity)) !important;
}

.bg-orange-500 {
  background-color: rgba(255, 90, 31, var(--bg-opacity)) !important;
}

.text-orange-500 {
  border-color: rgba(255, 90, 31, var(--border-opacity));
  color: rgba(255, 90, 31, var(--color-opacity)) !important;
}

.border-orange-500 {
  border-color: rgba(255, 90, 31, var(--border-opacity)) !important;
}

.bg-orange-600 {
  background-color: rgba(208, 56, 1, var(--bg-opacity)) !important;
}

.text-orange-600 {
  border-color: rgba(208, 56, 1, var(--border-opacity));
  color: rgba(208, 56, 1, var(--color-opacity)) !important;
}

.border-orange-600 {
  border-color: rgba(208, 56, 1, var(--border-opacity)) !important;
}

.bg-orange-700 {
  background-color: rgba(180, 52, 3, var(--bg-opacity)) !important;
}

.text-orange-700 {
  border-color: rgba(180, 52, 3, var(--border-opacity));
  color: rgba(180, 52, 3, var(--color-opacity)) !important;
}

.border-orange-700 {
  border-color: rgba(180, 52, 3, var(--border-opacity)) !important;
}

.bg-orange-800 {
  background-color: rgba(138, 44, 13, var(--bg-opacity)) !important;
}

.text-orange-800 {
  border-color: rgba(138, 44, 13, var(--border-opacity));
  color: rgba(138, 44, 13, var(--color-opacity)) !important;
}

.border-orange-800 {
  border-color: rgba(138, 44, 13, var(--border-opacity)) !important;
}

.bg-orange-900 {
  background-color: rgba(115, 35, 13, var(--bg-opacity)) !important;
}

.text-orange-900 {
  border-color: rgba(115, 35, 13, var(--border-opacity));
  color: rgba(115, 35, 13, var(--color-opacity)) !important;
}

.border-orange-900 {
  border-color: rgba(115, 35, 13, var(--border-opacity)) !important;
}

.bg-yellow-100 {
  background-color: rgba(253, 246, 178, var(--bg-opacity)) !important;
}

.text-yellow-100 {
  border-color: rgba(253, 246, 178, var(--border-opacity));
  color: rgba(253, 246, 178, var(--color-opacity)) !important;
}

.border-yellow-100 {
  border-color: rgba(253, 246, 178, var(--border-opacity)) !important;
}

.bg-yellow-200 {
  background-color: rgba(252, 233, 106, var(--bg-opacity)) !important;
}

.text-yellow-200 {
  border-color: rgba(252, 233, 106, var(--border-opacity));
  color: rgba(252, 233, 106, var(--color-opacity)) !important;
}

.border-yellow-200 {
  border-color: rgba(252, 233, 106, var(--border-opacity)) !important;
}

.bg-yellow-300 {
  background-color: rgba(250, 202, 21, var(--bg-opacity)) !important;
}

.text-yellow-300 {
  border-color: rgba(250, 202, 21, var(--border-opacity));
  color: rgba(250, 202, 21, var(--color-opacity)) !important;
}

.border-yellow-300 {
  border-color: rgba(250, 202, 21, var(--border-opacity)) !important;
}

.bg-yellow-400 {
  background-color: rgba(227, 160, 8, var(--bg-opacity)) !important;
}

.text-yellow-400 {
  border-color: rgba(227, 160, 8, var(--border-opacity));
  color: rgba(227, 160, 8, var(--color-opacity)) !important;
}

.border-yellow-400 {
  border-color: rgba(227, 160, 8, var(--border-opacity)) !important;
}

.bg-yellow-500 {
  background-color: rgba(194, 120, 3, var(--bg-opacity)) !important;
}

.text-yellow-500 {
  border-color: rgba(194, 120, 3, var(--border-opacity));
  color: rgba(194, 120, 3, var(--color-opacity)) !important;
}

.border-yellow-500 {
  border-color: rgba(194, 120, 3, var(--border-opacity)) !important;
}

.bg-yellow-600 {
  background-color: rgba(159, 88, 10, var(--bg-opacity)) !important;
}

.text-yellow-600 {
  border-color: rgba(159, 88, 10, var(--border-opacity));
  color: rgba(159, 88, 10, var(--color-opacity)) !important;
}

.border-yellow-600 {
  border-color: rgba(159, 88, 10, var(--border-opacity)) !important;
}

.bg-yellow-700 {
  background-color: rgba(142, 75, 16, var(--bg-opacity)) !important;
}

.text-yellow-700 {
  border-color: rgba(142, 75, 16, var(--border-opacity));
  color: rgba(142, 75, 16, var(--color-opacity)) !important;
}

.border-yellow-700 {
  border-color: rgba(142, 75, 16, var(--border-opacity)) !important;
}

.bg-yellow-800 {
  background-color: rgba(114, 59, 19, var(--bg-opacity)) !important;
}

.text-yellow-800 {
  border-color: rgba(114, 59, 19, var(--border-opacity));
  color: rgba(114, 59, 19, var(--color-opacity)) !important;
}

.border-yellow-800 {
  border-color: rgba(114, 59, 19, var(--border-opacity)) !important;
}

.bg-yellow-900 {
  background-color: rgba(99, 49, 18, var(--bg-opacity)) !important;
}

.text-yellow-900 {
  border-color: rgba(99, 49, 18, var(--border-opacity));
  color: rgba(99, 49, 18, var(--color-opacity)) !important;
}

.border-yellow-900 {
  border-color: rgba(99, 49, 18, var(--border-opacity)) !important;
}

.bg-green-100 {
  background-color: rgba(222, 247, 236, var(--bg-opacity)) !important;
}

.text-green-100 {
  border-color: rgba(222, 247, 236, var(--border-opacity));
  color: rgba(222, 247, 236, var(--color-opacity)) !important;
}

.border-green-100 {
  border-color: rgba(222, 247, 236, var(--border-opacity)) !important;
}

.bg-green-200 {
  background-color: rgba(188, 240, 218, var(--bg-opacity)) !important;
}

.text-green-200 {
  border-color: rgba(188, 240, 218, var(--border-opacity));
  color: rgba(188, 240, 218, var(--color-opacity)) !important;
}

.border-green-200 {
  border-color: rgba(188, 240, 218, var(--border-opacity)) !important;
}

.bg-green-300 {
  background-color: rgba(132, 225, 188, var(--bg-opacity)) !important;
}

.text-green-300 {
  border-color: rgba(132, 225, 188, var(--border-opacity));
  color: rgba(132, 225, 188, var(--color-opacity)) !important;
}

.border-green-300 {
  border-color: rgba(132, 225, 188, var(--border-opacity)) !important;
}

.bg-green-400 {
  background-color: rgba(49, 196, 141, var(--bg-opacity)) !important;
}

.text-green-400 {
  border-color: rgba(49, 196, 141, var(--border-opacity));
  color: rgba(49, 196, 141, var(--color-opacity)) !important;
}

.border-green-400 {
  border-color: rgba(49, 196, 141, var(--border-opacity)) !important;
}

.bg-green-500 {
  background-color: rgba(14, 159, 110, var(--bg-opacity)) !important;
}

.text-green-500 {
  border-color: rgba(14, 159, 110, var(--border-opacity));
  color: rgba(14, 159, 110, var(--color-opacity)) !important;
}

.border-green-500 {
  border-color: rgba(14, 159, 110, var(--border-opacity)) !important;
}

.bg-green-600 {
  background-color: rgba(5, 122, 85, var(--bg-opacity)) !important;
}

.text-green-600 {
  border-color: rgba(5, 122, 85, var(--border-opacity));
  color: rgba(5, 122, 85, var(--color-opacity)) !important;
}

.border-green-600 {
  border-color: rgba(5, 122, 85, var(--border-opacity)) !important;
}

.bg-green-700 {
  background-color: rgba(4, 108, 78, var(--bg-opacity)) !important;
}

.text-green-700 {
  border-color: rgba(4, 108, 78, var(--border-opacity));
  color: rgba(4, 108, 78, var(--color-opacity)) !important;
}

.border-green-700 {
  border-color: rgba(4, 108, 78, var(--border-opacity)) !important;
}

.bg-green-800 {
  background-color: rgba(3, 84, 63, var(--bg-opacity)) !important;
}

.text-green-800 {
  border-color: rgba(3, 84, 63, var(--border-opacity));
  color: rgba(3, 84, 63, var(--color-opacity)) !important;
}

.border-green-800 {
  border-color: rgba(3, 84, 63, var(--border-opacity)) !important;
}

.bg-green-900 {
  background-color: rgba(1, 71, 55, var(--bg-opacity)) !important;
}

.text-green-900 {
  border-color: rgba(1, 71, 55, var(--border-opacity));
  color: rgba(1, 71, 55, var(--color-opacity)) !important;
}

.border-green-900 {
  border-color: rgba(1, 71, 55, var(--border-opacity)) !important;
}

.bg-teal-100 {
  background-color: rgba(213, 245, 246, var(--bg-opacity)) !important;
}

.text-teal-100 {
  border-color: rgba(213, 245, 246, var(--border-opacity));
  color: rgba(213, 245, 246, var(--color-opacity)) !important;
}

.border-teal-100 {
  border-color: rgba(213, 245, 246, var(--border-opacity)) !important;
}

.bg-teal-200 {
  background-color: rgba(175, 236, 239, var(--bg-opacity)) !important;
}

.text-teal-200 {
  border-color: rgba(175, 236, 239, var(--border-opacity));
  color: rgba(175, 236, 239, var(--color-opacity)) !important;
}

.border-teal-200 {
  border-color: rgba(175, 236, 239, var(--border-opacity)) !important;
}

.bg-teal-300 {
  background-color: rgba(126, 220, 226, var(--bg-opacity)) !important;
}

.text-teal-300 {
  border-color: rgba(126, 220, 226, var(--border-opacity));
  color: rgba(126, 220, 226, var(--color-opacity)) !important;
}

.border-teal-300 {
  border-color: rgba(126, 220, 226, var(--border-opacity)) !important;
}

.bg-teal-400 {
  background-color: rgba(22, 189, 202, var(--bg-opacity)) !important;
}

.text-teal-400 {
  border-color: rgba(22, 189, 202, var(--border-opacity));
  color: rgba(22, 189, 202, var(--color-opacity)) !important;
}

.border-teal-400 {
  border-color: rgba(22, 189, 202, var(--border-opacity)) !important;
}

.bg-teal-500 {
  background-color: rgba(6, 148, 162, var(--bg-opacity)) !important;
}

.text-teal-500 {
  border-color: rgba(6, 148, 162, var(--border-opacity));
  color: rgba(6, 148, 162, var(--color-opacity)) !important;
}

.border-teal-500 {
  border-color: rgba(6, 148, 162, var(--border-opacity)) !important;
}

.bg-teal-600 {
  background-color: rgba(4, 116, 129, var(--bg-opacity)) !important;
}

.text-teal-600 {
  border-color: rgba(4, 116, 129, var(--border-opacity));
  color: rgba(4, 116, 129, var(--color-opacity)) !important;
}

.border-teal-600 {
  border-color: rgba(4, 116, 129, var(--border-opacity)) !important;
}

.bg-teal-700 {
  background-color: rgba(3, 102, 114, var(--bg-opacity)) !important;
}

.text-teal-700 {
  border-color: rgba(3, 102, 114, var(--border-opacity));
  color: rgba(3, 102, 114, var(--color-opacity)) !important;
}

.border-teal-700 {
  border-color: rgba(3, 102, 114, var(--border-opacity)) !important;
}

.bg-teal-800 {
  background-color: rgba(5, 80, 92, var(--bg-opacity)) !important;
}

.text-teal-800 {
  border-color: rgba(5, 80, 92, var(--border-opacity));
  color: rgba(5, 80, 92, var(--color-opacity)) !important;
}

.border-teal-800 {
  border-color: rgba(5, 80, 92, var(--border-opacity)) !important;
}

.bg-teal-900 {
  background-color: rgba(1, 68, 81, var(--bg-opacity)) !important;
}

.text-teal-900 {
  border-color: rgba(1, 68, 81, var(--border-opacity));
  color: rgba(1, 68, 81, var(--color-opacity)) !important;
}

.border-teal-900 {
  border-color: rgba(1, 68, 81, var(--border-opacity)) !important;
}

.bg-blue-100 {
  background-color: rgba(225, 239, 254, var(--bg-opacity)) !important;
}

.text-blue-100 {
  border-color: rgba(225, 239, 254, var(--border-opacity));
  color: rgba(225, 239, 254, var(--color-opacity)) !important;
}

.border-blue-100 {
  border-color: rgba(225, 239, 254, var(--border-opacity)) !important;
}

.bg-blue-200 {
  background-color: rgba(195, 221, 253, var(--bg-opacity)) !important;
}

.text-blue-200 {
  border-color: rgba(195, 221, 253, var(--border-opacity));
  color: rgba(195, 221, 253, var(--color-opacity)) !important;
}

.border-blue-200 {
  border-color: rgba(195, 221, 253, var(--border-opacity)) !important;
}

.bg-blue-300 {
  background-color: rgba(164, 202, 254, var(--bg-opacity)) !important;
}

.text-blue-300 {
  border-color: rgba(164, 202, 254, var(--border-opacity));
  color: rgba(164, 202, 254, var(--color-opacity)) !important;
}

.border-blue-300 {
  border-color: rgba(164, 202, 254, var(--border-opacity)) !important;
}

.bg-blue-400 {
  background-color: rgba(118, 169, 250, var(--bg-opacity)) !important;
}

.text-blue-400 {
  border-color: rgba(118, 169, 250, var(--border-opacity));
  color: rgba(118, 169, 250, var(--color-opacity)) !important;
}

.border-blue-400 {
  border-color: rgba(118, 169, 250, var(--border-opacity)) !important;
}

.bg-blue-500 {
  background-color: rgba(63, 131, 248, var(--bg-opacity)) !important;
}

.text-blue-500 {
  border-color: rgba(63, 131, 248, var(--border-opacity));
  color: rgba(63, 131, 248, var(--color-opacity)) !important;
}

.border-blue-500 {
  border-color: rgba(63, 131, 248, var(--border-opacity)) !important;
}

.bg-blue-600 {
  background-color: rgba(28, 100, 242, var(--bg-opacity)) !important;
}

.text-blue-600 {
  border-color: rgba(28, 100, 242, var(--border-opacity));
  color: rgba(28, 100, 242, var(--color-opacity)) !important;
}

.border-blue-600 {
  border-color: rgba(28, 100, 242, var(--border-opacity)) !important;
}

.bg-blue-700 {
  background-color: rgba(26, 86, 219, var(--bg-opacity)) !important;
}

.text-blue-700 {
  border-color: rgba(26, 86, 219, var(--border-opacity));
  color: rgba(26, 86, 219, var(--color-opacity)) !important;
}

.border-blue-700 {
  border-color: rgba(26, 86, 219, var(--border-opacity)) !important;
}

.bg-blue-800 {
  background-color: rgba(30, 66, 159, var(--bg-opacity)) !important;
}

.text-blue-800 {
  border-color: rgba(30, 66, 159, var(--border-opacity));
  color: rgba(30, 66, 159, var(--color-opacity)) !important;
}

.border-blue-800 {
  border-color: rgba(30, 66, 159, var(--border-opacity)) !important;
}

.bg-blue-900 {
  background-color: rgba(35, 56, 118, var(--bg-opacity)) !important;
}

.text-blue-900 {
  border-color: rgba(35, 56, 118, var(--border-opacity));
  color: rgba(35, 56, 118, var(--color-opacity)) !important;
}

.border-blue-900 {
  border-color: rgba(35, 56, 118, var(--border-opacity)) !important;
}

.bg-indigo-100 {
  background-color: rgba(229, 237, 255, var(--bg-opacity)) !important;
}

.text-indigo-100 {
  border-color: rgba(229, 237, 255, var(--border-opacity));
  color: rgba(229, 237, 255, var(--color-opacity)) !important;
}

.border-indigo-100 {
  border-color: rgba(229, 237, 255, var(--border-opacity)) !important;
}

.bg-indigo-200 {
  background-color: rgba(205, 219, 254, var(--bg-opacity)) !important;
}

.text-indigo-200 {
  border-color: rgba(205, 219, 254, var(--border-opacity));
  color: rgba(205, 219, 254, var(--color-opacity)) !important;
}

.border-indigo-200 {
  border-color: rgba(205, 219, 254, var(--border-opacity)) !important;
}

.bg-indigo-300 {
  background-color: rgba(180, 198, 252, var(--bg-opacity)) !important;
}

.text-indigo-300 {
  border-color: rgba(180, 198, 252, var(--border-opacity));
  color: rgba(180, 198, 252, var(--color-opacity)) !important;
}

.border-indigo-300 {
  border-color: rgba(180, 198, 252, var(--border-opacity)) !important;
}

.bg-indigo-400 {
  background-color: rgba(141, 162, 251, var(--bg-opacity)) !important;
}

.text-indigo-400 {
  border-color: rgba(141, 162, 251, var(--border-opacity));
  color: rgba(141, 162, 251, var(--color-opacity)) !important;
}

.border-indigo-400 {
  border-color: rgba(141, 162, 251, var(--border-opacity)) !important;
}

.bg-indigo-500 {
  background-color: rgba(104, 117, 245, var(--bg-opacity)) !important;
}

.text-indigo-500 {
  border-color: rgba(104, 117, 245, var(--border-opacity));
  color: rgba(104, 117, 245, var(--color-opacity)) !important;
}

.border-indigo-500 {
  border-color: rgba(104, 117, 245, var(--border-opacity)) !important;
}

.bg-indigo-600 {
  background-color: rgba(88, 80, 236, var(--bg-opacity)) !important;
}

.text-indigo-600 {
  border-color: rgba(88, 80, 236, var(--border-opacity));
  color: rgba(88, 80, 236, var(--color-opacity)) !important;
}

.border-indigo-600 {
  border-color: rgba(88, 80, 236, var(--border-opacity)) !important;
}

.bg-indigo-700 {
  background-color: rgba(81, 69, 205, var(--bg-opacity)) !important;
}

.text-indigo-700 {
  border-color: rgba(81, 69, 205, var(--border-opacity));
  color: rgba(81, 69, 205, var(--color-opacity)) !important;
}

.border-indigo-700 {
  border-color: rgba(81, 69, 205, var(--border-opacity)) !important;
}

.bg-indigo-800 {
  background-color: rgba(66, 56, 157, var(--bg-opacity)) !important;
}

.text-indigo-800 {
  border-color: rgba(66, 56, 157, var(--border-opacity));
  color: rgba(66, 56, 157, var(--color-opacity)) !important;
}

.border-indigo-800 {
  border-color: rgba(66, 56, 157, var(--border-opacity)) !important;
}

.bg-indigo-900 {
  background-color: rgba(54, 47, 120, var(--bg-opacity)) !important;
}

.text-indigo-900 {
  border-color: rgba(54, 47, 120, var(--border-opacity));
  color: rgba(54, 47, 120, var(--color-opacity)) !important;
}

.border-indigo-900 {
  border-color: rgba(54, 47, 120, var(--border-opacity)) !important;
}

.bg-purple-100 {
  background-color: rgba(237, 235, 254, var(--bg-opacity)) !important;
}

.text-purple-100 {
  border-color: rgba(237, 235, 254, var(--border-opacity));
  color: rgba(237, 235, 254, var(--color-opacity)) !important;
}

.border-purple-100 {
  border-color: rgba(237, 235, 254, var(--border-opacity)) !important;
}

.bg-purple-200 {
  background-color: rgba(220, 215, 254, var(--bg-opacity)) !important;
}

.text-purple-200 {
  border-color: rgba(220, 215, 254, var(--border-opacity));
  color: rgba(220, 215, 254, var(--color-opacity)) !important;
}

.border-purple-200 {
  border-color: rgba(220, 215, 254, var(--border-opacity)) !important;
}

.bg-purple-300 {
  background-color: rgba(202, 191, 253, var(--bg-opacity)) !important;
}

.text-purple-300 {
  border-color: rgba(202, 191, 253, var(--border-opacity));
  color: rgba(202, 191, 253, var(--color-opacity)) !important;
}

.border-purple-300 {
  border-color: rgba(202, 191, 253, var(--border-opacity)) !important;
}

.bg-purple-400 {
  background-color: rgba(172, 148, 250, var(--bg-opacity)) !important;
}

.text-purple-400 {
  border-color: rgba(172, 148, 250, var(--border-opacity));
  color: rgba(172, 148, 250, var(--color-opacity)) !important;
}

.border-purple-400 {
  border-color: rgba(172, 148, 250, var(--border-opacity)) !important;
}

.bg-purple-500 {
  background-color: rgba(144, 97, 249, var(--bg-opacity)) !important;
}

.text-purple-500 {
  border-color: rgba(144, 97, 249, var(--border-opacity));
  color: rgba(144, 97, 249, var(--color-opacity)) !important;
}

.border-purple-500 {
  border-color: rgba(144, 97, 249, var(--border-opacity)) !important;
}

.bg-purple-600 {
  background-color: rgba(126, 58, 242, var(--bg-opacity)) !important;
}

.text-purple-600 {
  border-color: rgba(126, 58, 242, var(--border-opacity));
  color: rgba(126, 58, 242, var(--color-opacity)) !important;
}

.border-purple-600 {
  border-color: rgba(126, 58, 242, var(--border-opacity)) !important;
}

.bg-purple-700 {
  background-color: rgba(108, 43, 217, var(--bg-opacity)) !important;
}

.text-purple-700 {
  border-color: rgba(108, 43, 217, var(--border-opacity));
  color: rgba(108, 43, 217, var(--color-opacity)) !important;
}

.border-purple-700 {
  border-color: rgba(108, 43, 217, var(--border-opacity)) !important;
}

.bg-purple-800 {
  background-color: rgba(85, 33, 181, var(--bg-opacity)) !important;
}

.text-purple-800 {
  border-color: rgba(85, 33, 181, var(--border-opacity));
  color: rgba(85, 33, 181, var(--color-opacity)) !important;
}

.border-purple-800 {
  border-color: rgba(85, 33, 181, var(--border-opacity)) !important;
}

.bg-purple-900 {
  background-color: rgba(74, 29, 150, var(--bg-opacity)) !important;
}

.text-purple-900 {
  border-color: rgba(74, 29, 150, var(--border-opacity));
  color: rgba(74, 29, 150, var(--color-opacity)) !important;
}

.border-purple-900 {
  border-color: rgba(74, 29, 150, var(--border-opacity)) !important;
}

.bg-gray-000 {
  background-color: rgba(248, 249, 250, var(--bg-opacity)) !important;
}

.text-gray-000 {
  border-color: rgba(248, 249, 250, var(--border-opacity));
  color: rgba(248, 249, 250, var(--color-opacity)) !important;
}

.border-gray-000 {
  border-color: rgba(248, 249, 250, var(--border-opacity)) !important;
}

.bg-gray-100 {
  background-color: rgba(241, 243, 245, var(--bg-opacity)) !important;
}

.text-gray-100 {
  border-color: rgba(241, 243, 245, var(--border-opacity));
  color: rgba(241, 243, 245, var(--color-opacity)) !important;
}

.border-gray-100 {
  border-color: rgba(241, 243, 245, var(--border-opacity)) !important;
}

.bg-gray-200 {
  background-color: rgba(233, 236, 239, var(--bg-opacity)) !important;
}

.text-gray-200 {
  border-color: rgba(233, 236, 239, var(--border-opacity));
  color: rgba(233, 236, 239, var(--color-opacity)) !important;
}

.border-gray-200 {
  border-color: rgba(233, 236, 239, var(--border-opacity)) !important;
}

.bg-gray-300 {
  background-color: rgba(222, 226, 230, var(--bg-opacity)) !important;
}

.text-gray-300 {
  border-color: rgba(222, 226, 230, var(--border-opacity));
  color: rgba(222, 226, 230, var(--color-opacity)) !important;
}

.border-gray-300 {
  border-color: rgba(222, 226, 230, var(--border-opacity)) !important;
}

.bg-gray-400 {
  background-color: rgba(206, 212, 218, var(--bg-opacity)) !important;
}

.text-gray-400 {
  border-color: rgba(206, 212, 218, var(--border-opacity));
  color: rgba(206, 212, 218, var(--color-opacity)) !important;
}

.border-gray-400 {
  border-color: rgba(206, 212, 218, var(--border-opacity)) !important;
}

.bg-gray-500 {
  background-color: rgba(173, 181, 189, var(--bg-opacity)) !important;
}

.text-gray-500 {
  border-color: rgba(173, 181, 189, var(--border-opacity));
  color: rgba(173, 181, 189, var(--color-opacity)) !important;
}

.border-gray-500 {
  border-color: rgba(173, 181, 189, var(--border-opacity)) !important;
}

.bg-gray-600 {
  background-color: rgba(134, 142, 150, var(--bg-opacity)) !important;
}

.text-gray-600 {
  border-color: rgba(134, 142, 150, var(--border-opacity));
  color: rgba(134, 142, 150, var(--color-opacity)) !important;
}

.border-gray-600 {
  border-color: rgba(134, 142, 150, var(--border-opacity)) !important;
}

.bg-gray-700 {
  background-color: rgba(73, 80, 87, var(--bg-opacity)) !important;
}

.text-gray-700 {
  border-color: rgba(73, 80, 87, var(--border-opacity));
  color: rgba(73, 80, 87, var(--color-opacity)) !important;
}

.border-gray-700 {
  border-color: rgba(73, 80, 87, var(--border-opacity)) !important;
}

.bg-gray-800 {
  background-color: rgba(52, 58, 64, var(--bg-opacity)) !important;
}

.text-gray-800 {
  border-color: rgba(52, 58, 64, var(--border-opacity));
  color: rgba(52, 58, 64, var(--color-opacity)) !important;
}

.border-gray-800 {
  border-color: rgba(52, 58, 64, var(--border-opacity)) !important;
}

.bg-gray-900 {
  background-color: rgba(33, 37, 41, var(--bg-opacity)) !important;
}

.text-gray-900 {
  border-color: rgba(33, 37, 41, var(--border-opacity));
  color: rgba(33, 37, 41, var(--color-opacity)) !important;
}

.border-gray-900 {
  border-color: rgba(33, 37, 41, var(--border-opacity)) !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0, .my-0 {
  margin-top: 0 !important;
}

.mb-0, .my-0 {
  margin-bottom: 0 !important;
}

.ml-0, .mx-0 {
  margin-left: 0 !important;
}

.mr-0, .mx-0 {
  margin-right: 0 !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0, .py-0 {
  padding-top: 0 !important;
}

.pb-0, .py-0 {
  padding-bottom: 0 !important;
}

.pl-0, .px-0 {
  padding-left: 0 !important;
}

.pr-0, .px-0 {
  padding-right: 0 !important;
}

.m-1 {
  margin: .5rem !important;
}

.mt-1, .my-1 {
  margin-top: .5rem !important;
}

.mb-1, .my-1 {
  margin-bottom: .5rem !important;
}

.ml-1, .mx-1 {
  margin-left: .5rem !important;
}

.mr-1, .mx-1 {
  margin-right: .5rem !important;
}

.p-1 {
  padding: .5rem !important;
}

.pt-1, .py-1 {
  padding-top: .5rem !important;
}

.pb-1, .py-1 {
  padding-bottom: .5rem !important;
}

.pl-1, .px-1 {
  padding-left: .5rem !important;
}

.pr-1, .px-1 {
  padding-right: .5rem !important;
}

.m-2 {
  margin: 1rem !important;
}

.mt-2, .my-2 {
  margin-top: 1rem !important;
}

.mb-2, .my-2 {
  margin-bottom: 1rem !important;
}

.ml-2, .mx-2 {
  margin-left: 1rem !important;
}

.mr-2, .mx-2 {
  margin-right: 1rem !important;
}

.p-2 {
  padding: 1rem !important;
}

.pt-2, .py-2 {
  padding-top: 1rem !important;
}

.pb-2, .py-2 {
  padding-bottom: 1rem !important;
}

.pl-2, .px-2 {
  padding-left: 1rem !important;
}

.pr-2, .px-2 {
  padding-right: 1rem !important;
}

.m-3 {
  margin: 1.5rem !important;
}

.mt-3, .my-3 {
  margin-top: 1.5rem !important;
}

.mb-3, .my-3 {
  margin-bottom: 1.5rem !important;
}

.ml-3, .mx-3 {
  margin-left: 1.5rem !important;
}

.mr-3, .mx-3 {
  margin-right: 1.5rem !important;
}

.p-3 {
  padding: 1.5rem !important;
}

.pt-3, .py-3 {
  padding-top: 1.5rem !important;
}

.pb-3, .py-3 {
  padding-bottom: 1.5rem !important;
}

.pl-3, .px-3 {
  padding-left: 1.5rem !important;
}

.pr-3, .px-3 {
  padding-right: 1.5rem !important;
}

.m-4 {
  margin: 2rem !important;
}

.mt-4, .my-4 {
  margin-top: 2rem !important;
}

.mb-4, .my-4 {
  margin-bottom: 2rem !important;
}

.ml-4, .mx-4 {
  margin-left: 2rem !important;
}

.mr-4, .mx-4 {
  margin-right: 2rem !important;
}

.p-4 {
  padding: 2rem !important;
}

.pt-4, .py-4 {
  padding-top: 2rem !important;
}

.pb-4, .py-4 {
  padding-bottom: 2rem !important;
}

.pl-4, .px-4 {
  padding-left: 2rem !important;
}

.pr-4, .px-4 {
  padding-right: 2rem !important;
}

.m-5 {
  margin: 2.5rem !important;
}

.mt-5, .my-5 {
  margin-top: 2.5rem !important;
}

.mb-5, .my-5 {
  margin-bottom: 2.5rem !important;
}

.ml-5, .mx-5 {
  margin-left: 2.5rem !important;
}

.mr-5, .mx-5 {
  margin-right: 2.5rem !important;
}

.p-5 {
  padding: 2.5rem !important;
}

.pt-5, .py-5 {
  padding-top: 2.5rem !important;
}

.pb-5, .py-5 {
  padding-bottom: 2.5rem !important;
}

.pl-5, .px-5 {
  padding-left: 2.5rem !important;
}

.pr-5, .px-5 {
  padding-right: 2.5rem !important;
}

.m-6 {
  margin: 3rem !important;
}

.mt-6, .my-6 {
  margin-top: 3rem !important;
}

.mb-6, .my-6 {
  margin-bottom: 3rem !important;
}

.ml-6, .mx-6 {
  margin-left: 3rem !important;
}

.mr-6, .mx-6 {
  margin-right: 3rem !important;
}

.p-6 {
  padding: 3rem !important;
}

.pt-6, .py-6 {
  padding-top: 3rem !important;
}

.pb-6, .py-6 {
  padding-bottom: 3rem !important;
}

.pl-6, .px-6 {
  padding-left: 3rem !important;
}

.pr-6, .px-6 {
  padding-right: 3rem !important;
}

.m-8 {
  margin: 4rem !important;
}

.mt-8, .my-8 {
  margin-top: 4rem !important;
}

.mb-8, .my-8 {
  margin-bottom: 4rem !important;
}

.ml-8, .mx-8 {
  margin-left: 4rem !important;
}

.mr-8, .mx-8 {
  margin-right: 4rem !important;
}

.p-8 {
  padding: 4rem !important;
}

.pt-8, .py-8 {
  padding-top: 4rem !important;
}

.pb-8, .py-8 {
  padding-bottom: 4rem !important;
}

.pl-8, .px-8 {
  padding-left: 4rem !important;
}

.pr-8, .px-8 {
  padding-right: 4rem !important;
}

.m-10 {
  margin: 5rem !important;
}

.mt-10, .my-10 {
  margin-top: 5rem !important;
}

.mb-10, .my-10 {
  margin-bottom: 5rem !important;
}

.ml-10, .mx-10 {
  margin-left: 5rem !important;
}

.mr-10, .mx-10 {
  margin-right: 5rem !important;
}

.p-10 {
  padding: 5rem !important;
}

.pt-10, .py-10 {
  padding-top: 5rem !important;
}

.pb-10, .py-10 {
  padding-bottom: 5rem !important;
}

.pl-10, .px-10 {
  padding-left: 5rem !important;
}

.pr-10, .px-10 {
  padding-right: 5rem !important;
}

.m-12 {
  margin: 6rem !important;
}

.mt-12, .my-12 {
  margin-top: 6rem !important;
}

.mb-12, .my-12 {
  margin-bottom: 6rem !important;
}

.ml-12, .mx-12 {
  margin-left: 6rem !important;
}

.mr-12, .mx-12 {
  margin-right: 6rem !important;
}

.p-12 {
  padding: 6rem !important;
}

.pt-12, .py-12 {
  padding-top: 6rem !important;
}

.pb-12, .py-12 {
  padding-bottom: 6rem !important;
}

.pl-12, .px-12 {
  padding-left: 6rem !important;
}

.pr-12, .px-12 {
  padding-right: 6rem !important;
}

.m-16 {
  margin: 8rem !important;
}

.mt-16, .my-16 {
  margin-top: 8rem !important;
}

.mb-16, .my-16 {
  margin-bottom: 8rem !important;
}

.ml-16, .mx-16 {
  margin-left: 8rem !important;
}

.mr-16, .mx-16 {
  margin-right: 8rem !important;
}

.p-16 {
  padding: 8rem !important;
}

.pt-16, .py-16 {
  padding-top: 8rem !important;
}

.pb-16, .py-16 {
  padding-bottom: 8rem !important;
}

.pl-16, .px-16 {
  padding-left: 8rem !important;
}

.pr-16, .px-16 {
  padding-right: 8rem !important;
}

.m-20 {
  margin: 10rem !important;
}

.mt-20, .my-20 {
  margin-top: 10rem !important;
}

.mb-20, .my-20 {
  margin-bottom: 10rem !important;
}

.ml-20, .mx-20 {
  margin-left: 10rem !important;
}

.mr-20, .mx-20 {
  margin-right: 10rem !important;
}

.p-20 {
  padding: 10rem !important;
}

.pt-20, .py-20 {
  padding-top: 10rem !important;
}

.pb-20, .py-20 {
  padding-bottom: 10rem !important;
}

.pl-20, .px-20 {
  padding-left: 10rem !important;
}

.pr-20, .px-20 {
  padding-right: 10rem !important;
}

.m-24 {
  margin: 12rem !important;
}

.mt-24, .my-24 {
  margin-top: 12rem !important;
}

.mb-24, .my-24 {
  margin-bottom: 12rem !important;
}

.ml-24, .mx-24 {
  margin-left: 12rem !important;
}

.mr-24, .mx-24 {
  margin-right: 12rem !important;
}

.p-24 {
  padding: 12rem !important;
}

.pt-24, .py-24 {
  padding-top: 12rem !important;
}

.pb-24, .py-24 {
  padding-bottom: 12rem !important;
}

.pl-24, .px-24 {
  padding-left: 12rem !important;
}

.pr-24, .px-24 {
  padding-right: 12rem !important;
}

.m-32 {
  margin: 16rem !important;
}

.mt-32, .my-32 {
  margin-top: 16rem !important;
}

.mb-32, .my-32 {
  margin-bottom: 16rem !important;
}

.ml-32, .mx-32 {
  margin-left: 16rem !important;
}

.mr-32, .mx-32 {
  margin-right: 16rem !important;
}

.p-32 {
  padding: 16rem !important;
}

.pt-32, .py-32 {
  padding-top: 16rem !important;
}

.pb-32, .py-32 {
  padding-bottom: 16rem !important;
}

.pl-32, .px-32 {
  padding-left: 16rem !important;
}

.pr-32, .px-32 {
  padding-right: 16rem !important;
}

.ml-auto, .mx-auto {
  margin-left: auto !important;
}

.mr-auto, .mx-auto {
  margin-right: auto !important;
}

.mt-auto, .my-auto {
  margin-top: auto !important;
}

.mb-auto, .my-auto {
  margin-bottom: auto !important;
}

@media screen and (min-width: 640px) {
  .m-0-sm {
    margin: 0 !important;
  }

  .mt-0-sm, .my-0-sm {
    margin-top: 0 !important;
  }

  .mb-0-sm, .my-0-sm {
    margin-bottom: 0 !important;
  }

  .ml-0-sm, .mx-0-sm {
    margin-left: 0 !important;
  }

  .mr-0-sm, .mx-0-sm {
    margin-right: 0 !important;
  }

  .p-0-sm {
    padding: 0 !important;
  }

  .pt-0-sm, .py-0-sm {
    padding-top: 0 !important;
  }

  .pb-0-sm, .py-0-sm {
    padding-bottom: 0 !important;
  }

  .pl-0-sm, .px-0-sm {
    padding-left: 0 !important;
  }

  .pr-0-sm, .px-0-sm {
    padding-right: 0 !important;
  }

  .ml-auto-sm, .mx-auto-sm {
    margin-left: auto !important;
  }

  .mr-auto-sm, .mx-auto-sm {
    margin-right: auto !important;
  }

  .mt-auto-sm, .my-auto-sm {
    margin-top: auto !important;
  }

  .mb-auto-sm, .my-auto-sm {
    margin-bottom: auto !important;
  }

  .m-1-sm {
    margin: .5rem !important;
  }

  .mt-1-sm, .my-1-sm {
    margin-top: .5rem !important;
  }

  .mb-1-sm, .my-1-sm {
    margin-bottom: .5rem !important;
  }

  .ml-1-sm, .mx-1-sm {
    margin-left: .5rem !important;
  }

  .mr-1-sm, .mx-1-sm {
    margin-right: .5rem !important;
  }

  .p-1-sm {
    padding: .5rem !important;
  }

  .pt-1-sm, .py-1-sm {
    padding-top: .5rem !important;
  }

  .pb-1-sm, .py-1-sm {
    padding-bottom: .5rem !important;
  }

  .pl-1-sm, .px-1-sm {
    padding-left: .5rem !important;
  }

  .pr-1-sm, .px-1-sm {
    padding-right: .5rem !important;
  }

  .ml-auto-sm, .mx-auto-sm {
    margin-left: auto !important;
  }

  .mr-auto-sm, .mx-auto-sm {
    margin-right: auto !important;
  }

  .mt-auto-sm, .my-auto-sm {
    margin-top: auto !important;
  }

  .mb-auto-sm, .my-auto-sm {
    margin-bottom: auto !important;
  }

  .m-2-sm {
    margin: 1rem !important;
  }

  .mt-2-sm, .my-2-sm {
    margin-top: 1rem !important;
  }

  .mb-2-sm, .my-2-sm {
    margin-bottom: 1rem !important;
  }

  .ml-2-sm, .mx-2-sm {
    margin-left: 1rem !important;
  }

  .mr-2-sm, .mx-2-sm {
    margin-right: 1rem !important;
  }

  .p-2-sm {
    padding: 1rem !important;
  }

  .pt-2-sm, .py-2-sm {
    padding-top: 1rem !important;
  }

  .pb-2-sm, .py-2-sm {
    padding-bottom: 1rem !important;
  }

  .pl-2-sm, .px-2-sm {
    padding-left: 1rem !important;
  }

  .pr-2-sm, .px-2-sm {
    padding-right: 1rem !important;
  }

  .ml-auto-sm, .mx-auto-sm {
    margin-left: auto !important;
  }

  .mr-auto-sm, .mx-auto-sm {
    margin-right: auto !important;
  }

  .mt-auto-sm, .my-auto-sm {
    margin-top: auto !important;
  }

  .mb-auto-sm, .my-auto-sm {
    margin-bottom: auto !important;
  }

  .m-3-sm {
    margin: 1.5rem !important;
  }

  .mt-3-sm, .my-3-sm {
    margin-top: 1.5rem !important;
  }

  .mb-3-sm, .my-3-sm {
    margin-bottom: 1.5rem !important;
  }

  .ml-3-sm, .mx-3-sm {
    margin-left: 1.5rem !important;
  }

  .mr-3-sm, .mx-3-sm {
    margin-right: 1.5rem !important;
  }

  .p-3-sm {
    padding: 1.5rem !important;
  }

  .pt-3-sm, .py-3-sm {
    padding-top: 1.5rem !important;
  }

  .pb-3-sm, .py-3-sm {
    padding-bottom: 1.5rem !important;
  }

  .pl-3-sm, .px-3-sm {
    padding-left: 1.5rem !important;
  }

  .pr-3-sm, .px-3-sm {
    padding-right: 1.5rem !important;
  }

  .ml-auto-sm, .mx-auto-sm {
    margin-left: auto !important;
  }

  .mr-auto-sm, .mx-auto-sm {
    margin-right: auto !important;
  }

  .mt-auto-sm, .my-auto-sm {
    margin-top: auto !important;
  }

  .mb-auto-sm, .my-auto-sm {
    margin-bottom: auto !important;
  }

  .m-4-sm {
    margin: 2rem !important;
  }

  .mt-4-sm, .my-4-sm {
    margin-top: 2rem !important;
  }

  .mb-4-sm, .my-4-sm {
    margin-bottom: 2rem !important;
  }

  .ml-4-sm, .mx-4-sm {
    margin-left: 2rem !important;
  }

  .mr-4-sm, .mx-4-sm {
    margin-right: 2rem !important;
  }

  .p-4-sm {
    padding: 2rem !important;
  }

  .pt-4-sm, .py-4-sm {
    padding-top: 2rem !important;
  }

  .pb-4-sm, .py-4-sm {
    padding-bottom: 2rem !important;
  }

  .pl-4-sm, .px-4-sm {
    padding-left: 2rem !important;
  }

  .pr-4-sm, .px-4-sm {
    padding-right: 2rem !important;
  }

  .ml-auto-sm, .mx-auto-sm {
    margin-left: auto !important;
  }

  .mr-auto-sm, .mx-auto-sm {
    margin-right: auto !important;
  }

  .mt-auto-sm, .my-auto-sm {
    margin-top: auto !important;
  }

  .mb-auto-sm, .my-auto-sm {
    margin-bottom: auto !important;
  }

  .m-5-sm {
    margin: 2.5rem !important;
  }

  .mt-5-sm, .my-5-sm {
    margin-top: 2.5rem !important;
  }

  .mb-5-sm, .my-5-sm {
    margin-bottom: 2.5rem !important;
  }

  .ml-5-sm, .mx-5-sm {
    margin-left: 2.5rem !important;
  }

  .mr-5-sm, .mx-5-sm {
    margin-right: 2.5rem !important;
  }

  .p-5-sm {
    padding: 2.5rem !important;
  }

  .pt-5-sm, .py-5-sm {
    padding-top: 2.5rem !important;
  }

  .pb-5-sm, .py-5-sm {
    padding-bottom: 2.5rem !important;
  }

  .pl-5-sm, .px-5-sm {
    padding-left: 2.5rem !important;
  }

  .pr-5-sm, .px-5-sm {
    padding-right: 2.5rem !important;
  }

  .ml-auto-sm, .mx-auto-sm {
    margin-left: auto !important;
  }

  .mr-auto-sm, .mx-auto-sm {
    margin-right: auto !important;
  }

  .mt-auto-sm, .my-auto-sm {
    margin-top: auto !important;
  }

  .mb-auto-sm, .my-auto-sm {
    margin-bottom: auto !important;
  }

  .m-6-sm {
    margin: 3rem !important;
  }

  .mt-6-sm, .my-6-sm {
    margin-top: 3rem !important;
  }

  .mb-6-sm, .my-6-sm {
    margin-bottom: 3rem !important;
  }

  .ml-6-sm, .mx-6-sm {
    margin-left: 3rem !important;
  }

  .mr-6-sm, .mx-6-sm {
    margin-right: 3rem !important;
  }

  .p-6-sm {
    padding: 3rem !important;
  }

  .pt-6-sm, .py-6-sm {
    padding-top: 3rem !important;
  }

  .pb-6-sm, .py-6-sm {
    padding-bottom: 3rem !important;
  }

  .pl-6-sm, .px-6-sm {
    padding-left: 3rem !important;
  }

  .pr-6-sm, .px-6-sm {
    padding-right: 3rem !important;
  }

  .ml-auto-sm, .mx-auto-sm {
    margin-left: auto !important;
  }

  .mr-auto-sm, .mx-auto-sm {
    margin-right: auto !important;
  }

  .mt-auto-sm, .my-auto-sm {
    margin-top: auto !important;
  }

  .mb-auto-sm, .my-auto-sm {
    margin-bottom: auto !important;
  }

  .m-8-sm {
    margin: 4rem !important;
  }

  .mt-8-sm, .my-8-sm {
    margin-top: 4rem !important;
  }

  .mb-8-sm, .my-8-sm {
    margin-bottom: 4rem !important;
  }

  .ml-8-sm, .mx-8-sm {
    margin-left: 4rem !important;
  }

  .mr-8-sm, .mx-8-sm {
    margin-right: 4rem !important;
  }

  .p-8-sm {
    padding: 4rem !important;
  }

  .pt-8-sm, .py-8-sm {
    padding-top: 4rem !important;
  }

  .pb-8-sm, .py-8-sm {
    padding-bottom: 4rem !important;
  }

  .pl-8-sm, .px-8-sm {
    padding-left: 4rem !important;
  }

  .pr-8-sm, .px-8-sm {
    padding-right: 4rem !important;
  }

  .ml-auto-sm, .mx-auto-sm {
    margin-left: auto !important;
  }

  .mr-auto-sm, .mx-auto-sm {
    margin-right: auto !important;
  }

  .mt-auto-sm, .my-auto-sm {
    margin-top: auto !important;
  }

  .mb-auto-sm, .my-auto-sm {
    margin-bottom: auto !important;
  }

  .m-10-sm {
    margin: 5rem !important;
  }

  .mt-10-sm, .my-10-sm {
    margin-top: 5rem !important;
  }

  .mb-10-sm, .my-10-sm {
    margin-bottom: 5rem !important;
  }

  .ml-10-sm, .mx-10-sm {
    margin-left: 5rem !important;
  }

  .mr-10-sm, .mx-10-sm {
    margin-right: 5rem !important;
  }

  .p-10-sm {
    padding: 5rem !important;
  }

  .pt-10-sm, .py-10-sm {
    padding-top: 5rem !important;
  }

  .pb-10-sm, .py-10-sm {
    padding-bottom: 5rem !important;
  }

  .pl-10-sm, .px-10-sm {
    padding-left: 5rem !important;
  }

  .pr-10-sm, .px-10-sm {
    padding-right: 5rem !important;
  }

  .ml-auto-sm, .mx-auto-sm {
    margin-left: auto !important;
  }

  .mr-auto-sm, .mx-auto-sm {
    margin-right: auto !important;
  }

  .mt-auto-sm, .my-auto-sm {
    margin-top: auto !important;
  }

  .mb-auto-sm, .my-auto-sm {
    margin-bottom: auto !important;
  }

  .m-12-sm {
    margin: 6rem !important;
  }

  .mt-12-sm, .my-12-sm {
    margin-top: 6rem !important;
  }

  .mb-12-sm, .my-12-sm {
    margin-bottom: 6rem !important;
  }

  .ml-12-sm, .mx-12-sm {
    margin-left: 6rem !important;
  }

  .mr-12-sm, .mx-12-sm {
    margin-right: 6rem !important;
  }

  .p-12-sm {
    padding: 6rem !important;
  }

  .pt-12-sm, .py-12-sm {
    padding-top: 6rem !important;
  }

  .pb-12-sm, .py-12-sm {
    padding-bottom: 6rem !important;
  }

  .pl-12-sm, .px-12-sm {
    padding-left: 6rem !important;
  }

  .pr-12-sm, .px-12-sm {
    padding-right: 6rem !important;
  }

  .ml-auto-sm, .mx-auto-sm {
    margin-left: auto !important;
  }

  .mr-auto-sm, .mx-auto-sm {
    margin-right: auto !important;
  }

  .mt-auto-sm, .my-auto-sm {
    margin-top: auto !important;
  }

  .mb-auto-sm, .my-auto-sm {
    margin-bottom: auto !important;
  }

  .m-16-sm {
    margin: 8rem !important;
  }

  .mt-16-sm, .my-16-sm {
    margin-top: 8rem !important;
  }

  .mb-16-sm, .my-16-sm {
    margin-bottom: 8rem !important;
  }

  .ml-16-sm, .mx-16-sm {
    margin-left: 8rem !important;
  }

  .mr-16-sm, .mx-16-sm {
    margin-right: 8rem !important;
  }

  .p-16-sm {
    padding: 8rem !important;
  }

  .pt-16-sm, .py-16-sm {
    padding-top: 8rem !important;
  }

  .pb-16-sm, .py-16-sm {
    padding-bottom: 8rem !important;
  }

  .pl-16-sm, .px-16-sm {
    padding-left: 8rem !important;
  }

  .pr-16-sm, .px-16-sm {
    padding-right: 8rem !important;
  }

  .ml-auto-sm, .mx-auto-sm {
    margin-left: auto !important;
  }

  .mr-auto-sm, .mx-auto-sm {
    margin-right: auto !important;
  }

  .mt-auto-sm, .my-auto-sm {
    margin-top: auto !important;
  }

  .mb-auto-sm, .my-auto-sm {
    margin-bottom: auto !important;
  }

  .m-20-sm {
    margin: 10rem !important;
  }

  .mt-20-sm, .my-20-sm {
    margin-top: 10rem !important;
  }

  .mb-20-sm, .my-20-sm {
    margin-bottom: 10rem !important;
  }

  .ml-20-sm, .mx-20-sm {
    margin-left: 10rem !important;
  }

  .mr-20-sm, .mx-20-sm {
    margin-right: 10rem !important;
  }

  .p-20-sm {
    padding: 10rem !important;
  }

  .pt-20-sm, .py-20-sm {
    padding-top: 10rem !important;
  }

  .pb-20-sm, .py-20-sm {
    padding-bottom: 10rem !important;
  }

  .pl-20-sm, .px-20-sm {
    padding-left: 10rem !important;
  }

  .pr-20-sm, .px-20-sm {
    padding-right: 10rem !important;
  }

  .ml-auto-sm, .mx-auto-sm {
    margin-left: auto !important;
  }

  .mr-auto-sm, .mx-auto-sm {
    margin-right: auto !important;
  }

  .mt-auto-sm, .my-auto-sm {
    margin-top: auto !important;
  }

  .mb-auto-sm, .my-auto-sm {
    margin-bottom: auto !important;
  }

  .m-24-sm {
    margin: 12rem !important;
  }

  .mt-24-sm, .my-24-sm {
    margin-top: 12rem !important;
  }

  .mb-24-sm, .my-24-sm {
    margin-bottom: 12rem !important;
  }

  .ml-24-sm, .mx-24-sm {
    margin-left: 12rem !important;
  }

  .mr-24-sm, .mx-24-sm {
    margin-right: 12rem !important;
  }

  .p-24-sm {
    padding: 12rem !important;
  }

  .pt-24-sm, .py-24-sm {
    padding-top: 12rem !important;
  }

  .pb-24-sm, .py-24-sm {
    padding-bottom: 12rem !important;
  }

  .pl-24-sm, .px-24-sm {
    padding-left: 12rem !important;
  }

  .pr-24-sm, .px-24-sm {
    padding-right: 12rem !important;
  }

  .ml-auto-sm, .mx-auto-sm {
    margin-left: auto !important;
  }

  .mr-auto-sm, .mx-auto-sm {
    margin-right: auto !important;
  }

  .mt-auto-sm, .my-auto-sm {
    margin-top: auto !important;
  }

  .mb-auto-sm, .my-auto-sm {
    margin-bottom: auto !important;
  }

  .m-32-sm {
    margin: 16rem !important;
  }

  .mt-32-sm, .my-32-sm {
    margin-top: 16rem !important;
  }

  .mb-32-sm, .my-32-sm {
    margin-bottom: 16rem !important;
  }

  .ml-32-sm, .mx-32-sm {
    margin-left: 16rem !important;
  }

  .mr-32-sm, .mx-32-sm {
    margin-right: 16rem !important;
  }

  .p-32-sm {
    padding: 16rem !important;
  }

  .pt-32-sm, .py-32-sm {
    padding-top: 16rem !important;
  }

  .pb-32-sm, .py-32-sm {
    padding-bottom: 16rem !important;
  }

  .pl-32-sm, .px-32-sm {
    padding-left: 16rem !important;
  }

  .pr-32-sm, .px-32-sm {
    padding-right: 16rem !important;
  }

  .ml-auto-sm, .mx-auto-sm {
    margin-left: auto !important;
  }

  .mr-auto-sm, .mx-auto-sm {
    margin-right: auto !important;
  }

  .mt-auto-sm, .my-auto-sm {
    margin-top: auto !important;
  }

  .mb-auto-sm, .my-auto-sm {
    margin-bottom: auto !important;
  }
}

@media screen and (min-width: 768px) {
  .m-0-md {
    margin: 0 !important;
  }

  .mt-0-md, .my-0-md {
    margin-top: 0 !important;
  }

  .mb-0-md, .my-0-md {
    margin-bottom: 0 !important;
  }

  .ml-0-md, .mx-0-md {
    margin-left: 0 !important;
  }

  .mr-0-md, .mx-0-md {
    margin-right: 0 !important;
  }

  .p-0-md {
    padding: 0 !important;
  }

  .pt-0-md, .py-0-md {
    padding-top: 0 !important;
  }

  .pb-0-md, .py-0-md {
    padding-bottom: 0 !important;
  }

  .pl-0-md, .px-0-md {
    padding-left: 0 !important;
  }

  .pr-0-md, .px-0-md {
    padding-right: 0 !important;
  }

  .ml-auto-md, .mx-auto-md {
    margin-left: auto !important;
  }

  .mr-auto-md, .mx-auto-md {
    margin-right: auto !important;
  }

  .mt-auto-md, .my-auto-md {
    margin-top: auto !important;
  }

  .mb-auto-md, .my-auto-md {
    margin-bottom: auto !important;
  }

  .m-1-md {
    margin: .5rem !important;
  }

  .mt-1-md, .my-1-md {
    margin-top: .5rem !important;
  }

  .mb-1-md, .my-1-md {
    margin-bottom: .5rem !important;
  }

  .ml-1-md, .mx-1-md {
    margin-left: .5rem !important;
  }

  .mr-1-md, .mx-1-md {
    margin-right: .5rem !important;
  }

  .p-1-md {
    padding: .5rem !important;
  }

  .pt-1-md, .py-1-md {
    padding-top: .5rem !important;
  }

  .pb-1-md, .py-1-md {
    padding-bottom: .5rem !important;
  }

  .pl-1-md, .px-1-md {
    padding-left: .5rem !important;
  }

  .pr-1-md, .px-1-md {
    padding-right: .5rem !important;
  }

  .ml-auto-md, .mx-auto-md {
    margin-left: auto !important;
  }

  .mr-auto-md, .mx-auto-md {
    margin-right: auto !important;
  }

  .mt-auto-md, .my-auto-md {
    margin-top: auto !important;
  }

  .mb-auto-md, .my-auto-md {
    margin-bottom: auto !important;
  }

  .m-2-md {
    margin: 1rem !important;
  }

  .mt-2-md, .my-2-md {
    margin-top: 1rem !important;
  }

  .mb-2-md, .my-2-md {
    margin-bottom: 1rem !important;
  }

  .ml-2-md, .mx-2-md {
    margin-left: 1rem !important;
  }

  .mr-2-md, .mx-2-md {
    margin-right: 1rem !important;
  }

  .p-2-md {
    padding: 1rem !important;
  }

  .pt-2-md, .py-2-md {
    padding-top: 1rem !important;
  }

  .pb-2-md, .py-2-md {
    padding-bottom: 1rem !important;
  }

  .pl-2-md, .px-2-md {
    padding-left: 1rem !important;
  }

  .pr-2-md, .px-2-md {
    padding-right: 1rem !important;
  }

  .ml-auto-md, .mx-auto-md {
    margin-left: auto !important;
  }

  .mr-auto-md, .mx-auto-md {
    margin-right: auto !important;
  }

  .mt-auto-md, .my-auto-md {
    margin-top: auto !important;
  }

  .mb-auto-md, .my-auto-md {
    margin-bottom: auto !important;
  }

  .m-3-md {
    margin: 1.5rem !important;
  }

  .mt-3-md, .my-3-md {
    margin-top: 1.5rem !important;
  }

  .mb-3-md, .my-3-md {
    margin-bottom: 1.5rem !important;
  }

  .ml-3-md, .mx-3-md {
    margin-left: 1.5rem !important;
  }

  .mr-3-md, .mx-3-md {
    margin-right: 1.5rem !important;
  }

  .p-3-md {
    padding: 1.5rem !important;
  }

  .pt-3-md, .py-3-md {
    padding-top: 1.5rem !important;
  }

  .pb-3-md, .py-3-md {
    padding-bottom: 1.5rem !important;
  }

  .pl-3-md, .px-3-md {
    padding-left: 1.5rem !important;
  }

  .pr-3-md, .px-3-md {
    padding-right: 1.5rem !important;
  }

  .ml-auto-md, .mx-auto-md {
    margin-left: auto !important;
  }

  .mr-auto-md, .mx-auto-md {
    margin-right: auto !important;
  }

  .mt-auto-md, .my-auto-md {
    margin-top: auto !important;
  }

  .mb-auto-md, .my-auto-md {
    margin-bottom: auto !important;
  }

  .m-4-md {
    margin: 2rem !important;
  }

  .mt-4-md, .my-4-md {
    margin-top: 2rem !important;
  }

  .mb-4-md, .my-4-md {
    margin-bottom: 2rem !important;
  }

  .ml-4-md, .mx-4-md {
    margin-left: 2rem !important;
  }

  .mr-4-md, .mx-4-md {
    margin-right: 2rem !important;
  }

  .p-4-md {
    padding: 2rem !important;
  }

  .pt-4-md, .py-4-md {
    padding-top: 2rem !important;
  }

  .pb-4-md, .py-4-md {
    padding-bottom: 2rem !important;
  }

  .pl-4-md, .px-4-md {
    padding-left: 2rem !important;
  }

  .pr-4-md, .px-4-md {
    padding-right: 2rem !important;
  }

  .ml-auto-md, .mx-auto-md {
    margin-left: auto !important;
  }

  .mr-auto-md, .mx-auto-md {
    margin-right: auto !important;
  }

  .mt-auto-md, .my-auto-md {
    margin-top: auto !important;
  }

  .mb-auto-md, .my-auto-md {
    margin-bottom: auto !important;
  }

  .m-5-md {
    margin: 2.5rem !important;
  }

  .mt-5-md, .my-5-md {
    margin-top: 2.5rem !important;
  }

  .mb-5-md, .my-5-md {
    margin-bottom: 2.5rem !important;
  }

  .ml-5-md, .mx-5-md {
    margin-left: 2.5rem !important;
  }

  .mr-5-md, .mx-5-md {
    margin-right: 2.5rem !important;
  }

  .p-5-md {
    padding: 2.5rem !important;
  }

  .pt-5-md, .py-5-md {
    padding-top: 2.5rem !important;
  }

  .pb-5-md, .py-5-md {
    padding-bottom: 2.5rem !important;
  }

  .pl-5-md, .px-5-md {
    padding-left: 2.5rem !important;
  }

  .pr-5-md, .px-5-md {
    padding-right: 2.5rem !important;
  }

  .ml-auto-md, .mx-auto-md {
    margin-left: auto !important;
  }

  .mr-auto-md, .mx-auto-md {
    margin-right: auto !important;
  }

  .mt-auto-md, .my-auto-md {
    margin-top: auto !important;
  }

  .mb-auto-md, .my-auto-md {
    margin-bottom: auto !important;
  }

  .m-6-md {
    margin: 3rem !important;
  }

  .mt-6-md, .my-6-md {
    margin-top: 3rem !important;
  }

  .mb-6-md, .my-6-md {
    margin-bottom: 3rem !important;
  }

  .ml-6-md, .mx-6-md {
    margin-left: 3rem !important;
  }

  .mr-6-md, .mx-6-md {
    margin-right: 3rem !important;
  }

  .p-6-md {
    padding: 3rem !important;
  }

  .pt-6-md, .py-6-md {
    padding-top: 3rem !important;
  }

  .pb-6-md, .py-6-md {
    padding-bottom: 3rem !important;
  }

  .pl-6-md, .px-6-md {
    padding-left: 3rem !important;
  }

  .pr-6-md, .px-6-md {
    padding-right: 3rem !important;
  }

  .ml-auto-md, .mx-auto-md {
    margin-left: auto !important;
  }

  .mr-auto-md, .mx-auto-md {
    margin-right: auto !important;
  }

  .mt-auto-md, .my-auto-md {
    margin-top: auto !important;
  }

  .mb-auto-md, .my-auto-md {
    margin-bottom: auto !important;
  }

  .m-8-md {
    margin: 4rem !important;
  }

  .mt-8-md, .my-8-md {
    margin-top: 4rem !important;
  }

  .mb-8-md, .my-8-md {
    margin-bottom: 4rem !important;
  }

  .ml-8-md, .mx-8-md {
    margin-left: 4rem !important;
  }

  .mr-8-md, .mx-8-md {
    margin-right: 4rem !important;
  }

  .p-8-md {
    padding: 4rem !important;
  }

  .pt-8-md, .py-8-md {
    padding-top: 4rem !important;
  }

  .pb-8-md, .py-8-md {
    padding-bottom: 4rem !important;
  }

  .pl-8-md, .px-8-md {
    padding-left: 4rem !important;
  }

  .pr-8-md, .px-8-md {
    padding-right: 4rem !important;
  }

  .ml-auto-md, .mx-auto-md {
    margin-left: auto !important;
  }

  .mr-auto-md, .mx-auto-md {
    margin-right: auto !important;
  }

  .mt-auto-md, .my-auto-md {
    margin-top: auto !important;
  }

  .mb-auto-md, .my-auto-md {
    margin-bottom: auto !important;
  }

  .m-10-md {
    margin: 5rem !important;
  }

  .mt-10-md, .my-10-md {
    margin-top: 5rem !important;
  }

  .mb-10-md, .my-10-md {
    margin-bottom: 5rem !important;
  }

  .ml-10-md, .mx-10-md {
    margin-left: 5rem !important;
  }

  .mr-10-md, .mx-10-md {
    margin-right: 5rem !important;
  }

  .p-10-md {
    padding: 5rem !important;
  }

  .pt-10-md, .py-10-md {
    padding-top: 5rem !important;
  }

  .pb-10-md, .py-10-md {
    padding-bottom: 5rem !important;
  }

  .pl-10-md, .px-10-md {
    padding-left: 5rem !important;
  }

  .pr-10-md, .px-10-md {
    padding-right: 5rem !important;
  }

  .ml-auto-md, .mx-auto-md {
    margin-left: auto !important;
  }

  .mr-auto-md, .mx-auto-md {
    margin-right: auto !important;
  }

  .mt-auto-md, .my-auto-md {
    margin-top: auto !important;
  }

  .mb-auto-md, .my-auto-md {
    margin-bottom: auto !important;
  }

  .m-12-md {
    margin: 6rem !important;
  }

  .mt-12-md, .my-12-md {
    margin-top: 6rem !important;
  }

  .mb-12-md, .my-12-md {
    margin-bottom: 6rem !important;
  }

  .ml-12-md, .mx-12-md {
    margin-left: 6rem !important;
  }

  .mr-12-md, .mx-12-md {
    margin-right: 6rem !important;
  }

  .p-12-md {
    padding: 6rem !important;
  }

  .pt-12-md, .py-12-md {
    padding-top: 6rem !important;
  }

  .pb-12-md, .py-12-md {
    padding-bottom: 6rem !important;
  }

  .pl-12-md, .px-12-md {
    padding-left: 6rem !important;
  }

  .pr-12-md, .px-12-md {
    padding-right: 6rem !important;
  }

  .ml-auto-md, .mx-auto-md {
    margin-left: auto !important;
  }

  .mr-auto-md, .mx-auto-md {
    margin-right: auto !important;
  }

  .mt-auto-md, .my-auto-md {
    margin-top: auto !important;
  }

  .mb-auto-md, .my-auto-md {
    margin-bottom: auto !important;
  }

  .m-16-md {
    margin: 8rem !important;
  }

  .mt-16-md, .my-16-md {
    margin-top: 8rem !important;
  }

  .mb-16-md, .my-16-md {
    margin-bottom: 8rem !important;
  }

  .ml-16-md, .mx-16-md {
    margin-left: 8rem !important;
  }

  .mr-16-md, .mx-16-md {
    margin-right: 8rem !important;
  }

  .p-16-md {
    padding: 8rem !important;
  }

  .pt-16-md, .py-16-md {
    padding-top: 8rem !important;
  }

  .pb-16-md, .py-16-md {
    padding-bottom: 8rem !important;
  }

  .pl-16-md, .px-16-md {
    padding-left: 8rem !important;
  }

  .pr-16-md, .px-16-md {
    padding-right: 8rem !important;
  }

  .ml-auto-md, .mx-auto-md {
    margin-left: auto !important;
  }

  .mr-auto-md, .mx-auto-md {
    margin-right: auto !important;
  }

  .mt-auto-md, .my-auto-md {
    margin-top: auto !important;
  }

  .mb-auto-md, .my-auto-md {
    margin-bottom: auto !important;
  }

  .m-20-md {
    margin: 10rem !important;
  }

  .mt-20-md, .my-20-md {
    margin-top: 10rem !important;
  }

  .mb-20-md, .my-20-md {
    margin-bottom: 10rem !important;
  }

  .ml-20-md, .mx-20-md {
    margin-left: 10rem !important;
  }

  .mr-20-md, .mx-20-md {
    margin-right: 10rem !important;
  }

  .p-20-md {
    padding: 10rem !important;
  }

  .pt-20-md, .py-20-md {
    padding-top: 10rem !important;
  }

  .pb-20-md, .py-20-md {
    padding-bottom: 10rem !important;
  }

  .pl-20-md, .px-20-md {
    padding-left: 10rem !important;
  }

  .pr-20-md, .px-20-md {
    padding-right: 10rem !important;
  }

  .ml-auto-md, .mx-auto-md {
    margin-left: auto !important;
  }

  .mr-auto-md, .mx-auto-md {
    margin-right: auto !important;
  }

  .mt-auto-md, .my-auto-md {
    margin-top: auto !important;
  }

  .mb-auto-md, .my-auto-md {
    margin-bottom: auto !important;
  }

  .m-24-md {
    margin: 12rem !important;
  }

  .mt-24-md, .my-24-md {
    margin-top: 12rem !important;
  }

  .mb-24-md, .my-24-md {
    margin-bottom: 12rem !important;
  }

  .ml-24-md, .mx-24-md {
    margin-left: 12rem !important;
  }

  .mr-24-md, .mx-24-md {
    margin-right: 12rem !important;
  }

  .p-24-md {
    padding: 12rem !important;
  }

  .pt-24-md, .py-24-md {
    padding-top: 12rem !important;
  }

  .pb-24-md, .py-24-md {
    padding-bottom: 12rem !important;
  }

  .pl-24-md, .px-24-md {
    padding-left: 12rem !important;
  }

  .pr-24-md, .px-24-md {
    padding-right: 12rem !important;
  }

  .ml-auto-md, .mx-auto-md {
    margin-left: auto !important;
  }

  .mr-auto-md, .mx-auto-md {
    margin-right: auto !important;
  }

  .mt-auto-md, .my-auto-md {
    margin-top: auto !important;
  }

  .mb-auto-md, .my-auto-md {
    margin-bottom: auto !important;
  }

  .m-32-md {
    margin: 16rem !important;
  }

  .mt-32-md, .my-32-md {
    margin-top: 16rem !important;
  }

  .mb-32-md, .my-32-md {
    margin-bottom: 16rem !important;
  }

  .ml-32-md, .mx-32-md {
    margin-left: 16rem !important;
  }

  .mr-32-md, .mx-32-md {
    margin-right: 16rem !important;
  }

  .p-32-md {
    padding: 16rem !important;
  }

  .pt-32-md, .py-32-md {
    padding-top: 16rem !important;
  }

  .pb-32-md, .py-32-md {
    padding-bottom: 16rem !important;
  }

  .pl-32-md, .px-32-md {
    padding-left: 16rem !important;
  }

  .pr-32-md, .px-32-md {
    padding-right: 16rem !important;
  }

  .ml-auto-md, .mx-auto-md {
    margin-left: auto !important;
  }

  .mr-auto-md, .mx-auto-md {
    margin-right: auto !important;
  }

  .mt-auto-md, .my-auto-md {
    margin-top: auto !important;
  }

  .mb-auto-md, .my-auto-md {
    margin-bottom: auto !important;
  }
}

@media screen and (min-width: 1024px) {
  .m-0-lg {
    margin: 0 !important;
  }

  .mt-0-lg, .my-0-lg {
    margin-top: 0 !important;
  }

  .mb-0-lg, .my-0-lg {
    margin-bottom: 0 !important;
  }

  .ml-0-lg, .mx-0-lg {
    margin-left: 0 !important;
  }

  .mr-0-lg, .mx-0-lg {
    margin-right: 0 !important;
  }

  .p-0-lg {
    padding: 0 !important;
  }

  .pt-0-lg, .py-0-lg {
    padding-top: 0 !important;
  }

  .pb-0-lg, .py-0-lg {
    padding-bottom: 0 !important;
  }

  .pl-0-lg, .px-0-lg {
    padding-left: 0 !important;
  }

  .pr-0-lg, .px-0-lg {
    padding-right: 0 !important;
  }

  .ml-auto-lg, .mx-auto-lg {
    margin-left: auto !important;
  }

  .mr-auto-lg, .mx-auto-lg {
    margin-right: auto !important;
  }

  .mt-auto-lg, .my-auto-lg {
    margin-top: auto !important;
  }

  .mb-auto-lg, .my-auto-lg {
    margin-bottom: auto !important;
  }

  .m-1-lg {
    margin: .5rem !important;
  }

  .mt-1-lg, .my-1-lg {
    margin-top: .5rem !important;
  }

  .mb-1-lg, .my-1-lg {
    margin-bottom: .5rem !important;
  }

  .ml-1-lg, .mx-1-lg {
    margin-left: .5rem !important;
  }

  .mr-1-lg, .mx-1-lg {
    margin-right: .5rem !important;
  }

  .p-1-lg {
    padding: .5rem !important;
  }

  .pt-1-lg, .py-1-lg {
    padding-top: .5rem !important;
  }

  .pb-1-lg, .py-1-lg {
    padding-bottom: .5rem !important;
  }

  .pl-1-lg, .px-1-lg {
    padding-left: .5rem !important;
  }

  .pr-1-lg, .px-1-lg {
    padding-right: .5rem !important;
  }

  .ml-auto-lg, .mx-auto-lg {
    margin-left: auto !important;
  }

  .mr-auto-lg, .mx-auto-lg {
    margin-right: auto !important;
  }

  .mt-auto-lg, .my-auto-lg {
    margin-top: auto !important;
  }

  .mb-auto-lg, .my-auto-lg {
    margin-bottom: auto !important;
  }

  .m-2-lg {
    margin: 1rem !important;
  }

  .mt-2-lg, .my-2-lg {
    margin-top: 1rem !important;
  }

  .mb-2-lg, .my-2-lg {
    margin-bottom: 1rem !important;
  }

  .ml-2-lg, .mx-2-lg {
    margin-left: 1rem !important;
  }

  .mr-2-lg, .mx-2-lg {
    margin-right: 1rem !important;
  }

  .p-2-lg {
    padding: 1rem !important;
  }

  .pt-2-lg, .py-2-lg {
    padding-top: 1rem !important;
  }

  .pb-2-lg, .py-2-lg {
    padding-bottom: 1rem !important;
  }

  .pl-2-lg, .px-2-lg {
    padding-left: 1rem !important;
  }

  .pr-2-lg, .px-2-lg {
    padding-right: 1rem !important;
  }

  .ml-auto-lg, .mx-auto-lg {
    margin-left: auto !important;
  }

  .mr-auto-lg, .mx-auto-lg {
    margin-right: auto !important;
  }

  .mt-auto-lg, .my-auto-lg {
    margin-top: auto !important;
  }

  .mb-auto-lg, .my-auto-lg {
    margin-bottom: auto !important;
  }

  .m-3-lg {
    margin: 1.5rem !important;
  }

  .mt-3-lg, .my-3-lg {
    margin-top: 1.5rem !important;
  }

  .mb-3-lg, .my-3-lg {
    margin-bottom: 1.5rem !important;
  }

  .ml-3-lg, .mx-3-lg {
    margin-left: 1.5rem !important;
  }

  .mr-3-lg, .mx-3-lg {
    margin-right: 1.5rem !important;
  }

  .p-3-lg {
    padding: 1.5rem !important;
  }

  .pt-3-lg, .py-3-lg {
    padding-top: 1.5rem !important;
  }

  .pb-3-lg, .py-3-lg {
    padding-bottom: 1.5rem !important;
  }

  .pl-3-lg, .px-3-lg {
    padding-left: 1.5rem !important;
  }

  .pr-3-lg, .px-3-lg {
    padding-right: 1.5rem !important;
  }

  .ml-auto-lg, .mx-auto-lg {
    margin-left: auto !important;
  }

  .mr-auto-lg, .mx-auto-lg {
    margin-right: auto !important;
  }

  .mt-auto-lg, .my-auto-lg {
    margin-top: auto !important;
  }

  .mb-auto-lg, .my-auto-lg {
    margin-bottom: auto !important;
  }

  .m-4-lg {
    margin: 2rem !important;
  }

  .mt-4-lg, .my-4-lg {
    margin-top: 2rem !important;
  }

  .mb-4-lg, .my-4-lg {
    margin-bottom: 2rem !important;
  }

  .ml-4-lg, .mx-4-lg {
    margin-left: 2rem !important;
  }

  .mr-4-lg, .mx-4-lg {
    margin-right: 2rem !important;
  }

  .p-4-lg {
    padding: 2rem !important;
  }

  .pt-4-lg, .py-4-lg {
    padding-top: 2rem !important;
  }

  .pb-4-lg, .py-4-lg {
    padding-bottom: 2rem !important;
  }

  .pl-4-lg, .px-4-lg {
    padding-left: 2rem !important;
  }

  .pr-4-lg, .px-4-lg {
    padding-right: 2rem !important;
  }

  .ml-auto-lg, .mx-auto-lg {
    margin-left: auto !important;
  }

  .mr-auto-lg, .mx-auto-lg {
    margin-right: auto !important;
  }

  .mt-auto-lg, .my-auto-lg {
    margin-top: auto !important;
  }

  .mb-auto-lg, .my-auto-lg {
    margin-bottom: auto !important;
  }

  .m-5-lg {
    margin: 2.5rem !important;
  }

  .mt-5-lg, .my-5-lg {
    margin-top: 2.5rem !important;
  }

  .mb-5-lg, .my-5-lg {
    margin-bottom: 2.5rem !important;
  }

  .ml-5-lg, .mx-5-lg {
    margin-left: 2.5rem !important;
  }

  .mr-5-lg, .mx-5-lg {
    margin-right: 2.5rem !important;
  }

  .p-5-lg {
    padding: 2.5rem !important;
  }

  .pt-5-lg, .py-5-lg {
    padding-top: 2.5rem !important;
  }

  .pb-5-lg, .py-5-lg {
    padding-bottom: 2.5rem !important;
  }

  .pl-5-lg, .px-5-lg {
    padding-left: 2.5rem !important;
  }

  .pr-5-lg, .px-5-lg {
    padding-right: 2.5rem !important;
  }

  .ml-auto-lg, .mx-auto-lg {
    margin-left: auto !important;
  }

  .mr-auto-lg, .mx-auto-lg {
    margin-right: auto !important;
  }

  .mt-auto-lg, .my-auto-lg {
    margin-top: auto !important;
  }

  .mb-auto-lg, .my-auto-lg {
    margin-bottom: auto !important;
  }

  .m-6-lg {
    margin: 3rem !important;
  }

  .mt-6-lg, .my-6-lg {
    margin-top: 3rem !important;
  }

  .mb-6-lg, .my-6-lg {
    margin-bottom: 3rem !important;
  }

  .ml-6-lg, .mx-6-lg {
    margin-left: 3rem !important;
  }

  .mr-6-lg, .mx-6-lg {
    margin-right: 3rem !important;
  }

  .p-6-lg {
    padding: 3rem !important;
  }

  .pt-6-lg, .py-6-lg {
    padding-top: 3rem !important;
  }

  .pb-6-lg, .py-6-lg {
    padding-bottom: 3rem !important;
  }

  .pl-6-lg, .px-6-lg {
    padding-left: 3rem !important;
  }

  .pr-6-lg, .px-6-lg {
    padding-right: 3rem !important;
  }

  .ml-auto-lg, .mx-auto-lg {
    margin-left: auto !important;
  }

  .mr-auto-lg, .mx-auto-lg {
    margin-right: auto !important;
  }

  .mt-auto-lg, .my-auto-lg {
    margin-top: auto !important;
  }

  .mb-auto-lg, .my-auto-lg {
    margin-bottom: auto !important;
  }

  .m-8-lg {
    margin: 4rem !important;
  }

  .mt-8-lg, .my-8-lg {
    margin-top: 4rem !important;
  }

  .mb-8-lg, .my-8-lg {
    margin-bottom: 4rem !important;
  }

  .ml-8-lg, .mx-8-lg {
    margin-left: 4rem !important;
  }

  .mr-8-lg, .mx-8-lg {
    margin-right: 4rem !important;
  }

  .p-8-lg {
    padding: 4rem !important;
  }

  .pt-8-lg, .py-8-lg {
    padding-top: 4rem !important;
  }

  .pb-8-lg, .py-8-lg {
    padding-bottom: 4rem !important;
  }

  .pl-8-lg, .px-8-lg {
    padding-left: 4rem !important;
  }

  .pr-8-lg, .px-8-lg {
    padding-right: 4rem !important;
  }

  .ml-auto-lg, .mx-auto-lg {
    margin-left: auto !important;
  }

  .mr-auto-lg, .mx-auto-lg {
    margin-right: auto !important;
  }

  .mt-auto-lg, .my-auto-lg {
    margin-top: auto !important;
  }

  .mb-auto-lg, .my-auto-lg {
    margin-bottom: auto !important;
  }

  .m-10-lg {
    margin: 5rem !important;
  }

  .mt-10-lg, .my-10-lg {
    margin-top: 5rem !important;
  }

  .mb-10-lg, .my-10-lg {
    margin-bottom: 5rem !important;
  }

  .ml-10-lg, .mx-10-lg {
    margin-left: 5rem !important;
  }

  .mr-10-lg, .mx-10-lg {
    margin-right: 5rem !important;
  }

  .p-10-lg {
    padding: 5rem !important;
  }

  .pt-10-lg, .py-10-lg {
    padding-top: 5rem !important;
  }

  .pb-10-lg, .py-10-lg {
    padding-bottom: 5rem !important;
  }

  .pl-10-lg, .px-10-lg {
    padding-left: 5rem !important;
  }

  .pr-10-lg, .px-10-lg {
    padding-right: 5rem !important;
  }

  .ml-auto-lg, .mx-auto-lg {
    margin-left: auto !important;
  }

  .mr-auto-lg, .mx-auto-lg {
    margin-right: auto !important;
  }

  .mt-auto-lg, .my-auto-lg {
    margin-top: auto !important;
  }

  .mb-auto-lg, .my-auto-lg {
    margin-bottom: auto !important;
  }

  .m-12-lg {
    margin: 6rem !important;
  }

  .mt-12-lg, .my-12-lg {
    margin-top: 6rem !important;
  }

  .mb-12-lg, .my-12-lg {
    margin-bottom: 6rem !important;
  }

  .ml-12-lg, .mx-12-lg {
    margin-left: 6rem !important;
  }

  .mr-12-lg, .mx-12-lg {
    margin-right: 6rem !important;
  }

  .p-12-lg {
    padding: 6rem !important;
  }

  .pt-12-lg, .py-12-lg {
    padding-top: 6rem !important;
  }

  .pb-12-lg, .py-12-lg {
    padding-bottom: 6rem !important;
  }

  .pl-12-lg, .px-12-lg {
    padding-left: 6rem !important;
  }

  .pr-12-lg, .px-12-lg {
    padding-right: 6rem !important;
  }

  .ml-auto-lg, .mx-auto-lg {
    margin-left: auto !important;
  }

  .mr-auto-lg, .mx-auto-lg {
    margin-right: auto !important;
  }

  .mt-auto-lg, .my-auto-lg {
    margin-top: auto !important;
  }

  .mb-auto-lg, .my-auto-lg {
    margin-bottom: auto !important;
  }

  .m-16-lg {
    margin: 8rem !important;
  }

  .mt-16-lg, .my-16-lg {
    margin-top: 8rem !important;
  }

  .mb-16-lg, .my-16-lg {
    margin-bottom: 8rem !important;
  }

  .ml-16-lg, .mx-16-lg {
    margin-left: 8rem !important;
  }

  .mr-16-lg, .mx-16-lg {
    margin-right: 8rem !important;
  }

  .p-16-lg {
    padding: 8rem !important;
  }

  .pt-16-lg, .py-16-lg {
    padding-top: 8rem !important;
  }

  .pb-16-lg, .py-16-lg {
    padding-bottom: 8rem !important;
  }

  .pl-16-lg, .px-16-lg {
    padding-left: 8rem !important;
  }

  .pr-16-lg, .px-16-lg {
    padding-right: 8rem !important;
  }

  .ml-auto-lg, .mx-auto-lg {
    margin-left: auto !important;
  }

  .mr-auto-lg, .mx-auto-lg {
    margin-right: auto !important;
  }

  .mt-auto-lg, .my-auto-lg {
    margin-top: auto !important;
  }

  .mb-auto-lg, .my-auto-lg {
    margin-bottom: auto !important;
  }

  .m-20-lg {
    margin: 10rem !important;
  }

  .mt-20-lg, .my-20-lg {
    margin-top: 10rem !important;
  }

  .mb-20-lg, .my-20-lg {
    margin-bottom: 10rem !important;
  }

  .ml-20-lg, .mx-20-lg {
    margin-left: 10rem !important;
  }

  .mr-20-lg, .mx-20-lg {
    margin-right: 10rem !important;
  }

  .p-20-lg {
    padding: 10rem !important;
  }

  .pt-20-lg, .py-20-lg {
    padding-top: 10rem !important;
  }

  .pb-20-lg, .py-20-lg {
    padding-bottom: 10rem !important;
  }

  .pl-20-lg, .px-20-lg {
    padding-left: 10rem !important;
  }

  .pr-20-lg, .px-20-lg {
    padding-right: 10rem !important;
  }

  .ml-auto-lg, .mx-auto-lg {
    margin-left: auto !important;
  }

  .mr-auto-lg, .mx-auto-lg {
    margin-right: auto !important;
  }

  .mt-auto-lg, .my-auto-lg {
    margin-top: auto !important;
  }

  .mb-auto-lg, .my-auto-lg {
    margin-bottom: auto !important;
  }

  .m-24-lg {
    margin: 12rem !important;
  }

  .mt-24-lg, .my-24-lg {
    margin-top: 12rem !important;
  }

  .mb-24-lg, .my-24-lg {
    margin-bottom: 12rem !important;
  }

  .ml-24-lg, .mx-24-lg {
    margin-left: 12rem !important;
  }

  .mr-24-lg, .mx-24-lg {
    margin-right: 12rem !important;
  }

  .p-24-lg {
    padding: 12rem !important;
  }

  .pt-24-lg, .py-24-lg {
    padding-top: 12rem !important;
  }

  .pb-24-lg, .py-24-lg {
    padding-bottom: 12rem !important;
  }

  .pl-24-lg, .px-24-lg {
    padding-left: 12rem !important;
  }

  .pr-24-lg, .px-24-lg {
    padding-right: 12rem !important;
  }

  .ml-auto-lg, .mx-auto-lg {
    margin-left: auto !important;
  }

  .mr-auto-lg, .mx-auto-lg {
    margin-right: auto !important;
  }

  .mt-auto-lg, .my-auto-lg {
    margin-top: auto !important;
  }

  .mb-auto-lg, .my-auto-lg {
    margin-bottom: auto !important;
  }

  .m-32-lg {
    margin: 16rem !important;
  }

  .mt-32-lg, .my-32-lg {
    margin-top: 16rem !important;
  }

  .mb-32-lg, .my-32-lg {
    margin-bottom: 16rem !important;
  }

  .ml-32-lg, .mx-32-lg {
    margin-left: 16rem !important;
  }

  .mr-32-lg, .mx-32-lg {
    margin-right: 16rem !important;
  }

  .p-32-lg {
    padding: 16rem !important;
  }

  .pt-32-lg, .py-32-lg {
    padding-top: 16rem !important;
  }

  .pb-32-lg, .py-32-lg {
    padding-bottom: 16rem !important;
  }

  .pl-32-lg, .px-32-lg {
    padding-left: 16rem !important;
  }

  .pr-32-lg, .px-32-lg {
    padding-right: 16rem !important;
  }

  .ml-auto-lg, .mx-auto-lg {
    margin-left: auto !important;
  }

  .mr-auto-lg, .mx-auto-lg {
    margin-right: auto !important;
  }

  .mt-auto-lg, .my-auto-lg {
    margin-top: auto !important;
  }

  .mb-auto-lg, .my-auto-lg {
    margin-bottom: auto !important;
  }
}

@media screen and (min-width: 1280px) {
  .m-0-xl {
    margin: 0 !important;
  }

  .mt-0-xl, .my-0-xl {
    margin-top: 0 !important;
  }

  .mb-0-xl, .my-0-xl {
    margin-bottom: 0 !important;
  }

  .ml-0-xl, .mx-0-xl {
    margin-left: 0 !important;
  }

  .mr-0-xl, .mx-0-xl {
    margin-right: 0 !important;
  }

  .p-0-xl {
    padding: 0 !important;
  }

  .pt-0-xl, .py-0-xl {
    padding-top: 0 !important;
  }

  .pb-0-xl, .py-0-xl {
    padding-bottom: 0 !important;
  }

  .pl-0-xl, .px-0-xl {
    padding-left: 0 !important;
  }

  .pr-0-xl, .px-0-xl {
    padding-right: 0 !important;
  }

  .ml-auto-xl, .mx-auto-xl {
    margin-left: auto !important;
  }

  .mr-auto-xl, .mx-auto-xl {
    margin-right: auto !important;
  }

  .mt-auto-xl, .my-auto-xl {
    margin-top: auto !important;
  }

  .mb-auto-xl, .my-auto-xl {
    margin-bottom: auto !important;
  }

  .m-1-xl {
    margin: .5rem !important;
  }

  .mt-1-xl, .my-1-xl {
    margin-top: .5rem !important;
  }

  .mb-1-xl, .my-1-xl {
    margin-bottom: .5rem !important;
  }

  .ml-1-xl, .mx-1-xl {
    margin-left: .5rem !important;
  }

  .mr-1-xl, .mx-1-xl {
    margin-right: .5rem !important;
  }

  .p-1-xl {
    padding: .5rem !important;
  }

  .pt-1-xl, .py-1-xl {
    padding-top: .5rem !important;
  }

  .pb-1-xl, .py-1-xl {
    padding-bottom: .5rem !important;
  }

  .pl-1-xl, .px-1-xl {
    padding-left: .5rem !important;
  }

  .pr-1-xl, .px-1-xl {
    padding-right: .5rem !important;
  }

  .ml-auto-xl, .mx-auto-xl {
    margin-left: auto !important;
  }

  .mr-auto-xl, .mx-auto-xl {
    margin-right: auto !important;
  }

  .mt-auto-xl, .my-auto-xl {
    margin-top: auto !important;
  }

  .mb-auto-xl, .my-auto-xl {
    margin-bottom: auto !important;
  }

  .m-2-xl {
    margin: 1rem !important;
  }

  .mt-2-xl, .my-2-xl {
    margin-top: 1rem !important;
  }

  .mb-2-xl, .my-2-xl {
    margin-bottom: 1rem !important;
  }

  .ml-2-xl, .mx-2-xl {
    margin-left: 1rem !important;
  }

  .mr-2-xl, .mx-2-xl {
    margin-right: 1rem !important;
  }

  .p-2-xl {
    padding: 1rem !important;
  }

  .pt-2-xl, .py-2-xl {
    padding-top: 1rem !important;
  }

  .pb-2-xl, .py-2-xl {
    padding-bottom: 1rem !important;
  }

  .pl-2-xl, .px-2-xl {
    padding-left: 1rem !important;
  }

  .pr-2-xl, .px-2-xl {
    padding-right: 1rem !important;
  }

  .ml-auto-xl, .mx-auto-xl {
    margin-left: auto !important;
  }

  .mr-auto-xl, .mx-auto-xl {
    margin-right: auto !important;
  }

  .mt-auto-xl, .my-auto-xl {
    margin-top: auto !important;
  }

  .mb-auto-xl, .my-auto-xl {
    margin-bottom: auto !important;
  }

  .m-3-xl {
    margin: 1.5rem !important;
  }

  .mt-3-xl, .my-3-xl {
    margin-top: 1.5rem !important;
  }

  .mb-3-xl, .my-3-xl {
    margin-bottom: 1.5rem !important;
  }

  .ml-3-xl, .mx-3-xl {
    margin-left: 1.5rem !important;
  }

  .mr-3-xl, .mx-3-xl {
    margin-right: 1.5rem !important;
  }

  .p-3-xl {
    padding: 1.5rem !important;
  }

  .pt-3-xl, .py-3-xl {
    padding-top: 1.5rem !important;
  }

  .pb-3-xl, .py-3-xl {
    padding-bottom: 1.5rem !important;
  }

  .pl-3-xl, .px-3-xl {
    padding-left: 1.5rem !important;
  }

  .pr-3-xl, .px-3-xl {
    padding-right: 1.5rem !important;
  }

  .ml-auto-xl, .mx-auto-xl {
    margin-left: auto !important;
  }

  .mr-auto-xl, .mx-auto-xl {
    margin-right: auto !important;
  }

  .mt-auto-xl, .my-auto-xl {
    margin-top: auto !important;
  }

  .mb-auto-xl, .my-auto-xl {
    margin-bottom: auto !important;
  }

  .m-4-xl {
    margin: 2rem !important;
  }

  .mt-4-xl, .my-4-xl {
    margin-top: 2rem !important;
  }

  .mb-4-xl, .my-4-xl {
    margin-bottom: 2rem !important;
  }

  .ml-4-xl, .mx-4-xl {
    margin-left: 2rem !important;
  }

  .mr-4-xl, .mx-4-xl {
    margin-right: 2rem !important;
  }

  .p-4-xl {
    padding: 2rem !important;
  }

  .pt-4-xl, .py-4-xl {
    padding-top: 2rem !important;
  }

  .pb-4-xl, .py-4-xl {
    padding-bottom: 2rem !important;
  }

  .pl-4-xl, .px-4-xl {
    padding-left: 2rem !important;
  }

  .pr-4-xl, .px-4-xl {
    padding-right: 2rem !important;
  }

  .ml-auto-xl, .mx-auto-xl {
    margin-left: auto !important;
  }

  .mr-auto-xl, .mx-auto-xl {
    margin-right: auto !important;
  }

  .mt-auto-xl, .my-auto-xl {
    margin-top: auto !important;
  }

  .mb-auto-xl, .my-auto-xl {
    margin-bottom: auto !important;
  }

  .m-5-xl {
    margin: 2.5rem !important;
  }

  .mt-5-xl, .my-5-xl {
    margin-top: 2.5rem !important;
  }

  .mb-5-xl, .my-5-xl {
    margin-bottom: 2.5rem !important;
  }

  .ml-5-xl, .mx-5-xl {
    margin-left: 2.5rem !important;
  }

  .mr-5-xl, .mx-5-xl {
    margin-right: 2.5rem !important;
  }

  .p-5-xl {
    padding: 2.5rem !important;
  }

  .pt-5-xl, .py-5-xl {
    padding-top: 2.5rem !important;
  }

  .pb-5-xl, .py-5-xl {
    padding-bottom: 2.5rem !important;
  }

  .pl-5-xl, .px-5-xl {
    padding-left: 2.5rem !important;
  }

  .pr-5-xl, .px-5-xl {
    padding-right: 2.5rem !important;
  }

  .ml-auto-xl, .mx-auto-xl {
    margin-left: auto !important;
  }

  .mr-auto-xl, .mx-auto-xl {
    margin-right: auto !important;
  }

  .mt-auto-xl, .my-auto-xl {
    margin-top: auto !important;
  }

  .mb-auto-xl, .my-auto-xl {
    margin-bottom: auto !important;
  }

  .m-6-xl {
    margin: 3rem !important;
  }

  .mt-6-xl, .my-6-xl {
    margin-top: 3rem !important;
  }

  .mb-6-xl, .my-6-xl {
    margin-bottom: 3rem !important;
  }

  .ml-6-xl, .mx-6-xl {
    margin-left: 3rem !important;
  }

  .mr-6-xl, .mx-6-xl {
    margin-right: 3rem !important;
  }

  .p-6-xl {
    padding: 3rem !important;
  }

  .pt-6-xl, .py-6-xl {
    padding-top: 3rem !important;
  }

  .pb-6-xl, .py-6-xl {
    padding-bottom: 3rem !important;
  }

  .pl-6-xl, .px-6-xl {
    padding-left: 3rem !important;
  }

  .pr-6-xl, .px-6-xl {
    padding-right: 3rem !important;
  }

  .ml-auto-xl, .mx-auto-xl {
    margin-left: auto !important;
  }

  .mr-auto-xl, .mx-auto-xl {
    margin-right: auto !important;
  }

  .mt-auto-xl, .my-auto-xl {
    margin-top: auto !important;
  }

  .mb-auto-xl, .my-auto-xl {
    margin-bottom: auto !important;
  }

  .m-8-xl {
    margin: 4rem !important;
  }

  .mt-8-xl, .my-8-xl {
    margin-top: 4rem !important;
  }

  .mb-8-xl, .my-8-xl {
    margin-bottom: 4rem !important;
  }

  .ml-8-xl, .mx-8-xl {
    margin-left: 4rem !important;
  }

  .mr-8-xl, .mx-8-xl {
    margin-right: 4rem !important;
  }

  .p-8-xl {
    padding: 4rem !important;
  }

  .pt-8-xl, .py-8-xl {
    padding-top: 4rem !important;
  }

  .pb-8-xl, .py-8-xl {
    padding-bottom: 4rem !important;
  }

  .pl-8-xl, .px-8-xl {
    padding-left: 4rem !important;
  }

  .pr-8-xl, .px-8-xl {
    padding-right: 4rem !important;
  }

  .ml-auto-xl, .mx-auto-xl {
    margin-left: auto !important;
  }

  .mr-auto-xl, .mx-auto-xl {
    margin-right: auto !important;
  }

  .mt-auto-xl, .my-auto-xl {
    margin-top: auto !important;
  }

  .mb-auto-xl, .my-auto-xl {
    margin-bottom: auto !important;
  }

  .m-10-xl {
    margin: 5rem !important;
  }

  .mt-10-xl, .my-10-xl {
    margin-top: 5rem !important;
  }

  .mb-10-xl, .my-10-xl {
    margin-bottom: 5rem !important;
  }

  .ml-10-xl, .mx-10-xl {
    margin-left: 5rem !important;
  }

  .mr-10-xl, .mx-10-xl {
    margin-right: 5rem !important;
  }

  .p-10-xl {
    padding: 5rem !important;
  }

  .pt-10-xl, .py-10-xl {
    padding-top: 5rem !important;
  }

  .pb-10-xl, .py-10-xl {
    padding-bottom: 5rem !important;
  }

  .pl-10-xl, .px-10-xl {
    padding-left: 5rem !important;
  }

  .pr-10-xl, .px-10-xl {
    padding-right: 5rem !important;
  }

  .ml-auto-xl, .mx-auto-xl {
    margin-left: auto !important;
  }

  .mr-auto-xl, .mx-auto-xl {
    margin-right: auto !important;
  }

  .mt-auto-xl, .my-auto-xl {
    margin-top: auto !important;
  }

  .mb-auto-xl, .my-auto-xl {
    margin-bottom: auto !important;
  }

  .m-12-xl {
    margin: 6rem !important;
  }

  .mt-12-xl, .my-12-xl {
    margin-top: 6rem !important;
  }

  .mb-12-xl, .my-12-xl {
    margin-bottom: 6rem !important;
  }

  .ml-12-xl, .mx-12-xl {
    margin-left: 6rem !important;
  }

  .mr-12-xl, .mx-12-xl {
    margin-right: 6rem !important;
  }

  .p-12-xl {
    padding: 6rem !important;
  }

  .pt-12-xl, .py-12-xl {
    padding-top: 6rem !important;
  }

  .pb-12-xl, .py-12-xl {
    padding-bottom: 6rem !important;
  }

  .pl-12-xl, .px-12-xl {
    padding-left: 6rem !important;
  }

  .pr-12-xl, .px-12-xl {
    padding-right: 6rem !important;
  }

  .ml-auto-xl, .mx-auto-xl {
    margin-left: auto !important;
  }

  .mr-auto-xl, .mx-auto-xl {
    margin-right: auto !important;
  }

  .mt-auto-xl, .my-auto-xl {
    margin-top: auto !important;
  }

  .mb-auto-xl, .my-auto-xl {
    margin-bottom: auto !important;
  }

  .m-16-xl {
    margin: 8rem !important;
  }

  .mt-16-xl, .my-16-xl {
    margin-top: 8rem !important;
  }

  .mb-16-xl, .my-16-xl {
    margin-bottom: 8rem !important;
  }

  .ml-16-xl, .mx-16-xl {
    margin-left: 8rem !important;
  }

  .mr-16-xl, .mx-16-xl {
    margin-right: 8rem !important;
  }

  .p-16-xl {
    padding: 8rem !important;
  }

  .pt-16-xl, .py-16-xl {
    padding-top: 8rem !important;
  }

  .pb-16-xl, .py-16-xl {
    padding-bottom: 8rem !important;
  }

  .pl-16-xl, .px-16-xl {
    padding-left: 8rem !important;
  }

  .pr-16-xl, .px-16-xl {
    padding-right: 8rem !important;
  }

  .ml-auto-xl, .mx-auto-xl {
    margin-left: auto !important;
  }

  .mr-auto-xl, .mx-auto-xl {
    margin-right: auto !important;
  }

  .mt-auto-xl, .my-auto-xl {
    margin-top: auto !important;
  }

  .mb-auto-xl, .my-auto-xl {
    margin-bottom: auto !important;
  }

  .m-20-xl {
    margin: 10rem !important;
  }

  .mt-20-xl, .my-20-xl {
    margin-top: 10rem !important;
  }

  .mb-20-xl, .my-20-xl {
    margin-bottom: 10rem !important;
  }

  .ml-20-xl, .mx-20-xl {
    margin-left: 10rem !important;
  }

  .mr-20-xl, .mx-20-xl {
    margin-right: 10rem !important;
  }

  .p-20-xl {
    padding: 10rem !important;
  }

  .pt-20-xl, .py-20-xl {
    padding-top: 10rem !important;
  }

  .pb-20-xl, .py-20-xl {
    padding-bottom: 10rem !important;
  }

  .pl-20-xl, .px-20-xl {
    padding-left: 10rem !important;
  }

  .pr-20-xl, .px-20-xl {
    padding-right: 10rem !important;
  }

  .ml-auto-xl, .mx-auto-xl {
    margin-left: auto !important;
  }

  .mr-auto-xl, .mx-auto-xl {
    margin-right: auto !important;
  }

  .mt-auto-xl, .my-auto-xl {
    margin-top: auto !important;
  }

  .mb-auto-xl, .my-auto-xl {
    margin-bottom: auto !important;
  }

  .m-24-xl {
    margin: 12rem !important;
  }

  .mt-24-xl, .my-24-xl {
    margin-top: 12rem !important;
  }

  .mb-24-xl, .my-24-xl {
    margin-bottom: 12rem !important;
  }

  .ml-24-xl, .mx-24-xl {
    margin-left: 12rem !important;
  }

  .mr-24-xl, .mx-24-xl {
    margin-right: 12rem !important;
  }

  .p-24-xl {
    padding: 12rem !important;
  }

  .pt-24-xl, .py-24-xl {
    padding-top: 12rem !important;
  }

  .pb-24-xl, .py-24-xl {
    padding-bottom: 12rem !important;
  }

  .pl-24-xl, .px-24-xl {
    padding-left: 12rem !important;
  }

  .pr-24-xl, .px-24-xl {
    padding-right: 12rem !important;
  }

  .ml-auto-xl, .mx-auto-xl {
    margin-left: auto !important;
  }

  .mr-auto-xl, .mx-auto-xl {
    margin-right: auto !important;
  }

  .mt-auto-xl, .my-auto-xl {
    margin-top: auto !important;
  }

  .mb-auto-xl, .my-auto-xl {
    margin-bottom: auto !important;
  }

  .m-32-xl {
    margin: 16rem !important;
  }

  .mt-32-xl, .my-32-xl {
    margin-top: 16rem !important;
  }

  .mb-32-xl, .my-32-xl {
    margin-bottom: 16rem !important;
  }

  .ml-32-xl, .mx-32-xl {
    margin-left: 16rem !important;
  }

  .mr-32-xl, .mx-32-xl {
    margin-right: 16rem !important;
  }

  .p-32-xl {
    padding: 16rem !important;
  }

  .pt-32-xl, .py-32-xl {
    padding-top: 16rem !important;
  }

  .pb-32-xl, .py-32-xl {
    padding-bottom: 16rem !important;
  }

  .pl-32-xl, .px-32-xl {
    padding-left: 16rem !important;
  }

  .pr-32-xl, .px-32-xl {
    padding-right: 16rem !important;
  }

  .ml-auto-xl, .mx-auto-xl {
    margin-left: auto !important;
  }

  .mr-auto-xl, .mx-auto-xl {
    margin-right: auto !important;
  }

  .mt-auto-xl, .my-auto-xl {
    margin-top: auto !important;
  }

  .mb-auto-xl, .my-auto-xl {
    margin-bottom: auto !important;
  }
}

.btn, button, [type="submit"], [type="reset"], [type="button"] {
  cursor: pointer;
  text-align: center;
  transition: all var(--animation-duration);
  background-color: rgba(var(--btn-color), var(--bg-opacity));
  border: 1px solid #0000;
  border-color: rgba(var(--btn-border-color), var(--color-opacity));
  color: rgba(var(--btn-fg), var(--color-opacity));
  font-size: .75rem;
  line-height: 2rem;
  font-weight: inherit;
  letter-spacing: .03rem;
  text-transform: uppercase;
  min-width: 2rem;
  -webkit-user-select: none;
  user-select: none;
  --btn-color: 248, 249, 250;
  --btn-fg: 73, 80, 87;
  --btn-border-color: 233, 236, 239;
  border-radius: .25rem;
  outline: none;
  margin-bottom: 1rem;
  padding: .5rem 1rem;
  font-family: Montserrat, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  overflow: hidden;
}

.btn:hover, button:hover, [type="submit"]:hover, [type="reset"]:hover, [type="button"]:hover {
  transition: all var(--animation-duration);
  --btn-color: 241, 243, 245;
  color: rgba(var(--btn-fg), var(--color-opacity));
}

.btn:active, button:active, [type="submit"]:active, [type="reset"]:active, [type="button"]:active {
  transition: var(--animation-duration) ease;
}

.btn:focus, button:focus, [type="submit"]:focus, [type="reset"]:focus, [type="button"]:focus {
  outline: none;
}

.btn:focus:not([disabled]), button:focus:not([disabled]), [type="submit"]:focus:not([disabled]), [type="reset"]:focus:not([disabled]), [type="button"]:focus:not([disabled]) {
  box-shadow: var(--btn-shadow);
}

.btn:disabled, .btn.btn--disabled, button:disabled, button.btn--disabled, [type="submit"]:disabled, [type="submit"].btn--disabled, [type="reset"]:disabled, [type="reset"].btn--disabled, [type="button"]:disabled, [type="button"].btn--disabled {
  cursor: not-allowed;
  opacity: .5;
}

.btn:disabled:active, .btn.btn--disabled:active, button:disabled:active, button.btn--disabled:active, [type="submit"]:disabled:active, [type="submit"].btn--disabled:active, [type="reset"]:disabled:active, [type="reset"].btn--disabled:active, [type="button"]:disabled:active, [type="button"].btn--disabled:active {
  pointer-events: none;
}

.btn.outline, button.outline, [type="submit"].outline, [type="reset"].outline, [type="button"].outline {
  --btn-color: transparent;
}

.btn.outline:hover, button.outline:hover, [type="submit"].outline:hover, [type="reset"].outline:hover, [type="button"].outline:hover {
  --btn-color: 233, 236, 239;
}

.btn.loading:active:after, button.loading:active:after, [type="submit"].loading:active:after, [type="reset"].loading:active:after, [type="button"].loading:active:after {
  transition: var(--animation-duration) ease;
  border-top-color: #0000;
  border-right-color: #0000;
  border-radius: 50%;
}

.btn.btn-animated, button.btn-animated, [type="submit"].btn-animated, [type="reset"].btn-animated, [type="button"].btn-animated {
  transition: calc(var(--animation-duration) / 2) ease;
}

.btn.btn-animated:active, button.btn-animated:active, [type="submit"].btn-animated:active, [type="reset"].btn-animated:active, [type="button"].btn-animated:active {
  transition: calc(var(--animation-duration) / 2) ease;
  transform: scale(.95);
}

.btn.btn-close, button.btn-close, [type="submit"].btn-close, [type="reset"].btn-close, [type="button"].btn-close {
  cursor: pointer;
  height: 20px;
  vertical-align: top;
  width: 20px;
  min-width: 20px;
  background-color: #0a0a0a33;
  border: none;
  border-radius: 290486px;
  outline: 0;
  flex-grow: 0;
  flex-shrink: 0;
  padding: 0;
  font-size: 0;
  display: inline-block;
  position: relative;
}

.btn.btn-close:hover, button.btn-close:hover, [type="submit"].btn-close:hover, [type="reset"].btn-close:hover, [type="button"].btn-close:hover {
  background-color: #0a0a0a4d;
}

.btn.btn-close:before, button.btn-close:before, [type="submit"].btn-close:before, [type="reset"].btn-close:before, [type="button"].btn-close:before {
  background-color: var(--cirrus-bg);
  content: "";
  transform-origin: center;
  height: 2px;
  width: 50%;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%)translateY(-50%)rotate(45deg);
}

.btn.btn-close:after, button.btn-close:after, [type="submit"].btn-close:after, [type="reset"].btn-close:after, [type="button"].btn-close:after {
  background-color: var(--cirrus-bg);
  content: "";
  transform-origin: center;
  height: 50%;
  width: 2px;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%)translateY(-50%)rotate(45deg);
}

.btn.btn-transparent, button.btn-transparent, [type="submit"].btn-transparent, [type="reset"].btn-transparent, [type="button"].btn-transparent {
  --btn-color: transparent;
  --btn-fg: 54, 54, 54;
  --btn-border-color: transparent;
  --btn-shadow: 0 0 0 .2rem #f6f9fc80;
}

.btn.btn-transparent:hover, button.btn-transparent:hover, [type="submit"].btn-transparent:hover, [type="reset"].btn-transparent:hover, [type="button"].btn-transparent:hover {
  --bg-opacity: .1;
  --btn-color: 0, 0, 0;
}

.btn.btn-transparent.outline, button.btn-transparent.outline, [type="submit"].btn-transparent.outline, [type="reset"].btn-transparent.outline, [type="button"].btn-transparent.outline {
  --btn-fg: 54, 54, 54;
}

.btn.btn-light, button.btn-light, [type="submit"].btn-light, [type="reset"].btn-light, [type="button"].btn-light {
  --btn-color: 246, 249, 252;
  --btn-fg: 54, 54, 54;
  --btn-border-color: 246, 249, 252;
  --btn-shadow: 0 0 0 .2rem #f6f9fc80;
}

.btn.btn-light:hover, button.btn-light:hover, [type="submit"].btn-light:hover, [type="reset"].btn-light:hover, [type="button"].btn-light:hover {
  --btn-color: 208, 224, 239;
}

.btn.btn-black, button.btn-black, [type="submit"].btn-black, [type="reset"].btn-black, [type="button"].btn-black {
  --btn-color: 0, 0, 0;
  --btn-fg: 246, 249, 252;
  --btn-border-color: 0, 0, 0;
  --btn-shadow: 0 0 0 .2rem #36363680;
}

.btn.btn-black:hover, button.btn-black:hover, [type="submit"].btn-black:hover, [type="reset"].btn-black:hover, [type="button"].btn-black:hover {
  --btn-color: 0, 0, 0;
}

.btn.btn-primary, button.btn-primary, [type="submit"].btn-primary, [type="reset"].btn-primary, [type="button"].btn-primary {
  --btn-color: 240, 61, 77;
  --btn-fg: 246, 249, 252;
  --btn-border-color: 240, 61, 77;
  --btn-shadow: 0 0 0 .2rem #f03d4d80;
}

.btn.btn-primary:hover, button.btn-primary:hover, [type="submit"].btn-primary:hover, [type="reset"].btn-primary:hover, [type="button"].btn-primary:hover {
  --btn-color: 232, 18, 37;
}

.btn.btn-primary.outline:hover, button.btn-primary.outline:hover, [type="submit"].btn-primary.outline:hover, [type="reset"].btn-primary.outline:hover, [type="button"].btn-primary.outline:hover {
  --btn-color: 240, 61, 77;
}

.btn.btn-dark, button.btn-dark, [type="submit"].btn-dark, [type="reset"].btn-dark, [type="button"].btn-dark {
  --btn-color: 54, 54, 54;
  --btn-fg: 246, 249, 252;
  --btn-border-color: 54, 54, 54;
  --btn-shadow: 0 0 0 .2rem #36363680;
}

.btn.btn-dark:hover, button.btn-dark:hover, [type="submit"].btn-dark:hover, [type="reset"].btn-dark:hover, [type="button"].btn-dark:hover {
  --btn-color: 29, 29, 29;
}

.btn.btn-dark.outline:hover, button.btn-dark.outline:hover, [type="submit"].btn-dark.outline:hover, [type="reset"].btn-dark.outline:hover, [type="button"].btn-dark.outline:hover {
  --btn-color: 54, 54, 54;
}

.btn.btn-link, button.btn-link, [type="submit"].btn-link, [type="reset"].btn-link, [type="button"].btn-link {
  --btn-color: 94, 92, 199;
  --btn-fg: 246, 249, 252;
  --btn-border-color: 94, 92, 199;
  --btn-shadow: 0 0 0 .2rem #5e5cc780;
}

.btn.btn-link:hover, button.btn-link:hover, [type="submit"].btn-link:hover, [type="reset"].btn-link:hover, [type="button"].btn-link:hover {
  --btn-color: 64, 61, 179;
}

.btn.btn-link.outline:hover, button.btn-link.outline:hover, [type="submit"].btn-link.outline:hover, [type="reset"].btn-link.outline:hover, [type="button"].btn-link.outline:hover {
  --btn-color: 94, 92, 199;
}

.btn.btn-info, button.btn-info, [type="submit"].btn-info, [type="reset"].btn-info, [type="button"].btn-info {
  --btn-color: 41, 114, 250;
  --btn-fg: 246, 249, 252;
  --btn-border-color: 41, 114, 250;
  --btn-shadow: 0 0 0 .2rem #2972fa80;
}

.btn.btn-info:hover, button.btn-info:hover, [type="submit"].btn-info:hover, [type="reset"].btn-info:hover, [type="button"].btn-info:hover {
  --btn-color: 5, 85, 235;
}

.btn.btn-info.outline:hover, button.btn-info.outline:hover, [type="submit"].btn-info.outline:hover, [type="reset"].btn-info.outline:hover, [type="button"].btn-info.outline:hover {
  --btn-color: 41, 114, 250;
}

.btn.btn-success, button.btn-success, [type="submit"].btn-success, [type="reset"].btn-success, [type="button"].btn-success {
  --btn-color: 13, 209, 87;
  --btn-fg: 246, 249, 252;
  --btn-border-color: 13, 209, 87;
  --btn-shadow: 0 0 0 .2rem #0dd15780;
}

.btn.btn-success:hover, button.btn-success:hover, [type="submit"].btn-success:hover, [type="reset"].btn-success:hover, [type="button"].btn-success:hover {
  --btn-color: 10, 161, 67;
}

.btn.btn-success.outline:hover, button.btn-success.outline:hover, [type="submit"].btn-success.outline:hover, [type="reset"].btn-success.outline:hover, [type="button"].btn-success.outline:hover {
  --btn-color: 13, 209, 87;
}

.btn.btn-warning, button.btn-warning, [type="submit"].btn-warning, [type="reset"].btn-warning, [type="button"].btn-warning {
  --btn-color: 250, 182, 51;
  --btn-fg: 54, 54, 54;
  --btn-border-color: 250, 182, 51;
  --btn-shadow: 0 0 0 .2rem #fab63380;
}

.btn.btn-warning:hover, button.btn-warning:hover, [type="submit"].btn-warning:hover, [type="reset"].btn-warning:hover, [type="button"].btn-warning:hover {
  --btn-color: 244, 163, 6;
}

.btn.btn-warning.outline:hover, button.btn-warning.outline:hover, [type="submit"].btn-warning.outline:hover, [type="reset"].btn-warning.outline:hover, [type="button"].btn-warning.outline:hover {
  --btn-color: 250, 182, 51;
}

.btn.btn-danger, button.btn-danger, [type="submit"].btn-danger, [type="reset"].btn-danger, [type="button"].btn-danger {
  --btn-color: 251, 65, 67;
  --btn-fg: 246, 249, 252;
  --btn-border-color: 251, 65, 67;
  --btn-shadow: 0 0 0 .2rem #fb414380;
}

.btn.btn-danger:hover, button.btn-danger:hover, [type="submit"].btn-danger:hover, [type="reset"].btn-danger:hover, [type="button"].btn-danger:hover {
  --btn-color: 250, 15, 18;
}

.btn.btn-danger.outline:hover, button.btn-danger.outline:hover, [type="submit"].btn-danger.outline:hover, [type="reset"].btn-danger.outline:hover, [type="button"].btn-danger.outline:hover {
  --btn-color: 251, 65, 67;
}

.btn.btn--xs, button.btn--xs, [type="submit"].btn--xs, [type="reset"].btn--xs, [type="button"].btn--xs {
  padding: 0 .5rem;
  font-size: 50%;
}

.btn.btn--sm, button.btn--sm, [type="submit"].btn--sm, [type="reset"].btn--sm, [type="button"].btn--sm {
  padding: .25rem 1rem;
  font-size: 70%;
}

.btn.btn--md, button.btn--md, [type="submit"].btn--md, [type="reset"].btn--md, [type="button"].btn--md {
  padding: .5rem 1.5rem;
  font-size: 90%;
}

.btn.btn--lg, button.btn--lg, [type="submit"].btn--lg, [type="reset"].btn--lg, [type="button"].btn--lg {
  padding: .75rem 2rem;
  font-size: 110%;
}

.btn.btn--xl, button.btn--xl, [type="submit"].btn--xl, [type="reset"].btn--xl, [type="button"].btn--xl {
  padding: 1rem 2.5rem;
  font-size: 130%;
}

.btn .fa-wrapper.pad-right, button .fa-wrapper.pad-right, [type="submit"] .fa-wrapper.pad-right, [type="reset"] .fa-wrapper.pad-right, [type="button"] .fa-wrapper.pad-right {
  margin-right: .4rem;
}

.btn .fa-wrapper.pad-left, button .fa-wrapper.pad-left, [type="submit"] .fa-wrapper.pad-left, [type="reset"] .fa-wrapper.pad-left, [type="button"] .fa-wrapper.pad-left {
  margin-left: .4rem;
}

.btn span:first-child, button span:first-child, [type="submit"] span:first-child, [type="reset"] span:first-child, [type="button"] span:first-child {
  margin-right: .2rem;
}

.btn span:last-child, button span:last-child, [type="submit"] span:last-child, [type="reset"] span:last-child, [type="button"] span:last-child {
  margin-left: .2rem;
}

a.btn {
  display: inline-flex;
}

[class^="btn-"]:not(.btn-container):not(.btn-close), [class*=" btn-"]:not(.btn-container):not(.btn-close) {
  background-color: rgba(var(--btn-color), var(--bg-opacity));
  border: 1px solid rgba(var(--btn-border-color), var(--color-opacity));
  color: rgba(var(--btn-fg), var(--color-opacity));
  transition: all var(--animation-duration);
}

[class^="btn-"]:not(.btn-container):not(.btn-close):hover, [class*=" btn-"]:not(.btn-container):not(.btn-close):hover {
  background-color: rgba(var(--btn-color), var(--bg-opacity));
  border-color: rgba(var(--btn-border-color), var(--color-opacity));
  transition: all var(--animation-duration);
}

[class^="btn-"]:not(.btn-container):not(.btn-close).outline, [class*=" btn-"]:not(.btn-container):not(.btn-close).outline {
  color: rgba(var(--btn-color), var(--color-opacity));
  background-color: #0000;
}

[class^="btn-"]:not(.btn-container):not(.btn-close).outline:hover, [class*=" btn-"]:not(.btn-container):not(.btn-close).outline:hover {
  background-color: rgba(var(--btn-color), var(--bg-opacity));
  color: rgba(var(--btn-fg), var(--color-opacity));
  transition: all var(--animation-duration);
}

[class^="btn-"]:not(.btn-container):not(.btn-close).outline.btn-transparent, [class*=" btn-"]:not(.btn-container):not(.btn-close).outline.btn-transparent {
  color: 73, 80, 87 !important;
}

.loading.btn-accent:after {
  border: 2px solid #0000;
  border-color: #0000 #0000 #fff #fff;
}

.btn-group {
  display: inline-flex;
}

.btn-group .btn, .btn-group button, .btn-group [type="submit"], .btn-group [type="reset"], .btn-group [type="button"] {
  flex: 1 0 auto;
  margin: 0;
}

.btn-group .btn:first-child:not(:last-child), .btn-group button:first-child:not(:last-child), .btn-group [type="submit"]:first-child:not(:last-child), .btn-group [type="reset"]:first-child:not(:last-child), .btn-group [type="button"]:first-child:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group .btn:last-child:not(:first-child), .btn-group button:last-child:not(:first-child), .btn-group [type="submit"]:last-child:not(:first-child), .btn-group [type="reset"]:last-child:not(:first-child), .btn-group [type="button"]:last-child:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  margin-left: -1px;
}

.btn-group .btn:not(:first-child):not(:last-child), .btn-group button:not(:first-child):not(:last-child), .btn-group [type="submit"]:not(:first-child):not(:last-child), .btn-group [type="reset"]:not(:first-child):not(:last-child), .btn-group [type="button"]:not(:first-child):not(:last-child) {
  border-radius: 0;
  margin-left: -1px;
}

.btn-group .btn:focus, .btn-group button:focus, .btn-group [type="submit"]:focus, .btn-group [type="reset"]:focus, .btn-group [type="button"]:focus {
  z-index: 1;
}

.btn-group.btn-group-fill {
  display: flex;
}

.btn-group.btn-group-fill .btn, .btn-group.btn-group-fill button, .btn-group.btn-group-fill [type="submit"], .btn-group.btn-group-fill [type="reset"], .btn-group.btn-group-fill [type="button"] {
  flex: 1 0;
}

.btn-group.btn-group-fill:focus {
  z-index: 1;
}

@media screen and (max-width: 767px) {
  .btn-group {
    flex-direction: column;
    display: flex;
  }

  .btn-group .btn, .btn-group button, .btn-group [type="submit"], .btn-group [type="reset"], .btn-group [type="button"] {
    margin-bottom: -1px;
  }

  .btn-group .btn:first-child:not(:last-child), .btn-group button:first-child:not(:last-child), .btn-group [type="submit"]:first-child:not(:last-child), .btn-group [type="reset"]:first-child:not(:last-child), .btn-group [type="button"]:first-child:not(:last-child) {
    border-radius: .25rem .25rem 0 0;
  }

  .btn-group .btn:not(:first-child):not(:last-child), .btn-group button:not(:first-child):not(:last-child), .btn-group [type="submit"]:not(:first-child):not(:last-child), .btn-group [type="reset"]:not(:first-child):not(:last-child), .btn-group [type="button"]:not(:first-child):not(:last-child) {
    margin-left: 0;
  }

  .btn-group .btn:last-child:not(:first-child), .btn-group button:last-child:not(:first-child), .btn-group [type="submit"]:last-child:not(:first-child), .btn-group [type="reset"]:last-child:not(:first-child), .btn-group [type="button"]:last-child:not(:first-child) {
    border-radius: 0 0 .25rem .25rem;
    margin-left: 0;
  }
}

code {
  --cirrus-code-label-fg: #868e96;
  background-color: var(--cirrus-code-bg);
  color: var(--cirrus-code-fg);
  border-radius: 3px;
  margin: .5em 0;
  padding: .3rem;
  overflow: auto;
}

code:before {
  color: var(--cirrus-code-label-fg);
  content: attr(data-lang);
  font-size: .9rem;
  position: absolute;
  top: .7rem;
  right: 1rem;
}

code.dark {
  --cirrus-code-bg: #343a40;
  --cirrus-code-fg: #f8f9fa;
  --cirrus-border-left-bg: #212529;
  --cirrus-code-label-fg: #ced4da;
}

pre > code {
  --cirrus-code-bg: #f8f9fa;
  --cirrus-code-fg: #343a40;
  --cirrus-border-left-bg: #e9ecef;
  background-color: var(--cirrus-code-bg);
  white-space: pre-wrap;
  word-wrap: break-word;
  text-align: left;
  tab-size: 4;
  -webkit-hyphens: none;
  hyphens: none;
  border-left: .3rem solid var(--cirrus-border-left-bg);
  color: var(--cirrus-code-fg);
  margin: 0;
  padding: 1rem;
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 14px;
  line-height: 1.5;
  display: block;
  position: relative;
}

pre > code[data-lang]:not([data-lang=""]) {
  padding: 2rem 1.5rem 1rem;
}

.footer {
  text-align: center;
  width: 100%;
  background-color: #343a40;
  margin-top: 5rem;
  padding: 6rem 0;
}

.footer.footer--fixed {
  position: fixed;
  bottom: 0;
}

.footer p {
  color: #868e96;
}

.footer ul {
  margin: .5rem 0;
}

.footer .footer__title {
  text-align: center;
  letter-spacing: 6px;
  padding-bottom: 10px;
  position: relative;
}

.footer .footer__list-title {
  color: #ced4da;
  text-transform: uppercase;
  font-size: 75%;
  font-weight: bolder;
}

.footer .footer__list-title:after {
  content: "";
  width: 10%;
  border-color: #343b49;
  border-bottom-style: solid;
  border-bottom-width: 2px;
  margin: auto;
  display: block;
}

.footer ul a .footer__list-item, .footer .footer__list-item {
  color: #868e96;
  transition: all var(--animation-duration);
  text-transform: uppercase;
  margin: .1rem;
  font-size: 75%;
}

input:not([type="checkbox"]):not([type="radio"]):not([type="submit"]):not([type="button"]):not([type="reset"]), select {
  width: 100%;
  font-family: Nunito Sans, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  font-size: var(--font-size-m);
  letter-spacing: .02rem;
  border: 1px solid #dee2e6;
  border-radius: 3px;
  outline: none;
  padding: .85rem 1.1rem;
  transition: all .3s;
}

input:not([type="checkbox"]):not([type="radio"]):not([type="submit"]):not([type="button"]):not([type="reset"]).input--xs, select.input--xs {
  padding: .35rem .9rem;
  font-size: .75rem;
}

input:not([type="checkbox"]):not([type="radio"]):not([type="submit"]):not([type="button"]):not([type="reset"]).input--sm, select.input--sm {
  padding: .55rem 1rem;
  font-size: .875rem;
}

input:not([type="checkbox"]):not([type="radio"]):not([type="submit"]):not([type="button"]):not([type="reset"]).input--lg, select.input--lg {
  font-size: 1.25rem;
}

input:not([type="checkbox"]):not([type="radio"]):not([type="submit"]):not([type="button"]):not([type="reset"]).input--xl, select.input--xl {
  font-size: 1.5rem;
}

input:not([type="checkbox"]):not([type="radio"]):not([type="submit"]):not([type="button"]):not([type="reset"]).input-contains-icon, input:not([type="checkbox"]):not([type="radio"]):not([type="submit"]):not([type="button"]):not([type="reset"]).input-contains-icon-left, select.input-contains-icon, select.input-contains-icon-left {
  padding-left: 2.75rem;
}

input:not([type="checkbox"]):not([type="radio"]):not([type="submit"]):not([type="button"]):not([type="reset"]).input-contains-icon-right, select.input-contains-icon-right {
  padding-right: 2.75rem;
}

input:not([type="checkbox"]):not([type="radio"]):not([type="submit"]):not([type="button"]):not([type="reset"]).input-contains-icon.input--xs, input:not([type="checkbox"]):not([type="radio"]):not([type="submit"]):not([type="button"]):not([type="reset"]).input-contains-icon-right.input-xs, select.input-contains-icon.input--xs, select.input-contains-icon-right.input-xs {
  padding-left: 2rem;
}

input:not([type="checkbox"]):not([type="radio"]):not([type="submit"]):not([type="button"]):not([type="reset"]).input-contains-icon.input--sm, input:not([type="checkbox"]):not([type="radio"]):not([type="submit"]):not([type="button"]):not([type="reset"]).input-contains-icon-right.input-sm, select.input-contains-icon.input--sm, select.input-contains-icon-right.input-sm {
  padding-left: 2.5rem;
}

input:not([type="checkbox"]):not([type="radio"]):not([type="submit"]):not([type="button"]):not([type="reset"]).input-contains-icon.input--lg, input:not([type="checkbox"]):not([type="radio"]):not([type="submit"]):not([type="button"]):not([type="reset"]).input-contains-icon-right.input-lg, select.input-contains-icon.input--lg, select.input-contains-icon-right.input-lg {
  padding-left: 3.5rem;
}

input:not([type="checkbox"]):not([type="radio"]):not([type="submit"]):not([type="button"]):not([type="reset"]).input-contains-icon.input--xl, input:not([type="checkbox"]):not([type="radio"]):not([type="submit"]):not([type="button"]):not([type="reset"]).input-contains-icon-right.input-xl, select.input-contains-icon.input--xl, select.input-contains-icon-right.input-xl {
  padding-left: 4rem;
}

input:not([type="checkbox"]):not([type="radio"]):not([type="submit"]):not([type="button"]):not([type="reset"]).input-contains-icon.input--xs ~ .icon, input:not([type="checkbox"]):not([type="radio"]):not([type="submit"]):not([type="button"]):not([type="reset"]).input-contains-icon-left.input--xs ~ .icon, input:not([type="checkbox"]):not([type="radio"]):not([type="submit"]):not([type="button"]):not([type="reset"]).input-contains-icon-right.input--xs ~ .icon.icon-right, select.input-contains-icon.input--xs ~ .icon, select.input-contains-icon-left.input--xs ~ .icon, select.input-contains-icon-right.input--xs ~ .icon.icon-right {
  width: 1.75rem;
  font-size: 7px;
  line-height: 1.75rem;
}

input:not([type="checkbox"]):not([type="radio"]):not([type="submit"]):not([type="button"]):not([type="reset"]).input-contains-icon.input--sm ~ .icon, input:not([type="checkbox"]):not([type="radio"]):not([type="submit"]):not([type="button"]):not([type="reset"]).input-contains-icon-left.input--sm ~ .icon, input:not([type="checkbox"]):not([type="radio"]):not([type="submit"]):not([type="button"]):not([type="reset"]).input-contains-icon-right.input--sm ~ .icon.icon-right, select.input-contains-icon.input--sm ~ .icon, select.input-contains-icon-left.input--sm ~ .icon, select.input-contains-icon-right.input--sm ~ .icon.icon-right {
  width: 2.5rem;
  font-size: 14px;
}

input:not([type="checkbox"]):not([type="radio"]):not([type="submit"]):not([type="button"]):not([type="reset"]).input-contains-icon.input--lg ~ .icon, input:not([type="checkbox"]):not([type="radio"]):not([type="submit"]):not([type="button"]):not([type="reset"]).input-contains-icon-left.input--lg ~ .icon, input:not([type="checkbox"]):not([type="radio"]):not([type="submit"]):not([type="button"]):not([type="reset"]).input-contains-icon-right.input--lg ~ .icon.icon-right, select.input-contains-icon.input--lg ~ .icon, select.input-contains-icon-left.input--lg ~ .icon, select.input-contains-icon-right.input--lg ~ .icon.icon-right {
  width: 3.5rem;
  font-size: 28px;
  line-height: 3.5rem;
}

input:not([type="checkbox"]):not([type="radio"]):not([type="submit"]):not([type="button"]):not([type="reset"]).input-contains-icon.input--xl ~ .icon, input:not([type="checkbox"]):not([type="radio"]):not([type="submit"]):not([type="button"]):not([type="reset"]).input-contains-icon-left.input--xl ~ .icon, input:not([type="checkbox"]):not([type="radio"]):not([type="submit"]):not([type="button"]):not([type="reset"]).input-contains-icon-right.input--xl ~ .icon.icon-right, select.input-contains-icon.input--xl ~ .icon, select.input-contains-icon-left.input--xl ~ .icon, select.input-contains-icon-right.input--xl ~ .icon.icon-right {
  width: 3.75rem;
  font-size: 35px;
  line-height: 2.5rem;
}

input:not([type="checkbox"]):not([type="radio"]):not([type="submit"]):not([type="button"]):not([type="reset"])[type="search"], input:not([type="checkbox"]):not([type="radio"]):not([type="submit"]):not([type="button"]):not([type="reset"]).search, select[type="search"], select.search {
  background-image: url("data:image/svg+xml;utf8,<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"16\" height=\"16\" viewBox=\"0 0 16 16\"><path opacity=\"0.45\" d=\"M14.891,14.39l-0.5.5a0.355,0.355,0,0,1-.5,0L9.526,10.529a5.3,5.3,0,1,1,2.106-4.212,5.268,5.268,0,0,1-1.1,3.21l4.362,4.362A0.354,0.354,0,0,1,14.891,14.39ZM6.316,2.418a3.9,3.9,0,1,0,3.9,3.9A3.9,3.9,0,0,0,6.316,2.418Z\"/></svg>");
  background-position: .6rem 50%;
  background-repeat: no-repeat;
  padding-left: 2rem !important;
}

input:not([type="checkbox"]):not([type="radio"]):not([type="submit"]):not([type="button"]):not([type="reset"])[type="color"], select[type="color"] {
  box-sizing: border-box;
  height: 3rem;
  background-color: #fff;
  outline: 0;
  transition: all .3s;
  position: relative;
  box-shadow: inset 0 1px 2px #0000000d;
  padding: .55rem .8rem !important;
}

input:not([type="checkbox"]):not([type="radio"]):not([type="submit"]):not([type="button"]):not([type="reset"]).input-success, select.input-success {
  border-color: #0dd157;
  background-color: #00e0000d !important;
}

input:not([type="checkbox"]):not([type="radio"]):not([type="submit"]):not([type="button"]):not([type="reset"]).input-success:focus, select.input-success:focus {
  box-shadow: 0 0 0 .2rem #0dd1578c, inset 0 1px 8px #00000012;
}

input:not([type="checkbox"]):not([type="radio"]):not([type="submit"]):not([type="button"]):not([type="reset"]).input-error, select.input-error {
  border-color: #fb4143;
  background-color: #f443360d !important;
}

input:not([type="checkbox"]):not([type="radio"]):not([type="submit"]):not([type="button"]):not([type="reset"]).input-error:focus, select.input-error:focus {
  box-shadow: 0 0 0 .2rem #fb41438c, inset 0 1px 8px #00000012;
}

textarea, textarea[type="text"] {
  width: 100%;
  font-family: Nunito Sans, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  font-size: var(--font-size-m);
  letter-spacing: .02rem;
  min-height: 8rem;
  resize: vertical;
  border: 1px solid #dee2e6;
  border-radius: 3px;
  outline: none;
  padding: .85rem 1.1rem;
  line-height: 1.5rem;
  transition: all .3s;
}

textarea.input--xs, textarea[type="text"].input--xs {
  padding: .35rem .9rem;
  font-size: .75rem;
}

textarea.input--sm, textarea[type="text"].input--sm {
  padding: .55rem 1rem;
  font-size: .875rem;
}

textarea.input--lg, textarea[type="text"].input--lg {
  font-size: 1.25rem;
}

textarea.input--xl, textarea[type="text"].input--xl {
  font-size: 1.5rem;
}

textarea.input-contains-icon, textarea.input-contains-icon-left, textarea[type="text"].input-contains-icon, textarea[type="text"].input-contains-icon-left {
  padding-left: 2.75rem;
}

textarea.input-contains-icon-right, textarea[type="text"].input-contains-icon-right {
  padding-right: 2.75rem;
}

textarea.input-contains-icon.input--xs, textarea.input-contains-icon-right.input-xs, textarea[type="text"].input-contains-icon.input--xs, textarea[type="text"].input-contains-icon-right.input-xs {
  padding-left: 2rem;
}

textarea.input-contains-icon.input--sm, textarea.input-contains-icon-right.input-sm, textarea[type="text"].input-contains-icon.input--sm, textarea[type="text"].input-contains-icon-right.input-sm {
  padding-left: 2.5rem;
}

textarea.input-contains-icon.input--lg, textarea.input-contains-icon-right.input-lg, textarea[type="text"].input-contains-icon.input--lg, textarea[type="text"].input-contains-icon-right.input-lg {
  padding-left: 3.5rem;
}

textarea.input-contains-icon.input--xl, textarea.input-contains-icon-right.input-xl, textarea[type="text"].input-contains-icon.input--xl, textarea[type="text"].input-contains-icon-right.input-xl {
  padding-left: 4rem;
}

textarea.input-contains-icon.input--xs ~ .icon, textarea.input-contains-icon-left.input--xs ~ .icon, textarea.input-contains-icon-right.input--xs ~ .icon.icon-right, textarea[type="text"].input-contains-icon.input--xs ~ .icon, textarea[type="text"].input-contains-icon-left.input--xs ~ .icon, textarea[type="text"].input-contains-icon-right.input--xs ~ .icon.icon-right {
  width: 1.75rem;
  font-size: 7px;
  line-height: 1.75rem;
}

textarea.input-contains-icon.input--sm ~ .icon, textarea.input-contains-icon-left.input--sm ~ .icon, textarea.input-contains-icon-right.input--sm ~ .icon.icon-right, textarea[type="text"].input-contains-icon.input--sm ~ .icon, textarea[type="text"].input-contains-icon-left.input--sm ~ .icon, textarea[type="text"].input-contains-icon-right.input--sm ~ .icon.icon-right {
  width: 2.5rem;
  font-size: 14px;
}

textarea.input-contains-icon.input--lg ~ .icon, textarea.input-contains-icon-left.input--lg ~ .icon, textarea.input-contains-icon-right.input--lg ~ .icon.icon-right, textarea[type="text"].input-contains-icon.input--lg ~ .icon, textarea[type="text"].input-contains-icon-left.input--lg ~ .icon, textarea[type="text"].input-contains-icon-right.input--lg ~ .icon.icon-right {
  width: 3.5rem;
  font-size: 28px;
  line-height: 3.5rem;
}

textarea.input-contains-icon.input--xl ~ .icon, textarea.input-contains-icon-left.input--xl ~ .icon, textarea.input-contains-icon-right.input--xl ~ .icon.icon-right, textarea[type="text"].input-contains-icon.input--xl ~ .icon, textarea[type="text"].input-contains-icon-left.input--xl ~ .icon, textarea[type="text"].input-contains-icon-right.input--xl ~ .icon.icon-right {
  width: 3.75rem;
  font-size: 35px;
  line-height: 2.5rem;
}

input:not([type="checkbox"]):not([type="radio"]):not([type="submit"]):not([type="button"]):not([type="reset"]):focus, select:focus, textarea:focus, textarea[type="text"]:focus, input[type="text"].input-focused, .input-focused {
  border-color: #3dabf0;
  box-shadow: 0 0 0 .2rem #3dabf073, inset 0 1px 8px #00000012;
}

input:not([type="checkbox"]):not([type="radio"]):not([type="submit"]):not([type="button"]):not([type="reset"]):focus.input-success, select:focus.input-success, textarea:focus.input-success, textarea[type="text"]:focus.input-success, input[type="text"].input-focused.input-success, .input-focused.input-success {
  border-color: #0dd157;
  background-color: #00e0000d !important;
}

input:not([type="checkbox"]):not([type="radio"]):not([type="submit"]):not([type="button"]):not([type="reset"]):focus.input-success:focus, select:focus.input-success:focus, textarea:focus.input-success:focus, textarea[type="text"]:focus.input-success:focus, input[type="text"].input-focused.input-success:focus, .input-focused.input-success:focus {
  box-shadow: 0 0 0 .2rem #0dd1578c, inset 0 1px 8px #00000012;
}

input:not([type="checkbox"]):not([type="radio"]):not([type="submit"]):not([type="button"]):not([type="reset"]):focus.input-error, select:focus.input-error, textarea:focus.input-error, textarea[type="text"]:focus.input-error, input[type="text"].input-focused.input-error, .input-focused.input-error {
  border-color: #fb4143;
  background-color: #f443360d !important;
}

input:not([type="checkbox"]):not([type="radio"]):not([type="submit"]):not([type="button"]):not([type="reset"]):focus.input-error:focus, select:focus.input-error:focus, textarea:focus.input-error:focus, textarea[type="text"]:focus.input-error:focus, input[type="text"].input-focused.input-error:focus, .input-focused.input-error:focus {
  box-shadow: 0 0 0 .2rem #fb41438c, inset 0 1px 8px #00000012;
}

select {
  width: 100%;
  font-family: Nunito Sans, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  font-size: var(--font-size-m);
  letter-spacing: .02rem;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 3px;
  outline: none;
  padding: .85rem 1.1rem;
  transition: all .3s;
}

select.input--xs {
  padding: .35rem .9rem;
  font-size: .75rem;
}

select.input--sm {
  padding: .55rem 1rem;
  font-size: .875rem;
}

select.input--lg {
  font-size: 1.25rem;
}

select.input--xl {
  font-size: 1.5rem;
}

select.input-contains-icon, select.input-contains-icon-left {
  padding-left: 2.75rem;
}

select.input-contains-icon-right {
  padding-right: 2.75rem;
}

select.input-contains-icon.input--xs, select.input-contains-icon-right.input-xs {
  padding-left: 2rem;
}

select.input-contains-icon.input--sm, select.input-contains-icon-right.input-sm {
  padding-left: 2.5rem;
}

select.input-contains-icon.input--lg, select.input-contains-icon-right.input-lg {
  padding-left: 3.5rem;
}

select.input-contains-icon.input--xl, select.input-contains-icon-right.input-xl {
  padding-left: 4rem;
}

select.input-contains-icon.input--xs ~ .icon, select.input-contains-icon-left.input--xs ~ .icon, select.input-contains-icon-right.input--xs ~ .icon.icon-right {
  width: 1.75rem;
  font-size: 7px;
  line-height: 1.75rem;
}

select.input-contains-icon.input--sm ~ .icon, select.input-contains-icon-left.input--sm ~ .icon, select.input-contains-icon-right.input--sm ~ .icon.icon-right {
  width: 2.5rem;
  font-size: 14px;
}

select.input-contains-icon.input--lg ~ .icon, select.input-contains-icon-left.input--lg ~ .icon, select.input-contains-icon-right.input--lg ~ .icon.icon-right {
  width: 3.5rem;
  font-size: 28px;
  line-height: 3.5rem;
}

select.input-contains-icon.input--xl ~ .icon, select.input-contains-icon-left.input--xl ~ .icon, select.input-contains-icon-right.input--xl ~ .icon.icon-right {
  width: 3.75rem;
  font-size: 35px;
  line-height: 2.5rem;
}

select[multiple] option {
  padding: .2rem .4rem;
}

select.select:not([size]):not([multiple]) {
  background: url("data:image/svg+xml;charset=utf8,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%204%205'%3E%3Cpath%20fill='%23667189'%20d='M2%200L0%202h4zm0%205L0%203h4z'/%3E%3C/svg%3E") no-repeat right .85rem center / .5rem .6rem no-repeat;
  appearance: none;
}

input:not([class*="btn-"]):disabled, input:not([class*=" btn-"]):disabled:hover, select:disabled, textarea:disabled {
  cursor: not-allowed;
  background-color: #f1f3f5;
  border: 1px solid #f1f3f5;
}

label.label {
  color: #495057;
  margin-top: .8rem;
  font-weight: bold;
  display: inline-block;
}

label + .input-control {
  margin-top: 0;
}

label.label:not(:last-child) {
  margin-bottom: 0;
}

label:first-child:not(:last-child):not(.form-group-label) {
  margin-right: .5rem;
}

label:not(:first-child):not(:last-child):not(.form-group-label) {
  margin: 0 .5rem;
}

label:last-child:not(:first-child):not(.form-group-label) {
  margin-left: .5rem;
}

.required {
  color: #c81e1e;
  padding-left: .1rem;
  font-weight: bold;
  position: relative;
  top: 1px;
}

.input-control {
  margin: .5rem 0;
  position: relative;
}

.input-contains-icon ~ .icon, .input-contains-icon-left ~ .icon, .input-contains-icon-right ~ .icon {
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.input-contains-icon ~ .icon:not(.icon-right), .input-contains-icon-left ~ .icon.icon-left {
  width: 3rem;
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
}

.input-contains-icon-right ~ .icon.icon-right {
  pointer-events: none;
  vertical-align: baseline;
  width: 3rem;
  z-index: 1;
  line-height: 2.75rem;
  position: absolute;
  top: 0;
  right: 0;
}

.form-group {
  margin: .5rem 0;
  display: flex;
}

.form-group .form-group-btn {
  flex: 1 0 auto;
  margin-bottom: 0;
}

.form-group .form-group-btn:first-child:not(:last-child) {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.form-group .form-group-btn:not(:first-child):not(:last-child) {
  margin-left: -1px;
  margin-right: -1px;
  border-radius: 0 !important;
}

.form-group .form-group-btn:last-child:not(:first-child) {
  z-index: 0;
  margin-left: -1px;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.form-group .form-group-btn:focus {
  z-index: 1;
}

.form-group .form-group-label {
  background-color: var(--cirrus-form-group-bg);
  color: var(--cirrus-form-group-fg);
  -webkit-user-select: none;
  user-select: none;
  border: 1px solid #dee2e6;
  border-radius: .2rem;
  margin: 0;
  padding: .8rem;
}

.form-group .form-group-label:first-child:not(:last-child) {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.form-group .form-group-label:not(:first-child):not(:last-child) {
  margin-left: -1px;
  margin-right: -1px;
  border-radius: 0 !important;
}

.form-group .form-group-label:last-child:not(:first-child) {
  z-index: 0;
  margin-left: -1px;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.form-group .form-group-label.label--xs {
  font-size: .75rem;
}

.form-group .form-group-label.label--sm {
  font-size: .875rem;
}

.form-group .form-group-label.label--lg {
  font-size: 1.25rem;
}

.form-group .form-group-label.label--xl {
  font-size: 1.5rem;
}

.form-group .form-group-input:first-child:not(:last-child) {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.form-group .form-group-input:not(:first-child):not(:last-child) {
  margin-left: -1px;
  margin-right: -1px;
  border-radius: 0 !important;
}

.form-group .form-group-input:last-child:not(:first-child) {
  z-index: 0;
  margin-left: -1px;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.form-group .form-group-input:focus {
  z-index: 1;
}

::-webkit-input-placeholder {
  color: #a9a9a9;
}

::-moz-placeholder {
  color: #a9a9a9;
}

.frame {
  border-radius: 3px;
  flex-direction: column;
  display: flex;
  box-shadow: 0 .2rem 1.25rem #1b1e2412;
}

.frame .frame__header, .frame .frame__footer {
  flex: none;
  padding: 1rem;
}

.frame .frame__nav {
  flex: none;
}

.frame .frame__body {
  flex: auto;
  padding: 0 1rem;
  overflow-y: auto;
}

.frame .frame__title {
  color: #0f172a;
  margin: .75rem auto 0;
  font-size: 1rem;
}

.frame .frame__subtitle {
  color: #0f172a99;
  margin: 0 auto .75rem;
  font-size: 1rem;
}

.header {
  width: 100%;
  z-index: 100;
  background-color: var(--cirrus-bg);
  max-height: 100vh;
  --header-link-color: #495057;
  --header-link-color-hover: #606a73;
  flex-grow: 1;
  margin-bottom: 20px;
  padding: 0 2rem;
  transition: all .3s;
  display: flex;
  box-shadow: 0 3px 15px #393f481a;
}

.header h1, .header h2, .header h3, .header h4, .header h5, .header h6 {
  margin: 0;
}

.header a {
  color: var(--header-link-color);
}

.header a:hover {
  color: var(--header-link-color-hover);
}

.header.header-dark {
  color: #fff;
  --header-link-color: #fff;
  --header-link-color-hover: #fff;
  background-color: #000000de;
}

.header.header-clear {
  box-shadow: none;
  background-color: #0000;
}

.header.header-clear .nav-item .dropdown-menu {
  border-radius: 4px;
}

.header.header-animated .header-nav {
  transition: all .3s;
}

.header .header-nav {
  overflow: auto;
}

.header .header-brand {
  max-width: 100vw;
  min-height: 3.25rem;
  flex-shrink: 0;
  align-items: stretch;
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
}

.header.header-fixed {
  position: fixed;
  top: 0;
}

.header:not(.header-clear) .nav-item:not(.no-hover):hover, .header:not(.header-clear) .nav-item:not(.no-hover).hovered {
  background-color: #d8d8d826;
  transition: all .3s;
}

.header:not(.header-clear) .nav-item.active, .header:not(.header-clear) .nav-item.active:hover {
  background-color: #d8d8d859;
}

.header .nav-btn {
  cursor: pointer;
  height: 3.5rem;
  width: 3.5rem;
  display: block;
  position: relative;
}

.header .btn, .header button, .header [type="submit"], .header [type="reset"], .header [type="button"] {
  margin: 0;
}

.nav-menu {
  transition: all .3s;
}

.nav-menu .has-sub {
  position: relative;
}

.nav-overflow-x {
  justify-content: inherit;
  overflow-x: scroll;
}

.nav-item {
  cursor: pointer;
  flex-grow: 0;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  padding: 0 .3rem;
  transition: all .3s;
  display: flex;
  position: relative;
}

.nav-item a {
  align-items: center;
  display: flex;
}

.nav-item .dropdown-menu {
  background-color: var(--cirrus-bg);
  z-index: 1000;
  float: left;
  min-width: 160px;
  text-align: left;
  background-clip: padding-box;
  border: 1px solid #e9ecef;
  border-radius: 0 0 4px 4px;
  margin: 2px 0 0;
  padding: 5px 0;
  font-size: 14px;
  list-style: none;
  position: absolute;
  top: 95%;
  box-shadow: 0 .5rem 1rem #0a0a0a1a;
}

.nav-item .dropdown-menu.dropdown-animated {
  transition: all var(--animation-duration);
}

.nav-item .dropdown-menu > li > a {
  clear: both;
  white-space: nowrap;
  padding: .5rem 1rem;
  line-height: 1.42857;
  display: block;
}

.nav-item .dropdown-menu > li {
  margin: 0;
  transition: all .3s;
}

.nav-item .dropdown-menu > li:hover {
  background-color: #d8d8d826;
  transition: all .3s;
}

.nav-item .dropdown-menu > li:active {
  background-color: #d8d8d840;
  transition: all .3s;
}

.nav-item .dropdown-menu > li:last-child {
  margin-bottom: 0;
}

.nav-item .dropdown-menu .dropdown-menu-divider {
  height: 1px;
  background-color: #d8d8d826;
  border: none;
  margin: .5rem 0;
}

.nav-item.has-sub .nav-dropdown-link {
  padding-right: 2.5rem;
  position: relative;
}

.nav-item.has-sub .nav-dropdown-link:after {
  height: .5em;
  width: .5em;
  content: " ";
  pointer-events: none;
  border-top: 0;
  border-bottom: 2px solid #f03d4d;
  border-left: 2px solid #f03d4d;
  border-right: 0;
  margin-top: -.435em;
  display: block;
  position: absolute;
  top: 50%;
  right: 1.125em;
  transform: rotate(-45deg);
}

.nav-item .dropdown-menu.dropdown-dark, .header.header-dark .dropdown-menu {
  color: #fff;
  background-color: #000000de;
  border: 1px solid #343a40;
}

.dropdown-menu.dropdown-shown, .nav-item.active {
  opacity: 1;
}

@media screen and (min-width: 768px) {
  .header {
    align-items: stretch;
    display: flex;
  }

  .header .header-nav {
    text-align: center;
    width: 100%;
    flex-grow: 1;
    align-items: stretch;
    display: flex;
    position: relative;
    top: 0;
    overflow: visible;
  }

  .header .nav-left {
    white-space: nowrap;
    flex: 1 0 0;
    justify-content: flex-start;
    align-items: stretch;
    display: flex;
  }

  .header .nav-left .has-sub .dropdown-menu {
    left: 0;
    right: auto;
  }

  .header .nav-right {
    white-space: nowrap;
    flex: 1 0 0;
    justify-content: flex-end;
    align-items: stretch;
    display: flex;
  }

  .header .nav-right .has-sub .dropdown-menu {
    left: auto;
    right: 0;
  }

  .header .nav-center {
    flex-grow: 0;
    flex-shrink: 0;
    justify-content: center;
    align-items: stretch;
    margin-left: auto;
    margin-right: auto;
    display: flex;
  }

  .header .nav-btn {
    display: none;
  }

  .header .nav-item a {
    padding: .5rem 1rem;
  }

  .header .nav-item .dropdown-menu {
    opacity: 0;
    pointer-events: none;
  }

  .header .nav-item .dropdown-menu.dropdown-animated {
    transform: translateY(-5px);
  }

  .header .nav-item .dropdown-menu.dropdown-shown, .header .nav-item.toggle-hover:hover .dropdown-menu, .header .nav-item .dropdown-menu.dropdown-animated.dropdown-shown {
    opacity: 1;
    pointer-events: auto;
    transform: none;
  }
}

@media screen and (max-width: 767px) {
  .header {
    flex-direction: column;
  }

  .header .header-brand .nav-item:first-child {
    padding: 0 1rem;
  }

  .header .header-nav {
    height: 0;
  }

  .header .header-nav.active {
    height: 100vh;
  }

  .header .header-nav .nav-item {
    padding: 1rem;
  }

  .header .header-nav .nav-item > a {
    width: 100%;
    padding: 0;
  }

  .nav-item.has-sub {
    display: block;
  }

  .nav-item.has-sub .dropdown-menu {
    display: none;
  }

  .nav-item.has-sub .dropdown-menu.dropdown-shown {
    box-shadow: none;
    float: none;
    background-color: #0000;
    border: none;
    border-radius: 0;
    margin-bottom: 1rem;
    display: block;
    position: relative;
    top: 1rem;
  }

  .nav-item.has-sub .dropdown-menu.dropdown-dark {
    background-color: #0000002b;
    border: 0;
  }

  .nav-btn {
    cursor: pointer;
    margin-left: auto;
    display: block;
    position: relative;
  }

  .nav-btn span {
    background-color: var(--header-link-color);
    height: 2px;
    width: 15px;
    margin-left: -7px;
    transition: all 86ms ease-out;
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
  }

  .nav-btn span:nth-child(1) {
    margin-top: -6px;
  }

  .nav-btn span:nth-child(2) {
    margin-top: -1px;
  }

  .nav-btn span:nth-child(3) {
    margin-top: 4px;
  }

  .nav-btn.active span:nth-child(1) {
    transform-origin: 0 0;
    margin-left: -5px;
    transform: rotate(45deg);
  }

  .nav-btn.active span:nth-child(2) {
    opacity: 0;
  }

  .nav-btn.active span:nth-child(3) {
    transform-origin: 0 100%;
    margin-left: -5px;
    transform: rotate(-45deg);
  }

  .nav-left, .nav-center, .nav-right {
    overflow: hidden;
  }

  .header .nav-item.has-sub.toggle-hover:not(.no-hover):hover > .dropdown-menu {
    box-shadow: none;
    float: none;
    background-color: #0000;
    border: none;
    border-radius: 0;
    margin-bottom: 1rem;
    display: block;
    position: relative;
    top: 1rem;
  }
}

a {
  color: #5e5cc7;
  padding: 2px;
  font-weight: 600;
  text-decoration: none;
  transition: all .3s;
}

a:hover {
  color: #4643e2;
  transition: all .3s;
}

a.underline {
  text-decoration: underline;
}

.subtitle a {
  padding: 0;
}

h1 a, h2 a, h3 a, h4 a, h5 a, h6 a, article a, blockquote a {
  display: inline;
}

a .btn, a button, [type="submit"] a {
  margin-bottom: 0;
}

ul, ol {
  margin: 1rem 0 1rem 1rem;
  padding-inline-start: .5rem;
}

ul ul, ul ol, ol ul, ol ol {
  margin: 0 0 0 1rem;
}

ul {
  list-style: disc;
}

ul ul {
  list-style-type: circle;
}

ul ul ul {
  list-style-type: square;
}

ol ol {
  list-style: lower-alpha;
}

ol ol ol {
  list-style: upper-roman;
}

dl {
  margin: 1rem 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: .5rem;
}

li {
  margin: .25rem 0;
}

ul.no-bullets {
  list-style: none;
}

ul.menu {
  margin: .5rem 0;
  font-size: 1rem;
  list-style: none;
}

ul .menu-title:not(:first-child) {
  margin-bottom: 1rem;
}

ul .menu-title:not(:last-child) {
  margin-top: 1rem;
}

ul .menu-item a {
  color: #495057;
  font-size: var(--font-size-s);
  cursor: pointer;
  transition: all var(--animation-duration);
  border-radius: 3px;
  padding: .5em .75em;
  display: block;
}

ul .menu-item:hover > a {
  color: #f03d4d;
  transition: all var(--animation-duration);
  background-color: #d0d0d04d;
}

ul .menu-item.selected > a {
  color: #fff;
  background-color: #f03d4d;
}

ul .menu-item .menu-addon {
  z-index: 1;
  color: var(--cirrus-fg);
  cursor: pointer;
  float: left;
  transition: all var(--animation-duration);
  margin-right: .1rem;
  padding: .3rem;
  position: relative;
}

ul .menu-item .menu-addon .icon {
  font-size: inherit;
  vertical-align: auto;
}

ul .menu-item .menu-addon:hover {
  transition: all var(--animation-duration);
}

ul .menu-item .menu-addon.right {
  float: right;
  margin-left: .1rem;
  margin-right: 0;
}

ul .menu-item.selected .menu-addon {
  color: #fff;
}

ul .menu-item ul {
  border-left: 1px solid #dee2e6;
  margin: .75rem;
  padding-left: .75rem;
}

ul .divider {
  height: .1rem;
  border-top: .1rem solid #e9ecef;
  margin: 1rem 0;
}

ul .divider:after {
  content: attr(data-label);
  background-color: var(--cirrus-bg);
  color: #adb5bd;
  margin: .5rem;
  padding: 0 .7rem;
  font-size: .7rem;
  display: inline-block;
  transform: translateY(-1.1rem);
}

.list-dropdown {
  display: inline-block;
  position: relative;
}

.list-dropdown .menu {
  animation: slide-down var(--animation-duration) ease 1;
  background-color: var(--cirrus-bg);
  opacity: 0;
  min-width: 15rem;
  z-index: 10;
  pointer-events: none;
  transition: all var(--animation-duration);
  border-radius: .2rem;
  margin: 0;
  padding: .25rem .5rem;
  position: absolute;
  top: 75%;
  left: 0;
  overflow: hidden;
  transform: translateY(.5rem);
  box-shadow: 0 1rem 3rem #959da54d;
}

@media screen and (max-width: 767px) {
  .list-dropdown .menu {
    height: 0;
    position: relative;
    padding: 0 !important;
  }
}

.list-dropdown.dropdown-right .menu {
  left: auto;
  right: 0;
}

.list-dropdown.shown .menu, .list-dropdown .btn-dropdown:focus + .menu, .list-dropdown .menu:hover {
  opacity: 1;
  z-index: 100;
  pointer-events: auto;
  height: auto;
  transition: all var(--animation-duration);
  display: block;
  top: 100%;
}

.list-dropdown .btn-group .btn-dropdown:nth-last-child(2) {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

.tree {
  margin: 0;
}

.tree .tree-item .tree-item-header {
  cursor: pointer;
  padding: .25rem .5rem;
  font-weight: 700;
  display: block;
}

.tree .tree-item .tree-item-header .icon {
  transition: all var(--animation-duration);
}

.tree .tree-item input:checked ~ .tree-item-body {
  max-height: 100vh;
}

.tree .tree-item input:checked ~ .tree-item-header .icon {
  transform: rotate(90deg);
}

.tree .tree-item .tree-item-body {
  max-height: 0;
  transition: all var(--animation-duration);
  margin-left: 1.5rem;
  overflow: hidden;
}

.tree-nav-body {
  height: 100vh;
  flex-wrap: nowrap;
  display: flex;
}

.tree-nav-body .tree-nav {
  min-width: 15rem;
  height: 100vh;
  flex-grow: 0;
  flex-shrink: 1;
  padding: 2rem 1rem 2rem 2rem;
  overflow: auto;
}

.tree-nav-body .tree-nav-container {
  top: 4rem;
  bottom: 1rem;
  overflow-y: auto;
}

.tree-nav-body + .tree-nav-close {
  display: none;
}

.tree-nav-body + .tree-nav-content {
  max-width: 100%;
  flex: 1 0 auto;
  margin: 0;
  padding: 2rem;
  overflow: auto;
}

.tree-nav-body .tree-nav-content {
  width: 100%;
  margin: 0;
  padding: 2rem;
  overflow: auto;
}

@media screen and (max-width: 767px) {
  .tree-nav {
    height: 100%;
    transition: transform var(--animation-duration) ease;
    z-index: 400;
    padding: 3rem 1.5rem;
    position: fixed;
    top: 0;
    left: 0;
    overflow-y: auto;
    transform: translateX(-100%);
  }

  .tree-nav:target {
    transition: transform var(--animation-duration) ease;
    transform: translateX(0);
  }

  .tree-nav:target + .tree-nav-close {
    height: 100%;
    width: 100%;
    z-index: 300;
    background-color: #00000026;
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
  }

  .tree-nav .tree-nav-close {
    height: 100%;
    width: 100%;
    z-index: 300;
    background-color: #00000026;
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
  }

  .tree-nav + .tree-nav-body {
    max-width: inherit;
  }

  .tree-nav-header {
    height: 3.5rem;
    text-align: center;
    z-index: 300;
    background-color: #f8f9facc;
    padding: .75rem .5rem;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
  }

  .nav-item.has-sub .list-dropdown, .nav-item.has-sub .list-dropdown .btn-group {
    width: 100%;
  }

  .nav-item.has-sub .list-dropdown .btn-group .btn-dropdown {
    flex-grow: 0;
  }

  .list-dropdown .btn-dropdown:focus + .menu {
    width: 100%;
    position: relative;
  }
}

.progress {
  --progress-color: #f03d4d;
  width: 100%;
  height: .75rem;
  background-color: #e9ecef;
  border: none;
  border-radius: .25rem;
  padding: 0;
  overflow: hidden;
}

.progress::-webkit-progress-bar {
  background-color: #0000;
}

.progress::-webkit-progress-value {
  background-color: var(--progress-color);
}

.progress::-moz-progress-bar {
  background-color: var(--progress-color);
}

.progress::-ms-fill {
  background-color: var(--progress-color);
  border: none;
}

.progress.progress--xs {
  height: .25rem;
}

.progress.progress--sm {
  height: .5rem;
}

.progress.progress--md {
  height: .75rem;
}

.progress.progress--lg {
  height: 1rem;
}

.progress.progress--xl {
  height: 1.25rem;
}

.progress.progress--primary {
  --progress-color: #f03d4d;
}

.progress.progress--dark {
  --progress-color: #363636;
}

.progress.progress--link {
  --progress-color: #5e5cc7;
}

.progress.progress--info {
  --progress-color: #2972fa;
}

.progress.progress--success {
  --progress-color: #0dd157;
}

.progress.progress--warning {
  --progress-color: #fab633;
}

.progress.progress--danger {
  --progress-color: #fb4143;
}

.progress:indeterminate {
  background: #e9ecef linear-gradient(to right, var(--progress-color) 30%, #e9ecef 30%) top left / 150% 150% no-repeat;
  animation: 1.5s linear infinite progress-indeterminate;
}

.progress:indeterminate::-webkit-progress-bar {
  --progress-color: transparent;
}

.progress:indeterminate::-moz-progress-bar {
  --progress-color: transparent;
}

.progress:indeterminate::-ms-fill {
  animation-name: none;
}

@keyframes progress-indeterminate {
  0% {
    background-position: 200% 0;
  }

  100% {
    background-position: -200% 0;
  }
}

.table-container {
  max-width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
}

.table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  text-align: center;
  margin-bottom: 1.5rem;
}

.table td, .table th {
  vertical-align: top;
  text-align: inherit;
  border: 0 solid #dee2e680;
  border-bottom-width: 1px;
  margin: 0;
  padding: .75rem;
}

.table tr {
  transition: all .3s;
}

.table tr.selected {
  color: #fff;
  background-color: #f03d4d;
}

.table .table caption {
  color: #dee2e6;
  text-align: left;
  caption-side: bottom;
  padding-top: .75rem;
  padding-bottom: .75rem;
}

.table tr:not(.selected):hover, .table.striped tbody tr:not(.selected):nth-child(2n):hover {
  background-color: #dee2e626;
}

.table thead th, .table thead {
  border-bottom: 2px solid #dee2e680;
}

.table thead th, .table tfoot th {
  padding: 1rem;
}

.table tfoot th {
  border-top: 2px solid #dee2e680;
  border-bottom: none;
}

.table.bordered thead th, .table.bordered thead {
  border-bottom: 1px solid #dee2e680;
}

.table.bordered td, .table.bordered th {
  border: 1px solid #dbdbdb80;
}

.table.bordered thead td, .table.bordered thead {
  border-width: 1px;
}

.table.striped tbody tr:nth-child(2n) {
  background-color: #0000000d;
}

.table.small td, .table.small th {
  padding: .25rem .75rem;
}

.table.fixed-head thead {
  display: block;
  position: relative;
}

.table.fixed-head tbody {
  min-height: 200px;
  display: block;
  overflow: auto;
}

.table.fixed-head tr {
  width: 100%;
  display: table;
}

.table.borderless thead th, .table.borderless th, .table.borderless td {
  border: none;
}

.u-top-auto {
  top: auto !important;
}

.u-top-0 {
  top: 0 !important;
}

.u-top-25p {
  top: 25% !important;
}

.u-top-n25p {
  top: -25% !important;
}

.u-top-50p {
  top: 50% !important;
}

.u-top-n50p {
  top: -50% !important;
}

.u-top-75p {
  top: 75% !important;
}

.u-top-n75p {
  top: -75% !important;
}

.u-top-100p {
  top: 100% !important;
}

.u-top-n100p {
  top: -100% !important;
}

.u-top-1 {
  top: .5rem !important;
}

.u-top-n1 {
  top: -.5rem !important;
}

.u-top-2 {
  top: 1rem !important;
}

.u-top-n2 {
  top: -1rem !important;
}

.u-top-3 {
  top: 1.5rem !important;
}

.u-top-n3 {
  top: -1.5rem !important;
}

.u-top-4 {
  top: 2rem !important;
}

.u-top-n4 {
  top: -2rem !important;
}

.u-top-5 {
  top: 2.5rem !important;
}

.u-top-n5 {
  top: -2.5rem !important;
}

.u-top-6 {
  top: 3rem !important;
}

.u-top-n6 {
  top: -3rem !important;
}

.u-top-8 {
  top: 4rem !important;
}

.u-top-n8 {
  top: -4rem !important;
}

.u-left-auto {
  left: auto !important;
}

.u-left-0 {
  left: 0 !important;
}

.u-left-25p {
  left: 25% !important;
}

.u-left-n25p {
  left: -25% !important;
}

.u-left-50p {
  left: 50% !important;
}

.u-left-n50p {
  left: -50% !important;
}

.u-left-75p {
  left: 75% !important;
}

.u-left-n75p {
  left: -75% !important;
}

.u-left-100p {
  left: 100% !important;
}

.u-left-n100p {
  left: -100% !important;
}

.u-left-1 {
  left: .5rem !important;
}

.u-left-n1 {
  left: -.5rem !important;
}

.u-left-2 {
  left: 1rem !important;
}

.u-left-n2 {
  left: -1rem !important;
}

.u-left-3 {
  left: 1.5rem !important;
}

.u-left-n3 {
  left: -1.5rem !important;
}

.u-left-4 {
  left: 2rem !important;
}

.u-left-n4 {
  left: -2rem !important;
}

.u-left-5 {
  left: 2.5rem !important;
}

.u-left-n5 {
  left: -2.5rem !important;
}

.u-left-6 {
  left: 3rem !important;
}

.u-left-n6 {
  left: -3rem !important;
}

.u-left-8 {
  left: 4rem !important;
}

.u-left-n8 {
  left: -4rem !important;
}

.u-right-auto {
  right: auto !important;
}

.u-right-0 {
  right: 0 !important;
}

.u-right-25p {
  right: 25% !important;
}

.u-right-n25p {
  right: -25% !important;
}

.u-right-50p {
  right: 50% !important;
}

.u-right-n50p {
  right: -50% !important;
}

.u-right-75p {
  right: 75% !important;
}

.u-right-n75p {
  right: -75% !important;
}

.u-right-100p {
  right: 100% !important;
}

.u-right-n100p {
  right: -100% !important;
}

.u-right-1 {
  right: .5rem !important;
}

.u-right-n1 {
  right: -.5rem !important;
}

.u-right-2 {
  right: 1rem !important;
}

.u-right-n2 {
  right: -1rem !important;
}

.u-right-3 {
  right: 1.5rem !important;
}

.u-right-n3 {
  right: -1.5rem !important;
}

.u-right-4 {
  right: 2rem !important;
}

.u-right-n4 {
  right: -2rem !important;
}

.u-right-5 {
  right: 2.5rem !important;
}

.u-right-n5 {
  right: -2.5rem !important;
}

.u-right-6 {
  right: 3rem !important;
}

.u-right-n6 {
  right: -3rem !important;
}

.u-right-8 {
  right: 4rem !important;
}

.u-right-n8 {
  right: -4rem !important;
}

.u-bottom-auto {
  bottom: auto !important;
}

.u-bottom-0 {
  bottom: 0 !important;
}

.u-bottom-25p {
  bottom: 25% !important;
}

.u-bottom-n25p {
  bottom: -25% !important;
}

.u-bottom-50p {
  bottom: 50% !important;
}

.u-bottom-n50p {
  bottom: -50% !important;
}

.u-bottom-75p {
  bottom: 75% !important;
}

.u-bottom-n75p {
  bottom: -75% !important;
}

.u-bottom-100p {
  bottom: 100% !important;
}

.u-bottom-n100p {
  bottom: -100% !important;
}

.u-bottom-1 {
  bottom: .5rem !important;
}

.u-bottom-n1 {
  bottom: -.5rem !important;
}

.u-bottom-2 {
  bottom: 1rem !important;
}

.u-bottom-n2 {
  bottom: -1rem !important;
}

.u-bottom-3 {
  bottom: 1.5rem !important;
}

.u-bottom-n3 {
  bottom: -1.5rem !important;
}

.u-bottom-4 {
  bottom: 2rem !important;
}

.u-bottom-n4 {
  bottom: -2rem !important;
}

.u-bottom-5 {
  bottom: 2.5rem !important;
}

.u-bottom-n5 {
  bottom: -2.5rem !important;
}

.u-bottom-6 {
  bottom: 3rem !important;
}

.u-bottom-n6 {
  bottom: -3rem !important;
}

.u-bottom-8 {
  bottom: 4rem !important;
}

.u-bottom-n8 {
  bottom: -4rem !important;
}

.u-blur-none {
  --cirrus-blur: blur(0);
  filter: var(--cirrus-blur);
}

.u-blur-xs {
  --cirrus-blur: blur(.25rem);
  filter: var(--cirrus-blur);
}

.u-blur-sm {
  --cirrus-blur: blur(.5rem);
  filter: var(--cirrus-blur);
}

.u-blur-md {
  --cirrus-blur: blur(1rem);
  filter: var(--cirrus-blur);
}

.u-blur-lg {
  --cirrus-blur: blur(1.5rem);
  filter: var(--cirrus-blur);
}

.u-blur-xl {
  --cirrus-blur: blur(3rem);
  filter: var(--cirrus-blur);
}

.u-border-0 {
  border-width: 0;
}

.u-border-1 {
  border-width: 1px;
}

.u-border-2 {
  border-width: 2px;
}

.u-border-4 {
  border-width: 4px;
}

.u-border-8 {
  border-width: 8px;
}

.u-border-opacity-0 {
  --border-opacity: 0;
}

.u-border-opacity-10 {
  --border-opacity: .1;
}

.u-border-opacity-20 {
  --border-opacity: .2;
}

.u-border-opacity-30 {
  --border-opacity: .3;
}

.u-border-opacity-40 {
  --border-opacity: .4;
}

.u-border-opacity-50 {
  --border-opacity: .5;
}

.u-border-opacity-60 {
  --border-opacity: .6;
}

.u-border-opacity-70 {
  --border-opacity: .7;
}

.u-border-opacity-80 {
  --border-opacity: .8;
}

.u-border-opacity-90 {
  --border-opacity: .9;
}

.u-border-opacity-100 {
  --border-opacity: 1;
}

.u-round-none {
  border-radius: 0;
}

.u-round-xs {
  border-radius: .25rem;
}

.u-round-sm {
  border-radius: .5rem;
}

.u-round-md {
  border-radius: .75rem;
}

.u-round-lg {
  border-radius: 1rem;
}

.u-round-xl {
  border-radius: 1.25rem;
}

.u-round-full {
  border-radius: 9001px;
}

.u-clear-left {
  clear: left !important;
}

.u-clear-right {
  clear: right !important;
}

.u-clear-both {
  clear: both !important;
}

@media screen and (min-width: 640px) {
  .u-clear-left-sm {
    clear: left !important;
  }

  .u-clear-right-sm {
    clear: right !important;
  }

  .u-clear-both-sm {
    clear: both !important;
  }
}

@media screen and (min-width: 768px) {
  .u-clear-left-md {
    clear: left !important;
  }

  .u-clear-right-md {
    clear: right !important;
  }

  .u-clear-both-md {
    clear: both !important;
  }
}

@media screen and (min-width: 1024px) {
  .u-clear-left-lg {
    clear: left !important;
  }

  .u-clear-right-lg {
    clear: right !important;
  }

  .u-clear-both-lg {
    clear: both !important;
  }
}

@media screen and (min-width: 1280px) {
  .u-clear-left-xl {
    clear: left !important;
  }

  .u-clear-right-xl {
    clear: right !important;
  }

  .u-clear-both-xl {
    clear: both !important;
  }
}

.u-clearfix:after {
  content: " ";
  clear: both !important;
  display: table !important;
}

.u-none {
  display: none !important;
}

.u-inline {
  display: inline !important;
}

.u-inline-block {
  display: inline-block !important;
}

.u-block {
  display: block !important;
}

.u-flex {
  display: flex !important;
}

.u-inline-flex {
  display: inline-flex !important;
}

.u-table {
  display: table !important;
}

.u-table-row {
  display: table-row !important;
}

.u-table-column {
  display: table-column !important;
}

.u-table-cell {
  display: table-cell !important;
}

@media screen and (min-width: 640px) {
  .u-none-sm {
    display: none !important;
  }

  .u-inline-sm {
    display: inline !important;
  }

  .u-inline-block-sm {
    display: inline-block !important;
  }

  .u-block-sm {
    display: block !important;
  }

  .u-flex-sm {
    display: flex !important;
  }

  .u-inline-flex-sm {
    display: inline-flex !important;
  }

  .u-table-sm {
    display: table !important;
  }

  .u-table-row-sm {
    display: table-row !important;
  }

  .u-table-column-sm {
    display: table-column !important;
  }

  .u-table-cell-sm {
    display: table-cell !important;
  }
}

@media screen and (min-width: 768px) {
  .u-none-md {
    display: none !important;
  }

  .u-inline-md {
    display: inline !important;
  }

  .u-inline-block-md {
    display: inline-block !important;
  }

  .u-block-md {
    display: block !important;
  }

  .u-flex-md {
    display: flex !important;
  }

  .u-inline-flex-md {
    display: inline-flex !important;
  }

  .u-table-md {
    display: table !important;
  }

  .u-table-row-md {
    display: table-row !important;
  }

  .u-table-column-md {
    display: table-column !important;
  }

  .u-table-cell-md {
    display: table-cell !important;
  }
}

@media screen and (min-width: 1024px) {
  .u-none-lg {
    display: none !important;
  }

  .u-inline-lg {
    display: inline !important;
  }

  .u-inline-block-lg {
    display: inline-block !important;
  }

  .u-block-lg {
    display: block !important;
  }

  .u-flex-lg {
    display: flex !important;
  }

  .u-inline-flex-lg {
    display: inline-flex !important;
  }

  .u-table-lg {
    display: table !important;
  }

  .u-table-row-lg {
    display: table-row !important;
  }

  .u-table-column-lg {
    display: table-column !important;
  }

  .u-table-cell-lg {
    display: table-cell !important;
  }
}

@media screen and (min-width: 1280px) {
  .u-none-xl {
    display: none !important;
  }

  .u-inline-xl {
    display: inline !important;
  }

  .u-inline-block-xl {
    display: inline-block !important;
  }

  .u-block-xl {
    display: block !important;
  }

  .u-flex-xl {
    display: flex !important;
  }

  .u-inline-flex-xl {
    display: inline-flex !important;
  }

  .u-table-xl {
    display: table !important;
  }

  .u-table-row-xl {
    display: table-row !important;
  }

  .u-table-column-xl {
    display: table-column !important;
  }

  .u-table-cell-xl {
    display: table-cell !important;
  }
}

.u-flex-row {
  flex-direction: row !important;
}

.u-flex-row-reverse {
  flex-direction: row-reverse !important;
}

.u-flex-column {
  flex-direction: column !important;
}

.u-flex-column-reverse {
  flex-direction: column-reverse !important;
}

@media screen and (min-width: 640px) {
  .u-flex-row-sm {
    flex-direction: row !important;
  }

  .u-flex-row-reverse-sm {
    flex-direction: row-reverse !important;
  }

  .u-flex-column-sm {
    flex-direction: column !important;
  }

  .u-flex-column-reverse-sm {
    flex-direction: column-reverse !important;
  }
}

@media screen and (min-width: 768px) {
  .u-flex-row-md {
    flex-direction: row !important;
  }

  .u-flex-row-reverse-md {
    flex-direction: row-reverse !important;
  }

  .u-flex-column-md {
    flex-direction: column !important;
  }

  .u-flex-column-reverse-md {
    flex-direction: column-reverse !important;
  }
}

@media screen and (min-width: 1024px) {
  .u-flex-row-lg {
    flex-direction: row !important;
  }

  .u-flex-row-reverse-lg {
    flex-direction: row-reverse !important;
  }

  .u-flex-column-lg {
    flex-direction: column !important;
  }

  .u-flex-column-reverse-lg {
    flex-direction: column-reverse !important;
  }
}

@media screen and (min-width: 1280px) {
  .u-flex-row-xl {
    flex-direction: row !important;
  }

  .u-flex-row-reverse-xl {
    flex-direction: row-reverse !important;
  }

  .u-flex-column-xl {
    flex-direction: column !important;
  }

  .u-flex-column-reverse-xl {
    flex-direction: column-reverse !important;
  }
}

.u-justify-flex-start {
  justify-content: flex-start !important;
}

.u-justify-center {
  justify-content: center !important;
}

.u-justify-flex-end {
  justify-content: flex-end !important;
}

.u-justify-space-between {
  justify-content: space-between !important;
}

.u-justify-space-around {
  justify-content: space-around !important;
}

.u-justify-space-evenly {
  justify-content: space-evenly !important;
}

@media screen and (min-width: 640px) {
  .u-justify-flex-start-sm {
    justify-content: flex-start !important;
  }

  .u-justify-center-sm {
    justify-content: center !important;
  }

  .u-justify-flex-end-sm {
    justify-content: flex-end !important;
  }

  .u-justify-space-between-sm {
    justify-content: space-between !important;
  }

  .u-justify-space-around-sm {
    justify-content: space-around !important;
  }

  .u-justify-space-evenly-sm {
    justify-content: space-evenly !important;
  }
}

@media screen and (min-width: 768px) {
  .u-justify-flex-start-md {
    justify-content: flex-start !important;
  }

  .u-justify-center-md {
    justify-content: center !important;
  }

  .u-justify-flex-end-md {
    justify-content: flex-end !important;
  }

  .u-justify-space-between-md {
    justify-content: space-between !important;
  }

  .u-justify-space-around-md {
    justify-content: space-around !important;
  }

  .u-justify-space-evenly-md {
    justify-content: space-evenly !important;
  }
}

@media screen and (min-width: 1024px) {
  .u-justify-flex-start-lg {
    justify-content: flex-start !important;
  }

  .u-justify-center-lg {
    justify-content: center !important;
  }

  .u-justify-flex-end-lg {
    justify-content: flex-end !important;
  }

  .u-justify-space-between-lg {
    justify-content: space-between !important;
  }

  .u-justify-space-around-lg {
    justify-content: space-around !important;
  }

  .u-justify-space-evenly-lg {
    justify-content: space-evenly !important;
  }
}

@media screen and (min-width: 1280px) {
  .u-justify-flex-start-xl {
    justify-content: flex-start !important;
  }

  .u-justify-center-xl {
    justify-content: center !important;
  }

  .u-justify-flex-end-xl {
    justify-content: flex-end !important;
  }

  .u-justify-space-between-xl {
    justify-content: space-between !important;
  }

  .u-justify-space-around-xl {
    justify-content: space-around !important;
  }

  .u-justify-space-evenly-xl {
    justify-content: space-evenly !important;
  }
}

.u-items-stretch {
  align-items: stretch !important;
}

.u-items-flex-start {
  align-items: flex-start !important;
}

.u-items-center {
  align-items: center !important;
}

.u-items-flex-end {
  align-items: flex-end !important;
}

.u-items-baseline {
  align-items: baseline !important;
}

@media screen and (min-width: 640px) {
  .u-items-stretch-sm {
    align-items: stretch !important;
  }

  .u-items-flex-start-sm {
    align-items: flex-start !important;
  }

  .u-items-center-sm {
    align-items: center !important;
  }

  .u-items-flex-end-sm {
    align-items: flex-end !important;
  }

  .u-items-baseline-sm {
    align-items: baseline !important;
  }
}

@media screen and (min-width: 768px) {
  .u-items-stretch-md {
    align-items: stretch !important;
  }

  .u-items-flex-start-md {
    align-items: flex-start !important;
  }

  .u-items-center-md {
    align-items: center !important;
  }

  .u-items-flex-end-md {
    align-items: flex-end !important;
  }

  .u-items-baseline-md {
    align-items: baseline !important;
  }
}

@media screen and (min-width: 1024px) {
  .u-items-stretch-lg {
    align-items: stretch !important;
  }

  .u-items-flex-start-lg {
    align-items: flex-start !important;
  }

  .u-items-center-lg {
    align-items: center !important;
  }

  .u-items-flex-end-lg {
    align-items: flex-end !important;
  }

  .u-items-baseline-lg {
    align-items: baseline !important;
  }
}

@media screen and (min-width: 1280px) {
  .u-items-stretch-xl {
    align-items: stretch !important;
  }

  .u-items-flex-start-xl {
    align-items: flex-start !important;
  }

  .u-items-center-xl {
    align-items: center !important;
  }

  .u-items-flex-end-xl {
    align-items: flex-end !important;
  }

  .u-items-baseline-xl {
    align-items: baseline !important;
  }
}

.u-flex-grow-0 {
  flex-grow: 0 !important;
}

.u-flex-grow-1 {
  flex-grow: 1 !important;
}

@media screen and (min-width: 640px) {
  .u-flex-grow-0-sm {
    flex-grow: 0 !important;
  }

  .u-flex-grow-1-sm {
    flex-grow: 1 !important;
  }
}

@media screen and (min-width: 768px) {
  .u-flex-grow-0-md {
    flex-grow: 0 !important;
  }

  .u-flex-grow-1-md {
    flex-grow: 1 !important;
  }
}

@media screen and (min-width: 1024px) {
  .u-flex-grow-0-lg {
    flex-grow: 0 !important;
  }

  .u-flex-grow-1-lg {
    flex-grow: 1 !important;
  }
}

@media screen and (min-width: 1280px) {
  .u-flex-grow-0-xl {
    flex-grow: 0 !important;
  }

  .u-flex-grow-1-xl {
    flex-grow: 1 !important;
  }
}

.u-flex-shrink-0 {
  flex-shrink: 0 !important;
}

.u-flex-shrink-1 {
  flex-shrink: 1 !important;
}

@media screen and (min-width: 640px) {
  .u-flex-shrink-0-sm {
    flex-shrink: 0 !important;
  }

  .u-flex-shrink-1-sm {
    flex-shrink: 1 !important;
  }
}

@media screen and (min-width: 768px) {
  .u-flex-shrink-0-md {
    flex-shrink: 0 !important;
  }

  .u-flex-shrink-1-md {
    flex-shrink: 1 !important;
  }
}

@media screen and (min-width: 1024px) {
  .u-flex-shrink-0-lg {
    flex-shrink: 0 !important;
  }

  .u-flex-shrink-1-lg {
    flex-shrink: 1 !important;
  }
}

@media screen and (min-width: 1280px) {
  .u-flex-shrink-0-xl {
    flex-shrink: 0 !important;
  }

  .u-flex-shrink-1-xl {
    flex-shrink: 1 !important;
  }
}

.u-flex-wrap {
  flex-wrap: wrap !important;
}

.u-flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.u-flex-nowrap {
  flex-wrap: nowrap !important;
}

@media screen and (min-width: 640px) {
  .u-flex-wrap-sm {
    flex-wrap: wrap !important;
  }

  .u-flex-wrap-reverse-sm {
    flex-wrap: wrap-reverse !important;
  }

  .u-flex-nowrap-sm {
    flex-wrap: nowrap !important;
  }
}

@media screen and (min-width: 768px) {
  .u-flex-wrap-md {
    flex-wrap: wrap !important;
  }

  .u-flex-wrap-reverse-md {
    flex-wrap: wrap-reverse !important;
  }

  .u-flex-nowrap-md {
    flex-wrap: nowrap !important;
  }
}

@media screen and (min-width: 1024px) {
  .u-flex-wrap-lg {
    flex-wrap: wrap !important;
  }

  .u-flex-wrap-reverse-lg {
    flex-wrap: wrap-reverse !important;
  }

  .u-flex-nowrap-lg {
    flex-wrap: nowrap !important;
  }
}

@media screen and (min-width: 1280px) {
  .u-flex-wrap-xl {
    flex-wrap: wrap !important;
  }

  .u-flex-wrap-reverse-xl {
    flex-wrap: wrap-reverse !important;
  }

  .u-flex-nowrap-xl {
    flex-wrap: nowrap !important;
  }
}

.u-basis-auto {
  flex-basis: auto !important;
}

.u-basis-max-content {
  flex-basis: max-content !important;
}

.u-basis-min-content {
  flex-basis: min-content !important;
}

.u-basis-fit-content {
  flex-basis: fit-content !important;
}

.u-basis-content {
  flex-basis: content !important;
}

.u-basis-revert {
  flex-basis: revert !important;
}

.u-basis-revert-layer {
  flex-basis: revert-layer !important;
}

.u-basis-10p {
  flex-basis: 10% !important;
}

.u-basis-20p {
  flex-basis: 20% !important;
}

.u-basis-30p {
  flex-basis: 30% !important;
}

.u-basis-40p {
  flex-basis: 40% !important;
}

.u-basis-50p {
  flex-basis: 50% !important;
}

.u-basis-60p {
  flex-basis: 60% !important;
}

.u-basis-70p {
  flex-basis: 70% !important;
}

.u-basis-80p {
  flex-basis: 80% !important;
}

.u-basis-90p {
  flex-basis: 90% !important;
}

.u-basis-100p {
  flex-basis: 100% !important;
}

@media screen and (min-width: 640px) {
  .u-basis-auto-sm {
    flex-basis: auto !important;
  }

  .u-basis-max-content-sm {
    flex-basis: max-content !important;
  }

  .u-basis-min-content-sm {
    flex-basis: min-content !important;
  }

  .u-basis-fit-content-sm {
    flex-basis: fit-content !important;
  }

  .u-basis-content-sm {
    flex-basis: content !important;
  }

  .u-basis-revert-sm {
    flex-basis: revert !important;
  }

  .u-basis-revert-layer-sm {
    flex-basis: revert-layer !important;
  }

  .u-basis-10p-sm {
    flex-basis: 10% !important;
  }

  .u-basis-20p-sm {
    flex-basis: 20% !important;
  }

  .u-basis-30p-sm {
    flex-basis: 30% !important;
  }

  .u-basis-40p-sm {
    flex-basis: 40% !important;
  }

  .u-basis-50p-sm {
    flex-basis: 50% !important;
  }

  .u-basis-60p-sm {
    flex-basis: 60% !important;
  }

  .u-basis-70p-sm {
    flex-basis: 70% !important;
  }

  .u-basis-80p-sm {
    flex-basis: 80% !important;
  }

  .u-basis-90p-sm {
    flex-basis: 90% !important;
  }

  .u-basis-100p-sm {
    flex-basis: 100% !important;
  }
}

@media screen and (min-width: 768px) {
  .u-basis-auto-md {
    flex-basis: auto !important;
  }

  .u-basis-max-content-md {
    flex-basis: max-content !important;
  }

  .u-basis-min-content-md {
    flex-basis: min-content !important;
  }

  .u-basis-fit-content-md {
    flex-basis: fit-content !important;
  }

  .u-basis-content-md {
    flex-basis: content !important;
  }

  .u-basis-revert-md {
    flex-basis: revert !important;
  }

  .u-basis-revert-layer-md {
    flex-basis: revert-layer !important;
  }

  .u-basis-10p-md {
    flex-basis: 10% !important;
  }

  .u-basis-20p-md {
    flex-basis: 20% !important;
  }

  .u-basis-30p-md {
    flex-basis: 30% !important;
  }

  .u-basis-40p-md {
    flex-basis: 40% !important;
  }

  .u-basis-50p-md {
    flex-basis: 50% !important;
  }

  .u-basis-60p-md {
    flex-basis: 60% !important;
  }

  .u-basis-70p-md {
    flex-basis: 70% !important;
  }

  .u-basis-80p-md {
    flex-basis: 80% !important;
  }

  .u-basis-90p-md {
    flex-basis: 90% !important;
  }

  .u-basis-100p-md {
    flex-basis: 100% !important;
  }
}

@media screen and (min-width: 1024px) {
  .u-basis-auto-lg {
    flex-basis: auto !important;
  }

  .u-basis-max-content-lg {
    flex-basis: max-content !important;
  }

  .u-basis-min-content-lg {
    flex-basis: min-content !important;
  }

  .u-basis-fit-content-lg {
    flex-basis: fit-content !important;
  }

  .u-basis-content-lg {
    flex-basis: content !important;
  }

  .u-basis-revert-lg {
    flex-basis: revert !important;
  }

  .u-basis-revert-layer-lg {
    flex-basis: revert-layer !important;
  }

  .u-basis-10p-lg {
    flex-basis: 10% !important;
  }

  .u-basis-20p-lg {
    flex-basis: 20% !important;
  }

  .u-basis-30p-lg {
    flex-basis: 30% !important;
  }

  .u-basis-40p-lg {
    flex-basis: 40% !important;
  }

  .u-basis-50p-lg {
    flex-basis: 50% !important;
  }

  .u-basis-60p-lg {
    flex-basis: 60% !important;
  }

  .u-basis-70p-lg {
    flex-basis: 70% !important;
  }

  .u-basis-80p-lg {
    flex-basis: 80% !important;
  }

  .u-basis-90p-lg {
    flex-basis: 90% !important;
  }

  .u-basis-100p-lg {
    flex-basis: 100% !important;
  }
}

@media screen and (min-width: 1280px) {
  .u-basis-auto-xl {
    flex-basis: auto !important;
  }

  .u-basis-max-content-xl {
    flex-basis: max-content !important;
  }

  .u-basis-min-content-xl {
    flex-basis: min-content !important;
  }

  .u-basis-fit-content-xl {
    flex-basis: fit-content !important;
  }

  .u-basis-content-xl {
    flex-basis: content !important;
  }

  .u-basis-revert-xl {
    flex-basis: revert !important;
  }

  .u-basis-revert-layer-xl {
    flex-basis: revert-layer !important;
  }

  .u-basis-10p-xl {
    flex-basis: 10% !important;
  }

  .u-basis-20p-xl {
    flex-basis: 20% !important;
  }

  .u-basis-30p-xl {
    flex-basis: 30% !important;
  }

  .u-basis-40p-xl {
    flex-basis: 40% !important;
  }

  .u-basis-50p-xl {
    flex-basis: 50% !important;
  }

  .u-basis-60p-xl {
    flex-basis: 60% !important;
  }

  .u-basis-70p-xl {
    flex-basis: 70% !important;
  }

  .u-basis-80p-xl {
    flex-basis: 80% !important;
  }

  .u-basis-90p-xl {
    flex-basis: 90% !important;
  }

  .u-basis-100p-xl {
    flex-basis: 100% !important;
  }
}

.u-gap-0 {
  gap: 0 !important;
}

.u-gap-1 {
  gap: .5rem !important;
}

.u-gap-2 {
  gap: 1rem !important;
}

.u-gap-3 {
  gap: 1.5rem !important;
}

.u-gap-4 {
  gap: 2rem !important;
}

.u-gap-5 {
  gap: 2.5rem !important;
}

.u-gap-6 {
  gap: 3rem !important;
}

.u-gap-8 {
  gap: 4rem !important;
}

.u-gap-10 {
  gap: 5rem !important;
}

.u-gap-12 {
  gap: 6rem !important;
}

.u-gap-16 {
  gap: 8rem !important;
}

.u-gap-20 {
  gap: 10rem !important;
}

.u-gap-24 {
  gap: 12rem !important;
}

.u-gap-32 {
  gap: 16rem !important;
}

.u-pull-left {
  float: left !important;
}

.u-pull-right {
  float: right !important;
}

@media screen and (min-width: 640px) {
  .u-pull-left-sm {
    float: left !important;
  }

  .u-pull-right-sm {
    float: right !important;
  }
}

@media screen and (min-width: 768px) {
  .u-pull-left-md {
    float: left !important;
  }

  .u-pull-right-md {
    float: right !important;
  }
}

@media screen and (min-width: 1024px) {
  .u-pull-left-lg {
    float: left !important;
  }

  .u-pull-right-lg {
    float: right !important;
  }
}

@media screen and (min-width: 1280px) {
  .u-pull-left-xl {
    float: left !important;
  }

  .u-pull-right-xl {
    float: right !important;
  }
}

.u-text-justify {
  text-align: justify !important;
}

.u-text-left {
  text-align: left !important;
}

.u-text-right {
  text-align: right !important;
}

.u-text-center {
  text-align: center !important;
}

@media screen and (min-width: 640px) {
  .u-text-justify-sm {
    text-align: justify !important;
  }

  .u-text-left-sm {
    text-align: left !important;
  }

  .u-text-right-sm {
    text-align: right !important;
  }

  .u-text-center-sm {
    text-align: center !important;
  }
}

@media screen and (min-width: 768px) {
  .u-text-justify-md {
    text-align: justify !important;
  }

  .u-text-left-md {
    text-align: left !important;
  }

  .u-text-right-md {
    text-align: right !important;
  }

  .u-text-center-md {
    text-align: center !important;
  }
}

@media screen and (min-width: 1024px) {
  .u-text-justify-lg {
    text-align: justify !important;
  }

  .u-text-left-lg {
    text-align: left !important;
  }

  .u-text-right-lg {
    text-align: right !important;
  }

  .u-text-center-lg {
    text-align: center !important;
  }
}

@media screen and (min-width: 1280px) {
  .u-text-justify-xl {
    text-align: justify !important;
  }

  .u-text-left-xl {
    text-align: left !important;
  }

  .u-text-right-xl {
    text-align: right !important;
  }

  .u-text-center-xl {
    text-align: center !important;
  }
}

.u-text-ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.u-text-break {
  -webkit-hyphens: auto;
  hyphens: auto;
  word-break: break-word;
  word-wrap: break-word;
}

.u-center {
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  display: flex;
}

.u-center-alt, .u-center-alt:hover, .u-center-alt:active {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.u-overlay {
  position: absolute;
  inset: 0;
}

.u-disabled {
  cursor: not-allowed !important;
  pointer-events: none !important;
}

.u-unselectable {
  -webkit-user-select: none !important;
  user-select: none !important;
}

.u-no-outline {
  outline: 0 !important;
}

.u-opacity-0 {
  opacity: 0 !important;
}

.u-opacity-10 {
  opacity: .1 !important;
}

.u-opacity-20 {
  opacity: .2 !important;
}

.u-opacity-30 {
  opacity: .3 !important;
}

.u-opacity-40 {
  opacity: .4 !important;
}

.u-opacity-50 {
  opacity: .5 !important;
}

.u-opacity-60 {
  opacity: .6 !important;
}

.u-opacity-70 {
  opacity: .7 !important;
}

.u-opacity-80 {
  opacity: .8 !important;
}

.u-opacity-90 {
  opacity: .9 !important;
}

.u-opacity-100 {
  opacity: 1 !important;
}

@media screen and (min-width: 640px) {
  .u-opacity-0-sm {
    opacity: 0 !important;
  }

  .u-opacity-10-sm {
    opacity: .1 !important;
  }

  .u-opacity-20-sm {
    opacity: .2 !important;
  }

  .u-opacity-30-sm {
    opacity: .3 !important;
  }

  .u-opacity-40-sm {
    opacity: .4 !important;
  }

  .u-opacity-50-sm {
    opacity: .5 !important;
  }

  .u-opacity-60-sm {
    opacity: .6 !important;
  }

  .u-opacity-70-sm {
    opacity: .7 !important;
  }

  .u-opacity-80-sm {
    opacity: .8 !important;
  }

  .u-opacity-90-sm {
    opacity: .9 !important;
  }

  .u-opacity-100-sm {
    opacity: 1 !important;
  }
}

@media screen and (min-width: 768px) {
  .u-opacity-0-md {
    opacity: 0 !important;
  }

  .u-opacity-10-md {
    opacity: .1 !important;
  }

  .u-opacity-20-md {
    opacity: .2 !important;
  }

  .u-opacity-30-md {
    opacity: .3 !important;
  }

  .u-opacity-40-md {
    opacity: .4 !important;
  }

  .u-opacity-50-md {
    opacity: .5 !important;
  }

  .u-opacity-60-md {
    opacity: .6 !important;
  }

  .u-opacity-70-md {
    opacity: .7 !important;
  }

  .u-opacity-80-md {
    opacity: .8 !important;
  }

  .u-opacity-90-md {
    opacity: .9 !important;
  }

  .u-opacity-100-md {
    opacity: 1 !important;
  }
}

@media screen and (min-width: 1024px) {
  .u-opacity-0-lg {
    opacity: 0 !important;
  }

  .u-opacity-10-lg {
    opacity: .1 !important;
  }

  .u-opacity-20-lg {
    opacity: .2 !important;
  }

  .u-opacity-30-lg {
    opacity: .3 !important;
  }

  .u-opacity-40-lg {
    opacity: .4 !important;
  }

  .u-opacity-50-lg {
    opacity: .5 !important;
  }

  .u-opacity-60-lg {
    opacity: .6 !important;
  }

  .u-opacity-70-lg {
    opacity: .7 !important;
  }

  .u-opacity-80-lg {
    opacity: .8 !important;
  }

  .u-opacity-90-lg {
    opacity: .9 !important;
  }

  .u-opacity-100-lg {
    opacity: 1 !important;
  }
}

@media screen and (min-width: 1280px) {
  .u-opacity-0-xl {
    opacity: 0 !important;
  }

  .u-opacity-10-xl {
    opacity: .1 !important;
  }

  .u-opacity-20-xl {
    opacity: .2 !important;
  }

  .u-opacity-30-xl {
    opacity: .3 !important;
  }

  .u-opacity-40-xl {
    opacity: .4 !important;
  }

  .u-opacity-50-xl {
    opacity: .5 !important;
  }

  .u-opacity-60-xl {
    opacity: .6 !important;
  }

  .u-opacity-70-xl {
    opacity: .7 !important;
  }

  .u-opacity-80-xl {
    opacity: .8 !important;
  }

  .u-opacity-90-xl {
    opacity: .9 !important;
  }

  .u-opacity-100-xl {
    opacity: 1 !important;
  }
}

.u-bg-opacity-0 {
  --bg-opacity: 0;
}

.u-bg-opacity-10 {
  --bg-opacity: .1;
}

.u-bg-opacity-20 {
  --bg-opacity: .2;
}

.u-bg-opacity-30 {
  --bg-opacity: .3;
}

.u-bg-opacity-40 {
  --bg-opacity: .4;
}

.u-bg-opacity-50 {
  --bg-opacity: .5;
}

.u-bg-opacity-60 {
  --bg-opacity: .6;
}

.u-bg-opacity-70 {
  --bg-opacity: .7;
}

.u-bg-opacity-80 {
  --bg-opacity: .8;
}

.u-bg-opacity-90 {
  --bg-opacity: .9;
}

.u-bg-opacity-100 {
  --bg-opacity: 1;
}

.u-color-opacity-0 {
  --color-opacity: 0;
}

.u-color-opacity-10 {
  --color-opacity: .1;
}

.u-color-opacity-20 {
  --color-opacity: .2;
}

.u-color-opacity-30 {
  --color-opacity: .3;
}

.u-color-opacity-40 {
  --color-opacity: .4;
}

.u-color-opacity-50 {
  --color-opacity: .5;
}

.u-color-opacity-60 {
  --color-opacity: .6;
}

.u-color-opacity-70 {
  --color-opacity: .7;
}

.u-color-opacity-80 {
  --color-opacity: .8;
}

.u-color-opacity-90 {
  --color-opacity: .9;
}

.u-color-opacity-100 {
  --color-opacity: 1;
}

.u-border-opacity-0 {
  --border-opacity: 0;
}

.u-border-opacity-10 {
  --border-opacity: .1;
}

.u-border-opacity-20 {
  --border-opacity: .2;
}

.u-border-opacity-30 {
  --border-opacity: .3;
}

.u-border-opacity-40 {
  --border-opacity: .4;
}

.u-border-opacity-50 {
  --border-opacity: .5;
}

.u-border-opacity-60 {
  --border-opacity: .6;
}

.u-border-opacity-70 {
  --border-opacity: .7;
}

.u-border-opacity-80 {
  --border-opacity: .8;
}

.u-border-opacity-90 {
  --border-opacity: .9;
}

.u-border-opacity-100 {
  --border-opacity: 1;
}

.u-overflow-auto {
  overflow: auto !important;
}

.u-overflow-hidden {
  overflow: hidden !important;
}

.u-overflow-visible {
  overflow: visible !important;
}

.u-overflow-scroll {
  overflow: scroll !important;
}

@media screen and (min-width: 640px) {
  .u-overflow-auto-sm {
    overflow: auto !important;
  }

  .u-overflow-hidden-sm {
    overflow: hidden !important;
  }

  .u-overflow-visible-sm {
    overflow: visible !important;
  }

  .u-overflow-scroll-sm {
    overflow: scroll !important;
  }
}

@media screen and (min-width: 768px) {
  .u-overflow-auto-md {
    overflow: auto !important;
  }

  .u-overflow-hidden-md {
    overflow: hidden !important;
  }

  .u-overflow-visible-md {
    overflow: visible !important;
  }

  .u-overflow-scroll-md {
    overflow: scroll !important;
  }
}

@media screen and (min-width: 1024px) {
  .u-overflow-auto-lg {
    overflow: auto !important;
  }

  .u-overflow-hidden-lg {
    overflow: hidden !important;
  }

  .u-overflow-visible-lg {
    overflow: visible !important;
  }

  .u-overflow-scroll-lg {
    overflow: scroll !important;
  }
}

@media screen and (min-width: 1280px) {
  .u-overflow-auto-xl {
    overflow: auto !important;
  }

  .u-overflow-hidden-xl {
    overflow: hidden !important;
  }

  .u-overflow-visible-xl {
    overflow: visible !important;
  }

  .u-overflow-scroll-xl {
    overflow: scroll !important;
  }
}

.u-overflow-x-auto {
  overflow-x: auto !important;
}

.u-overflow-x-hidden {
  overflow-x: hidden !important;
}

.u-overflow-x-visible {
  overflow-x: visible !important;
}

.u-overflow-x-scroll {
  overflow-x: scroll !important;
}

@media screen and (min-width: 640px) {
  .u-overflow-x-auto-sm {
    overflow-x: auto !important;
  }

  .u-overflow-x-hidden-sm {
    overflow-x: hidden !important;
  }

  .u-overflow-x-visible-sm {
    overflow-x: visible !important;
  }

  .u-overflow-x-scroll-sm {
    overflow-x: scroll !important;
  }
}

@media screen and (min-width: 768px) {
  .u-overflow-x-auto-md {
    overflow-x: auto !important;
  }

  .u-overflow-x-hidden-md {
    overflow-x: hidden !important;
  }

  .u-overflow-x-visible-md {
    overflow-x: visible !important;
  }

  .u-overflow-x-scroll-md {
    overflow-x: scroll !important;
  }
}

@media screen and (min-width: 1024px) {
  .u-overflow-x-auto-lg {
    overflow-x: auto !important;
  }

  .u-overflow-x-hidden-lg {
    overflow-x: hidden !important;
  }

  .u-overflow-x-visible-lg {
    overflow-x: visible !important;
  }

  .u-overflow-x-scroll-lg {
    overflow-x: scroll !important;
  }
}

@media screen and (min-width: 1280px) {
  .u-overflow-x-auto-xl {
    overflow-x: auto !important;
  }

  .u-overflow-x-hidden-xl {
    overflow-x: hidden !important;
  }

  .u-overflow-x-visible-xl {
    overflow-x: visible !important;
  }

  .u-overflow-x-scroll-xl {
    overflow-x: scroll !important;
  }
}

.u-overflow-y-auto {
  overflow-y: auto !important;
}

.u-overflow-y-hidden {
  overflow-y: hidden !important;
}

.u-overflow-y-visible {
  overflow-y: visible !important;
}

.u-overflow-y-scroll {
  overflow-y: scroll !important;
}

@media screen and (min-width: 640px) {
  .u-overflow-y-auto-sm {
    overflow-y: auto !important;
  }

  .u-overflow-y-hidden-sm {
    overflow-y: hidden !important;
  }

  .u-overflow-y-visible-sm {
    overflow-y: visible !important;
  }

  .u-overflow-y-scroll-sm {
    overflow-y: scroll !important;
  }
}

@media screen and (min-width: 768px) {
  .u-overflow-y-auto-md {
    overflow-y: auto !important;
  }

  .u-overflow-y-hidden-md {
    overflow-y: hidden !important;
  }

  .u-overflow-y-visible-md {
    overflow-y: visible !important;
  }

  .u-overflow-y-scroll-md {
    overflow-y: scroll !important;
  }
}

@media screen and (min-width: 1024px) {
  .u-overflow-y-auto-lg {
    overflow-y: auto !important;
  }

  .u-overflow-y-hidden-lg {
    overflow-y: hidden !important;
  }

  .u-overflow-y-visible-lg {
    overflow-y: visible !important;
  }

  .u-overflow-y-scroll-lg {
    overflow-y: scroll !important;
  }
}

@media screen and (min-width: 1280px) {
  .u-overflow-y-auto-xl {
    overflow-y: auto !important;
  }

  .u-overflow-y-hidden-xl {
    overflow-y: hidden !important;
  }

  .u-overflow-y-visible-xl {
    overflow-y: visible !important;
  }

  .u-overflow-y-scroll-xl {
    overflow-y: scroll !important;
  }
}

.u-static {
  position: static !important;
}

.u-fixed {
  position: fixed !important;
}

.u-absolute {
  position: absolute !important;
}

.u-relative {
  position: relative !important;
}

.u-sticky {
  position: sticky !important;
}

@media screen and (min-width: 640px) {
  .u-static-sm {
    position: static !important;
  }

  .u-fixed-sm {
    position: fixed !important;
  }

  .u-absolute-sm {
    position: absolute !important;
  }

  .u-relative-sm {
    position: relative !important;
  }

  .u-sticky-sm {
    position: sticky !important;
  }
}

@media screen and (min-width: 768px) {
  .u-static-md {
    position: static !important;
  }

  .u-fixed-md {
    position: fixed !important;
  }

  .u-absolute-md {
    position: absolute !important;
  }

  .u-relative-md {
    position: relative !important;
  }

  .u-sticky-md {
    position: sticky !important;
  }
}

@media screen and (min-width: 1024px) {
  .u-static-lg {
    position: static !important;
  }

  .u-fixed-lg {
    position: fixed !important;
  }

  .u-absolute-lg {
    position: absolute !important;
  }

  .u-relative-lg {
    position: relative !important;
  }

  .u-sticky-lg {
    position: sticky !important;
  }
}

@media screen and (min-width: 1280px) {
  .u-static-xl {
    position: static !important;
  }

  .u-fixed-xl {
    position: fixed !important;
  }

  .u-absolute-xl {
    position: absolute !important;
  }

  .u-relative-xl {
    position: relative !important;
  }

  .u-sticky-xl {
    position: sticky !important;
  }
}

.u-shadow-none {
  --cirrus-shadow: 0 0 #000;
  box-shadow: var(--cirrus-shadow);
}

.u-shadow-xs {
  --cirrus-shadow: 0 1px 2px 0 #0000000d;
  box-shadow: var(--cirrus-shadow);
}

.u-shadow-sm {
  --cirrus-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px 0 #0000000f;
  box-shadow: var(--cirrus-shadow);
}

.u-shadow-md {
  --cirrus-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -1px #0000000f;
  box-shadow: var(--cirrus-shadow);
}

.u-shadow-lg {
  --cirrus-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -2px #0000000d;
  box-shadow: var(--cirrus-shadow);
}

.u-shadow-xl {
  --cirrus-shadow: 0 20px 25px -5px #0000001a, 0 10px 10px -5px #0000000a;
  box-shadow: var(--cirrus-shadow);
}

.u-shadow-inset {
  --cirrus-shadow: inset 0 2px 4px 0 #0000000f;
  box-shadow: var(--cirrus-shadow);
}

.u-duration-75 {
  transition-duration: 75ms !important;
}

.u-duration-100 {
  transition-duration: .1s !important;
}

.u-duration-200 {
  transition-duration: .2s !important;
}

.u-duration-300 {
  transition-duration: .3s !important;
}

.u-duration-500 {
  transition-duration: .5s !important;
}

.u-duration-700 {
  transition-duration: .7s !important;
}

.u-duration-1000 {
  transition-duration: 1s !important;
}

.u-z-n1 {
  z-index: -1 !important;
}

.u-z-0 {
  z-index: 0 !important;
}

.u-z-1 {
  z-index: 1 !important;
}

.u-z-10 {
  z-index: 10 !important;
}

.u-z-20 {
  z-index: 20 !important;
}

.u-z-30 {
  z-index: 30 !important;
}

.u-z-40 {
  z-index: 40 !important;
}

.u-z-50 {
  z-index: 50 !important;
}

.u-z-auto {
  z-index: auto !important;
}

details.accordion {
  border-bottom: 1px solid #dee2e6;
  padding: 1rem;
  display: block;
}

details.accordion summary.accordion__summary {
  cursor: pointer;
  opacity: .75;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
  list-style-type: none;
  transition: all .25s;
  display: flex;
}

details.accordion summary.accordion__summary:after {
  content: "";
  float: right;
  height: 1rem;
  width: 1rem;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='rgb(65, 84, 98)' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
  background-position: 100%;
  background-repeat: no-repeat;
  background-size: 1rem;
  display: block;
  transform: rotate(-90deg);
}

details.accordion[open] summary.accordion__summary {
  opacity: 1;
  margin-bottom: 1rem;
}

details.accordion[open] summary.accordion__summary:after {
  transform: rotate(0);
}

.avatar {
  width: 3.2rem;
  height: 3.2rem;
  background-color: #f03d4d;
  border-radius: 50%;
  margin: auto;
  font-size: 1.5rem;
  font-weight: lighter;
  display: block;
  position: relative;
  overflow: hidden;
}

.avatar:before {
  content: attr(data-text);
  color: currentColor;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.avatar.avatar--xs {
  width: 1.6rem;
  height: 1.6rem;
  font-size: .8rem;
}

.avatar.avatar--sm {
  width: 2.4rem;
  height: 2.4rem;
  font-size: 1rem;
}

.avatar.avatar--lg {
  width: 4.8rem;
  height: 4.8rem;
  font-size: 2rem;
}

.avatar.avatar--xl {
  width: 6.4rem;
  height: 6.4rem;
  font-size: 3rem;
}

.avatar img.padded {
  width: 100%;
  padding: .5rem;
}

.breadcrumb {
  white-space: nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  margin: .5rem 0;
  padding: .5rem 0;
  list-style: none;
  display: flex;
}

.breadcrumb.breadcrumb--center {
  justify-content: center;
}

.breadcrumb.breadcrumb--right {
  justify-content: flex-end;
}

.breadcrumb.breadcrumb--arrow .breadcrumb__item:not(:first-child):before {
  content: "→";
}

.breadcrumb.breadcrumb--bullet .breadcrumb__item:not(:first-child):before {
  content: "•";
}

.breadcrumb.breadcrumb--dot .breadcrumb__item:not(:first-child):before {
  content: "·";
}

.breadcrumb.breadcrumb--gt .breadcrumb__item:not(:first-child):before {
  content: "≻";
}

.breadcrumb .breadcrumb__item {
  margin: 0;
}

.breadcrumb .breadcrumb__item:not(:last-child) {
  margin-right: .5rem;
}

.breadcrumb .breadcrumb__item:not(:first-child):before {
  content: "/";
  padding-right: .5rem;
}

.breadcrumb .breadcrumb__item.breadcrumb__item--active {
  cursor: default;
  pointer-events: none;
}

.breadcrumb .breadcrumb__item.breadcrumb__item--active a {
  color: #0f172a;
}

.card {
  backface-visibility: hidden;
  background-color: #fff;
  border-radius: 5px;
  margin-bottom: 1rem;
  transition: all .3s;
  position: relative;
  overflow: hidden;
  box-shadow: 0 5px 12px #2a33531f, 0 0 5px #0000000f;
}

.card:hover {
  transition: all .3s;
  box-shadow: 0 8px 20px #2a33531f, 0 5px 5px #0000000f;
}

.card:hover .card-image:after {
  opacity: 0;
}

.card.slide-up, .card.card--slide-up {
  max-height: 550px;
  flex-direction: column;
  display: flex;
}

.card.slide-up:hover .card-body, .card.slide-up:hover .card__body, .card.card--slide-up:hover .card-body, .card.card--slide-up:hover .card__body {
  opacity: 1;
}

.card.slide-up:hover .card-image, .card.slide-up:hover .card__image, .card.card--slide-up:hover .card-image, .card.card--slide-up:hover .card__image {
  transform: translateY(-40px);
}

.card.slide-up:hover .mobile-title, .card.slide-up:hover .card__mobile-title, .card.card--slide-up:hover .mobile-title, .card.card--slide-up:hover .card__mobile-title {
  transition: all .3s ease-in-out;
  bottom: 35%;
}

.card.slide-up .mobile-title, .card.slide-up .card__mobile-title, .card.card--slide-up .mobile-title, .card.card--slide-up .card__mobile-title {
  background-color: var(--cirrus-bg);
  width: 100%;
  backface-visibility: hidden;
  padding: 1.5rem 0 0;
  transition: all .3s ease-in-out;
  position: absolute;
  bottom: 5rem;
  left: 0;
}

.card.slide-up .card-body, .card.slide-up .card__body, .card.card--slide-up .card-body, .card.card--slide-up .card__body {
  opacity: 0;
  transition: all var(--animation-duration) ease-in-out;
  overflow: auto;
}

.card.u-flex .content {
  flex-grow: 1;
}

.card p {
  margin: 1rem 0;
}

.card .card-image, .card .card__image {
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 5px 5px 0 0;
  transition: all .3s ease-in-out;
  position: absolute;
  inset: 0;
}

.card .card-image:after, .card .card__image:after {
  content: "";
  background-color: #0000001a;
  transition: all .5s;
  display: block;
  position: absolute;
  inset: 0;
}

.card .card-container, .card .card__container {
  height: 40%;
  min-height: 21rem;
  display: block;
  position: relative;
}

.card .title-container, .card .card__title-container {
  width: 100%;
  padding: 0 1rem;
  position: absolute;
  bottom: 1rem;
}

.card .title-container .title, .card .title-container .subtitle, .card .card__title-container .title, .card .card__title-container .subtitle {
  color: #fff;
  margin: 1rem auto;
}

.card .title-container .title, .card .card__title-container .title {
  text-shadow: 0 1px 2px #00000040;
  margin-bottom: 0;
  font-size: 1.5rem;
  font-weight: 300;
}

.card .action-bar, .card .card__action-bar {
  -webkit-user-select: none;
  user-select: none;
  box-sizing: border-box;
  border-top: 1px solid #e9ecef;
  margin: 0;
  padding: .5rem;
  transition: left .2s cubic-bezier(.075, .82, .165, 1);
}

.card .action-bar .btn, .card .action-bar button, .card .action-bar [type="submit"], .card .action-bar [type="reset"], .card .action-bar [type="button"], .card .card__action-bar .btn, .card .card__action-bar button, .card .card__action-bar [type="submit"], .card .card__action-bar [type="reset"], .card .card__action-bar [type="button"] {
  margin: 0 .5rem;
}

.card .action-bar + .card-footer, .card .action-bar + .card__footer, .card .card__action-bar + .card-footer, .card .card__action-bar + .card__footer {
  border-top: 1px solid #e9ecef;
  padding: 1rem 0;
}

.card .card-footer, .card .card__footer {
  color: #868e96;
  font-size: .875rem;
  position: relative;
}

.card .card-head, .card .card__header {
  border-bottom: 1px solid #e9ecef;
  align-items: stretch;
  display: flex;
}

.form-ext-control {
  padding-left: 1.5rem;
  position: relative;
}

.form-ext-control.form-ext-checkbox .form-ext-input:checked ~ .form-ext-label:after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
}

.form-ext-control.form-ext-checkbox .form-ext-label:before {
  border-radius: .25rem;
}

.form-ext-control.form-ext-radio .form-ext-input:checked ~ .form-ext-label:after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23fff'/%3E%3C/svg%3E");
}

.form-ext-control.form-ext-radio .form-ext-label:before {
  border-radius: 50%;
}

.form-ext-control .form-ext-input {
  opacity: 0;
  z-index: -1;
  position: absolute;
}

.form-ext-control .form-ext-input:disabled ~ .form-ext-label {
  opacity: .4;
}

.form-ext-control .form-ext-input:checked ~ .form-ext-label:before {
  background-color: #f03d4d;
}

.form-ext-control .form-ext-input.form-ext-input--primary:checked ~ .form-ext-label {
  color: #f03d4d;
}

.form-ext-control .form-ext-input.form-ext-input--primary:checked ~ .form-ext-label:before {
  background-color: #f03d4d;
}

.form-ext-control .form-ext-input.form-ext-input--primary:focus ~ .form-ext-label:before {
  border-color: inherit;
  box-shadow: 0 0 0 .2rem #f03d4d8c, inset 0 1px 8px #00000012;
}

.form-ext-control .form-ext-input.form-ext-input--dark:checked ~ .form-ext-label {
  color: #363636;
}

.form-ext-control .form-ext-input.form-ext-input--dark:checked ~ .form-ext-label:before {
  background-color: #363636;
}

.form-ext-control .form-ext-input.form-ext-input--dark:focus ~ .form-ext-label:before {
  border-color: inherit;
  box-shadow: 0 0 0 .2rem #3636368c, inset 0 1px 8px #00000012;
}

.form-ext-control .form-ext-input.form-ext-input--link:checked ~ .form-ext-label {
  color: #5e5cc7;
}

.form-ext-control .form-ext-input.form-ext-input--link:checked ~ .form-ext-label:before {
  background-color: #5e5cc7;
}

.form-ext-control .form-ext-input.form-ext-input--link:focus ~ .form-ext-label:before {
  border-color: inherit;
  box-shadow: 0 0 0 .2rem #5e5cc78c, inset 0 1px 8px #00000012;
}

.form-ext-control .form-ext-input.form-ext-input--info:checked ~ .form-ext-label {
  color: #2972fa;
}

.form-ext-control .form-ext-input.form-ext-input--info:checked ~ .form-ext-label:before {
  background-color: #2972fa;
}

.form-ext-control .form-ext-input.form-ext-input--info:focus ~ .form-ext-label:before {
  border-color: inherit;
  box-shadow: 0 0 0 .2rem #2972fa8c, inset 0 1px 8px #00000012;
}

.form-ext-control .form-ext-input.form-ext-input--success:checked ~ .form-ext-label {
  color: #0dd157;
}

.form-ext-control .form-ext-input.form-ext-input--success:checked ~ .form-ext-label:before {
  background-color: #0dd157;
}

.form-ext-control .form-ext-input.form-ext-input--success:focus ~ .form-ext-label:before {
  border-color: inherit;
  box-shadow: 0 0 0 .2rem #0dd1578c, inset 0 1px 8px #00000012;
}

.form-ext-control .form-ext-input.form-ext-input--warning:checked ~ .form-ext-label {
  color: #fab633;
}

.form-ext-control .form-ext-input.form-ext-input--warning:checked ~ .form-ext-label:before {
  background-color: #fab633;
}

.form-ext-control .form-ext-input.form-ext-input--warning:focus ~ .form-ext-label:before {
  border-color: inherit;
  box-shadow: 0 0 0 .2rem #fab6338c, inset 0 1px 8px #00000012;
}

.form-ext-control .form-ext-input.form-ext-input--danger:checked ~ .form-ext-label {
  color: #fb4143;
}

.form-ext-control .form-ext-input.form-ext-input--danger:checked ~ .form-ext-label:before {
  background-color: #fb4143;
}

.form-ext-control .form-ext-input.form-ext-input--danger:focus ~ .form-ext-label:before {
  border-color: inherit;
  box-shadow: 0 0 0 .2rem #fb41438c, inset 0 1px 8px #00000012;
}

.form-ext-control .form-ext-input:disabled ~ .form-ext-toggle__toggler {
  opacity: .5;
}

.form-ext-control .form-ext-toggle__label {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.form-ext-control .form-ext-toggle {
  cursor: pointer;
  position: relative;
}

.form-ext-control .form-ext-toggle input[type="checkbox"], .form-ext-control .form-ext-toggle input[type="radio"] {
  opacity: 0;
  z-index: -1;
  position: absolute;
}

.form-ext-control .form-ext-toggle .form-ext-toggle__toggler {
  color: #d5d7dc;
  height: 1.5rem;
  width: 3rem;
  border: 1px solid #d5d7dc;
  border-radius: 6.25rem;
  font-size: 9px;
  display: block;
  position: relative;
}

.form-ext-control .form-ext-toggle .form-ext-toggle__toggler i {
  display: inline-block;
}

.form-ext-control .form-ext-toggle input[type="checkbox"]:checked + .form-ext-toggle__toggler, .form-ext-control .form-ext-toggle input[type="checkbox"]:checked + * .form-ext-toggle__toggler, .form-ext-control .form-ext-toggle input[type="radio"]:checked + .form-ext-toggle__toggler, .form-ext-control .form-ext-toggle input[type="radio"]:checked + * .form-ext-toggle__toggler {
  color: #fff;
  background-color: #f03d4d;
  border-color: #f03d4d;
  transition: all .4s;
  position: relative;
}

.form-ext-control .form-ext-toggle input[type="checkbox"]:checked + .form-ext-toggle__toggler i:after, .form-ext-control .form-ext-toggle input[type="checkbox"]:checked + * .form-ext-toggle__toggler i:after, .form-ext-control .form-ext-toggle input[type="radio"]:checked + .form-ext-toggle__toggler i:after, .form-ext-control .form-ext-toggle input[type="radio"]:checked + * .form-ext-toggle__toggler i:after {
  background-color: #fff;
  left: calc(100% - 20px);
}

.form-ext-control .form-ext-toggle input[type="checkbox"]:checked + .form-ext-toggle__toggler i:before, .form-ext-control .form-ext-toggle input[type="checkbox"]:checked + * .form-ext-toggle__toggler i:before, .form-ext-control .form-ext-toggle input[type="radio"]:checked + .form-ext-toggle__toggler i:before, .form-ext-control .form-ext-toggle input[type="radio"]:checked + * .form-ext-toggle__toggler i:before {
  color: #fff;
  content: attr(data-check-icon);
  text-align: left;
}

.form-ext-control .form-ext-toggle.form-ext-toggle--primary input[type="checkbox"]:checked + .form-ext-toggle__toggler, .form-ext-control .form-ext-toggle.form-ext-toggle--primary input[type="checkbox"]:checked + * .form-ext-toggle__toggler, .form-ext-control .form-ext-toggle.form-ext-toggle--primary input[type="radio"]:checked + .form-ext-toggle__toggler, .form-ext-control .form-ext-toggle.form-ext-toggle--primary input[type="radio"]:checked + * .form-ext-toggle__toggler {
  background-color: #f03d4d;
  border-color: #f03d4d;
}

.form-ext-control .form-ext-toggle .form-ext-toggle--primary .form-ext-toggle__toggler {
  color: #f03d4d;
  border-color: #f03d4d;
}

.form-ext-control .form-ext-toggle .form-ext-toggle--primary .form-ext-toggle__toggler i:after {
  background-color: #f03d4d;
}

.form-ext-control .form-ext-toggle .form-ext-input:focus + .form-ext-toggle__toggler, .form-ext-control .form-ext-toggle .form-ext-input:focus ~ .form-ext-label:before, .form-ext-control .form-ext-toggle.form-ext-toggle--primary .form-ext-input:focus + .form-ext-toggle__toggler {
  box-shadow: 0 0 0 .2rem #f03d4d8c, inset 0 1px 8px #00000012;
}

.form-ext-control .form-ext-toggle.form-ext-toggle--dark input[type="checkbox"]:checked + .form-ext-toggle__toggler, .form-ext-control .form-ext-toggle.form-ext-toggle--dark input[type="checkbox"]:checked + * .form-ext-toggle__toggler, .form-ext-control .form-ext-toggle.form-ext-toggle--dark input[type="radio"]:checked + .form-ext-toggle__toggler, .form-ext-control .form-ext-toggle.form-ext-toggle--dark input[type="radio"]:checked + * .form-ext-toggle__toggler {
  background-color: #363636;
  border-color: #363636;
}

.form-ext-control .form-ext-toggle .form-ext-toggle--dark .form-ext-toggle__toggler {
  color: #363636;
  border-color: #363636;
}

.form-ext-control .form-ext-toggle .form-ext-toggle--dark .form-ext-toggle__toggler i:after {
  background-color: #363636;
}

.form-ext-control .form-ext-toggle .form-ext-input:focus + .form-ext-toggle__toggler, .form-ext-control .form-ext-toggle .form-ext-input:focus ~ .form-ext-label:before, .form-ext-control .form-ext-toggle.form-ext-toggle--dark .form-ext-input:focus + .form-ext-toggle__toggler {
  box-shadow: 0 0 0 .2rem #3636368c, inset 0 1px 8px #00000012;
}

.form-ext-control .form-ext-toggle.form-ext-toggle--link input[type="checkbox"]:checked + .form-ext-toggle__toggler, .form-ext-control .form-ext-toggle.form-ext-toggle--link input[type="checkbox"]:checked + * .form-ext-toggle__toggler, .form-ext-control .form-ext-toggle.form-ext-toggle--link input[type="radio"]:checked + .form-ext-toggle__toggler, .form-ext-control .form-ext-toggle.form-ext-toggle--link input[type="radio"]:checked + * .form-ext-toggle__toggler {
  background-color: #5e5cc7;
  border-color: #5e5cc7;
}

.form-ext-control .form-ext-toggle .form-ext-toggle--link .form-ext-toggle__toggler {
  color: #5e5cc7;
  border-color: #5e5cc7;
}

.form-ext-control .form-ext-toggle .form-ext-toggle--link .form-ext-toggle__toggler i:after {
  background-color: #5e5cc7;
}

.form-ext-control .form-ext-toggle .form-ext-input:focus + .form-ext-toggle__toggler, .form-ext-control .form-ext-toggle .form-ext-input:focus ~ .form-ext-label:before, .form-ext-control .form-ext-toggle.form-ext-toggle--link .form-ext-input:focus + .form-ext-toggle__toggler {
  box-shadow: 0 0 0 .2rem #5e5cc78c, inset 0 1px 8px #00000012;
}

.form-ext-control .form-ext-toggle.form-ext-toggle--info input[type="checkbox"]:checked + .form-ext-toggle__toggler, .form-ext-control .form-ext-toggle.form-ext-toggle--info input[type="checkbox"]:checked + * .form-ext-toggle__toggler, .form-ext-control .form-ext-toggle.form-ext-toggle--info input[type="radio"]:checked + .form-ext-toggle__toggler, .form-ext-control .form-ext-toggle.form-ext-toggle--info input[type="radio"]:checked + * .form-ext-toggle__toggler {
  background-color: #2972fa;
  border-color: #2972fa;
}

.form-ext-control .form-ext-toggle .form-ext-toggle--info .form-ext-toggle__toggler {
  color: #2972fa;
  border-color: #2972fa;
}

.form-ext-control .form-ext-toggle .form-ext-toggle--info .form-ext-toggle__toggler i:after {
  background-color: #2972fa;
}

.form-ext-control .form-ext-toggle .form-ext-input:focus + .form-ext-toggle__toggler, .form-ext-control .form-ext-toggle .form-ext-input:focus ~ .form-ext-label:before, .form-ext-control .form-ext-toggle.form-ext-toggle--info .form-ext-input:focus + .form-ext-toggle__toggler {
  box-shadow: 0 0 0 .2rem #2972fa8c, inset 0 1px 8px #00000012;
}

.form-ext-control .form-ext-toggle.form-ext-toggle--success input[type="checkbox"]:checked + .form-ext-toggle__toggler, .form-ext-control .form-ext-toggle.form-ext-toggle--success input[type="checkbox"]:checked + * .form-ext-toggle__toggler, .form-ext-control .form-ext-toggle.form-ext-toggle--success input[type="radio"]:checked + .form-ext-toggle__toggler, .form-ext-control .form-ext-toggle.form-ext-toggle--success input[type="radio"]:checked + * .form-ext-toggle__toggler {
  background-color: #0dd157;
  border-color: #0dd157;
}

.form-ext-control .form-ext-toggle .form-ext-toggle--success .form-ext-toggle__toggler {
  color: #0dd157;
  border-color: #0dd157;
}

.form-ext-control .form-ext-toggle .form-ext-toggle--success .form-ext-toggle__toggler i:after {
  background-color: #0dd157;
}

.form-ext-control .form-ext-toggle .form-ext-input:focus + .form-ext-toggle__toggler, .form-ext-control .form-ext-toggle .form-ext-input:focus ~ .form-ext-label:before, .form-ext-control .form-ext-toggle.form-ext-toggle--success .form-ext-input:focus + .form-ext-toggle__toggler {
  box-shadow: 0 0 0 .2rem #0dd1578c, inset 0 1px 8px #00000012;
}

.form-ext-control .form-ext-toggle.form-ext-toggle--warning input[type="checkbox"]:checked + .form-ext-toggle__toggler, .form-ext-control .form-ext-toggle.form-ext-toggle--warning input[type="checkbox"]:checked + * .form-ext-toggle__toggler, .form-ext-control .form-ext-toggle.form-ext-toggle--warning input[type="radio"]:checked + .form-ext-toggle__toggler, .form-ext-control .form-ext-toggle.form-ext-toggle--warning input[type="radio"]:checked + * .form-ext-toggle__toggler {
  background-color: #fab633;
  border-color: #fab633;
}

.form-ext-control .form-ext-toggle .form-ext-toggle--warning .form-ext-toggle__toggler {
  color: #fab633;
  border-color: #fab633;
}

.form-ext-control .form-ext-toggle .form-ext-toggle--warning .form-ext-toggle__toggler i:after {
  background-color: #fab633;
}

.form-ext-control .form-ext-toggle .form-ext-input:focus + .form-ext-toggle__toggler, .form-ext-control .form-ext-toggle .form-ext-input:focus ~ .form-ext-label:before, .form-ext-control .form-ext-toggle.form-ext-toggle--warning .form-ext-input:focus + .form-ext-toggle__toggler {
  box-shadow: 0 0 0 .2rem #fab6338c, inset 0 1px 8px #00000012;
}

.form-ext-control .form-ext-toggle.form-ext-toggle--danger input[type="checkbox"]:checked + .form-ext-toggle__toggler, .form-ext-control .form-ext-toggle.form-ext-toggle--danger input[type="checkbox"]:checked + * .form-ext-toggle__toggler, .form-ext-control .form-ext-toggle.form-ext-toggle--danger input[type="radio"]:checked + .form-ext-toggle__toggler, .form-ext-control .form-ext-toggle.form-ext-toggle--danger input[type="radio"]:checked + * .form-ext-toggle__toggler {
  background-color: #fb4143;
  border-color: #fb4143;
}

.form-ext-control .form-ext-toggle .form-ext-toggle--danger .form-ext-toggle__toggler {
  color: #fb4143;
  border-color: #fb4143;
}

.form-ext-control .form-ext-toggle .form-ext-toggle--danger .form-ext-toggle__toggler i:after {
  background-color: #fb4143;
}

.form-ext-control .form-ext-toggle .form-ext-input:focus + .form-ext-toggle__toggler, .form-ext-control .form-ext-toggle .form-ext-input:focus ~ .form-ext-label:before, .form-ext-control .form-ext-toggle.form-ext-toggle--danger .form-ext-input:focus + .form-ext-toggle__toggler {
  box-shadow: 0 0 0 .2rem #fb41438c, inset 0 1px 8px #00000012;
}

.form-ext-control .form-ext-toggle .form-ext-toggle__toggler i:before, .form-ext-control .form-ext-toggle .form-ext-toggle__toggler i:after {
  content: "";
  display: block;
  position: absolute;
}

.form-ext-control .form-ext-toggle .form-ext-toggle__toggler i:before {
  content: attr(data-uncheck-icon);
  text-align: right;
  width: 55%;
  padding: 2px 7px;
  font-size: 12px;
  line-height: 18px;
  top: 0;
}

.form-ext-control .form-ext-toggle .form-ext-toggle__toggler i:after {
  height: 16px;
  width: 16px;
  transition: left var(--animation-duration) ease;
  text-align: left;
  background-color: #d5d7dc;
  border-radius: 50%;
  top: 50%;
  left: 4px;
  transform: translateY(-50%);
}

.form-ext-control .form-ext-label {
  margin-bottom: 0;
  position: relative;
}

.form-ext-control .form-ext-label:before, .form-ext-control .form-ext-label:after {
  content: "";
  height: 1rem;
  transition: all var(--animation-duration);
  width: 1rem;
  display: block;
  position: absolute;
  top: .3rem;
  left: -1.5rem;
}

.form-ext-control .form-ext-label:before {
  pointer-events: none;
  -webkit-user-select: none;
  user-select: none;
  box-sizing: border-box;
  background-color: #f6f9fc;
  border: 1px solid #dee2e6;
  border-radius: .25rem;
}

.form-ext-control .form-ext-label:after {
  background-position: center;
  background-repeat: no-repeat;
  background-size: 50% 50%;
}

.form-ext-control .form-ext-input:checked ~ .form-ext-label:before {
  border: none;
}

.u, .utb {
  display: inline;
  position: relative;
}

.u:after {
  content: "";
  backface-visibility: hidden;
  height: 2px;
  width: 0;
  background: #4643e2;
  transition: all .3s;
  position: absolute;
  bottom: -.25em;
}

.u:hover:after {
  width: 100%;
}

.u.u-LR:after {
  left: 0;
}

.u.u-RL:after {
  right: 0;
}

.u.u-RL:hover:after {
  width: 100%;
}

.u.u-C:after {
  left: 50%;
  transform: translateX(-50%);
}

.utb:before, .utb:after {
  content: "";
  backface-visibility: hidden;
  height: 2px;
  width: 0;
  background: #4643e2;
  transition: all .3s;
  position: absolute;
}

.utb:before {
  top: -.25em;
}

.utb:after {
  bottom: -.25em;
}

.utb:hover:before, .utb:hover:after {
  width: 100%;
}

.utb.utb-LR:before, .utb.utb-LR:after {
  left: 0;
}

.utb.utb-RL:before, .utb.utb-RL:after {
  right: 0;
}

.utb.utb-C:before, .utb.utb-C:after {
  left: 50%;
  transform: translateX(-50%);
}

.utb.utb-OLR:before {
  left: 0;
}

.utb.utb-OLR:after, .utb.utb-ORL:before {
  right: 0;
}

.utb.utb-ORL:after {
  left: 0;
}

.usquare {
  margin-left: .4rem;
  position: relative;
  overflow: hidden;
}

.usquare:before, .usquare:after {
  content: "";
  backface-visibility: hidden;
  width: 2px;
  width: .1 rem;
  height: 0;
  background: #4643e2;
  transition: all .25s;
  position: absolute;
}

.usquare:before {
  bottom: -.2rem;
  left: 0;
}

.usquare:after {
  top: -.2rem;
  right: 0;
}

.usquare:hover:before, .usquare:hover:after {
  height: calc(100% + .4rem);
}

.usquare:hover a:before, .usquare:hover a:after {
  width: 100%;
}

.usquare.delay:before {
  transition-delay: .6s;
}

.usquare.delay:after {
  transition-delay: var(--animation-duration);
}

.usquare.delay a:after {
  transition: all .25s .4s;
}

.usquare a {
  padding: .2rem .4rem;
  transition: all .25s;
  position: static;
}

.usquare a:before {
  left: 0;
}

.usquare a:after {
  right: 0;
}

.modal {
  opacity: 0;
  pointer-events: none;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  display: none;
  position: fixed;
  inset: 0;
}

.modal:target, .modal.modal--visible {
  opacity: 1;
  z-index: 999;
  pointer-events: auto;
  display: flex;
}

.modal:target .modal-overlay, .modal.modal--visible .modal-overlay {
  background-color: #36363680;
  display: block;
  position: absolute;
  inset: 0;
}

.modal:target .modal-container, .modal:target .modal-container, .modal.modal--visible .modal-container, .modal.modal--visible .modal-container {
  animation: slide-down var(--animation-duration) ease 1;
  z-index: 1;
}

.modal:target.modal-animated--zoom-in, .modal:target.modal-animated--zoom-out, .modal.modal--visible.modal-animated--zoom-in, .modal.modal--visible.modal-animated--zoom-out {
  opacity: 1;
  transition: all .3s;
}

.modal:target.modal-animated--zoom-in .modal-content, .modal:target.modal-animated--zoom-out .modal-content, .modal.modal--visible.modal-animated--zoom-in .modal-content, .modal.modal--visible.modal-animated--zoom-out .modal-content {
  transition: all .3s;
  transform: scale(1);
}

.modal.modal-small .modal-content {
  max-width: 20rem;
}

.modal.modal-large .modal-content {
  max-width: 60rem;
}

.modal .modal-content {
  background-color: var(--cirrus-bg);
  z-index: 1;
  color: var(--cirrus-fg);
  max-width: 40rem;
  border-radius: 3px;
  padding: 0;
  display: block;
  box-shadow: 0 .4rem 1rem #3636364d;
}

.modal .modal-content.small {
  max-width: 32rem;
}

.modal .modal-content h1, .modal .modal-content h2, .modal .modal-content h3, .modal .modal-content h4, .modal .modal-content h5, .modal .modal-content h6 {
  color: var(--cirrus-fg);
}

.modal .modal-content .modal-header {
  padding: 1rem 2.5rem;
}

.modal .modal-content .modal-header .modal-title {
  font-size: 1.4rem;
  font-weight: bolder;
}

.modal .modal-content .modal-body {
  max-height: 50vh;
  padding: 1rem 2.5rem;
  position: relative;
  overflow-y: auto;
}

.modal .modal-content .modal-footer {
  padding: 1rem 2.5rem;
}

@media screen and (max-width: 767px) {
  .modal .modal-content {
    max-width: 90%;
  }
}

.modal.modal-animated--dropdown {
  animation: slide-down var(--animation-duration) ease 1;
}

.modal.modal-animated--zoom-in, .modal.modal-animated--zoom-out {
  opacity: 0;
  transition: all .3s;
  display: flex;
}

.modal.modal-animated--zoom-in .modal-content {
  transition: all .3s;
  transform: scale(.8);
}

.modal.modal-animated--zoom-out .modal-content {
  transition: all .3s;
  transform: scale(1.2);
}

@keyframes slide-down {
  0% {
    opacity: 0;
    transform: translateY(-3rem);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@media screen and (max-width: 767px) {
  .modal-content {
    max-width: 90%;
  }
}

.pagination {
  display: flex;
}

.pagination .pagination-item {
  flex: 1 0 50%;
  margin: 1rem .1rem;
  transition: all .3s;
}

.pagination .pagination-item:hover p, .pagination .pagination-item:hover h1, .pagination .pagination-item:hover h2, .pagination .pagination-item:hover h3, .pagination .pagination-item:hover h4, .pagination .pagination-item:hover h5, .pagination .pagination-item:hover h6, .pagination .pagination-item:not(.selected):hover :not([disabled]):not(.disabled):not(.ellipses) {
  color: #f03d4d;
  transition: all .3s;
}

.pagination .pagination-item.pagination-next {
  text-align: right;
}

.pagination .pagination-item.pagination-prev {
  text-align: left;
}

.pagination .pagination-item a {
  color: #495057;
}

.pagination .pagination-item a[disabled], .pagination .pagination-item a.disabled {
  cursor: pointer;
  opacity: .5;
  pointer-events: none;
  -webkit-user-select: none;
  user-select: none;
}

.pagination .pagination-item .pagination-item-subtitle {
  opacity: .7;
  margin: 0;
}

.pagination .pagination-item.short {
  border-radius: .1rem;
  flex: 0;
  margin: .2rem 0;
}

.pagination .pagination-item.short:first-child a {
  border-top-left-radius: .25rem;
  border-bottom-left-radius: .25rem;
}

.pagination .pagination-item.short:last-child a {
  border-top-right-radius: .25rem;
  border-bottom-right-radius: .25rem;
}

.pagination .pagination-item.short:not([disabled]):not(.disabled):not(.selected):hover {
  background-color: #00000012;
}

.pagination .pagination-item.short.selected {
  color: #fff;
  background-color: #f03d4d;
}

.pagination .pagination-item.short.selected a {
  color: #fff;
}

.pagination .pagination-item.short.ellipses {
  color: #adb5bd;
  pointer-events: none;
}

.pagination .pagination-item.short a {
  padding: .5rem .75rem;
  line-height: 1.25;
  display: inline-block;
}

.pagination.pagination-bordered .pagination-item.short a {
  border: 1px solid #dee2e6;
  border-radius: 0;
  margin-left: -1px;
}

.placeholder {
  text-align: center;
  color: #495057;
  background-color: #dee2e680;
  border-radius: 3px;
  padding: 3rem 2rem;
  display: block;
}

.placeholder .placeholder-icon {
  text-align: center;
}

.placeholder .placeholder-title, .placeholder .placeholder-subtitle {
  margin: 1rem auto;
}

.placeholder .placeholder-subtitle {
  opacity: .7;
  margin: 0;
}

.placeholder .placeholder-commands {
  margin-top: 1rem;
}

.tab-container {
  -webkit-user-select: none;
  user-select: none;
  white-space: nowrap;
  justify-content: space-between;
  align-items: stretch;
  font-size: 1rem;
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
}

.tab-container.tabs--left ul {
  justify-content: flex-start;
}

.tab-container.tabs--center ul {
  justify-content: center;
}

.tab-container.tabs--right ul {
  justify-content: flex-end;
}

.tab-container.tabs-depth ul {
  border-bottom: 0;
  box-shadow: 0 2px 3px #868e9626;
}

.tab-container.tabs-classic ul {
  border-bottom: 1px solid #dee2e6;
  border-radius: 3px 3px 0 0;
  transition: all .3s;
}

.tab-container.tabs-classic ul li:not(.selected) a:hover, .tab-container.tabs-classic ul li:not(.selected) .tab-item-content:hover {
  background-color: #f1f3f5;
  transition: all .3s;
}

.tab-container.tabs-classic ul li.selected a, .tab-container.tabs-classic ul li.selected .tab-item-content {
  color: #f03d4d;
  border-color: #dee2e6;
  border-bottom-color: #0000 !important;
}

.tab-container.tabs-classic ul li a, .tab-container.tabs-classic ul li .tab-item-content {
  cursor: pointer;
  border: 1px solid #0000;
  border-bottom-color: #dee2e6;
  border-radius: 3px 3px 0 0;
  transition: all .3s;
}

.tab-container.tabs-fill ul {
  display: flex;
}

.tab-container.tabs-fill li {
  flex: 1 0 0;
}

.tab-container.tabs--xs {
  font-size: .75rem;
}

.tab-container.tabs--sm {
  font-size: .875rem;
}

.tab-container.tabs--lg {
  font-size: 1.25rem;
}

.tab-container.tabs--xl {
  font-size: 1.5rem;
}

.tab-container ul {
  border-bottom: 1px solid #e9ecef;
  flex-grow: 1;
  justify-content: flex-start;
  margin: .5rem;
  padding-inline-start: 0;
  list-style: none;
  display: flex;
}

.tab-container li {
  cursor: pointer;
  text-align: center;
  margin: 0;
  display: block;
}

.tab-container li:hover a, .tab-container li:hover .tab-item-content {
  border-bottom-color: #f03d4d99;
  transition: all .3s;
}

.tab-container li.selected a, .tab-container li.selected .tab-item-content {
  color: #f03d4d;
  border-width: 2px;
  border-bottom-color: #f03d4d;
  transition: all .3s;
}

.tab-container li a, .tab-container li .tab-item-content {
  color: var(--cirrus-fg);
  background-color: var(--cirrus-bg);
  border: 0;
  border-bottom: 2px solid #e9ecef;
  justify-content: center;
  align-items: center;
  margin-bottom: -2px;
  padding: .5rem 1rem;
  transition: all .3s;
  display: flex;
}

.tab-container .icon:first-child {
  margin-right: .75rem;
}

.tab-container .icon:last-child {
  margin-left: .75rem;
}

.tag {
  color: currentColor;
  height: 2em;
  white-space: nowrap;
  background-color: #f6f9fc;
  border-radius: .25rem;
  align-items: center;
  padding: .625rem;
  font-size: .875rem;
  line-height: 1.5;
  display: inline-flex;
}

.tag.tag--xs {
  padding: .5rem;
  font-size: .75rem;
}

.tag.tag--sm {
  padding: .625rem;
  font-size: .875rem;
}

.tag.tag--md {
  padding: .75rem;
  font-size: 1rem;
}

.tag.tag--lg {
  padding: .875rem;
  font-size: 1.25rem;
}

.tag.tag--xl {
  padding: 1rem;
  font-size: 1.5rem;
}

.tag.tag--rounded {
  border-radius: 290486px;
}

.tag.tag__delete {
  aspect-ratio: 1 / 1;
  cursor: pointer;
  pointer-events: auto;
  height: auto;
  width: auto;
  background-color: #0a0a0a33;
  border-radius: 290486px;
  display: inline-block;
  position: relative;
}

.tag.tag__delete:hover {
  background-color: #0a0a0a4d;
}

.tag.tag__delete:before, .tag.tag__delete:after {
  content: "";
  transform-origin: center;
  background-color: #f6f9fc;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%)translateY(-50%)rotate(45deg);
}

.tag.tag__delete:before {
  height: 1px;
  width: 50%;
}

.tag.tag__delete:after {
  height: 50%;
  width: 1px;
}

.tag.tag__delete {
  margin-left: .25rem;
  margin-right: -.125rem;
}

.tag.tag--white {
  color: #363636;
  background-color: #fff;
}

.tag.tag--black {
  color: #fff;
  background-color: #000;
}

.tag.tag--primary {
  color: #fff;
  color: #fff;
  cursor: pointer;
  background-color: #f03d4d;
}

.tag.tag--primary.tag__close-btn:hover {
  background-color: #ee2537;
}

.tag.tag--primary.tag__close-btn:active {
  background-color: #e81225;
}

.tag.tag--dark {
  color: #fff;
  cursor: pointer;
  background-color: #363636;
}

.tag.tag--dark.tag__close-btn:hover {
  background-color: #292929;
}

.tag.tag--dark.tag__close-btn:active {
  background-color: #1d1d1d;
}

.tag.tag--link {
  color: #fff;
  cursor: pointer;
  background-color: #5e5cc7;
}

.tag.tag--link.tag__close-btn:hover {
  background-color: #4b49c0;
}

.tag.tag--link.tag__close-btn:active {
  background-color: #403db3;
}

.tag.tag--info {
  color: #fff;
  cursor: pointer;
  background-color: #2972fa;
}

.tag.tag--info.tag__close-btn:hover {
  background-color: #1062f9;
}

.tag.tag--info.tag__close-btn:active {
  background-color: #0555eb;
}

.tag.tag--success {
  color: #fff;
  cursor: pointer;
  background-color: #0dd157;
}

.tag.tag--success.tag__close-btn:hover {
  background-color: #0cb94d;
}

.tag.tag--success.tag__close-btn:active {
  background-color: #0aa143;
}

.tag.tag--warning {
  color: #fff;
  cursor: pointer;
  background-color: #fab633;
}

.tag.tag--warning.tag__close-btn:hover {
  background-color: #f9ad1a;
}

.tag.tag--warning.tag__close-btn:active {
  background-color: #f4a306;
}

.tag.tag--danger {
  color: #fff;
  cursor: pointer;
  background-color: #fb4143;
}

.tag.tag--danger.tag__close-btn:hover {
  background-color: #fa282a;
}

.tag.tag--danger.tag__close-btn:active {
  background-color: #fa0f12;
}

.tag.tag__close-btn {
  width: 2em;
  position: relative;
}

.tag.tag__close-btn:before, .tag.tag__close-btn:after {
  content: "";
  transform-origin: center;
  background-color: currentColor;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%)translateY(-50%)rotate(45deg);
}

.tag.tag__close-btn:before {
  height: 1px;
  width: 50%;
}

.tag.tag__close-btn:after {
  height: 50%;
  width: 1px;
}

.tag-container:not(:last-child) {
  margin-bottom: 1rem;
}

.tag-container.group-tags {
  --tag-first-border-radius: .25rem 0 0 .25rem;
  --tag-last-border-radius: 0 .25rem .25rem 0;
  flex-wrap: wrap;
  align-items: center;
  display: flex;
}

.tag-container.group-tags .tag {
  margin-right: 0 !important;
}

.tag-container.group-tags .tag:first-child {
  border-radius: var(--tag-first-border-radius);
}

.tag-container.group-tags .tag:not(:first-child):not(:last-child) {
  border-radius: 0;
}

.tag-container.group-tags .tag:last-child {
  border-radius: var(--tag-last-border-radius);
}

.tag-container.group-tags.group-tags--rounded {
  --tag-first-border-radius: 290486px 0 0 290486px;
  --tag-last-border-radius: 0 290486px 290486px 0;
}

.tag-container.tag-container--centered {
  justify-content: center;
}

.tag-container.tag-container--centered .tag {
  margin: 0 .25rem;
}

.tag-container.tag-container--right {
  justify-content: flex-end;
}

.tag-container.tag-container--right .tag:not(:first-child) {
  margin-left: .5rem;
}

.tag-container.tag-container--right .tag:not(:last-child) {
  margin-right: 0;
}

.tag-container .tag {
  margin-bottom: .5rem;
}

.tag-container .tag:not(:last-child) {
  margin-right: .5rem;
}

a.tag:hover {
  text-decoration: underline;
}

.tile {
  overflow: inherit;
  align-content: space-between;
  display: flex;
}

.tile p {
  font-size: .95rem;
}

.tile .tile__icon, .tile .tile__buttons {
  flex: none;
}

.tile .tile__buttons button, .tile .tile__buttons .btn, .tile .tile__buttons [type="submit"] {
  margin: .1rem;
  display: inline-block;
}

.tile .tile__container {
  flex: auto;
}

.tile .tile__container:not(:first-child) {
  padding-left: 1rem;
}

.tile .tile__container:not(:last-child) {
  padding-right: 1rem;
}

.tile .tile__title {
  margin: .1rem auto;
  font-weight: bolder;
  line-height: 1.5rem;
}

.tile .tile__subtitle {
  opacity: .7;
  margin: .1rem auto;
  line-height: 1.25rem;
}

.toast {
  width: 100%;
  background-color: var(--toast-primary-bg);
  border: 1px solid var(--toast-primary-bg);
  color: #fff;
  border-radius: 2px;
  margin: .5rem;
  padding: .75rem 1.25rem;
  display: block;
  position: relative;
}

.toast.toast--translucent {
  opacity: .5;
}

.toast.toast--translucent:hover {
  opacity: 1;
}

.toast p {
  margin: 0;
}

.toast a {
  color: #f6f9fc;
  transition: all .3s;
}

.toast a:hover {
  opacity: .8;
  transition: all .3s;
}

.toast .toast__title {
  margin: 1rem 0 0;
}

.toast .btn-close {
  position: absolute;
  top: 1rem;
  right: 1rem;
}

.toast.toast--primary {
  background-color: #f03d4d;
  border-color: #f03d4d;
}

.toast.toast--dark {
  background-color: #363636;
  border-color: #363636;
}

.toast.toast--link {
  background-color: #5e5cc7;
  border-color: #5e5cc7;
}

.toast.toast--info {
  background-color: #2972fa;
  border-color: #2972fa;
}

.toast.toast--success {
  background-color: #0dd157;
  border-color: #0dd157;
}

.toast.toast--warning {
  background-color: #fab633;
  border-color: #fab633;
}

.toast.toast--danger {
  background-color: #fb4143;
  border-color: #fb4143;
}

.tooltip {
  white-space: nowrap;
  position: relative;
  overflow: visible;
}

.tooltip:after {
  color: #fff;
  content: attr(data-tooltip);
  text-transform: none;
  opacity: 0;
  text-overflow: ellipsis;
  max-width: 15rem;
  transition: all var(--animation-duration) ease;
  z-index: 200;
  pointer-events: none;
  background-color: #454d5de6;
  border-radius: .2rem;
  padding: .4rem .8rem;
  font-size: .6rem;
  line-height: 1rem;
  display: block;
  position: absolute;
  bottom: 100%;
  left: 50%;
  overflow: hidden;
  transform: translate(-50%);
}

.tooltip:focus:after, .tooltip:hover:after {
  opacity: 1;
  transition: all var(--animation-duration) ease;
  transform: translate(-50%, -.5rem);
}

.tooltip.tooltip--top-left:after {
  transform: translate(-50%);
}

.tooltip.tooltip--top-left:hover:after, .tooltip.tooltip--top-left:focus:after {
  left: 0;
  transform: translate(-100%, -.5rem);
}

.tooltip.tooltip--top-right:after {
  left: auto;
  transform: translate(15%);
}

.tooltip.tooltip--top-right:hover:after, .tooltip.tooltip--top-right:focus:after {
  right: 0;
  transform: translate(100%, -.5rem);
}

.tooltip.tooltip--bottom:after {
  top: 100%;
  bottom: auto;
  transform: translate(-50%, -1rem);
}

.tooltip.tooltip--bottom:hover:after, .tooltip.tooltip--bottom:focus:after {
  bottom: auto;
  transform: translate(-50%, .5rem);
}

.tooltip.tooltip--bottom-left:after {
  top: 100%;
  bottom: auto;
  transform: translate(-65%, -1rem);
}

.tooltip.tooltip--bottom-left:hover:after, .tooltip.tooltip--bottom-left:focus:after {
  left: 0;
  transform: translate(-100%, .5rem);
}

.tooltip.tooltip--bottom-right:after {
  top: 100%;
  bottom: auto;
  left: auto;
  transform: translate(0%, -1rem);
}

.tooltip.tooltip--bottom-right:hover:after, .tooltip.tooltip--bottom-right:focus:after {
  right: 0;
  transform: translate(100%, .5rem);
}

.tooltip.tooltip--right:after {
  bottom: 50%;
  left: 100%;
  transform: translate(-1rem, 50%);
}

.tooltip.tooltip--right:hover:after, .tooltip.tooltip--right:focus:after {
  transform: translate(.5rem, 50%);
}

.tooltip.tooltip--left:after {
  bottom: 50%;
  left: auto;
  right: 100%;
  transform: translate(1rem, 50%);
}

.tooltip.tooltip--left:hover:after, .tooltip.tooltip--left:focus:after {
  transform: translate(-.5rem, 50%);
}

.mfp-bg {
  width: 100%;
  height: 100%;
  z-index: 1042;
  opacity: .8;
  background: #0b0b0b;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
}

.mfp-wrap {
  width: 100%;
  height: 100%;
  z-index: 1043;
  -webkit-backface-visibility: hidden;
  position: fixed;
  top: 0;
  left: 0;
  outline: none !important;
}

.mfp-container {
  text-align: center;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 0 8px;
  position: absolute;
  top: 0;
  left: 0;
}

.mfp-container:before {
  content: "";
  height: 100%;
  vertical-align: middle;
  display: inline-block;
}

.mfp-align-top .mfp-container:before {
  display: none;
}

.mfp-content {
  vertical-align: middle;
  text-align: left;
  z-index: 1045;
  margin: 0 auto;
  display: inline-block;
  position: relative;
}

.mfp-inline-holder .mfp-content, .mfp-ajax-holder .mfp-content {
  width: 100%;
  cursor: auto;
}

.mfp-ajax-cur {
  cursor: progress;
}

.mfp-zoom-out-cur, .mfp-zoom-out-cur .mfp-image-holder .mfp-close {
  cursor: -moz-zoom-out;
  cursor: -webkit-zoom-out;
  cursor: zoom-out;
}

.mfp-zoom {
  cursor: pointer;
  cursor: -webkit-zoom-in;
  cursor: -moz-zoom-in;
  cursor: zoom-in;
}

.mfp-auto-cursor .mfp-content {
  cursor: auto;
}

.mfp-close, .mfp-arrow, .mfp-preloader, .mfp-counter {
  -webkit-user-select: none;
  user-select: none;
}

.mfp-loading.mfp-figure {
  display: none;
}

.mfp-hide {
  display: none !important;
}

.mfp-preloader {
  color: #ccc;
  width: auto;
  text-align: center;
  z-index: 1044;
  margin-top: -.8em;
  position: absolute;
  top: 50%;
  left: 8px;
  right: 8px;
}

.mfp-preloader a {
  color: #ccc;
}

.mfp-preloader a:hover {
  color: #fff;
}

.mfp-s-ready .mfp-preloader, .mfp-s-error .mfp-content {
  display: none;
}

button.mfp-close, button.mfp-arrow {
  cursor: pointer;
  -webkit-appearance: none;
  z-index: 1046;
  box-shadow: none;
  touch-action: manipulation;
  background: none;
  border: 0;
  outline: none;
  padding: 0;
  display: block;
  overflow: visible;
}

button::-moz-focus-inner {
  border: 0;
  padding: 0;
}

.mfp-close {
  width: 44px;
  height: 44px;
  text-align: center;
  opacity: .65;
  color: #fff;
  padding: 0 0 18px 10px;
  font-family: Arial, Baskerville, monospace;
  font-size: 28px;
  font-style: normal;
  line-height: 44px;
  text-decoration: none;
  position: absolute;
  top: 0;
  right: 0;
}

.mfp-close:hover, .mfp-close:focus {
  opacity: 1;
}

.mfp-close:active {
  top: 1px;
}

.mfp-close-btn-in .mfp-close {
  color: #333;
}

.mfp-image-holder .mfp-close, .mfp-iframe-holder .mfp-close {
  color: #fff;
  text-align: right;
  width: 100%;
  padding-right: 6px;
  right: -6px;
}

.mfp-counter {
  color: #ccc;
  white-space: nowrap;
  font-size: 12px;
  line-height: 18px;
  position: absolute;
  top: 0;
  right: 0;
}

.mfp-arrow {
  opacity: .65;
  width: 90px;
  height: 110px;
  -webkit-tap-highlight-color: transparent;
  margin: -55px 0 0;
  padding: 0;
  position: absolute;
  top: 50%;
}

.mfp-arrow:active {
  margin-top: -54px;
}

.mfp-arrow:hover, .mfp-arrow:focus {
  opacity: 1;
}

.mfp-arrow:before, .mfp-arrow:after {
  content: "";
  width: 0;
  height: 0;
  border: inset #0000;
  margin-top: 35px;
  margin-left: 35px;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.mfp-arrow:after {
  border-top-width: 13px;
  border-bottom-width: 13px;
  top: 8px;
}

.mfp-arrow:before {
  opacity: .7;
  border-top-width: 21px;
  border-bottom-width: 21px;
}

.mfp-arrow-left {
  left: 0;
}

.mfp-arrow-left:after {
  border-right: 17px solid #fff;
  margin-left: 31px;
}

.mfp-arrow-left:before {
  border-right: 27px solid #3f3f3f;
  margin-left: 25px;
}

.mfp-arrow-right {
  right: 0;
}

.mfp-arrow-right:after {
  border-left: 17px solid #fff;
  margin-left: 39px;
}

.mfp-arrow-right:before {
  border-left: 27px solid #3f3f3f;
}

.mfp-iframe-holder {
  padding-top: 40px;
  padding-bottom: 40px;
}

.mfp-iframe-holder .mfp-content {
  width: 100%;
  max-width: 900px;
  line-height: 0;
}

.mfp-iframe-holder .mfp-close {
  top: -40px;
}

.mfp-iframe-scaler {
  width: 100%;
  height: 0;
  padding-top: 56.25%;
  overflow: hidden;
}

.mfp-iframe-scaler iframe {
  width: 100%;
  height: 100%;
  background: #000;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: 0 0 8px #0009;
}

img.mfp-img {
  width: auto;
  max-width: 100%;
  height: auto;
  box-sizing: border-box;
  margin: 0 auto;
  padding: 40px 0;
  line-height: 0;
  display: block;
}

.mfp-figure {
  line-height: 0;
}

.mfp-figure:after {
  content: "";
  width: auto;
  height: auto;
  z-index: -1;
  background: #444;
  display: block;
  position: absolute;
  inset: 40px 0;
  box-shadow: 0 0 8px #0009;
}

.mfp-figure small {
  color: #bdbdbd;
  font-size: 12px;
  line-height: 14px;
  display: block;
}

.mfp-figure figure {
  margin: 0;
}

.mfp-bottom-bar {
  width: 100%;
  cursor: auto;
  margin-top: -36px;
  position: absolute;
  top: 100%;
  left: 0;
}

.mfp-title {
  text-align: left;
  color: #f3f3f3;
  word-wrap: break-word;
  padding-right: 36px;
  line-height: 18px;
}

.mfp-image-holder .mfp-content {
  max-width: 100%;
}

.mfp-gallery .mfp-image-holder .mfp-figure {
  cursor: pointer;
}

@media screen and (max-width: 800px) and (orientation: landscape), screen and (max-height: 300px) {
  .mfp-img-mobile .mfp-image-holder {
    padding-left: 0;
    padding-right: 0;
  }

  .mfp-img-mobile img.mfp-img {
    padding: 0;
  }

  .mfp-img-mobile .mfp-figure:after {
    top: 0;
    bottom: 0;
  }

  .mfp-img-mobile .mfp-figure small {
    margin-left: 5px;
    display: inline;
  }

  .mfp-img-mobile .mfp-bottom-bar {
    box-sizing: border-box;
    background: #0009;
    margin: 0;
    padding: 3px 5px;
    position: fixed;
    top: auto;
    bottom: 0;
  }

  .mfp-img-mobile .mfp-bottom-bar:empty {
    padding: 0;
  }

  .mfp-img-mobile .mfp-counter {
    top: 3px;
    right: 5px;
  }

  .mfp-img-mobile .mfp-close {
    width: 35px;
    height: 35px;
    text-align: center;
    background: #0009;
    padding: 0;
    line-height: 35px;
    position: fixed;
    top: 0;
    right: 0;
  }
}

@media (max-width: 900px) {
  .mfp-arrow {
    transform: scale(.75);
  }

  .mfp-arrow-left {
    transform-origin: 0;
  }

  .mfp-arrow-right {
    transform-origin: 100%;
  }

  .mfp-container {
    padding-left: 6px;
    padding-right: 6px;
  }
}

body {
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  background-repeat: no-repeat;
  background-size: cover;
}

.logo {
  width: 150px;
}

#map-donau, #map-mh {
  z-index: 99;
  width: 100%;
  height: 400px;
}

.filtr-item {
  min-width: 250px;
}

.filter-cnt {
  min-height: 370px;
}

/*# sourceMappingURL=index.3d9a5c64.css.map */
